import { ERROR_VALUE } from '../constants';
import { isNum } from './utils';
import { add, mul } from '../operations.js';
import Matrix from '../Matrix';
import type FormulaError from '../FormulaError';
import type { MaybeBoxed } from '../ValueBox';

/**
 * Calculates the matrix product of two matrices specified as arrays or ranges.
 */
export function MMULT (a: Matrix, b: Matrix): Matrix | FormulaError {
  if (a.width !== b.height) {
    return ERROR_VALUE.detailed("MMULT matrix dimensions don't match");
  }
  if (a.hasDefaultValueFulfilling(v => !isNum(v)) || b.hasDefaultValueFulfilling(v => !isNum(v))) {
    // Early exit for cases where we know the result will be an error (because
    // of non-numerical values). This should handle most catastrophic
    // performance cases such as `=MMUL(A:B, 1:2)` as well.
    return ERROR_VALUE.detailed('MMULT matrix has non-numeric value');
  }

  const result = new Matrix(b.width, a.height);
  for (let row = 0; row < a.height; row++) {
    for (let col = 0; col < b.width; col++) {
      let sum: MaybeBoxed<number> = 0;
      for (let sharedOffset = 0; sharedOffset < a.width; sharedOffset++) {
        const aVal = a.getBoxed(sharedOffset, row);
        const bVal = b.getBoxed(col, sharedOffset);
        if (!isNum(aVal) || !isNum(bVal)) {
          return ERROR_VALUE.detailed('MMULT matrix has non-numeric value');
        }
        sum = add(sum, mul(aVal, bVal));
      }
      result.set(col, row, sum);
    }
  }
  return result;
}
