import escaperegexp from 'lodash.escaperegexp';
import { NextPageContext } from 'next';
import { NextIncomingMessage } from 'next/dist/server/request-meta';

import { locationProps } from './locationProps';
import { isBrowser } from './runtimeContext';

/**
 * A function that optionally accepts a context object on the server and returns the cookie domain
 * @param {object} req: from nextJs context object https://nextjs.org/docs/api-reference/data-fetching/getInitialProps#context-object
 * @returns a string matching the cookie domain
 */
const getCookieDomain = (req?: NextIncomingMessage): string => {
  let hostname = '';
  if (isBrowser()) {
    hostname = window.location.hostname;
  }
  else if (req) {
    hostname = locationProps(req).host;
  }

  if (hostname.endsWith('.ingrid.is') || hostname === 'ingrid.is') {
    return '.ingrid.is';
  }
  if (hostname.endsWith('.grid.is') || hostname === 'grid.is') {
    return '.grid.is';
  }
  if (hostname.endsWith('.calculatorstudio.co')) {
    return '.calculatorstudio.co';
  }
  // XXX: perhaps change to if (hostname === 'localhost') ?
  if (hostname.includes('localhost')) {
    return 'localhost';
  }
  // XXX: change this default
  return '.grid.is';
};

const cookieReCache = {};
export const getCookie = (name: string, ctx?: Pick<NextPageContext, 'req'>): string | null => {
  let cookie: string | undefined;
  if (isBrowser()) {
    try {
      cookie = document.cookie;
    }
    catch {
      return null;
    }
  }
  else if (ctx) {
    cookie = ctx && ctx.req && ctx.req.headers && ctx.req.headers.cookie;
  }
  if (cookie) {
    if (!cookieReCache[name]) {
      cookieReCache[name] = new RegExp('(?:^|;) ?' + escaperegexp(name) + '=([^;]*)(?:;|$)');
    }
    const v = cookieReCache[name].exec(cookie);
    if (v) {
      return v[1];
    }
  }
  return null;
};

export const setCookie =  (name: string, value: string, expires: string | number = 'Session', domain = getCookieDomain()) => {
  // if expires is a number it is interpreted as to mean "in how many seconds"
  if (typeof expires === 'number') {
    const endTime = Date.now() + (expires * 1000);
    expires = new Date(endTime).toUTCString();
  }
  document.cookie = `${name}=${value};expires=${expires};path=/;domain=${domain};`;
};

export const deleteCookie = (name: string, domain = getCookieDomain()): void => {
  setCookie(name, '', 'Thu, 01 Jan 1970 00:00:00 UTC', domain);
};
