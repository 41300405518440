import { ERROR_DIV0, ERROR_NULL, ERROR_NUM, MODE_GOOGLE } from '../constants';
import { nearlyEqual } from './utils-number';
import { isRef } from './utils';
import { TYPE_BLANK, TYPE_NUM, TYPE_BOOL, TYPE_STRING, TYPE_LAMBDA, TYPE_ERROR } from '../types';
import { valueToType } from '../coerce';
import Reference, { type A1Reference } from '../Reference';
import Range from '../referenceParser/Range.js';
import { add, div, sub, pow, mul } from '../operations.js';
import { box, isBoxed, unbox, type MaybeBoxed } from '../ValueBox.js';
import type FormulaError from '../FormulaError';
import type { Lambda } from '../lambda';
import type ValueBox from '../ValueBox';
import type { ContextFreeSpreadsheetFunction, EvaluationContext } from '../EvaluationContext';

// Higher number means higher priority
const TYPE_PRIORITY = {
  [TYPE_NUM]: 1,
  [TYPE_STRING]: 2,
  [TYPE_LAMBDA]: 3,
  [TYPE_BOOL]: 4,
  [TYPE_ERROR]: 5,
};

const collator: { compare: (a: string, b: string) => number } = (() => {
  // we prefer Intl.Collator
  if (typeof Intl !== 'undefined') {
    return new Intl.Collator('en', { sensitivity: 'accent' });
  }
  else {
    // but will fallback to localeCompare
    return {
      compare: (a, b) => {
        if (a === b) {
          return 0;
        }
        return a.toLocaleUpperCase().localeCompare(b.toLocaleUpperCase());
      },
    };
  }
})();

export function strCompare (a: string, b: string): number {
  return collator.compare(a, b);
}

/**
 * - Returns zero when `a` and `b` are equal (or nearly so, per Excel).
 * - Returns a negative number when `a > b`.
 * - Returns a positive number when `a < b`.
 */
export function operatorCollate (
  a: number | string | boolean | null | FormulaError | Lambda,
  b: number | string | boolean | null | FormulaError | Lambda,
): number {
  const aType = valueToType(a);
  const bType = valueToType(b);
  if ((aType | bType) & ~(TYPE_NUM | TYPE_STRING | TYPE_BOOL | TYPE_BLANK | TYPE_LAMBDA | TYPE_ERROR)) {
    throw new Error(`internal error: can't collate types ${aType} and ${bType}`);
  }
  if (aType === bType) {
    if (aType & (TYPE_NUM | TYPE_BOOL)) {
      // We use `nearlyEqual` to make expressions like 0.1 + 0.2 = 0.3 match
      // Excel's behaviour rather than JavaScript's.
      if (nearlyEqual(a as number | boolean, b as number | boolean)) {
        return 0;
      }
      // @ts-expect-error `a` and `b` are numbers or booleans
      return a - b;
    }
    if (aType === TYPE_STRING) {
      // @ts-expect-error `a` and `b` are strings
      return strCompare(a, b);
    }
    // OBSERVATION: aType === bType, and that type is either BLANK or LAMBDA,
    // each of which only has a single value.
    return 0;
  }
  if ((aType | bType) & TYPE_BLANK) {
    // Exactly one of the arguments is blank
    if (bType === TYPE_BLANK) {
      if (aType & (TYPE_ERROR | TYPE_LAMBDA)) {
        return -1;
      }
      if (aType & (TYPE_NUM | TYPE_BOOL)) {
        // @ts-expect-error `a` is a number or boolean
        return +a;
      }
      // @ts-expect-error OBSERVATION: aType === TYPE_STRING
      return strCompare(a, '');
    }
    if (bType & (TYPE_ERROR | TYPE_LAMBDA)) {
      return 1;
    }
    if (bType & (TYPE_NUM | TYPE_BOOL)) {
      // @ts-expect-error `b` is a number or boolean
      return -b;
    }
    // @ts-expect-error OBSERVATION: bType === TYPE_STRING
    return strCompare('', b);
  }
  return TYPE_PRIORITY[aType] - TYPE_PRIORITY[bType];
}

export function LT (a: string | number | boolean | null, b: string | number | boolean | null) {
  const cmp = operatorCollate(a, b);
  return cmp < 0;
}

export function GT (a: string | number | boolean | null, b: string | number | boolean | null) {
  const cmp = operatorCollate(a, b);
  return cmp > 0;
}

export function LTE (a: string | number | boolean | null, b: string | number | boolean | null) {
  const cmp = operatorCollate(a, b);
  return cmp <= 0;
}

export function GTE (a: string | number | boolean | null, b: string | number | boolean | null) {
  const cmp = operatorCollate(a, b);
  return cmp >= 0;
}

export function EQ (a: string | number | boolean | null, b: string | number | boolean | null) {
  const cmp = operatorCollate(a, b);
  return cmp === 0;
}

export function NE (a: string | number | boolean | null, b: string | number | boolean | null) {
  const cmp = operatorCollate(a, b);
  return cmp !== 0;
}

export function UPLUS (a: MaybeBoxed<number>): MaybeBoxed<number> {
  if (isBoxed(a)) {
    return a.map(v => v);
  }
  return a;
}

export function UMINUS (a: MaybeBoxed<number>): MaybeBoxed<number> {
  if (isBoxed(a)) {
    return a.map(v => -v);
  }
  return -a;
}

/**
 * Returns the value interpreted as a percentage.
 *
 * @example
 * // Returns 1
 * UNARY_PERCENT(100);
 */
export function UNARY_PERCENT (a: MaybeBoxed<number>): ValueBox<number> {
  if (isBoxed(a)) {
    return box(unbox(a) * 0.01, { ...a.metadata });
  }
  return box(a * 0.01);
}

export function ADD (a: MaybeBoxed<number>, b: MaybeBoxed<number>): MaybeBoxed<number> {
  return add(a, b);
}

export function MINUS (a: MaybeBoxed<number>, b: MaybeBoxed<number>): MaybeBoxed<number> {
  return sub(a, b);
}

export function MULTIPLY (a: MaybeBoxed<number>, b: MaybeBoxed<number>): MaybeBoxed<number> {
  return mul(a, b);
}

/**
 * @param a
 * @param b
 * @returns {string}
 */
export function CONCAT (a: string, b: string): string {
  return a + b;
}

export function DIVIDE (a: MaybeBoxed<number>, b: MaybeBoxed<number>): MaybeBoxed<number> | FormulaError {
  if (unbox(b) === 0) {
    return ERROR_DIV0;
  }
  return div(a, b);
}

export function POW (
  this: EvaluationContext,
  a: MaybeBoxed<number>,
  b: MaybeBoxed<number>,
): MaybeBoxed<number> | FormulaError {
  if (unbox(a) === 0) {
    const ub = unbox(b);
    if (ub === 0) {
      return ERROR_NUM;
    }
    if (ub < 0) {
      return this.mode === MODE_GOOGLE ? ERROR_NUM : ERROR_DIV0;
    }
  }
  return pow(a, b);
}

export function INTERSECT (a: A1Reference, b: A1Reference): A1Reference | FormulaError {
  if (!isRef(a) || !isRef(b)) {
    // intersect only works on ranges everything else should be blocked by the parser (syntax error)
    return ERROR_NULL;
  }
  const ar = a.range;
  const br = b.range;
  if (
    a.sheetName !== b.sheetName ||
    ar.top > br.bottom ||
    br.top > ar.bottom ||
    ar.left > br.right ||
    br.left > ar.right
  ) {
    // ranges don't overlap
    return ERROR_NULL;
  }
  // ranges overlap, return a new range with the minimum common area
  const c = new Reference(
    new Range({
      top: Math.max(ar.top, br.top),
      left: Math.max(ar.left, br.left),
      bottom: Math.min(ar.bottom, br.bottom),
      right: Math.min(ar.right, br.right),
    }),
    a,
  );
  return c as A1Reference;
}

const infixHandlers = {
  '<': LT,
  '>': GT,
  '<=': LTE,
  '>=': GTE,
  '=': EQ,
  '<>': NE,
  '+': ADD,
  '-': MINUS,
  '*': MULTIPLY,
  '/': DIVIDE,
  '^': POW,
  '&': CONCAT,
  ' ': INTERSECT,
};

const infixNames = {
  '<': 'LT',
  '>': 'GT',
  '<=': 'LTE',
  '>=': 'GTE',
  '=': 'EQ',
  '<>': 'NE',
  '+': 'ADD',
  '-': 'MINUS',
  '*': 'MULTIPLY',
  '/': 'DIVIDE',
  '^': 'POW',
  '&': 'CONCAT',
  ' ': 'INTERSECT',
};

export function getInfixHandler<T extends string> (
  symbol: T,
): T extends keyof typeof infixHandlers ? (typeof infixHandlers)[T] : ContextFreeSpreadsheetFunction | undefined {
  return infixHandlers[symbol as string];
}

export function getInfixName (symbol: string): string | undefined {
  return infixNames[symbol];
}
