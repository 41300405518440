import { elementVisibility, layoutWidth } from '../propsData';

/**
 * @param {object[] | null | undefined} doc
 * @param {import('@grid-is/apiary').Model} model
 * @param {boolean} [ignoreHidden=false]
 * @return {'full' | 'wide' | ''}
 */
export function getDocumentActiveWidth (doc, model, ignoreHidden = false) {
  if (!doc || !doc.length) {
    return '';
  }
  return doc.reduce((a, d) => {
    const attr = Object.assign({ id: d.id, model }, d.data || d.attr);
    if (ignoreHidden || elementVisibility.read(attr)) {
      const width = layoutWidth.read(attr);
      if (width === 'full' || (width === 'wide' && a !== 'full')) {
        return width;
      }
    }
    return a;
  }, '');
}
