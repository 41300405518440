import { ERROR_VALUE, ERROR_REF, ERROR_NA, MISSING, MODE_EXCEL, MODE_GOOGLE } from '../constants';
import { fmtWithCellValues, isErr, isMatrix, isNum, isRef, toNum, toStr } from './utils';
import {
  searchExact,
  searchBinary,
  searchExactDesc,
  searchBinaryXlookup,
  searchExactXlookup,
  searchWildcardXlookup,
} from './utils-seek';
import Reference, { type A1Reference } from '../Reference';
import Range from '../referenceParser/Range.js';
import Matrix from '../Matrix';
import EvaluationOrderException from '../../EvaluationOrderException';
import FormulaError from '../FormulaError';
import { invariant } from '../../validation';
import { box, type MaybeBoxed } from '../ValueBox.js';
import { isLambda, type Lambda } from '../lambda';
import type { EvaluationContext } from '../EvaluationContext';
import type { ArrayValue, CellValue, CellValueAtom, FormulaValue } from '../types';
import type Cell from '../Cell';

function createValueGetter (table: Matrix, isHorizontal: boolean): (n: number) => ArrayValue {
  if (isHorizontal) {
    /** @returns {ArrayValue} */
    return (i): ArrayValue => {
      return table.get(i, 0);
    };
  }
  else {
    /** @returns {ArrayValue} */
    return (i): ArrayValue => {
      return table.get(0, i);
    };
  }
}

/**
 * ADDRESS(row, column, [abs], [use_a1_notation], [sheet])
 * Returns a cell reference as a string.
 * @param {number} row the one-based row number to use in the reference
 * @param {number} [col] the one-based column number to use in the reference
 * @param {number} [abs] 1 to produce an absolute reference, 2 for absolute row
 *   but relative column, 3 for relative row but absolute column, and 4 for
 *   a fully relative reference
 * @param {boolean} [a1] true or omitted, to produce an A1 reference; false to
 *   produce an R1C1 reference
 * @param {string} [sheet] name of a sheet to prefix the reference with
 * @return {string | FormulaError}
 */
export function ADDRESS (row: number, col?: number, abs?: number, a1?: boolean, sheet?: string): string | FormulaError {
  const r = toNum(row);
  if (isErr(r)) {
    return r;
  }
  const c = toNum(col);
  if (isErr(c)) {
    return c;
  }
  const abs_mode = toNum(abs == null ? 1 : abs);
  if (isErr(abs_mode)) {
    return abs_mode;
  }
  if (a1 === false) {
    return ERROR_VALUE.detailed('ADDRESS with a1 = false is not supported');
  }
  if (![ 1, 2, 3, 4 ].includes(abs_mode)) {
    return ERROR_VALUE;
  }
  const s = toStr(sheet);
  if (isErr(s)) {
    return s;
  }
  const rowIndex = r - 1;
  const colIndex = c - 1;
  const rowAbs = abs_mode === 1 || abs_mode === 2;
  const colAbs = abs_mode === 1 || abs_mode === 3;
  if (rowIndex < 0 || colIndex < 0) {
    return ERROR_VALUE;
  }
  const ref = new Reference(
    new Range({
      top: rowIndex,
      left: colIndex,
      bottom: rowIndex,
      right: colIndex,
      $top: rowAbs,
      $left: colAbs,
      $bottom: rowAbs,
      $right: colAbs,
    }),
    { sheetName: s },
  );
  return ref.toString();
}

// reference
// The number of areas in a cell range or reference.
export function AREAS () {
  return null;
}

/**
 * CHOOSE(index, choice1, [choice2, ...])
 * Returns an element from a list of choices based on index.
 */
export function CHOOSE (
  index: number | Matrix,
  ...args: (string | number | boolean | Reference | Matrix)[]
): FormulaValue {
  // FIXME: `=CHOOSE(1,A1:A10,...)` is a special case where choose yields the Nth from column where Nth=the row number of the reference cell
  const idx = toNum(index);
  if (isErr(idx)) {
    return idx;
  }
  if (idx < 1 || idx > 254 || idx > args.length || args.length < 1) {
    // the 254 limit is stated by the docs/spec, but is really just there because 255 is the max parameter count for a function
    return ERROR_VALUE;
  }
  return args[idx - 1];
}

/**
 * COLUMN([cell_reference])
 * Returns the column number (one-based, so A is 1) of a specified cell, or of
 * the sheet cell in which the formula is evaluated. If passed a range
 * reference, return a 1-column-tall matrix of the column numbers of the
 * reference.
 * @param cellRef the specified cell or matrix, or the context cell.
 *   Note: this parameter is optional in formulas, but we special-case the
 *   no-argument case in call handling, passing a reference to the current sheet
 *   cell. So this function _implementation_ always receives a reference.
 */
export function COLUMN (cellRef: A1Reference): number | Matrix {
  if (cellRef.width === 1) {
    return cellRef.range.left + 1;
  }
  // return a new 1-dimensional matrix of row numbers
  const r = new Matrix();
  for (let i = 0; i < cellRef.width; i++) {
    r.set(i, 0, cellRef.range.left + 1 + i);
  }
  return r;
}

/**
 * COLUMNS(range)
 * Returns the number of columns in a specified array or range.
 * @param {Matrix | Reference} range
 */
export function COLUMNS (range: Matrix | Reference) {
  return range.width;
}

/**
 * FORMULATEXT(reference)
 * The formula from a particular cell.
 * @param reference
 * @returns the formula from the referenced
 *   cell, or #N/A if that cell has no formula, or a Matrix of such results if
 *   `reference` is not 1x1.
 */
export function FORMULATEXT (this: EvaluationContext, reference: Reference): string | FormulaError | Matrix {
  if (this.mode === MODE_GOOGLE) {
    reference = reference.collapse();
  }
  if (reference.size === 1) {
    return formulaTextOfCell(reference.resolveCell());
  }
  const areaCells = reference.resolveAreaCells();
  return Matrix.new({
    data: areaCells.map(row => row.map(formulaTextOfCell)),
    width: reference.width,
    height: reference.height,
    defaultValue: ERROR_NA,
  });
}

function formulaTextOfCell (cell: Cell | null) {
  if (!cell || !cell.f) {
    return ERROR_NA;
  }
  const formula = cell.f;
  if (formula.startsWith('=')) {
    return formula;
  }
  else {
    return '=' + formula;
  }
}

// GETPIVOTDATA(value_name, any_pivot_table_cell, [original_column, ...], [pivot_item, ...]
// Extracts an aggregated value from a pivot table that corresponds to the specified row and column headings.
export function GETPIVOTDATA () {
  return null;
}

function bothLookup (
  searchKey: boolean | string | number | Lambda,
  addressRange: Matrix | Reference | number,
  index: Matrix | Reference | number,
  doBinSearch: boolean | null,
  isHorizontal: boolean,
  resolver: EvaluationContext,
): MaybeBoxed<CellValue> {
  if (isNum(addressRange)) {
    return ERROR_NA;
  }
  const lookupRange = isHorizontal ? addressRange.collapseToRow(0) : addressRange.collapseToColumn(0);
  if (isErr(lookupRange)) {
    return lookupRange;
  }
  if (
    resolver.isDirtyFormulaCell &&
    isRef(addressRange) &&
    addressRange.dynamic &&
    'any' in lookupRange &&
    lookupRange.any(resolver.isDirtyFormulaCell) === true
  ) {
    throw new EvaluationOrderException('Unhandled dynamic dependency', lookupRange);
  }
  const lookupValues = lookupRange.toMatrix(false);
  if (isErr(lookupValues)) {
    return lookupValues;
  }
  let numIndex = toNum(index);
  if (isErr(numIndex)) {
    return numIndex;
  }
  numIndex = Math.floor(numIndex);
  if (numIndex < 1) {
    return ERROR_VALUE;
  }
  if (numIndex > (isHorizontal ? addressRange.height : addressRange.width)) {
    return ERROR_NA;
  }

  const dataLength = isHorizontal
    ? Math.min(lookupValues.populatedWidth + 1, lookupValues.width)
    : Math.min(lookupValues.populatedHeight + 1, lookupValues.height);
  const fullLength = isHorizontal ? lookupValues.width : lookupValues.height;

  doBinSearch = doBinSearch == null ? true : doBinSearch;

  const getCellValue = createValueGetter(lookupValues, isHorizontal);
  let pos = doBinSearch
    ? searchBinary(searchKey, dataLength, getCellValue, firstRepeatStart(lookupValues))
    : searchExact(searchKey, dataLength, getCellValue);
  if (pos === dataLength - 1) {
    pos = fullLength - 1;
  }
  if (pos < 0 || pos >= fullLength) {
    return ERROR_NA;
  }

  const resultRef = isHorizontal
    ? addressRange.collapseToCell(numIndex - 1, pos)
    : addressRange.collapseToCell(pos, numIndex - 1);

  if (isErr(resultRef)) {
    return resultRef;
  }

  const resultCell = resultRef.resolveCell();

  if (!resultCell || isErr(resultCell)) {
    return resultCell;
  }
  if (isRef(resultRef)) {
    if (resolver.isDirtyFormulaCell?.(resultCell)) {
      throw new EvaluationOrderException('HLOOKUP/VLOOKUP result cell is not yet UPTODATE', resultRef);
    }
  }
  const result = resultCell.v;
  invariant(!isRef(result) && !isMatrix(result) && !isLambda(result));
  return result;
}

/**
 *   HLOOKUP(search_key, range, index, [is_sorted])
 * Horizontal lookup. Searches across the first row of a range for a key and
 * returns the value of a specified cell in the column found.
 */
export function HLOOKUP (
  this: EvaluationContext,
  searchKey: boolean | string | number | Lambda,
  range: Matrix | Reference | number,
  index: Matrix | Reference | number,
  doBinSearch: boolean,
): MaybeBoxed<CellValue> {
  return bothLookup(searchKey, range, index, doBinSearch, true, this);
}

/**
 *   VLOOKUP(search_key, range, index, [is_sorted])
 * Vertical lookup. Searches down the first column of a range for a key and
 * returns the value of a specified cell in the row found.
 */
export function VLOOKUP (
  this: EvaluationContext,
  searchKey: boolean | string | number | Lambda,
  range: Matrix | Reference | number,
  index: Matrix | Reference | number,
  doBinSearch: boolean,
): MaybeBoxed<CellValue> {
  return bothLookup(searchKey, range, index, doBinSearch, false, this);
}

/**
 * Syntax: XLOOKUP(lookup, lookup_array, return_array, [not_found], [match_mode], [search_mode])
 * @param lookup The lookup value.
 * @param lookupArray The array or range to search.
 * @param returnArray The array or range to return the matching element(s) from.
 * @param [notFound] Value to return if no match found.
 * @param [matchMode] One of:
 *     * 0 = exact match (Default)
 *     * -1 = exact match or next smallest
 *     * 1 = exact match or next larger
 *     * 2 = wildcard match.
 * @param [searchMode] One of:
 *     * 1 = search from first (Default)
 *     * -1 = search from last
 *     * 2 = binary search where `lookup_array` is in ascending order
 *     * -2 = binary search where `lookup_array` is in descending order
 * @returns Matching value(s) from return array, or the fallback value if given and no match found.
 */
export function XLOOKUP (
  lookup: CellValueAtom,
  lookupArray: Matrix | A1Reference,
  returnArray: Matrix | A1Reference,
  notFound?: CellValue | Matrix | A1Reference,
  matchMode: number | null = 0,
  searchMode: number | null = 1,
): CellValue | Matrix | A1Reference {
  if (!lookupArray.is1D()) {
    return ERROR_VALUE.detailed('Lookup array is not a single row or column');
  }

  const horizontal = lookupArray.width > lookupArray.height;
  if (horizontal) {
    if (lookupArray.width !== returnArray.width) {
      return ERROR_VALUE.detailed('Lookup array is horizontal, and width does not match return array');
    }
  }
  else if (lookupArray.height !== returnArray.height) {
    return ERROR_VALUE.detailed('Lookup array is vertical, and height does not match return array');
  }

  const pos = searchXlookupXmatch(searchMode, matchMode, lookup, lookupArray);
  if (isErr(pos)) {
    return pos;
  }

  if (pos < 0 || pos >= lookupArray.size) {
    if (notFound === MISSING) {
      return ERROR_NA;
    }
    return notFound;
  }

  if (horizontal) {
    return returnArray.collapseToColumn(pos);
  }
  else {
    return returnArray.collapseToRow(pos);
  }
}

/**
 *  Syntax:
 *     =XMATCH (lookup_value, lookup_array, [match_mode], [search_mode])
 * Arguments:
 *     lookup_value (Blank, Boolean, String, Number):
 *       The lookup value.
 *     lookup_array (Array, Range):
 *       The array or range to search.
 *     match_mode (Optional) (Missing, Number):
 *       * 1 = search from first (Default)
 *       * -1 = search from last
 *       * 2 = binary search where `lookup_array` is in ascending order
 *       * -2 = binary search where `lookup_array` is in descending order
 *     search_mode (Optional) (Missing, Number):
 *       * 0 = Exact match (default)
 *       * -1 = Exact match or next smallest item
 *       * 1 = Exact match or next largest item
 *       * 2 = A wildcard match where *, ?, and ~ have special meaning:
 *         - `?` (question mark): any single character; for example, sm?th finds "smith" and "smyth"
 *         - `*` (asterisk): any number of characters; for example, *east finds "yeast" and "Southeast"
 *         - `~` (tilde) followed by ?, *, or ~: a question mark, asterisk, or tilde; for example, fy06~? finds "fy06?"
 * Return value (Number):
 *   Numeric position in lookup array
 * @param {CellValueAtom} lookup
 * @param {Matrix | Reference} lookupArray
 * @param {number | null} matchMode
 * @param {number | null} searchMode
 * @returns {number | FormulaError}
 */
export function XMATCH (
  lookup: CellValueAtom,
  lookupArray: Matrix | A1Reference,
  matchMode: number | null,
  searchMode: number | null,
): number | FormulaError {
  if (!lookupArray.is1D()) {
    return ERROR_VALUE;
  }

  const pos = searchXlookupXmatch(searchMode, matchMode, lookup, lookupArray);
  if (isErr(pos)) {
    return pos;
  }

  if (pos < 0 || pos >= lookupArray.size) {
    return ERROR_NA;
  }
  return pos + 1;
}

/**
 * Common part of XLOOKUP and XMATCH
 * @param {number | null} searchMode
 * @param {number | null} matchMode
 * @param {CellValueAtom} lookupValue
 * @param {Reference | Matrix} lookupArray
 */
function searchXlookupXmatch (
  searchMode: number | null,
  matchMode: number | null,
  lookupValue: CellValueAtom,
  lookupArray: A1Reference | Matrix,
) {
  matchMode = matchMode == null ? 0 : Math.floor(matchMode);
  searchMode = searchMode == null ? 1 : Math.floor(searchMode);
  if (matchMode < -1 || matchMode > 2 || ![ 1, -1, 2, -2 ].includes(searchMode)) {
    return ERROR_VALUE;
  }
  if (matchMode === 2 && Math.abs(searchMode) === 2) {
    // We can't perform a binary search on a wildcard match.
    return ERROR_VALUE;
  }

  const values = lookupArray.toMatrix(false);
  if (values instanceof FormulaError) {
    return values;
  }
  const getter = (/** @type {number} */ i: number) => values.getByIndex(i) ?? null;

  let pos = -1;
  if (searchMode === 1 || searchMode === -1) {
    const repeatStart = firstRepeatStart(values);
    if (matchMode === 2) {
      pos = searchWildcardXlookup(lookupValue, lookupArray.size, getter, searchMode > 0, repeatStart);
    }
    else {
      pos = searchExactXlookup(lookupValue, lookupArray.size, getter, searchMode > 0, matchMode, repeatStart);
    }
  }
  else {
    pos = searchBinaryXlookup(lookupValue, lookupArray.size, getter, searchMode > 0, matchMode);
  }
  return pos;
}

/**
 * HYPERLINK(url, [link_label])
 * Creates a hyperlink inside a cell.
 */
export function HYPERLINK (this: EvaluationContext, url: string, link_label?: Matrix | CellValue) {
  // set url to the context cell if we have one (we don't when evaluating options, tests, etc.)
  if (this && this.cell) {
    this.cell.href = String(url);
  }
  // return link_label if present (even if falsy), else url as text
  return link_label ?? url;
}

/**
 * INDEX(area, [row], [column])
 * Returns the content of a cell, specified by row and column offset.
 * @see https://support.office.com/en-us/article/index-function-a5dcf0dd-996d-40a4-a822-b56b061328bd
 */
export function INDEX (area: Matrix | Reference, rowNum: number, colNum: number, areaNum: number): FormulaValue {
  if (rowNum < 0) {
    return ERROR_VALUE.detailed(`Row index out of bounds (${rowNum} < 0)`);
  }
  if (colNum != null && colNum < 0) {
    return ERROR_VALUE.detailed(`Column index out of bounds (${colNum} < 0)`);
  }
  // TODO: We don't support sub-expressions
  if (areaNum != null) {
    return ERROR_VALUE.detailed('INDEX area_num not supported');
  }

  const isMx = isMatrix(area);
  rowNum = Math.floor(rowNum || 0);
  if (colNum == null) {
    if (area.height === 1) {
      // If the range is 1 dimensional and colNum is not provided,
      // row_num becomes an index into whatever dimension range has.
      // In praxis: rowNum works as colNum when that dim is width.
      colNum = rowNum;
      rowNum = 1;
    }
    else {
      if (!isMx && area.width !== 1) {
        // When the column number is missing, this behaviour differs between
        // array and range inputs.
        return ERROR_REF;
      }
      colNum = 0;
    }
  }
  else {
    colNum = Math.floor(colNum);
  }

  if (rowNum > area.height || colNum > area.width) {
    return ERROR_REF;
  }
  if (rowNum === 0 && colNum === 0) {
    // Optimization: Don't reconstruct array/reference if both the row and col
    // numbers are zero.
    return area;
  }

  if (isMx) {
    if (rowNum === 0) {
      return area.collapseToColumn(colNum - 1);
    }
    if (colNum === 0) {
      return area.collapseToRow(rowNum - 1);
    }
    return area.get(colNum - 1, rowNum - 1);
  }
  else {
    return new Reference(area, { dynamic: true }).offset(
      Math.max(rowNum - 1, 0),
      Math.max(colNum - 1, 0),
      rowNum === 0 ? area.height : 1,
      colNum === 0 ? area.width : 1,
    );
  }
}

/**
 * INDIRECT(ref_text, [a1])
 * Returns a cell reference specified by a string.
 */
export function INDIRECT (this: EvaluationContext, ref_text: string, a1?: boolean): Reference | FormulaError {
  if (a1 === false) {
    return ERROR_REF.detailed('INDIRECT with a1=false is not supported');
  }
  return Reference.from(ref_text, { dynamic: true, ctx: this }) || ERROR_REF;
}

/**
 * LOOKUP(search_key, search_range|search_result_array, [result_range])
 * Looks through a sorted row or column for a key and returns the value of the
 * cell in a result range located in the same position as the search row or column.
 */
export function LOOKUP (
  this: EvaluationContext,
  lookupValue: string | number | boolean | Lambda,
  lookupArg: Reference | Matrix | FormulaError,
  resultVector?: Reference | Matrix | null,
): MaybeBoxed<ArrayValue> {
  if (isErr(lookupArg)) {
    return this.mode === MODE_GOOGLE
      ? ERROR_NA.detailed(fmtWithCellValues`Did not find value ${lookupValue} in LOOKUP evaluation`)
      : lookupArg;
  }
  const lookupHorizontal = lookupArg.width > lookupArg.height;
  const lookupVector = lookupHorizontal ? lookupArg.collapseToRow() : lookupArg.collapseToColumn();
  invariant(!isErr(lookupVector), 'collapseToX only returns error if given an arg < 0 or >= width/height');
  const lookupVectorMatrix = lookupVector.toMatrix(false);
  if (isErr(lookupVectorMatrix)) {
    return lookupVectorMatrix;
  }

  const getCellValue = createValueGetter(lookupVectorMatrix, lookupHorizontal);
  const length = lookupHorizontal
    ? Math.min(lookupVectorMatrix.populatedWidth + 1, lookupVectorMatrix.width)
    : Math.min(lookupVectorMatrix.populatedHeight + 1, lookupVectorMatrix.height);

  const pos = searchBinary(lookupValue, length, getCellValue);
  if (pos < 0 || pos >= length) {
    return ERROR_NA;
  }
  invariant(length > 0); // if it were 0, searchBinary would find nothing and return -1, so we'd return ERROR_NA above
  if (resultVector == null) {
    const collapsed = lookupHorizontal
      ? lookupArg.collapseToRow(lookupArg.height - 1)
      : lookupArg.collapseToColumn(lookupArg.width - 1);
    invariant(!isErr(collapsed), 'collapseToX only returns error if given an arg < 0 or >= width/height');
    resultVector = collapsed;
  }
  else if (resultVector.width !== 1 && resultVector.height !== 1) {
    return ERROR_NA;
  }
  const resultHorizontal = resultVector.width >= resultVector.height;
  const resultLength = resultHorizontal ? resultVector.width : resultVector.height;
  if (pos >= resultLength) {
    if (isRef(resultVector)) {
      let rangeArg;
      if (resultHorizontal) {
        rangeArg = {
          top: resultVector.top,
          left: resultVector.left,
          bottom: resultVector.top,
          right: resultVector.left + length - 1,
        };
      }
      else {
        rangeArg = {
          top: resultVector.top,
          left: resultVector.left,
          bottom: resultVector.top + length - 1,
          right: resultVector.left,
        };
      }
      // we know length is an integer > 0, so we know rangeArg cannot be invalid
      resultVector = resultVector.withRange(rangeArg);
    }
    else {
      // Matrix
      return ERROR_NA;
    }
  }
  const resultCellRefOrMatrix = resultVector.collapseToNthCell(pos);
  if (isRef(resultCellRefOrMatrix)) {
    const resultCell = resultCellRefOrMatrix.resolveCell();
    if (resultCell == null) {
      return resultCell;
    }
    if (this.isDirtyFormulaCell?.(resultCell)) {
      throw new EvaluationOrderException('unhandled dynamic dependency', resultCellRefOrMatrix);
    }
    // resultCell is not a defined name so we can be sure its value is not a
    // reference or matrix or lambda
    invariant(!isRef(resultCell.v) && !isMatrix(resultCell.v) && !isLambda(resultCell.v));
    return box(resultCell.v, { numberFormat: resultCell.z });
  }
  else if (isErr(resultCellRefOrMatrix)) {
    return resultCellRefOrMatrix;
  }
  else {
    return resultCellRefOrMatrix.resolveSingleBoxed();
  }
}

/**
 * MATCH(search_key, range, [search_type])
 * Returns the relative position of an item in a range that matches a specified value.
 */
export function MATCH (
  this: EvaluationContext,
  needle: CellValueAtom | Lambda,
  range: Reference | Matrix | number | Lambda,
  search_type?: Reference | number | null,
) {
  if (!((isRef(range) && range.isAddress) || isMatrix(range)) || (range.height !== 1 && range.width !== 1)) {
    return ERROR_NA;
  }

  const searchTypeNum = search_type == null ? 1 : toNum(search_type);
  if (isErr(searchTypeNum)) {
    return searchTypeNum;
  }

  const matrix = range.toMatrix(false);
  if (isErr(matrix)) {
    // Excel returns #N/A in this case; in other modes, do like other functions
    return this.mode === MODE_EXCEL ? ERROR_NA : matrix;
  }

  const getCellValue = (i: number) => matrix.getByIndex(i);
  let pos: number;
  if (searchTypeNum === 0) {
    pos = searchExact(needle, firstRepeatStart(matrix), getCellValue);
  }
  else if (searchTypeNum === 1) {
    pos = searchBinary(needle, matrix.size, getCellValue, firstRepeatStart(matrix));
  }
  else {
    pos = searchExactDesc(needle, matrix.size, getCellValue, firstRepeatStart(matrix));
  }
  return pos < 0 ? ERROR_NA : pos + 1;
}

/**
 * OFFSET(reference, rows, cols, [height], [width])
 * Returns a range reference shifted a specified number of rows and columns from a starting cell reference.
 */
export function OFFSET (
  ref: A1Reference,
  rows: number | null | undefined,
  cols: number | null | undefined,
  height?: number | null,
  width?: number | null,
): A1Reference | FormulaError {
  if (rows == null) {
    rows = 0;
  }
  if (cols == null) {
    cols = 0;
  }
  if (height == null) {
    height = ref.height;
  }
  if (width == null) {
    width = ref.width;
  }
  rows = Math.trunc(rows);
  cols = Math.trunc(cols);
  // Follow oddball Excel handling of height/width in range ]-1,1[
  if (height < 0 && height > -1) {
    height = 2;
  }
  else if (height > 0 && height < 1) {
    height = -2;
  }
  else {
    height = Math.trunc(height);
  }
  if (width < 0 && width > -1) {
    width = 2;
  }
  else if (width > 0 && width < 1) {
    width = -2;
  }
  else {
    width = Math.trunc(width);
  }
  // size args can be negative numbers which then adjust positioning
  if (height < 0) {
    rows = height + rows + 1;
    height = -height;
  }
  if (width < 0) {
    cols = width + cols + 1;
    width = -width;
  }
  // shift R by [rows, cols] arguments and crop/expand range to [width, height]

  try {
    return Reference.from(ref, { dynamic: true })?.offset(rows, cols, height, width) || ERROR_REF;
  }
  catch {
    return ERROR_REF;
  }
}

/**
 * ROW([cell_reference])
 * Return the row number of a specified cell, or of the sheet cell in which the
 * formula is evaluated. If passed a range reference, return a 1-column-wide
 * matrix of the row numbers of the referenced cells.
 * @param {Reference} cellRef the specified cell or matrix, or the context cell.
 *   Note: this parameter is optional in formulas, but we special-case the
 *   no-argument case in call handling, passing a reference to the current sheet
 *   cell. So this function _implementation_ always receives a reference.
 * @returns {number | Matrix}
 */
export function ROW (cellRef: A1Reference): number | Matrix {
  if (cellRef.height === 1) {
    return cellRef.range.top + 1;
  }
  // return a new 1-dimensional matrix of row numbers
  const r = new Matrix();
  for (let i = 0; i < cellRef.height; i++) {
    r.set(0, i, cellRef.range.top + 1 + i);
  }
  return r;
}

/**
 * ROWS(range)
 * Returns the number of rows in a specified array or range.
 * @param {Matrix | Reference} range
 */
export function ROWS (range: Matrix | Reference) {
  return range.height;
}

// RTD(???)
// The real time data from a program that supports COM.
export function RTD () {
  return null;
}

/**
 * TRANSPOSE(array)
 * Transposes the rows and columns of an array or range of cells.
 * @param {Matrix | string | number | boolean} array
 * @returns {Matrix}
 */
export function TRANSPOSE (array: Matrix | string | number | boolean): Matrix {
  if (!isMatrix(array)) {
    return Matrix.of(array);
  }
  return Matrix.ofTransposed(array);
}

/**
 * Find index of first repeated element that will never be followed by a non
 * repeated element when we are iterating rows-first (right, then down).
 * @param {Matrix} matrix
 * @return {number}
 */
function firstRepeatStart (matrix: Matrix): number {
  if (matrix.populatedHeight < matrix.height) {
    return matrix.width * matrix.populatedHeight;
  }
  if (matrix.populatedWidth < matrix.width) {
    return matrix.width * (matrix.populatedHeight - 1) + matrix.populatedWidth;
  }
  return matrix.size - 1;
}
