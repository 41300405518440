export { default as Model, modelFromData } from './Model';
export { findVertexIdsNeedingInitRecalc } from './initRecalc';
export { default as Workbook } from './Workbook';
export { default as WorkSheet } from './WorkSheet';
export { default as Reference, isRef, isA1Ref, isNameRef, checkA1 } from './excel/Reference';
export type { A1Reference, NameReference } from './excel/Reference';
export type Sheet = import('./WorkSheet').default;

import {
  ready as formulaParserReady,
  type ReplaceSheetReferencesFn,
  type ReplaceTableReferencesFn,
  type ReplaceWorkbookReferencesFn,
} from './excel/formulaParser';
export {
  parseFormula,
  ready as formulaParserReady,
  type ReplaceSheetReferencesFn,
  type ReplaceWorkbookReferencesFn,
} from './excel/formulaParser';

export { default as Range } from './excel/referenceParser/Range.js';
export { parse as parseReference } from './excel/referenceParser';
export { offsFromCol, colFromOffs, a1ToRowColumn } from './excel/referenceParser/a1.js';

export { default as ModelError } from './ModelError';
export { default as FormulaError } from './excel/FormulaError';
export { default as Matrix } from './excel/Matrix';
export { default as Cell } from './excel/Cell';
export { Lambda } from './excel/lambda';
export { default as ValueBox, unbox, isBoxed, type MaybeBoxed } from './excel/ValueBox';

export { default as functions, loadLazy } from './excel/functions';

export { default as wbNameToKey } from './wbNameToKey.js';
import evaluateFormula from './excel/evaluate';
import type { EvaluationContext } from './excel/EvaluationContext';
export { hasCellRoot, getRootsAndDependents } from './workbookRootsAndDependents';
export { type WorkbookRootsAndDependents } from './types';
export { constructCellID } from './utils';
export { functionSignatures } from './excel/functions';
export { toNum } from './excel/functions/utils';
export { parseDateTime } from './excel/functions/utils-number';

export { describeWorkbook } from './describeWorkbook';

export { flags } from './Flags';

export {
  ERROR_NAME,
  ERROR_VALUE,
  ERROR_REF,
  ERROR_DIV0,
  ERROR_NULL,
  ERROR_NUM,
  ERROR_NA,
  ERROR_GETDATA,
  ERROR_SPILL,
  ERROR_UNKNOWN,
  ERROR_FIELD,
  ERROR_CALC,
  ERROR_SYNTAX,
  MAX_COL,
  MAX_ROW,
} from './excel/constants';

export { MODE_GRID_SHEET, MODE_GOOGLE, MODE_EXCEL, MODE_CANVAS, MODE_ALL } from './mode';

export type { ModelEntity } from './modelEntities';

export function run (fx: string, rawOutput: boolean, resolver: EvaluationContext) {
  return evaluateFormula(fx, { ...resolver, rawOutput });
}

/**
 * Make a function that replaces workbook names in formulas in a syntactically
 * sound way. That means:
 * (a) it will only affect occurrences of the previous name in reference
 *     prefixes (not e.g. in string literals)
 * (b) it takes quoting into account, including changing references to/from
 *     quoted form as appropriate.
 * (c) it matches workbook names case-insensitively.
 *
 * This is an async factory for such a function because we need to dynamically
 * import the waspiary module which is the actual implementation. The returned
 * function is synchronous.
 *
 * The returned function throws `FormulaSyntaxError` if the given `formula` is
 * not parseable to begin with.
 */
export const getReplaceWorkbookFn = async (): Promise<ReplaceWorkbookReferencesFn> => {
  const formulaParser = await formulaParserReady;
  return formulaParser.replaceWorkbookReferences;
};

/**
 * Make a function that replaces sheet names in formulas in a syntactically
 * sound way. That means:
 * (a) it will only affect occurrences of the previous name in reference
 *     prefixes (not e.g. in string literals), and only in those reference
 *     prefixes that have the given workbook name, or (if `includeNoWb` is true)
 *     have no workbook name.
 * (b) it takes quoting into account, including changing references to/from
 *     quoted form as appropriate.
 * (c) it matches sheet names and workbook names case-insensitively.
 *
 * This is an async factory for such a function because we need to dynamically
 * import the waspiary module which is the actual implementation. The returned
 * function is synchronous.
 *
 * The returned function throws `FormulaSyntaxError` if the given `formula` is
 * not parseable to begin with.
 */
export const getReplaceSheetReferencesFn = async (): Promise<ReplaceSheetReferencesFn> => {
  const formulaParser = await import('./excel/formulaParser/parser.js');
  return formulaParser.replaceSheetReferences;
};

/**
 * Make a function that replaces table names in structured references in
 * formulas in a syntactically sound way. That means:
 * (a) it will only affect occurrences of the previous table name in structured
 *     references, and only in those structured references which have the given
 *     workbook name, or (if `includeNoWb` is true) have no workbook name.
 * (c) it matches workbook names and table names case-insensitively.
 *
 * This is an async factory for such a function because we need to dynamically
 * import the waspiary module which is the actual implementation. The returned
 * function is synchronous.
 *
 * The returned function throws `FormulaSyntaxError` if the given `formula` is
 * not parseable to begin with.
 */
export const getReplaceTableReferencesFn = async (): Promise<ReplaceTableReferencesFn> => {
  const formulaParser = await import('./excel/formulaParser/parser.js');
  return formulaParser.replaceTableReferences;
};

export type {
  CellValue,
  CellValueAtom,
  ArrayValue,
  FormulaValue,
  MaybeBoxedFormulaValue,
  FormulaArgument,
  MaybeBoxedFormulaArgument,
  NonMatrixFormulaArgument,
  TYPE_NONE,
  TYPE_ERROR,
  TYPE_BLANK,
  TYPE_MISSING,
  TYPE_NUM,
  TYPE_BOOL,
  TYPE_STRING,
  TYPE_RANGE,
  TYPE_ARRAY,
  TYPE_LAMBDA,
  TYPE_ALL,
  IterationOptions,
} from './excel/types';
export type {
  AxisCSF,
  CellCSF,
  ChartCSF,
  CloudConnection,
  ColumnInfo,
  DrawingCSF,
  SheetCSF,
  TableCSF,
  TableColumnCSF,
  WorkbookBody,
  WorkbookType,
} from './csf';
export type { EvaluationContext } from './excel/EvaluationContext';
export type { AreaArray, AreaCellArray, AreaValueArray, AreaBoxedValueArray } from './AreaArray';
export type { SlimRange, SlimRangeOrCoords } from './excel/referenceParser/Range';
