/* eslint-disable no-console */
import { isDateFormat } from 'numfmt';
import { translateToR1C1 } from '@borgar/fx';
import { chain, range } from '@grid-is/iterators';
import {
  CellVertexId,
  NameVertexId,
  RangeVertexId,
  type KnownVertexId,
  VertexId,
  DependencyGraph,
  Vertex,
  type Edge,
} from './DependencyGraph';
import type WorkSheet from './WorkSheet';
import type Workbook from './Workbook';
import {
  cellToVertexId,
  formulaCellsForVertexId,
  getFormulaCellsInDependencies,
  referenceToVertexId,
  vertexIdToCell,
  vertexIdToReference,
  visitFormulaCellsForVertexId,
} from './dep-graph-helpers';
import Cell from './excel/Cell';
import Reference, { type A1Reference } from './excel/Reference';
import { isNonLiteralNode } from './excel/ast';
import type { ASTRootNode } from './excel/ast-types';
import { TYPE_TO_STR, valueToType } from './excel/coerce';
import { evaluateStaticReferenceASTNodeUnbound } from './excel/evaluate';
import { invariant } from './validation';
import { isBool, isErr, isNum, isStr } from './typeguards';
import Range from './excel/referenceParser/Range';
import type Model from './Model';
import { DefaultMap, LinkedList } from '@grid-is/collections';
import { getRootsAndLeaves, isNontriviallyReferenced } from './describeWorkbook-graph';
import RTree, { type BBox } from './algorithm/rtree';
import { boundingBoxToRange } from './Cells';
import {
  islandsAndLabels,
  findLikelyLabels,
  formatCellValue,
  isFixedIntervalSequence,
  type Label,
  type Labeled,
  type Island,
} from './label-detection';
import { boundingBox } from './excel/evaluate-common';

type SheetDescription = {
  name: string,
  hidden: boolean,
  cells: number,
  formulas: number,
  islands: Array<Island>,
  labels: Label[],
  contents: string[],
};

type FormulaDescription = {
  type: 'formula' | 'reference' | 'literal',
  description: string | null,
};

type DefinedNameDescription = FormulaDescription & {
  name: string,
};

/**
 * A single non-formula cell that is depended on by some formula and does not
 * appear to be part of a data range, so likely makes sense to apply a value to.
 */
export type Parameter = Labeled & {
  /** The cell may be null if the cell address is depended on but is not populated in the workbook */
  cell: Cell | null,
  /** More specific type than in `Labeled`, as parameters are sheet cells */
  vertexId: CellVertexId,
};

/**
 * Type of a node in the chunked dependency graph:
 * output: a computed cell or range that nothing depends on
 * inter: a computed cell or range that _is_ depended on
 * data: a range of cells that are depended on, but do not seem to be parameters
 * param: a single cell that is depended on and does seem to be a parameter (not part of a data range)
 */
type ChunkType = 'output' | 'inter' | 'data' | 'param';

/**
 * A node in the chunked dependency graph.
 *
 * The root height is the smallest number of dependency steps by which this node
 * depends on a root of the chunked graph. So a parameter or data node has root
 * height 0, computed results depending directly on it have root height 1, etc.
 *
 * The leaf depth is the smallest number of dependency steps by which any leaf
 * of the chunked graph depends on this node. So an output node (one with no
 * dependents) has leaf depth 0, its direct dependencies have leaf depth 1, etc.
 *
 * Note that in the chunked graph, a sequence of formula cells that depend
 * iteratively one on the next can be chunked together, so “dependency steps”
 * can get collapsed together, and the root height and leaf depth of a chunk
 * will be the minimum across all cells in the chunk.
 */
export type ChunkNode = Labeled & { type: ChunkType, rootHeight?: number, leafDepth?: number };

type ChunkedGraphLayer = { formulaChunks: Labeled[], nonFormulaChunks: Labeled[] };

type Which = 'parameters' | 'chunkedGraph' | 'calculated' | 'summary';
export type DescriptionOptions = {
  which: ReadonlyArray<Which>,
  recognize2dTables: boolean,
};
const DEFAULTS: DescriptionOptions = {
  which: [ 'parameters', 'chunkedGraph', 'calculated', 'summary' ],
  recognize2dTables: false,
};

export class WorkbookDescription {
  workbook: Workbook;
  totalFormulas: number;
  sheets: SheetDescription[];
  names: DefinedNameDescription[];
  calculated: ChunkNode[] = [];
  parameters: Parameter[];
  // TODO: probably get rid of this, it was just Birkir's shortcut to work with the description in the SpAI prototype
  summary: ReturnType<WorkbookDescription['setSummary']>;

  private cellDependsOnNontrivialPartOfModel: (cell: Cell) => boolean;
  private rangeMemo = new Map<string, Cell[]>();
  private sheetToDataRegions = new DefaultMap<number, RTree>(() => new RTree());
  private _likelyLabels = new Map<string, ReadonlyArray<Label>>();
  chunkedGraph: DependencyGraph<ChunkNode, KnownVertexId>;
  cellR1C1formula = new DefaultMap((c: Cell) => (c.f ? translateToR1C1(c.f, c.id, { xlsx: true }) : null));
  labels: Array<Label>;
  options: DescriptionOptions;

  constructor (wb: Workbook, options?: Partial<DescriptionOptions>) {
    this.workbook = wb;
    this.options = { ...DEFAULTS, ...options };
    const { which } = this.options;
    this.sheets = this.describeSheets();
    this.names = this.describeDefinedNames();
    this.totalFormulas = this.sheets.reduce((sum, { formulas }) => sum + formulas, 0) + this.names.length;
    const model = wb._model;
    const rangeMemo = new Map<string, Cell[]>();
    // XXX change min-dep filtering to act on the chunked graph, not per cell
    // ... and/or possibly just _rank_ (not filter) by dependency footprint
    const minDeps = 1;
    const cellDepMemo: DefaultMap<Cell, boolean> = new DefaultMap(cell => {
      return dependsOnAtLeast(cellDepMemo, rangeMemo, model, cell, minDeps);
    });
    this.cellDependsOnNontrivialPartOfModel = cellDepMemo.get.bind(cellDepMemo);
    const { roots, leaves } = getRootsAndLeaves(wb);
    this.parameters = which.includes('parameters') ? this.identifyParameters(roots) : [];
    const graphOrCalculated = which.includes('chunkedGraph') || which.includes('calculated');
    this.chunkedGraph = graphOrCalculated ? this.buildChunkedGraph(leaves) : new DependencyGraph();
    this.calculated = which.includes('calculated') ? this.identifyCalculated() : [];
    this.labels = this.mergeLabels(this.sheets, this.parameters, this.calculated);
    this.summary = which.includes('summary') ? this.setSummary() : null;
  }

  private mergeLabels (sheets: SheetDescription[], parameters: Parameter[], outputs: ChunkNode[]): Label[] {
    const labelTextOccurrences = new DefaultMap<string, Label[]>(() => []);
    const labelCellAddressToLabels = new Map<string, Label>();
    const labels = sheets
      .flatMap(sheet => sheet.labels)
      .concat(parameters.flatMap(p => p.labels))
      .concat(outputs.flatMap(o => [ ...o.labels, ...this.labelsFromIsland(o.ref) ]))
      .reduce((acc, label) => {
        const labelCellAddress = String(label.at);
        const firstLabel = labelCellAddressToLabels.get(labelCellAddress);
        if (firstLabel) {
          invariant(firstLabel.text === label.text);
          invariant(firstLabel.type === label.type);
          // XXX: Assign confidence scores? Address overlaps in some other way?
          firstLabel.for = mergeLabeledRanges(firstLabel.for, label.for);
        }
        else {
          labelCellAddressToLabels.set(labelCellAddress, label);
          labelTextOccurrences.get(label.text).push(label);
          acc.push(label);
        }
        return acc;
      }, [] as Label[]);
    return labels;
  }

  private labelsFromIsland (ref: A1Reference) {
    const island = this.sheets.find(s => s.name === ref.sheetName)?.islands.find(i => i.range.contains(ref));
    return island?.labelsFor(ref) || [];
  }

  private identifyCalculated (): ChunkNode[] {
    const chunks = formulaChunks(this.workbook, this.chunkedGraph);
    return filterCalculated(this.workbook, chunks);
  }

  toString () {
    const wb = this.workbook;
    const sheetLines = this.sheets
      .map(({ name, contents, hidden }) => {
        const maybeHidden = hidden ? '(hidden) ' : '';
        return `* ${maybeHidden}"${name}" with ${contents.filter(c => !!c).join(', ')}`;
      })
      .join('\n');
    let description = `The ${wb.type} spreadsheet "${wb.name}" contains these sheets:

${sheetLines}`;
    if (this.names.length) {
      description += `

and these defined names:

${this.names.map(({ name, description }) => `* "${name}": ${description}`).join('\n')}
`;
    }

    const parametersDescription = !this.parameters.length
      ? 'no apparent inputs '
      : `${this.parameters.length} apparent inputs:\n\n` +
        this.parameters
          .map(({ labels, cell, vertexId }) => {
            const labelsRepr = labels.map(this.labelString).join(', ');
            const refRepr = this.cellReference(vertexId);
            const valueRepr = formatCellValue(cell, this.workbook);
            return `* ${labelsRepr}: ${refRepr} with value ${valueRepr}`;
          })
          .join('\n');
    const calculatedDescription = !this.calculated.length
      ? 'no apparent calculated values/ranges'
      : `${this.calculated.length} apparent calculated values/ranges:\n\n` +
        this.calculated
          .map(({ labels, ref }) => {
            const labelsRepr = labels.map(this.labelString).join(', ');
            const rangeSizeRepr = ref.range && ref.size! > 1 ? `${ref.height}x${ref.width} ` : '1x1';
            return `* ${labelsRepr}: with size ${rangeSizeRepr} and reference to range ${ref}`;
          })
          .join('\n');
    const joiningSpace = calculatedDescription.includes('\n') ? '\n\n' : ' ';
    description += `
It has ${calculatedDescription}${joiningSpace}and ${parametersDescription}`;
    return description;
  }

  setSummary () {
    const wb = this.workbook;
    if (wb.name === 'GRID Sheet') {
      return null;
    }
    const parameters = this.parameters.map(({ labels, cell, vertexId }) => {
      let type = 'input';
      if (cell == null || cell.v == null) {
        // XXX guess the type more intelligently based on whether formulas use the cell in a string/boolean context
        // ... e.g.
        // `IF(A1, ..., ...)` => expects boolean, so checkbox
        // `=SUBSTITUTE(A1, ...)` => expects string, so input
        type = 'slider';
      }
      else if (isNum(cell.v)) {
        type = 'slider';
      }
      else if (isBool(cell.v)) {
        type = 'checkbox';
      }
      const ref = this.cellReference(vertexId);
      const refString = String(ref);
      return {
        labels: labels.map(this.labelString),
        reference: refString,
        referenceLabel: refString,
        value: formatCellValue(cell, wb),
        type: type,
      };
    });
    const hasMultipleSheets = this.workbook._sheets.length > 1;
    const calculated = this.calculated.map(({ labels, ref }) => {
      if (!hasMultipleSheets) {
        ref = ref.withPrefix({ sheetName: '' }) as A1Reference; // withPrefix returns same subtype so cast is safe
      }
      const refString = String(ref);
      return {
        labels: labels.map(this.labelString),
        referenceLabel: refString,
        reference: refString,
        type: ref.range && ref.range.size > 1 ? 'line' : 'text',
      };
    });

    const sheetLines = this.sheets
      .map(({ name, contents, hidden }) => {
        const maybeHidden = hidden ? '(hidden) ' : '';
        return `* ${maybeHidden}"${name}" with ${contents.filter(c => !!c).join(', ')}`;
      })
      .join('\n');
    let description = `The ${wb.type} workbook "${wb.name}" contains these sheets:

${sheetLines}`;
    if (this.names.length) {
      description += `

and these defined names:

${this.names.map(({ name, description }) => `* "${name}": ${description}`).join('\n')}`;
    }

    return { description, parameters, calculated, wbId: wb.id };
  }

  extractAllText () {
    const textValues = new Set<string>();
    for (const sheet of this.workbook.getSheets()) {
      for (const textValue of iterTextValues(sheet)) {
        textValues.add(textValue);
      }
    }
    return textValues;
  }

  /**
   * Precondition: this.parameters must have been populated already
   */
  private buildChunkedGraph (leaves: Cell[]) {
    const leafChunks = this.consolidateAndLabel(leaves);
    const chunkedGraph = new DependencyGraph<ChunkNode, KnownVertexId>();
    let prevLayer: ChunkedGraphLayer = { formulaChunks: leafChunks, nonFormulaChunks: [] };
    let leafDepth = 0;
    const formulaChunksDone = new Set<string>(); // Vertex ID keys
    while (prevLayer.formulaChunks.length > 0) {
      const thisLayer: ChunkedGraphLayer = { formulaChunks: [], nonFormulaChunks: [] };
      for (const lab of prevLayer.formulaChunks) {
        const { formulaChunks, nonFormulaChunks } = this.depChunks(lab);
        for (const formulaChunk of formulaChunks) {
          if (formulaChunk.vertexId.key === lab.vertexId.key) {
            // ignore self-dependencies for formula chunks
            continue;
          }
          chunkedGraph.addDependency(
            lab.vertexId,
            formulaChunk.vertexId,
            'regular',
            null,
            { ...lab, leafDepth, type: leafDepth === 0 ? 'output' : 'inter' },
            { ...formulaChunk, leafDepth: leafDepth + 1, type: 'inter' },
          );
          if (!formulaChunksDone.has(formulaChunk.vertexId.key)) {
            thisLayer.formulaChunks.push(formulaChunk);
            formulaChunksDone.add(formulaChunk.vertexId.key);
          }
        }
        for (const nonFormulaChunk of nonFormulaChunks) {
          chunkedGraph.addDependency(
            lab.vertexId,
            nonFormulaChunk.vertexId,
            'regular',
            null,
            { ...lab, leafDepth, type: leafDepth === 0 ? 'output' : 'inter' },
            { ...nonFormulaChunk, type: 'param' },
          );
          thisLayer.nonFormulaChunks.push(nonFormulaChunk);
        }
      }
      if (prevLayer.formulaChunks.length || prevLayer.nonFormulaChunks.length) {
        leafDepth += 1;
      }
      prevLayer = thisLayer;
    }
    populateRootHeights(this.workbook.keyInDepGraph, chunkedGraph);
    return chunkedGraph;
  }

  makeLabel (lab: Labeled): string {
    return lab.labels.map(this.labelString).join(', ') || this.describeValues(lab) || '?';
  }

  describeValues (lab: Labeled): string | undefined {
    const { ref, vertexId } = lab;
    if (vertexId instanceof CellVertexId) {
      const cell = vertexIdToCell(this.workbook, vertexId);
      if (cell?.v != null) {
        return formatCellValue(cell, this.workbook);
      }
    }
    else if (vertexId instanceof NameVertexId) {
      return vertexId.name;
    }
    else {
      const values = ref.withContext(this.workbook).resolveRange();
      if (Array.isArray(values) && values.length >= 3) {
        if (isFixedIntervalSequence(values)) {
          const [ first, last ] = [ values[0], values[values.length - 1] ];
          return `Numbers ${first} – ${last}`;
        }
      }
    }
  }

  private identifyParameters (relevantRoots: CellVertexId[]): Parameter[] {
    const wb = this.workbook;
    const rootCellsNotInDataRegions = getRelevantNonDataRoots(relevantRoots, wb);
    const parameters = chain(rootCellsNotInDataRegions)
      .filter(vertexId => {
        const sheet = wb._model.getWorkbookByKey(vertexId.workbookKey)?.getSheetByIndex(vertexId.sheetIndex);
        if (!sheet) {
          // Sheet not found, so can't check for apparent data range, so treat as a parameter
          return true;
        }
        const sheetSize = sheet.getSize();
        const [ sheetWidth, sheetHeight ] = sheetSize;
        if (vertexId.colIndex >= sheetWidth || vertexId.rowIndex >= sheetHeight) {
          // Out of sheet bounds, so blank and not data, so treat as a parameter
          return true;
        }
        if (this.isInDataRegion(vertexId)) {
          return false;
        }
        const candidateCell = vertexIdToCell(wb._model, vertexId);
        const valueType = cellValueType(candidateCell);
        const rect = expandRectangle(vertexId, sheetSize, (neighborX, neighborY) => {
          const neighborCell = sheet.getCellByCoords(neighborY, neighborX);
          const neighborVertexId = vertexId.withCoordinates(neighborX, neighborY);
          return (
            !neighborCell?.f &&
            (candidateCell?.v == null || neighborCell?.v == null || cellValueType(neighborCell) === valueType) &&
            !this.isInDataRegion(neighborVertexId) &&
            wb._model._graph.isDependedOn(neighborVertexId)
          );
        });
        // A small range of (value) non-formula cells may still be parameters.
        // But if it is a 1-dimensional range of at least four fixed-interval
        // integers, that's probably an index sequence (years or 1, 2, 3, ...)
        // that doesn't make sense for a user to tweak, so not parameters.
        if (isRangeSmallEnoughToStillMaybeContainParameters(rect) && !isFixedIntervalRange(rect, sheet, wb)) {
          return true;
        }
        this.recordDataRegion(vertexId.sheetIndex, rect.toBoundingBox());
        return false;
      })
      .map(vertexId => ({
        labels: this.likelyLabels(vertexId),
        cell: vertexIdToCell(wb._model, vertexId),
        vertexId,
        ref: vertexIdToReference(wb._model, vertexId)!.withPrefix({ workbookName: '' }),
      }))
      .filter((arg): arg is Parameter => arg.labels.length > 0)
      .toArray();
    parameters.sort(
      ({ vertexId: a }, { vertexId: b }) =>
        a.sheetIndex - b.sheetIndex || a.rowIndex - b.rowIndex || a.colIndex - b.colIndex,
    );
    return parameters;
  }

  likelyLabels (vertexId: KnownVertexId): ReadonlyArray<Label> {
    const memoized = this._likelyLabels.get(vertexId.key);
    if (memoized) {
      return memoized;
    }
    const labels = findLikelyLabels(this.workbook, vertexId);
    this._likelyLabels.set(vertexId.key, labels);
    return labels;
  }

  private recordDataRegion (sheetIndex: number, bbox: BBox) {
    this.sheetToDataRegions.get(sheetIndex).insert(bbox);
  }

  private findDataRegion (vertexId: CellVertexId): Range | undefined {
    const { sheetIndex, colIndex: minX, rowIndex: minY } = vertexId;
    const dataRegionsInSheet = this.sheetToDataRegions.get(sheetIndex);
    const bbox = dataRegionsInSheet.search({ minX, maxX: minX, minY, maxY: minY })[0];
    if (bbox) {
      return boundingBoxToRange(bbox);
    }
  }

  private isInDataRegion (vertexId: CellVertexId) {
    const { sheetIndex, colIndex: minX, rowIndex: minY } = vertexId;
    const dataRegionsInSheet = this.sheetToDataRegions.get(sheetIndex);
    return dataRegionsInSheet.collides({ minX, maxX: minX, minY, maxY: minY });
  }

  /**
   * XXX Precondition: this.parameters must have been populated already
   */
  private depChunks (lab: Labeled): ChunkedGraphLayer {
    const { formulaCells, nonFormulaCellsOrVertexIdKeys } = directDependencyCellsNotIn(
      this.rangeMemo,
      this.workbook._model,
      [ lab ],
    );
    const formulaChunks = this.consolidateAndLabel(formulaCells);
    const nonFormulaChunks: Labeled[] = [];
    for (const nonFormulaCell of [ ...nonFormulaCellsOrVertexIdKeys ]) {
      const matchingParameter = this.parameters.find(p => p.cell === nonFormulaCell);
      if (matchingParameter) {
        nonFormulaChunks.push(matchingParameter);
        nonFormulaCellsOrVertexIdKeys.delete(nonFormulaCell);
      }
    }
    nonFormulaChunks.push(...this.consolidateAndLabel(nonFormulaCellsOrVertexIdKeys));
    return { formulaChunks, nonFormulaChunks };
  }

  /**
   * Join neighboring cells in the indicated set of cells into chunks and
   * identify labels for them.
   * @param cellsOrVertexIdKeys the cells or cell addresses to act on. Strings
   *   are vertex ID keys for cell addresses that are not populated in the model
   *   (so the cells are blank, and may or may not be outside sheet bounds);
   *   these may still be treated as parameters, but if they are outside sheet
   *   bounds, they will not be chunked.
   */
  private consolidateAndLabel (cellsOrVertexIdKeys: Iterable<Cell | string>): Labeled[] {
    const wb = this.workbook;
    const singleCells = new Set<Cell | string>(cellsOrVertexIdKeys);
    const labels = new DefaultMap<string, ReadonlyArray<Label>>(() => []);
    for (const cellOrVertexIdKey of cellsOrVertexIdKeys) {
      if (!singleCells.has(cellOrVertexIdKey)) {
        // Already consolidated into some range
        continue;
      }
      const { vid, cell } =
        typeof cellOrVertexIdKey === 'string'
          ? { vid: VertexId.fromKey(cellOrVertexIdKey) as CellVertexId, cell: null }
          : { vid: cellToVertexId(cellOrVertexIdKey), cell: cellOrVertexIdKey };
      if (vid instanceof NameVertexId) {
        continue;
      }
      const cellR1C1formula = cell?.f ? this.cellR1C1formula.get(cell) : cell;
      const sheet = wb.getSheetByIndex(vid.sheetIndex);
      invariant(sheet);
      const sheetSize = sheet.getSize();
      const range =
        this.findDataRegion(vid) ||
        expandRectangle(vid, sheetSize, (x, y) => {
          // only expand within same row or column
          if (y !== vid.rowIndex && x !== vid.colIndex) {
            return false;
          }
          // expand to neighboring cell if it is also a leaf, _or_ if it has the same formula, adjusted for relative refs
          const otherCell = sheet.getCellByCoords(y, x, false, false);
          if (
            otherCell &&
            ((cellR1C1formula && otherCell.f && this.cellR1C1formula.get(otherCell) === cellR1C1formula) ||
              (!cellR1C1formula && !otherCell.f && singleCells.has(otherCell)))
          ) {
            singleCells.delete(otherCell);
            return true;
          }
          return false;
        });
      singleCells.delete(cellOrVertexIdKey);
      const ref = new Reference(range, { sheetName: sheet.name });
      const rangeVertexId = referenceToVertexId(wb._model, ref);
      const rangeLabels = this.likelyLabels(rangeVertexId);
      invariant(rangeVertexId instanceof CellVertexId || rangeVertexId instanceof RangeVertexId);
      if (rangeVertexId instanceof CellVertexId) {
        singleCells.delete(vertexIdToCell(wb._model, rangeVertexId)!);
      }
      labels.set(rangeVertexId.key, rangeLabels);
    }
    for (const cell of singleCells) {
      if (cell instanceof Cell && this.cellDependsOnNontrivialPartOfModel(cell)) {
        const cellVertexId = cellToVertexId(cell);
        const cellLabels = this.likelyLabels(cellVertexId);
        const staticRef = evaluateStaticReferenceASTNodeUnbound.call(wb, cell._ast);
        const vertexId = staticRef
          ? referenceToVertexId(wb._model, staticRef.withPrefix({ workbookName: wb.name }))
          : cellVertexId;
        const existingLabels = labels.get(vertexId.key);
        const labelStringLower = (existingLabel: Label) => this.labelString(existingLabel).toLowerCase();
        labels.set(vertexId.key, [
          ...existingLabels,
          ...cellLabels.filter(
            label => !existingLabels.some(existing => labelStringLower(existing).includes(labelStringLower(label))),
          ),
        ]);
      }
    }
    return chain(labels.entries())
      .map(([ idKey, labels ]): Labeled => {
        const vertexId = VertexId.fromKey(idKey);
        const ref = vertexIdToReference(wb._model, vertexId)!.withPrefix({ workbookName: '' }) as A1Reference;
        const cell = vertexIdToCell(wb._model, vertexId instanceof RangeVertexId ? vertexId.topLeft() : vertexId);
        return { labels, vertexId, ref, formula: cell?.f };
      })
      .toArray()
      .sort(compareByVertexIdLocation);
  }

  cellReference (vertexId: CellVertexId | NameVertexId) {
    const hasMultipleSheets = this.workbook._sheets.length > 1;
    const prefix = hasMultipleSheets ? { workbookName: '' } : { workbookName: '', sheetName: '' };
    return vertexIdToReference(this.workbook._model, vertexId)?.withPrefix(prefix);
  }

  getCellLabel (cellOrRef: Cell | Reference) {
    const calculatedOrParameter = this.getCalculated(cellOrRef) || this.getParameter(cellOrRef);
    if (!calculatedOrParameter) {
      return '';
    }
    return calculatedOrParameter.labels.map(this.labelString).join(',');
  }

  getParameter (cellOrRef: Cell | Reference) {
    const vertexId =
      cellOrRef instanceof Cell ? cellToVertexId(cellOrRef) : referenceToVertexId(this.workbook._model, cellOrRef);
    return this.parameters.find(p => p.vertexId.key === vertexId.key);
  }

  getCalculated (cellOrRef: Cell | Reference) {
    const vertexId =
      cellOrRef instanceof Cell ? cellToVertexId(cellOrRef) : referenceToVertexId(this.workbook._model, cellOrRef);
    return this.calculated.find(p => p.vertexId.key === vertexId.key);
  }

  private describeDefinedNames (): Array<FormulaDescription & { name: string }> {
    return Object.entries(this.workbook._globals)
      .filter(([ name, cell ]) => !name.startsWith('__grid') && cell.f !== '#REF!')
      .map(([ name, cell ]) => ({ name, ...this.describeName(cell) }));
  }

  private describeSheets () {
    return this.workbook.getSheets().map(s => this.describeSheet(s));
  }

  private describeSheet (sheet: WorkSheet): SheetDescription {
    const [ cols, rows ] = sheet.getSize();
    let cells = 0;
    let formulas = 0;
    let formulaLength = 0;
    let spillRanges = 0;
    const typeCounts = new Map<number, number>();
    for (const cell of sheet.getCells()) {
      cells += 1;
      if (cell.isSpillAnchor()) {
        spillRanges += 1;
      }
      if (cell.f) {
        formulas += 1;
        formulaLength += cell.f.length;
      }
      const t = valueToType(cell.v);
      typeCounts.set(t, (typeCounts.get(t) || 0) + 1);
    }
    const spillRangesRepr = spillRanges > 1 ? 's' : '';
    const spillSummary = spillRanges ? `${spillRanges} spill range${spillRangesRepr}` : '';
    let formulaSummary = `${formulas} formulas`;
    if (formulas) {
      formulaSummary += ` of average length ${(formulaLength / formulas).toFixed(0)}`;
    }
    const bounds = `spanning ${rows} rows and ${cols} columns`;
    const contents = [ `${cells} cells` ];
    if (cells) {
      contents.push(describeCellTypeCounts(typeCounts));
    }
    contents.push(spillSummary, formulaSummary, bounds);
    const { name, hidden } = sheet;
    const { islands, labels } = islandsAndLabels(this.workbook, sheet, this.options.recognize2dTables);
    return { name, hidden, cells, formulas, islands, labels, contents };
  }

  private describeReference (ref: Reference | string) {
    if (typeof ref === 'string') {
      return ref;
    }
    if (ref.name) {
      return `defined name "${ref.name}"`;
    }
    if (this.workbook._sheets.length === 1) {
      ref = ref.withPrefix({ workbookName: '', sheetName: '' });
    }
    else {
      ref = ref.withPrefix({ workbookName: '' });
    }
    const kind = ref.range && ref.range.size > 1 ? 'range' : 'cell';
    return `${kind} ${ref}`;
  }

  private describeName (cell: Cell): FormulaDescription {
    const staticRef = evaluateStaticReferenceASTNodeUnbound.call(this.workbook, cell._ast);
    if (staticRef) {
      return { type: 'reference', description: 'reference to ' + this.describeReference(staticRef) };
    }
    const model = this.workbook._model;
    const refs = model._graph
      .getOutgoingEdges(cellToVertexId(cell))
      .map(e => vertexIdToReference(model, e.to.id) || e.to.id.key);
    if (refs.length) {
      return {
        type: 'formula',
        description: 'formula referencing ' + refs.map(r => this.describeReference(r)).join(', '),
      };
    }
    return describeFormula(cell._ast);
  }

  labelString = (label: Label) => {
    if (label.cell && !label.cell.isDefinedName) {
      return formatCellValue(label.cell, this.workbook, false).trim();
    }
    return label.text.trim();
  };
}

function describeCellTypeCounts (typeCounts: Map<number, number>) {
  let typesSummary = '';
  let someTypesSkippedInSummary = false;
  let prevTypeCount = 0;
  const typeCountDetails = Array.from(typeCounts.entries())
    .sort((a, b) => b[1] - a[1])
    .map(([ typeCode, count ]) => {
      const typeMaybePlural = TYPE_TO_STR[typeCode].toLowerCase() + (count === 1 ? '' : 's');
      if (!someTypesSkippedInSummary && count > prevTypeCount / 2) {
        typesSummary += (typesSummary ? ' and ' : '') + typeMaybePlural;
      }
      else {
        someTypesSkippedInSummary = true;
      }
      prevTypeCount = count;
      return `${count} ${typeMaybePlural}`;
    })
    .join(', ');
  typesSummary = (someTypesSkippedInSummary ? 'mostly ' : 'all ') + typesSummary;
  return `${typesSummary} (${typeCountDetails})`;
}

function getRelevantNonDataRoots (roots: CellVertexId[], wb: Workbook) {
  const relevantRoots = chain(roots).filter(vertexId => {
    const cell = vertexIdToCell(wb, vertexId);
    if (cell != null && wb.getSheetByIndex(cell.sheetIndex)?.hidden) {
      // ignore cells in hidden sheets
      return false;
    }
    return (cell != null && !isStr(cell.v)) || isNontriviallyReferenced(wb, vertexId);
  });
  const rootCellsNotInDataRegions = new Set<CellVertexId>(relevantRoots);
  wb._model._graph.visitRangeVertices(rangeVertex => {
    if (isRangeSmallEnoughToStillMaybeContainParameters(rangeVertex.id.toRange())) {
      return;
    }
    // cells referenced together in a big range are probably not parameters, so remove them
    for (const vertexId of [ ...rootCellsNotInDataRegions ]) {
      if (rangeVertex.id.overlaps(vertexId)) {
        rootCellsNotInDataRegions.delete(vertexId);
      }
    }
  });
  return rootCellsNotInDataRegions;
}

function cellValueType (cell: Cell | null) {
  if (cell?.v == null) {
    return 'blank';
  }
  else if (cell.z && isDateFormat(cell.z)) {
    return 'date';
  }
  else {
    return typeof cell.v;
  }
}

function isRangeSmallEnoughToStillMaybeContainParameters (range: Range) {
  return range.size <= 6;
}

/**
 * Yield all non-empty cells with non-empty text values, which are not formula
 * cells or spilled cells, i.e. contain static text values.
 */
function* iterTextValues (sheet: WorkSheet) {
  for (const cell of sheet._cells._cells.values()) {
    if (!cell.f && cell.v && isStr(cell.v) && !cell.isSpilled()) {
      yield cell.v;
    }
  }
}

function expandRectangle (
  { colIndex: x, rowIndex: y }: CellVertexId,
  [ width, height ]: [width: number, height: number],
  isInside: (x: number, y: number) => boolean,
) {
  let left = x;
  let right = x;
  let top = y;
  let bottom = y;
  while (left > 1 && isInside(left - 1, y)) {
    left -= 1;
  }
  const maxXminus1 = width - 1;
  while (right < maxXminus1 && isInside(right + 1, y)) {
    right += 1;
  }
  const isSpanInside = (y: number) => chain(range(left, right + 1)).every(ix => isInside(ix, y));
  while (top > 1 && isSpanInside(top - 1)) {
    top -= 1;
  }
  const maxYminus1 = height - 1;
  while (bottom < maxYminus1 && isSpanInside(bottom + 1)) {
    bottom += 1;
  }
  return new Range({ left, right, top, bottom });
}

function dependsOnAtLeast (
  cellMemo: DefaultMap<Cell, boolean>,
  rangeMemo: Map<string, Cell[]>,
  model: Model,
  cell: Cell,
  n: number,
) {
  const found = new Set<Cell>();
  const todo = new LinkedList<Cell>([ cell ]);
  const nminus1 = n - 1;
  let nextCell: Cell | undefined;
  while ((nextCell = todo.removeFirst())) {
    found.add(nextCell);
    for (const { dependencyCell } of getFormulaCellsInDependencies(rangeMemo, model, nextCell)) {
      if (found.has(dependencyCell)) {
        continue;
      }
      if (cellMemo.getIfPresent(dependencyCell) || found.size >= nminus1) {
        cellMemo.set(cell, true);
        return true;
      }
      found.add(dependencyCell);
      todo.append(dependencyCell);
    }
  }
  cellMemo.set(cell, false);
  return false;
}

function describeFormula (ast: ASTRootNode): FormulaDescription {
  if (isNonLiteralNode(ast)) {
    if ('call' in ast) {
      if (typeof ast.call === 'string') {
        return { type: 'formula', description: 'calling ' + ast.call.toUpperCase() };
      }
      if ('name' in ast.call) {
        return { type: 'formula', description: 'calling lambda in defined name ' + ast.call.name };
      }
      return { type: 'formula', description: 'defining a lambda and calling it' };
    }
    if ('lambda' in ast) {
      return { type: 'formula', description: 'defining a lambda' };
    }
    return { type: 'formula', description: null };
  }
  if (typeof ast === 'object' && ast !== null) {
    return { type: 'literal', description: 'error value ' + ast.error };
  }
  return { type: 'literal', description: TYPE_TO_STR[valueToType(ast)] + ' ' + ast };
}

export function describeWorkbook (wb: Workbook, options: Partial<DescriptionOptions> = DEFAULTS) {
  const description = new WorkbookDescription(wb, options);
  return description;
}

/**
 * Find cells (or cell addresses where the cells are not populated in the model)
 * that are directly referenced by at least one cell in `froms`.
 * @returns formula cells and non-formula cells. The latter are Cell instances
 *   where available, else vertex ID keys for cells that are not populated in
 *   the model (so are blank, and may or may not be outside sheet bounds).
 */
function directDependencyCellsNotIn (
  memoizedRangeFormulaCells: Map<string, Cell[]>,
  model: Model,
  froms: Array<{ vertexId: KnownVertexId }>,
) {
  const formulaCells = new Set<Cell>();
  const nonFormulaCellsOrVertexIdKeys = new Set<Cell | string>();
  for (const { vertexId } of froms) {
    visitFormulaCellsForVertexId(model, vertexId, (cell: Cell) => {
      const cellVertexId = cellToVertexId(cell);
      model._graph.visitOutgoingEdges(cellVertexId, outEdge => {
        if (outEdge.kind !== 'nonvalue') {
          const depVertexId = outEdge.to.id;
          for (const dependencyCell of formulaCellsForVertexId(
            memoizedRangeFormulaCells,
            model,
            depVertexId,
            cell._spill,
          )) {
            formulaCells.add(dependencyCell);
          }
          if (depVertexId instanceof RangeVertexId) {
            const sheetKey = `${depVertexId.workbookKey},${depVertexId.sheetIndex}`;
            const cellBounds = model._graph._sheetCellBounds.get(sheetKey);
            depVertexId.visitCells(cvid => {
              const cell = vertexIdToCell(model, cvid);
              if (cell && !cell.f) {
                nonFormulaCellsOrVertexIdKeys.add(cell);
              }
            }, cellBounds);
          }
          else if (depVertexId instanceof CellVertexId) {
            const cell = vertexIdToCell(model, depVertexId);
            if (cell && !cell.f) {
              nonFormulaCellsOrVertexIdKeys.add(cell);
            }
            else if (!cell) {
              nonFormulaCellsOrVertexIdKeys.add(depVertexId.key);
            }
          }
        }
      });
    });
  }
  return { formulaCells, nonFormulaCellsOrVertexIdKeys };
}

function formulaChunks (wb: Workbook, chunkedGraph: DependencyGraph<ChunkNode, KnownVertexId>) {
  const vertices = chunkedGraph._getVertices(wb.keyInDepGraph);
  if (!vertices) {
    if (chunkedGraph.nodeCount() > 0) {
      console.warn('Chunked graph is non-empty but contains no nodes for workbook', wb.name);
    }
    return [];
  }
  const compareByLeafDepthAndRootHeightAndLocation = (a: ChunkNode, b: ChunkNode) => {
    if (a.leafDepth !== b.leafDepth) {
      if (a.leafDepth == null) {
        return 1;
      }
      else if (b.leafDepth == null) {
        return -1;
      }
      return a.leafDepth - b.leafDepth;
    }
    if (a.rootHeight !== b.rootHeight) {
      if (a.rootHeight == null) {
        return -1;
      }
      else if (b.rootHeight == null) {
        return 1;
      }
      return b.rootHeight - a.rootHeight;
    }
    return compareByVertexIdLocation(a, b);
  };
  return chain(vertices._vertices.values())
    .filter(v => v.outgoingEdges.length > 0)
    .map(v => v.data)
    .filter((data): data is ChunkNode => !!data)
    .filter(chunk => !!chunk.formula)
    .toArray()
    .sort(compareByLeafDepthAndRootHeightAndLocation);
}

function filterCalculated (wb: Workbook, list: ChunkNode[]) {
  let maxRootHeight = 0;
  let maxLeafDepth = 0;
  for (const chunk of list) {
    if (chunk.rootHeight != null && chunk.rootHeight > maxRootHeight) {
      maxRootHeight = chunk.rootHeight;
    }
    if (chunk.leafDepth != null && chunk.leafDepth > maxLeafDepth) {
      maxLeafDepth = chunk.leafDepth;
    }
  }
  const heuristicMinRootHeight = Math.min(3, Math.max(1, Math.ceil(Math.sqrt(maxRootHeight) / 2)));
  return list.filter(chunk => {
    if (chunk.leafDepth === 0 && chunk.rootHeight != null && chunk.rootHeight < heuristicMinRootHeight) {
      const values = chunk.ref.withContext(wb).resolveRange();
      invariant(!isErr(values));
      if (values.every(isStr)) {
        // Heuristic: this is likely a label-generation formula chunk ' + chunk.ref);
        return false;
      }
    }
    return true;
  });
}

/**
 * Sort comparator to sort things that have a vertexId by that vertex ID (its
 * top-down-left-right location if it is a cell vertex ID, anchor cell location
 * if it is a range vertex, or lexicographically ordered name if it is a name
 * vertex. Name vertex IDs come last.)
 */
function compareByVertexIdLocation ({ vertexId: a }: Labeled, { vertexId: b }: Labeled): number {
  if (a instanceof NameVertexId && b instanceof NameVertexId) {
    return a.name.localeCompare(b.name);
  }
  if (a instanceof NameVertexId) {
    return -1;
  }
  if (b instanceof NameVertexId) {
    return 1;
  }
  return (
    a.sheetIndex - b.sheetIndex ||
    a.topLeft().rowIndex - b.topLeft().rowIndex ||
    a.topLeft().colIndex - b.topLeft().colIndex
  );
}

function isFixedIntervalRange (rect: Range, sheet: WorkSheet, wb: Workbook) {
  if (rect.size < 4 || (rect.width > 1 && rect.height > 1)) {
    return false;
  }
  const values = new Reference(rect, { sheetName: sheet.name, ctx: wb }).resolveRange();
  return !isErr(values) && isFixedIntervalSequence(values);
}

function populateRootHeights (wbKey: number, graph: DependencyGraph<ChunkNode, KnownVertexId>) {
  const entered: Set<Vertex<ChunkNode>> = new Set();
  const finished: Set<Vertex<ChunkNode>> = new Set();

  function populateRootHeightsDFS (start: Vertex<ChunkNode>): void {
    type RecursionFrame = {
      vertex: Vertex<ChunkNode>,
      edgeIterator: IterableIterator<Edge<ChunkNode, KnownVertexId>>,
    };

    const stack: RecursionFrame[] = [ { vertex: start, edgeIterator: start.outgoingEdges[Symbol.iterator]() } ];

    let frame: RecursionFrame | undefined;
    while ((frame = stack.pop())) {
      const { vertex, edgeIterator } = frame;
      if (finished.has(vertex)) {
        continue;
      }

      invariant(
        !vertex.data?.rootHeight,
        `Vertex ${vertex.id.toRange()} not finished but already has rootHeight ${vertex.data?.rootHeight}`,
      );

      entered.add(vertex);

      let iterationStep = edgeIterator.next();
      while (!iterationStep.done) {
        const edge = iterationStep.value;
        const neighbor = edge.to;
        if (!entered.has(neighbor)) {
          stack.push(frame);
          stack.push({ vertex: neighbor, edgeIterator: neighbor.outgoingEdges[Symbol.iterator]() });
          break; // break inner loop, to continue outer loop on this new stack frame; this is the loop recursion
        }
        iterationStep = edgeIterator.next();
      }

      if (iterationStep.done) {
        let maxNeighborRootHeight = -1;
        for (const edge of vertex.outgoingEdges) {
          const neighborRootHeight = edge.to.data?.rootHeight || 0;
          if (neighborRootHeight > maxNeighborRootHeight) {
            maxNeighborRootHeight = neighborRootHeight;
          }
        }
        vertex.data!.rootHeight = maxNeighborRootHeight + 1;
        finished.add(vertex);
      }
    }
  }

  for (const vertex of graph._getVertices(wbKey) || []) {
    if (vertex.data?.rootHeight == null) {
      populateRootHeightsDFS(vertex);
    }
  }
}

function mergeLabeledRanges (for1: Label['for'], for2: Label['for']): Label['for'] {
  const all = [ ...for1, ...for2 ];
  // Order by row ascending and within each row by column ascending
  all.sort((f1, f2) => Math.sign(f1.ref.top - f2.ref.top) * 2 + Math.sign(f1.ref.left - f2.ref.left));
  let i = 1;
  while (i < all.length) {
    const f1 = all[i - 1];
    const f2 = all[i];
    if (f1.ref.contains(f2.ref)) {
      // remove f2
      all.splice(i, 1);
    }
    else if (f2.ref.contains(f1.ref)) {
      // remove f1
      all.splice(i - 1, 1);
    }
    else if (areInLine(f1.ref.range, f2.ref.range)) {
      // replace f1 and f2 with their union
      all.splice(i - 1, 2, { ref: boundingBox(f1.ref, f2.ref), type: f1.type });
    }
    else {
      // keep both and move on to the next pair
      i += 1;
    }
  }
  return all;
}

function areInLine (r1: Range, r2: Range) {
  return (r1.left === r2.left && r1.right === r2.right) || (r1.top === r2.top && r1.bottom === r2.bottom);
}
