import type { ArrayValue } from '../types';
import type { MaybeBoxed } from '../ValueBox';
import { sortCollate } from './utils-seek';

function createSortingCollator (
  valueGetter: (idx: number) => MaybeBoxed<ArrayValue>,
  isAscending: boolean,
): (a: number, b: number) => number {
  return (a, b) => {
    const aVal = valueGetter(a);
    const bVal = valueGetter(b);
    return sortCollate(aVal, bVal, isAscending);
  };
}

/**
 * Given lists of values, return a permutation which sorts the list when
 * applied.
 */
export function calcSortingPermutation (
  orderBys: ((idx: number) => MaybeBoxed<ArrayValue>)[],
  sortOrders: boolean[],
  length: number,
): number[] {
  const collators = orderBys.map((orderBy, i) => createSortingCollator(orderBy, sortOrders[i]));
  const permutation = [ ...Array(length).keys() ];
  permutation.sort((a, b) => {
    for (const collator of collators) {
      const cmp = collator(a, b);
      if (cmp !== 0) {
        return cmp;
      }
    }
    return 0;
  });
  return permutation;
}
