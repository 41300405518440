import { BLANK, MISSING, ERROR_VALUE, ERROR_NA } from './constants';
import { parseDateTime, round15 } from './functions/utils-number';
import { isBool, isNum, isStr, isErr } from '../typeguards';
import { MODE_EXCEL, MODE_GOOGLE } from '../mode';
import type { ModeBit } from './functions/sigtypes.js';
import {
  CellValue,
  FormulaArgument,
  MaybeBoxedFormulaArgument,
  TYPE_ALL,
  TYPE_ARRAY,
  TYPE_BLANK,
  TYPE_BOOL,
  TYPE_ERROR,
  TYPE_LAMBDA,
  TYPE_MISSING,
  TYPE_NONE,
  TYPE_NUM,
  TYPE_RANGE,
  TYPE_STRING,
} from './types';
import { individualBits, englishArticle, oxfordCommaJoin } from '../utils';
import { invariant } from '../validation';
import Matrix, { isMatrix } from './Matrix';
import { isRef } from './Reference';
import FormulaError from './FormulaError';
import { mapMaybeBox, unbox } from './ValueBox.js';
import { isLambda } from './lambda';
import { parseNumber } from 'numfmt';

export const TYPE_TO_STR: Record<typeof TYPE_ALL, string> = {
  [TYPE_ERROR]: 'Error',
  [TYPE_BLANK]: 'Blank',
  [TYPE_MISSING]: 'Missing',
  [TYPE_NUM]: 'Number',
  [TYPE_BOOL]: 'Boolean',
  [TYPE_STRING]: 'String',
  [TYPE_RANGE]: 'Range',
  [TYPE_ARRAY]: 'Array',
  [TYPE_LAMBDA]: 'Lambda',
};

export function defaultValueForType (type: number): CellValue {
  if ((type & TYPE_NUM) !== 0) {
    return 0;
  }
  if ((type & TYPE_STRING) !== 0) {
    return '';
  }
  if ((type & TYPE_BOOL) !== 0) {
    return false;
  }
  return ERROR_VALUE;
}

export function valueToType (value: MaybeBoxedFormulaArgument) {
  value = unbox(value);
  if (value === MISSING) {
    return TYPE_MISSING;
  }
  if (value === BLANK) {
    return TYPE_BLANK;
  }
  if (value instanceof Error) {
    return TYPE_ERROR;
  }
  if (isRef(value)) {
    return TYPE_RANGE;
  }
  if (isMatrix(value)) {
    return TYPE_ARRAY;
  }
  const t = typeof value;
  if (t === 'number') {
    return TYPE_NUM;
  }
  if (t === 'boolean') {
    return TYPE_BOOL;
  }
  if (t === 'string') {
    return TYPE_STRING;
  }
  if (isLambda(value)) {
    return TYPE_LAMBDA;
  }
  throw new Error(`Unhandled value type for: ${JSON.stringify(value)} ${typeof value}`);
}

function typeMismatchErrorMsg (haveType: number, targetType: number): string {
  // Engine thought there was type mismatch, but there really isn't
  invariant((haveType & targetType) === 0);

  const bits = individualBits(targetType, TYPE_ALL);
  const partSet = new Set(bits.map(b => TYPE_TO_STR[b]));
  if (partSet.has('Range') && partSet.has('Array')) {
    partSet.delete('Range');
    partSet.delete('Array');
    partSet.add('Range/Array');
  }

  const optionalPart = partSet.delete('Missing') ? ' it to be omitted, or' : '';

  const partArr = Array.from(partSet);
  const expected =
    partArr.length > 0 ? `${englishArticle(partArr[0])} ${oxfordCommaJoin(partArr, 'or')}` : 'nothing (this is a bug)';

  const haveTypeStr = TYPE_TO_STR[haveType] ?? 'unknown (this is a bug)';

  return `Argument is ${haveTypeStr}. Expected${optionalPart} ${expected}`;
}

function coerceValueInner (value: FormulaArgument, targetType: number, googleMode: boolean): FormulaArgument {
  const valType = valueToType(value);
  if ((targetType & valType) !== 0) {
    return value;
  }
  if (isNum(value)) {
    return coerceNumber(value, targetType);
  }
  if (isBool(value)) {
    return coerceBool(value, targetType);
  }
  if (isStr(value)) {
    return coerceString(value, targetType, googleMode);
  }
  if (valType === TYPE_ERROR) {
    return value;
  }
  if (valType === TYPE_MISSING) {
    if ((targetType & TYPE_BLANK) !== 0) {
      return BLANK;
    }
    const result = defaultValueForType(targetType);
    if (isErr(result)) {
      return result.detailed('Argument not allowed to be omitted');
    }
    return result;
  }
  if (valType === TYPE_BLANK) {
    const result = defaultValueForType(targetType);
    if (isErr(result)) {
      return result.detailed('Argument not allowed to be blank');
    }
    return result;
  }
  if (isLambda(value)) {
    if ((targetType & TYPE_ARRAY) !== 0) {
      return Matrix.of(value);
    }
    return ERROR_VALUE.detailed(typeMismatchErrorMsg(TYPE_LAMBDA, targetType));
  }
  return ERROR_VALUE.detailed(typeMismatchErrorMsg(TYPE_NONE, targetType));
}

function coerceNumber (value: number, targetType: number): string | boolean | Matrix | FormulaError {
  if ((targetType & TYPE_STRING) !== 0) {
    return String(round15(value));
  }
  if ((targetType & TYPE_BOOL) !== 0) {
    return value !== 0;
  }
  if ((targetType & TYPE_ARRAY) !== 0) {
    // Turn the number into an array with a single value
    return Matrix.of(value);
  }
  return ERROR_VALUE.detailed(typeMismatchErrorMsg(TYPE_NUM, targetType));
}

function coerceBool (value: boolean, targetType: number): string | number | Matrix | FormulaError {
  if ((targetType & TYPE_NUM) !== 0) {
    return value ? 1 : 0;
  }
  if ((targetType & TYPE_STRING) !== 0) {
    return value ? 'TRUE' : 'FALSE';
  }
  if ((targetType & TYPE_ARRAY) !== 0) {
    // Turn the boolean into an array with a single value
    return Matrix.of(value);
  }
  return ERROR_VALUE.detailed(typeMismatchErrorMsg(TYPE_BOOL, targetType));
}

function coerceString (
  value: string,
  targetType: number,
  googleMode: boolean,
): number | boolean | Matrix | FormulaError {
  if ((targetType & TYPE_NUM) !== 0) {
    if (value.length === 0) {
      if (googleMode) {
        return 0;
      }
      return ERROR_VALUE.detailed('Cannot convert empty string to number');
    }
    const parsed = parseNumber(value);
    if (parsed) {
      const num = +parsed.v;
      if (isFinite(num)) {
        // String was successfully converted to a number
        return num;
      }
    }
    // Strings such as "2000-10-31" can be coerced into numbers by parsing
    // them as a date, similarly to the DATEVALUE function.
    const dateNum = parseDateTime(value);
    if (isErr(dateNum)) {
      return ERROR_VALUE.detailed(`Cannot convert string ${JSON.stringify(value)} to number`);
    }
    return dateNum;
  }
  if ((targetType & TYPE_BOOL) !== 0) {
    // Comparison is case insensitive; both "tRuE" and "TRUE" should be
    // convertible to booleans.
    const uppercase = value.toUpperCase();
    if (uppercase === 'TRUE') {
      return true;
    }
    if (uppercase === 'FALSE') {
      return false;
    }
    return ERROR_VALUE.detailed(`Cannot convert string ${JSON.stringify(value)} to boolean`);
  }
  if ((targetType & TYPE_ARRAY) !== 0) {
    // Turn the string into an array with a single value
    return Matrix.of(value);
  }
  return ERROR_VALUE.detailed(typeMismatchErrorMsg(TYPE_STRING, targetType));
}

/**
 * Map `value` to a corresponding value acceptable to a function parameter declaring `targetType`, or if there is no
 * such mapping, return `FormulaError`.
 */
export function coerceValue (
  value: MaybeBoxedFormulaArgument,
  targetType: number,
  mode: ModeBit = MODE_EXCEL,
): MaybeBoxedFormulaArgument {
  const googleMode = mode === MODE_GOOGLE;
  const result = mapMaybeBox(value, value => coerceValueInner(value, targetType, googleMode));
  if (googleMode && !isErr(value) && isErr(result) && targetType === TYPE_RANGE) {
    // GSHEETS: This is a deviation from Excel's logic to accommodate Google
    // Sheets. Return ERROR_NA instead of ERROR_VALUE.
    return ERROR_NA;
  }
  return result;
}
