import { translateToA1 } from '@borgar/fx';

/**
 * Mutates cellData from clipboard. Converts formulas from RC to A1 as needed and
 * nukes the value stored cells that either contain a formula or are spilled
 *
 * @param {object} oldCellData
 * @param {string} a1
 */
export function prepPastedCellData (oldCellData, a1) {
  const cellData = Object.assign({}, oldCellData);
  if (cellData.f && cellData.isRC) {
    // Some formulas will use RC address mode for references and will need
    // to be converted to A1 when the pasting position is known.
    // If the formula is RC then its isRC parameter will be true, else it
    // will be false.
    cellData.f = translateToA1(cellData.f, a1, { wrapEdges: false, xlsx: true });
  }

  // Removing the value causes Apiary to recalculate the cell, this could be done
  // only for formulas that have references but has to be done for all reference
  // formulas because they may point to cells that don't exist in the target sheet
  // (such as if they were copied from Google Sheets)
  // TODO: in "paste values"-mode we should be clearing the formula but not the value
  if (cellData.f) {
    cellData.v = null;
  }

  // don't write a value when the cell contains a spilled value
  if (cellData.spilled) {
    cellData.v = null;
  }
  return cellData;
}
