import Cell, { BLANK_CELL } from '../excel/Cell';

type StyleDef = Record<string, unknown>;
type StyleItem = { si: number, key: string, s: StyleDef };
type Celllike = {
  s?: StyleDef,
  id?: string,
  v?: any,
  userZ?: string,
  formulaZ?: string,
};

/**
 * List of values which can be omitted in CSF
 */
const SKIP_VALUES = {
  'font-name': 'Calibri',
  'font-size': 11,
  'font-color': '#000',
  'bold': false,
  'italic': false,
  'underline': 'none',
  'border-left-style': 'none',
  'border-left-color': '#000',
  'border-right-style': 'none',
  'border-right-color': '#000',
  'border-top-style': 'none',
  'border-top-color': '#000',
  'border-bottom-style': 'none',
  'border-bottom-color': '#000',
  'horizontal-alignment': 'general',
  'vertical-alignment': 'bottom',
  'wrap-text': false,
  'shrink-to-fit': false,
  'number-format': 'General',
};

function skipValue (key: string, val: unknown): boolean {
  return val == null || key.startsWith('_') || String(val).toLowerCase() === String(SKIP_VALUES[key]).toLowerCase();
}

export default class StyleDeduper {
  static getCacheID (styles: StyleDef): string {
    if (!styles || typeof styles !== 'object') {
      throw new Error('Argument must be an object.');
    }
    const styleProps = Object.keys(styles).filter(key => styles[key] != null);
    styleProps.sort();
    // There is a way to poison the cache by making keys or values that include
    // the separator values... but the risk very low and we can switch to hashing
    // any of these become user entered data
    return styleProps.reduce((a, c) => (a += `${c}\x1D${styles[c]}\x1E`), '');
  }

  static cellStyles (cell?: Cell | Celllike): StyleDef {
    const styleDefs: StyleDef = {};
    if (cell && cell.s) {
      for (const [ key, val ] of Object.entries(cell.s)) {
        if (!skipValue(key, val)) {
          styleDefs[key] = val;
        }
      }
    }
    // cell.userZ is prefered over cell.s['number-format']
    if (cell && cell.userZ) {
      styleDefs['number-format'] = cell.userZ;
    }
    if (cell && cell.formulaZ) {
      styleDefs['number-format-from-formula'] = cell.formulaZ;
    }
    return styleDefs;
  }

  _defaultStyle: StyleItem;
  _styles: Map<string, StyleItem>;

  constructor (defaultStyle = BLANK_CELL) {
    const _defaultStyle: StyleDef = StyleDeduper.cellStyles(defaultStyle);
    this._defaultStyle = {
      si: 0,
      key: StyleDeduper.getCacheID(_defaultStyle),
      s: _defaultStyle,
    };
    this._styles = new Map();
    this.reset();
  }

  reset (): void {
    this._styles.clear();
    this._styles.set(this._defaultStyle.key, this._defaultStyle);
  }

  getSi (cell: Cell | Celllike): number {
    const styleDefs = StyleDeduper.cellStyles(cell);
    if (!Object.keys(styleDefs).length) {
      // for no style defs we emit default styles
      // this happens EVEN IF default styles do contain defs
      return 0;
    }
    // write as needed and return the .si
    const key = StyleDeduper.getCacheID(styleDefs);
    if (!this._styles.has(key)) {
      this._styles.set(key, { key: key, si: this._styles.size, s: styleDefs });
    }
    return this._styles.get(key)?.si ?? 0;
  }

  getStyles (): StyleDef[] {
    const styles = Array.from(this._styles.values());
    styles.sort((a, b) => a.si - b.si);
    return styles.map(d => d.s);
  }
}
