import { BLANK_CELL } from '@grid-is/apiary/lib/excel/Cell';

import { isBlank } from '../utils/isBlank';
import { isFormula } from '../utils/isFormula';
import { typeCast } from '../utils/typeCast';

/**
 * @typedef {import('@/grid-ui/Select/types').SelectOptionType} Option
 */

/**
 * Class representing a GRID element option.
 * @abstract
 */
export class GridOption {
  /** @type {string} */
  type = 'abstract';
  /** @type {string} */
  id = '';
  /** @type {string} */
  name = '';
  /** @type {string | undefined} */
  defaultValue;
  /** @type {string | undefined} */
  propGroup;
  /** @type {Option[]} */
  options = [];
  /** @type {string} */
  label = '';
  /** @type {import('react').ReactNode} */
  help = null;
  /** @type {import('react').ReactNode} */
  tooltip = '';
  /** @type {string | undefined} */
  placeholder;
  /** @type {boolean} */
  enforceExpression = false;
  /** @type {boolean} */
  disableFx = false;
  /** @type {boolean} */
  hideLabel = false;

  /**
   * Create a GridOption.
   * @param {import('./GridOption').GridOptionArgs} opts Attributes of the option
   */
  constructor ({
    name, defaultValue, label, tooltip, enforceExpression, disableFx,
    options, placeholder, propGroup, help, hideLabel,
  }) {
    /** @type {string} */
    this.name = name;
    this.defaultValue = defaultValue;
    if (defaultValue == null && options && options.length) {
      this.defaultValue = options[0].value;
    }
    this.propGroup = propGroup;
    if (Array.isArray(options)) {
      this.options = [ ...options ];
    }
    this.label = label || '';
    this.help = help || null;
    this.tooltip = tooltip || '';
    this.placeholder = placeholder;
    if (enforceExpression != null) {
      this.enforceExpression = !!enforceExpression;
    }
    if (disableFx != null) {
      this.disableFx = !!disableFx;
    }
    if (hideLabel != null) {
      this.hideLabel = !!hideLabel;
    }
    this.validate = this.validate.bind(this);
    this.parse = this.parse.bind(this);
  }

  /**
   * Report if a value for this option is one of the basic value set that this option accepts.
   * When this is is true, the UI can show a simplified UI for the option.
   * @param {string} value - An option value.
   * @return {boolean}
   */
  isPlainOption = value => {
    return isBlank(value) || !isFormula(value);
  };

  /**
   * Report if an option has been assigned a value in a collection of properties.
   * @param {object} props - A props collection to read from.
   * @return {boolean} true if the value has been set.
   */
  isSet (props) {
    const propValue = this.readRaw(props, false);
    return propValue !== '' && propValue != null;
  }

  /**
   * Report if an option has been set to a formula (even an invalid one) in a collection of properties.
   * @param {object} props - A props collection to read from.
   * @return {boolean} true if the value is determined to be a formula (or an attempt at one).
   */
  isFx (props) {
    const propValue = this.readRaw(props, false);
    return propValue ? /^(= *)?[^\s=]/.test(propValue) : false;
  }

  /**
   * Validate a value according to the rules of this option.
   * @abstract
   * @param {any} value - An option value.
   * @return {import('@/grid/options/OptionError').OptionError | null} Error object or null if valid.
   */
  // eslint-disable-next-line no-unused-vars
  validate (value) {
    throw new Error('Not implemented');
  }

  /**
   * Parse a value according to the rules of this option.
   * @abstract
   * @param {string|null|undefined} value - An option value.
   * @param {string} [locale] - A locale to use when parsing dates
   * @return {(boolean|string|number|import('@grid-is/apiary').Reference|null)} The most reasonable representation of the value.
   */
  // eslint-disable-next-line no-unused-vars
  parse (value, locale) {
    throw new Error('Not implemented');
  }

  /**
   * Write a value to a spreadsheet model through this option. It is a no-op on this option.
   * @param {object} props - A props collection to read from.
   * @param {string|number|boolean|null|undefined|import('@/grid/types').CellArray} value - The value to write.
   */
  // eslint-disable-next-line no-unused-vars
  write (props, value) {}

  /**
   * Read an option value from a props object or use a default if possible (and allowed).
   * @param {object} props - A props collection to read from.
   * @param {boolean} [useDefault=true] - Fallback to default if value is unset.
   * @return {(string|null|undefined)} The option value or its fallback value.
   */
  readRaw (props, useDefault = true) {
    const raw = (this.propGroup)
      ? props[this.propGroup] && props[this.propGroup][this.name]
      : props[this.name];
    if (useDefault && (raw == null || raw === '')) {
      return this.defaultValue;
    }
    // old documents may still contain numbers as numbers, so ensure this is text or null
    return raw == null ? raw : String(raw);
  }

  /**
   * Read an option value from a props object and deal with running any spreadsheet formula as needed.
   * @abstract
   * @deprecated
   * @param {object} props - A props collection to read from.
   * @return {import('@/grid/types').CellArray} The option value or its default value.
   */
  // eslint-disable-next-line no-unused-vars
  read = props => {
    throw new Error('Not implemented');
  };

  /**
   * Read a single cell from an expression result from a spreadsheet model.
   * If the result of the expression was a multi cell range, the top-left-most
   * cell of the area will be returned.
   * @param {object} props - A props collection to read from.
   * @returns {import('@/grid/types').Cellish} A resulting cell.
   */
  readCell (props) {
    // XXX: would be nice to optimize this by giving the model "max dimensions"
    return this._readModelCells(props)[0][0] || BLANK_CELL;
  }

  /**
   * Read a range of cells which are the result of running an expression in a
   * spreadsheet model. If the property was not a formula, it will be returned
   * contained by a cell wrapped in a 2D array.
   * @param {object} props - A props collection to read from.
   * @return {import('@/grid/types').CellArray} A nested array of cells
   */
  readCells (props) {
    return this._readModelCells(props);
  }

  /**
   * @param {object} props - A props collection to read from.
   * @return {import('@/grid/types').CellArray} A nested array of cells
   */
  _readModelCells (props, cropped = false) {
    // current value or default
    const propValue = this.readRaw(props);
    if (!propValue || isBlank(propValue)) {
      return [ [] ];
    }
    if (isFormula(propValue)) {
      const cropTo = cropped
        ? 'cells-with-non-blank-values'
        : 'any-cell-information';
      /** @type {import('@/grid/types').CellArray} */
      const cells = props.model?.readCells(propValue, { cropTo });
      if (!cells || !cells.length || !Array.isArray(cells)) {
        return [ [] ];
      }
      return cells;
    }
    // it's an option, so we typecast it according to Excel rules
    return [ [ { v: typeCast(propValue) } ] ];
  }
}
