import { chain, range } from '@grid-is/iterators';
import type Cell from './excel/Cell';
import Range from './excel/referenceParser/Range';
import { isBool, isNum, isStr } from './typeguards';
import type WorkSheet from './WorkSheet';
import { isDateFormat, getFormatInfo, parseDate } from 'numfmt';
import type { ArrayValue } from './excel/types';
import { CellVertexId, NameVertexId, RangeVertexId, type KnownVertexId } from './DependencyGraph';
import type Workbook from './Workbook';
import { TEXT } from './excel/functions/text';
import Reference, { isA1Ref, type A1Reference } from './excel/Reference';
import { invariant } from './validation';
import { cellToVertexId, vertexIdToCell, vertexIdToReference } from './dep-graph-helpers';
import { evaluateStaticReferenceASTNodeUnbound } from './excel/evaluate';
import { unbox } from './excel/ValueBox';
import { DefaultMap } from '@grid-is/collections';

const RETURN_CELLS = {
  returnBoxed: false,
  returnCells: true,
  returnLambda: false,
  cropTo: 'cells-with-non-blank-values',
} as const;

type LabelType = 'text' | 'date' | 'numbers';

/**
 * A text value, and possibly a reference and cell where the text is found, that
 * is believed to describe another cell or range.
 */
export type Label = {
  /** Cell in which the label was found, (main one, if multiple) */
  cell: Cell | null,
  /** The label text, possibly assembled from more than one cell value */
  text: string,
  /**
   * Cell address (or defined name) where the label is found (main one, if multiple).
   * Should have a sheet prefix but not a workbook prefix.
   */
  at: Reference,
  /** Cells/ranges that might be referenced by the label; at least one element */
  for: Array<{ ref: A1Reference, type?: LabelType }>, // TODO: maybe add confidence score
  /** The type of information constituting the label itself */
  type: LabelType,
};

/**
 * A cell or range reference, with zero or more labels believed to apply to it.
 * _May_ have a formula (a subtype does, and we don't want type errors where we check for it).
 */

export type Labeled = {
  labels: ReadonlyArray<Label>,
  ref: A1Reference,
  vertexId: KnownVertexId,
  formula?: string | null,
};

const MAX_LABELS_PER_DIRECTION = 5;

/**
 * A sequence of labels of the same type. Generally part of a label axis at the
 * top or left of an island, but can also be a singleton (N=1).
 */
export type LabelSequence = {
  /** The type of all labels in this sequence */
  type: LabelType,
  /** 1xN or Nx1 range of label cells, e.g. C1:E1 */
  range: Range,
  /** MxN or NxM range of cells described by these labels, e.g. C2:E99 */
  labeledRange: Range,
  /** 1x1 address of a cell that may be the label for this label sequence */
  sequenceLabelCell?: Range, // XXX really we should have an A1Adress type.
};

type Orientation = 'horizontal' | 'vertical';

export class Island {
  workbook: Workbook;
  sheet: WorkSheet;
  range: Range;
  labelSequencesAlongTop: LabelSequence[] = [];
  labelSequencesAlongLeftSide: LabelSequence[] = [];
  orientationsDone: Orientation[] = [];
  maxHeaderLevels: number;

  constructor (workbook: Workbook, sheet: WorkSheet, range: Range, detectComplexHeaderStructure: boolean) {
    this.workbook = workbook;
    this.sheet = sheet;
    this.range = range;
    const labelSequenceOrientations: Orientation[] = detectComplexHeaderStructure
      ? [ 'horizontal', 'vertical' ]
      : [ 'horizontal' ];
    this.maxHeaderLevels = detectComplexHeaderStructure ? Infinity : 1;
    this.detectLabelSequences(labelSequenceOrientations);
  }

  detectLabelSequences (orientations: ReadonlyArray<Orientation>) {
    const orientationsTodo = orientations.filter(o => !this.orientationsDone.includes(o));
    for (const headerLevel of range(Math.min(this.maxHeaderLevels, this.range.width, this.range.height))) {
      for (const orientation of orientationsTodo) {
        const horizontal = orientation === 'horizontal';
        const [ dimensionPerpendicularToLabelSequence, narrowToLabelSequence ] = horizontal
          ? [ 'height', 'collapseToRow' ]
          : [ 'width', 'collapseToColumn' ];
        if (this.range[dimensionPerpendicularToLabelSequence] > headerLevel + 1) {
          const labelSequenceRange = this.range[narrowToLabelSequence](headerLevel);
          const labelSequence = makeLabelSequence(horizontal, this.sheet, labelSequenceRange, this.range);
          if (labelSequence) {
            (horizontal ? this.labelSequencesAlongTop : this.labelSequencesAlongLeftSide).push(labelSequence);
          }
        }
      }
    }
    this.orientationsDone.push(...orientationsTodo);
  }

  get labelSequences (): ReadonlyArray<LabelSequence> {
    return [ ...this.labelSequencesAlongLeftSide, ...this.labelSequencesAlongTop ];
  }

  get numHeaderRows () {
    return this.labelSequencesAlongTop.reduce((max, { range }) => Math.max(max, range.height), 0);
  }

  get numHeaderColumns () {
    return this.labelSequencesAlongLeftSide.reduce((max, { range }) => Math.max(max, range.width), 0);
  }

  get dataRange (): Range {
    return new Range({
      top: this.range.top + this.numHeaderRows,
      left: this.range.left + this.numHeaderColumns,
      bottom: this.range.bottom,
      right: this.range.right,
    });
  }

  labelsFor (ref: A1Reference) {
    const { top, left, bottom, right } = ref;
    const labels: Label[] = [];
    if (ref.width > 1) {
      this.detectLabelSequences([ 'horizontal' ]);
      const maxHeaderY = this.range.top + this.numHeaderRows - 1;
      for (let y = this.range.top; y <= Math.min(maxHeaderY, top - 1); y++) {
        const labelSequence = this.labelSequencesAlongTop.find(
          ({ range }) => range.top === y && range.bottom === y && range.left <= left && range.right >= right,
        );
        if (labelSequence) {
          let lastNonBlankCellInThisSequence: Cell | null = null;
          for (let x = Math.max(labelSequence.range.left, left); x <= Math.min(labelSequence.range.right, right); x++) {
            const cell = this.sheet.getCellByCoords(y, x) || lastNonBlankCellInThisSequence;
            if (cell) {
              lastNonBlankCellInThisSequence = cell;
              labels.push(makeLabel(this.workbook, cell, ref.collapseToColumn(x - ref.range.left), labelSequence.type));
            }
          }
        }
      }
    }
    else if (ref.height > 1) {
      this.detectLabelSequences([ 'vertical' ]);
      const maxHeaderX = this.range.left + this.numHeaderColumns - 1;
      for (let x = this.range.left; x <= Math.min(maxHeaderX, left - 1); x++) {
        const labelSequence = this.labelSequencesAlongLeftSide.find(
          ({ range }) => range.left === x && range.right === x && range.top <= top && range.bottom >= bottom,
        );
        if (labelSequence) {
          let lastNonBlankCellInThisSequence: Cell | null = null;
          for (let y = Math.max(labelSequence.range.top, top); y <= Math.min(labelSequence.range.bottom, bottom); y++) {
            const cell = this.sheet.getCellByCoords(y, x) || lastNonBlankCellInThisSequence;
            if (cell) {
              lastNonBlankCellInThisSequence = cell;
              labels.push(makeLabel(this.workbook, cell, ref.collapseToRow(y - ref.range.top), labelSequence.type));
            }
          }
        }
      }
    }
    return labels;
  }

  textValues (): Map<string, string[]> {
    const dataTextValues = new DefaultMap<string, string[]>(() => []);
    const data = this.sheet.resolveArea(this.dataRange, RETURN_CELLS).area;
    for (const row of data) {
      for (const cell of row) {
        if (cell && (isStr(cell.v) || isLikelyDateOrYearCell(cell))) {
          const textValue = formatCellValue(cell, this.workbook);
          dataTextValues.get(textValue).push(cell.id);
        }
      }
    }
    return dataTextValues;
  }
}

export function analyzeIslands (
  wb: Workbook,
  sheet: WorkSheet,
  recognize2dTables = true, // false to look for left-side labels and more than one header level on each side
): Island[] {
  const islands = detectIslands(sheet).map(populateIsland);
  // Merge islands that line up, if labels of one seem to apply to the other
  // TODO: generalize this; currently it just checks the island above.
  let i = 1;
  while (i < islands.length) {
    const island = islands[i];
    if (island.labelSequences.length === 0) {
      const mergeCandidate = findMergeCandidate(island);
      if (mergeCandidate) {
        const [ k, merged ] = mergeCandidate;
        islands[k] = merged;
        islands.splice(i, 1);
        continue;
      }
    }
    i += 1;
  }

  return islands;

  function findMergeCandidate (island: Island): [number, Island] | undefined {
    for (let k = i - 1; k >= 0; k--) {
      const neighbor = islands[k];
      const isAbove = island.range.left <= neighbor.range.right && island.range.right >= neighbor.range.left;
      if (!isAbove) {
        continue;
      }
      const mergedRange = new Range({
        top: neighbor.range.top,
        bottom: island.range.bottom,
        left: Math.min(neighbor.range.left, island.range.left),
        right: Math.max(neighbor.range.right, island.range.right),
      });
      if (islands.slice(k + 1).some(other => other !== island && other.range.intersects(mergedRange))) {
        // Don't merge `island` with its upward `neighbor` because the result would overlap with another island
        return;
      }
      const merged = populateIsland(mergedRange);
      if (merged.labelSequences.length > 0) {
        const bogusLabelSequence = merged.labelSequences.find(labelSequence => {
          return !(labelSequence.labeledRange.top > labelSequence.range.top);
        });
        if (bogusLabelSequence) {
          // Don't merge `island` with its upward `neighbor` because the result has a non-horizontal label sequence
          // XXX should this be possible at all?
          return;
        }
        // Merge `island` with its upward `neighbor` because that makes it gain labels
        return [ k, merged ];
      }
    }
  }

  function populateIsland (islandRange: Range): Island {
    return new Island(wb, sheet, islandRange, recognize2dTables);
  }
}

export function makeLabelSequence (
  horizontal: boolean,
  sheet: WorkSheet,
  labelSequenceRange: Range,
  islandRange: Range,
) {
  const recognized = recognizeLabelSequence(sheet, labelSequenceRange);
  if (recognized) {
    const labeledRange = horizontal
      ? recognized.range.moveBy(0, 1).extendSide('bottom', islandRange.bottom)
      : recognized.range.moveBy(1, 0).extendSide('right', islandRange.right);
    return { ...recognized, labeledRange };
  }
}

function recognizeLabelSequence (sheet: WorkSheet, range: Range): Omit<LabelSequence, 'labeledRange'> | undefined {
  return (
    recognizeDateOrYearLabelSequence(range, sheet) ||
    recognizeFixedIntervalLabelSequence(range, sheet) ||
    recognizeTextLabelSequence(range, sheet)
  );
}

function isBlank (cell: Cell | null) {
  return cell == null || cell.v == null || cell.v === '';
}

export function recognizeTextLabelSequence (
  range: Range,
  sheet: WorkSheet,
): Omit<LabelSequence, 'labeledRange'> | undefined {
  let labelSequenceRange: Range | undefined;
  for (const { row, column } of range.iterCoordinates()) {
    const cell = sheet.getCellByCoords(row, column);
    if (isBlank(cell)) {
      // keep going until we find a non-blank cell
    }
    else if (!isStr(cell?.v)) {
      // found a non-blank cell that's not text, so not a text label sequence
      return;
    }
    else if (!labelSequenceRange) {
      labelSequenceRange = new Range({ top: row, left: column, bottom: range.bottom, right: range.right });
    }
  }
  if (labelSequenceRange) {
    return { range: labelSequenceRange, type: 'text' };
  }
}

export function recognizeDateOrYearLabelSequence (
  range: Range,
  sheet: WorkSheet,
): Omit<LabelSequence, 'labeledRange'> | undefined {
  let labelSequenceRange: Range | undefined;
  let sequenceLabelCell: Range | undefined;
  for (const { row, column } of range.iterCoordinates()) {
    const cell = sheet.getCellByCoords(row, column);
    if (isBlank(cell)) {
      if (!labelSequenceRange) {
        // blank before label sequence; that's OK, even after the label-sequence label, so keep going.
        continue;
      }
      // not a label sequence if it has gaps in it, so abort
      return;
    }
    if (isLikelyDateOrYearCell(cell)) {
      if (!labelSequenceRange) {
        // first date/year label, so start the label sequence here. Still just a
        // candidate; we may early-exit in a later loop iteration if we find a
        // counterexample to this being a label sequence.
        labelSequenceRange = new Range({ top: row, left: column, bottom: range.bottom, right: range.right });
      }
    }
    else if (isStr(cell?.v) && !sequenceLabelCell && !labelSequenceRange) {
      // haven't started seeing date/year labels, so consider this the label-sequence label
      sequenceLabelCell = new Range({ top: row, left: column });
    }
    else {
      // not a year/date and not the label-sequence label and not a blank, so abort
      return;
    }
  }
  if (labelSequenceRange) {
    return { range: labelSequenceRange, sequenceLabelCell, type: 'date' };
  }
}

export function recognizeFixedIntervalLabelSequence (
  range: Range,
  sheet: WorkSheet,
): Omit<LabelSequence, 'labeledRange'> | undefined {
  if ((range.width < 3 && range.height < 3) || (range.width > 1 && range.height > 1)) {
    return;
  }
  let diff: number | undefined;
  let prevNumber: number | undefined;
  let labelSequenceRange: Range | undefined;
  let sequenceLabelCell: Range | undefined;
  for (const { row, column } of range.iterCoordinates()) {
    const cell = sheet.getCellByCoords(row, column);
    if (isBlank(cell)) {
      if (!labelSequenceRange) {
        continue; // permit blanks before label sequence, even after the label-sequence label
      }
      return; // label sequence only recognized if it is unbroken, so abort
    }
    if (isNum(cell?.v)) {
      if (!prevNumber) {
        prevNumber = cell.v;
        labelSequenceRange = new Range({ top: row, left: column, bottom: range.bottom, right: range.right });
      }
      else if (!diff) {
        diff = cell.v - prevNumber;
      }
      else if (cell.v !== prevNumber + diff) {
        return; // not a fixed-interval sequence, so abort
      }
      prevNumber = cell.v;
    }
    else if (isStr(cell?.v) && !sequenceLabelCell && !labelSequenceRange) {
      // haven't started seeing date/year labels, so consider this the label-sequence label
      sequenceLabelCell = new Range({ top: row, left: column });
    }
    else {
      // Not a number, not a label, not a blank. Abort.
      return;
    }
  }
  if (labelSequenceRange && labelSequenceRange.size >= 3) {
    return { range: labelSequenceRange, sequenceLabelCell, type: 'numbers' };
  }
}

export function detectIslands (sheet: WorkSheet) {
  const [ width, height ] = sheet.getSize();
  let islands: Range[] = [];
  type Span = { left: number, right: number };
  type CandidateRectangle = Span & { top: number };
  let candidates: CandidateRectangle[] = [];
  const resolved = sheet.resolveArea(
    { left: 0, right: width - 1, top: 0, bottom: height - 1 },
    { returnCells: true, returnBoxed: false, returnLambda: false, cropTo: 'cells-with-non-blank-values' },
  );
  for (const y of range(0, (resolved.dataBottom || resolved.bottom) + 1)) {
    const rowCells = resolved.area[y];
    const nonBlankSpansInThisRow = (rowCells || []).reduce((spans, cell, x) => {
      if (!isBlank(cell)) {
        const lastSpan = spans[spans.length - 1];
        if (lastSpan && lastSpan.right === x - 1) {
          lastSpan.right = x;
        }
        else {
          spans.push({ left: x, right: x });
        }
      }
      return spans;
    }, [] as Span[]);
    const touches = (next: Span, prev: Span) => next.left <= prev.right + 1 && next.right >= prev.left - 1;
    for (let i = candidates.length - 1; i >= 0; i--) {
      const candidate = candidates[i];
      if (!nonBlankSpansInThisRow.some(span => touches(candidate, span))) {
        // no non-blank span in this row intersects this candidate rectangle. Complete it.
        islands.push(new Range({ ...candidate, bottom: y - 1 }));
        candidates.splice(i, 1);
      }
    }
    const candidatesThatJustGrew: CandidateRectangle[] = [];
    for (const span of nonBlankSpansInThisRow) {
      const intersecting = candidates.filter(candidate => touches(candidate, span));
      if (intersecting.length > 0) {
        const mergedCandidate = mergeTouching(intersecting, { ...span, top: y });
        candidatesThatJustGrew.push(mergedCandidate);
      }
      else {
        candidates.push({ ...span, top: y });
      }
    }
    for (const candidateThatJustGrew of candidatesThatJustGrew) {
      // join any islands that intersect or touch the now-grown candidate
      const touchingIslands = islands.filter(
        island => island.bottom >= candidateThatJustGrew.top && touches(island, candidateThatJustGrew),
      );
      if (touchingIslands.length > 0) {
        const merged = mergeTouching(touchingIslands, candidateThatJustGrew);
        islands = islands.filter(island => !touchingIslands.includes(island));
        candidates = candidates.filter(c => c !== candidateThatJustGrew).concat(merged);
      }
    }
  }
  candidates.forEach(candidate => islands.push(new Range({ ...candidate, bottom: height - 1 })));
  return islands;

  function mergeTouching (touching: CandidateRectangle[], next: CandidateRectangle) {
    const merged = touching.reduce((acc, { left, right, top }) => {
      acc.left = Math.min(acc.left, left);
      acc.right = Math.max(acc.right, right);
      acc.top = Math.min(acc.top, top);
      return acc;
    }, next);
    candidates = candidates.filter(c => !touching.includes(c)).concat(merged);
    return merged;
  }
}

export function isLikelyDateOrYearCell (cell: Cell | null): boolean {
  if (!cell) {
    return false;
  }
  if (isStr(cell.valueBoxed)) {
    return parseDate(unbox(cell.valueBoxed)) != null;
  }
  if (!isNum(cell.v)) {
    return false;
  }
  if (cell.z && isDateFormat(cell.z)) {
    return true;
  }
  if (!cell.z || isIntegerFormat(cell.z)) {
    const v = cell.v;
    return Number.isInteger(v) && v >= 1900 && v <= 2099;
  }
  return false;
}

function isIntegerFormat (z: string): boolean {
  const { type, maxDecimals } = getFormatInfo(z);
  return (type === 'number' || type === 'general') && maxDecimals === 0;
}

export function isFixedIntervalSequence (values: ArrayValue[]) {
  let firstDiff: number | null = null;
  return chain(values)
    .adjacentPairs()
    .every(function diffIsSameAsFirst ([ prev, next ]) {
      if (!isNum(prev) || !isNum(next)) {
        return false;
      }
      const diff = next - prev;
      if (diff === 0) {
        // don't consider a _constant_ sequence to be a fixed-interval sequence
        return false;
      }
      if (firstDiff == null) {
        firstDiff = diff;
      }
      return diff === firstDiff;
    });
}

export function formatCellValue (cell: Cell | null, wb: Workbook, quoteStrings = true) {
  if (cell == null) {
    return '';
  }
  const v = cell.v;
  if ((isStr(v) || isNum(v) || isBool(v)) && cell.z != null) {
    return String(TEXT.call(wb, v, cell.z));
  }
  if (isStr(v) && quoteStrings) {
    return `"${v.replace(/"/g, '""')}"`;
  }
  if (isBool(v)) {
    return String(v).toUpperCase();
  }
  return String(v);
}

export function findLikelyLabels (wb: Workbook, vertexId: KnownVertexId): Label[] {
  const at = vertexIdToReference(wb._model, vertexId)!.withPrefix({ workbookName: '' });
  if (vertexId instanceof NameVertexId) {
    const cell = vertexIdToCell(wb, vertexId);
    const staticRef = cell?._ast ? evaluateStaticReferenceASTNodeUnbound.call(wb, cell._ast) : null;
    if (isA1Ref(staticRef)) {
      invariant(cell);
      return [ { cell, at, text: vertexId.name, for: [ { ref: staticRef } ], type: 'text' } ];
    }
    return [];
  }
  const labels: Label[] = [];
  const sheet = wb.getSheetByIndex(vertexId.sheetIndex);
  invariant(sheet);
  for (const step of labelSearchSteppers(vertexId)) {
    let prevLabelCellInThisDirection: Cell | null = null;
    let labelsInThisDirection = 0;
    let blankConsecutiveCells = 0;
    for (let candidate = step(vertexId.topLeft()); candidate != null; candidate = step(candidate)) {
      const nextCellInStraightLine = sheet.getCellByCoords(candidate.rowIndex, candidate.colIndex);
      // If the straight-line cell is in a merged range, use the merge anchor instead
      const cell = nextCellInStraightLine?.M ? sheet.getCellByID(nextCellInStraightLine.M) : nextCellInStraightLine;
      if (cell?.v == null) {
        blankConsecutiveCells += 1;
        if (blankConsecutiveCells >= 4) {
          break;
        }
      }
      else {
        blankConsecutiveCells = 0;
      }
      const looksLikeDateOrYearLabel =
        isLikelyDateOrYearCell(cell) &&
        cellsOnEachSidePerpendicularToSearchDirection(step)(candidate).some(
          sideNeighbor => sideNeighbor && isLikelyDateOrYearCell(vertexIdToCell(wb._model, sideNeighbor)),
        );
      if (
        cell &&
        !cellStyleLooksSubordinateTo(cell, prevLabelCellInThisDirection) &&
        ((isStr(cell.v) && cell.v.trim()) || looksLikeDateOrYearLabel)
      ) {
        labels.push(makeLabel(wb, cell, at, looksLikeDateOrYearLabel ? 'date' : 'text'));
        prevLabelCellInThisDirection = cell;
        labelsInThisDirection += 1;
        if (labelsInThisDirection === MAX_LABELS_PER_DIRECTION) {
          break;
        }
      }
      else if (prevLabelCellInThisDirection != null) {
        // already found a label in this direction, and now found something that
        // _isn't_ a label. Don't look further in this direction for labels.
        break;
      }
    }
  }
  return labels;
}

const left = (vid: CellVertexId) =>
  (vid.colIndex === 0 ? null : new CellVertexId(vid.workbookKey, vid.sheetIndex, vid.rowIndex, vid.colIndex - 1));

const up = (vid: { rowIndex: number, workbookKey: number, sheetIndex: number, colIndex: number }) =>
  (vid.rowIndex === 0 ? null : new CellVertexId(vid.workbookKey, vid.sheetIndex, vid.rowIndex - 1, vid.colIndex));

const right = (vid: CellVertexId) =>
  (vid.colIndex === 0 ? null : new CellVertexId(vid.workbookKey, vid.sheetIndex, vid.rowIndex, vid.colIndex + 1));

const down = (vid: { rowIndex: number, workbookKey: number, sheetIndex: number, colIndex: number }) =>
  (vid.rowIndex === 0 ? null : new CellVertexId(vid.workbookKey, vid.sheetIndex, vid.rowIndex + 1, vid.colIndex));

export function makeLabel (wb: Workbook, cell: Cell, labeledRef: Reference, type: LabelType) {
  const at = vertexIdToReference(wb._model, cellToVertexId(cell))!.withPrefix({ workbookName: '' });
  invariant(isA1Ref(at));
  invariant(isA1Ref(labeledRef));
  return {
    cell,
    at,
    type,
    for: [ { ref: labeledRef } ], // XXX should be the whole range that the label pertains to
    text: formatCellValue(cell, wb, false),
  };
}

function cellsOnEachSidePerpendicularToSearchDirection (step: typeof left | typeof up) {
  return (vertexId: CellVertexId) => {
    if (step === left) {
      return [ up(vertexId), down(vertexId) ].filter(Boolean);
    }
    else if (step === up) {
      return [ left(vertexId), right(vertexId) ].filter(Boolean);
    }
    else {
      return [];
    }
  };
}

function labelSearchSteppers (vertexId: CellVertexId | RangeVertexId) {
  let directions: ((vid: CellVertexId) => CellVertexId | null)[];
  if (vertexId instanceof CellVertexId) {
    directions = [ left, up ];
  }
  else if (vertexId.top === vertexId.bottom) {
    directions = [ left ];
  }
  else if (vertexId.left === vertexId.right) {
    directions = [ up ];
  }
  else {
    directions = [];
  }
  return directions;
}

const TEXT_DECORATION_STYLE_NAMES = [ 'bold', 'italic', 'underline' ];

function cellStyleLooksSubordinateTo (cell: Cell, comparisonCell: Cell | null) {
  const style = cell.s;
  const comparisonStyle = comparisonCell?.s;
  if (!style || !comparisonStyle) {
    return false;
  }

  if (
    TEXT_DECORATION_STYLE_NAMES.some(decoration => !style[decoration] && comparisonStyle[decoration]) &&
    !TEXT_DECORATION_STYLE_NAMES.some(decoration => style[decoration] && !comparisonStyle[decoration])
  ) {
    return true;
  }
  return false;
}

export function islandsAndLabels (workbook: Workbook, sheet: WorkSheet, recognize2dTables = true) {
  const islands = analyzeIslands(workbook, sheet, recognize2dTables);
  const labels = islands.flatMap(({ range: islandRange, labelSequences }) => {
    return labelSequences.flatMap(labelSeq => {
      const individualLabels = chain(labelSeq.range.iterCoordinates())
        .map(({ row, column }) => makeLabel(islandRange, labelSeq, row, column, labelSeq.type, 'perpendicular'))
        .toArray();
      if (recognize2dTables && labelSeq.type === 'text' && labelSeq.range.size > 1) {
        // Also add the initial cell of a label sequence as a possible label for
        // the rest of the label sequence.
        individualLabels.push(
          makeLabel(islandRange, labelSeq, labelSeq.range.top, labelSeq.range.left, labelSeq.type, 'along'),
        );
      }
      return individualLabels;
    });
  });
  return { islands, labels };

  function makeLabel (
    islandRange: Range,
    labelSequence: LabelSequence,
    row: number,
    column: number,
    type: LabelType,
    orientation: 'along' | 'perpendicular',
  ) {
    const cell = sheet.getCellByCoords(row, column);
    const labelCellRange = new Range({ top: row, left: column });
    const at = Reference.from(labelCellRange, { sheetName: sheet.name, ctx: workbook });
    const labelSequenceIsHorizontal = labelSequence.labeledRange.top > labelSequence.range.top;
    const text = formatCellValue(cell, workbook, false);
    let forRef: A1Reference;
    if (orientation === 'perpendicular') {
      forRef = labelSequenceIsHorizontal
        ? at.offset(1, 0, islandRange.bottom - row, 1)
        : at.offset(0, 1, 1, islandRange.right - column);
    }
    else {
      forRef = labelSequenceIsHorizontal
        ? at.offset(0, 1, 1, labelSequence.range.right - column)
        : at.offset(1, 0, labelSequence.range.bottom - row, 1);
    }
    return { cell, at, text, type, for: [ { ref: forRef } ] };
  }
}
