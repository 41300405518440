/* eslint-disable max-len */

// NOTE: this is a generated file. Make changes in `generate-signatures.js` or
// the files in the data/ subfolder, and regenerate with `npm run gensigs`.
import {
  TYPE_ERROR as ERR,
  TYPE_BLANK as BLANK,
  TYPE_MISSING as MISSING,
  TYPE_NUM as NUM,
  TYPE_BOOL as BOOL,
  TYPE_STRING as STR,
  TYPE_ARRAY as ARR,
  TYPE_RANGE as RANGE,
  TYPE_LAMBDA as LAMBDA,
  TYPE_ALL as ALL,
  FLAG_VALUEBOX as BOX,
  FLAG_IS_GOOGLE_ARRAY_FUNCTION as GOOGLE_ARRAY_FUNCTION,
} from './types';
import {
  MODE_ALL,
  MODE_EXCEL as EXCEL,
  MODE_GOOGLE as GOOGLE,
  MODE_GRID_SHEET as GRID_SHEET,
  MODE_CANVAS as CANVAS,
} from '../mode';
import type { FunctionSignature } from './functions/sigtypes';

/**
 * These functions will never be supported by Apiary. This is a business
 * decision. The reason why most of these functions will never be supported
 * is that they're incompatible with the web browser environment (e.g.
 * `CALL`, `SQL.REQUEST`) or because they're a pain to implement and see
 * almost no use in the real world.
 *
 * See: https://github.com/GRID-is/Apiary/issues/51
 */
export const NEVER_SUPPORTED: string[] = [
  'ARRAY_CONSTRAIN',
  'CALL',
  'CUBEKPIMEMBER',
  'CUBEMEMBER',
  'CUBEMEMBERPROPERTY',
  'CUBERANKEDMEMBER',
  'CUBESET',
  'CUBESETCOUNT',
  'CUBEVALUE',
  'DETECTLANGUAGE',
  'EUROCONVERT',
  'FILTERXML',
  'GETPIVOTDATA',
  'GOOGLEFINANCE',
  'GOOGLETRANSLATE',
  'IMAGE',
  'IMPORTDATA',
  'IMPORTFEED',
  'IMPORTHTML',
  'IMPORTRANGE',
  'IMPORTXML',
  'REGISTER.ID',
  'RTD',
  'SPARKLINE',
  'SQL.REQUEST',
  'STOCKHISTORY',
  'WEBSERVICE',
];

// One can index the signature arrays with these constants
export const SIG_MODE = 0;
export const SIG_EXCEL_MINARGS = 1;
export const SIG_EXCEL_MAXARGS = 2;
export const SIG_GOOGLE_MINARGS = 3;
export const SIG_GOOGLE_MAXARGS = 4;
export const SIG_HEADTYPES = 5;
export const SIG_VARTYPES = 6;
export const SIG_TAILTYPES = 7;
export const SIG_RETURNTYPE = 8;
export const SIG_FLAGS = 9;

const ATOM = NUM | BOOL | STR;
const VALUE = ATOM | ARR | RANGE;

// Arguments to functions in the "Excel Analysis ToolPak" have this type
const ATP_ARG = MISSING | RANGE | ERR | ATOM;

// Maximum number expressible by signed 32 bit integer (2^32 - 1).
const S32_MAX = 2147483647;

export const defaultSignature: FunctionSignature = [ MODE_ALL, 0, -1, -1, -1, [], [ ALL ], [], ALL, 0 ];

/**
 * GRID specific function signatures
 */
const gridSigs: { [s: string]: FunctionSignature[] } = {
  // BEGIN experimental canvas functions
  'COLOR': [ [ CANVAS, 1, -1, -1, -1, [ NUM | STR ], [], [], NUM, 0 ] ],
  'COLORSCALE': [ [ CANVAS, 2, -1, -1, -1, [ NUM ], [ NUM | STR ], [], NUM, 0 ] ],
  'HSL': [ [ CANVAS, 3, -1, -1, -1, [ NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'RGB': [ [ CANVAS, 3, -1, -1, -1, [ NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'MIXCOLOR': [ [ CANVAS, 3, -1, -1, -1, [ NUM, NUM | STR, NUM | STR ], [], [], NUM, 0 ] ],
  'ELLIPSE': [ [ CANVAS, 4, -1, -1, -1, [ NUM, NUM, NUM, NUM, MISSING | NUM | STR ], [], [], BOOL, 0 ] ],
  'SELLIPSE': [ [ CANVAS, 4, -1, -1, -1, [ NUM, NUM, NUM, NUM, MISSING | NUM | STR, MISSING | NUM ], [], [], BOOL, 0 ] ],
  'LINE': [ [ CANVAS, 4, -1, -1, -1, [ NUM, NUM, NUM, NUM, MISSING | NUM | STR, MISSING | NUM ], [], [], BOOL, 0 ] ],
  'RECT': [ [ CANVAS, 4, -1, -1, -1, [ NUM, NUM, NUM, NUM, MISSING | NUM | STR ], [], [], BOOL, 0 ] ],
  'SRECT': [ [ CANVAS, 4, -1, -1, -1, [ NUM, NUM, NUM, NUM, MISSING | NUM | STR, MISSING | NUM ], [], [], BOOL, 0 ] ],
  'POLYGON': [ [ CANVAS, 2, -1, -1, -1, [ RANGE | ARR, RANGE | ARR, MISSING | NUM | STR ], [], [], BOOL, 0 ] ],
  'POLYLINE': [
    [ CANVAS, 2, -1, -1, -1, [ RANGE | ARR, RANGE | ARR, MISSING | NUM | STR, MISSING | NUM ], [], [], BOOL, 0 ],
  ],
  'SPOLYGON': [
    [ CANVAS, 2, -1, -1, -1, [ RANGE | ARR, RANGE | ARR, MISSING | NUM | STR, MISSING | NUM ], [], [], BOOL, 0 ],
  ],
  'FONT': [ [ CANVAS, 1, -1, -1, -1, [ NUM, MISSING | STR ], [], [], BOOL, 0 ] ],
  'PRINT': [ [ CANVAS, 1, -1, -1, -1, [ STR, NUM, NUM, MISSING | NUM | STR, MISSING | STR ], [], [], BOOL, 0 ] ],
  'TRANSLATE': [ [ CANVAS, 2, -1, -1, -1, [ NUM, NUM ], [], [], BOOL, 0 ] ],
  'WIDTH': [ [ CANVAS, 0, -1, -1, -1, [], [], [], NUM, 0 ] ],
  'HEIGHT': [ [ CANVAS, 0, -1, -1, -1, [], [], [], NUM, 0 ] ],
  // END experimental canvas functions
  'GRID.ERROR': [ [ GRID_SHEET | CANVAS, 0, 1, 0, 1, [ STR | RANGE ], [], [], BOOL, 0 ] ], // the return type is a lie; this always throws an error
  'GRID.ISPRINT': [ [ GRID_SHEET | CANVAS, 0, -1, -1, -1, [], [], [], BOOL, 0 ] ],
  'GRID.ISMOBILE': [ [ GRID_SHEET | CANVAS, 0, -1, -1, -1, [], [], [], BOOL, 0 ] ],
  'GRID.USERNAME': [ [ GRID_SHEET | CANVAS, 0, -1, -1, -1, [], [], [], STR, 0 ] ],
  'GRID.SCENARIO': [ [ GRID_SHEET, 0, -1, -1, -1, [], [], [], STR, 0 ] ],
};

/**
 * Google Sheets specific function signatures
 */
const googleSheetsSigs: { [s: string]: FunctionSignature[] } = {
  // Google Sheets operator functions
  ADD: [ [ GOOGLE, 2, -1, -1, -1, [ NUM, NUM ], [], [], NUM, BOX ] ],
  MINUS: [ [ GOOGLE, 2, -1, -1, -1, [ NUM, NUM ], [], [], NUM, BOX ] ],
  MULTIPLY: [ [ GOOGLE, 2, -1, -1, -1, [ NUM, NUM ], [], [], NUM, BOX ] ],
  DIVIDE: [ [ GOOGLE, 2, -1, -1, -1, [ NUM, NUM ], [], [], NUM, BOX ] ],
  POW: [ [ GOOGLE, 2, -1, -1, -1, [ NUM, NUM ], [], [], NUM, BOX ] ],
  UNARY_PERCENT: [ [ GOOGLE, 1, -1, -1, -1, [ NUM ], [], [], NUM, BOX ] ],
  UMINUS: [ [ GOOGLE, 1, -1, -1, -1, [ NUM ], [], [], NUM, BOX ] ],
  EQ: [ [ GOOGLE, 2, -1, -1, -1, [ BLANK | ATOM, BLANK | ATOM ], [], [], BOOL, 0 ] ],
  NE: [ [ GOOGLE, 2, -1, -1, -1, [ BLANK | ATOM, BLANK | ATOM ], [], [], BOOL, 0 ] ],
  LT: [ [ GOOGLE, 2, -1, -1, -1, [ BLANK | ATOM, BLANK | ATOM ], [], [], BOOL, 0 ] ],
  LTE: [ [ GOOGLE, 2, -1, -1, -1, [ BLANK | ATOM, BLANK | ATOM ], [], [], BOOL, 0 ] ],
  GT: [ [ GOOGLE, 2, -1, -1, -1, [ BLANK | ATOM, BLANK | ATOM ], [], [], BOOL, 0 ] ],
  GTE: [ [ GOOGLE, 2, -1, -1, -1, [ BLANK | ATOM, BLANK | ATOM ], [], [], BOOL, 0 ] ],
  // Google Sheets non-Excel functions
  QUERY: [
    [
      GOOGLE | CANVAS | GRID_SHEET,
      1,
      3,
      -1,
      -1,
      [ MISSING | ARR | RANGE, STR | MISSING, NUM ],
      [],
      [],
      ARR,
      GOOGLE_ARRAY_FUNCTION,
    ],
  ],
  REGEXMATCH: [
    [
      GOOGLE | CANVAS | GRID_SHEET,
      2,
      2,
      -1,
      -1,
      [ BLANK | MISSING | ATOM, BLANK | MISSING | ATOM ],
      [],
      [],
      BOOL | ERR,
      0,
    ],
  ],
  REGEXREPLACE: [
    [
      GOOGLE | CANVAS | GRID_SHEET,
      3,
      3,
      -1,
      -1,
      [ BLANK | MISSING | ATOM, BLANK | MISSING | ATOM, BLANK | MISSING | ATOM ],
      [],
      [],
      STR | ERR,
      0,
    ],
  ],
  REGEXEXTRACT: [
    [
      GOOGLE | CANVAS | GRID_SHEET,
      2,
      2,
      -1,
      -1,
      [ BLANK | MISSING | ATOM, BLANK | MISSING | ATOM ],
      [],
      [],
      STR | ARR | ERR,
      0,
    ],
  ],
  IMLOG: [ [ GOOGLE, 1, 2, -1, -1, [ ATP_ARG, ATP_ARG ], [], [], ERR | STR, 0 ] ],
  IMTANH: [ [ GOOGLE, 1, 1, -1, -1, [ ATP_ARG ], [], [], ERR | STR, 0 ] ],
  IMCOTH: [ [ GOOGLE, 1, 1, -1, -1, [ ATP_ARG ], [], [], ERR | STR, 0 ] ],
  ISBETWEEN: [ [ GOOGLE, 3, 5, -1, -1, [ ARR | ATOM, ARR | ATOM, ARR | ATOM, BOOL, BOOL ], [], [], BOOL, 0 ] ],
  ISURL: [ [ GOOGLE, 1, 1, -1, -1, [ STR ], [], [], BOOL, 0 ] ],
  ISEMAIL: [ [ GOOGLE, 1, 1, -1, -1, [ STR ], [], [], BOOL, 0 ] ],
  ISDATE: [ [ GOOGLE, 1, 1, -1, -1, [ STR | NUM ], [], [], BOOL, BOX ] ],
  TO_DATE: [ [ GOOGLE, 1, 1, -1, -1, [ BLANK | ERR | ATOM ], [], [], BLANK | ERR | ATOM, 0 ] ],
  TO_DOLLARS: [ [ GOOGLE, 1, 1, -1, -1, [ BLANK | ERR | ATOM ], [], [], BLANK | ERR | ATOM, 0 ] ],
  TO_PERCENT: [ [ GOOGLE, 1, 1, -1, -1, [ BLANK | ERR | ATOM ], [], [], BLANK | ERR | ATOM, 0 ] ],
  TO_PURE_NUMBER: [ [ GOOGLE, 1, 1, -1, -1, [ BLANK | ERR | ATOM ], [], [], BLANK | ERR | ATOM, 0 ] ],
  TO_TEXT: [ [ GOOGLE, 1, 1, -1, -1, [ BLANK | ERR | ATOM ], [], [], STR, 0 ] ],
  ARRAYFORMULA: [
    [ GOOGLE, 1, 1, -1, -1, [ VALUE | MISSING | BLANK | ERR ], [], [], VALUE | MISSING | BLANK, GOOGLE_ARRAY_FUNCTION ],
  ],
  SPLIT: [ [ GOOGLE, 2, 4, -1, -1, [ STR, STR, BOOL | MISSING, BOOL | MISSING ], [], [], ARR, 0 ] ],
  EPOCHTODATE: [ [ GOOGLE, 1, 2, -1, -1, [ ATOM, NUM | MISSING ], [], [], ERR | NUM, 0 ] ],
  JOIN: [ [ GOOGLE, 2, S32_MAX, -1, -1, [ BLANK | ARR | RANGE ], [ STR | ARR | RANGE ], [], STR, 0 ] ],
};

/**
 * A mapping from function name to function signature.
 */
export const funcSigs: { [s: string]: FunctionSignature[] } = {
  ...gridSigs,
  ...googleSheetsSigs,
  'COUNT': [ [ MODE_ALL, 1, 255, 1, S32_MAX, [], [ ARR | RANGE | ERR | NUM ], [], NUM, 0 ] ],
  'IF': [ [ MODE_ALL, 2, 3, 2, 3, [ ARR | BOOL, ALL, ALL ], [], [], LAMBDA | VALUE, BOX ] ],
  'ISNA': [ [ MODE_ALL, 1, 1, 1, 1, [ LAMBDA | ERR | ATOM ], [], [], BOOL, 0 ] ],
  'ISERROR': [ [ MODE_ALL, 1, 1, 1, 1, [ LAMBDA | ERR | ATOM ], [], [], BOOL, 0 ] ],
  'SUM': [ [ MODE_ALL, 1, 255, 1, S32_MAX, [], [ LAMBDA | ARR | RANGE | NUM ], [], NUM, 0 ] ],
  'AVERAGE': [ [ MODE_ALL, 1, 255, 1, S32_MAX, [], [ ARR | RANGE | NUM ], [], NUM, 0 ] ],
  'MIN': [ [ MODE_ALL, 1, 255, 1, S32_MAX, [], [ ARR | RANGE | NUM ], [], NUM, 0 ] ],
  'MAX': [ [ MODE_ALL, 1, 255, 1, S32_MAX, [], [ ARR | RANGE | NUM ], [], NUM, 0 ] ],
  'ROW': [ [ MODE_ALL, 0, 1, 0, 1, [ RANGE ], [], [], ARR | NUM, 0 ] ],
  'COLUMN': [ [ MODE_ALL, 0, 1, 0, 1, [ RANGE ], [], [], ARR | NUM, 0 ] ],
  'NA': [ [ MODE_ALL, 0, 0, 0, 0, [], [], [], STR | NUM, 0 ] ],
  'NPV': [ [ MODE_ALL, 2, 255, 2, S32_MAX, [ NUM ], [ ARR | RANGE | NUM ], [], NUM, 0 ] ],
  'STDEV': [ [ MODE_ALL, 1, 255, -1, -1, [], [ ARR | RANGE | NUM ], [], NUM, 0 ] ],
  'DOLLAR': [ [ MODE_ALL, 1, 2, 1, 2, [ NUM, NUM ], [], [], STR, 0 ] ],
  'FIXED': [ [ MODE_ALL, 1, 3, 1, 3, [ NUM, MISSING | NUM, BOOL ], [], [], STR, 0 ] ],
  'SIN': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'COS': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'TAN': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'ATAN': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'PI': [ [ MODE_ALL, 0, 0, 0, 0, [], [], [], NUM, 0 ] ],
  'SQRT': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'EXP': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'LN': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'LOG10': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'ABS': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'INT': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'SIGN': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'ROUND': [ [ MODE_ALL, 1, 2, 1, 2, [ NUM, NUM ], [], [], NUM, 0 ] ],
  'LOOKUP': [ [ MODE_ALL, 2, 3, 2, 3, [ LAMBDA | ATOM, ARR | RANGE | ERR, ARR | RANGE ], [], [], LAMBDA | ATOM, 0 ] ],
  'INDEX': [ [ MODE_ALL, 2, 4, 1, 4, [ ARR | RANGE, NUM, NUM, NUM ], [], [], LAMBDA | VALUE, GOOGLE_ARRAY_FUNCTION ] ],
  'REPT': [ [ MODE_ALL, 2, 2, 2, 2, [ STR, NUM ], [], [], STR, 0 ] ],
  'MID': [ [ MODE_ALL, 3, 3, 3, 3, [ STR, NUM, NUM ], [], [], STR, 0 ] ],
  'LEN': [ [ MODE_ALL, 1, 1, 1, 1, [ STR ], [], [], NUM, 0 ] ],
  'VALUE': [ [ MODE_ALL, 1, 1, 1, 1, [ ATOM ], [], [], NUM, 0 ] ],
  'TRUE': [ [ MODE_ALL, 0, 0, 0, 0, [], [], [], BOOL, 0 ] ],
  'FALSE': [ [ MODE_ALL, 0, 0, 0, 0, [], [], [], BOOL, 0 ] ],
  'AND': [ [ MODE_ALL, 1, 255, 1, S32_MAX, [], [ ARR | RANGE | BOOL ], [], BOOL, 0 ] ],
  'OR': [ [ MODE_ALL, 1, 255, 1, S32_MAX, [], [ ARR | RANGE | BOOL ], [], BOOL, 0 ] ],
  'NOT': [ [ MODE_ALL, 1, 1, 1, 1, [ BOOL ], [], [], BOOL, 0 ] ],
  'MOD': [ [ MODE_ALL, 2, 2, 2, 2, [ NUM, NUM ], [], [], NUM, 0 ] ],
  'DCOUNT': [
    [ MODE_ALL, 3, 3, 3, 3, [ RANGE | ARR, MISSING | STR | NUM, RANGE | STR | ARR ], [], [], NUM, GOOGLE_ARRAY_FUNCTION ],
  ],
  'DSUM': [ [ MODE_ALL, 3, 3, 3, 3, [ RANGE | ARR, STR | NUM, RANGE | STR | ARR ], [], [], NUM, GOOGLE_ARRAY_FUNCTION ] ],
  'DAVERAGE': [ [ MODE_ALL, 3, 3, 3, 3, [ RANGE | ARR, STR | NUM, RANGE | STR | ARR ], [], [], NUM, GOOGLE_ARRAY_FUNCTION ] ],
  'DMIN': [ [ MODE_ALL, 3, 3, 3, 3, [ RANGE | ARR, STR | NUM, RANGE | STR | ARR ], [], [], NUM, GOOGLE_ARRAY_FUNCTION ] ],
  'DMAX': [ [ MODE_ALL, 3, 3, 3, 3, [ RANGE | ARR, STR | NUM, RANGE | STR | ARR ], [], [], NUM, GOOGLE_ARRAY_FUNCTION ] ],
  'DSTDEV': [ [ MODE_ALL, 3, 3, 3, 3, [ RANGE | ARR, STR | NUM, RANGE | STR | ARR ], [], [], NUM, GOOGLE_ARRAY_FUNCTION ] ],
  'VAR': [ [ MODE_ALL, 1, 255, -1, -1, [], [ ARR | RANGE | NUM ], [], NUM, 0 ] ],
  'DVAR': [ [ MODE_ALL, 3, 3, 3, 3, [ RANGE | ARR, STR | NUM, RANGE | STR | ARR ], [], [], NUM, GOOGLE_ARRAY_FUNCTION ] ],
  'TEXT': [ [ MODE_ALL, 2, 2, 2, 2, [ LAMBDA | ATOM, STR ], [], [], STR, 0 ] ],
  'LINEST': [
    [
      MODE_ALL,
      1,
      4,
      1,
      4,
      [ ARR | RANGE, MISSING | ARR | RANGE, MISSING | RANGE | BOOL, MISSING | RANGE | BOOL ],
      [],
      [],
      ARR,
      0,
    ],
  ],
  'TREND': [
    [
      MODE_ALL,
      1,
      4,
      1,
      4,
      [ ARR | RANGE, MISSING | ARR | RANGE, MISSING | ARR | RANGE, MISSING | RANGE | BOOL ],
      [],
      [],
      ARR,
      0,
    ],
  ],
  'LOGEST': [
    [
      MODE_ALL,
      1,
      4,
      1,
      4,
      [ ARR | RANGE, MISSING | ARR | RANGE, MISSING | RANGE | BOOL, MISSING | RANGE | BOOL ],
      [],
      [],
      ARR,
      0,
    ],
  ],
  'GROWTH': [
    [
      MODE_ALL,
      1,
      4,
      1,
      4,
      [ ARR | RANGE, MISSING | ARR | RANGE, MISSING | ARR | RANGE, MISSING | RANGE | BOOL ],
      [],
      [],
      ARR,
      0,
    ],
  ],
  'PV': [ [ MODE_ALL, 3, 5, 3, 5, [ NUM, NUM, NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'FV': [ [ MODE_ALL, 3, 5, 3, 5, [ NUM, NUM, NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'NPER': [ [ MODE_ALL, 3, 5, 3, 5, [ NUM, NUM, NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'PMT': [ [ MODE_ALL, 3, 5, 3, 5, [ NUM, NUM, NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'RATE': [ [ MODE_ALL, 3, 6, 3, 6, [ NUM, NUM, NUM, NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'MIRR': [ [ MODE_ALL, 3, 3, 3, 3, [ ARR | RANGE, NUM, NUM ], [], [], NUM, 0 ] ],
  'IRR': [ [ MODE_ALL, 1, 2, 1, 2, [ ARR | RANGE, NUM ], [], [], NUM, 0 ] ],
  'RAND': [ [ MODE_ALL, 0, 0, 0, 0, [], [], [], NUM, 0 ] ],
  'MATCH': [ [ MODE_ALL, 2, 3, 2, 3, [ LAMBDA | ATOM, LAMBDA | ARR | RANGE | NUM, RANGE | NUM ], [], [], NUM, 0 ] ],
  'DATE': [ [ MODE_ALL, 3, 3, 3, 3, [ NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'TIME': [ [ MODE_ALL, 3, 3, 3, 3, [ NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'DAY': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'MONTH': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'YEAR': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'WEEKDAY': [ [ MODE_ALL, 1, 2, 1, 2, [ NUM, NUM ], [], [], NUM, 0 ] ],
  'HOUR': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'MINUTE': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'SECOND': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'NOW': [ [ MODE_ALL, 0, 0, 0, 0, [], [], [], NUM, 0 ] ],
  'AREAS': [ [ EXCEL | GOOGLE | CANVAS, 1, 1, -1, -1, [ RANGE ], [], [], NUM, 0 ] ],
  'ROWS': [ [ MODE_ALL, 1, 1, 1, 1, [ ARR | RANGE ], [], [], NUM, 0 ] ],
  'COLUMNS': [ [ MODE_ALL, 1, 1, 1, 1, [ ARR | RANGE ], [], [], NUM, 0 ] ],
  'OFFSET': [
    [ MODE_ALL, 3, 5, 3, 5, [ RANGE, MISSING | NUM, MISSING | NUM, MISSING | NUM, MISSING | NUM ], [], [], RANGE, 0 ],
  ],
  'SEARCH': [ [ MODE_ALL, 2, 3, 2, 3, [ STR, STR, NUM ], [], [], NUM, 0 ] ],
  'TRANSPOSE': [ [ MODE_ALL, 1, 1, 1, 1, [ ARR | ATOM ], [], [], ARR, GOOGLE_ARRAY_FUNCTION ] ],
  'TYPE': [ [ MODE_ALL, 1, 1, 1, 1, [ LAMBDA | ARR | ERR | ATOM ], [], [], NUM, 0 ] ],
  'ATAN2': [ [ MODE_ALL, 2, 2, 2, 2, [ NUM, NUM ], [], [], NUM, 0 ] ],
  'ASIN': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'ACOS': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'CHOOSE': [ [ MODE_ALL, 2, 255, 2, S32_MAX, [ ARR | NUM ], [ LAMBDA | ERR | VALUE ], [], LAMBDA | VALUE, 0 ] ],
  'HLOOKUP': [
    [ MODE_ALL, 3, 4, 3, 4, [ LAMBDA | ATOM, ARR | RANGE | NUM, ARR | RANGE | NUM, BOOL ], [], [], LAMBDA | ARR | ATOM, 0 ],
  ],
  'VLOOKUP': [
    [ MODE_ALL, 3, 4, 3, 4, [ LAMBDA | ATOM, ARR | RANGE | NUM, ARR | RANGE | NUM, BOOL ], [], [], LAMBDA | ARR | ATOM, 0 ],
  ],
  'ISREF': [ [ MODE_ALL, 1, 1, 1, 1, [ ERR | VALUE ], [], [], BOOL, 0 ] ],
  'LOG': [ [ MODE_ALL, 1, 2, 1, 2, [ NUM, NUM ], [], [], NUM, 0 ] ],
  'CHAR': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], STR, 0 ] ],
  'LOWER': [ [ MODE_ALL, 1, 1, 1, 1, [ STR ], [], [], STR, 0 ] ],
  'UPPER': [ [ MODE_ALL, 1, 1, 1, 1, [ STR ], [], [], STR, 0 ] ],
  'PROPER': [ [ MODE_ALL, 1, 1, 1, 1, [ STR ], [], [], STR, 0 ] ],
  'LEFT': [ [ MODE_ALL, 1, 2, 1, 2, [ STR, ERR | NUM ], [], [], STR, 0 ] ],
  'RIGHT': [ [ MODE_ALL, 1, 2, 1, 2, [ STR, ERR | NUM ], [], [], STR, 0 ] ],
  'EXACT': [ [ MODE_ALL, 2, 2, 2, 2, [ LAMBDA | STR, LAMBDA | STR ], [], [], BOOL, 0 ] ],
  'TRIM': [ [ MODE_ALL, 1, 1, 1, 1, [ STR ], [], [], STR, 0 ] ],
  'REPLACE': [ [ MODE_ALL, 4, 4, 4, 4, [ STR, NUM, NUM, STR ], [], [], STR, 0 ] ],
  'SUBSTITUTE': [ [ MODE_ALL, 3, 4, 3, 4, [ STR, STR, STR, STR ], [], [], STR, 0 ] ],
  'CODE': [ [ MODE_ALL, 1, 1, 1, 1, [ STR ], [], [], NUM, 0 ] ],
  'FIND': [ [ MODE_ALL, 2, 3, 2, 3, [ STR, STR, NUM ], [], [], NUM, 0 ] ],
  'CELL': [ [ MODE_ALL, 1, 2, 2, 2, [ STR, RANGE | STR ], [], [], LAMBDA | ARR | ERR | ATOM, 0 ] ],
  'ISERR': [ [ MODE_ALL, 1, 1, 1, 1, [ LAMBDA | ERR | ATOM ], [], [], BOOL, 0 ] ],
  'ISTEXT': [ [ MODE_ALL, 1, 1, 1, 1, [ LAMBDA | BLANK | ERR | ATOM ], [], [], BOOL, 0 ] ],
  'ISNUMBER': [ [ MODE_ALL, 1, 1, 1, 1, [ LAMBDA | BLANK | ERR | ATOM ], [], [], BOOL, 0 ] ],
  'ISBLANK': [ [ MODE_ALL, 1, 1, 1, 1, [ LAMBDA | BLANK | ERR | ATOM ], [], [], BOOL, 0 ] ],
  'T': [ [ MODE_ALL, 1, 1, 1, 1, [ RANGE | ATOM ], [], [], STR, 0 ] ],
  'N': [ [ MODE_ALL, 1, 1, 1, 1, [ RANGE | ATOM ], [], [], NUM, 0 ] ],
  'DATEVALUE': [ [ MODE_ALL, 1, 1, 1, 1, [ STR | NUM ], [], [], NUM, BOX ] ],
  'TIMEVALUE': [ [ MODE_ALL, 1, 1, 1, 1, [ STR | NUM ], [], [], NUM, 0 ] ],
  'SLN': [ [ MODE_ALL, 3, 3, 3, 3, [ NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'SYD': [ [ MODE_ALL, 4, 4, 4, 4, [ NUM, NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'DDB': [ [ MODE_ALL, 4, 5, 4, 5, [ NUM, NUM, NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'INDIRECT': [ [ MODE_ALL, 1, 2, 1, 2, [ STR, BOOL ], [], [], RANGE, 0 ] ],
  'CLEAN': [ [ MODE_ALL, 1, 1, 1, 1, [ STR ], [], [], STR, 0 ] ],
  'MDETERM': [ [ MODE_ALL, 1, 1, 1, 1, [ ARR ], [], [], NUM, 0 ] ],
  'MINVERSE': [ [ MODE_ALL, 1, 1, 1, 1, [ ARR ], [], [], ARR, 0 ] ],
  'MMULT': [ [ MODE_ALL, 2, 2, 2, 2, [ ARR, ARR ], [], [], ARR, 0 ] ],
  'IPMT': [ [ MODE_ALL, 4, 6, 4, 6, [ NUM, NUM, NUM, NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'PPMT': [ [ MODE_ALL, 4, 6, 4, 6, [ NUM, NUM, NUM, NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'COUNTA': [ [ MODE_ALL, 1, 255, 1, S32_MAX, [], [ MISSING | ARR | RANGE | ERR | NUM ], [], NUM, 0 ] ],
  'PRODUCT': [ [ MODE_ALL, 1, 255, 1, S32_MAX, [], [ MISSING | ARR | RANGE | NUM ], [], NUM, 0 ] ],
  'FACT': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'DPRODUCT': [ [ MODE_ALL, 3, 3, 3, 3, [ RANGE | ARR, STR | NUM, RANGE | STR | ARR ], [], [], NUM, GOOGLE_ARRAY_FUNCTION ] ],
  'ISNONTEXT': [ [ MODE_ALL, 1, 1, 1, 1, [ BLANK | ERR | ATOM ], [], [], BOOL, 0 ] ],
  'STDEVP': [ [ MODE_ALL, 1, 255, -1, -1, [], [ ARR | RANGE | NUM ], [], NUM, 0 ] ],
  'VARP': [ [ MODE_ALL, 1, 255, -1, -1, [], [ ARR | RANGE | NUM ], [], NUM, 0 ] ],
  'DSTDEVP': [ [ MODE_ALL, 3, 3, 3, 3, [ RANGE | ARR, STR | NUM, RANGE | STR | ARR ], [], [], NUM, GOOGLE_ARRAY_FUNCTION ] ],
  'DVARP': [ [ MODE_ALL, 3, 3, 3, 3, [ RANGE | ARR, STR | NUM, RANGE | STR | ARR ], [], [], NUM, GOOGLE_ARRAY_FUNCTION ] ],
  'TRUNC': [ [ MODE_ALL, 1, 2, -1, -1, [ NUM, NUM ], [], [], NUM, 0 ] ],
  'ISLOGICAL': [ [ MODE_ALL, 1, 1, 1, 1, [ BLANK | ERR | ATOM ], [], [], BOOL, 0 ] ],
  'DCOUNTA': [
    [ MODE_ALL, 3, 3, 3, 3, [ RANGE | ARR, MISSING | STR | NUM, RANGE | STR | ARR ], [], [], NUM, GOOGLE_ARRAY_FUNCTION ],
  ],
  'USDOLLAR': [ [ MODE_ALL, 1, 2, 1, 2, [ NUM, NUM ], [], [], STR, 0 ] ],
  'FINDB': [ [ MODE_ALL, 2, 3, 2, 3, [ STR, STR, NUM ], [], [], NUM, 0 ] ],
  'SEARCHB': [ [ MODE_ALL, 2, 3, 2, 3, [ STR, STR, NUM ], [], [], NUM, 0 ] ],
  'REPLACEB': [ [ MODE_ALL, 4, 4, 4, 4, [ STR, NUM, NUM, STR ], [], [], STR, 0 ] ],
  'LEFTB': [ [ MODE_ALL, 1, 2, 1, 2, [ STR, ERR | NUM ], [], [], STR, 0 ] ],
  'RIGHTB': [ [ MODE_ALL, 1, 2, 1, 2, [ STR, ERR | NUM ], [], [], STR, 0 ] ],
  'MIDB': [ [ MODE_ALL, 3, 3, 3, 3, [ STR, NUM, NUM ], [], [], STR, 0 ] ],
  'LENB': [ [ MODE_ALL, 1, 1, 1, 1, [ STR ], [], [], NUM, 0 ] ],
  'ROUNDUP': [ [ MODE_ALL, 1, 2, 1, 2, [ NUM, NUM ], [], [], NUM, 0 ] ],
  'ROUNDDOWN': [ [ MODE_ALL, 1, 2, 1, 2, [ NUM, NUM ], [], [], NUM, 0 ] ],
  'ASC': [ [ MODE_ALL, 1, 1, 1, 1, [ STR ], [], [], STR, 0 ] ],
  'DBCS': [ [ MODE_ALL, 1, 1, -1, -1, [ STR ], [], [], STR, 0 ] ],
  'RANK': [ [ MODE_ALL, 2, 3, 2, 3, [ NUM, RANGE, BOOL ], [], [], NUM, 0 ] ],
  'ADDRESS': [
    [ MODE_ALL, 2, 5, 2, 5, [ NUM, MISSING | NUM, MISSING | NUM, MISSING | BOOL, MISSING | STR ], [], [], STR, 0 ],
  ],
  'DAYS360': [ [ MODE_ALL, 2, 3, 2, 3, [ NUM, NUM, BOOL ], [], [], NUM, 0 ] ],
  'TODAY': [ [ MODE_ALL, 0, 0, 0, 0, [], [], [], NUM, 0 ] ],
  'VDB': [ [ MODE_ALL, 5, 7, 5, 7, [ NUM, NUM, NUM, NUM, NUM, NUM, BOOL ], [], [], NUM, 0 ] ],
  'MEDIAN': [ [ MODE_ALL, 1, 255, 1, S32_MAX, [], [ ARR | RANGE | NUM ], [], NUM, 0 ] ],
  'SUMPRODUCT': [ [ MODE_ALL, 1, 255, 1, S32_MAX, [], [ ARR ], [], NUM, GOOGLE_ARRAY_FUNCTION ] ],
  'SINH': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'COSH': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'TANH': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'ASINH': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'ACOSH': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'ATANH': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'DGET': [
    [ MODE_ALL, 3, 3, 3, 3, [ RANGE | ARR, STR | NUM, RANGE | STR | ARR ], [], [], LAMBDA | ATOM, GOOGLE_ARRAY_FUNCTION ],
  ],
  'INFO': [ [ MODE_ALL, 1, 1, -1, -1, [ STR ], [], [], STR | NUM, 0 ] ],
  'DB': [ [ MODE_ALL, 4, 5, 4, 5, [ NUM, NUM, NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'FREQUENCY': [ [ MODE_ALL, 2, 2, 2, 2, [ ARR | RANGE, ARR | RANGE ], [], [], ARR, 0 ] ],
  'ERROR.TYPE': [ [ MODE_ALL, 1, 1, -1, -1, [ ERR | ATOM ], [], [], NUM, 0 ] ],
  'AVEDEV': [ [ MODE_ALL, 1, 255, 1, S32_MAX, [], [ ARR | RANGE | NUM ], [], NUM, 0 ] ],
  'BETADIST': [ [ MODE_ALL, 3, 5, 3, 5, [ NUM, NUM, NUM, MISSING | NUM, MISSING | NUM ], [], [], NUM, 0 ] ],
  'GAMMALN': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'BETAINV': [ [ MODE_ALL, 3, 5, 3, 5, [ NUM, NUM, NUM, MISSING | NUM, MISSING | NUM ], [], [], NUM, 0 ] ],
  'BINOMDIST': [ [ MODE_ALL, 4, 4, 4, 4, [ NUM, NUM, NUM, BOOL ], [], [], NUM, 0 ] ],
  'CHIDIST': [ [ MODE_ALL, 2, 2, 2, 2, [ NUM, NUM ], [], [], NUM, 0 ] ],
  'CHIINV': [ [ MODE_ALL, 2, 2, 2, 2, [ NUM, NUM ], [], [], NUM, 0 ] ],
  'COMBIN': [ [ MODE_ALL, 2, 2, 2, 2, [ NUM, NUM ], [], [], NUM, 0 ] ],
  'CONFIDENCE': [ [ MODE_ALL, 3, 3, 3, 3, [ NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'CRITBINOM': [ [ MODE_ALL, 3, 3, 3, 3, [ NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'EVEN': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'EXPONDIST': [ [ MODE_ALL, 3, 3, 3, 3, [ NUM, NUM, BOOL ], [], [], NUM, 0 ] ],
  'FDIST': [ [ MODE_ALL, 3, 3, -1, -1, [ NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'FINV': [ [ MODE_ALL, 3, 3, 3, 3, [ NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'FISHER': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'FISHERINV': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'FLOOR': [ [ MODE_ALL, 2, 2, 1, 2, [ NUM, NUM ], [], [], NUM, 0 ] ],
  'GAMMADIST': [ [ MODE_ALL, 4, 4, 4, 4, [ NUM, NUM, NUM, BOOL ], [], [], NUM, 0 ] ],
  'GAMMAINV': [ [ MODE_ALL, 3, 3, 3, 3, [ NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'CEILING': [ [ MODE_ALL, 2, 2, 1, 2, [ NUM, NUM ], [], [], NUM, 0 ] ],
  'HYPGEOMDIST': [ [ MODE_ALL, 4, 4, 4, 4, [ NUM, NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'LOGNORMDIST': [ [ MODE_ALL, 3, 3, 3, 3, [ NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'LOGINV': [ [ MODE_ALL, 3, 3, 3, 3, [ NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'NEGBINOMDIST': [ [ MODE_ALL, 3, 3, 3, 3, [ NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'NORMDIST': [ [ MODE_ALL, 4, 4, 4, 4, [ NUM, NUM, NUM, BOOL ], [], [], NUM, 0 ] ],
  'NORMSDIST': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'NORMINV': [ [ MODE_ALL, 3, 3, 3, 3, [ NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'NORMSINV': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'STANDARDIZE': [ [ MODE_ALL, 3, 3, 3, 3, [ NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'ODD': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'PERMUT': [ [ MODE_ALL, 2, 2, 2, 2, [ NUM, NUM ], [], [], NUM, 0 ] ],
  'POISSON': [ [ MODE_ALL, 3, 3, 3, 3, [ NUM, NUM, BOOL ], [], [], NUM, 0 ] ],
  'TDIST': [ [ MODE_ALL, 3, 3, 3, 3, [ NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'WEIBULL': [ [ MODE_ALL, 4, 4, 4, 4, [ NUM, NUM, NUM, BOOL ], [], [], NUM, 0 ] ],
  'SUMXMY2': [ [ MODE_ALL, 2, 2, 2, 2, [ ARR, ARR ], [], [], NUM, 0 ] ],
  'SUMX2MY2': [ [ MODE_ALL, 2, 2, 2, 2, [ ARR, ARR ], [], [], NUM, 0 ] ],
  'SUMX2PY2': [ [ MODE_ALL, 2, 2, 2, 2, [ ARR, ARR ], [], [], NUM, 0 ] ],
  'CHITEST': [ [ MODE_ALL, 2, 2, -1, -1, [ ARR, ARR ], [], [], NUM, 0 ] ],
  'CORREL': [ [ MODE_ALL, 2, 2, -1, -1, [ ARR, ARR ], [], [], NUM, 0 ] ],
  'COVAR': [ [ MODE_ALL, 2, 2, -1, -1, [ ARR, ARR ], [], [], NUM, 0 ] ],
  'FORECAST': [ [ MODE_ALL, 3, 3, 3, 3, [ NUM, ARR, ARR ], [], [], NUM, 0 ] ],
  'FTEST': [ [ MODE_ALL, 2, 2, -1, -1, [ ARR, ARR ], [], [], NUM, 0 ] ],
  'INTERCEPT': [ [ MODE_ALL, 2, 2, 2, 2, [ ARR, ARR ], [], [], NUM, 0 ] ],
  'PEARSON': [ [ MODE_ALL, 2, 2, -1, -1, [ ARR, ARR ], [], [], NUM, 0 ] ],
  'RSQ': [ [ MODE_ALL, 2, 2, 2, 2, [ ARR, ARR ], [], [], NUM, 0 ] ],
  'STEYX': [ [ MODE_ALL, 2, 2, 2, 2, [ ARR, ARR ], [], [], NUM, 0 ] ],
  'SLOPE': [ [ MODE_ALL, 2, 2, 2, 2, [ ARR, ARR ], [], [], NUM, 0 ] ],
  'TTEST': [ [ MODE_ALL, 4, 4, 4, 4, [ ARR, ARR, NUM, NUM ], [], [], NUM, 0 ] ],
  'PROB': [ [ MODE_ALL, 3, 4, 3, 4, [ ARR, ARR, NUM, MISSING | NUM ], [], [], NUM, 0 ] ],
  'DEVSQ': [ [ MODE_ALL, 1, 255, 1, S32_MAX, [], [ ARR | RANGE | NUM ], [], NUM, 0 ] ],
  'GEOMEAN': [ [ MODE_ALL, 1, 255, 1, S32_MAX, [], [ ARR | RANGE | NUM ], [], NUM, 0 ] ],
  'HARMEAN': [ [ MODE_ALL, 1, 255, 1, S32_MAX, [], [ ARR | RANGE | NUM ], [], NUM, 0 ] ],
  'SUMSQ': [ [ MODE_ALL, 1, 255, 1, S32_MAX, [], [ ARR | RANGE | NUM ], [], NUM, 0 ] ],
  'KURT': [ [ MODE_ALL, 1, 255, 1, S32_MAX, [], [ ARR | RANGE | NUM ], [], NUM, 0 ] ],
  'SKEW': [ [ MODE_ALL, 1, 255, 1, S32_MAX, [], [ ARR | RANGE | NUM ], [], NUM, 0 ] ],
  'ZTEST': [ [ MODE_ALL, 2, 3, 2, 3, [ ARR | RANGE | NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'LARGE': [ [ MODE_ALL, 2, 2, 2, 2, [ ARR | RANGE | NUM, NUM ], [], [], NUM, 0 ] ],
  'SMALL': [ [ MODE_ALL, 2, 2, 2, 2, [ ARR | RANGE | NUM, NUM ], [], [], NUM, 0 ] ],
  'QUARTILE': [ [ MODE_ALL, 2, 2, 2, 2, [ ARR | RANGE | NUM, NUM ], [], [], NUM, 0 ] ],
  'PERCENTILE': [ [ MODE_ALL, 2, 2, 2, 2, [ ARR | RANGE | NUM, NUM ], [], [], NUM, 0 ] ],
  'PERCENTRANK': [ [ MODE_ALL, 2, 3, 2, 3, [ ARR | RANGE | NUM, NUM, MISSING | NUM ], [], [], NUM, 0 ] ],
  'MODE': [ [ MODE_ALL, 1, 255, -1, -1, [], [ ARR ], [], NUM, 0 ] ],
  'TRIMMEAN': [ [ MODE_ALL, 2, 2, 2, 2, [ ARR | RANGE | NUM, NUM ], [], [], NUM, 0 ] ],
  'TINV': [ [ MODE_ALL, 2, 2, 2, 2, [ NUM, NUM ], [], [], NUM, 0 ] ],
  'POWER': [ [ MODE_ALL, 2, 2, 2, 2, [ NUM, NUM ], [], [], NUM, 0 ] ],
  'RADIANS': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'DEGREES': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'SUBTOTAL': [ [ MODE_ALL, 2, 255, 1, S32_MAX, [ NUM ], [ RANGE ], [], NUM, 0 ] ],
  'SUMIF': [ [ MODE_ALL, 2, 3, 2, 3, [ RANGE, ERR | ATOM, RANGE ], [], [], NUM, 0 ] ],
  'COUNTIF': [ [ MODE_ALL, 2, 2, 2, 2, [ RANGE, ERR | ATOM ], [], [], NUM, 0 ] ],
  'COUNTBLANK': [ [ MODE_ALL, 1, 1, 1, S32_MAX, [], [ RANGE | ARR ], [], NUM, 0 ] ],
  'ISPMT': [ [ MODE_ALL, 4, 4, 4, 4, [ NUM, NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'DATEDIF': [ [ MODE_ALL, 3, 3, 3, 3, [ NUM, NUM, STR ], [], [], NUM, 0 ] ],
  'ROMAN': [ [ MODE_ALL, 1, 2, 1, 2, [ NUM, BOOL | NUM ], [], [], STR, 0 ] ],
  'HYPERLINK': [ [ MODE_ALL, 1, 2, 1, 2, [ STR, ARR | ATOM ], [], [], ARR | ATOM, 0 ] ],
  'AVERAGEA': [ [ MODE_ALL, 1, 255, 1, S32_MAX, [], [ ARR | RANGE | NUM ], [], NUM, 0 ] ],
  'MAXA': [ [ MODE_ALL, 1, 255, 1, S32_MAX, [], [ ARR | RANGE | NUM ], [], NUM, 0 ] ],
  'MINA': [ [ MODE_ALL, 1, 255, 1, S32_MAX, [], [ ARR | RANGE | NUM ], [], NUM, 0 ] ],
  'STDEVPA': [ [ MODE_ALL, 1, 255, 1, S32_MAX, [], [ ARR | RANGE | NUM ], [], NUM, 0 ] ],
  'VARPA': [ [ MODE_ALL, 1, 255, 1, S32_MAX, [], [ ARR | RANGE | NUM ], [], NUM, 0 ] ],
  'STDEVA': [ [ MODE_ALL, 1, 255, 1, S32_MAX, [], [ ARR | RANGE | NUM ], [], NUM, 0 ] ],
  'VARA': [ [ MODE_ALL, 1, 255, 1, S32_MAX, [], [ ARR | RANGE | NUM ], [], NUM, 0 ] ],
  'BAHTTEXT': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], STR, 0 ] ],
  'HEX2BIN': [ [ MODE_ALL, 1, 2, 1, 2, [ ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'HEX2DEC': [ [ MODE_ALL, 1, 1, 1, 1, [ ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'HEX2OCT': [ [ MODE_ALL, 1, 2, 1, 2, [ ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'DEC2BIN': [ [ MODE_ALL, 1, 2, 1, 2, [ ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'DEC2HEX': [ [ MODE_ALL, 1, 2, 1, 2, [ ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'DEC2OCT': [ [ MODE_ALL, 1, 2, 1, 2, [ ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'OCT2BIN': [ [ MODE_ALL, 1, 2, 1, 2, [ ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'OCT2HEX': [ [ MODE_ALL, 1, 2, 1, 2, [ ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'OCT2DEC': [ [ MODE_ALL, 1, 1, 1, 1, [ ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'BIN2DEC': [ [ MODE_ALL, 1, 1, 1, 1, [ ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'BIN2OCT': [ [ MODE_ALL, 1, 2, 1, 2, [ ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'BIN2HEX': [ [ MODE_ALL, 1, 2, 1, 2, [ ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'IMSUB': [ [ MODE_ALL, 2, 2, 2, 2, [ ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'IMDIV': [ [ MODE_ALL, 2, 2, 2, 2, [ ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'IMPOWER': [ [ MODE_ALL, 2, 2, 2, 2, [ ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'IMABS': [ [ MODE_ALL, 1, 1, 1, 1, [ ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'IMSQRT': [ [ MODE_ALL, 1, 1, 1, 1, [ ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'IMLN': [ [ MODE_ALL, 1, 1, 1, 1, [ ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'IMLOG2': [ [ MODE_ALL, 1, 1, -1, -1, [ ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'IMLOG10': [ [ MODE_ALL, 1, 1, -1, -1, [ ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'IMSIN': [ [ MODE_ALL, 1, 1, 1, 1, [ ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'IMCOS': [ [ MODE_ALL, 1, 1, 1, 1, [ ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'IMEXP': [ [ MODE_ALL, 1, 1, 1, 1, [ ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'IMARGUMENT': [ [ MODE_ALL, 1, 1, 1, 1, [ ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'IMCONJUGATE': [ [ MODE_ALL, 1, 1, 1, 1, [ ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'IMAGINARY': [ [ MODE_ALL, 1, 1, 1, 1, [ ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'IMREAL': [ [ MODE_ALL, 1, 1, 1, 1, [ ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'COMPLEX': [ [ MODE_ALL, 2, 3, 2, 3, [ ATP_ARG, ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'IMSUM': [ [ MODE_ALL, 1, 255, 1, S32_MAX, [], [ MISSING | ERR | VALUE ], [], ERR | NUM, 0 ] ],
  'IMPRODUCT': [ [ MODE_ALL, 1, 255, 1, S32_MAX, [], [ MISSING | ERR | VALUE ], [], ERR | NUM, 0 ] ],
  'SERIESSUM': [ [ MODE_ALL, 4, 4, 4, 4, [ ATP_ARG, ATP_ARG, ATP_ARG, MISSING | ERR | VALUE ], [], [], ERR | NUM, 0 ] ],
  'FACTDOUBLE': [ [ MODE_ALL, 1, 1, 1, 1, [ ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'SQRTPI': [ [ MODE_ALL, 1, 1, 1, 1, [ ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'QUOTIENT': [ [ MODE_ALL, 2, 2, 2, 2, [ ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'DELTA': [ [ MODE_ALL, 1, 2, 1, 2, [ ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'GESTEP': [ [ MODE_ALL, 1, 2, 1, 2, [ ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'ISEVEN': [ [ MODE_ALL, 1, 1, 1, 1, [ ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'ISODD': [ [ MODE_ALL, 1, 1, 1, 1, [ ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'MROUND': [ [ MODE_ALL, 2, 2, 2, 2, [ ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'ERF': [ [ MODE_ALL, 1, 2, 1, 2, [ ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'ERFC': [ [ MODE_ALL, 1, 1, 1, 1, [ ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'BESSELJ': [ [ MODE_ALL, 2, 2, -1, -1, [ ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'BESSELK': [ [ MODE_ALL, 2, 2, -1, -1, [ ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'BESSELY': [ [ MODE_ALL, 2, 2, -1, -1, [ ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'BESSELI': [ [ MODE_ALL, 2, 2, -1, -1, [ ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'XIRR': [ [ MODE_ALL, 2, 3, 2, 3, [ MISSING | ERR | VALUE, MISSING | ERR | VALUE, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'XNPV': [ [ MODE_ALL, 3, 3, 3, 3, [ ATP_ARG, MISSING | ERR | VALUE, MISSING | ERR | VALUE ], [], [], ERR | NUM, 0 ] ],
  'PRICEMAT': [ [ MODE_ALL, 5, 6, 5, 6, [ ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'YIELDMAT': [ [ MODE_ALL, 5, 6, 5, 6, [ ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'INTRATE': [ [ MODE_ALL, 4, 5, 4, 5, [ ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'RECEIVED': [ [ MODE_ALL, 4, 5, 4, 5, [ ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'DISC': [ [ MODE_ALL, 4, 5, 4, 5, [ ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'PRICEDISC': [ [ MODE_ALL, 4, 5, 4, 5, [ ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'YIELDDISC': [ [ MODE_ALL, 4, 5, 4, 5, [ ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'TBILLEQ': [ [ MODE_ALL, 3, 3, 3, 3, [ ATP_ARG, ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'TBILLPRICE': [ [ MODE_ALL, 3, 3, 3, 3, [ ATP_ARG, ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'TBILLYIELD': [ [ MODE_ALL, 3, 3, 3, 3, [ ATP_ARG, ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'PRICE': [
    [ MODE_ALL, 6, 7, 6, 7, [ ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ],
  ],
  'YIELD': [
    [ MODE_ALL, 6, 7, 6, 7, [ ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ],
  ],
  'DOLLARDE': [ [ MODE_ALL, 2, 2, 2, 2, [ ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'DOLLARFR': [ [ MODE_ALL, 2, 2, 2, 2, [ ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'NOMINAL': [ [ MODE_ALL, 2, 2, 2, 2, [ ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'EFFECT': [ [ MODE_ALL, 2, 2, 2, 2, [ ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'CUMPRINC': [ [ MODE_ALL, 6, 6, 6, 6, [ ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'CUMIPMT': [ [ MODE_ALL, 6, 6, 6, 6, [ ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'EDATE': [ [ MODE_ALL, 2, 2, 2, 2, [ ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'EOMONTH': [ [ MODE_ALL, 2, 2, 2, 2, [ ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'YEARFRAC': [ [ MODE_ALL, 2, 3, 2, 3, [ ATP_ARG, ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'COUPDAYBS': [ [ MODE_ALL, 3, 4, 3, 4, [ ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'COUPDAYS': [ [ MODE_ALL, 3, 4, 3, 4, [ ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'COUPDAYSNC': [ [ MODE_ALL, 3, 4, 3, 4, [ ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'COUPNCD': [ [ MODE_ALL, 3, 4, 3, 4, [ ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'COUPNUM': [ [ MODE_ALL, 3, 4, 3, 4, [ ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'COUPPCD': [ [ MODE_ALL, 3, 4, 3, 4, [ ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'DURATION': [ [ MODE_ALL, 5, 6, 5, 6, [ ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'MDURATION': [ [ MODE_ALL, 5, 6, 5, 6, [ ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'ODDLPRICE': [
    [
      MODE_ALL,
      7,
      8,
      -1,
      -1,
      [ ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG ],
      [],
      [],
      ERR | NUM,
      0,
    ],
  ],
  'ODDLYIELD': [
    [
      MODE_ALL,
      7,
      8,
      -1,
      -1,
      [ ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG ],
      [],
      [],
      ERR | NUM,
      0,
    ],
  ],
  'ODDFPRICE': [
    [
      MODE_ALL,
      8,
      9,
      -1,
      -1,
      [ ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG ],
      [],
      [],
      ERR | NUM,
      0,
    ],
  ],
  'ODDFYIELD': [
    [
      MODE_ALL,
      8,
      9,
      -1,
      -1,
      [ ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG ],
      [],
      [],
      ERR | NUM,
      0,
    ],
  ],
  'RANDBETWEEN': [ [ MODE_ALL, 2, 2, 2, 2, [ ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'WEEKNUM': [ [ MODE_ALL, 1, 2, 1, 2, [ ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'AMORDEGRC': [
    [ MODE_ALL, 6, 7, -1, -1, [ ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ],
  ],
  'AMORLINC': [
    [ MODE_ALL, 6, 7, 6, 7, [ ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ],
  ],
  'CONVERT': [ [ MODE_ALL, 3, 3, 3, 3, [ ATP_ARG, ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'ACCRINT': [
    [
      MODE_ALL,
      6,
      8,
      6,
      8,
      [ ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG ],
      [],
      [],
      ERR | NUM,
      0,
    ],
  ],
  'ACCRINTM': [ [ MODE_ALL, 4, 5, 3, 5, [ ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'WORKDAY': [ [ MODE_ALL, 2, 3, 2, 3, [ ATP_ARG, ATP_ARG, MISSING | ERR | VALUE ], [], [], ERR | NUM, 0 ] ],
  'NETWORKDAYS': [ [ MODE_ALL, 2, 3, 2, 3, [ ATP_ARG, ATP_ARG, MISSING | ERR | VALUE ], [], [], ERR | NUM, 0 ] ],
  'GCD': [ [ MODE_ALL, 1, 255, 1, S32_MAX, [], [ MISSING | ERR | VALUE ], [], ERR | NUM, 0 ] ],
  'MULTINOMIAL': [ [ MODE_ALL, 1, 255, 1, S32_MAX, [], [ MISSING | ERR | VALUE ], [], ERR | NUM, 0 ] ],
  'LCM': [ [ MODE_ALL, 1, 255, 1, S32_MAX, [], [ MISSING | ERR | VALUE ], [], ERR | NUM, 0 ] ],
  'FVSCHEDULE': [ [ MODE_ALL, 2, 2, 2, 2, [ ATP_ARG, MISSING | ERR | VALUE ], [], [], ERR | NUM, 0 ] ],
  'IFERROR': [
    [ MODE_ALL, 2, 2, 1, 2, [ ARR | ERR | BLANK | ATOM, ERR | MISSING | VALUE ], [], [], LAMBDA | ARR | ERR | ATOM, 0 ],
  ],
  'COUNTIFS': [ [ MODE_ALL, 2, 255, 2, S32_MAX, [], [ RANGE, ERR | ATOM ], [], NUM, 0 ] ],
  'SUMIFS': [ [ MODE_ALL, 3, 255, 3, S32_MAX, [ RANGE ], [ RANGE, ERR | ATOM ], [], NUM, 0 ] ],
  'AVERAGEIF': [ [ MODE_ALL, 2, 3, 2, 3, [ RANGE, ERR | ATOM, RANGE ], [], [], NUM, 0 ] ],
  'AVERAGEIFS': [ [ MODE_ALL, 3, 255, 3, S32_MAX, [ RANGE ], [ RANGE, ERR | ATOM ], [], NUM, 0 ] ],
  'AGGREGATE': [ [ MODE_ALL, 3, 254, -1, -1, [ NUM, NUM, ARR | RANGE, RANGE | NUM ], [ RANGE ], [], NUM, 0 ] ],
  'BINOM.DIST': [ [ MODE_ALL, 4, 4, 4, 4, [ NUM, NUM, NUM, BOOL ], [], [], NUM, 0 ] ],
  'BINOM.INV': [ [ MODE_ALL, 3, 3, -1, -1, [ NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'CONFIDENCE.NORM': [ [ MODE_ALL, 3, 3, 3, 3, [ NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'CONFIDENCE.T': [ [ MODE_ALL, 3, 3, 3, 3, [ NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'CHISQ.TEST': [ [ MODE_ALL, 2, 2, -1, -1, [ ARR, ARR ], [], [], NUM, 0 ] ],
  'F.TEST': [ [ MODE_ALL, 2, 2, -1, -1, [ ARR, ARR ], [], [], NUM, 0 ] ],
  'COVARIANCE.P': [ [ MODE_ALL, 2, 2, -1, -1, [ ARR, ARR ], [], [], NUM, 0 ] ],
  'COVARIANCE.S': [ [ MODE_ALL, 2, 2, 2, 2, [ ARR, ARR ], [], [], NUM, 0 ] ],
  'EXPON.DIST': [ [ MODE_ALL, 3, 3, 3, 3, [ NUM, NUM, BOOL ], [], [], NUM, 0 ] ],
  'GAMMA.DIST': [ [ MODE_ALL, 4, 4, 4, 4, [ NUM, NUM, NUM, BOOL ], [], [], NUM, 0 ] ],
  'GAMMA.INV': [ [ MODE_ALL, 3, 3, -1, -1, [ NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'MODE.MULT': [ [ MODE_ALL, 1, 254, -1, -1, [], [ ARR ], [], ARR, 0 ] ],
  'MODE.SNGL': [ [ MODE_ALL, 1, 254, -1, -1, [], [ ARR ], [], NUM, 0 ] ],
  'NORM.DIST': [ [ MODE_ALL, 4, 4, 4, 4, [ NUM, NUM, NUM, BOOL ], [], [], NUM, 0 ] ],
  'NORM.INV': [ [ MODE_ALL, 3, 3, 3, 3, [ NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'PERCENTILE.EXC': [ [ MODE_ALL, 2, 2, -1, -1, [ ARR | RANGE | NUM, NUM ], [], [], NUM, 0 ] ],
  'PERCENTILE.INC': [ [ MODE_ALL, 2, 2, -1, -1, [ ARR | RANGE | NUM, NUM ], [], [], NUM, 0 ] ],
  'PERCENTRANK.EXC': [ [ MODE_ALL, 2, 3, 2, 3, [ ARR | RANGE | NUM, NUM, MISSING | NUM ], [], [], NUM, 0 ] ],
  'PERCENTRANK.INC': [ [ MODE_ALL, 2, 3, 2, 3, [ ARR | RANGE | NUM, NUM, MISSING | NUM ], [], [], NUM, 0 ] ],
  'POISSON.DIST': [ [ MODE_ALL, 3, 3, 3, 3, [ NUM, NUM, BOOL ], [], [], NUM, 0 ] ],
  'QUARTILE.EXC': [ [ MODE_ALL, 2, 2, -1, -1, [ ARR | RANGE | NUM, NUM ], [], [], NUM, 0 ] ],
  'QUARTILE.INC': [ [ MODE_ALL, 2, 2, -1, -1, [ ARR | RANGE | NUM, NUM ], [], [], NUM, 0 ] ],
  'RANK.AVG': [ [ MODE_ALL, 2, 3, -1, -1, [ NUM, RANGE, BOOL ], [], [], NUM, 0 ] ],
  'RANK.EQ': [ [ MODE_ALL, 2, 3, -1, -1, [ NUM, RANGE, BOOL ], [], [], NUM, 0 ] ],
  'STDEV.S': [ [ MODE_ALL, 1, 254, -1, -1, [], [ ARR | RANGE | NUM ], [], NUM, 0 ] ],
  'STDEV.P': [ [ MODE_ALL, 1, 254, -1, -1, [], [ ARR | RANGE | NUM ], [], NUM, 0 ] ],
  'T.DIST': [ [ MODE_ALL, 3, 3, 3, 3, [ NUM, NUM, BOOL ], [], [], NUM, 0 ] ],
  'T.DIST.2T': [ [ MODE_ALL, 2, 2, 2, 2, [ NUM, NUM ], [], [], NUM, 0 ] ],
  'T.DIST.RT': [ [ MODE_ALL, 2, 2, 2, 2, [ NUM, NUM ], [], [], NUM, 0 ] ],
  'T.INV': [ [ MODE_ALL, 2, 2, 2, 2, [ NUM, NUM ], [], [], NUM, 0 ] ],
  'T.INV.2T': [ [ MODE_ALL, 2, 2, 2, 2, [ NUM, NUM ], [], [], NUM, 0 ] ],
  'VAR.S': [ [ MODE_ALL, 1, 254, -1, -1, [], [ ARR | RANGE | NUM ], [], NUM, 0 ] ],
  'VAR.P': [ [ MODE_ALL, 1, 254, -1, -1, [], [ ARR | RANGE | NUM ], [], NUM, 0 ] ],
  'WEIBULL.DIST': [ [ MODE_ALL, 4, 4, 4, 4, [ NUM, NUM, NUM, BOOL ], [], [], NUM, 0 ] ],
  'NETWORKDAYS.INTL': [
    [ MODE_ALL, 2, 4, 2, 4, [ ATP_ARG, ATP_ARG, MISSING | ERR | STR | NUM, MISSING | ERR | VALUE ], [], [], ERR | NUM, 0 ],
  ],
  'WORKDAY.INTL': [
    [ MODE_ALL, 2, 4, 2, 4, [ ATP_ARG, ATP_ARG, MISSING | ERR | STR | NUM, MISSING | ERR | VALUE ], [], [], ERR | NUM, 0 ],
  ],
  'ECMA.CEILING': [ [ MODE_ALL, 2, 2, -1, -1, [ NUM, NUM ], [], [], NUM, 0 ] ],
  'ISO.CEILING': [ [ MODE_ALL, 1, 2, -1, -1, [ NUM, MISSING | NUM ], [], [], NUM, 0 ] ],
  'BETA.DIST': [ [ MODE_ALL, 4, 6, 3, 6, [ NUM, NUM, NUM, BOOL, MISSING | NUM, MISSING | NUM ], [], [], NUM, 0 ] ],
  'BETA.INV': [ [ MODE_ALL, 3, 5, 3, 5, [ NUM, NUM, NUM, MISSING | NUM, MISSING | NUM ], [], [], NUM, 0 ] ],
  'CHISQ.DIST': [ [ MODE_ALL, 3, 3, 3, 3, [ NUM, NUM, BOOL ], [], [], NUM, 0 ] ],
  'CHISQ.DIST.RT': [ [ MODE_ALL, 2, 2, 2, 2, [ NUM, NUM ], [], [], NUM, 0 ] ],
  'CHISQ.INV': [ [ MODE_ALL, 2, 2, 2, 2, [ NUM, NUM ], [], [], NUM, 0 ] ],
  'CHISQ.INV.RT': [ [ MODE_ALL, 2, 2, 2, 2, [ NUM, NUM ], [], [], NUM, 0 ] ],
  'F.DIST': [ [ MODE_ALL, 4, 4, 4, 4, [ NUM, NUM, NUM, BOOL ], [], [], NUM, 0 ] ],
  'F.DIST.RT': [ [ MODE_ALL, 3, 3, 3, 3, [ NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'F.INV': [ [ MODE_ALL, 3, 3, 3, 3, [ NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'F.INV.RT': [ [ MODE_ALL, 3, 3, 3, 3, [ NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'HYPGEOM.DIST': [ [ MODE_ALL, 5, 5, 4, 4, [ NUM, NUM, NUM, NUM, BOOL ], [], [], NUM, 0 ] ],
  'LOGNORM.DIST': [ [ MODE_ALL, 4, 4, 3, 3, [ NUM, NUM, NUM, BOOL ], [], [], NUM, 0 ] ],
  'LOGNORM.INV': [ [ MODE_ALL, 3, 3, 3, 3, [ NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'NEGBINOM.DIST': [ [ MODE_ALL, 4, 4, 3, 3, [ NUM, NUM, NUM, BOOL ], [], [], NUM, 0 ] ],
  'NORM.S.DIST': [ [ MODE_ALL, 2, 2, 1, 1, [ NUM, BOOL ], [], [], NUM, 0 ] ],
  'NORM.S.INV': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'T.TEST': [ [ MODE_ALL, 4, 4, 4, 4, [ ARR, ARR, NUM, NUM ], [], [], NUM, 0 ] ],
  'Z.TEST': [ [ MODE_ALL, 2, 3, 2, 3, [ ARR | RANGE | NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'ERFC.PRECISE': [ [ MODE_ALL, 1, 1, 1, 1, [ ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'GAMMALN.PRECISE': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'CEILING.PRECISE': [ [ MODE_ALL, 1, 2, 1, 2, [ NUM, MISSING | NUM ], [], [], NUM, 0 ] ],
  'FLOOR.PRECISE': [ [ MODE_ALL, 1, 2, 1, 2, [ NUM, MISSING | NUM ], [], [], NUM, 0 ] ],
  'ACOT': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'ACOTH': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'COT': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'COTH': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'CSC': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'CSCH': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'SEC': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'SECH': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'IMTAN': [ [ MODE_ALL, 1, 1, 1, 1, [ ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'IMCOT': [ [ MODE_ALL, 1, 1, 1, 1, [ ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'IMCSC': [ [ MODE_ALL, 1, 1, 1, 1, [ ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'IMCSCH': [ [ MODE_ALL, 1, 1, 1, 1, [ ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'IMSEC': [ [ MODE_ALL, 1, 1, 1, 1, [ ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'IMSECH': [ [ MODE_ALL, 1, 1, 1, 1, [ ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'BITAND': [ [ MODE_ALL, 2, 2, 2, 2, [ NUM, NUM ], [], [], NUM, 0 ] ],
  'BITOR': [ [ MODE_ALL, 2, 2, 2, 2, [ NUM, NUM ], [], [], NUM, 0 ] ],
  'BITXOR': [ [ MODE_ALL, 2, 2, 2, 2, [ NUM, NUM ], [], [], NUM, 0 ] ],
  'BITLSHIFT': [ [ MODE_ALL, 2, 2, 2, 2, [ NUM, NUM ], [], [], NUM, 0 ] ],
  'BITRSHIFT': [ [ MODE_ALL, 2, 2, 2, 2, [ NUM, NUM ], [], [], NUM, 0 ] ],
  'PERMUTATIONA': [ [ MODE_ALL, 2, 2, 2, 2, [ NUM, NUM ], [], [], NUM, 0 ] ],
  'COMBINA': [ [ MODE_ALL, 2, 2, 2, 2, [ NUM, NUM ], [], [], NUM, 0 ] ],
  'XOR': [ [ MODE_ALL, 1, 254, 1, S32_MAX, [], [ ARR | RANGE | BOOL ], [], BOOL, 0 ] ],
  'PDURATION': [ [ MODE_ALL, 3, 3, 3, 3, [ NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'BASE': [ [ MODE_ALL, 2, 3, 2, 3, [ NUM, NUM, MISSING | NUM ], [], [], STR, 0 ] ],
  'DECIMAL': [ [ MODE_ALL, 2, 2, 2, 2, [ STR, NUM ], [], [], NUM, 0 ] ],
  'DAYS': [ [ MODE_ALL, 2, 2, 2, 2, [ STR | NUM, STR | NUM ], [], [], NUM, 0 ] ],
  'BINOM.DIST.RANGE': [ [ MODE_ALL, 3, 4, 3, 4, [ NUM, NUM, NUM, MISSING | NUM ], [], [], NUM, 0 ] ],
  'GAMMA': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'SKEW.P': [ [ MODE_ALL, 1, 254, -1, -1, [], [ ARR | RANGE | NUM ], [], NUM, 0 ] ],
  'GAUSS': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'PHI': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'RRI': [ [ MODE_ALL, 3, 3, 3, 3, [ NUM, NUM, NUM ], [], [], NUM, 0 ] ],
  'UNICHAR': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], STR, 0 ] ],
  'UNICODE': [ [ MODE_ALL, 1, 1, 1, 1, [ STR ], [], [], NUM, 0 ] ],
  'MUNIT': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], ARR, 0 ] ],
  'ARABIC': [ [ MODE_ALL, 1, 1, 1, 1, [ STR ], [], [], NUM, 0 ] ],
  'ISOWEEKNUM': [ [ MODE_ALL, 1, 1, 1, 1, [ NUM ], [], [], NUM, 0 ] ],
  'NUMBERVALUE': [ [ MODE_ALL, 1, 3, -1, -1, [ STR, MISSING | STR, MISSING | STR ], [], [], NUM, 0 ] ],
  'SHEET': [ [ EXCEL | GOOGLE | CANVAS, 0, 1, -1, -1, [ ARR | RANGE | STR ], [], [], NUM, 0 ] ],
  'SHEETS': [ [ EXCEL | GOOGLE | CANVAS, 0, 1, -1, -1, [ ARR | RANGE ], [], [], NUM, 0 ] ],
  'FORMULATEXT': [ [ MODE_ALL, 1, 1, 1, 1, [ RANGE ], [], [], STR | ARR, 0 ] ],
  'ISFORMULA': [ [ MODE_ALL, 1, 1, 1, 1, [ RANGE ], [], [], BOOL, 0 ] ],
  'IFNA': [
    [ MODE_ALL, 2, 2, 1, 2, [ ARR | ERR | BLANK | ATOM, ERR | MISSING | VALUE ], [], [], LAMBDA | ARR | ERR | ATOM, 0 ],
  ],
  'CEILING.MATH': [ [ MODE_ALL, 1, 3, 1, 3, [ NUM, MISSING | NUM, MISSING | NUM ], [], [], NUM, 0 ] ],
  'FLOOR.MATH': [ [ MODE_ALL, 1, 3, 1, 3, [ NUM, MISSING | NUM, MISSING | NUM ], [], [], NUM, 0 ] ],
  'IMSINH': [ [ MODE_ALL, 1, 1, 1, 1, [ ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'IMCOSH': [ [ MODE_ALL, 1, 1, 1, 1, [ ATP_ARG ], [], [], ERR | NUM, 0 ] ],
  'ENCODEURL': [ [ MODE_ALL, 1, 1, 1, 1, [ STR ], [], [], ERR | STR, 0 ] ],
  'FORECAST.ETS': [
    [
      MODE_ALL,
      3,
      6,
      -1,
      -1,
      [ NUM, ARR | RANGE, ARR | RANGE, MISSING | NUM, MISSING | NUM, MISSING | NUM ],
      [],
      [],
      NUM,
      0,
    ],
  ],
  'FORECAST.ETS.CONFINT': [
    [
      MODE_ALL,
      3,
      7,
      -1,
      -1,
      [ NUM, ARR | RANGE, ARR | RANGE, MISSING | NUM, MISSING | NUM, MISSING | NUM, MISSING | NUM ],
      [],
      [],
      NUM,
      0,
    ],
  ],
  'FORECAST.ETS.SEASONALITY': [
    [ MODE_ALL, 2, 4, -1, -1, [ ARR | RANGE, ARR | RANGE, MISSING | NUM, MISSING | NUM ], [], [], NUM, 0 ],
  ],
  'FORECAST.LINEAR': [ [ MODE_ALL, 3, 3, -1, -1, [ NUM, ARR, ARR ], [], [], NUM, 0 ] ],
  'FORECAST.ETS.STAT': [
    [
      MODE_ALL,
      3,
      6,
      -1,
      -1,
      [ ARR | RANGE, ARR | RANGE, NUM, MISSING | NUM, MISSING | NUM, MISSING | NUM ],
      [],
      [],
      NUM,
      0,
    ],
  ],
  'MAXIFS': [ [ MODE_ALL, 3, 254, 3, S32_MAX, [ RANGE ], [ RANGE, ERR | ATOM ], [], NUM, 0 ] ],
  'MINIFS': [ [ MODE_ALL, 3, 254, 3, S32_MAX, [ RANGE ], [ RANGE, ERR | ATOM ], [], NUM, 0 ] ],
  'TEXTJOIN': [
    [
      MODE_ALL,
      3,
      254,
      3,
      S32_MAX,
      [ MISSING | ARR | RANGE | STR, MISSING | BOOL ],
      [ MISSING | ARR | RANGE | STR ],
      [],
      STR,
      0,
    ],
  ],
  'IFS': [ [ MODE_ALL, 2, 254, 2, S32_MAX, [], [ ARR | ERR | BOOL, ALL ], [], LAMBDA | ERR | VALUE, 0 ] ],
  'SWITCH': [
    [ MODE_ALL, 3, 254, 3, S32_MAX, [ ARR | ERR | ATOM ], [ ERR | VALUE, ALL ], [ ERR | VALUE ], LAMBDA | ERR | VALUE, 0 ],
  ],
  'SINGLE': [
    [
      EXCEL | GOOGLE | CANVAS,
      1,
      1,
      1,
      1,
      [ LAMBDA | BLANK | MISSING | VALUE ],
      [],
      [],
      LAMBDA | BLANK | MISSING | RANGE | ERR | ATOM,
      0,
    ],
  ],
  'ANCHORARRAY': [ [ EXCEL | GOOGLE | CANVAS, 1, 1, -1, -1, [ RANGE ], [], [], ARR | RANGE, 0 ] ],
  'UNIQUE': [ [ MODE_ALL, 1, 3, 1, 3, [ BLANK | ARR, BOOL, BOOL ], [], [], ARR, GOOGLE_ARRAY_FUNCTION ] ],
  'TEXTBEFORE': [
    [
      MODE_ALL,
      2,
      6,
      -1,
      -1,
      [ STR, ARR | STR, MISSING | NUM, MISSING | BOOL, MISSING | BOOL, MISSING | VALUE | ERR ],
      [],
      [],
      VALUE,
      0,
    ],
  ],
  'TEXTAFTER': [
    [
      MODE_ALL,
      2,
      6,
      -1,
      -1,
      [ STR, ARR | STR, MISSING | NUM, MISSING | BOOL, MISSING | BOOL, MISSING | VALUE | ERR ],
      [],
      [],
      VALUE,
      0,
    ],
  ],
  'TEXTSPLIT': [
    [
      MODE_ALL,
      2,
      6,
      -1,
      -1,
      [
        STR,
        MISSING | ARR | STR,
        MISSING | ARR | STR,
        MISSING | BOOL,
        NUM | MISSING,
        LAMBDA | BLANK | MISSING | ARR | ERR | ATOM,
      ],
      [],
      [],
      ARR,
      0,
    ],
  ],
  'XMATCH': [
    [ MODE_ALL, 2, 4, 2, 4, [ LAMBDA | BLANK | ATOM, ARR | RANGE, MISSING | NUM, MISSING | NUM ], [], [], NUM, 0 ],
  ],
  'XLOOKUP': [
    [
      MODE_ALL,
      3,
      6,
      3,
      6,
      [ LAMBDA | BLANK | ATOM, ARR | RANGE, ARR | RANGE, ALL, MISSING | NUM, MISSING | NUM ],
      [],
      [],
      LAMBDA | ERR | VALUE,
      GOOGLE_ARRAY_FUNCTION,
    ],
  ],
  'SORTBY': [ [ MODE_ALL, 2, 254, -1, -1, [ BLANK | ARR ], [ BLANK | ARR, MISSING | NUM ], [], ARR, 0 ] ],
  'RANDARRAY': [
    [
      MODE_ALL,
      0,
      5,
      0,
      2,
      [ MISSING | NUM, MISSING | NUM, MISSING | NUM, MISSING | NUM, MISSING | BOOL ],
      [],
      [],
      ARR,
      0,
    ],
  ],
  'SEQUENCE': [ [ MODE_ALL, 1, 4, 0, 4, [ MISSING | NUM, MISSING | NUM, MISSING | NUM, MISSING | NUM ], [], [], ARR, 0 ] ],
  'WRAPROWS': [ [ MODE_ALL, 2, 3, -1, -1, [ ARR, NUM, LAMBDA | BLANK | MISSING | ERR | ATOM ], [], [], ARR, 0 ] ],
  'VSTACK': [ [ MODE_ALL, 1, 254, -1, -1, [ ARR | RANGE ], [ ARR | RANGE ], [], ARR, 0 ] ],
  'HSTACK': [ [ MODE_ALL, 1, 254, -1, -1, [ ARR | RANGE ], [ ARR | RANGE ], [], ARR, 0 ] ],
  'CHOOSEROWS': [ [ MODE_ALL, 2, 254, -1, -1, [ ARR | RANGE, ARR | NUM ], [ ARR | NUM ], [], ARR, 0 ] ],
  'CHOOSECOLS': [ [ MODE_ALL, 2, 254, -1, -1, [ ARR | RANGE, ARR | NUM ], [ ARR | NUM ], [], ARR, 0 ] ],
  'LET': [
    [
      MODE_ALL,
      3,
      254,
      -1,
      -1,
      [],
      [ LAMBDA | ERR | VALUE, LAMBDA | ERR | VALUE ],
      [ LAMBDA | ERR | VALUE ],
      LAMBDA | ERR | VALUE,
      0,
    ],
  ],
  'LAMBDA': [ [ MODE_ALL, 1, 254, 1, S32_MAX, [], [ LAMBDA | ERR | VALUE ], [], LAMBDA | ERR | VALUE, 0 ] ],
  'MAKEARRAY': [ [ MODE_ALL, 3, 3, -1, -1, [ MISSING | NUM, MISSING | NUM, LAMBDA | ERR | VALUE ], [], [], ARR, 0 ] ],
  'VALUETOTEXT': [ [ MODE_ALL, 1, 2, -1, -1, [ LAMBDA | BLANK | ERR | ATOM, NUM ], [], [], STR, 0 ] ],
  'ARRAYTOTEXT': [ [ MODE_ALL, 1, 2, -1, -1, [ ARR, NUM ], [], [], STR, 0 ] ],
  'TOCOL': [ [ MODE_ALL, 1, 3, -1, -1, [ ARR | RANGE, MISSING | NUM, MISSING | BOOL ], [], [], ARR, 0 ] ],
  'TOROW': [ [ MODE_ALL, 1, 3, -1, -1, [ ARR | RANGE, MISSING | NUM, MISSING | BOOL ], [], [], ARR, 0 ] ],
  'WRAPCOLS': [ [ MODE_ALL, 2, 3, -1, -1, [ ARR, NUM, LAMBDA | BLANK | MISSING | ERR | ATOM ], [], [], ARR, 0 ] ],
  'MAP': [ [ MODE_ALL, 2, 254, -1, -1, [ ARR | RANGE ], [ LAMBDA | ERR | VALUE ], [], ARR, GOOGLE_ARRAY_FUNCTION ] ],
  'REDUCE': [
    [
      MODE_ALL,
      3,
      3,
      -1,
      -1,
      [ ALL, ARR | RANGE, LAMBDA | ERR | ATOM ],
      [],
      [],
      LAMBDA | ERR | VALUE,
      GOOGLE_ARRAY_FUNCTION,
    ],
  ],
  'SCAN': [ [ MODE_ALL, 3, 3, -1, -1, [ ALL, ARR | RANGE, LAMBDA | ERR | ATOM ], [], [], ARR, GOOGLE_ARRAY_FUNCTION ] ],
  'BYROW': [ [ MODE_ALL, 1, 2, -1, -1, [ ARR | RANGE, LAMBDA | ERR | ATOM ], [], [], ARR, GOOGLE_ARRAY_FUNCTION ] ],
  'BYCOL': [ [ MODE_ALL, 1, 2, -1, -1, [ ARR | RANGE, LAMBDA | ERR | ATOM ], [], [], ARR, GOOGLE_ARRAY_FUNCTION ] ],
  'ISOMITTED': [ [ MODE_ALL, 1, 1, -1, -1, [ ALL ], [], [], BOOL, 0 ] ],
  'EXPAND': [
    [
      MODE_ALL,
      2,
      4,
      -1,
      -1,
      [ ARR, MISSING | NUM, MISSING | NUM, LAMBDA | BLANK | MISSING | ARR | ERR | ATOM ],
      [],
      [],
      ARR,
      0,
    ],
  ],
  'TAKE': [ [ MODE_ALL, 2, 3, -1, -1, [ ARR | RANGE, MISSING | NUM, MISSING | NUM ], [], [], ARR | RANGE, 0 ] ],
  'DROP': [ [ MODE_ALL, 2, 3, -1, -1, [ ARR | RANGE, MISSING | NUM, MISSING | NUM ], [], [], ARR | RANGE, 0 ] ],
  // Excel and Google Sheets's SORT differs in that Google allows a variadic
  // number of `(sort_column, is_ascending)` pairs, while Excel limits the user
  // to one. The type of `is_ascending` is also different (boolean vs number).
  'SORT': [
    [
      EXCEL | GRID_SHEET | CANVAS,
      1,
      4,
      2,
      4,
      [ BLANK | ARR, MISSING | ARR, MISSING | ARR, BOOL ],
      [],
      [],
      ARR,
      GOOGLE_ARRAY_FUNCTION,
    ],
    [ GOOGLE, 1, S32_MAX, -1, -1, [ BLANK | ARR, NUM | ARR, BOOL ], [ NUM | ARR, BOOL ], [], ARR, GOOGLE_ARRAY_FUNCTION ],
  ],
  'SORT.GOOGLE': [
    [
      GRID_SHEET | CANVAS,
      1,
      S32_MAX,
      -1,
      -1,
      [ BLANK | ARR, NUM | ARR, BOOL ],
      [ NUM | ARR, BOOL ],
      [],
      ARR,
      GOOGLE_ARRAY_FUNCTION,
    ],
  ],
  // Excel and Google Sheets' FILTER differs in that Google allows a variadic
  // number of filter criteria arguments, while Excel limits the number to one
  // and the third argument is instead an `is_empty` fallback.
  'FILTER': [
    [
      EXCEL | GRID_SHEET | CANVAS,
      2,
      3,
      2,
      S32_MAX,
      [ ARR | RANGE, ARR | RANGE, MISSING | ARR | ERR | ATOM ],
      [],
      [],
      LAMBDA | ARR | ERR | ATOM,
      GOOGLE_ARRAY_FUNCTION,
    ],
    [ GOOGLE, 2, S32_MAX, -1, -1, [ ARR | RANGE ], [ ARR | RANGE | MISSING | ERR ], [], ARR, GOOGLE_ARRAY_FUNCTION ],
  ],
  'FILTER.GOOGLE': [
    [
      GRID_SHEET | CANVAS,
      2,
      S32_MAX,
      -1,
      -1,
      [ ARR | RANGE ],
      [ ARR | RANGE | MISSING | ERR ],
      [],
      ARR,
      GOOGLE_ARRAY_FUNCTION,
    ],
  ],
  // Google Sheets' CONCAT and CONCATENATE are the opposite of Excel's, in that
  // CONCAT takes only strings and CONCATENATE takes arrays and range references.
  // This matters in how it impacts single-cell vs array formula evaluation.
  'CONCAT': [
    [ EXCEL | GRID_SHEET | CANVAS, 1, 254, -1, -1, [], [ MISSING | ARR | RANGE | STR ], [], STR, 0 ],
    [ GOOGLE, 1, 254, -1, -1, [], [ STR ], [], STR, 0 ],
  ],
  'CONCATENATE': [
    [ EXCEL | GRID_SHEET | CANVAS, 1, 255, -1, -1, [], [ STR ], [], STR, 0 ],
    [ GOOGLE, 1, 255, -1, -1, [], [ STR | ARR | RANGE ], [], STR, 0 ],
  ],
  // In Google Sheets, ERF.PRECISE is aliased to ERF, including its additional
  // argument. In GRID mode, we follow Google Sheets.
  'ERF.PRECISE': [
    [ EXCEL, 1, 1, -1, -1, [ ATP_ARG ], [], [], ERR | NUM, 0 ],
    [ GOOGLE | GRID_SHEET | CANVAS, 1, 2, 1, 2, [ ATP_ARG, ATP_ARG ], [], [], ERR | NUM, 0 ],
  ],
  // Functions with default signature
  'ARRAY': [ defaultSignature ],
  'ARRAYROW': [ defaultSignature ],
};

export const operatorSigs: { [s: string]: FunctionSignature[] } = {
  ADD: [ [ MODE_ALL, 2, -1, -1, -1, [ NUM, NUM ], [], [], NUM, BOX ] ],
  MINUS: [ [ MODE_ALL, 2, -1, -1, -1, [ NUM, NUM ], [], [], NUM, BOX ] ],
  MULTIPLY: [ [ MODE_ALL, 2, -1, -1, -1, [ NUM, NUM ], [], [], NUM, BOX ] ],
  DIVIDE: [ [ MODE_ALL, 2, -1, -1, -1, [ NUM, NUM ], [], [], NUM, BOX ] ],
  POW: [ [ MODE_ALL, 2, -1, -1, -1, [ NUM, NUM ], [], [], NUM, BOX ] ],
  UNARY_PERCENT: [ [ MODE_ALL, 1, -1, -1, -1, [ NUM ], [], [], NUM, BOX ] ],
  UMINUS: [ [ MODE_ALL, 1, -1, -1, -1, [ NUM ], [], [], NUM, BOX ] ],
  CONCAT: [ [ MODE_ALL, 2, -1, -1, -1, [ STR, STR ], [], [], STR, 0 ] ],
  EQ: [ [ MODE_ALL, 2, -1, -1, -1, [ BLANK | ATOM, BLANK | ATOM ], [], [], BOOL, 0 ] ],
  NE: [ [ MODE_ALL, 2, -1, -1, -1, [ BLANK | ATOM, BLANK | ATOM ], [], [], BOOL, 0 ] ],
  LT: [ [ MODE_ALL, 2, -1, -1, -1, [ BLANK | ATOM, BLANK | ATOM ], [], [], BOOL, 0 ] ],
  LTE: [ [ MODE_ALL, 2, -1, -1, -1, [ BLANK | ATOM, BLANK | ATOM ], [], [], BOOL, 0 ] ],
  GT: [ [ MODE_ALL, 2, -1, -1, -1, [ BLANK | ATOM, BLANK | ATOM ], [], [], BOOL, 0 ] ],
  GTE: [ [ MODE_ALL, 2, -1, -1, -1, [ BLANK | ATOM, BLANK | ATOM ], [], [], BOOL, 0 ] ],
  INTERSECT: [ [ MODE_ALL, 2, -1, -1, -1, [ RANGE, RANGE ], [], [], RANGE, 0 ] ],
  ANCHORARRAY: [ [ MODE_ALL, 1, 1, -1, -1, [ RANGE ], [], [], ARR | RANGE, 0 ] ],
  UPLUS: [ [ MODE_ALL, 1, 1, -1, -1, [ ERR | MISSING | BLANK | ATOM ], [], [], MISSING | BLANK | ATOM, 0 ] ],
};
