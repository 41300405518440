import React, { useEffect, useMemo, useState } from 'react';
import csx from 'classnames';
import PropTypes from 'prop-types';

import { getMetadata } from '@/api/document';

import { parseHref } from '../utils/urlState';

import styles from './mention.module.scss';

const getDocURLTokens = url => {
  return url
    .replace(/([?#].*)/g, '')
    .split(/[ /-]/g)
    .filter(Boolean);
};

export default function GridMention (props) {
  const { isEditor, isSelected, id, attributes, value, children, isAuthenticated } = props;
  const [ title, setTitle ] = useState(/** @type {string|null} */(null));
  const [ isLoading, setIsLoading ] = useState(false);
  const type = props.type || 'user';
  const fetchMeta = props.getMetadata || getMetadata;

  const isDocMention = type === 'document';
  const isUserMention = type !== 'document'; // type === 'user' || type == null
  let text = '';

  useEffect(() => {
    if (isDocMention && title == null && !isLoading) {
      setIsLoading(true);
      fetchMeta(getDocURLTokens(value).pop())
        .then(d => {
          setTitle(d.title);
          setIsLoading(false);
        })
        .catch(() => {
          setTitle('Unable to access document');
          setIsLoading(false);
        });
    }
  }, [ isDocMention, isLoading, value, title, fetchMeta ]);

  if (isDocMention) {
    if (title) {
      text = title;
    }
    else {
      text = getDocURLTokens(value)
        .slice(1, -1)
        .join(' ');
    }
  }
  else {
    text = value || '@';
  }

  let href = value;
  if (isUserMention) {
    href = '/' + value;
  }

  /** @type {Record<string, any>}*/
  const linkProps = { ...useMemo(() => parseHref(href, isAuthenticated), [ href, isAuthenticated ]).props };
  if (isEditor) {
    linkProps.href = undefined;
  }
  linkProps.onClick = () => props.track('interact', { elementType: 'mention', targetUrl: href });

  return (
    <a
      className={csx(
        styles.mention,
        isDocMention && styles.document,
        isUserMention && styles.user,
        isSelected && styles.selected,
      )}
      {...linkProps}
      contentEditable={isEditor ? false : null}
      title={isEditor ? `You mentioned ${value}` : null}
      id={id}
      key={id}
      data-testid="mention"
      {...attributes}
      >
      {isDocMention && (
        <svg viewBox="0 0 26 18" height="13px">
          <path
            fill="currentColor"
            d="M2.53 11.52H16.50C15.56 11.06 14.92 10.10 14.92 8.98C14.92 7.87 15.56 6.91 16.50 6.45H2.53C1.13 6.45 0 7.58 0 8.98C0 10.38 1.13 11.52 2.53 11.52Z
M20.56 8.98C20.56 10.10 19.92 11.06 18.99 11.52H22.81C24.21 11.52 25.35 10.38 25.35 8.98C25.35 7.58 24.21 6.45 22.81 6.45H18.99C19.92 6.91 20.56 7.87 20.56 8.98Z
M9.67 15.44C9.67 16.57 10.36 17.55 11.33 17.97H4.83C3.43 17.97 2.30 16.84 2.30 15.44C2.30 14.04 3.43 12.90 4.83 12.90H11.33C10.36 13.33 9.67 14.30 9.67 15.44Z
M17.51 17.97H13.55C14.52 17.55 15.21 16.57 15.21 15.44C15.21 14.30 14.52 13.33 13.55 12.90H17.51C18.91 12.90 20.05 14.04 20.05 15.44C20.05 16.84 18.91 17.97 17.51 17.97Z
M20.80 4.32C20.33 4.80 19.68 5.07 19.01 5.07H13.78C14.75 4.64 15.44 3.66 15.44 2.53C15.44 1.40 14.75 0.42 13.78 0H19.01C19.68 0 20.33 0.26 20.80 0.74C21.28 1.21 21.54 1.86 21.54 2.53C21.54 3.20 21.28 3.85 20.80 4.32Z
M11.56 0H9.67C9.00 0 8.36 0.26 7.88 0.74C7.41 1.21 7.14 1.86 7.14 2.53C7.14 3.20 7.41 3.85 7.88 4.32C8.36 4.80 9.00 5.07 9.67 5.07H11.56C10.59 4.64 9.91 3.66 9.91 2.53C9.91 1.40 10.59 0.42 11.56 0Z"
            />
        </svg>
      )}
      {isLoading ? (
        <span className={styles.loading}>
          <span className={styles.spacer}>{text}</span>
          <span className={styles.indicator}>...</span>
        </span>
      ) : (
        text
      )}
      {isEditor ? children : null}
    </a>
  );
}

GridMention.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  attributes: PropTypes.object,
  isEditor: PropTypes.bool,
  isSelected: PropTypes.bool,
  isFocused: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  track: PropTypes.func,
  model: PropTypes.object,
  element: PropTypes.object,
  children: PropTypes.node,
  getMetadata: PropTypes.func,
};
