export { isRef } from './excel/Reference';
export { isMatrix } from './excel/Matrix';
export { isNum, isStr, isBool, isErr } from './typeguards';
import { colFromOffs } from './excel/referenceParser';
import { isStr } from './typeguards';
import { invariant } from './validation';

type POWER_OF_TWO =
  | 1
  | 2
  | 4
  | 8
  | 16
  | 32
  | 64
  | 128
  | 256
  | 512
  | 1024
  | 2048
  | 4096
  | 8192
  | 16384
  | 32768
  | 65536
  | 131072
  | 262144
  | 524288
  | 1048576
  | 2097152
  | 4194304
  | 8388608
  | 16777216
  | 33554432
  | 67108864
  | 134217728
  | 268435456
  | 536870912
  | 1073741824
  | 2147483648;

export function individualBits (bitField: number, max: number): POWER_OF_TWO[] {
  invariant(max <= 2147483648, `${max} > 2147483648`);
  const bits: POWER_OF_TWO[] = [];
  let bit = 1;
  while (bit <= max) {
    if ((bit & bitField) !== 0) {
      bits.push(bit as POWER_OF_TWO);
    }
    bit <<= 1;
  }
  return bits;
}

/**
 * Format a list of things in plain english, using an Oxford comma. For example:
 *   ['duck'] -> "duck"
 *   ['cows', 'horses'] -> "cows and horses"
 *   ['cats', 'dogs', 'rabbits'] -> "cats, dogs, and rabbits"
 */
export function oxfordCommaJoin (parts: string[], word = 'and') {
  if (parts.length > 2) {
    return parts.slice(0, -1).join(', ') + `, ${word} ${parts[parts.length - 1]}`;
  }
  return parts.join(` ${word} `);
}

const VOWELS = new Set([ 'a', 'e', 'i', 'o', 'u', 'y' ]);

/**
 * Either `an` or `a` depending on the given word. This will not work accurately
 * for all English words. For our use case it's fine though. For example:
 * "eagle" -> "an" | an eagle
 * "bird" -> "a"   | a bird
 * "range" -> "a"  | a range
 */
export function englishArticle (word: string): string {
  if (VOWELS.has(word[0].toLowerCase())) {
    return 'an';
  }
  else {
    return 'a';
  }
}

/**
 * A utility function to compare two strings (or possibly nullishes) disregarding case
 * @param a subject string to compare
 * @param b subject string to compare
 * @return true if a and b are considered equal
 */
export function excelEqual (a: string | null | undefined, b: string | null | undefined): boolean {
  if (a === b || (a == null && b == null)) {
    return true;
  }
  if (isStr(a) && isStr(b)) {
    return a.toLowerCase() === b.toLowerCase();
  }
  // these are not strings and not equal
  return false;
}

export function constructCellID (row: number, col: number): string {
  return `${colFromOffs(col)}${row + 1}`;
}
