import { isBrowser } from './runtimeContext';

function getNativeSessionStorage () {
  if (!isBrowser()) {
    return null;
  }
  try {
    const ss = window.sessionStorage;
    ss.setItem('_grid_', '_grid_');
    ss.removeItem('_grid_');
    return ss;
  }
  catch {
    return null;
  }
}

export const sessionStorage = getNativeSessionStorage() || {
  setItem (id, obj) {
    sessionStorage[id] = String(obj);
  },
  getItem (id) {
    return sessionStorage[id] || null;
  },
  removeItem (id) {
    delete sessionStorage[id];
  },
};
