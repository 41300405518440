import { ERROR_VALUE, isA1Ref, Reference } from '@grid-is/apiary';

import { validExpr } from '@/grid/utils';

import { OptionError } from '../OptionError';
import { isBlank } from '../utils/isBlank';
import { isFormula } from '../utils/isFormula';
import { typeCast } from '../utils/typeCast';
import { validateFormula } from '../utils/validateFormula';
import { GridOption } from './GridOption';

/**
 * Class representing a "Target" GRID element option.
 * This option expects a formula or single cell reference as its value.
 * Used to provide data and/or a write target to input elements.
 * @augments GridOption
 */
export class TargetOption extends GridOption {
  type = 'target';

  /** @param {import('./GridOption').GridOptionArgs} opts */
  constructor (opts) {
    super(opts);
    if (opts.enforceExpression == null) {
      this.enforceExpression = true;
    }
  }

  read = props => {
    return this.readCell(props);
  };

  parse (value, locale) {
    return typeCast(value, locale);
  }

  /**
   * Validate that a value is a valid formula or blank.
   * @param {string|number|boolean|null|undefined} value - An option value.
   * @return {OptionError|null} Error object or null if valid.
   */
  validate (value) {
    if (isBlank(value)) {
      return null;
    }
    const str = String(value);
    if (isFormula(str)) {
      return validateFormula(str);
    }
    // TODO: parse range expression using Apiary
    //   if valid expression: OKAY
    //   if has area >1 WARNING
    //   if formula that is nested in =OFFSET(...)/=INDIRECT(...): WARNING
    //   if formula: ERROR (cannot write to this formula)
    //   else: ERROR
    return new OptionError();
  }

  /**
   * Write a value to a spreadsheet model through this option. Its raw value in
   * `props` should point to a target cell, or a range. If a range, then the
   * same value is written to all cells of the range.
   * @abstract
   * @param {object} props - A props collection to read from.
   * @param {string|number|boolean|null|undefined|import('@/grid/types').CellArray} value - The value to write.
   * @param {boolean} [skipRecalc=false] - Skip automatic recalculation of the model after writes happen.
   */
  write (props, value, skipRecalc = false) {
    const propValue = this.readRaw(props);
    const model = /** @type {import('@grid-is/apiary').Model} */(props.model);
    if (model && validExpr(propValue)) {
      // run Apiary to determine if the expression results in a ref
      let ref = /** @type {unknown} */(model.runFormula(propValue ?? ''));
      // if ref is a named range, we'll have to resolve it
      if (ref instanceof Reference && !ref.range && ref.name) {
        ref = ref.ctx ? ref.resolveToNonName(ref.ctx) : ERROR_VALUE;
      }
      if (isA1Ref(ref)) {
        // it's a range or matrix
        if (Array.isArray(value)) {
          /** @type {null|[string, import('@grid-is/apiary').CellValue][]} */
          let writes = null;
          // all children must also be arrays
          if (value.every(Array.isArray)) {
            const srcHeight = value.length;
            const srcWidth = value.reduce((a, d) => Math.max(a, d.length), 0);
            const getValue = (r, c) => {
              return (value[r] && value[r][c])
                ? value[r][c].v ?? null
                : null;
            };
            if (
              // single value: write it to entire target range
              (srcHeight === 1 && srcWidth === 1) ||
              // target and source are the same dimensions: copy values
              (srcWidth === ref.width && srcHeight === ref.height) ||
              // target and source are both 1D and share length: copy values
              ((srcHeight === 1 || srcWidth === 1) &&
                ref.is1D() && (srcHeight * srcWidth) === ref.size)
            ) {
              writes = [];
              for (let i = 0; i < ref.range.size; i++) {
                writes.push([
                  String(ref.collapseToCell(~~(i / ref.width), i % ref.width)),
                  getValue(~~(i / srcWidth) % srcHeight, i % srcWidth),
                ]);
              }
            }
          }
          // if we have writes, then we pass them to the model
          if (writes && writes.length === 1) {
            const [ ref, val ] = writes[0];
            return model.write(ref, val, !skipRecalc);
          }
          else if (writes) {
            return model.writeMultiple(writes, { skipRecalc });
          }
          // for incompatible dimensions, we write #VALUE! to the range
          else {
            return model.write(String(ref), ERROR_VALUE, !skipRecalc);
          }
        }
        else {
          return model.write(String(ref), value ?? null, !skipRecalc);
        }
      }
    }
  }
}
