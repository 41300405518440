import { format, getFormatInfo, isDateFormat } from 'numfmt';

import { VOLATILES } from '@grid-is/apiary/lib/excel/constants';
import { getFontStack } from '@grid-is/fontstack';

import { renderInputValue } from '@/grid/utils/editValues';
import { defaultFonts, defaultStyles } from '@/WorkbookEditor/utils/constants';

/**
 * ProxiedCell is a copy of an Apiary Cell with additional
 * helper methods useful when displaying the values.
 *
 * @export class
 */
export class ProxiedCell {
  constructor (cellData, id = cellData.id) {
    const { v, _v, f, s, z, href, sheetIndex, M, _spill, F } = cellData;
    this.id = id;
    this.v = v;
    this._v = _v;
    this.f = f;
    this.s = s;
    this.z = z;
    this.href = href;
    this.sheetIndex = sheetIndex;
    this.M = M;
    this._spill = _spill;
    this.F = F;
    this.workbookType = 'native';
  }

  // Is part of a spilled range. Copied from Apiary.
  isSpilled () {
    return this._spill != null && this._spill.valid;
  }

  isVolatile () {
    return this.f && typeof this.f === 'string' && Array.from(VOLATILES).some(v => {
      return this.f.toUpperCase().includes(v);
    });
  }

  get isNumber () {
    return typeof this.v === 'number';
  }

  get hasValue () {
    return this.v != null && this.v !== '';
  }

  get hasFormula () {
    return !!this.f;
  }

  /**
   * Is the proxied cell's value an error?
   *
   * @type boolean
   */
  get hasError () {
    return this.v instanceof Error;
  }

  get isDate () {
    if (this.hasFormula) {
      return false;
    }
    const isNumber = typeof this.v === 'number';
    return isNumber && isDateFormat(this.z);
  }

  get isBoolean () {
    if (this.hasFormula || typeof this.v !== 'string') {
      return false;
    }
    const value = this.v.trim().toLowerCase();
    return value === 'true' || value === 'false';
  }

  get type () {
    if (this.hasFormula) {
      return 'formula';
    }
    else if (this.isDate) {
      return 'date';
    }
    else if (this.isBoolean) {
      return 'boolean';
    }
    return typeof this.v;
  }

  get isModifiedState () {
    if (this.v == null && this._v == null) {
      return false;
    }

    // If the cell has a modified value and is part of a spilled range we need to check for isRangeWrite - if that's true we want to show modified state otherwise not
    if (this._v !== this.v && this._spill && this._spill.isRangeWrite) {
      return true;
    }

    // 1. If a cell contains a spilled value we don't know if has a modified state
    // 2. We don't show modified state for volatile cells
    return this._v !== this.v && !this.isSpilled() && !this.isVolatile();
  }

  get editingValue () {
    let value = null;
    if (this.f) {
      value = '=' + this.f;
    }
    else if (this.v != null) {
      value = renderInputValue(this.v ?? '', this.z);
    }
    return value;
  }

  get printValue () {
    let text = '';
    const type = typeof this.v;
    if (this.v == null || this.v === '') {
      text = '';
    }
    else if (type === 'boolean' || this.hasError) {
      text = String(this.v).toUpperCase();
    }
    else { // num or string
      try {
        text = format(this.z || 'General', this.v);
      }
      catch (err) {
        text = '#########';
      }
    }
    return text;
  }

  get fontSize () {
    const fontsize = this.styleProp('font-size');
    return Math.round(fontsize * 1.3);
  }

  get backgroundColor () {
    return this.styleProp('fill-color');
  }

  get hAlign () {
    const type = typeof this.v;
    const horizontalStyle = this.styleProp('horizontal-alignment');
    if (horizontalStyle) {
      // horizontal alignment style always takes precedence
      // e.g a user can left align percentage by
      // 1. write number to sheet
      // 2. set number format to percentage (or some other number format)
      // 3. set the horizonal-alignment format
      // NOTE: Currently the steps are not supported in GRID Sheets.
      return horizontalStyle;
    }
    try {
      if (this.z && type !== 'string') {
        // unless the value is of type string,
        // if the user has set specific number formatting
        // the adhere to those rules
        const fmtInfo = getFormatInfo(this.z);
        if (fmtInfo.type === 'error') {
          return 'center';
        }
        else if (fmtInfo.type === 'text') {
          return 'left';
        }
      }
    }
    catch (e) {
      // it's possible that the numfmt lib emits an error so,
      // allow it fail with grace
    }

    let fallbackHorizontalAlignment = 'left';
    if (type === 'boolean' || this.hasError) {
      fallbackHorizontalAlignment = 'center';
    }
    else if (type === 'number') {
      fallbackHorizontalAlignment = 'right';
    }
    return fallbackHorizontalAlignment;
  }

  get font () {
    let result = '';
    if (this.styleProp('italic') === true) {
      result += 'italic ';
    }
    if (this.styleProp('bold') === true) {
      result += 'bold ';
    }
    result += this.fontSize + 'px ';
    const fontName = this.styleProp('font-name');
    result += getFontStack(fontName);

    return result;
  }

  get fontColor () {
    return this.styleProp('font-color');
  }

  get borderLeft () {
    return this.border('left');
  }

  get borderTop () {
    return this.border('top');
  }

  get borderRight () {
    return this.border('right');
  }

  get borderBottom () {
    return this.border('bottom');
  }

  get bold () {
    return this.styleProp('bold');
  }

  get italic () {
    return this.styleProp('italic');
  }

  get underline () {
    return this.styleProp('underline');
  }

  get hasBorder () {
    return this.borderStyle('top') || this.borderStyle('left') || this.borderStyle('right') || this.borderStyle('bottom');
  }

  get defaultFont () {
    return defaultFonts[this.workbookType] || defaultFonts.unknown;
  }

  styleProp (prop) {
    // default font value depends on the Workbook's origin
    const defaultValue = (prop === 'font-name')
      ? this.defaultFont
      : defaultStyles[prop];
    return this.s && this.s[prop] || defaultValue;
  }

  border (side) {
    const style = this.borderStyle(side);
    if (style) {
      return {
        style,
        color: this.borderColor(side),
      };
    }
  }

  borderStyle (side) {
    return this.styleProp(`border-${side}-style`);
  }

  borderColor (side) {
    return this.styleProp(`border-${side}-color`);
  }
}

/**
 * Creates an instance of ProxiedCell given an Apiary Cell Object
 *
 * @param {import('@grid-is/apiary').Cell} cell: Apiary Cell object
 * @param {string} workbookType: the origin type of the container workbook (native | excel | google-sheets)
 * @returns instance of ProxiedCell
 */
ProxiedCell.from = (cell, workbookType = 'native') => {
  const proxyCell = new ProxiedCell(cell);
  proxyCell.workbookType = workbookType;
  return proxyCell;
};
