/* eslint-disable react/prop-types */
import React from 'react';

import { a1ToRowColumn, colFromOffs, Range, Reference } from '@grid-is/apiary';

import { BORDER_GRID, Borders } from './Borders';
import { CellRect } from './CellRect';
import { packID } from './intId';
import { Plane } from './Plane';
import * as render from './render';
import { SheetWindow } from './SheetWindow';

const COLOR_WHITE = '#fff';

const HEADER_HEIGHT = 18;
const HEADER_WIDTH = 25;

/**
 * @param {Range} range
 * @yields {[number, number]}
 */
function* offsetsFromRange (range) {
  // This is a generic utility function. It might belong somewhere else.
  for (let col = range.left; col <= range.right; col++) {
    for (let row = range.top; row <= range.bottom; row++) {
      yield [ col, row ];
    }
  }
}

/**
 * @typedef SheetGridProps
 * @prop {boolean} [headers=false]
 * @prop {string} locale
 * @prop {import('@grid-is/apiary').A1Reference} range
 * @prop {import('@grid-is/apiary').A1Reference} [highlight]
 * @prop {string} [highlightColor]
 * @prop {boolean} [hasTabs]
 * @prop {import('@grid-is/apiary').Workbook} workbook
 * @prop {number} [maxWidth=null]
 * @prop {number} [maxHeight=null]
 */

export class SheetGrid extends React.Component {
  static defaultProps = {
    headers: true,
  };

  /** @param {SheetGridProps} props */
  constructor (props) {
    super(props);
    this.skipCells = new Map();
    this.borders = new Borders();
    this.state = {
      height: null,
      width: null,
      flowMode: false,
      sheetSize: [ 0, 0 ],
      scrollPos: [ 0, 0 ],
      scale: new Plane({ top: 0, left: 0, bottom: 0, right: 0 }),
    };
  }

  /**
   * @param {SheetGridProps} props
   * @param {Record<string, any>} prevState
   */
  static getDerivedStateFromProps (props, prevState) {
    const { workbook, range } = props;

    const strRef = String(range);

    let fullUpdate = (
      strRef !== prevState.currRef ||
      workbook?.instanceId !== prevState.instanceId ||
      props.hasTabs !== prevState.hasTabs
    );

    const newState = {
      currRef: strRef,
      newSelectionPos: false,
      headers: props.headers,
    };

    // if the sheet size has changed, we need a full update
    if (range.sheetName && workbook) {
      const size = workbook.getSheetSize(range.sheetName);
      const prevSize = prevState.sheetSize;
      if (size[0] !== prevSize[0] || size[1] !== prevSize[1]) {
        newState.sheetSize = size;
        fullUpdate = true;
      }
    }

    if (fullUpdate) {
      newState.instanceId = workbook?.instanceId;
    }

    if (!prevState.scale || fullUpdate) {
      newState.scale = Plane.from(range, workbook);
    }

    // if width/height/hasTabs is set, we start in "flow mode"
    // In flow mode we fix the height and weight of the window and render
    // the full sheet. This is opposite to static mode where we only render
    // the range provided.
    const scale = newState.scale || prevState.scale;
    newState.flowMode = !!(props.maxWidth || props.maxHeight || props.hasTabs);
    if (newState.flowMode) {
      newState.width = props.maxWidth || '100%';
      newState.height = (
        props.maxHeight ||
        (props.maxWidth ? props.maxWidth * 0.45 : null) ||
        null // "auto"
      );
      newState.scrollWidth = scale.width + 1;
      newState.scrollHeight = scale.height + 1;
    }
    else {
      // scroll size is the size of the selected range
      const viewDim = scale.rangeToRect(range);
      newState.scrollHeight = viewDim.height;
      newState.scrollWidth = viewDim.width + 1;
      newState.scrollHeight = viewDim.height + 1;
      newState.width = viewDim.width + 1;
      newState.height = viewDim.height + 1;
    }

    return newState;
  }

  componentDidMount () {
    this.resizeGrid();
    this.renderGrid();

    if (this.sheetWindow) {
      // start by scrolling to highlight if present, else the range
      const { highlight, range } = this.props;
      const r = this.hasHighlight() ? highlight : range;
      const rect = this.state.scale.rangeToRect(r.range);
      this.sheetWindow.scrollIntoView(rect);
    }
  }

  componentDidUpdate (prevProps) {
    const newName = this.props.sheetName !== prevProps.sheetName;
    const newData = this.props.range !== prevProps.range;
    const newHeaders = this.props.headers !== prevProps.headers;
    if (newName || newData || newHeaders) {
      this.resizeGrid();
    }
    this.renderGrid();
  }

  /**
   * Returns the size of the grids column & row headers.
   * @returns {{ width: number, height: number }}
   */
  getHeadersSize () {
    const { headers } = this.props;
    return {
      height: headers ? HEADER_HEIGHT : 0,
      width: headers ? HEADER_WIDTH : 0,
    };
  }

  /** @returns {import('@grid-is/apiary').WorkSheet | null} */
  getCurrentSheet () {
    return this.props.workbook?.getSheet(this.props.range.sheetName);
  }

  /**
   * @param {number} colIndex
   * @returns {null | Record<string, any>}
   */
  getColumnStyle (colIndex) {
    const sheet = this.getCurrentSheet();
    if (sheet && sheet.columns) {
      const adjustedIndex = colIndex + 1;
      const columnInfo = sheet.columns.find(col => col.begin <= adjustedIndex && adjustedIndex <= col.end);
      if (columnInfo?.si != null) {
        return this.props.workbook.styles[columnInfo.si];
      }
    }
    return null;
  }

  /**
   * @param {number} x
   * @param {number} y
   * @returns {null | Record<string, any>}
   */
  getStyles (x, y) {
    const sheet = this.getCurrentSheet();
    const cell = (sheet && sheet.getCellByCoords(y, x)) || null;
    return (cell && cell.s) || this.getColumnStyle(x);
  }

  /**
   * @param {null | Cell} cell
   * @return {null | Range}
   */
  getMergeRange (cell) {
    // disregard any instance where a merge is same as a cell
    // this can happen in older sheets and isn't cleaned out by converted
    if (cell && cell.M) {
      const sheet = this.getCurrentSheet();
      const r = Reference.parse(cell.M)?.range;
      const m = sheet && sheet.merges[cell.M];
      if (r && m) {
        return new Range({
          top: r.top,
          left: r.left,
          bottom: r.top + m[1] - 1,
          right: r.left + m[0] - 1,
        });
      }
    }
    return null;
  }

  hasHighlight () {
    const { highlight, range } = this.props;
    return (
      highlight &&
      highlight.range &&
      (!highlight.workbookName || highlight.workbookName === range.workbookName) &&
      (!highlight.sheetName || highlight.sheetName === range.sheetName)
    );
  }

  resizeGrid () {
    const ratio = window.devicePixelRatio;
    const { canvas, ctx } = this;
    if (!canvas) {
      return;
    }
    const containerDim = canvas.parentElement?.getBoundingClientRect() || { width: 100, height: 100 };
    let w = containerDim.width;
    let h = containerDim.height;
    if (!this.state.flowMode) {
      const head = this.getHeadersSize();
      w = Math.min(this.state.scrollWidth + head.width + 1, containerDim.width);
      h = Math.min(this.state.scrollHeight + head.height + 1, 12600);
    }
    if (ctx && canvas && (h !== canvas.height || w !== canvas.width)) {
      canvas.style.height = h + 'px';
      canvas.style.width = w + 'px';
      canvas.height = h * ratio;
      canvas.width = w * ratio;
      ctx.scale(ratio, ratio);
    }
  }

  /** @returns {(x:number, y:number) => Cell | null} */
  cellGetter () {
    const sheet = this.getCurrentSheet();
    return (x, y) => {
      return (sheet && sheet.getCellByCoords(y, x)) || null;
    };
  }

  shouldRenderGridLines () {
    const sheet = this.getCurrentSheet();
    if (sheet && sheet.show_grid_lines === false) {
      return false;
    }
    return true;
  }

  detectBorders () {
    const doGridLines = this.shouldRenderGridLines();
    const { left, top, right, bottom } = this.state.scale.viewBox;
    /** @type {[ string, import('./Borders').BorderSide ][]} */
    const directions = [
      [ 'top', Borders.TOP ],
      [ 'left', Borders.LEFT ],
      [ 'bottom', Borders.BOTTOM ],
      [ 'right', Borders.RIGHT ],
    ];
    if (doGridLines) {
      // pass 1, add gridlines
      for (let x = left; x <= right + 1; x++) {
        for (let y = top; y <= bottom + 1; y++) {
          /** @type {[ number, number ]} */
          const pos = [ x, y ];
          this.borders.set(pos, Borders.TOP, BORDER_GRID);
          this.borders.set(pos, Borders.LEFT, BORDER_GRID);
        }
      }
      // pass 2, remove gridlines off any cells touching a background color
      for (let x = left; x <= right + 1; x++) {
        for (let y = top; y <= bottom + 1; y++) {
          const styles = this.getStyles(x, y);
          if (styles && styles['fill-color']) {
            /** @type {[ number, number ]} */
            const pos = [ x, y ];
            this.borders.set(pos, Borders.TOP, null);
            this.borders.set(pos, Borders.LEFT, null);
            this.borders.set(pos, Borders.BOTTOM, null);
            this.borders.set(pos, Borders.RIGHT, null);
          }
        }
      }
      // pass 3: remove borders from inside merges
      const sheet = this.getCurrentSheet();
      const merges = (sheet && sheet.merged_cells) || [];
      for (const m of merges) {
        const merge = Reference.parse(m)?.range;
        if (merge) {
          for (let x = merge.left; x <= merge.right; x++) {
            for (let y = merge.top; y <= merge.bottom; y++) {
              /** @type {[ number, number ]} */
              const pos = [ x, y ];
              if (y > merge.top) {
                this.borders.set(pos, Borders.TOP, null);
              }
              if (x > merge.left) {
                this.borders.set(pos, Borders.LEFT, null);
              }
            }
          }
        }
      }
    }
    // pass 4, add actual cell styles
    // Note that we purposely don't collect borders for cells outside the
    // bounding box like above. We do want the gridlines to appear on the bottom
    // and right edges, but not the edges of boxes starting outside the view.
    // This only works because Excel defines semantic borders: e.g. it uses left
    // _and_ right for a cell as appropriate.
    for (let x = left; x <= right; x++) {
      for (let y = top; y <= bottom; y++) {
        const styles = this.getStyles(x, y);
        if (styles) {
          for (const [ dirS, dirN ] of directions) {
            const ts = styles[`border-${dirS}-style`];
            const tc = styles[`border-${dirS}-color`] || '#000';
            if (ts && ts !== 'none') {
              this.borders.set([ x, y ], dirN, { style: ts, color: tc });
            }
          }
        }
      }
    }
  }

  /**
   * Is a Cell allowed to overflow? Returns:
   *
   * * `0` if overflow is not allowed
   * * `1` if overflow is allowed to the right
   * * `-1` if overflow is allowed to the left
   *
   * @param {Cell} cell
   * @returns {number}
   */
  canOverflow (cell) {
    // only non-empty strings can overflow
    if (!cell || typeof cell.v !== 'string' || !cell.v) {
      return 0;
    }
    // cells that are a part of a merge do not overflow
    if (cell.M) {
      return 0;
    }
    // text wrapped cells don't overflow
    if (cell.s?.['wrap-text']) {
      return 0;
    }
    const alignment = (
      cell.s?.['horizontal-alignment'] ||
      // XXX: column styles may have alignment that affects this
      render.getDefaultAlignment(cell)
    );
    return alignment === 'left' ? 1 : -1;
  }

  /**
   * @param {number} x: the current column being rendered
   * @param {number} y: the current row being rendered
   * @param {boolean} [isExtended=false]: is this cell from outside the viewport
   */
  renderCellText (x, y, isExtended = false) {
    const { scale } = this.state;
    const getCell = this.cellGetter();
    const sheet = this.getCurrentSheet();
    if (!sheet) {
      return;
    }
    const cellStyles = this.getStyles(x, y) || {};
    const { skipCells, borders } = this;

    const cell = getCell(x, y);
    if (!cell || cell.v == null || cell.v === '') {
      return;
    }

    const column = scale.getCol(x);
    const textWidth = render.measureText(cell, this.props.locale) + 8;
    const align = cellStyles['horizontal-alignment'] || render.getDefaultAlignment(cell);
    const overflowAlign = align === 'left' ? 1 : -1;
    const canOverflow = (textWidth > column.size ? overflowAlign : 0) && this.canOverflow(cell);

    if (isExtended && !canOverflow) {
      // if this is a cell from outside the viewport that doesn't overflow, then
      // we don't need to render it.
      return;
    }

    let minX = scale.viewBox.left;
    let maxX = scale.viewBox.right;
    if (!canOverflow) {
      minX = x;
      maxX = x;
    }
    // limited to the right by another cell?
    if (canOverflow && (align === 'left' || align === 'center')) {
      const rightCell = sheet.nextValueCellByCoords(y, x, 'right');
      if (rightCell) {
        maxX = a1ToRowColumn(rightCell.id)[1] - 1;
      }
    }
    // limited to the left by another cell?
    if (canOverflow && (align === 'right' || align === 'center')) {
      const leftCell = sheet.nextValueCellByCoords(y, x, 'left');
      if (leftCell) {
        minX = a1ToRowColumn(leftCell.id)[1] + 1;
      }
    }

    let sx = column.start;
    let ex = column.end;
    if (canOverflow) {
      // left aligned text may overflow to the right
      if (align === 'left' && maxX > x) {
        const tX = scale.toCol(column.start + textWidth).index;
        const rCol = Math.min(maxX, tX ?? Range.MAX_COL);
        ex = scale.getCol(rCol).end;
        for (let i = x + 1; i <= rCol; i++) {
          borders.set([ i, y ], Borders.LEFT, null);
          skipCells.set(packID(i, y), 1);
        }
      }
      // right aligned text may overflow to the left
      else if (align === 'right' && minX < x) {
        const tX = scale.toCol(column.end - textWidth).index;
        const lCol = Math.max(minX, tX || 0);
        sx = scale.getCol(lCol).start;
        for (let i = x; i >= lCol; i--) {
          borders.set([ i, y ], Borders.LEFT, null);
          skipCells.set(packID(i, y), 1);
        }
      }
      // center aligned text may overflow in either direction
      else if (align === 'center') {
        const cx = column.start + column.size / 2;
        // right
        const rX = scale.toCol(cx + textWidth / 2).index;
        const rCol = Math.min(maxX, rX ?? Range.MAX_COL);
        ex = scale.getCol(rCol).end;
        for (let i = x + 1; i <= rCol; i++) {
          borders.set([ i, y ], Borders.LEFT, null);
          skipCells.set(packID(i, y), 1);
        }
        // left
        const lX = scale.toCol(cx - textWidth / 2).index;
        const lCol = Math.max(minX, lX || 0);
        sx = scale.getCol(lCol).start;
        for (let i = x + 1; i >= lCol + 1; i--) {
          borders.set([ i, y ], Borders.LEFT, null);
          skipCells.set(packID(i, y), 1);
        }
      }
    }

    const headers = this.getHeadersSize();
    const cellRect = scale
      .getCellRect(x, y)
      .translate(headers.width, headers.height);

    const clipRect = new CellRect(
      headers.width + sx - scale.viewLeft,
      cellRect.y,
      ex - sx,
      cellRect.height,
    );

    render.renderText(cell, {
      cellRect: cellRect.translate(...scale.tilt),
      clipRect: clipRect.translate(...scale.tilt),
      locale: this.props.locale,
    });
  }

  renderBackgrounds () {
    const { scale } = this.state;
    const getCell = this.cellGetter();
    const headers = this.getHeadersSize();
    render.setCtx(this.ctx);

    for (const pos of offsetsFromRange(scale.viewBox)) {
      const [ x, y ] = pos;
      // Google Sheets does not always correctly set background to non-anchor
      // cells of a merge so we check for merges and read background color from
      // the anchor cell.
      const merge = this.getMergeRange(getCell(x, y));
      const styles = merge
        ? this.getStyles(merge.left, merge.top)
        : this.getStyles(x, y);
      const cellRect = scale.getCellRect(x, y)
        .translate(headers.width, headers.height)
        .translate(...scale.tilt);
      const fillColor = styles ? styles['fill-color'] : null;
      render.background(cellRect, fillColor);
    }
  }

  /**
   * Renders the text values and background colors of the cells in the grid.
   * NOTE: does not render merged cells & borders
   */
  renderContent () {
    const getCell = this.cellGetter();
    const { scale } = this.state;
    const sheet = this.getCurrentSheet();
    if (!sheet) {
      return;
    }
    const head = this.getHeadersSize();
    render.setCtx(this.ctx);
    const vb = scale.viewBox;
    for (let y = vb.top; y <= vb.bottom; y++) {
      // if there is a cell to the left or right of the view that possibly bleeds
      // into view, we render it
      if (vb.left > 0) {
        const leftCell = sheet.nextValueCellByCoords(y, vb.left, 'left');
        if (leftCell && this.canOverflow(leftCell)) {
          const r = Reference.parse(leftCell.id)?.range;
          r && this.renderCellText(r.left, y, true);
        }
      }
      if (vb.right < Range.MAX_COL) {
        const rightCell = sheet.nextValueCellByCoords(y, vb.right, 'right');
        if (rightCell && this.canOverflow(rightCell)) {
          const r = Reference.parse(rightCell.id)?.range;
          r && this.renderCellText(r.left, y, true);
        }
      }

      for (let x = vb.left; x <= vb.right; x++) {
        if (this.skipCells.has(packID(x, y))) {
          continue;
        }
        let cell = getCell(x, y);
        const merge = this.getMergeRange(cell);
        if (merge && cell?.M !== cell?.id) {
          // Because we allow the displayed range to slice merged cells, this
          // may not be the actual content cell. We therefore re-query for the
          // top/left-most cell in the merge:
          cell = getCell(merge.left, merge.top);
        }
        if (!cell || cell.v == null || cell.v === '') {
          continue;
        }
        if (merge) {
          const cellRect = scale
            .rangeToRect(merge)
            .translate(head.width, head.height)
            .translate(...scale.tilt);
          for (const [ mx, my ] of offsetsFromRange(merge)) {
            // ignore cell in this merge in future iterations
            this.skipCells.set(packID(mx, my), 1);
          }
          render.renderText(cell, {
            cellRect: cellRect,
            locale: this.props.locale,
          });
        }
        else {
          const cellRect = scale
            .getCellRect(x, y)
            .translate(head.width, head.height);
          if (cellRect.width > 0 && cellRect.height > 0) {
            this.renderCellText(x, y);
          }
        }
      }
    }
  }

  renderBorders () {
    const { scale } = this.state;
    const { borders } = this;
    const [ tx, ty ] = scale.tilt;
    const head = this.getHeadersSize();
    render.setCtx(this.ctx);
    borders.forEach((pos, direction, attributes) => {
      const cellRect = scale.getCellRect(pos[0], pos[1])
        .translate(head.width, head.height);
      if (direction === Borders.TOP) {
        render.border(
          [ cellRect.x + tx, cellRect.y + ty ],
          [ cellRect.right + tx, cellRect.y + ty ],
          attributes.style || 'none',
          attributes.color,
          borders.getJoin(pos[0], pos[1]),
          borders.getJoin(pos[0] + 1, pos[1]),
        );
      }
      else if (direction === Borders.LEFT) {
        render.border(
          [ cellRect.x + tx, cellRect.y + ty ],
          [ cellRect.x + tx, cellRect.bottom + ty ],
          attributes.style || 'none',
          attributes.color,
          borders.getJoin(pos[0], pos[1]),
          borders.getJoin(pos[0], pos[1] + 1),
        );
      }
    });
  }

  renderGrid () {
    if (!this.ctx || !this.canvas) {
      return;
    }
    const { scale, flowMode } = this.state;
    const head = this.getHeadersSize();

    const containerDim = this.canvas.parentElement?.getBoundingClientRect() || { width: 100, height: 100 };

    let [ sx, sy ] = this.state.scrollPos;
    if (!flowMode) {
      // in non-flow mode we need to offset by the top/left of the range
      scale.setView(0, 0, containerDim.width, containerDim.height);
      const viewDim = scale.rangeToRect(this.props.range.range);
      sx += viewDim.left;
      sy += viewDim.top;
    }
    scale.setView(sx, sy, containerDim.width, containerDim.height);

    this.ctx.fillStyle = COLOR_WHITE;
    this.ctx.fillRect(0, 0, this.canvas.clientWidth, this.canvas.clientHeight);
    render.setCtx(this.ctx);

    this.skipCells.clear();
    this.borders.clear();
    this.detectBorders();
    this.renderBackgrounds();
    this.renderContent();
    this.renderBorders();
    this.renderHighlight();

    this.ctx.save();
    this.ctx.translate(head.width, head.height);
    this.renderHeaders();
    this.ctx.restore();

    // free memory
    this.borders.clear();
    this.skipCells.clear();
  }

  renderHighlight () {
    if (this.hasHighlight()) {
      const { highlight } = this.props;
      const { scale } = this.state;
      const color = this.props.highlightColor || '#39c6d9';
      const head = this.getHeadersSize();
      scale
        .rangeToRect(highlight.range)
        .translate(head.width, head.height)
        .translate(...scale.tilt)
        .getBorders()
        .forEach(([ start, end ]) => {
          render.border(start, end, 'medium', color);
        });
    }
  }

  renderHeaders () {
    if (this.props.headers) {
      const { scale } = this.state;
      const range = scale.viewBox;
      const head = this.getHeadersSize();
      const [ tx, ty ] = scale.tilt;
      // draw left column headers
      for (let y = range.top; y <= range.bottom; y++) {
        const row = scale.getRowSize(y);
        if (row.size) {
          render.header(
            String(y + 1),
            new CellRect(0, head.height + row.start - 1 + ty, head.width, row.size),
          );
        }
      }
      // draw top row headers
      for (let x = range.left; x <= range.right; x++) {
        const col = scale.getColSize(x);
        if (col.size) {
          render.header(
            colFromOffs(x),
            new CellRect(head.width + col.start + tx, 0, col.size, head.height),
          );
        }
      }
      // draw "corner" cell
      render.header('', new CellRect(0, 0, head.width, head.height));
    }
  }

  render () {
    const head = this.getHeadersSize();
    const { height, width, scrollWidth, scrollHeight } = this.state;
    return (
      <SheetWindow
        ref={r => (this.sheetWindow = r)}
        top={head.height}
        left={head.width}
        width={width}
        height={height}
        innerWidth={scrollWidth} // sheet width
        innerHeight={scrollHeight} // sheet height
        onScroll={e => {
          this.setState({ scrollPos: [ e.left, e.top ] });
          this.renderGrid();
        }}
        onResize={() => {
          this.resizeGrid();
          this.renderGrid();
        }}
        >
        <canvas
          ref={elm => {
            this.canvas = elm;
            this.ctx = elm && elm.getContext('2d', { alpha: false });
          }}
          />
      </SheetWindow>
    );
  }
}
