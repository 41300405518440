import { CallbackWithStop, Signal } from '../Signal';
import { isErr, isStr } from '../typeguards';
import { AssertionError, invariant } from '../validation';
import {
  ASTNode,
  ASTNonLiteralNode,
  ASTReferenceOperationNode,
  ASTCallNode,
  ASTFunctionCallNode,
  ASTReferenceNode,
  ASTStructuredReferenceNode,
  ASTLetNode,
  ASTPath,
  type ASTLambdaNode,
} from './ast-types';
import { type EvaluationContext } from './EvaluationContext';
import Reference, { isRef, prefix, type A1Reference } from './Reference';
import { boundingBox, doIntersectionOperation, doRangeOperation } from './evaluate-common';
import { lazyArgumentFunctions } from './functions/lazy-argument';
import Range from './referenceParser/Range';
import { FLAG_IS_GOOGLE_ARRAY_FUNCTION, MaybeBoxedFormulaArgument } from './types';
import { resolveCallee, resolveLambda } from './functions/calls';
import { MODE_GOOGLE, VOLATILES } from './constants';
import { isReferenceOperationNode, isStaticReferenceNode, isLiteralNode, visitAllNonLiteralNodes } from './ast-common';
import { formatCellValueForFormula } from './functions/utils';
import { funcSigs, SIG_MODE, SIG_FLAGS } from './signatures';
import { toNum } from './functions/utils-number';
import FormulaError from './FormulaError';
import { MaybeBoxed } from './ValueBox';
import type { LambdaParameterSymbol } from './lambda';

/**
 * Functions which take a reference argument but never look at cell _values_.
 */
const NON_VALUE_REFERENCE_FUNCTIONS = [ 'ROW', 'COLUMN', 'ROWS', 'COLUMNS', 'INTERSECT', 'FORMULATEXT' ];

/**
 * Functions which call a lambda that is passed to them as an argument.
 */
const LAMBDA_CALLING_FUNCTIONS = new Set([ 'BYROW', 'BYCOL', 'MAKEARRAY', 'MAP', 'REDUCE', 'SCAN' ]);

export function visitAllCallNodes (ast: ASTNode, callback: CallbackWithStop<ASTCallNode>): void {
  visitAllNonLiteralNodes(ast, (node, stop) => {
    if ('call' in node) {
      callback(node, stop);
    }
  });
}

export function hasFunctionCallNode (ast: ASTNode, predicate: (node: ASTFunctionCallNode) => boolean): boolean {
  let result = false;
  visitAllCallNodes(ast, (node, stop) => {
    // XXX: not sure why the `as ASTFunctionCallNode` is not inferred
    if (typeof node.call === 'string' && predicate(node as ASTFunctionCallNode)) {
      result = true;
      stop.set();
    }
  });
  return result;
}

export type FnEvaluateStaticReferenceASTNode = (ast: ASTNode) => Reference | null | undefined;

export type FnEvaluateASTNode = (ast: ASTNode) => MaybeBoxedFormulaArgument;

type StaticEvaluationContext = EvaluationContext & {
  evaluateStaticReferenceASTNode: FnEvaluateStaticReferenceASTNode,
};

type VisitStaticReferences = (
  /**
   * Path of non-literal nodes from the AST root down to and including `ast`.
   * Note: this differs from the path parameter of `visitAllStaticReferences`,
   * which is all non-literal nodes from AST root down to but _excluding_ `ast`.
   */
  path: ASTPath,
  ast: ASTFunctionCallNode,
  opts: StaticEvaluationContext,
  callback: CallbackWithStop<ReferenceWithASTPath>,
  stopSignal: Signal
) => void;

const visitStaticReferencesForFunction: Partial<Record<string, VisitStaticReferences>> = {
  IF: visitStaticReferencesInIfCall,
  IFS: visitStaticReferencesInIfsCall,
  IFERROR: visitStaticReferencesInIfNaOrIfErrorCall,
  IFNA: visitStaticReferencesInIfNaOrIfErrorCall,
  OFFSET: visitStaticReferencesInOffsetCall,
  HLOOKUP: visitStaticReferencesInHlookupVlookupCall,
  VLOOKUP: visitStaticReferencesInHlookupVlookupCall,
  LOOKUP: visitStaticReferencesInLookupCall,
  XLOOKUP: visitStaticReferencesInXLookupCall,
  INDEX: visitStaticReferencesInIndexCall,
  ROWS: visitStaticReferencesInRowsOrColumnsCall,
  COLUMNS: visitStaticReferencesInRowsOrColumnsCall,
  SUMIF: visitStaticReferencesInAggregateIfCall,
  AVERAGEIF: visitStaticReferencesInAggregateIfCall,
  CELL: visitStaticReferencesInCellCall,
};
for (const funcName of NON_VALUE_REFERENCE_FUNCTIONS) {
  visitStaticReferencesForFunction[funcName] = visitStaticReferencesInNonValueReferenceFunctionCall;
}

export type ReferenceWithASTPath = [ref: Reference | 'volatile', astPath: ASTPath | null];

/**
 * Yield all static references of the formula expression represented by the given AST subtree. This includes
 * _conditional_ static references (those that are not certain to be required up-to-date when the formula is evaluated)
 * in several cases, excludes some apparent static references in other cases, and applies evaluation context to the
 * references where needed (in particular, applying evaluation context sheet name to unprefixed cell/column/range
 * references). Specifically:
 *
 * * nodes of type cell, colrange, rowrange and name yield a reference qualified by the evaluation context's sheet name
 * * nodes of type prefix yield the reference of their subtree, but override sheet, workbook and/or dir name.
 * * nodes of type call yield static references from their arguments, with some special-case exceptions in which
 *   those references are marked conditional, otherwise modified, or omitted.
 * * nodes of type range and intersection yield the result of the respective reference operation applied to their
 *   operands, if those operands themselves evaluate as static references. If they are name references that yield
 *   dynamic references, or non-reference results, or evaluating them leads to a dependency loop of name references,
 *   then the name references are themselves yielded, marked conditional (as we do not _know_ that all cells they
 *   resolve to will be required by this expression).
 * * nodes of type union yield nothing, as we do not yet support evaluating unions. (The formula may still not need
 *   to evaluate the union, e.g. if it is in the false-branch of an `IF` whose condition arg evaluates as true, etc.)
 *
 * Additionally, the value `'volatile'` is yielded if determining a complete set
 * of possible dependencies was infeasible. This signals that the cell should be
 * treated as volatile to ensure that it gets recalculated when a cell in that
 * unknown set has updated.
 *
 * @param path array of AST non-literal nodes from the AST root down to (but excluding) `ast`.
 * @param ast the AST node whose subtree is to be searched
 * @param opts the evaluation context
 * @param evaluateStaticReferenceASTNode function mapping a node to a Reference,
 *   bound to an evaluation context with the context sheetName of the formula
 *   containing this AST (null in the case of defined-name formulas)
 * @returns static references (possibly conditional) in the given AST subtree
 */
export function visitAllStaticReferences (
  path: ASTPath | [],
  ast: ASTNode,
  opts: StaticEvaluationContext,
  callback: CallbackWithStop<ReferenceWithASTPath>,
  stopSignal = new Signal(),
): void {
  if (ast == null || isLiteralNode(ast)) {
    // no-op, literal nodes (including nulls, i.e. blank arguments) have no static references
    return;
  }
  path = [ ...path, ast ];
  if (isStaticReferenceNode(ast)) {
    const staticRef = opts.evaluateStaticReferenceASTNode(ast);
    if (staticRef != null) {
      callback([ staticRef, path ], stopSignal);
      if ('name' in ast && opts.collectStaticReferencesFromLambdaDefinitions) {
        const definedName = opts.resolveName(ast.name);
        if (!isErr(definedName) && isNonLiteralNode(definedName._ast) && 'lambda' in definedName._ast) {
          visitStaticReferencesInLambdaDefinition(definedName._ast, callback, path, opts, stopSignal);
        }
      }
    }
    return;
  }
  if (isReferenceOperationNode(ast)) {
    for (const refP of getStaticReferencesFromReferenceOperationNode(path, ast, opts)) {
      callback(refP, stopSignal);
      if (stopSignal.isSet) {
        return;
      }
    }
    return;
  }
  if ('binop' in ast) {
    for (const elem of ast.args) {
      visitAllStaticReferences(path, elem, opts, callback, stopSignal);
      if (stopSignal.isSet) {
        return;
      }
    }
  }
  else if ('unop' in ast) {
    visitAllStaticReferences(path, ast.expr, opts, callback, stopSignal);
  }
  else if ('array' in ast) {
    for (const row of ast.array) {
      for (const elem of row) {
        visitAllStaticReferences(path, elem, opts, callback, stopSignal);
        if (stopSignal.isSet) {
          return;
        }
      }
    }
  }
  else if ('lambda' in ast) {
    // Lambda _definitions_ have no static references (calls to them may), but
    // complex dependency analysis cases fall back on reporting all references
    // in a lambda call's arguments and callee as conditional references, so in
    // that case we do collect static references from lambda definitions.
    if (opts.collectStaticReferencesFromLambdaDefinitions) {
      visitStaticReferencesInLambdaDefinition(ast, callback, path, opts, stopSignal);
    }
  }
  else if ('let' in ast) {
    visitLetReferences(path, ast, opts, stopSignal, callback);
  }
  else if ('call' in ast) {
    if (typeof ast.call !== 'string') {
      visitAllStaticReferences(path, ast.call, opts, callback, stopSignal);
    }
    const nameExists = (name: string) => !isErr(opts.resolveName(name));
    const _path = path; // Workaround for overcautious type inference in closure
    const callee =
      typeof ast.call === 'object' && 'lambda' in ast.call
        ? {
          funcName: null,
          lambda: resolveLambda(null, ast.call, opts, nameExists),
        }
        : resolveCallee(ast, opts, nameExists, ref => callback([ ref, _path ], stopSignal));
    if (isErr(callee)) {
      return;
    }
    const { funcName, lambda } = callee;

    if (lambda && !isErr(lambda)) {
      const symbols = lambda.parameterSymbols;
      const parameterSymbolToArgAST = new Map<LambdaParameterSymbol, ASTNode>();
      let i = 0;
      for (const symbol of symbols.values()) {
        if (i >= ast.args.length) {
          break;
        }
        parameterSymbolToArgAST.set(symbol, ast.args[i]);
        i += 1;
      }
      const lambdaPath = isNonLiteralNode(ast.call) ? (path.concat(ast.call) as ASTPath) : path;
      const staticEvalCtx = {
        ...opts,
        resolveLambdaArgumentAST: (param: LambdaParameterSymbol) => {
          const resolved = parameterSymbolToArgAST.get(param);
          if (resolved == null && !parameterSymbolToArgAST.has(param)) {
            const paramBinding = lambda.boundArgs?.get(param);
            if (paramBinding && 'astNode' in paramBinding) {
              const { astNode: boundArg, ctx: bindingCtx } = paramBinding;
              visitAllStaticReferences(
                lambdaPath,
                boundArg,
                { ...bindingCtx, evaluateStaticReferenceASTNode: opts.evaluateStaticReferenceASTNode.bind(bindingCtx) },
                // Do not report AST paths in LAMBDA definition, because we will not optimize inside it.
                ([ ref ]: ReferenceWithASTPath, stop: Signal) => callback([ ref, null ], stop),
                stopSignal,
              );
            }
          }
          return resolved;
        },
      };
      visitAllStaticReferences(
        lambdaPath,
        lambda.expression,
        staticEvalCtx,
        // Do not report AST paths in LAMBDA definition, because we will not optimize inside it.
        ([ ref ]: ReferenceWithASTPath, stop: Signal) => callback([ ref, null ], stop),
        stopSignal,
      );
    }
    else if (funcName) {
      if (VOLATILES.has(funcName)) {
        callback([ 'volatile', path ], stopSignal);
      }
      const getStaticReferences = visitStaticReferencesForFunction[funcName];

      if (getStaticReferences) {
        // XXX: not sure why `as ASTFunctionCallNode` is not inferred
        getStaticReferences(path, ast as ASTFunctionCallNode, opts, callback, stopSignal);
        return;
      }

      invariant(
        !lazyArgumentFunctions[funcName],
        'lazy argument functions should specify how to get their static references',
      );

      const argContext = LAMBDA_CALLING_FUNCTIONS.has(funcName)
        ? { ...opts, collectStaticReferencesFromLambdaDefinitions: true }
        : opts;
      for (const elem of ast.args) {
        visitAllStaticReferences(path, elem, argContext, callback, stopSignal);
        if (stopSignal.isSet) {
          return;
        }
      }
    }
    else {
      // Non-trivial lambda call, where the callee expression (which produces
      // the lambda to call) is something other than just a LAMBDA(...) or a
      // name pointing to one. It's not feasible to statically analyze exactly
      // which references are used conditionally and which unconditionally, so
      // give up and just collect all references found in each argument AST and
      // in the callee expression AST and mark them all as conditional.
      const recordAsConditional = (refWithPath: ReferenceWithASTPath, stop: Signal): void => {
        callback(markConditional(refWithPath), stop);
      };
      for (const arg of ast.args) {
        visitAllStaticReferences(path, arg, opts, recordAsConditional, stopSignal);
        if (stopSignal.isSet) {
          return;
        }
      }
      const includeLambdaDefsCtx = {
        ...opts,
        collectStaticReferencesFromLambdaDefinitions: true,
      };
      visitAllStaticReferences(path, ast.call, includeLambdaDefsCtx, recordAsConditional, stopSignal);
    }
  }
  else if ('param' in ast) {
    const argAST = opts.resolveLambdaArgumentAST?.(ast.param);
    if (argAST) {
      visitAllStaticReferences(path, argAST, opts, callback, stopSignal);
    }
  }
}

function visitStaticReferencesInLambdaDefinition (
  ast: ASTLambdaNode,
  callback: CallbackWithStop<ReferenceWithASTPath>,
  path: ASTPath,
  opts: StaticEvaluationContext,
  stopSignal: Signal,
) {
  const filterOutParamsCallback: CallbackWithStop<ReferenceWithASTPath> = (rp, stop) => {
    const ref = rp[0];
    if (isRef(ref) && ref.name && ast.params?.map(p => p.toUpperCase()).includes(ref.name.toUpperCase())) {
      return;
    }
    callback([ ref, null ], stop);
  };
  visitAllStaticReferences(path, ast.lambda, opts, filterOutParamsCallback, stopSignal);
}

function visitLetReferences (
  path: ASTPath,
  ast: ASTLetNode,
  opts: StaticEvaluationContext,
  stopSignal: Signal,
  callback: CallbackWithStop<ReferenceWithASTPath>,
) {
  const nameToArgAST = new Map<string, ASTNode>();
  for (const [ name, argAST ] of Object.entries(ast.let)) {
    nameToArgAST.set(name.toUpperCase(), argAST);
  }
  const argOverridingCallback: CallbackWithStop<ReferenceWithASTPath> = function argOverridingCallback (
    this: Map<string, ASTNode>,
    refWithPath,
    stop,
  ) {
    // Ignore the AST path to the parameter reference in the LET expression node
    // as we cannot optimize through that (and it may be null if the reference
    // should be ignored in graph optimization). The path to (and excluding) any
    // argument AST subtree is simply the path to (and including) the LET root.
    const [ ref ] = refWithPath;
    const refNameUpper = isRef(ref) && ref.name && !ref.workbookName && !ref.sheetName ? ref.name.toUpperCase() : null;
    if (refNameUpper && this.has(refNameUpper)) {
      const argAST = this.get(refNameUpper)!;
      const nameToEarlierArgAST = mapSubsetBeforeKey(this, refNameUpper);
      const earlierArgOverridingCallback = argOverridingCallback.bind(nameToEarlierArgAST);
      const maybeCondCallback: CallbackWithStop<ReferenceWithASTPath> = (ref as Reference).conditional
        ? (refInArg, stop) => earlierArgOverridingCallback(markConditional(refInArg), stop)
        : earlierArgOverridingCallback;
      const letPath = path.concat(ast) as ASTPath;
      visitAllStaticReferences(letPath, argAST, opts, maybeCondCallback, stopSignal);
    }
    else {
      callback(refWithPath, stop);
    }
  };
  visitAllStaticReferences(path, ast.expr, opts, argOverridingCallback.bind(nameToArgAST), stopSignal);
}

function mapSubsetBeforeKey (map: Map<string, ASTNode>, keyToStopAt: any) {
  const nameToEarlierArgAST = new Map();
  for (const [ argName, argAST ] of map) {
    const argNameUpper = argName.toUpperCase();
    if (argNameUpper === keyToStopAt) {
      break;
    }
    nameToEarlierArgAST.set(argNameUpper, argAST);
  }
  return nameToEarlierArgAST;
}

export function isNonLiteralNode (ast?: ASTNode): ast is ASTNonLiteralNode {
  return typeof ast === 'object' && ast != null && !('error' in ast);
}

export function isFunctionCallNode (ast: ASTNonLiteralNode): ast is ASTFunctionCallNode {
  return 'call' in ast && typeof ast.call === 'string';
}

export function doStaticReferenceOperation (
  ast: ASTReferenceOperationNode,
  opts: EvaluationContext & { evaluateStaticReferenceASTNode?: FnEvaluateStaticReferenceASTNode },
) {
  let result: Reference | MaybeBoxed<FormulaError>;
  if ('range' in ast) {
    result = doRangeOperation(ast.range, opts, opts.evaluateStaticReferenceASTNode);
  }
  else if ('intersection' in ast) {
    result = doIntersectionOperation(ast.intersection, opts, opts.evaluateStaticReferenceASTNode);
  }
  else {
    // union not yet supported, fall through to reporting each operand as a conditional static reference
    return null;
  }
  return !isRef(result) || result.dynamic ? null : result;
}

function getStaticReferencesFromReferenceOperationNode (
  /** path is up to and including `ast` */
  path: ASTPath,
  ast: ASTReferenceOperationNode,
  opts: StaticEvaluationContext,
): ReferenceWithASTPath[] {
  const resultingReference = doStaticReferenceOperation(ast, opts);
  if (isRef(resultingReference)) {
    invariant(!resultingReference.dynamic, 'fails for: ' + JSON.stringify(ast, null, 2));
    return [ [ resultingReference, path ] ];
  }
  else {
    // The above evaluation failed to produce a static reference. Happens if:
    //
    // * the operation is a range or intersection, and one operand (or both) is
    //   a reference function call, or a reference operation, or a name
    //   reference to a defined-name whose formula yields a dynamic reference
    // * the operation is a union, simply because that is not yet supported
    //
    // So we don't have a static _resulting_ reference to report. So we collect
    // static references from both operands, and additionally, if this is a
    // range operation, try to determine for each operand expression a range
    // encompassing _any possibly outcome_ of that operand expression, and add
    // a conditional reference to the bounding box of those ranges. If we cannot
    // derive a bound on the outcome, then give up and call this cell volatile.
    //
    // This reflects that:
    //
    // * we don't know for sure that all cells in these references _will_ be
    //   required to be up-to-date before this formula is evaluated, so
    //   enforcing that up-to-dateness may trigger a false-positive circular
    //   dependency error
    //
    // * but we do know that they _may_ be required to be up-to-date, so this
    //   formula should be dirtied when any of the conditionally-referenced
    //   cells are dirtied, and should be re-evaluated if any of them do change
    //
    // * and specifically in the range case, we may need to depend on cells not
    //   included in any of the individual static references, but contained in
    //   the bounding box spanning them. For example, consider the expression
    //   INDEX(A1:B2, 1, 1):INDEX(B2:C3, 2, 2) --- which of course simplifies to
    //   A1:C3 with those hardcoded row/column indices, and with other indices
    //   could resolve to a tighter subset --- but note that A3 and C1 are in
    //   neither of those static references but _are_ in the resulting range. We
    //   can be sure that each INDEX expression will return a reference within
    //   the bounding box of those two ranges, or else will return an error. So
    //   a conditional reference to that bounding box is sufficient to ensure
    //   recalculation propagation.
    //
    //   If we _cannot_ determine that outer bound on the resulting reference
    //   from either operand, then we must give up and call the cell volatile,
    //   so that we can guarantee that it gets recalculated when it should.
    const operands: ASTReferenceNode[] =
      // @ts-expect-error (type checker is too persnickety, doesn't like this form of fallback attribute checking)
      ast.range ?? ast.intersection ?? ast.union;
    const operandRefs: ReferenceWithASTPath[] = [];
    operands.forEach(astNode => {
      visitAllStaticReferences(path, astNode, opts, ref => {
        operandRefs.push(ref);
      });
    });
    if (operandRefs.length >= 2 && 'range' in ast) {
      const combinedBound = determineRangeReferenceBound(operands, opts);
      if (combinedBound) {
        operandRefs.push([ combinedBound, path ]);
      }
      else {
        operandRefs.push([ 'volatile', path ]);
      }
    }
    return operandRefs.map(markConditional);
  }
}

function markConditional<T> ([ ref, astPath ]: [T, ASTPath | null]): [T, ASTPath | null] {
  if (isRef(ref)) {
    return [ new Reference(ref, { conditional: true }) as T, astPath ];
  }
  return [ ref, astPath ];
}

function determineRangeReferenceBound (operands: ASTReferenceNode[], opts: StaticEvaluationContext) {
  const outerBoundRefs = operands.map(node => determineResultReferenceBound(node, opts.evaluateStaticReferenceASTNode));
  if (outerBoundRefs.every(isRef) && allInSameSheet(outerBoundRefs, opts)) {
    const nonNameRefs = outerBoundRefs.map(r => r.resolveToNonName(opts));
    if (nonNameRefs.every(isRef)) {
      const [ first, ...remaining ] = nonNameRefs as A1Reference[];
      let boundsCombined = first;
      for (const operandRef of remaining) {
        boundsCombined = boundingBox(boundsCombined, operandRef);
      }
      return boundsCombined;
    }
  }
}

function determineResultReferenceBound (
  astRefNode: ASTReferenceNode,
  evaluateStaticReferenceASTNode: FnEvaluateStaticReferenceASTNode,
) {
  const staticRef = evaluateStaticReferenceASTNode(astRefNode);
  if (staticRef) {
    return staticRef;
  }
  if (!('call' in astRefNode && isStr(astRefNode.call))) {
    return;
  }
  const funcName = astRefNode.call.toUpperCase();
  if (funcName === 'INDEX') {
    return evaluateStaticReferenceASTNode(astRefNode.args[0]);
  }
  if (funcName === 'XLOOKUP') {
    return evaluateStaticReferenceASTNode(astRefNode.args[2]);
  }
}

function allInSameSheet (outerBoundRefs: Reference[], opts: EvaluationContext) {
  return new Set(outerBoundRefs.map(r => opts.resolveSheet(r.sheetName, r.workbookName))).size === 1;
}

function visitStaticReferencesInIfCall (
  path: ASTPath,
  ast: ASTFunctionCallNode,
  opts: StaticEvaluationContext,
  callback: CallbackWithStop<ReferenceWithASTPath>,
  stopSignal: Signal,
) {
  visitAllStaticReferences(path, ast.args[0], opts, callback, stopSignal);
  if (!stopSignal.isSet) {
    for (const arg of ast.args.slice(1)) {
      visitAllStaticReferences(
        path,
        arg,
        opts,
        (ref, stop) => {
          callback(markConditional(ref), stop);
        },
        stopSignal,
      );
      if (stopSignal.isSet) {
        return;
      }
    }
  }
}

function visitStaticReferencesInIfsCall (
  path: ASTPath,
  ast: ASTFunctionCallNode,
  opts: StaticEvaluationContext,
  callback: CallbackWithStop<ReferenceWithASTPath>,
  stopSignal: Signal,
) {
  for (let i = 0; i < ast.args.length; i++) {
    const arg = ast.args[i];
    visitAllStaticReferences(
      path,
      arg,
      opts,
      (ref, stop) => {
        if (i % 2 !== 0) {
          ref = markConditional(ref);
        }
        callback(ref, stop);
      },
      stopSignal,
    );
    if (stopSignal.isSet) {
      return;
    }
  }
}

function visitStaticReferencesInIfNaOrIfErrorCall (
  path: ASTPath,
  ast: ASTFunctionCallNode,
  opts: StaticEvaluationContext,
  callback: CallbackWithStop<ReferenceWithASTPath>,
  stopSignal: Signal,
) {
  const [ eagerArg, lazyArg ] = ast.args;
  visitAllStaticReferences(path, eagerArg, opts, callback, stopSignal);
  if (lazyArg && !stopSignal.isSet) {
    visitAllStaticReferences(
      path,
      lazyArg,
      opts,
      (ref, stop) => {
        callback(markConditional(ref), stop);
      },
      stopSignal,
    );
  }
}

function visitStaticReferencesInOffsetCall (
  path: ASTPath,
  ast: ASTFunctionCallNode,
  opts: StaticEvaluationContext,
  callback: CallbackWithStop<ReferenceWithASTPath>,
  stopSignal: Signal,
) {
  // reference node as first argument of OFFSET is not a static reference
  visitAllStaticReferencesSpecialCasingNth(
    path,
    ast.args,
    0,
    opts,
    function (argNode: ASTNode, cb, stop) {
      if (!isReferenceNode(argNode)) {
        // not _directly_ a reference node, so its subtree may contain relevant static references
        visitAllStaticReferences(path, argNode, opts, cb, stop);
      }
      // _directly_ a reference node, so no static references
    },
    callback,
    stopSignal,
  );
}

function visitStaticReferencesInHlookupVlookupCall (
  path: ASTPath,
  ast: ASTFunctionCallNode,
  opts: StaticEvaluationContext,
  callback: CallbackWithStop<ReferenceWithASTPath>,
  stopSignal: Signal,
) {
  // A table argument of these functions that is a static reference requires some subtle special-case handling:
  // the top row (HLOOKUP) or leftmost column (VLOOKUP) is treated as a normal static reference, and the remainder
  // (if any) is treated as a static reference that is skipped when enqueueing dependencies of a cell being
  // enqueued. This is because from that remainder reference, only the lookup result cell is _really_ depended on,
  // and any others _must not_ be enqueued as dependencies of the cell containing the HLOOKUP/VLOOKUP call because
  // they could lead to a false-positive circular-dependency error cutting the recalculation short, leading to
  // incorrect results. But the static dependency on the remainder reference must still exist, because an update
  // of the lookup result cell must lead to an update of the cell containing the HLOOKUP/VLOOKUP call, and so:
  //
  // (a) marking any cell in the table dirty must cause the HLOOKUP/VLOOKUP cell to be marked dirty
  // (b) updating the cell that would be the lookup result cell must cause recalculation of the HLOOKUP/VLOOKUP
  const funcNameUpper = ast.call.toUpperCase();
  const index = ast.args[2];
  const handleRangeArg = makeHlookupVlookupRangeArgHandler(path, funcNameUpper, opts, index);
  visitAllStaticReferencesSpecialCasingNth(path, ast.args, 1, opts, handleRangeArg, callback, stopSignal);
}

/**
 * Make a special-case argument node handler for the second argument of
 * HLOOKUP/VLOOKUP calls. This is an extraction of a closure arrow function to
 * cut down on the cognitive complexity of `getAllStaticReferences`.
 * @param funcNameUpper either 'HLOOKUP' or 'VLOOKUP'
 * @param opts the evaluation context
 * @param evaluateStaticReferenceASTNode function mapping a node to a Reference,
 *   bound to an evaluation context with the context sheetName of the formula
 *   containing this AST (null in the case of defined-name formulas)
 * @returns argument node handler to pass to `getAllStaticReferencesSpecialCasingNth`
 */
function makeHlookupVlookupRangeArgHandler (
  path: ASTPath,
  funcNameUpper: string,
  opts: StaticEvaluationContext,
  index: ASTNode,
) {
  const constantIndexArg = isLiteralNode(index) && typeof index !== 'object' ? toNum(index) : null;
  function handleHVLookupRangeArg (
    argNode: ASTNode,
    callback: CallbackWithStop<ReferenceWithASTPath>,
    stopSignal: Signal,
  ) {
    if (isReferenceNode(argNode)) {
      let constIndex0based: number | undefined;
      if (typeof constantIndexArg === 'number') {
        // Constant index arg! We know the result cell can only be in a specific row/column
        constIndex0based = Math.floor(constantIndexArg) - 1;
        if (constIndex0based < 0) {
          // Out of bounds; result is always an error. No dependencies.
          return;
        }
      }
      // second arg is directly a reference node, so evaluate it to a range and take the top row of that range
      const ref = opts.evaluateStaticReferenceASTNode(argNode);
      if (ref == null) {
        // couldn't evaluate directly as a static reference, so yield any contained static references
        visitAllStaticReferences(path, argNode, opts, callback, stopSignal);
      }
      else {
        invariant(!ref.dynamic);
        const addressRef = ref.resolveToNonName(opts);
        const argNodePath = path.concat(argNode) as ASTPath;
        if (!isRef(addressRef) || !addressRef.range || addressRef.dynamic) {
          // can't be resolved to a static non-name reference, so yield the immediate reference, marked conditional
          callback(markConditional([ ref, argNodePath ]), stopSignal);
        }
        else {
          const fullRange = addressRef.range;
          const isHorizontal = funcNameUpper === 'HLOOKUP';
          const fullRangeLength = isHorizontal ? fullRange.height : fullRange.width;
          if (constIndex0based != null && constIndex0based >= fullRangeLength) {
            // Out of bounds; result is always an error. No dependencies.
            return;
          }
          const lookupRef = isHorizontal ? addressRef.collapseToRow() : addressRef.collapseToColumn();
          let resultRange: Reference | null | undefined;
          if (fullRangeLength > 1) {
            if (constIndex0based != null) {
              // We know the result cell can only be in the specified row/column
              // and that this row/column is within bounds (checked above), so
              // record a conditional dependency on just that row/column.
              resultRange = isHorizontal
                ? addressRef.collapseToRow(constIndex0based)
                : addressRef.collapseToColumn(constIndex0based);
            }
            else {
              // Index arg is not constant so have to depend conditionally on
              // the whole rest of `fullRange` (omitting first row/column which
              // we already depend on unconditionally).
              resultRange = new Reference(
                new Range({
                  ...fullRange,
                  ...(isHorizontal ? { top: fullRange.top + 1 } : { left: fullRange.left + 1 }),
                }),
                addressRef,
              );
            }
          }
          callback([ lookupRef, argNodePath ], stopSignal);
          if (!stopSignal.isSet && resultRange) {
            callback(markConditional([ resultRange, null ]), stopSignal);
          }
        }
      }
    }
    else {
      // second arg is not _directly_ a reference node, so its subtree may contain relevant static references
      visitAllStaticReferences(path, argNode, opts, callback, stopSignal);
    }
  }
  return handleHVLookupRangeArg;
}

function visitStaticReferencesInLookupCall (
  path: ASTPath,
  ast: ASTFunctionCallNode,
  opts: StaticEvaluationContext,
  callback: CallbackWithStop<ReferenceWithASTPath>,
  stopSignal: Signal,
) {
  visitAllStaticReferences(path, ast.args[0], opts, callback, stopSignal);
  if (stopSignal.isSet) {
    return;
  }
  let lookupVectorRef = opts.evaluateStaticReferenceASTNode(ast.args[1]);
  let resultVectorRef = opts.evaluateStaticReferenceASTNode(ast.args[2]);
  if (lookupVectorRef != null && resultVectorRef != null && !lookupVectorRef.dynamic && !resultVectorRef.dynamic) {
    // both second arg and third arg are static references, so extend third if shorter than second
    const lookupVectorAddressRef = lookupVectorRef.resolveToNonName(opts);
    const resultVectorAddressRef = resultVectorRef.resolveToNonName(opts);
    if (
      isRef(lookupVectorAddressRef) &&
      lookupVectorAddressRef.range != null &&
      isRef(resultVectorAddressRef) &&
      resultVectorAddressRef?.range != null &&
      !lookupVectorAddressRef.dynamic &&
      !resultVectorAddressRef.dynamic
    ) {
      // both resolved to a static range reference; find lengths and extend resultVector if needed
      lookupVectorRef = lookupVectorAddressRef;
      const lookupVectorLength = Math.max(lookupVectorAddressRef.width, lookupVectorAddressRef.height);
      const resultVectorLength = Math.max(resultVectorAddressRef.width, resultVectorAddressRef.height);
      resultVectorRef =
        lookupVectorLength > resultVectorLength
          ? resultVectorAddressRef.withRange(resultVectorAddressRef.range.extendToLength(lookupVectorLength))
          : resultVectorAddressRef;
    }
  }
  if (lookupVectorRef != null) {
    invariant(!lookupVectorRef.dynamic);
    const lookupVectorAddressRef = lookupVectorRef.resolveToNonName(opts);
    if (isRef(lookupVectorAddressRef)) {
      lookupVectorRef = lookupVectorAddressRef;
    }
    const isHorizontal = lookupVectorRef.width > lookupVectorRef.height;
    if (ast.args[2] == null) {
      resultVectorRef = isHorizontal
        ? lookupVectorRef.collapseToRow(lookupVectorRef.height - 1)
        : lookupVectorRef.collapseToColumn(lookupVectorRef.width - 1);
    }
    lookupVectorRef = isHorizontal ? lookupVectorRef.collapseToRow() : lookupVectorRef.collapseToColumn();
    callback([ lookupVectorRef, path ], stopSignal);
  }
  else {
    visitAllStaticReferences(path, ast.args[1], opts, callback, stopSignal);
  }
  if (!stopSignal.isSet) {
    if (resultVectorRef != null) {
      callback(markConditional([ resultVectorRef, null ]), stopSignal);
    }
    else if (ast.args[2] != null) {
      visitAllStaticReferences(
        path,
        ast.args[2],
        opts,
        (ref, stop) => callback(markConditional(ref), stop),
        stopSignal,
      );
    }
  }
}

function visitStaticReferencesInConditionalDependencyArgument (
  path: ASTPath,
  argNode: ASTNode,
  opts: StaticEvaluationContext,
  callback: CallbackWithStop<ReferenceWithASTPath>,
  stopSignal: Signal,
) {
  const reference = opts.evaluateStaticReferenceASTNode(argNode);
  if (reference != null) {
    callback(markConditional([ reference, null ]), stopSignal);
  }
  else {
    visitAllStaticReferences(path, argNode, opts, callback, stopSignal);
  }
}

function visitStaticReferencesInXLookupCall (
  path: ASTPath,
  ast: ASTFunctionCallNode,
  opts: StaticEvaluationContext,
  callback: CallbackWithStop<ReferenceWithASTPath>,
  stopSignal: Signal,
) {
  const specialHandler = (argNode: ASTNode, cb: CallbackWithStop<ReferenceWithASTPath>, stop: Signal) => {
    visitStaticReferencesInConditionalDependencyArgument(path, argNode, opts, cb, stop);
  };
  visitAllStaticReferencesSpecialCasingNth(path, ast.args, 2, opts, specialHandler, callback, stopSignal);
}

function visitStaticReferencesInIndexCall (
  path: ASTPath,
  ast: ASTFunctionCallNode,
  opts: StaticEvaluationContext,
  callback: CallbackWithStop<ReferenceWithASTPath>,
  stopSignal: Signal,
) {
  const specialHandler = (argNode: ASTNode, cb: CallbackWithStop<ReferenceWithASTPath>, stop: Signal) => {
    visitStaticReferencesInConditionalDependencyArgument(path, argNode, opts, cb, stop);
  };
  visitAllStaticReferencesSpecialCasingNth(path, ast.args, 0, opts, specialHandler, callback, stopSignal);
}

function visitStaticReferencesInRowsOrColumnsCall (
  path: ASTPath,
  ast: ASTFunctionCallNode,
  opts: StaticEvaluationContext,
  callback: CallbackWithStop<ReferenceWithASTPath>,
  stopSignal: Signal,
) {
  const node = ast.args[0];
  if (!isReferenceNode(node)) {
    // not _directly_ a reference node, so its subtree may contain relevant static references
    visitAllStaticReferences(path, node, opts, callback, stopSignal);
  }
}

function visitStaticReferencesInAggregateIfCall (
  path: ASTPath,
  ast: ASTFunctionCallNode,
  opts: StaticEvaluationContext,
  callback: CallbackWithStop<ReferenceWithASTPath>,
  stopSignal: Signal,
) {
  const criteriaRef = opts.evaluateStaticReferenceASTNode(ast.args[0]);
  let targetRef = opts.evaluateStaticReferenceASTNode(ast.args[2]);
  if (criteriaRef != null && targetRef != null) {
    invariant(!criteriaRef.dynamic && !targetRef.dynamic);
    const criteriaAddressRef = criteriaRef.resolveToNonName(opts);
    const targetAddressRef = targetRef.resolveToNonName(opts);
    if (
      isRef(criteriaAddressRef) &&
      !criteriaAddressRef.dynamic &&
      isRef(targetAddressRef) &&
      !targetAddressRef.dynamic
    ) {
      // both resolved to a static range reference; extend target range to match criteria range
      const criteriaRange = criteriaAddressRef.range;
      const targetRange = targetAddressRef.range;
      if (targetRange.width !== criteriaRange.width || targetRange.height !== criteriaRange.height) {
        targetRef = targetAddressRef.offset(0, 0, criteriaRange.height, criteriaRange.width);
      }
    }
  }
  if (criteriaRef != null) {
    callback([ criteriaRef, [ ...path, ast.args[0] as ASTNonLiteralNode ] ], stopSignal);
  }
  else {
    visitAllStaticReferences(path, ast.args[0], opts, callback, stopSignal);
  }
  if (stopSignal.isSet) {
    return;
  }
  visitAllStaticReferences(path, ast.args[1], opts, callback, stopSignal);
  if (stopSignal.isSet) {
    return;
  }
  if (targetRef != null) {
    callback([ targetRef, [ ...path, ast.args[2] as ASTNonLiteralNode ] ], stopSignal);
  }
  else {
    visitAllStaticReferences(path, ast.args[2], opts, callback, stopSignal);
  }
}

function visitStaticReferencesInCellCall (
  path: ASTPath,
  ast: ASTFunctionCallNode,
  opts: StaticEvaluationContext,
  callback: CallbackWithStop<ReferenceWithASTPath>,
  stopSignal: Signal,
) {
  const [ infoTypeArg, refArg ] = ast.args;
  visitAllStaticReferences(path, infoTypeArg, opts, callback, stopSignal);
  if (stopSignal.isSet) {
    return;
  }
  // may or may not need value of anchor cell of reference argument
  const knownUnconditional = isStr(infoTypeArg) && [ 'gridsupport', 'contents' ].includes(infoTypeArg.toLowerCase());
  let staticRef = opts.evaluateStaticReferenceASTNode(refArg);
  if (staticRef && staticRef.range) {
    staticRef = staticRef.collapse();
    const refWithPath: ReferenceWithASTPath = [ staticRef, path ];
    const maybeCondRefWithPath = knownUnconditional ? refWithPath : markConditional(refWithPath);
    callback(maybeCondRefWithPath, stopSignal);
  }
  else {
    visitAllStaticReferences(
      path,
      refArg,
      opts,
      (ref, stop) => {
        callback(knownUnconditional ? ref : markConditional(ref), stop);
      },
      stopSignal,
    );
  }
}

function visitStaticReferencesInNonValueReferenceFunctionCall (
  path: ASTPath,
  ast: ASTFunctionCallNode,
  opts: StaticEvaluationContext,
  callback: CallbackWithStop<ReferenceWithASTPath>,
  stopSignal: Signal,
) {
  for (const arg of ast.args) {
    const staticRef = opts.evaluateStaticReferenceASTNode(arg);
    if (staticRef) {
      const nonValueRef = new Reference(staticRef, { nonValue: true });
      callback([ nonValueRef, path ], stopSignal);
    }
    else {
      visitAllStaticReferences(
        path,
        arg,
        opts,
        (ref, stop) => {
          callback(markConditional(ref), stop);
        },
        stopSignal,
      );
    }
  }
}

function visitAllStaticReferencesSpecialCasingNth (
  path: ASTPath,
  args: ASTNode[],
  n: number,
  opts: StaticEvaluationContext,
  handleSpecialCaseArgNode: (
    argNode: ASTNode,
    callback: CallbackWithStop<ReferenceWithASTPath>,
    stopSignal: Signal
  ) => void,
  callback: CallbackWithStop<ReferenceWithASTPath>,
  stopSignal: Signal,
) {
  for (let i = 0; i < args.length; i++) {
    const arg = args[i];
    if (i === n) {
      handleSpecialCaseArgNode(arg, callback, stopSignal);
    }
    else {
      visitAllStaticReferences(path, arg, opts, callback, stopSignal);
    }
    if (stopSignal.isSet) {
      return;
    }
  }
}

function isReferenceNode (ast: ASTNode): ast is ASTReferenceNode {
  return (
    typeof ast === 'object' &&
    ast != null &&
    ('cell' in ast ||
      'name' in ast ||
      'colrange' in ast ||
      'rowrange' in ast ||
      'prefixed' in ast ||
      'range' in ast ||
      'intersection' in ast ||
      'structured' in ast ||
      'union' in ast)
  );
}

export function isStructuredReferenceNode (ast: ASTNode): ast is ASTStructuredReferenceNode {
  return typeof ast === 'object' && ast != null && 'structured' in ast;
}

export function containsLambda (ast: ASTNode) {
  let result = false;
  visitAllNonLiteralNodes(ast, (node, stop) => {
    if ('lambda' in node) {
      result = true;
      stop.set();
    }
  });
  return result;
}

export function reconstructFormula (
  ast: ASTNode,
  sheetName: string,
  memo: Map<ASTNonLiteralNode, string>,
  inPrefix = false,
): string {
  if (isLiteralNode(ast)) {
    return typeof ast === 'object' && ast ? ast.error : formatCellValueForFormula(ast);
  }
  const memoized = memo.get(ast);
  if (memoized) {
    return memoized;
  }
  let result: string;
  if ('call' in ast) {
    const callee = typeof ast.call === 'string' ? ast.call : reconstructFormula(ast.call, sheetName, memo);
    const argumentList = ast.args.map(arg => reconstructFormula(arg, sheetName, memo)).join(',');
    result = `${callee}(${argumentList})`;
  }
  else if ('name' in ast) {
    result = ast.name;
  }
  else if ('prefixed' in ast) {
    const prefixStr = prefix({ sheetName: ast.sheet, workbookName: ast.workbook });
    const prefixed = ast.prefixed;
    result = prefixStr + reconstructFormula(prefixed, sheetName, memo, true);
  }
  else if ('binop' in ast) {
    result = reconstructBinOp(ast.args, sheetName, ast.binop, memo, inPrefix);
  }
  else if ('unop' in ast) {
    result = `${ast.unop}${reconstructFormula(ast.expr, sheetName, memo)}`;
  }
  else if ('cell' in ast) {
    result = inPrefix ? ast.cell : prefix({ sheetName }) + ast.cell;
  }
  else if ('range' in ast) {
    result = reconstructBinOp(ast.range, sheetName, ':', memo, inPrefix);
  }
  else if ('colrange' in ast) {
    result = (inPrefix ? '' : prefix({ sheetName })) + `${ast.colrange[0]}:${ast.colrange[1]}`;
  }
  else if ('rowrange' in ast) {
    result = (inPrefix ? '' : prefix({ sheetName })) + `${ast.rowrange[0]}:${ast.rowrange[1]}`;
  }
  else if ('structured' in ast) {
    const prefixStr = prefix({ sheetName: ast.sheet, workbookName: ast.workbook });
    let sref = '';
    const structured = ast.structured;
    if (structured != null) {
      const kws = structured.keywords || [];
      const cols = 'column' in structured ? [ structured.column ] : structured.column_range || [];
      const colStr = cols.map(c => `[${c}]`).join(':');
      sref = `[${[ ...kws, colStr ].join(',')}]`;
    }
    result = prefixStr + (ast.table || '') + sref;
  }
  else if ('intersection' in ast) {
    result = reconstructBinOp(ast.intersection, sheetName, ' ', memo, inPrefix);
  }
  else if ('union' in ast) {
    result = reconstructBinOp(ast.union, sheetName, ',', memo, inPrefix);
  }
  else if ('array' in ast) {
    const rows = ast.array.map(row => row.map(element => reconstructFormula(element, sheetName, memo)).join(','));
    result = `{${rows.join(';')}}`;
  }
  else if ('lambda' in ast) {
    const paramList = ast.params?.map(param => param).join(',') || '';
    const expr = reconstructFormula(ast.lambda, sheetName, memo);
    result = `LAMBDA(${paramList},${expr})`;
  }
  else if ('let' in ast) {
    const letBindings = Object.entries(ast.let).map(
      ([ name, expr ]) => `${name},${reconstructFormula(expr, sheetName, memo)}`,
    );
    result = `LET(${letBindings.join(',')},${reconstructFormula(ast.expr, sheetName, memo)})`;
  }
  else {
    throw new AssertionError('Unhandled AST node type: ' + Object.keys(ast).join(', '));
  }
  memo.set(ast, result);
  return result;
}

const operatorPrecedence = {
  '^': 5,
  '*': 4,
  '/': 4,
  '-': 3,
  '+': 3,
  '&': 2,
  // others have precedence 1
};

function reconstructBinOp (
  operands: ASTNode[],
  sheetName: string,
  op: string,
  memo: Map<ASTNonLiteralNode, string>,
  inPrefix: boolean,
) {
  const opPrecedence = operatorPrecedence[op] || 1;
  return operands
    .map(operand => {
      const operandExpression = reconstructFormula(operand, sheetName, memo, inPrefix);
      if (op === ':' || op === ' ' || op === ',') {
        // Range operation. Set inPrefix true so the second operand is
        // considered to be in a prefix ... this is because the above
        // reconsituteFormula call will add a prefix around the first operand,
        // and that is how we denote prefixes on ranges (and intersections and
        // unions), by just prefixing the first operand within the reference
        // operation node.
        inPrefix = true;
      }
      if (isNonLiteralNode(operand) && 'binop' in operand && (operatorPrecedence[operand.binop] || 1) < opPrecedence) {
        return `(${operandExpression})`;
      }
      return operandExpression;
    })
    .join(op);
}

export function astIsGoogleArrayFunctionCall (ast: ASTNode): boolean {
  if (typeof ast === 'object' && !!ast && 'call' in ast && typeof ast.call === 'string') {
    if (ast.call === 'ARRAYFORMULA') {
      return true;
    }
    const sigList = funcSigs[ast.call.toUpperCase()] || [];
    for (const sig of sigList) {
      if ((sig[SIG_MODE] & MODE_GOOGLE) !== 0) {
        return (sig[SIG_FLAGS] & FLAG_IS_GOOGLE_ARRAY_FUNCTION) !== 0;
      }
    }
  }
  return false;
}

const AGGR_FUNCTIONS = new Set([
  'SUM',
  'PRODUCT',
  'AVERAGE',
  'AVERAGEA',
  'MIN',
  'MINA',
  'MAX',
  'MAXA',
  'LARGE',
  'SMALL',
  'MEDIAN',
  'GEOMEAN',
  'HARMEAN',
  'TRIMMEAN',
  'STDEV',
  'STDEVA',
  'STDEVP',
  'STDEVPA',
  'AVEDEV',
  'SUMSQ',
  'VAR',
  'VARA',
  'VARP',
  'VARPA',
  'NPV',
  'KURT',
  'SKEW',
  'QUARTILE',
  'PERCENTILE',
  'PERCENTRANK',
]);

export function isAggrCallWithArrayOpsInside (ast: ASTFunctionCallNode): boolean {
  return AGGR_FUNCTIONS.has(ast.call.toUpperCase()) && ast.args.some(hasArrayOperation);
}

function hasArrayOperation (ast: ASTNode): boolean {
  let result = false;
  visitAllNonLiteralNodes(ast, (node, stop) => {
    if (isArrayOperation(node)) {
      result = true;
      stop.set();
    }
  });
  return result;
}

function isArrayOperation (ast: ASTNonLiteralNode): boolean {
  let args: ASTNode[];
  if ('args' in ast) {
    args = ast.args;
  }
  else if ('unop' in ast) {
    args = [ ast.expr ];
  }
  else {
    return false;
  }
  return args.some(
    arg =>
      !isLiteralNode(arg) &&
      ('array' in arg ||
        'range' in arg ||
        'colrange' in arg ||
        'rowrange' in arg ||
        (isFunctionCallNode(arg) && AGGR_FUNCTIONS.has(arg.call.toUpperCase()))),
  );
}
export function isContextDependentCall (funcName: string, args: ASTNode[]) {
  return (
    funcName === 'INDIRECT' ||
    funcName === 'SINGLE' ||
    ((funcName === 'ROW' || funcName === 'COLUMN') && args.length === 0)
  );
}
