import { MAX_COL, MAX_ROW, ERROR_NA, ERROR_VALUE, ERROR_REF } from './constants';
import Cell from './Cell';
import { invariant } from '../validation';
import { maybeBoxedValuesEqual, unbox, type MaybeBoxed } from './ValueBox.js';
import { ResolveAreaOptions, type ReturnOptions } from '../ResolveAreaOptions';
import { cropRange } from './cropRange';
import type { ArrayValue, IterationOptions } from './types';
import type {
  AreaArray,
  AreaArrayAttributes,
  AreaArrayElement,
  AreaBoxedValueArray,
  AreaCellArray,
  AreaValueArray,
} from '../AreaArray';
import type FormulaError from './FormulaError';

type RequireWidthForDefaultColumn<T> = T extends { defaultColumn: MaybeBoxed<ArrayValue>[] } ? { width: number } : {};

type RequireHeightForDefaultRow<T> = T extends { defaultRow: MaybeBoxed<ArrayValue>[] } ? { height: number } : {};

type RequireDimensionForDefaultValue<T> = T extends { defaultValue: MaybeBoxed<ArrayValue> }
  ? { height: number } | { width: number }
  : {};

type RequireDimensionsForDefaultQuadrants<T> = RequireWidthForDefaultColumn<T> &
  RequireHeightForDefaultRow<T> &
  RequireDimensionForDefaultValue<T>;

type MatrixPopulatedData =
  | { data: MaybeBoxed<ArrayValue>[][] }
  | { column: MaybeBoxed<ArrayValue>[] }
  | { row: MaybeBoxed<ArrayValue>[] };

type MatrixNewData = MatrixPopulatedData & {
  defaultColumn?: MaybeBoxed<ArrayValue>[],
  defaultRow?: MaybeBoxed<ArrayValue>[],
  defaultValue?: MaybeBoxed<ArrayValue>,
  width?: number,
  height?: number,
};

/**
 * Representation of a rectangle of (maybe boxed) cell values.
 * For the purposes of compact representation of a common redundancy structure,
 * the rectangle should be thought of as consisting of four quadrants whose
 * contents are specified as follows:
 * - the top-left quadrant is arbitrary data, specified with a dense array of
 *   dense arrays of `MaybeBoxed<ArrayValue>`, each array representing one row of
 *   that quadrant, all arrays having the same length.
 * - the bottom-right quadrant has the same value in all its elements
 * - the top-right quadrant either has the same value as the bottom-right
 *   quadrant in all its elements (if `_defaultColumn` is empty), or else
 *   consists of constant-value rows, i.e. each row has a single value repeating
 *   in all its elements (but this value can differ between rows)
 * - the bottom-left quadrant likewise either has the same value as the
 *   bottom-right quadrant in all its elements (if `_defaultRow` is empty), or
 *   else consists of constant-value columns, i.e. each column has a single
 *   value repeating in all its elements (but this value can differ between
 *   columns)
 *
 * The `populatedHeight` and `populatedWidth` properties specify the dimensions
 * of the top left quadrant, and `width` and `height` then specify the remaining
 * quadrants, so if `populatedHeight === height`, then the bottom quadrants
 * are empty, and if `populatedWidth === width`, then the rightmost quadrants
 * are empty.
 */
class Matrix {
  private _height: number;
  private _width: number;
  /**
   * Values of the elements in the top-left quadrant.
   * Invariant: all rows are the same length.
   */
  _data: MaybeBoxed<ArrayValue>[][];
  /**
   * Values that apply in the rows of the top-right quadrant. This is either:
   * - the same length as `_data`, in which case each value in this array
   *   specifies the value of every element along the corresponding row of
   *   the top-right quadrant
   * - or empty, in which case `_defaultValue` specifies the value of every
   *   element in _all_ rows of the top-right quadrant.
   */
  _defaultColumn: MaybeBoxed<ArrayValue>[];
  /**
   * Values that apply in the columns of the bottom-left quadrant. This is
   * either:
   * - the same length as each row of `_data`, in which case each value in
   *   this array specifies the value of every element along the corresponding
   *   column of the bottom-left quadrant
   * - or empty, in which case `_defaultValue` specifies the value of every
   *   element in _all_ columns of the bottom-left quadrant.
   */
  _defaultRow: MaybeBoxed<ArrayValue>[];
  /**
   * The value of:
   * - every element of the bottom-right quadrant
   * - every element of the top-right quadrant, if `_defaultColumn` is empty
   * - every element of the bottom-left quadrant, if `_defaultRow` is empty
   */
  _defaultValue: MaybeBoxed<ArrayValue>;
  /**
   * Shrink the _data property (populated top-left quadrant) if possible, by
   * pruning away bottommost rows and rightmost columns in which all elements
   * are exactly equal to _defaultValue. If the matrix starts out fully
   * populated (i.e. has only a top-left quadrant, no defaulted quadrants),
   * then first check whether the bottom row (if tall) or rightmost column (if
   * wide) is all the same value, and if so, set _defaultValue to that.
   */
  shrink () {
    const { height, populatedHeight, width, populatedWidth, _data } = this;
    if (height === 1 && width === 1) {
      return;
    }
    if (populatedHeight === height && populatedWidth === width) {
      // we are free to choose any defaultValue
      let differentFound = false;
      if (height >= width) {
        const bottomRow = _data[height - 1];
        const bottomLeft = bottomRow[0];
        for (let x = 1; x < bottomRow.length; x++) {
          if (bottomRow[x] !== bottomLeft) {
            differentFound = true;
            break;
          }
        }
        if (!differentFound && _data[height - 2].every(v => v === bottomLeft)) {
          this._defaultValue = bottomLeft;
          _data.pop();
        }
      }
      else {
        const topRight = _data[0][width - 1];
        for (let y = 1; y < height; y++) {
          if (_data[y][width - 1] !== topRight) {
            differentFound = true;
            break;
          }
        }
        if (!differentFound && _data.every(row => row[width - 2] === topRight)) {
          this._defaultValue = topRight;
          for (const row of _data) {
            row.pop();
          }
        }
      }
    }
    const _defaultValue = this._defaultValue;
    for (let y = this.populatedHeight - 1; y > 0; y--) {
      if (_data[y].every(v => v === _defaultValue)) {
        _data.pop();
      }
      else {
        break;
      }
    }
    for (let x = this.populatedWidth - 1; x > 0; x--) {
      if (_data.every(row => row[x] === _defaultValue)) {
        for (const row of _data) {
          row.pop();
        }
      }
      else {
        break;
      }
    }
  }

  /**
   * Construct a new `Matrix` with a given width and height and default value,
   * and `populatedHeight` and `populatedWidth` of 0.
   * The default value applies at any coordinates to the right of, and/or
   * downwards of, the largest X and Y coordinates for which a value has been
   * set, with `.set` or `.setData`. Within the range (0,0) to
   * (populatedWidth, populatedHeight), any coordinates at which a value has
   * not been set is blank (the value is `null`).
   */
  constructor (width: number = 0, height: number = 0, defaultValue: MaybeBoxed<ArrayValue> = null) {
    this._height = height;
    this._width = width;
    this._data = [];
    this._defaultColumn = [];
    this._defaultRow = [];
    this._defaultValue = defaultValue;
  }

  get height () {
    return this._height;
  }

  set height (newHeight) {
    if (newHeight < this._data.length) {
      this._data.length = newHeight;
      if (this._defaultColumn.length > newHeight) {
        this._defaultColumn.length = newHeight;
      }
    }
    this._height = newHeight;
  }

  get width () {
    return this._width;
  }

  set width (newWidth) {
    if (newWidth < this._width) {
      for (const row of this._data) {
        if (row.length > newWidth) {
          row.length = newWidth;
        }
      }
      if (this._defaultRow.length > newWidth) {
        this._defaultRow.length = newWidth;
      }
    }
    this._width = newWidth;
  }

  get populatedHeight () {
    return this._data.length;
  }

  get populatedWidth () {
    return this._data[0] != null ? this._data[0].length : 0;
  }

  static of (value: MaybeBoxed<ArrayValue> | ((MaybeBoxed<ArrayValue> | undefined)[] | undefined | null)[]): Matrix {
    let arr;
    if (Array.isArray(value)) {
      const height = value.length;
      arr = new Matrix(0, height);
      let maxRowLength = 0;
      for (let y = 0; y < height; y++) {
        const row = value[y];
        if (row) {
          if (!Array.isArray(row)) {
            throw new Error(`internal error: Matrix.of argument row is of type ${typeof row}, should be an array`);
          }
          if (row.length > maxRowLength) {
            maxRowLength = row.length;
          }
          for (let x = 0; x < row.length; x++) {
            arr.set(x, y, row[x] ?? null);
          }
        }
      }
      if (isAreaValueArray(value)) {
        arr._defaultValue = value.defaultValue;
        arr._defaultColumn = value.defaultColumn?.concat() || [];
        arr._defaultRow = value.defaultRow?.concat() || [];
        arr.height = value.bottom - value.top + 1;
        arr.width = value.right - value.left + 1;
      }
    }
    else {
      arr = new Matrix(1, 1);
      arr.set(0, 0, value);
    }
    return arr;
  }

  static new<T extends MatrixNewData> (data: T & RequireDimensionsForDefaultQuadrants<T>) {
    const mat = new Matrix(data.width, data.height, data.defaultValue);
    if ('data' in data) {
      mat.setData(data.data);
    }
    else if ('row' in data) {
      mat.setData([ data.row ]);
    }
    else {
      mat.setData(data.column.map(el => [ el ]));
    }

    if (data.defaultColumn) {
      invariant(data.defaultColumn.length === mat.populatedHeight, 'defaultColumn should match populated height');
      mat._defaultColumn = data.defaultColumn;
    }
    if (data.defaultRow) {
      invariant(data.defaultRow.length === mat.populatedWidth, 'defaultRow should match populated width');
      mat._defaultRow = data.defaultRow;
    }

    return mat;
  }

  static ofTransposed (value: MaybeBoxed<ArrayValue> | MaybeBoxed<ArrayValue>[][] | Matrix): Matrix {
    if (isMatrix(value)) {
      const copy = new Matrix(value.height, value.width, value._defaultValue);
      copy._defaultRow = value._defaultColumn;
      copy._defaultColumn = value._defaultRow;
      value._data.forEach((row, r) =>
        row.forEach((value, c) => {
          copy.set(r, c, value);
        }),
      );
      return copy;
    }
    if (Array.isArray(value)) {
      const height = value.length;
      if (height === 0) {
        return new Matrix(0, 0);
      }
      const width = value[0].length;
      if (height === 0) {
        return new Matrix(0, 0);
      }
      const arr = new Matrix(height, width);
      for (let y = 0; y < height; y++) {
        const row = value[y];
        if (!Array.isArray(row) || row.length !== width) {
          throw new Error('internal error: invalid matrix');
        }
        for (let x = 0; x < width; x++) {
          arr.set(y, x, row[x]);
        }
      }
      return arr;
    }
    const arr = new Matrix(1, 1);
    arr.set(0, 0, value);
    return arr;
  }

  static createColumn (columnValues: MaybeBoxed<ArrayValue>[]) {
    const arr = new Matrix(1, columnValues.length);
    for (let i = 0; i < columnValues.length; i++) {
      arr.set(0, i, columnValues[i]);
    }
    return arr;
  }

  static createRow (rowValues: MaybeBoxed<ArrayValue>[]) {
    const arr = new Matrix(rowValues.length, 1);
    for (let i = 0; i < rowValues.length; i++) {
      arr.set(i, 0, rowValues[i]);
    }
    return arr;
  }

  get size () {
    return this.width * this.height;
  }

  get valid () {
    return (
      isFinite(this.height) &&
      isFinite(this.width) &&
      this.height > 0 &&
      this.height <= MAX_ROW &&
      this.width > 0 &&
      this.width <= MAX_COL
    );
  }

  clone () {
    const copy = new Matrix(this.width, this.height, this._defaultValue);
    copy._data = this._data.map(row => row.concat());
    copy._defaultColumn = this._defaultColumn.concat();
    copy._defaultRow = this._defaultRow.concat();
    return copy;
  }

  is1D () {
    return this.width === 1 || this.height === 1;
  }

  toString () {
    return `{${[ ...this.iterRowsBoxed() ].map(({ row }) => String(row)).join(';')}}`;
  }

  /**
   * Populate this matrix with the given data array.
   * The rows in `data` will be referenced, not copied, so `data` should be
   * considered to belong to this matrix henceforth, i.e. not mutated elsewhere.
   */
  setData (data: AreaValueArray | AreaBoxedValueArray | MaybeBoxed<ArrayValue>[][]) {
    // create a shallow copy of the data array
    this._data = data.slice();
    if ('defaultValue' in data) {
      this._defaultValue = data.defaultValue;
      invariant(
        data.defaultRow.length === 0 || (this._data.length > 0 && data.defaultRow.length === this._data[0].length),
      );
      this._defaultRow = data.defaultRow.slice();
      invariant(data.defaultColumn.length === 0 || data.defaultColumn.length === this._data.length);
      this._defaultColumn = data.defaultColumn.slice();
    }
    let xMax = -1;
    const yMax = this._data.length - 1;
    if (yMax >= 0) {
      // normalize row lengths
      const maxRowLength: number = data.reduce((max, row) => (row.length > max ? row.length : max), 0);
      for (let y = 0; y <= yMax; y++) {
        const row = this._data[y];
        if (row.length < maxRowLength) {
          const prevRowLength = row.length;
          const fillValue = this._defaultColumn.length > 0 ? this._defaultColumn[y] : this._defaultValue;
          row.length = maxRowLength;
          row.fill(fillValue, prevRowLength, maxRowLength);
        }
      }
      xMax = maxRowLength - 1;
    }
    this._ensureSizeAttributesEncompass(xMax, yMax);
    return this;
  }

  _ensureSizeAttributesEncompass (x: number, y: number) {
    if (this.height < y + 1) {
      this.height = y + 1;
    }
    if (this.width < x + 1) {
      this.width = x + 1;
    }
  }

  set (x: number, y: number, value: MaybeBoxed<ArrayValue>) {
    invariant(Number.isFinite(x) && Number.isFinite(y), 'x and y should be finite numbers');
    let popWidth = this.populatedWidth;
    if (x >= popWidth) {
      // must extend each existing row (and _defaultRow if not empty) with default value
      const popWidthBefore = popWidth;
      for (let iterY = 0; iterY < this._data.length; iterY++) {
        const fillValue = iterY < this._defaultColumn.length ? this._defaultColumn[iterY] : this._defaultValue;
        const row = this._data[iterY];
        for (let iterX = popWidthBefore; iterX <= x; iterX++) {
          row[iterX] = fillValue;
        }
      }
      popWidth = x + 1;
    }
    if (y >= this._data.length) {
      // must fill new rows with default values
      const fillRow = Array(popWidth).fill(this._defaultValue);
      for (let iterX = 0; iterX < popWidth; iterX++) {
        fillRow[iterX] = iterX < this._defaultRow.length ? this._defaultRow[iterX] : this._defaultValue;
      }
      for (let unpopulatedY = this._data.length; unpopulatedY <= y; unpopulatedY++) {
        this._data[unpopulatedY] = fillRow.concat();
      }
    }
    const row = this._data[y];
    invariant(row != null);
    row[x] = value;
    this._ensureSizeAttributesEncompass(x, y);
  }

  _get (x: number, y: number, strict: boolean = false, leaveBoxed: boolean = false): MaybeBoxed<ArrayValue> {
    if (!strict && this.width === 1) {
      x = 0;
    }
    if (!strict && this.height === 1) {
      y = 0;
    }
    if (x >= this.width || y >= this.height) {
      return ERROR_NA;
    }
    const xPopulated = x < this.populatedWidth;
    let result = this._defaultValue;
    if (y < this._data.length) {
      if (xPopulated) {
        result = this._data[y][x];
      }
      else if (y < this._defaultColumn.length) {
        result = this._defaultColumn[y];
      }
    }
    else if (xPopulated && x < this._defaultRow.length) {
      result = this._defaultRow[x];
    }
    if (leaveBoxed) {
      return result;
    }
    return unbox(result);
  }

  get (x: number, y: number, strict: boolean = false): ArrayValue {
    // @ts-expect-error (guaranteed not to be a value box by passing `leaveBoxed=false`)
    return this._get(x, y, strict, false);
  }

  getBoxed (x: number, y: number): MaybeBoxed<ArrayValue> {
    return this._get(x, y, false, true);
  }

  /**
   * Get the i'th element of this `Matrix` in by-rows iteration order.
   */
  getByIndex (i: number) {
    const div = Math.floor(i / this.width);
    const mod = i - div * this.width;
    return this.get(mod, div);
  }

  /**
   * Get the (possibly boxed) values of column c as a simple (not area) array.
   */
  getColumnBoxed (c: number, includeDefaulted: boolean = true): MaybeBoxed<ArrayValue>[] {
    const height = includeDefaulted ? this.height : this.populatedHeight;
    if (c >= this.width) {
      return Array(height).fill(ERROR_NA);
    }
    const column: MaybeBoxed<ArrayValue>[] = [];
    for (let r = 0; r < height; r += 1) {
      column[r] = this.getBoxed(c, r);
    }
    return column;
  }

  /**
   * Get the (possibly boxed) values of row r as a simple (not area) array.
   */
  getRowBoxed (r: number, includeDefaulted: boolean = true): MaybeBoxed<ArrayValue>[] {
    const width = includeDefaulted ? this.width : this.populatedWidth;
    if (r >= this.height) {
      return Array(width).fill(ERROR_NA);
    }
    let row;
    if (r < this._data.length) {
      row = this._data[r].slice();
    }
    else if (this._defaultRow.length > 0) {
      row = this._defaultRow.slice();
    }
    else {
      return Array(width).fill(this._defaultValue);
    }
    if (row.length < width) {
      const prevRowLength = row.length;
      row.length = width;
      const defaultValue = this._defaultColumn[r] ?? this._defaultValue;
      row.fill(defaultValue, prevRowLength, width);
    }
    return row;
  }

  /**
   * @returns {Cell}
   */
  resolveCell (): Cell {
    let v: ArrayValue = ERROR_VALUE;
    if (this.width === 1 && this.height === 1) {
      v = this.get(0, 0);
    }
    const cell = new Cell({ v: null });
    cell.v = v;
    return cell;
  }

  /**
   * @returns {ArrayValue}
   */
  resolveSingle (): ArrayValue {
    if (this.width === 1 && this.height === 1) {
      return this.get(0, 0);
    }
    return ERROR_VALUE;
  }

  /**
   * @returns {MaybeBoxed<ArrayValue>}
   */
  resolveSingleBoxed (): MaybeBoxed<ArrayValue> {
    if (this.width === 1 && this.height === 1) {
      return this.getBoxed(0, 0);
    }
    return ERROR_VALUE;
  }

  _resolveArea<O extends ReturnOptions> (options: ResolveAreaOptions<O>): AreaArray<AreaArrayElement<O>> {
    const area: (AreaArrayElement<O> | null)[][] = Array(this.populatedHeight);
    const popWidth = this.populatedWidth;
    let mapAndWrap: (value: MaybeBoxed<ArrayValue>) => AreaArrayElement<O> | null;
    if (options.returnCells) {
      mapAndWrap = el => {
        if (el == null) {
          return null;
        }
        const c = new Cell({ v: null });
        c.v = el;
        return c as AreaArrayElement<O>;
      };
    }
    else if (options.returnBoxed) {
      mapAndWrap = el => el as AreaArrayElement<O>;
    }
    else {
      // @ts-expect-error
      mapAndWrap = unbox;
    }
    for (let y = 0; y < this.populatedHeight; y++) {
      if (options.returnBoxed && !options.returnCells) {
        area[y] = this._data[y].slice(0, popWidth) as AreaArrayElement<O>[];
      }
      else {
        const row = Array(popWidth);
        area[y] = row;
        for (let x = 0; x < popWidth; x++) {
          row[x] = mapAndWrap(this.getBoxed(x, y));
        }
      }
    }
    const out: (AreaArrayElement<O> | null)[][] & AreaArrayAttributes<AreaArrayElement<O>> = Object.assign(area, {
      top: 0,
      left: 0,
      bottom: this.height - 1,
      right: this.width - 1,
      dataBottom: this.populatedHeight - 1,
      dataRight: this.populatedWidth - 1,
      defaultColumn: this._defaultColumn.map(mapAndWrap),
      defaultRow: this._defaultRow.map(mapAndWrap),
      defaultValue: mapAndWrap(this._defaultValue),
    });
    if (options.cropTo === 'cells-with-non-blank-values') {
      return cropRange(out);
    }
    return out;
  }

  resolveAreaCells (cropTo?: 'any-cell-information' | 'cells-with-non-blank-values') {
    return this._resolveArea(
      new ResolveAreaOptions({ returnCells: true, returnBoxed: false, cropTo }),
    ) as AreaCellArray;
  }

  resolveAreaValues () {
    return this._resolveArea(new ResolveAreaOptions({ returnCells: false, returnBoxed: false })) as AreaValueArray;
  }

  resolveAreaBoxed () {
    return this._resolveArea(new ResolveAreaOptions({ returnCells: false, returnBoxed: true })) as AreaBoxedValueArray;
  }

  _resolveRange<Boxed extends boolean = false> (
    opts?: IterationOptions<Boxed>,
  ): Array<Boxed extends false ? ArrayValue : MaybeBoxed<ArrayValue>> {
    const range: Array<Boxed extends false ? ArrayValue : MaybeBoxed<ArrayValue>> = [];
    for (const { value } of this.iterAll(opts)) {
      range.push(value);
    }
    return range;
  }

  uniqueValues (): Set<ArrayValue> {
    const values = new Set<ArrayValue>();
    for (const { value } of this.iterPopulated()) {
      values.add(value);
    }
    if (this.populatedHeight < this.height) {
      for (const value of this._defaultRow) {
        values.add(unbox(value));
      }
    }
    if (this.populatedWidth < this.width) {
      for (const value of this._defaultColumn) {
        values.add(unbox(value));
      }
    }
    // This adds the default value, including when `this._defaultRow` or
    // `this._defaultColumn` are empty or partially populated.
    values.add(this.get(this.width - 1, this.height - 1));
    return values;
  }

  resolveRange<Boxed extends boolean = false> (
    opts?: IterationOptions<Boxed>,
  ): Array<Boxed extends false ? ArrayValue : MaybeBoxed<ArrayValue>> {
    return this._resolveRange(opts);
  }

  collapseToRow (r: number = 0): Matrix | FormulaError {
    if (r >= this.height || r < 0) {
      return ERROR_REF.detailed(`Row ${r} out of bounds 0..${this.height - 1}`);
    }
    const defaultValue = r < this._defaultColumn.length ? this._defaultColumn[r] : this._defaultValue;
    const mx = new Matrix(this.width, 1, defaultValue);
    const sourceRow = r >= this.populatedHeight ? this._defaultRow : this._data[r];
    mx._data = [ sourceRow.slice() ];
    return mx;
  }

  collapseToColumn (c: number = 0): Matrix | FormulaError {
    if (c >= this.width || c < 0) {
      return ERROR_REF.detailed(`Column ${c} out of bounds 0..${this.width - 1}`);
    }
    if (c >= this.populatedWidth) {
      const col = this._defaultColumn.slice();
      if (col.length < this.populatedHeight) {
        const prevLength = col.length;
        col.length = this.populatedHeight;
        col.fill(this._defaultValue, prevLength, this.populatedHeight);
      }
      const mx = new Matrix(1, this.height, this._defaultValue);
      mx._data = this._defaultColumn.length > 0 ? this._defaultColumn.map(value => [ value ]) : [ [] ];
      return mx;
    }
    else {
      const defaultValue = c < this._defaultRow.length ? this._defaultRow[c] : this._defaultValue;
      const mx = new Matrix(1, this.height, defaultValue);
      mx._data = this._data.map(row => [ row[c] ]);
      return mx;
    }
  }

  collapseToCell (r: number = 0, c: number = 0): Matrix | FormulaError {
    if (r >= this.height || r < 0) {
      return ERROR_REF.detailed(`Row ${r} out of bounds 0..${this.height - 1}`);
    }
    if (c >= this.width || c < 0) {
      return ERROR_REF.detailed(`Column ${c} out of bounds 0..${this.width - 1}`);
    }
    return Matrix.createRow([ this._get(c, r, true, true) ]);
  }

  /**
   * Return a matrix of the zero-based nth element in a left-to-right-then-top-down traversal of this matrix.
   * Note that bounds checking is not performed; this can yield a cell outside this range.
   * The returned instance is guaranteed to be new, even if it is identical to `this`.
   * @param n zero-based index of the value to return
   * @returns matrix containing the single value specified, or error if n out of bounds
   */
  collapseToNthCell (n: number = 0): Matrix | FormulaError {
    const colIndex = n % this.width;
    const rowIndex = Math.floor(n / this.width);
    return this.collapseToCell(rowIndex, colIndex);
  }

  /**
   * Stack matrices in a sequence horizontally (column wise).
   * See e.g. https://numpy.org/doc/stable/reference/generated/numpy.hstack.html
   *
   * Invariant: `this.height === other.height`
   * @param other Other matrix. Must have the same height as `this`.
   * @returns Horizontally stacked matrix
   */
  hstack (other: Matrix): Matrix {
    if (this.size === 0) {
      return other;
    }
    if (other.size === 0) {
      return this;
    }
    invariant(this.height === other.height, 'Cannot horizontally stack matrices of different heights');
    const mx = new Matrix(this.width + other.width, this.height, other._defaultValue);
    const populatedWidth = this.width + other.populatedWidth;
    const populatedHeight = Math.max(this.populatedHeight, other.populatedHeight);
    const height = Math.max(this.height, other.height);
    const width = this.width + other.width;

    if (width > populatedWidth && other._defaultColumn.length !== 0) {
      if (!allMaybeBoxedValuesEqual([ other._defaultValue, ...other._defaultColumn ])) {
        mx._defaultColumn =
          populatedHeight !== other._defaultColumn.length
            ? [ ...other._defaultColumn, ...Array(populatedHeight - other.populatedHeight).fill(other._defaultValue) ]
            : other._defaultColumn.concat();
      }
    }

    if (height > populatedHeight) {
      const [ defaultRow, defaultValue ] = getHstackVstackDefaultValuesForPerpendicularAxis(
        this._defaultRow,
        this._defaultValue,
        this.width,
        this.populatedWidth,
        other._defaultRow,
        other._defaultValue,
        other.width,
        other.populatedWidth,
      );
      mx._defaultRow = defaultRow;
      mx._defaultValue = defaultValue;
    }

    const data: MaybeBoxed<ArrayValue>[][] = [];
    for (let y = 0; y < populatedHeight; y++) {
      const row: MaybeBoxed<ArrayValue>[] = [];
      data.push(row);
      for (let x = 0; x < this.width; x++) {
        row.push(this.getBoxed(x, y));
      }
      for (let x = 0; x < other.populatedWidth; x++) {
        row.push(other.getBoxed(x, y));
      }
    }
    mx._data = data;
    return mx;
  }

  /**
   * Stack matrices in a sequence vertically (row wise).
   * See e.g. https://numpy.org/doc/stable/reference/generated/numpy.vstack.html
   *
   * Invariant: `this.width === other.width`
   * @param other Other matrix. Must have the same width as `this`.
   * @returns Vertically stacked matrix
   */
  vstack (other: Matrix): Matrix {
    if (this.size === 0) {
      return other;
    }
    if (other.size === 0) {
      return this;
    }
    invariant(this.width === other.width, 'Cannot vertically stack matrices of different widths');
    const mx = new Matrix(this.width, this.height + other.height, other._defaultValue);
    const populatedHeight = this.height + other.populatedHeight;
    const populatedWidth = Math.max(this.populatedWidth, other.populatedWidth);
    const width = Math.max(this.width, other.width);
    const height = this.height + other.height;

    if (height > populatedHeight && other._defaultRow.length !== 0) {
      if (!allMaybeBoxedValuesEqual([ other._defaultValue, ...other._defaultRow ])) {
        mx._defaultRow =
          populatedWidth !== other._defaultRow.length
            ? [ ...other._defaultRow, ...Array(populatedWidth - other.populatedWidth).fill(other._defaultValue) ]
            : other._defaultRow.concat();
      }
    }

    if (width > populatedWidth) {
      const [ defaultColumn, defaultValue ] = getHstackVstackDefaultValuesForPerpendicularAxis(
        this._defaultColumn,
        this._defaultValue,
        this.height,
        this.populatedHeight,
        other._defaultColumn,
        other._defaultValue,
        other.height,
        other.populatedHeight,
      );
      mx._defaultColumn = defaultColumn;
      mx._defaultValue = defaultValue;
    }

    const data: MaybeBoxed<ArrayValue>[][] = [];
    for (let y = 0; y < populatedHeight; y++) {
      const row: MaybeBoxed<ArrayValue>[] = [];
      data.push(row);
      const which = y < this.height ? this : other;
      const relY = y < this.height ? y : y - this.height;
      for (let x = 0; x < populatedWidth; x++) {
        row.push(which.getBoxed(x, relY));
      }
    }
    mx._data = data;
    return mx;
  }

  /**
   * Expand matrix to the given dimensions, using `fillWith` as a padding value.
   * If either dimension of the matrix is already the given size or larger, it
   * is not changed.
   * @param paddingValue
   * @param toRows Desired number of rows in the expanded matrix.
   * @param toCols Desired number of columns in the expanded matrix.
   */
  expand (paddingValue: MaybeBoxed<ArrayValue>, toRows: number, toCols: number): Matrix {
    if (toRows <= this.height && toCols <= this.width) {
      return this;
    }
    const expandedMatrix = this.clone();
    if (!expandedMatrix.isPartiallyPopulated) {
      // happy path, just extend with defaultValue
      expandedMatrix._defaultValue = paddingValue;
      expandedMatrix.height = toRows;
      expandedMatrix.width = toCols;
      // make default column and default row empty, so only defaultValue applies
      expandedMatrix._defaultColumn.splice(0);
      expandedMatrix._defaultRow.splice(0);
      return expandedMatrix;
    }
    const startY = toCols > this.width ? 0 : this.height;
    for (let y = startY; y < toRows; y++) {
      const startX = y < this.height ? this.width : 0;
      for (let x = startX; x < toCols; x++) {
        expandedMatrix.set(x, y, paddingValue);
      }
    }
    return expandedMatrix;
  }

  /**
   * @returns {Matrix}
   */
  toMatrix (): Matrix {
    return this;
  }

  * iterPopulated<Boxed extends boolean = false> (
    opts?: IterationOptions<Boxed>,
  ): IterableIterator<{ x: number, y: number, value: Boxed extends false ? ArrayValue : MaybeBoxed<ArrayValue> }> {
    type ElementType = Boxed extends false ? ArrayValue : MaybeBoxed<ArrayValue>;
    const leaveBoxed = opts ? !!opts.leaveBoxed : false;
    const skipBlanks = opts?.skipBlanks === 'all';
    for (let y = 0; y < this.populatedHeight; y++) {
      for (let x = 0; x < this.populatedWidth; x++) {
        const value = this._get(x, y, true, leaveBoxed);
        if (!skipBlanks || unbox(value) != null) {
          yield { x, y, value: value as ElementType };
        }
      }
    }
  }

  /**
   * Yield all values in rows-first order, including default values for
   * unpopulated areas (unless they are blank and `skipBlanks` is not `'none'`).
   */
  * iterAll<Boxed extends boolean = false> (
    opts?: IterationOptions<Boxed>,
  ): IterableIterator<{ x: number, y: number, value: Boxed extends false ? ArrayValue : MaybeBoxed<ArrayValue> }> {
    type ElementType = Boxed extends false ? ArrayValue : MaybeBoxed<ArrayValue>;
    const leaveBoxed = opts ? !!opts.leaveBoxed : false;
    const skipBlanks = opts?.skipBlanks ?? 'none';
    const runCollapsedWidth = opts?.collapseRuns ? Math.min(this.width, this.populatedWidth + 1) : this.width;
    const runCollapsedHeight = opts?.collapseRuns ? Math.min(this.height, this.populatedHeight + 1) : this.height;
    const height =
      skipBlanks === 'none' || this._defaultRow.length || this._defaultValue != null
        ? runCollapsedHeight
        : this.populatedHeight;
    const width =
      skipBlanks === 'none' || this._defaultColumn.length || this._defaultValue != null
        ? runCollapsedWidth
        : this.populatedWidth;
    const includeBlanks = skipBlanks !== 'all';
    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        const maybeBoxedValue = this.getBoxed(x, y);
        const unboxedValue = unbox(maybeBoxedValue);
        if (includeBlanks || unboxedValue != null) {
          yield { x, y, value: leaveBoxed ? (maybeBoxedValue as ElementType) : unboxedValue };
        }
      }
    }
  }

  /**
   * Yield all values, including default values for unpopulated areas (but only
   * once, with a count for how many times they are repeated), in unspecified
   * order. The same value may be yielded multiple times, so the count each time
   * is not necessarily the total number of occurrences of that value in the
   * matrix; this is just run-length encoding to cut down on repetition.
   * @returns {IterableIterator<{value: ArrayValue, count: number}>}
   */
  * iterAllWithCount (): IterableIterator<{ value: ArrayValue, count: number }> {
    const unpopulatedHeight = this.height - this.populatedHeight;
    const unpopulatedWidth = this.width - this.populatedWidth;
    const hasDefaultRow = unpopulatedHeight && this._defaultRow.length > 0;
    const hasDefaultColumn = unpopulatedWidth > 0 && this._defaultColumn.length > 0;
    for (let y = 0; y < this.populatedHeight; y++) {
      for (let x = 0; x < this.populatedWidth; x++) {
        yield { value: this.get(x, y), count: 1 };
      }
      if (hasDefaultColumn) {
        yield { value: this.get(this.populatedWidth, y), count: unpopulatedWidth };
      }
    }
    if (hasDefaultRow) {
      for (let x = 0; x < this.populatedWidth; x++) {
        yield { value: this.get(x, this.populatedHeight), count: unpopulatedHeight };
      }
    }
    let defaultValueCount = unpopulatedWidth * unpopulatedHeight;
    if (!hasDefaultColumn) {
      defaultValueCount += unpopulatedWidth * this.populatedHeight;
    }
    if (!hasDefaultRow) {
      defaultValueCount += unpopulatedHeight * this.populatedWidth;
    }
    yield { value: unbox(this._defaultValue), count: defaultValueCount };
  }

  * iterAllColumnWise (
    leaveBoxed: boolean = false,
  ): IterableIterator<{ x: number, y: number, value: MaybeBoxed<ArrayValue> }> {
    for (let x = 0; x < this.width; x++) {
      for (let y = 0; y < this.height; y++) {
        yield { x, y, value: leaveBoxed ? this.getBoxed(x, y) : this.get(x, y) };
      }
    }
  }

  map (fn: (element: MaybeBoxed<ArrayValue>, coords: { x: number, y: number }) => MaybeBoxed<ArrayValue>): Matrix {
    const defaultValue = fn(this._defaultValue, { x: this.populatedWidth, y: this.populatedHeight });
    const mx = new Matrix(this.width, this.height, defaultValue);
    mx._defaultRow = this._defaultRow.map((element, x) => fn(element, { x, y: this.populatedHeight }));
    mx._defaultColumn = this._defaultColumn.map((element, y) => fn(element, { x: this.populatedWidth, y }));
    for (let y = 0; y < this.populatedHeight; y++) {
      for (let x = 0; x < this.populatedWidth; x++) {
        mx.set(x, y, fn(this.getBoxed(x, y), { x, y }));
      }
    }
    return mx;
  }

  /**
   * @returns {IterableIterator<{y: number, row: MaybeBoxed<ArrayValue>[]}>}
   */
  * iterRowsBoxed (): IterableIterator<{ y: number, row: MaybeBoxed<ArrayValue>[] }> {
    const popHeight = this.populatedHeight;
    for (let y = 0; y < popHeight; y++) {
      const row = this.getRowBoxed(y);
      yield { y, row };
    }
    const defaultRow = this._defaultRow.length ? this._defaultRow : Array(this.width).fill(this._defaultValue);
    for (let y = this.populatedHeight; y < this.height; y++) {
      yield { y, row: defaultRow };
    }
  }

  /**
   * @returns {IterableIterator<{x: number, column: MaybeBoxed<ArrayValue>[]}>}
   */
  * iterColumnsBoxed (): IterableIterator<{ x: number, column: MaybeBoxed<ArrayValue>[] }> {
    const popWidth = this.populatedWidth;
    for (let x = 0; x < popWidth; x++) {
      const column = this.getColumnBoxed(x);
      yield { x, column };
    }
    const defaultColumn = this._defaultColumn.length
      ? this._defaultColumn
      : Array(this.height).fill(this._defaultValue);
    for (let x = this.populatedWidth; x < this.width; x++) {
      yield { x, column: defaultColumn };
    }
  }

  /**
   * Indicates whether or not this matrix is partially populated
   * @returns {boolean}
   */
  get isPartiallyPopulated (): boolean {
    return this.populatedHeight < this.height || this.populatedWidth < this.width;
  }

  every (predicate: (value: MaybeBoxed<ArrayValue>) => boolean) {
    for (const row of this._data) {
      for (const element of row) {
        if (!predicate(element)) {
          return false;
        }
      }
    }
    if ((this.populatedHeight < this.height || this.populatedWidth < this.width) && !predicate(this._defaultValue)) {
      // default value fails the predicate _and_ there are coordinates where it applies
      return false;
    }
    if (this.populatedHeight < this.height) {
      for (const element of this._defaultRow) {
        if (!predicate(element)) {
          return false;
        }
      }
    }
    if (this.populatedWidth < this.width) {
      for (const element of this._defaultColumn) {
        if (!predicate(element)) {
          return false;
        }
      }
    }
    return true;
  }

  /**
   * When providing the permutations for a matrix where `height` is
   * greater than `populatedHeight`, the length of `permutation` should
   * be `populatedHeight + 1`. Given a Matrix like so:
   *
   *    Matrix { populatedHeight: 3, height: 1000 }
   *
   * `permutation` should should be of length 4, where
   *
   *    - indices 0, 1, 2 represent the indices of the populated
   *      rows, and
   *    - index 3 represents all of the indices in the default
   *      quadrant.
   *
   * Providing `permutation = [ 0, 2, 1, 3 ]` would create a matrix
   * of `height` 1000 with a `populatedHeight` of 3.
   *
   * However, providing `permutation = [ 0, 3, 1, 2 ]` would create
   * a matrix of `height` 1000 where the `populatedHeight` is 1000
   * as well. `permutation = [0, 3, 1, 2]` is equivalent to:
   *
   *    permutation = [ 0, 3, 3, 3, (...994 more 3's), 1, 2 ]
   */
  permuteRows (permutation: number[]) {
    const { width, height, populatedWidth, populatedHeight } = this;
    const matrix = new Matrix(width, height, this._defaultValue);
    const popHeight = populatedHeight;

    const attemptHandleDefaultQuadrant = height > populatedHeight && permutation.length === populatedHeight + 1;

    if (attemptHandleDefaultQuadrant) {
      const defaultQuadrantAtEnd = permutation[permutation.length - 1] === permutation.length - 1;
      if (defaultQuadrantAtEnd) {
        // Default quadrant value is last. We don't need to consider the
        // default quadrants.
        permutation = permutation.slice(0, permutation.length - 1);
      }
      else {
        // A default quadrant element is between populated data. This means
        // that we need to make `height` equal to `populatedHeight`.
        //
        // Insert an index pointing to a default quadrant element N times
        // where the default quadrant element landed, where N is equal to
        // `height - populatedHeight`.
        const N = height - populatedHeight;
        const i = permutation.findIndex(n => n === permutation.length - 1);
        const indices = Array(N).fill(permutation.length - 1);
        safeSplice(permutation, i, 1, indices);
      }
    }

    matrix._data = permutation.map(rowIndex => {
      if (rowIndex >= popHeight) {
        return this._defaultRow.length > 0 ? this._defaultRow.slice() : Array(populatedWidth).fill(this._defaultValue);
      }
      return this._data[rowIndex].slice();
    });
    if (permutation.length < matrix.height) {
      matrix._defaultRow = this._defaultRow.concat();
    }
    if (this._defaultColumn.length) {
      matrix._defaultColumn = permutation.map(rowIndex => {
        if (rowIndex >= popHeight) {
          return this._defaultValue;
        }
        return this._defaultColumn[rowIndex];
      });
    }
    return matrix;
  }

  /**
   * When providing the permutations for a matrix where `width` is
   * greater than `populatedWidth`, the length of `permutation` should
   * be `populatedWidth + 1`. Given a Matrix like so:
   *
   *    Matrix { populatedWidth: 3, width: 1000 }
   *
   * `permutation` should should be of length 4, where
   *
   *    - indices 0, 1, 2 represent the indices of the populated
   *      rows, and
   *    - index 3 represents all of the indices in the default
   *      quadrant.
   *
   * Providing `permutation = [ 0, 2, 1, 3 ]` would create a matrix
   * of `width` 1000 with a `populatedWidth` of 3.
   *
   * However, providing `permutation = [ 0, 3, 1, 2 ]` would create
   * a matrix of `width` 1000 where the `populatedWidth` is 1000
   * as well. `permutation = [0, 3, 1, 2]` is equivalent to:
   *
   *    permutation = [ 0, 3, 3, 3, (...994 more 3's), 1, 2 ]
   */
  permuteColumns (permutation: number[]) {
    const { width, height, populatedWidth } = this;
    const matrix = new Matrix(width, height, this._defaultValue);

    const attemptHandleDefaultQuadrant = width > populatedWidth && permutation.length === populatedWidth + 1;

    if (attemptHandleDefaultQuadrant) {
      const defaultQuadrantAtEnd = permutation[permutation.length - 1] === permutation.length - 1;
      if (defaultQuadrantAtEnd) {
        // Default quadrant value is last. We don't need to consider the
        // default quadrants.
        permutation = permutation.slice(0, permutation.length - 1);
      }
      else {
        // A default quadrant element is between populated data. This means
        // that we need to make `width` equal to `populatedWidth`.
        //
        // Insert an index pointing to a default quadrant element N times
        // where the default quadrant element landed, where N is equal to
        // `width - populatedWidth`.
        const N = width - populatedWidth;
        const insertAt = permutation.findIndex(n => n === permutation.length - 1);
        const indices = Array(N).fill(permutation.length - 1);
        safeSplice(permutation, insertAt, 1, indices);
      }
    }

    this._data.forEach((row, rowIndex) => {
      matrix._data[rowIndex] = permutation.map(colIndex => {
        if (colIndex >= populatedWidth) {
          return this._defaultColumn.length > 0 ? this._defaultColumn[rowIndex] : this._defaultValue;
        }
        return row[colIndex];
      });
    });
    if (permutation.length < matrix.width) {
      matrix._defaultColumn = this._defaultColumn.concat();
    }
    if (this._defaultRow.length) {
      matrix._defaultRow = permutation.map(colIndex => {
        if (colIndex >= populatedWidth) {
          return this._defaultValue;
        }
        return this._defaultRow[colIndex];
      });
    }
    return matrix;
  }

  trimBottom () {
    if (this._defaultValue == null && this._defaultRow.every(el => unbox(el) == null)) {
      this.height = this.populatedHeight;
    }
    while (this.height && this.getRowBoxed(this.height - 1).every(el => unbox(el) == null)) {
      this.height -= 1;
    }
  }

  /**
   * Does this Matrix have a default value which fulfills the given predicate?
   */
  hasDefaultValueFulfilling (predicate: (value: MaybeBoxed<ArrayValue>) => boolean): boolean {
    if (
      this.width > this.populatedWidth &&
      ((this._defaultColumn.length > 0 && this._defaultColumn.some(v => predicate(v))) ||
        (this._defaultColumn.length === 0 && predicate(this._defaultValue)))
    ) {
      return true;
    }
    return (
      this.height > this.populatedHeight &&
      ((this._defaultRow.length > 0 && this._defaultRow.some(v => predicate(v))) ||
        (this._defaultRow.length === 0 && predicate(this._defaultValue)))
    );
  }
}

/**
 * Remove numToDelete elements starting at i; insert the elements in toInsert
 * in their stead. This exists because Array.prototype.splice is not safe for
 * large insertion arrays because the spreading can blow the stack, throwing
 * RangeError.
 */
function safeSplice<T> (array: T[], i: number, numToDelete: number, toInsert: T[]) {
  const CHUNK_SIZE = 10_000;
  while (toInsert.length) {
    const chunk = toInsert.slice(0, CHUNK_SIZE);
    array.splice(i, numToDelete, ...chunk);
    numToDelete = 0;
    toInsert = toInsert.slice(CHUNK_SIZE);
  }
}

function allMaybeBoxedValuesEqual (arr: MaybeBoxed<ArrayValue>[]) {
  const first = arr[0];
  for (let i = 1; i < arr.length; i++) {
    if (!maybeBoxedValuesEqual(first, arr[i])) {
      return false;
    }
  }
  return true;
}

function isAreaValueArray (value: Array<any>): value is AreaBoxedValueArray | AreaValueArray {
  return (
    'defaultValue' in value &&
    'defaultColumn' in value &&
    'defaultRow' in value &&
    'top' in value &&
    'left' in value &&
    'bottom' in value &&
    'right' in value
  );
}

function getHstackVstackDefaultValuesForPerpendicularAxis (
  thisDefaultArray: MaybeBoxed<ArrayValue>[],
  thisDefaultValue: MaybeBoxed<ArrayValue>,
  thisDimension: number,
  thisPopulatedDimension: number,
  otherDefaultArray: MaybeBoxed<ArrayValue>[],
  otherDefaultValue: MaybeBoxed<ArrayValue>,
  otherDimension: number,
  otherPopulatedDimension: number,
): [defaultArray: MaybeBoxed<ArrayValue>[], defaultValue: MaybeBoxed<ArrayValue>] {
  const hasDefaultArr = thisDefaultArray.length !== 0 || otherDefaultArray.length !== 0;
  const hasDefaultValue = thisDefaultValue !== null || otherDefaultValue !== null;

  if (hasDefaultArr) {
    const defaultElements = [
      ...thisDefaultArray,
      ...otherDefaultArray,
      ...(thisDimension > thisPopulatedDimension ? [ thisDefaultValue ] : []),
      ...(otherDimension > otherPopulatedDimension ? [ otherDefaultValue ] : []),
    ];
    if (allMaybeBoxedValuesEqual(defaultElements)) {
      // All elements that affect the two perpendicular quadrants are
      // equal, so we can use one of the elements as the `defaultValue`
      // for the resulting Matrix.
      return [ [], defaultElements[0] ];
    }

    const a =
      thisDefaultArray.length !== 0
        ? [ ...thisDefaultArray, ...Array(thisDimension - thisPopulatedDimension).fill(thisDefaultValue) ]
        : Array(thisDimension).fill(thisDefaultValue);
    const b =
      otherDefaultArray.length !== 0 ? otherDefaultArray : Array(otherPopulatedDimension).fill(thisDefaultValue);
    return [ [ ...a, ...b ], otherDefaultValue ];
  }

  if (maybeBoxedValuesEqual(thisDefaultValue, otherDefaultValue)) {
    return [ [], otherDefaultValue ];
  }
  if (hasDefaultValue) {
    const a = Array(thisDimension).fill(thisDefaultValue);
    const b = Array(otherPopulatedDimension).fill(otherDefaultValue);
    return [ [ ...a, ...b ], otherDefaultValue ];
  }

  return [ [], otherDefaultValue ];
}

export function isMatrix (d: any): d is Matrix {
  return d instanceof Matrix;
}

export default Matrix;
