/* globals process */
import { PureComponent, ReactNode } from 'react';
import * as Sentry from '@sentry/nextjs';
import csx from 'classnames';
import dynamic from 'next/dynamic';
import SingletonRouter, { Router, withRouter } from 'next/router';
import { Descendant } from 'slate';

import type { SheetCSF } from '@grid-is/apiary';
import { getElementTop, getIsIntegrationTests, isBrowser, query, sessionStorage } from '@grid-is/browser-utils';
import { getConfig } from '@grid-is/environment-config';
import { tracking } from '@grid-is/tracking';
import { assert } from '@grid-is/validation';

import { refresh } from '@/api/source';
import { ReplacingWorkbookContextProvider } from '@/editor/contexts/ReplacingWorkbookContext';
import { EventStream } from '@/editor/EditorEventStream';
import { Doc } from '@/grid/Doc';
import GridDocument from '@/grid/GridDocument';
import { getDocumentActiveWidth } from '@/grid/utils/getDocumentActiveWidth';
import getID from '@/grid/utils/uid';
import { applyStateFromURL, parseHref } from '@/grid/utils/urlState';
import { Button } from '@/grid-ui/Button';
import { LoadingSpinner } from '@/grid-ui/LoadingSpinner';
import { Modal } from '@/grid-ui/Modal';
import { TextLink } from '@/grid-ui/TextLink';
import { DocumentTutorial } from '@/components/DocumentTutorial';
import { WorkbookInfo } from '@/components/WorkbookInfo';
import {
  CLOUD_DRIVE_AUTOMATIC_REFRESH_NOTICE,
  CLOUD_DRIVE_SYNC_ERRORS,
  DOCUMENT_SIZE,
  DUMMY_UPLOAD_ID,
  EDIT,
  GENERIC_DOCUMENT_ERROR,
  SOURCE_ERRORS,
  VIEW,
} from '@/constants';
import { fileExtension } from '@/utils/file';
import { processWorkbook } from '@/utils/processWorkbook';
import { SESSION_STORAGE_KEY_ADD_DRIVE_LOCATION, SESSION_STORAGE_KEY_SOURCE_ID_BEING_REPLACED } from '@/utils/remotes';

import { ModelStateArray } from '../../utils/modelState';
import { HelpCenter } from '../DocumentActions/HelpCenter';
import { TutorialsType } from '../DocumentTutorial/Tutorials';
import type { FileToImport } from './AddWorkbook/AddWorkbook';
import { PasteTabularDataToGridSheets } from './EditableDocument/PasteTabularDataToGridSheets';
import { ElementFocusManager } from './ElementFocusManager';
import { EmbedAPIManager, EmbedCellData } from './EmbedAPIManager';
import { Fullscreen } from './Fullscreen';
import { MarginManager } from './MarginManager/MarginManager';
import { DISCARDWAIT, PUBLISHWAIT, SAVEFAIL, SAVEPENDING, SAVESTALE, type SaveState, SAVEWAIT } from './states';

import styles from './document.module.scss';

const AddWorkbook = dynamic(() => import('./AddWorkbook').then(mod => mod.AddWorkbook), {
  loading: () => null,
});

// Lazy-load the EditableDocument component to reduce the first load bundle size for view mode
const EditableDocument = dynamic(() => import('./EditableDocument').then(mod => mod.EditableDocument), {
  loading: () => <LoadingSpinner centered />,
});

const exitWarnMessage = 'Changes you made may not be saved.';

const isUnsaved = (saveState: SaveState) => {
  return saveState === SAVEPENDING || saveState === SAVEWAIT || saveState === PUBLISHWAIT || saveState === DISCARDWAIT;
};

type Props = {
  router: Router,
  doc: Doc,
  ready: boolean,
  isOwner: boolean,
  closeFullScreen: (duration: number) => void,
  embed: boolean,
  chromeless: boolean,
  useFullWidth: boolean,
  alignCenter: boolean,
  customPadding?: string,
  fullscreen: boolean,
  mode: typeof VIEW | typeof EDIT,
  initialModelState?: null | ModelStateArray,
  onUpdateDocument: (value: Descendant) => void,
  onModelChange: (modelState: any, callback?: () => void) => void,
  shouldServerSideRender: boolean,
  saveState: SaveState,
  hasAlteredState: boolean,
  onClickFullscreenButton: () => void,
};

type State = {
  pendingWorkbookIds: string[],
  workbookToSelect: string | null,
  isModelReady: boolean,
  showDetailsForWorkbookId: string | null,
  isAddOrReplaceWorkbookModalOpen: boolean,
  workbookIdBeingReplaced: string | null,
  upload: boolean,
  newDocument: boolean,
  tutorial?: TutorialsType,
  error?: {
    title: string,
    body: ReactNode,
    footer?: ReactNode,
    onClose?: () => void,
  } | null,
  isNewDocument?: boolean,
  isUploadingNewWb?: boolean,
  addFlowError?: {
    error_code: string,
  },
};

interface DocEmbedEvent {
  isGRID: boolean,
  type: string,
  elementType: string,
  documentId: string,
  elementId: string,
  timestamp: number,
  targetUrl?: string,
  elementLabel?: string,
  submitData?: Record<string, any>,
  queryData?: EmbedCellData[],
}

class DocumentInternal extends PureComponent<Props, State> {
  constructor (props: Props | Readonly<Props>) {
    super(props);
    const workbookIdBeingReplaced = isBrowser() && sessionStorage.getItem(SESSION_STORAGE_KEY_SOURCE_ID_BEING_REPLACED);
    if (workbookIdBeingReplaced) {
      sessionStorage.removeItem(SESSION_STORAGE_KEY_SOURCE_ID_BEING_REPLACED);
    }
    const addDriveLocation = isBrowser() && sessionStorage.getItem(SESSION_STORAGE_KEY_ADD_DRIVE_LOCATION);
    if (addDriveLocation && addDriveLocation !== 'settings') {
      sessionStorage.removeItem(SESSION_STORAGE_KEY_ADD_DRIVE_LOCATION);
    }
    this.embedAPI = new EmbedAPIManager();
    this.state = {
      pendingWorkbookIds: [],
      workbookToSelect: null,
      isModelReady: false,
      showDetailsForWorkbookId: null,
      isAddOrReplaceWorkbookModalOpen: (!!workbookIdBeingReplaced || addDriveLocation && addDriveLocation !== 'settings'),
      workbookIdBeingReplaced: workbookIdBeingReplaced || null,
      upload: false,
      newDocument: false,
      // tutorial may be an array if query looks like `?tutorial=FOO&tutorial=BAR`
      tutorial: this.props.router.query.tutorial?.toString() as TutorialsType,
    };
  }

  componentDidMount () {
    if (!getIsIntegrationTests()) {
      // we dont want this functionality in our e2e tests as it causes some tests to hang
      window.addEventListener('beforeunload', this.getExitFunction());
    }
    if (document.fonts) {
      document.fonts.addEventListener('loadingdone', this.onFont);
    }
    window.addEventListener('message', this.onMessage);

    if (this.props.doc) {
      this.loadWorkbooks();
    }
    this.promptUnsavedChanges();
  }

  componentDidUpdate (prevProps, prevState) {
    const { doc, router, isOwner } = this.props;
    const { tutorial } = this.state;

    this.processSingleShotQueryParams();

    if (prevProps.doc !== doc) {
      this.loadWorkbooks();
    }
    this.maybeReloadPrunedWorkbooks();

    if (doc.isBlank() && !this.state.newDocument) {
      this.showNewDocumentAnimation();
    }

    // we want to open the data modal for new documents if there is no workbook
    // attached
    const shouldOpenDataModal = this.state.isModelReady && !prevState.isModelReady;
    if (shouldOpenDataModal && doc.isBlank() && !doc.model.getWorkbooks().length) {
      this.setState({ isAddOrReplaceWorkbookModalOpen: true });
    }
    if (router.query?.isNew) {
      this.onNewRoute();
    }

    // Trigger Appcues event if the add data modal was just closed.
    if (!tutorial && prevState.isAddOrReplaceWorkbookModalOpen && !this.state.isAddOrReplaceWorkbookModalOpen) {
      setTimeout(() => {
        tracking.logAppcuesEvent('Add Spreadsheet Modal Closed');
      }, 2000);
    }

    const { saveState } = this.props;
    if (saveState && saveState !== prevProps.saveState && [ SAVESTALE, SAVEFAIL ].includes(saveState)) {
      tracking.logEvent('Unable To Save Popup Displayed', {
        document_id: doc.id,
        document_owner_id: doc.creator.id,
        is_document_owner: isOwner,
      });
      // eslint-disable-next-line react/no-did-update-set-state
      if (saveState === SAVESTALE) {
        this.setState({ error: {
          title: 'Unable to save',
          onClose: () => {
            const { user, documentId } = router.query;
            if (user && documentId) {
              window.location.href = `/${user}/${documentId}`;
            }
          },
          body: (
            <div>
              <p>
                It seems like this document is open in more than one window.
              </p>
              <p>
                To see the most recent changes, please <TextLink onClick={() => location.reload()}>reload</TextLink>.
              </p>
            </div>
          ),
        } });
      }
      else {
        this.setState({ error: {
          title: 'Unable to save',
          onClose: () => location.reload(),
          body: (
            <div>
              <p>
                We encountered an error while saving your document. We'll recover the most recent version when you close.
              </p>
              <p>
                Contact us at <TextLink href="mailto:support@calculatorstudio.co">support@calculatorstudio.co</TextLink> if the issue persists.
              </p>
            </div>
          ),
        } });
      }
    }
  }

  componentWillUnmount () {
    const { doc } = this.props;
    doc.suspendMonitoring();
    doc.off('modelrecalc', this.onModelRecalc);
    doc.off('modelbeforerecalc', this.onModelBeforeRecalc);
    doc.off('modelreset', this.onModelReset);
    doc.off('modelready', this.onModelReady);
    doc.off('wbattached', this.onDocWbAdded);
    doc.off('wbloaded', this.onTrackWbStart);
    doc.off('wbupdate', this.onDocWbUpdate);
    doc.off('wbfail', this.onDocWbFail);
    doc.off('wbupdateerror', this.onDocWbFail);
    doc.off('wbrenamed', this.relayWbrenamedEvent);
    doc.off('linkwb', this.onDocWbLinked);
    doc.off('interaction', this.onDocInteraction);
    doc.off('track', this.onDocTrackEvent);
    doc.off('warning', this.captureErrorToSentry);
    doc.off('boundarycatch', this.captureErrorToSentry);
    window.removeEventListener('beforeunload', this.getExitFunction());
    window.removeEventListener('message', this.onMessage);
    if (document.fonts) {
      document.fonts.removeEventListener('loadingdone', this.onFont);
    }
    // Undo the hack override of client side routing, see promptUnsavedChanges
    assert(SingletonRouter.router);
    // @ts-expect-error
    delete SingletonRouter.router.change;
  }

  getExitFunction () {
    if (!this._exitFn) {
      const exitFn = function (this: DocumentInternal, event: { returnValue: string }) {
        if (isUnsaved(this.props.saveState)) {
          event.returnValue = exitWarnMessage;
          return exitWarnMessage;
        }
      };
      this._exitFn = exitFn.bind(this);
    }
    return this._exitFn;
  }

  getWorkbook (id) {
    const doc = this.props.doc;
    if (doc.model) {
      return id ? doc.model.getWorkbooks().find(wb => wb.id === id) : doc.model.getWorkbook();
    }
    return null;
  }

  onNewRoute = () => {
    this.setState({ isAddOrReplaceWorkbookModalOpen: true, isNewDocument: true });
    const { replace, asPath } = this.props.router;
    const url = query.remove(asPath, 'isNew');
    replace(url, undefined, { shallow: true });
  };

  onMessage = (e: MessageEvent) => {
    const data = e.data || {};
    const { model } =  this.props.doc;
    if (this.props.doc.features.can_use_embed_api) {
      this.embedAPI.handleRequest(data, model);
    }
  };

  onDocTrackEventEmit (type: string) {
    const docId = this.props.doc.id || '';
    this.onDocTrackEvent({
      isGRID: true,
      type: type,
      elementType: 'document',
      documentId: docId,
      elementId: docId,
      timestamp: Date.now(),
    });
  }

  onDocTrackEvent = (e: DocEmbedEvent) => {
    const doc = this.props.doc;
    // GRID embed events are a paid feature
    if (!(doc.features.can_see_embed_events || doc.features.can_use_embed_api)) {
      return;
    }
    // propigate the tracking event to a host document if there is one
    if (e.isGRID && (window.self !== window.top || getIsIntegrationTests())) {
      // we copy only the "approved" data here to ensure we're not accidentally
      // leaking anything to the host without intending to:
      // const eventData: Record<string, string | boolean> = {
      const eventData: DocEmbedEvent = {
        isGRID: true,
        type: String(e.type || ''),
        elementType: String(e.elementType || ''),
        documentId: String(e.documentId || ''),
        elementId: String(e.elementId || ''),
        elementLabel: String(e.elementLabel || ''),
        timestamp: e.timestamp,
      };
      if (e.submitData) {
        eventData.submitData = e.submitData;
      }
      if (e.queryData) {
        eventData.queryData = e.queryData;
      }
      if (e.targetUrl) {
        eventData.targetUrl = String(e.targetUrl);
      }
      window.parent.postMessage(eventData, '*');
    }
  };

  onDocInteraction = e => {
    EventStream.emit(EventStream.ELEMENT_SELECT, { id: e.id, data: e.attr, name: e.name, type: e.type });
  };

  onTrackWbStart = e => {
    // Start a new tracing transaction unconditionally here, i.e. don't try to participate in an existing
    // tracing transaction. This is because an existing transaction will be either:
    //
    // (a) a page load transaction managed by Sentry's BrowserTracing integration, which will be closed when
    // that integration considers the page load complete --- but at that time the loadSource work is _not_
    // complete (because it proceeds across React's async state reconciliation), so its tracing spans would
    // not be finished before the page load transaction is finished. Unfinished child spans get discarded
    // when a transaction is marked finished, so if the loadSource work is traced under the page load
    // transaction, then its tracing information it doesn't get sent at all.
    //
    // (b) a transaction that we would create when a source-updated message is received. That way we _would_
    // be in control of when the tracing transaction completes, but then we would be inconsistent about
    // whether loadSource is traced as its own transaction or as the child of the page load transaction.
    // Consistency is nice, and is to be preferred when there aren't good reasons not to.

    // XXX: should this really start a transaction even when not doing the rest of this?
    const tracingTransaction = Sentry.startTransaction({ name: 'Document.loadSource.then' });
    if (e.state === 'ready' || e.isStale) {
      // XXX: is this really needed?
      Sentry.withScope(() => {
        Sentry.getCurrentHub().configureScope(scope => {
          scope.setSpan(tracingTransaction);
        });
      });
    }
  };

  onTrackWbEnd = () => {
    Sentry.getCurrentHub().getScope()
      ?.getTransaction()
      ?.finish();
    Sentry.getCurrentHub().configureScope(scope => {
      scope.setSpan(undefined);
    });
  };

  onDocWbLinked = changes => {
    if (changes.addedId) {
      const stateUpdate = {
        pendingWorkbookIds: [ ...this.state.pendingWorkbookIds, changes.addedId ],
        workbookToSelect: changes.addedId,
      };

      this.setState(stateUpdate);
    }
  };

  // workbook did get attached to the model (even if that workbook is invalid)
  onDocWbAdded = (e: Readonly<{
    id: string | undefined,
    state: string | undefined,
    isStale: false | SheetCSF[],
    defect: string | undefined,
  }>) => {
    const newState: Partial<State> = {
      pendingWorkbookIds: this.state.pendingWorkbookIds.filter(id => id !== e.id),
    };
    let requestToOpenPanel = false;
    if (this.state.workbookToSelect === e.id) {
      requestToOpenPanel = true;
      newState.workbookToSelect = null;
      EventStream.emit(EventStream.SELECT_WORKBOOK, { workbookId: e.id, origin: 'dataPanel' });
    }
    if (e.state === 'invalid' && !e.isStale) {
      const defectError: { title: string, body: string } = SOURCE_ERRORS[e.defect];
      newState.error = defectError;
    }
    this.setState(newState as State, () => {
      if (requestToOpenPanel) {
        EventStream.emit(EventStream.OPEN_SHEET_PANEL);
      }
    });
  };

  // server did not send a workbook (500 error?)
  onDocWbFail = err => {
    const workbook = this.getWorkbook(err.id);
    const newState: Partial<State> = {
      pendingWorkbookIds: this.state.pendingWorkbookIds.filter(id => id !== err.id),
      workbookIdBeingReplaced: null,
    };
    if (!workbook || !workbook.state().defect || this.state.workbookIdBeingReplaced) {
      newState.error = SOURCE_ERRORS[err.defect];
    }
    this.setState(newState as State);
  };

  onModelReset = () => {
    if (this.props.embed) {
      // Embeded docs should restore to their altered state if they were embeded that way.
      if (this.props.initialModelState) {
        this.props.doc.model.writeMultiple(this.props.initialModelState, { forceRecalc: true });
      }
    }
    this.setState({ isModelReady: true });
    this.onDocTrackEventEmit('reset');
  };

  onModelReady = () => {
    this.setState({ isModelReady: true });
    this.onDocTrackEventEmit('ready');
  };

  onDocWbUpdate = e => {
    const model = this.props.doc.model;
    if (this.state.upload) {
      this.logUnsupportedFunctions(model);
    }

    const newState: Partial<State> = { upload: false };

    // keep workbookIdBeingReplaced state if modal is open (it may be true if we have just
    // returned from an add-cloud-drive flow while replacing). Else reset it to null.
    if (!this.state.isAddOrReplaceWorkbookModalOpen) {
      newState.workbookIdBeingReplaced = null;
    }

    if (!newState.error) {
      // XXX: maybe only do this when: wb.cloud_connection is there? (CLIENT-1388)
      if (e.defect) {
        newState.error = SOURCE_ERRORS[e.defect];
      }
    }

    const tracingTransaction = Sentry.getCurrentHub().getScope()
      ?.getTransaction();
    const setStateTracingSpan = tracingTransaction?.startChild({ op: 'Document.setState new model' });
    this.setState(newState as State, () => {
      setStateTracingSpan?.finish();
      this.onTrackWbEnd();
    });
  };

  onModelBeforeRecalc = () => {
    if (this.state.isModelReady) {
      this.onDocTrackEventEmit('beforeupdate');
    }
  };

  onModelRecalc = () => {
    const model = this.props.doc.model;
    if (this.props.onModelChange) {
      const modelState = model.writes();
      if (modelState.length) {
        // Manual span because it ends only after asynchronous UI work is done,
        // and that asynchronous UI work is not represented by a Promise (it is
        // React setState) so can't use Sentry.startSpan with an async callback.
        Sentry.startSpanManual({
          name: 'Document.props.onModelChange',
          op: 'ui.update',
          // Force this to be its own transaction, not a child span of the
          // Model.write span that we are in. That is necessary because
          // this.props.onModelChange will schedule UI work and return before
          // that work is performed. When that work completes, the Model.write
          // transaction will typically have ended and been sent, so if this
          // span were a child of it, it would be discarded (being incomplete).
          forceTransaction: true,
        }, span => {
          this.props.doc.modelState = modelState;
          this.props.onModelChange(modelState, () => span?.end());
        });
      }
      else {
        this.props.onModelChange(modelState);
      }
      EventStream.emit(EventStream.MODEL_RECALC);
    }
    if (this.state.isModelReady) {
      this.onDocTrackEventEmit('update');
    }

    const embedReads = this.embedAPI.getAndClearReads(model);
    if (embedReads.length) {
      this.onDocTrackEvent({
        isGRID: true,
        type: 'query',
        elementType: 'document',
        documentId: this.props.doc.id || '',
        elementId: this.props.doc.id || '',
        timestamp: Date.now(),
        queryData: embedReads,
      });
    }

    this.forceUpdate();
  };

  onFont = () => {
    // this triggers a re-render when fonts are loaded
    this.props.doc.model.triggerUpdate();
  };

  onAddWorkbook () {
    this.setState({ isAddOrReplaceWorkbookModalOpen: true, error: null });
  }

  onRemoveWorkbookConfirmed = async workbookBeingRemoved => {
    // FIXME: fire some event like this on workbook removal?
    // tracking.logEvent('Remove Workbook Invoked')
    await this.props.doc.detachSource(workbookBeingRemoved.id);
    this.forceUpdate();
  };

  onCloudConnection = cloudConnection => {
    const automaticRefresh = cloudConnection.automatic_refresh;

    if (automaticRefresh === 'delayed' || automaticRefresh === 'unavailable') {
      const errorObject = { ...CLOUD_DRIVE_AUTOMATIC_REFRESH_NOTICE[automaticRefresh] };
      this.setState({ error: errorObject });
    }
  };

  onReceiveWorkbook = (source: FileToImport, workbookIdBeingReplaced: string | null) => {
    const { doc, isOwner } = this.props;
    if (workbookIdBeingReplaced) {
      // CLICKUP-1443: the model is reset on all replace operations
      doc.model.reset();
    }
    this.setState({ isUploadingNewWb: !workbookIdBeingReplaced }, () => {
      processWorkbook(doc, source, workbookIdBeingReplaced)
        .then(data => {
          const cloudConnection = data.workbook?.cloud_connection;
          if (cloudConnection) {
            this.onCloudConnection(cloudConnection);
          }
          if (data.workbook?.type === 'native') {
            const hasOtherWorkbooksAttached = doc.model.getWorkbooks().length > 0;
            tracking.logEvent('GRID Workbook Added', {
              document_id: doc.id,
              workbook_id: data.workbook.id,
              has_other_workbooks_attached: hasOtherWorkbooksAttached,
              grid_workbook_added_from: hasOtherWorkbooksAttached ? 'Spreadsheet Ribbon' : 'Add Data Button', // XXX: when pasting into GRID documents automatically creates a GRID Sheet, then this needs to be updated.
              is_document_owner: isOwner,
              document_owner_id:  doc.creator?.id,
            });
          }
          // This does not fire when adding sample spreadsheet data.
          tracking.logAppcuesEvent('Document Data Added');
          this.setState({ isUploadingNewWb: false, isAddOrReplaceWorkbookModalOpen: false });
        })
        .catch((err: unknown) => {
          assert(err && typeof err === 'object'); // else not much we can do
          const status = 'status' in err && typeof err.status === 'number' ? err.status : null;
          const errorCode = 'error_code' in err && typeof err.error_code === 'string' ? err.error_code : null;
          const urlImportError = (
            source.type === 'url' && (status === 400 || status === 415 || errorCode === 'duplicate_names')
          );
          const newState: Partial<State> = {
            isUploadingNewWb: false,
            pendingWorkbookIds: this.state.pendingWorkbookIds.filter(id => id !== (err as { id: string }).id),
          };
          if (urlImportError || (errorCode === 'default' || !SOURCE_ERRORS[errorCode])) {
            Sentry.captureException(err);
          }
          if (urlImportError) {
            newState.addFlowError = err as { error_code: string };
          }
          else {
            newState.error = SOURCE_ERRORS[errorCode] || SOURCE_ERRORS.default;
          }
          this.setState(newState as State);
        });
    });
  };

  // this is a catch-all link handler for any clicks inside the doc
  // if a clicked link is trying to navigate to own doc then we
  // cancel the navigation and instead apply any state found in the href
  onClick = e => {
    const { doc } = this.props;
    // we must have a loaded doc & click must be from a link that has a href
    if (!e.defaultPrevented && e.target.nodeName === 'A' && e.target.href) {
      const link = parseHref(e.target.href);
      if (link.docId === doc.id) {
        // prevent navigation
        e.preventDefault();
        // apply state if it is present
        applyStateFromURL(doc.model, link.url);
      }
    }
  };

  onLoadScenario = (scenario?: string) => {
    const { doc } = this.props;
    const link = parseHref(`?s=${scenario}`);
    applyStateFromURL(doc.model, link.url);
  };

  private embedAPI: EmbedAPIManager;
  private _exitFn: typeof window.onbeforeunload | undefined;

  processSingleShotQueryParams () {
    const { router } = this.props;
    const query = router?.query;
    if (query.template) {
      // XXX: Talk to Hörður to see if this can be removed
      this.removeQueryParam('template');
    }
    else if (query.tutorial) {
      this.setState({ tutorial: query.tutorial.toString() as TutorialsType });
      this.removeQueryParam('tutorial');
    }
  }

  removeQueryParam (name) {
    const { router } = this.props;
    const url = query.remove(router.asPath, name);
    router.replace(url);
  }

  maybeReloadPrunedWorkbooks () {
    // Are workbook pruning conditions no longer satisfied?
    const { doc } = this.props;
    if (doc.allowWorkbookPruning && !this._pruningConditionsAreMet()) {
      // Disable pruning and reload workbooks
      doc.allowWorkbookPruning = false;
      this.setState({ isModelReady: false }, () => {
        doc.reloadAllSources();
      });
    }
  }

  showNewDocumentAnimation () {
    this.setState({ newDocument: true });
  }

  loadWorkbooks () {
    const { doc, router, embed } = this.props;

    if (router.query.auth_token) {
      doc.auth_token = router.query.auth_token.toString();
    }
    doc.initialModelState = this.props.initialModelState;
    doc.allowWorkbookPruning = this._pruningConditionsAreMet();
    doc.isEmbedded = embed;
    doc.useMonitoring();
    doc.on('modelrecalc', this.onModelRecalc);
    doc.on('modelbeforerecalc', this.onModelBeforeRecalc);
    doc.on('modelreset', this.onModelReset);
    doc.on('modelready', this.onModelReady);
    doc.on('wbattached', this.onDocWbAdded);
    doc.on('wbloaded', this.onTrackWbStart);
    doc.on('wbupdate', this.onDocWbUpdate);
    doc.on('wbfail', this.onDocWbFail);
    doc.on('wbrenamed', this.relayWbrenamedEvent);
    doc.on('wbupdateerror', this.onDocWbFail);
    doc.on('linkwb', this.onDocWbLinked);
    doc.on('interaction', this.onDocInteraction);
    doc.on('track', this.onDocTrackEvent);
    doc.on('warning', this.captureErrorToSentry);
    doc.on('boundarycatch', this.captureErrorToSentry);

    doc.initializeModel(this.props.mode);
  }

  relayWbrenamedEvent (e: import('@/editor/EditorEventStream').Events['ON_DOCUMENT_WORKBOOK_RENAMED']) {
    EventStream.emit(EventStream.ON_DOCUMENT_WORKBOOK_RENAMED, e);
  }

  captureErrorToSentry (e) {
    Sentry.captureMessage(e.message);
    if (process.env.NODE_ENV !== 'production') {
      console.warn(e.message);
    }
  }

  _pruningConditionsAreMet () {
    const { doc, mode } = this.props;
    const inViewMode = mode === VIEW;
    const featureFlagIsEnabled = getConfig()?.PRUNE_WORKBOOKS === true;
    const documentHasSingleWorkbook = doc.sources && doc.sources.length === 1;  // pruning logic doesn't support multiple workbooks yet
    return inViewMode && documentHasSingleWorkbook && featureFlagIsEnabled;
  }

  promptUnsavedChanges () {
    // A hack to override intercept the client side routing
    // https://github.com/vercel/next.js/issues/2476#issuecomment-612483261
    assert(SingletonRouter.router);
    // @ts-expect-error
    SingletonRouter.router.change = (...args) => {
      // @ts-expect-error
      const change = Router.prototype.change;
      const navigateNormally = () => change.apply(SingletonRouter.router, args);
      if (args[0] === 'replaceState') {
        // replace state is being used to trigger an URL change when the document title is changed.
        // we should ignore those changes and allow the router to navigate normally.
        return navigateNormally();
      }
      if (!isUnsaved(this.props.saveState)) {
        return navigateNormally();
      }
      if (confirm(exitWarnMessage)) {
        return navigateNormally();
      }
      else {
        return Promise.resolve(false);
      }
    };
  }

  logUnsupportedFunctions = model => {
    const unsupported = new Set();
    model.errors && model.errors.forEach(error => {
      if ((error.type === 'fn' || error.type === 'fn-unsup') && error.message) {
        // The function name is the first word in the error message
        const functionName = error.message.trim().split(' ')[0];
        unsupported.add(functionName);
      }
      else if (error.type === 'arrformula') {
        unsupported.add('Array Formula');
      }
      else if (error.type === 'spill') {
        unsupported.add('Spill Operator');
      }
      else if (error.type === 'tbl-unsup') {
        unsupported.add('Structured Reference');
      }
    });
    if (unsupported.size > 0) {
      const workbookId = model.getWorkbooks()[0] && model.getWorkbooks()[0].id;
      tracking.logEvent('UnsupportedFunction Encountered', {
        functions: Array.from(unsupported),
        workbook_id: workbookId,
      });
    }
  };

  /**
   * Returns a Slate DOM generated from an error. This can then be passed to
   * the document renderer instead of the actual document.
   */
  makeDocFromError (error) {
    const b = (t: string, c: any) => ({ id: getID(), type: t, object: 'block', data: {}, children: [ c ] });
    const t = (t: string) => ({ object: 'text', id: getID(), text: t });
    const errorDoc = [
      b('h1', t(error.title || GENERIC_DOCUMENT_ERROR.title)),
      b('p', typeof error.body === 'string' ? t(error.body) : t(GENERIC_DOCUMENT_ERROR.body)),
      b('p', typeof error.footer === 'string' ? t(error.footer) : t('')),
    ];
    return errorDoc;
  }

  renderErrorModal () {
    const { error } = this.state;
    const onClose = () => {
      this.setState({ error: null });
      error?.onClose?.();
    };
    return (
      <Modal
        id="error-spreadsheet-modal"
        open={!!error}
        closeButton={false}
        header={error ? error.title : ''}
        size="small"
        footer={
          <div className={styles.buttons}>
            <Button
              buttonType="primary"
              onClick={onClose}
              >I understand
            </Button>
          </div>}
        >
        <div>
          {error && error.body}
          {error && error.footer &&
            <p>{error.footer}</p>
          }
        </div>
      </Modal>
    );
  }

  renderDocument () {
    const {
      closeFullScreen,
      doc,
      embed,
      chromeless,
      useFullWidth,
      fullscreen,
      mode,
      onUpdateDocument,
      isOwner,
    } = this.props;

    const { isAddOrReplaceWorkbookModalOpen, workbookIdBeingReplaced, pendingWorkbookIds, isUploadingNewWb, tutorial, addFlowError } = this.state;
    let dom = doc.toSlateDom();
    if (mode !== EDIT && this.state.error) {
      // If there is an error, we generate an alternative dom from the error to render.
      // Edit mode handles this differently (with a modal) as authors need to be able to
      // resolve issues. For everything else we use this as modals don't do great in embeds.
      dom = this.makeDocFromError(this.state.error);
    }
    // these global properties will be applied to all elements
    const globalProps = doc.getGlobalProps();

    if (fullscreen) {
      return (
        <Fullscreen
          document={dom}
          theme={doc.theme}
          chartTheme={doc.chartTheme}
          globalProps={globalProps}
          model={doc.model}
          onClose={closeFullScreen}
          />
      );
    }
    else if (embed) {
      return (
        <GridDocument
          document={dom}
          theme={doc.theme}
          chartTheme={doc.chartTheme}
          globalProps={globalProps}
          model={doc.model}
          useFullWidth={useFullWidth}
          embed
          />
      );
    }
    if (!doc.id) {
      // XXX: render an indicator
      return null;
    }
    if (mode === EDIT) {
      return (
        <>
          <PasteTabularDataToGridSheets doc={doc} globalProps={globalProps} />
          <ElementFocusManager />
          <EditableDocument
            doc={doc}
            initialValue={dom}
            docWidth={getDocumentActiveWidth(dom, doc.model, true)}
            globalProps={globalProps}
            onChange={onUpdateDocument}
            onClickFullscreenButton={this.props.onClickFullscreenButton}
            hasAlteredState={this.props.hasAlteredState}
            onGlobalPropChange={props => doc.setGlobalProps(props)}
            onDetails={workbookId => this.setState({ showDetailsForWorkbookId: workbookId })}
            onAddWorkbook={() => this.onAddWorkbook()}
            onRemoveWorkbook={this.onRemoveWorkbookConfirmed}
            onReplaceWorkbook={wb => {
              this.setState({
                isAddOrReplaceWorkbookModalOpen: true,
                workbookIdBeingReplaced: wb.id,
              });
            }}
            onSyncWorkbook={async wb => {
              // the model is reset when sync is clicked [CLICKUP-1443]
              doc.model.reset();
              // track the action
              tracking.logEvent('Workbook Synchronized Manually', {
                workbook_id: wb.id,
                file_extension: '.' + fileExtension(wb.filename),
                provider: wb.cloud_connection?.cloud_drive_provider ?? 'unknown',
              });
              // signal to UI that changes are inbound
              this.setState(prevState => ({
                pendingWorkbookIds: [ ...prevState.pendingWorkbookIds, wb.id ],
              }));
              // request a sync from the server
              try {
                await refresh(wb.id);
              }
              catch (err: unknown) {
                assert(err && typeof err === 'object');
                const errorCode = 'error_code' in err && typeof err.error_code === 'string' ? err.error_code : null;
                // fatal error server-size: so remove the wb from inbound and alert user
                this.setState(prevState => ({
                  pendingWorkbookIds: prevState.pendingWorkbookIds.filter(d => d !== wb.id),
                  error: (
                    CLOUD_DRIVE_SYNC_ERRORS[errorCode] || CLOUD_DRIVE_SYNC_ERRORS.default
                  ),
                }));
              }
            }}
            pendingWorkbookIds={isUploadingNewWb ? [ ...pendingWorkbookIds, DUMMY_UPLOAD_ID ] : pendingWorkbookIds}
            onCopyEmbedUrl={shareInOriginalState => doc.getEmbedUrl(shareInOriginalState)}
            isOwner={isOwner}
            tutorial={tutorial}
            />
          <ReplacingWorkbookContextProvider value={workbookIdBeingReplaced}>
            {isAddOrReplaceWorkbookModalOpen &&
              <AddWorkbook
                documentId={doc.id}
                workbooks={doc.model.getWorkbooks()}
                onClose={() => {
                  this.setState({
                    error: null,
                    workbookIdBeingReplaced: null,
                    isAddOrReplaceWorkbookModalOpen: false,
                    isNewDocument: false,
                  });
                  EventStream.emit(EventStream.FOCUS_EDITOR);
                }}
                onSource={source => {
                  this.setState({ upload: true, error: null }, () => {
                    this.onReceiveWorkbook(source, workbookIdBeingReplaced);
                  });
                }}
                addFlowError={addFlowError}
                isNewDocument={!!this.state.isNewDocument}
                />}
          </ReplacingWorkbookContextProvider>
          {this.renderErrorModal()}
        </>
      );
    }
    else {
      return (
        <MarginManager
          className={styles.documentWrapper}
          width={getDocumentActiveWidth(dom, doc.model)}
          >
          <ElementFocusManager />
          <GridDocument
            document={dom}
            theme={doc.theme}
            chartTheme={doc.chartTheme}
            globalProps={globalProps}
            model={doc.model}
            />
          {!chromeless &&
            <HelpCenter
              documentId={doc.id}
              isOwner={isOwner}
              />}
        </MarginManager>
      );
    }
  }

  renderDetails () {
    const { doc } = this.props;
    const wb = this.state.showDetailsForWorkbookId ? this.getWorkbook(this.state.showDetailsForWorkbookId) : null;
    if (!doc || !wb) {
      return;
    }
    return (
      <Modal
        open
        onClose={() => this.setState({ showDetailsForWorkbookId: null })}
        header={wb.name}
        >
        <WorkbookInfo
          workbook={wb}
          documentId={doc.id || ''}
          />
      </Modal>
    );
  }

  render () {
    const { doc, useFullWidth, alignCenter, customPadding, ready, embed, shouldServerSideRender } = this.props;
    const { tutorial } = this.state;
    const isWorkbookReadyToRender = this.state.isModelReady;
    const isReady = ready && (isWorkbookReadyToRender || shouldServerSideRender);
    const cls = isReady ? csx('grid-doc', embed && 'embed') : csx(styles.loading, embed && styles.embed);
    const docTop = getElementTop('grid-doc');
    return (
      <>
        {this.renderDetails()}
        <div
          id="grid-doc"
          className={cls}
          style={{
            ...(embed && !useFullWidth && { maxWidth: DOCUMENT_SIZE }),
            ...(embed && alignCenter && { margin: '0 auto' }),
            ...(embed && customPadding && { padding: customPadding }),
            ...(!embed && { minHeight: `calc(100vh - ${docTop}px - 48px)` }),
          }}
          onClick={this.onClick}
          >
          {isReady ? (
            this.renderDocument()
          ) : (
            <LoadingSpinner color={doc ? doc.theme.vars['--color-70'] : null} size={8} centered />
          )}
        </div>
        {tutorial && <DocumentTutorial tutorialName={tutorial} doc={doc} onExit={() => this.setState({ tutorial: undefined })} />}
      </>
    );
  }
}

export const Document = withRouter(DocumentInternal);
