import { ERROR_NAME } from './excel/constants';
import Reference from './excel/Reference';
import ModelError from './ModelError';
import EvaluationOrderException from './EvaluationOrderException';
import { a1ToRowColumnOrNull } from './excel/referenceParser/a1.js';
import { invariant } from './validation';
import { isNum } from './utils';
import {
  CellVertexId,
  cellVertexIdKey,
  NameVertexId,
  nameVertexIdKey,
  RangeVertexId,
  type KnownVertexId,
  type Vertex,
  type VertexId,
} from './DependencyGraph';
import {
  cellToVertexId,
  referenceToVertexId,
  vertexIdToCell,
  vertexIdToReference,
  visitFormulaCellsDependingOnRange,
  formulaCellsForVertexId,
  getFormulaCellsInDependencies,
} from './dep-graph-helpers';
import { NameNotFoundError, SheetNotFoundError, WorkbookNotFoundError } from './errors';
import { isNonLiteralNode } from './excel/ast';
import { LinkedList } from '@grid-is/collections';
import type Cell from './excel/Cell';
import type { FormulaValue } from './excel/types';
import type Model from './Model';
import type WorkSheet from './WorkSheet';
import type Workbook from './Workbook';

export const NUM_STATES = 5;

export const CHANGED_OR_VOLATILE = Symbol('CHANGED_OR_VOLATILE');
export const ALL_FORMULA_CELLS = Symbol('ALL_FORMULA_CELLS');
export const CHANGED_ONLY = Symbol('CHANGED_ONLY');

/**
 * Linked-list element representing the path from recalculation root to a cell
 */
type CellPath = { parentPath: CellPath | null, cell: Cell };

type DependencyCycleKind = 'static' | 'reordering' | 'propagation';

type DependencyCycle = {
  cells: Cell[],
  kind: DependencyCycleKind,
};

/**
 * Class holding state which is used during a recalculation pass, then
 * discarded. Thus "transient".
 */
export class RecalcTransientState {
  DIRTY: number;
  ENQUEUEING: number;
  ENQUEUED_MAYBE: number;
  ENQUEUED_CALC: number;
  NEWLY_UPTODATE: number;
  rootPaths = new Map<Cell, CellPath>();
  iterativeCalculationState: null | {
    cells: Set<Cell>,
    iterate: boolean,
    maxIterations: number,
    maxChange: number,
  };

  recalculationRootCells = new Set<Cell>();
  cellsNotToRecalculate = new Set<Cell>();
  cellsChangedSincePreviousRecalc = new Set<Cell>();
  cellsEvaluated = new Set<string>();
  formulaCellsUpdated = new Map<Cell, FormulaValue>();
  totalFormulaCellUpdates = 0;
  rangesUpdated: Reference[] = [];
  cycles = new Map<Cell, Array<DependencyCycle>>();
  cycleCells = new Set<Cell>();
  cellsWithValueEdits = new Set<Cell>();
  changedCellsChangedRefs: Reference[] = [];
  cellsThatHaveRedoPropagatedTo = new Map<string, Set<string>>();

  /**
   * A record of queue reorderings, where a dependency cell D is injected
   * in front of a cell C in the queue upon evaluating C and catching an
   * EvaluationOrderException.
   *
   * Each key is the cell C whose evaluation called for the reordering.
   * Each value is a Map in which:
   * * the key is the dependency cell D
   * * the value is the value of `this.totalFormulaCellUpdates` at the time of
   *   the last reordering of D before C.
   *
   * A repeated reordering of D before C is reliably a circular dependency
   * only if `this.totalFormulaCellUpdates` is unchanged since the previous
   * reordering, i.e. no formula cell updates have occurred inbetween. Else
   * recalculation might still be propagating towards D, so it may yet update
   * before C and without requiring the value of C.
   */
  cellReorderings = new Map<Cell, Map<Cell, number>>();

  /**
   * In each entry:
   * * the key is the concatenation of the vertex ID key of the from-vertex
   *   and to-vertex of an edge in the dependency graph
   * * the value is a vertex ID representing the reference actually used.
   * This is used to determine, upon spilling into a cell/range conditionally
   * depended on by a cell/defined-name that is already up-to-date in this
   * recalculation, whether the cell/defined-name needs to be redone in this
   * recalculation because we updated a cell it depends on, after the fact.
   */
  dependenciesUsed = new Map<string, KnownVertexId[]>();

  memoizedRangeFormulaCells = new Map<string, Cell[]>();

  /**
   * Cells (defined names) whose existing value is not known, but is believed
   * to be unchanged from when their dependents were last recalculated. Thus,
   * for a cell in this set:
   * (1) its value is calculated if/when it turns out that it is going to be
   *     needed (because a dependent is enqueued)
   * (2) calculating its value does not cause recalculation to propagate to
   *     its dependents (because they are already up-to-date with it)
   * But if recalculation propagates _to_ an on-demand cell, it ceases to be
   * an on-demand cell, and thus undergoes normal enqueueing, recalculation,
   * and propagation to its dependents.
   */
  onDemandCells = new Set<Cell>();

  constructor (
    upToDateState: number,
    iterativeCalculation: { iterate: boolean, maxIterations: number, maxChange: number },
  ) {
    this.DIRTY = upToDateState + 1;
    this.ENQUEUEING = upToDateState + 2;
    this.ENQUEUED_MAYBE = upToDateState + 3;
    this.ENQUEUED_CALC = upToDateState + 4;
    this.NEWLY_UPTODATE = upToDateState + NUM_STATES;

    this.iterativeCalculationState = iterativeCalculation.iterate
      ? {
        ...iterativeCalculation,
        cells: new Set(),
      }
      : null;
  }

  recordRootPath (cell: Cell, path: CellPath | null) {
    if (!path) {
      return;
    }
    this.rootPaths.set(cell, path);
  }

  /**
   * @param revertValue previous value to store
   *   for this cell (if not spilled); used for reverting if we discover a
   *   circular dependency at this cell after updating it.
   */
  recordFormulaCellUpdates (cell: Cell, revertValue: FormulaValue, changedRefs: Reference[], model: Model) {
    let sheet: WorkSheet | null | undefined = null;
    this.totalFormulaCellUpdates += 1;
    for (const ref of changedRefs) {
      if (ref.name) {
        if (!this.formulaCellsUpdated.has(cell)) {
          this.formulaCellsUpdated.set(cell, revertValue);
        }
      }
      else {
        if (sheet == null) {
          sheet = model.getWorkbookByKey(cell.workbookKey)?.getSheetByIndex(cell.sheetIndex);
          invariant(sheet);
        }
        const anchorCell = sheet.getCellByRange(ref.range!);
        // There might not be a formula cell at the top-left coordinates of `ref`.
        // This happens when a spill is resized such that the old spill does not
        // contain the new spill nor vice versa. For example, suppose the spill of
        // B2 changes from B2:E3 to B2:D4. Then we will get two non-overlapping
        // `changedRefs`, B2:D3 and B4:D4, and B4 will not be a formula cell. So
        // we don't record B4 as an updated formula cell, but do record B4:D4 as
        // being an updated range.
        if (anchorCell?.f) {
          const revertValueToStore = anchorCell === cell && !cell.isSpillAnchor() ? revertValue : null;
          if (!this.formulaCellsUpdated.has(anchorCell)) {
            this.formulaCellsUpdated.set(anchorCell, revertValueToStore);
          }
        }
        if (ref.size! > 1) {
          this.rangesUpdated.push(ref);
        }
      }
    }
  }

  /**
   * @param cycle should not be empty
   */
  recordCycle (cycle: Cell[], kind: DependencyCycleKind) {
    const firstCell = cycle[0];
    if (!this.cycles.has(firstCell)) {
      this.cycles.set(firstCell, []);
    }
    const cyclesStartingAtThisCell = this.cycles.get(firstCell);
    invariant(cyclesStartingAtThisCell);
    const wasCycleAlreadyRecorded = cyclesStartingAtThisCell.some(
      existingRecord =>
        existingRecord.cells.length === cycle.length &&
        existingRecord.cells.every((cell, index) => cell === cycle[index]),
    );
    if (!wasCycleAlreadyRecorded) {
      cyclesStartingAtThisCell.push({ cells: cycle, kind });
      for (const cycleCell of cycle) {
        this.cycleCells.add(cycleCell);
      }
      // Return its value before this recalculation, if known, to revert to it.
      return this.formulaCellsUpdated.get(firstCell);
    }
  }
}

/**
 * Class holding state which is used for recalculation, and preserved between
 * recalculation passes.
 */
export class RecalcState {
  /**
   * References to cell ranges whose values have been _transiently changed_
   * (generally via `Model.write` or `Model.writeMultiple`), meaning that the
   * change and any downstream changes resulting from it should be reverted by
   * `Model.reset`.
   */
  writtenSinceRecalc: KnownVertexId[] = [];

  /**
   * References to cell ranges whose formulas have been _persistently changed_
   * (via edit operations like `Workbook.writeCellData`, `Workbook.moveCells`,
   * `Workbook.setRowHeight` etc.), meaning that the change and any downstream
   * changes resulting from it should _not_ be reverted by `Model.reset`, and
   * thus should be captured in the next GRID Sheets save operation. This
   * means recalculating (and persisting the recalculation consequences of)
   * any formula cells in the range, spilling into the range, or depending on
   * cells in the range.
   */
  changedSinceRecalc: KnownVertexId[] = [];

  /**
   * Cells edited with changes to v and/or z (stored so that we take care to
   * propagate recalculation from them despite not having a formula)
   */
  valueEdited: CellVertexId[] = [];

  /**
   * References to cell ranges that `setData` deemed in need of recalculation
   */
  needsInitRecalc: (CellVertexId | NameVertexId)[] = [];

  namesAwaitingRecalc: NameVertexId[] = [];
  upToDateState = 0;
  lastRecalc: RecalcTransientState | null = null;

  removeWorkbook (workbookKey: number) {
    this.writtenSinceRecalc = this.writtenSinceRecalc.filter(vid => vid.workbookKey !== workbookKey);
    this.changedSinceRecalc = this.changedSinceRecalc.filter(vid => vid.workbookKey !== workbookKey);
    this.valueEdited = this.valueEdited.filter(vid => vid.workbookKey !== workbookKey);
    this.needsInitRecalc = this.needsInitRecalc.filter(vid => vid.workbookKey !== workbookKey);
    this.namesAwaitingRecalc = this.namesAwaitingRecalc.filter(vid => vid.workbookKey !== workbookKey);
  }
}

/**
 * Mark all cells that depend directly or (by recursion) indirectly on the given cell, as DIRTY.
 */
function markDependentsDirty (model: Model, ts: RecalcTransientState, dirtyCell: Cell) {
  // Loop-recurse depth-first, maintaining paused outer iterators on a stack.
  // This is done with a loop rather than recursive calls, because it:
  // 1. Does not fail with a “Maximum call stack size exceeded” error on very
  //    deep dependency graphs.
  // 2. Is faster (by about 40-50% in ad-hoc tests), by replacing function
  //    call overhead with direct array stack.
  // 3. Doesn't confuse profiling (profilers only store max N frames per
  //    sample; exceed that and stuff gets weird).
  type DependencyCellIterator = Iterator<Vertex<Cell, CellVertexId | NameVertexId, CellVertexId | NameVertexId>>;
  const recursionStack: Array<DependencyCellIterator> = [];
  const vertexId = cellToVertexId(dirtyCell);
  let currentDependentsIterator = model._graph.getIncomingVertices(vertexId)[Symbol.iterator]();
  let nextResult = currentDependentsIterator.next();
  while (!nextResult.done || recursionStack.length) {
    if (nextResult.done) {
      // Done with current iterator and not top level of recursion, so pop back
      // up a level of the recursion.
      //
      // @ts-expect-error We know from the loop condition that recursionStack is not
      // empty, so this will be an iterator.
      currentDependentsIterator = recursionStack.pop();
      nextResult = currentDependentsIterator.next();
    }
    else {
      const dependentVertex = nextResult.value;
      const dependentCell = dependentVertex.data;
      if (dependentCell && dependentCell.state < ts.DIRTY) {
        // The above condition, along with the assignment of DIRTY before recursing,
        // prevents infinite recursion even if there are circular dependencies
        dependentCell.state = ts.DIRTY;
        // Recurse depth-first to cell's dependents.
        recursionStack.push(currentDependentsIterator);
        currentDependentsIterator = model._graph.getIncomingVertices(dependentVertex.id)[Symbol.iterator]();
      }
      nextResult = currentDependentsIterator.next();
    }
  }
}

/**
 * Mark the given cell _and_ all cells depending directly or indirectly on it
 * as DIRTY.
 */
function maybeMarkDirty (model: Model, ts: RecalcTransientState, dirtyCell: Cell) {
  if (dirtyCell.state < ts.DIRTY) {
    dirtyCell.state = ts.DIRTY;
    markDependentsDirty(model, ts, dirtyCell);
  }
}

/**
 * Get the workbook with a given key, in a context where we are quite sure it
 * should be there (so this throws an AssertionError if that assumption fails).
 * @throws {AssertionError} if not found
 */
function workbookFromKey (model: Model, key: number): Workbook {
  const wb = model.getWorkbookByKey(key);
  invariant(wb != null, 'gaaa, workbook key ' + key + ' not found');
  return wb;
}

/**
 * Normal recalculation; the recalculation roots are:
 * - Any cells in `s.namesAwaitingRecalc`
 * - Any cells depending directly on cells that have been changed since the
 *   recalculation.
 * - All volatile cells that have _not_ been written (because the writes
 *   override their volatile formulas).
 * - Any cells in the `s.needsInitRecalc` list.
 */
function changedOrVolatilePrep (model: Model, s: RecalcState, ts: RecalcTransientState, skipVolatiles: boolean) {
  /**
   * This set may contain null; that is simply ignored. It may also contain
   * non-formula cells; those _are_ enqueued as recalculation roots because they
   * were previously formula cells and so need to be calculated to determine the
   * spilling effects of their change to value (non-formula) cells.
   * @type {Set<Cell | null>}
   */
  const awaitingRecalc: Set<Cell | null> = new Set(
    skipVolatiles
      ? []
      : model.volatiles.filter(vid => !hasWrite(model, vid)).map(vertexId => vertexIdToCell(model, vertexId)),
  );

  for (const vertexId of s.valueEdited) {
    const cell = vertexIdToCell(model, vertexId);
    if (cell) {
      ts.cellsWithValueEdits.add(cell);
    }
  }

  /**
   * This list contains vertex IDs of cells and ranges which may have changed
   * value since the last recalculation, so all formula cells depending on them
   * must be enqueued as recalculation roots.
   */
  const valueChangedVertexIDs = [ ...s.writtenSinceRecalc ];

  for (const vertexId of [ ...s.changedSinceRecalc, ...s.needsInitRecalc ]) {
    const changedRef = vertexIdToReference(model, vertexId);
    invariant(changedRef);
    ts.changedCellsChangedRefs.push(changedRef);
    if (vertexId instanceof RangeVertexId) {
      // recalculate each formula cell in the range
      for (const formulaCell of formulaCellsForVertexId(ts.memoizedRangeFormulaCells, model, vertexId)) {
        if (
          !formulaCell._spill ||
          !formulaCell._spill.valid ||
          formulaCell._spill.masked ||
          (formulaCell._spill.minY >= vertexId.top && formulaCell._spill.minX >= vertexId.left)
        ) {
          awaitingRecalc.add(formulaCell);
          ts.cellsChangedSincePreviousRecalc.add(formulaCell);
        }
      }
      // Record the whole range as value-changed even if formula cells are also
      // in awaitingRecalc, because that redundancy causes ≈no additional work,
      // and the alternative of treating each non-formula cell individually as
      // value-changed _would_ be more work
      valueChangedVertexIDs.push(vertexId);
      // For each formula cell that references this range, note that any change
      // in it, and any changes resulting from that change, should be considered
      // persistent (should update reset state). This can matter e.g. when a row
      // is hidden/unhidden with setRowHeight, which may affect SUBTOTAL calls
      // that reference it.
      // (Don't need to add these to `awaitingRecalc`, because adding `vertexId`
      // to `valueChangedVertexIDs` above already makes them get recalculated.)
      visitFormulaCellsDependingOnRange(model, vertexId, formulaCell => {
        ts.cellsChangedSincePreviousRecalc.add(formulaCell);
      });
    }
    else {
      const changedCell = vertexIdToCell(model, vertexId);
      // Recalculate if cell exists and is not spilled from some other cell ...
      // the latter comes up in at least one known case: when a range has been
      // moved that overlaps a spill range but does not include its anchor.
      if (changedCell != null && (!changedCell.isSpilled() || changedCell.isSpillAnchor())) {
        awaitingRecalc.add(changedCell);
        ts.cellsChangedSincePreviousRecalc.add(changedCell);
      }
      else {
        // No cell there now so it was removed. Still a value change (to BLANK)
        // and that may impact cells referencing it.
        valueChangedVertexIDs.push(vertexId);
      }
    }
  }

  for (const vertexId of valueChangedVertexIDs) {
    let writtenCell: Cell | null = null;

    if (vertexId instanceof CellVertexId || vertexId instanceof NameVertexId) {
      writtenCell = vertexIdToCell(model, vertexId);
      if (writtenCell && writtenCell.f && writtenCell.v == null) {
        // The modified cell has a formula and no value, so we consider it to
        // be the recalculation root.
        maybeMarkDirty(model, ts, writtenCell);
        ts.recalculationRootCells.add(writtenCell);
        continue;
      }
    }

    // Direct dependents are the recalculation roots.
    model._graph.visitIncomingVertices(vertexId, inVertex => {
      const dependentCell = inVertex.data;
      invariant(dependentCell != null);
      if (!dependentCell.f) {
        invariant(dependentCell.neutralizedFormula != null);
        // Not a formula cell, despite being a dependent according to the graph.
        // Happens when a formula cell is neutralized by a range write. So skip.
        return;
      }
      maybeMarkDirty(model, ts, dependentCell);
      ts.recalculationRootCells.add(dependentCell);
      if (writtenCell) {
        ts.recordRootPath(dependentCell, { parentPath: null, cell: writtenCell });
      }
    });
  }

  for (const cellToCalculate of awaitingRecalc) {
    if (!cellToCalculate) {
      continue;
    }
    maybeMarkDirty(model, ts, cellToCalculate);
    ts.recalculationRootCells.add(cellToCalculate);
  }

  for (const nameVertexId of s.namesAwaitingRecalc) {
    const nameCell = vertexIdToCell(model, nameVertexId);
    if (nameCell == null) {
      console.warn('namesAwaitingRecalc includes vertexID not present in the model: ', nameVertexId);
      continue;
    }
    if (!ts.recalculationRootCells.has(nameCell)) {
      // just mark the defined-name itself as dirty; its dependents are assumed to
      // be consistent with its (unknown) value, so we just need to recalculate it
      // in order to use it in any cells which do need to be updated, and depend
      // on it.
      nameCell.state = ts.DIRTY;
      ts.onDemandCells.add(nameCell);
    }
  }
}

function hasWrite (model: Model, vid: CellVertexId | NameVertexId) {
  return model.getWorkbookByKey(vid.workbookKey)?.hasWrite(vid);
}

function allFormulaCellsPrep (model: Model, ts: RecalcTransientState) {
  for (const wb of model._workbooks) {
    for (const formulaCell of wb.iterFormulaCells()) {
      maybeMarkDirty(model, ts, formulaCell);
      ts.recalculationRootCells.add(formulaCell);
    }
  }
}

/**
 * Stack frame in the non-call-stack recursion on dependencies when enqueueing.
 */
type RecursionFrame = {
  cell: Cell,
  dependencyIterator: IterableIterator<{ dependencyCell: Cell, conditional: boolean }> | null,
  first: boolean,
  conditional: boolean,
};

/**
 * Mark `startCell` ENQUEUED_CALC (or ENQUEUED_MAYBE, if `maybe`) and add it to
 * `queue` if not already in it. But first recurse into its dependencies and
 * enqueue (in state ENQUEUED_MAYBE) any that are DIRTY — including conditional
 * dependencies, except those that appear in `noCond`.
 *
 * Resolve any unconditional static dependency cycles as follows:
 *
 * - if iterative calculation is disabled, record the cycle and move the cell in
 *   which it is discovered to state NEWLY_UPTODATE. If that cell is blank, give
 *   it the value 0 (sheet cell) or #NAME? (defined name).
 *
 * - if iterative calculation is enabled, defer the cells of the cycle to the
 *   iterative phase.
 *
 * Ignore any _conditional_ static dependency cycles, and simply don't recurse
 * into dependencies of the discovery cell. Later on, recalculation may or may
 * not propagate to a cell in the cycle, and all the way around it. If it does,
 * then a 'propagation' cycle will be recorded at that point. Else the cycle is
 * inactive in this recalculation. Either way, it is out of scope here.
 *
 * The inclusion of conditional dependencies is to order the entire static
 * dependency closure of `startCell` ahead of it in dependency order, so that
 * cells to which recalculation _does_ end up propagating are already in the
 * queue in the correct order, without costing us much in the case that
 * recalculation _doesn't_ propagate to them (then they're still ENQUEUED_MAYBE
 * and will just be skipped over in the main recalculation loop).
 *
 * The `noCond` exclusion is used in the case of queue reordering, when formula
 * evaluation turns out to require a cell to be up-to-date that isn't (because
 * of a dependency not accounted for in the static dependency graph, e.g. via
 * OFFSET or INDIRECT, or because of a conditional dependency whose condition
 * turns out to be true in this recalculation). The exclusion serves to avoid:
 *
 * - repeating a reordering that has was already done with no cells having
 *   updated since,
 *
 * - reversing a reordering that was done earlier in this recalculation.
 *
 * ... because these reorderings will lead to significant repeated work and/or
 * false circular dependency detection. A case where this is known to happen is
 * where:
 *
 * - A has an _active_ conditional dependency on B and C
 * - and B and C have an _inactive_ conditional dependency on A
 * - and B and C both require calculation for some other reason not involving A
 *
 * Avoiding these reorderings is correct because if a skipped reordering of B
 * and C in front of A does in fact cause A to be calculated with non-up-to-date
 * values of B and C, i.e. if they do change later in the recalculation, then
 * that will be corrected by redo propagation back to A, and if the circular
 * dependency is real, we will discover it then. But that's the rare case, and
 * the common case is that some conditional dependency along the chain _wasn't_
 * active, or some cell along the propagation chain didn't change value. Either
 * way, we get the right result, and don't get false-positive dependency cycles.
 *
 * @param startCell the cell object identified by `cellID`, already resolved
 * @param startPath the path from recalculation roots that led to this cell being enqueued
 * @param [maybe=false] true if the cell is not _known_ to require
 *   recalculation (to be used when enqueueing a dirty dynamic dependency)
 * @param [noCond=null] cells to ignore if they occur as conditional dependencies.
 */
function enqueueCellPrecededByDirtyDependencies (
  model: Model,
  ts: RecalcTransientState,
  startCell: Cell,
  startPath: CellPath | null,
  queue: LinkedList<Cell>,
  maybe: boolean = false,
  noCond: Set<Cell> | null = null,
) {
  if (startCell.state === ts.ENQUEUED_MAYBE) {
    // Previously enqueued to _maybe_ get recalculated, as a dependency of some
    // other cell being enqueued. All static dependencies of this cell will have
    // also been enqueued at that time. Now this cell is to be enqueued, either:
    // (a) just because it is a dirty dynamic dependency of some cell being
    //     evaluated, so then it should stay MAYBE and we don't do anything
    // (b) or because some dependency of it got updated So just note which cell
    //     that was, that propagated to this one, and move this to ENQUEUE_CALC.
    if (!maybe) {
      ts.recordRootPath(startCell, startPath);
      startCell.state = ts.ENQUEUED_CALC;
    }
    return;
  }
  // If cell was on-demand, then now it isn't, because change propagated to it.
  ts.onDemandCells.delete(startCell);
  /**
   * @type {RecursionFrame[]}
   */
  const recursionStack: RecursionFrame[] = [
    {
      cell: startCell,
      dependencyIterator: null,
      first: true,
      conditional: false,
    },
  ];
  const iterCalcCells = ts.iterativeCalculationState?.cells; // truthy if-and-only-if iterative calculation is enabled
  /** @type {RecursionFrame | undefined} */
  let frame: RecursionFrame | undefined;
  while ((frame = recursionStack.pop()) != null) {
    const currentCell = frame.cell;
    /** @type {Cell | null} */
    let foundUpdatedDependency: Cell | null = null;
    let dependencyIterator = frame.dependencyIterator;
    let first = frame.first;

    // Check if there's an existing iterator. If not, then let's do prep work
    // before digging into the dependencies of this cell.
    if (dependencyIterator == null) {
      if (
        (currentCell.state !== ts.DIRTY && currentCell.state !== ts.ENQUEUED_MAYBE) ||
        ts.cellsNotToRecalculate.has(currentCell)
      ) {
        continue;
      }
      dependencyIterator = getFormulaCellsInDependencies(ts.memoizedRangeFormulaCells, model, currentCell)[
        Symbol.iterator
      ]();
    }

    let iterationStep = dependencyIterator.next();
    while (!iterationStep.done) {
      const { dependencyCell, conditional } = iterationStep.value;
      if ((conditional || frame.conditional) && noCond?.has(dependencyCell)) {
        iterationStep = dependencyIterator.next();
        continue;
      }

      // The number of dependencies is not known up front as they are returned,
      // one-by-one, from a generator function. The following if statement
      // ensures that the cell is enqueued only if it has any dependencies.
      if (first) {
        currentCell.state = ts.ENQUEUEING;
        first = false;
      }
      if (iterCalcCells?.has(dependencyCell)) {
        // Dependency is already deferred to iterative phase, so defer the
        // depending cell too. (It would be caught by the transitive-closure
        // setup of the iterative calculation phase, but since we know about
        // it here, we may be able to save a little work by doing it here).
        iterCalcCells.add(currentCell);
        currentCell.state = ts.NEWLY_UPTODATE;
        // Don't break; other dependencies of this cell may not be part of any
        // dependency cycle and so must be enqueued.
      }
      else if (dependencyCell.state === ts.DIRTY) {
        // This is where we would previously perform call recursion. Simulate
        // it by manipulating the stack.

        // Store current state
        recursionStack.push(Object.assign(frame, { dependencyIterator, first }));
        recursionStack.push({
          cell: dependencyCell,
          dependencyIterator: null,
          first: true,
          conditional: frame.conditional || conditional,
        });
        // Break the inner loop, to continue the outer one on the new stack
        // frame we just pushed, i.e. loop-recurse. The current cell's
        // `dependencyIterator` is not done; we will pop it back and continue
        // it after recursing.
        break;
      }
      else if (dependencyCell.state === ts.NEWLY_UPTODATE) {
        if (ts.formulaCellsUpdated.has(dependencyCell)) {
          foundUpdatedDependency = dependencyCell;
        }
      }
      else if (dependencyCell.state === ts.ENQUEUEING && (iterCalcCells || (!conditional && !frame.conditional))) {
        // Static circular dependency, and either it is unconditional or we have
        // iterative calculation enabled (so we include conditional dependency
        // cycles to be on the safe side); defer to iterative phase if iterative
        // calculation is enabled, else record dependency cycle.
        const cycleCells = collectDependencyCycleCellsFromRecursionStack(recursionStack, currentCell, dependencyCell);
        if (iterCalcCells) {
          // Just record the cells of the circular dependency for later
          // reference in the iterative calculation phase.
          for (const cycleCell of cycleCells) {
            iterCalcCells.add(cycleCell);
            cycleCell.state = ts.NEWLY_UPTODATE;
          }
        }
        else {
          const previousValue = dependencyCell.v;
          // Keep existing value if there is one, else assign 0 (if sheet cell)
          // or ERROR_NAME (if defined name).
          if (previousValue == null) {
            // XXX: revise "is defined name" check when we support sheet-scoped
            // names.
            dependencyCell.v = dependencyCell.isDefinedName
              ? ERROR_NAME.detailed(
                'Circular dependency in defined names: ' + [ ...cycleCells, cycleCells[0] ].map(c => c.id).join(' -> '),
              )
              : 0;
          }
          ts.recordCycle(cycleCells, 'static');
          if (dependencyCell.v !== previousValue) {
            const dependencyCellRef = vertexIdToReference(model, cellToVertexId(dependencyCell));
            invariant(dependencyCellRef);
            ts.recordFormulaCellUpdates(dependencyCell, previousValue, [ dependencyCellRef ], model);
          }
          dependencyCell.state = ts.NEWLY_UPTODATE;
          // Each other cell C in `cycleCells` is either:
          //
          // * not yet enqueued, in which case we leave it to frames further up
          //   the recursion stack to deal with C normally, i.e. enqueue its
          //   other dependencies and then enqueue it, and possibly discovering
          //   _another_ dependency cycle overlapping this one
          //
          // * or NEWLY_UPTODATE, in which case we _are_ that frame further up
          //   the recursion stack, and C was already the discovery cell of
          //   another dependency cycle overlapping this one
          //
          // Either way, no cell in `cycleCells` should be in the queue.
          invariant(
            cycleCells.every(
              cell => (cell.state === ts.NEWLY_UPTODATE || cell.state < ts.ENQUEUED_MAYBE) && !queue.includes(cell),
            ),
          );
        }
      }
      iterationStep = dependencyIterator.next();
    }

    // Only enter here when we have gone through all the dependencies of the
    // current cell.
    if (iterationStep.done && currentCell.state < ts.ENQUEUED_MAYBE) {
      currentCell.state =
        foundUpdatedDependency || ts.onDemandCells.has(currentCell) ? ts.ENQUEUED_CALC : ts.ENQUEUED_MAYBE;
      if (!iterCalcCells?.has(currentCell)) {
        queue.append(currentCell);
      }
    }
  }
  if (startCell.state !== ts.NEWLY_UPTODATE) {
    if (!maybe) {
      ts.recordRootPath(startCell, startPath);
      startCell.state = ts.ENQUEUED_CALC;
    }
  }
}

/**
 * Walk back along a linked-list recalculation path until `cellToStopAt` is
 * found. If found, the sequence of cells along the path is returned, starting
 * with the tip `path.cell` and ending with `cellToStopAt` or the first
 * formula cell along the path (that may be preceded by a non-formula cell in
 * the case of a write where a cycle is not found along the path).
 *
 * This function can also double as a means of collecting a root path all the
 * way back to a recalculation root into a simple list, by passing `null` for
 * `cellToStopAt` (to mean don't stop at any cell). This is occasionally
 * convenient in investigations. It seems harmless to keep the capability here.
 */
function collectCellsFromRootPath (path: CellPath | null, cellToStopAt: Cell | null): Cell[] | null {
  const cycleCells: Cell[] = [];
  let currPath: CellPath | null = path;
  while (currPath && currPath.cell.f) {
    if (currPath?.cell === cellToStopAt) {
      invariant(cellToStopAt != null);
      return [ cellToStopAt, ...cycleCells ];
    }
    cycleCells.push(currPath.cell);
    currPath = currPath.parentPath;
  }
  if (cellToStopAt === null) {
    return cycleCells;
  }
  return null;
}

function collectDependencyCycleCellsFromRecursionStack (
  stack: { cell: Cell }[],
  secondLastCell: Cell,
  lastAndFirstCell: Cell,
) {
  if (lastAndFirstCell === secondLastCell) {
    // Self-dependency!
    return [ lastAndFirstCell ];
  }
  const cycleCells = [ lastAndFirstCell, secondLastCell ];
  for (let i = stack.length - 1; i >= 0; i--) {
    const stackCell = stack[i].cell;
    if (stackCell === lastAndFirstCell) {
      break;
    }
    cycleCells.push(stackCell);
  }
  return cycleCells;
}

/**
 * Main recalculation loop.
 *
 * INVARIANTS:
 * - a cell is in state ENQUEUED if-and-only-if the cell is in `queue`
 *
 * OTHER NOTES:
 * - a cell that is in state NEWLY_ENQUEUED does not go back to any earlier
 *   state.
 * - a cell that is in a `state <= NEWLY_UPTODATE - NUM_STATES` is considered up-to-date
 *   (even if it has `state % NUM_STATES !== 0`), i.e. cells may be have been left in
 *   DIRTY state in a previous recalculation and this should _not_ cause them
 *   to be treated as non-up-to-date for the purposes of dependency handling
 *   and calculation order.
 */
function mainRecalcLoop (queue: LinkedList<Cell>, model: Model, ts: RecalcTransientState) {
  /** @type {Cell | undefined} */
  let cell: Cell | undefined;

  const recordDependencyUse = (ref: Reference) => {
    // If this invariant gets violated, then most likely the cause is that a
    // recalculation evaluation context got stored as the resolver property of a
    // `Reference` which stays around after recalculation finishes, probably as
    // the stored result in a defined-name cell object. So that root cause needs
    // to be hunted down and fixed. (Or even better, `Reference` should be made
    // immutable, so that mistakes like this are harder to make.)
    invariant(cell, 'recordDependencyUse must only be called within the context of a recalculation');
    const fromVertexIdKey = cellToVertexIdKey(cell);
    let toVertexId;
    try {
      toVertexId = referenceToVertexId(model, ref);
    }
    catch (err) {
      // referenceToVertexId throws if reference fails to resolve; ignore that
      const isNotFoundError =
        err instanceof NameNotFoundError || err instanceof WorkbookNotFoundError || err instanceof SheetNotFoundError;
      if (!isNotFoundError) {
        throw err;
      }
      return;
    }
    let verticesUsed = ts.dependenciesUsed.get(fromVertexIdKey);
    if (!verticesUsed) {
      verticesUsed = [];
      ts.dependenciesUsed.set(fromVertexIdKey, verticesUsed);
    }
    verticesUsed.push(toVertexId);
  };

  while ((cell = queue.removeFirst())) {
    if (model.profile) {
      model.profile.queueSteps += 1;
    }
    if (cell.state === ts.ENQUEUED_MAYBE) {
      // Didn't need to recalculate this after all, no ancestor changed, else
      // propagation would have promoted this state to ENQUEUED_CALC, and it has
      // no conditional dependencies and thus no _undiscovered_ dependencies, so
      // any reason for it to update would already have been discovered, except
      // for growing spills, and that rare case would force this back to DIRTY
      // from NEWLY_UPTODATE state. So it is safe to move this to NEWLY_UPTODATE
      // state.
      cell.state = ts.NEWLY_UPTODATE;
      if (model.profile) {
        model.profile.queueStepsNotReached += 1;
      }
      continue;
    }
    else if (cell.state === ts.NEWLY_UPTODATE) {
      if (model.profile) {
        model.profile.queueStepsUpToDate += 1;
      }
      continue;
    }

    invariant(cell.state === ts.ENQUEUED_CALC);

    /** @type {Reference[]} */
    let changedRefs: Reference[];
    const revertValue = determineRevertValue(cell);
    try {
      const wb = workbookFromKey(model, cell.workbookKey);
      const maybeRecordDependencyUse =
        isNonLiteralNode(cell._ast) && cell._ast.hasConditionalDependencies
          ? recordDependencyUse
          : // eslint-disable-next-line no-undefined
          undefined;
      changedRefs = wb.calcCell(cell, true, maybeRecordDependencyUse).changedRefs;
    }
    catch (exc) {
      if (exc instanceof EvaluationOrderException) {
        const profileEnd = model.profile?.category('reorder')?.start(
          `${briefRefStr(model, cellToVertexId(cell))} => ${exc.ref}`,
          // eslint-disable-next-line no-undefined
          cell.f || undefined,
        );
        handleEvaluationOrderException(exc, model, ts, cell, queue);
        profileEnd?.();
        continue;
      }
      else {
        throw exc;
      }
    }
    ts.cellsEvaluated.add(cellToVertexIdKey(cell));
    if (changedRefs.length === 0 && ts.cellsWithValueEdits.has(cell)) {
      const ref = vertexIdToReference(model, cellToVertexId(cell));
      invariant(ref);
      changedRefs.push(ref);
    }
    if (changedRefs.length) {
      ts.recordFormulaCellUpdates(cell, revertValue, changedRefs, model);
    }
    cell.state = ts.NEWLY_UPTODATE;
    const profileEnd = model.profile?.category('propagate')?.start(briefRefStr(model, cellToVertexId(cell)));
    // Propagate recalculation, unless the cell is an on-demand defined name.
    // Why that exclusion? Because on-demand defined names are those whose value
    // is unknown, but is assumed to be unchanged since the dependents of the
    // defined name were last calculated. So they are up-to-date with respect to
    // the defined name's value --- we just didn't know that value.
    // If changes do propagate to a cell in this set, we remove it from the set.
    // So if it is still in it when we get here, we can safely skip propagation.
    if (!ts.onDemandCells.has(cell)) {
      propagateRecalculation(changedRefs, model, cell, ts, queue);
    }
    profileEnd?.();
  }
}

/**
 * Represent a vertex ID as a stringified Reference, including workbook prefix
 * only if the model has more than one workbook. Return `???` if the vertex ID
 * specifies a workbook or sheet not present in the model.
 */
export function briefRefStr (model: Model, v: KnownVertexId) {
  let ref = vertexIdToReference(model, v);
  if (!ref) {
    return '???';
  }
  if (model._workbooks.length === 1 || v.workbookKey === model._workbooks[0].keyInDepGraph) {
    ref = ref.withPrefix({ workbookName: '' });
  }
  return String(ref);
}

/**
 * Determine current value of this cell, to revert to if we detect a circular
 * dependency by propagating back to it (except don't try if it is spilled).
 * For spilled cells, use null for now; don't try to revert spill effects.
 * For non-spilled sheet cells with no current value this is 0.
 */
function determineRevertValue (cell: Cell): FormulaValue {
  if (cell.isSpillAnchor()) {
    return null;
  }
  else {
    return cell.v ?? (cell.isDefinedName ? null : 0);
  }
}

function handleEvaluationOrderException (
  exc: EvaluationOrderException,
  model: Model,
  ts: RecalcTransientState,
  cell: Cell,
  queue: LinkedList<Cell>,
) {
  // dynamic dependency discovered that wasn't up-to-date. So inject that
  // at front of queue, then resume.
  let ref = exc.ref;
  if (!ref.ctx) {
    ref = ref.withContext(model);
  }
  const wb = workbookFromKey(model, cell.workbookKey);
  warnIfNoSheetOrWorkbookName(ref, wb, cell, exc);
  const cellVertexId = cellToVertexId(cell);

  // Find which of the dependencies are not up to date
  /** @type {Cell[]} */
  let nonUpToDateDeps: Cell[] = [];
  if (ref.isAddress) {
    ref.visitFormulaCells(c => {
      if (c.state >= ts.DIRTY && c.state !== ts.NEWLY_UPTODATE) {
        nonUpToDateDeps.push(c);
      }
    });
  }
  else {
    const refVertexId = referenceToVertexId(model, ref);
    invariant(refVertexId instanceof NameVertexId);
    const resolvedCell = vertexIdToCell(model, refVertexId);
    invariant(resolvedCell != null);
    nonUpToDateDeps = [ resolvedCell ];
  }

  // There better be some non-uptodate cells, else the exception was
  // incorrectly thrown and we will get an infinite loop if we retry the
  // formula without fixing the cause of it throwing us an exception.
  invariant(
    nonUpToDateDeps.length > 0,
    'Internal error: EvaluationOrderException thrown with ref that fails to resolve or is fully UPTODATE',
  );
  // All nonUptodateDependencyCells should be either DIRTY or ENQUEUED.
  checkNoDependenciesInStateEnqueueing(nonUpToDateDeps, ts, ref);
  let previouslyEnqueuedInFrontOfCell = ts.cellReorderings.get(cell);
  if (!previouslyEnqueuedInFrontOfCell) {
    previouslyEnqueuedInFrontOfCell = new Map();
    ts.cellReorderings.set(cell, previouslyEnqueuedInFrontOfCell);
  }
  const noPrepend = new Set<Cell>();
  for (const [ previouslyPrependedCell, totalCellsUpdatedAtThatPoint ] of previouslyEnqueuedInFrontOfCell.entries()) {
    if (totalCellsUpdatedAtThatPoint === ts.totalFormulaCellUpdates) {
      // Do not prepend `previouslyPrependedCell` before `cell`, because no
      // cells have updated since we tried that before.
      noPrepend.add(previouslyPrependedCell);
    }
  }
  for (const [ previouslyReorderedCell, prependedForThatCell ] of ts.cellReorderings.entries()) {
    if (prependedForThatCell.has(cell)) {
      // Do not prepend `previouslyReorderedCell` before `cell`, because we
      // previously did the reverse reordering in this recalculation
      noPrepend.add(previouslyReorderedCell);
    }
  }
  const depsToPrepend: Cell[] = [];
  for (const depCell of nonUpToDateDeps) {
    if (depCell === cell) {
      // Dynamic self-dependency
      handleDynamicCircularDependency(ts, depCell, cell, cellVertexId, 'reordering');
      return;
    }
    if (!noPrepend.has(depCell)) {
      depsToPrepend.push(depCell);
      for (const [ previouslyReorderedCell, prependedForThatCell ] of ts.cellReorderings.entries()) {
        if (prependedForThatCell.has(depCell)) {
          // Do not prepend `previouslyReorderedCell` before `cell`, because we
          // previously reordered `depCell` before `previouslyReorderedCell`,
          // and still `depCell` is not up-to-date, so this reordering would be
          // repeated.
          noPrepend.add(previouslyReorderedCell);
        }
      }
      previouslyEnqueuedInFrontOfCell.set(depCell, ts.totalFormulaCellUpdates);
    }
  }
  if (depsToPrepend.length === 0) {
    // Circular dependency because all potential reorderings were tried before,
    // and still there are non-up-to-date dependency cells, so no further
    // reordering can complete the recalculation without cycles.
    handleDynamicCircularDependency(ts, nonUpToDateDeps[0], cell, cellVertexId, 'reordering');
    return;
  }
  insertDynamicDependenciesAtFrontOfQueue(depsToPrepend, queue, model, ts, cell, noPrepend);
  // TODO: remember evaluation order, to maybe not need to reorder next time?
}

/**
 * Check reference and add a model error if it has no sheet name or workbook
 * name, neither explicit nor contextual. Not 100% sure this _can_ happen, and
 * if it does, we will fall back to the same sheet as the referencing formula,
 * which is fairly likely to be correct (as the reference _probably_ originates
 * in the current cell's formula), but if this is incorrect, then we _may_ get
 * incorrect calculation results. So that is kinda bad. So log the error with
 * stack trace and report ModelError.
 */
function warnIfNoSheetOrWorkbookName (ref: Reference, wb: Workbook, cell: Cell, exc: EvaluationOrderException) {
  if (ref.isAddress) {
    const sheetName = ref.sheetName || ref.ctx?.sheetName;
    const workbookName = ref.workbookName || ref.ctx?.workbookName;
    if (!sheetName || !workbookName) {
      wb.addError(
        new ModelError(
          'Possible error: formula signals dynamic dependency lacking sheet name or workbook name: ' + ref.toString(),
          ModelError.WARNING,
          cell,
          'unexpected',
          exc,
        ),
      );
    }
  }
}

function handleDynamicCircularDependency (
  ts: RecalcTransientState,
  depCell: Cell,
  cell: Cell,
  cellVertexId: CellVertexId | NameVertexId,
  kind: DependencyCycleKind,
) {
  if (ts.iterativeCalculationState) {
    // Just record the cells of the circular dependency for later
    // reference in the iterative calculation phase.
    ts.iterativeCalculationState.cells.add(depCell);
    depCell.state = ts.NEWLY_UPTODATE;
    ts.iterativeCalculationState.cells.add(cell);
    cell.state = ts.NEWLY_UPTODATE;
  }
  else {
    // OK, we are not in iterative calculation mode, so apply standard
    // circular dependency handling. In other words, keep existing value
    // if there is one, else assign 0 (if sheet cell) or ERROR_NAME
    // (if defined name).
    let thisCycle = findCycleFromRootPaths(ts, depCell, cell);
    if (!thisCycle) {
      thisCycle = [ cell, depCell ];
    }
    if (thisCycle.length === 2 && thisCycle[0] === thisCycle[1]) {
      thisCycle.splice(1);
    }
    ts.recordCycle(thisCycle, kind);
    if (cell.v == null) {
      cell.v = cellVertexId instanceof CellVertexId ? 0 : ERROR_NAME.detailed('Circular dependency');
    }
    cell.state = ts.NEWLY_UPTODATE;
  }
}

function findCycleFromRootPaths (ts: RecalcTransientState, depCell: Cell, cell: Cell | null) {
  const cycleFromLastPath = collectCellsFromRootPath(
    { parentPath: ts.rootPaths.get(depCell) ?? null, cell: depCell },
    cell,
  );
  if (cycleFromLastPath) {
    return cycleFromLastPath;
  }
}

/**
 * @param noCond cells to ignore if they occur as conditional dependencies of cells being enqueued
 */
function insertDynamicDependenciesAtFrontOfQueue (
  nonUpToDateDeps: Cell[],
  queue: LinkedList<Cell>,
  model: Model,
  ts: RecalcTransientState,
  cell: Cell,
  noCond: Set<Cell>,
) {
  // Clear any ENQUEUED states, because cells in the queue should be re-enqueued
  // as if they were not in it, else they would not get reordered. But keep
  // ENQUEUED_MAYBE state distinct from ENQUEUED_CALC when re-enqueueing.
  const maybeEnqueued = new Set();
  for (const queuedCell of queue) {
    if (queuedCell.state === ts.ENQUEUED_MAYBE) {
      maybeEnqueued.add(queuedCell);
    }
    else if (queuedCell.state === ts.NEWLY_UPTODATE) {
      // Already up-to-date but still in the queue; don't dirty it!
      // It will get cleaned out of the queue below.
      // TODO: find and eliminate all ways for this to happen in the first place
      continue;
    }
    queuedCell.state = ts.DIRTY;
  }
  cell.state = ts.ENQUEUEING;
  /** @type {LinkedList<Cell>} */
  const frontOfQueue: LinkedList<Cell> = new LinkedList();
  // Enqueue all of those dynamic-dependency cells, or if some are
  // deferred to iterative calc, defer `cell` too.
  const path = { parentPath: ts.rootPaths.get(cell) ?? null, cell };
  for (const dependencyCell of nonUpToDateDeps) {
    if (ts.iterativeCalculationState?.cells.has(dependencyCell)) {
      // Dynamic dependency on cell that's been deferred to the iterative
      // phase. Must defer depending cell too.
      ts.iterativeCalculationState.cells.add(cell);
      // Mark it up-to-date so that we don't also put it back in the queue below.
      // Will still put its dynamic dependendents in the queue; they need to be
      // up-to-date before the iterative phase, unless they also get deferred.
      cell.state = ts.NEWLY_UPTODATE;
    }
    else {
      // Enqueue the dependency — but only MAYBE, because we don't really know
      // that any of its dependents has updated.
      enqueueCellPrecededByDirtyDependencies(model, ts, dependencyCell, path, frontOfQueue, true, noCond);
    }
  }
  // put cell back on queue if it isn't there already --- unless it got whisked
  // into NEWLY_UPTODATE state (by circular dependency handling)
  if (cell.state !== ts.ENQUEUED_CALC && cell.state !== ts.NEWLY_UPTODATE) {
    frontOfQueue.append(cell);
    cell.state = ts.ENQUEUED_CALC;
  }

  queue.retain(prevEnqueuedCell => {
    // Might be already re-enqueued as one of nonUpToDateDeps or their
    // dependencies; can tell by state.
    if (prevEnqueuedCell.state === ts.DIRTY) {
      // it did not get re-enqueued in `frontOfQueue`
      prevEnqueuedCell.state = maybeEnqueued.has(prevEnqueuedCell) ? ts.ENQUEUED_MAYBE : ts.ENQUEUED_CALC;
      return true;
    }
    else {
      // Was enqueued in `frontOfQueue` as one of nonUpToDateDeps, or is
      // NEWLY_UPTODATE. Either way, it should be removed from the remainder of
      // the queue. But also, if it got re-enqueued in front, then that may have
      // set its state to ENQUEUED_MAYBE, when previously it was in state
      // ENQUEUED_CALC. In that case, we must move it back to ENQUEUED_CALC.
      if (prevEnqueuedCell.state === ts.ENQUEUED_MAYBE && !maybeEnqueued.has(prevEnqueuedCell)) {
        prevEnqueuedCell.state = ts.ENQUEUED_CALC;
      }
      return false;
    }
  });
  queue.prependList(frontOfQueue);
}

function checkNoDependenciesInStateEnqueueing (nonUpToDateDeps: Cell[], ts: RecalcTransientState, ref: Reference) {
  const uhoh = nonUpToDateDeps.filter(cell => cell.state === ts.ENQUEUEING);
  if (uhoh.length) {
    const idList = uhoh.slice(0, 3).map(c => ref.getRefId(c.id));
    const idListRepr = `${idList.join(', ')}${uhoh.length > idList.length ? '...' : ''}`;
    throw new Error(`Internal error: ${uhoh.length} dynamic dependencies in state ENQUEUEING: ${idListRepr}`);
  }
}

/**
 * Enqueue all formula cells that have unconditional static dependencies on any
 * cells in `changedRefs`, or on `cell` itself if it is not a formula cell. This
 * latter case is for calculating the consequences of a literal-value cell edit.
 */
function propagateRecalculation (
  changedRefs: Reference[],
  model: Model,
  cell: Cell,
  ts: RecalcTransientState,
  queue: LinkedList<Cell>,
) {
  const vertexIdsToPropagateFrom = changedRefs.map(ref => referenceToVertexId(model, ref));
  if (cell.f == null) {
    // Cell was a formula cell that was just edited, so unconditionally
    // consider it changed so that recalculation propagates to its dependents
    ensureVertexIdIncluded(vertexIdsToPropagateFrom, cellToVertexId(cell));
  }
  // If `cell` is an edited cell, then record changes to any dependent cells,
  // because these need to have their reset/save state updated to reflect the
  // consequences of the edit.
  const cellChangedByEdit = ts.cellsChangedSincePreviousRecalc.has(cell);
  if (cellChangedByEdit) {
    for (const ref of changedRefs) {
      ts.changedCellsChangedRefs.push(new Reference(ref));
    }
  }
  const parentPath = ts.rootPaths.get(cell) ?? null;
  for (const vertexId of vertexIdsToPropagateFrom) {
    model._graph.visitIncomingEdges(vertexId, inEdge => {
      if (inEdge.kind === 'nonvalue' && !cellChangedByEdit) {
        // Non-value dependencies are only for applying consequences of edits,
        // so don't need to propagate recalculation since `cell` was not edited
        return;
      }
      const inVertexId = inEdge.from.id;
      const directDependentCell = inEdge.from.data;
      invariant(directDependentCell != null);
      if (!directDependentCell.f) {
        invariant(directDependentCell.neutralizedFormula != null);
        // Not a formula cell, despite being a dependent according to the graph.
        // This happens when a formula cell is overwritten by a write. So skip.
        return;
      }
      if (cellChangedByEdit) {
        ts.cellsChangedSincePreviousRecalc.add(directDependentCell);
      }
      if (directDependentCell.state === ts.NEWLY_UPTODATE) {
        const dependentVertexIdKey = inVertexId.key;
        if (
          inEdge.kind === 'conditional' &&
          ts.cellsEvaluated.has(dependentVertexIdKey) &&
          !wasDependencyUsed(ts, dependentVertexIdKey, vertexId)
        ) {
          // Dependency is conditional and the cell(s) we updated were not
          // actually used in the last evaluation of the dependent cell. Thus
          // the updates will not result in a different result in that cell.
          return;
        }
        if (inEdge.kind === 'nonvalue') {
          // Don't record circular dependencies on non-value edges.
          return;
        }
        if (directDependentCell === cell) {
          // Self-dependency via redo-propagation; this can happen when a cell
          // spills into cell that it depends on. For example, if A1 has formula
          // `=SEQUENCE(1, 2) + IF(ISBLANK(B1), 10, B1)` this will happen.
          handleDynamicCircularDependency(ts, cell, cell, inVertexId, 'propagation');
        }
        const rootPathCycle = collectCellsFromRootPath({ cell, parentPath }, directDependentCell);
        if (rootPathCycle) {
          // We got here via the cell we are about to propagate to. That's a dependency cycle.
          // If iterative calculation is enabled, defer the cells to that, else redo-propagate.
          if (ts.iterativeCalculationState) {
            for (const cycleCell of rootPathCycle) {
              ts.iterativeCalculationState.cells.add(cycleCell);
              cycleCell.state = ts.NEWLY_UPTODATE;
            }
          }
          else {
            const valueToRevertTo = ts.recordCycle(rootPathCycle, 'propagation');
            if (valueToRevertTo != null) {
              directDependentCell.v = valueToRevertTo;
              const changedRef = vertexIdToReference(model, inVertexId);
              invariant(changedRef);
              // Enqueue each other cell in the cycle for redo recalculation.
              // Enqueue them in reverse order, as the cycle already reflects dependency order.
              for (const cycleCell of rootPathCycle.slice(1).reverse()) {
                cycleCell.state = ts.ENQUEUED_CALC;
                queue.append(cycleCell);
              }
              propagateRecalculation([ changedRef ], model, directDependentCell, ts, queue);
            }
            else if (directDependentCell.v == null) {
              directDependentCell.v =
                inVertexId instanceof CellVertexId ? 0 : ERROR_NAME.detailed('Circular dependency');
            }
            directDependentCell.state = ts.NEWLY_UPTODATE;
          }
          return;
        }
        // We have already updated `directDependentCell` in this recalculation,
        // but now we affected a dependency of it by updating `cell`. So `cell`
        // is discovered to affect `directDependentCell` via dynamic dependency.
        // Must redo `directDependentCell` and potentially the subgraph of cells
        // affected by it. Unless it has been identified as part of a dependency
        // cycle, in which case we must just ignore it.
        if (!ts.cycleCells.has(directDependentCell)) {
          // Move back to before DIRTY, to force markDirty to really mark it dirty
          directDependentCell.state = ts.DIRTY - 1;
        }
      }
      maybeMarkDirty(model, ts, directDependentCell);
      const dependentState = directDependentCell.state;
      if (
        dependentState === ts.DIRTY ||
        dependentState === ts.ENQUEUED_MAYBE ||
        (dependentState === ts.ENQUEUED_CALC && ts.onDemandCells.has(directDependentCell))
      ) {
        const path = { parentPath, cell };
        enqueueCellPrecededByDirtyDependencies(model, ts, directDependentCell, path, queue);
      }
    });
  }
}

function wasDependencyUsed (ts: RecalcTransientState, dependentVertexIdKey: string, vertexId: KnownVertexId) {
  return ts.dependenciesUsed
    .get(dependentVertexIdKey)
    ?.some(
      vertexIdUsed =>
        vertexIdUsed.key === vertexId.key ||
        (vertexIdUsed instanceof RangeVertexId && vertexIdUsed.overlaps(vertexId)) ||
        (vertexId instanceof RangeVertexId && vertexId.overlaps(vertexIdUsed)),
    );
}

/**
 * Add to the given set of cells any other cells depending on one of the cells in the set according to this workbook's
 * dependency graph, recursing until there are no more cells to be added.
 */
function extendCellSetToDependencyClosure (model: Model, cells: Set<Cell>, upToDateState: number) {
  const pendingCells = Array.from(cells);
  // Loop-recurse breadth-first to find all static and potential (because
  // previously observed) dynamic dependents.
  let pendingCell;
  while ((pendingCell = pendingCells.shift()) != null) {
    pendingCell.state = upToDateState;
    const vertexId = cellToVertexId(pendingCell);
    model._graph.visitIncomingVertices(vertexId, inVertex => {
      const dependentCell = inVertex.data;
      invariant(dependentCell != null);
      if (!cells.has(dependentCell)) {
        cells.add(dependentCell);
        pendingCells.push(dependentCell);
      }
    });
  }
}

/**
 * @returns the cells in calculation order
 */
function makeIterativeCalculationOrder (cells: Set<Cell>): Cell[] {
  const cellArray = Array.from(cells);
  cellArray.sort((a, b) => {
    // Order:
    // * Sheet cells before name cells
    // * Sheets according to their ordering in the workbook
    // * Cells within a sheet by row and then column, i.e. rows in order, each
    //   row left to right.
    // * Name cells in lexicographic order of names
    const aCoords = a1ToRowColumnOrNull(a.id);
    const bCoords = a1ToRowColumnOrNull(b.id);
    if (aCoords != null && bCoords != null) {
      const [ aRow, aCol ] = aCoords;
      const [ bRow, bCol ] = bCoords;
      // Both are sheet cells
      if (a.sheetIndex === b.sheetIndex) {
        // In the same sheet
        if (aRow === bRow) {
          // In the same row
          return aCol - bCol;
        }
        // In different rows
        return aRow - bRow;
      }
      // In different sheets
      // @ts-expect-error Since both are sheet cells, a.sheetIndex and b.sheetIndex
      //            will be non-null.
      return a.sheetIndex - b.sheetIndex;
    }
    else if (aCoords != null) {
      // OK, then b is not a sheet cell, and sheet cells before name cells, so
      return -1;
    }
    else if (bCoords != null) {
      // OK, then a is not a sheet cell, and sheet cells before name cells, so
      return 1;
    }
    // We now know both are defined-name cells
    // TODO figure out exact ordering of sheet-scoped names when we get to those
    return a.id < b.id ? -1 : 1;
  });
  return cellArray;
}

function doIterativeCalculation (model: Model, ts: RecalcTransientState) {
  if (ts.iterativeCalculationState == null) {
    return;
  }

  // `iterativeCalculationState.cells` contains cells found to be part of a
  // circular dependency. Now extend that set to any cells depending directly
  // or indirectly on any of them.
  extendCellSetToDependencyClosure(model, ts.iterativeCalculationState.cells, ts.NEWLY_UPTODATE);

  const { cells, maxIterations, maxChange } = ts.iterativeCalculationState;
  const cellsInCalculationOrder = makeIterativeCalculationOrder(cells);

  // These cells are already in NEWLY_UPTODATE state. That is where they should
  // end up regardless of how iterative calculation terminates. But they were
  // put in that state as soon as they were added to the iterative-calculation
  // set, and that has the nice side effect of sidestepping any up-to-dateness
  // checks for dynamic dependencies, and always using existing values of
  // defined names (if their AST is not marked with `isContextDependent`).
  //
  // We might have to reconsider this shortcut if we ever give access to cell
  // states _during_ iterative calculation (e.g. by making all this async and
  // yielding occasionally), as then this may be misleading.

  for (let iteration = 0; iteration < maxIterations; iteration++) {
    let biggestChange = 0;
    for (const c of cellsInCalculationOrder) {
      // Pass false for `checkDirty` to treat all cells as not dirty, i.e. ignore
      // dirty states and never throw an `EvaluationOrderException` about them.
      //
      // Why? Because normal (pre-iteration) recalculation has completed, so
      // if formula evaluation in the iteration phase encounters a dependency
      // on a cell that's marked with a “dirty” state, that cell _is_ actually
      // up-to-date — it just wasn't reached by the mark-and-eval-queue
      // algorithm because each static dependency path from a recalculation
      // root to that cell included some cell whose value didn't change, so
      // changes did not propagate further along that path. The only reason the
      // cell remains marked with a dirty state is that we skip the work of
      // hunting for all cells that weren't reached by a recalculation (instead
      // we bump `upToDateState` by NUM_STATES when recalculation completes and
      // then treat any cells with a state lower than that as being up-to-date
      // in the next recalculation). So any remaining dirty states are safe to
      // ignore during iterative calculation.
      //
      // Alternatively we could bump `upToDateState` _before_ this iterative
      // calculation phase. But this would risk leaving a misleading state in
      // the case where iterative calculation fails with an exception, for any
      // reason. Admittedly we _already_ leave some cells in states
      // `> upToDateState` when recalculation fails with an exception, but at
      // least such a situation can currently be readily recognized and
      // understood, precisely _because_ `upToDateState` will lag behind those
      // cell states in such a case. So updating it before a recalculation is
      // really complete might make that situation harder to recognize and
      // troubleshoot.
      const wb = workbookFromKey(model, c.workbookKey);
      const change = wb.calcCell(c, false).change;
      if (biggestChange > maxChange) {
        // No need to check the change
      }
      else if (isNum(change)) {
        biggestChange = Math.max(biggestChange, Math.abs(change));
      }
      else if (change) {
        // Non-numeric change, so neutralize `maxChange` check for this
        // iteration.
        biggestChange = maxChange + 1;
      }
    }
    if (biggestChange < maxChange) {
      break;
    }
  }
}

/**
 * Recalculate any cells that require update, starting with the recalculation
 * roots:
 * - Cells referenced in `state.volatile`.
 * - Cells referenced in `state.needsInitRecalc`.
 * - Cells referenced in `state.changedSinceRecalc`.
 * - Cells depending _directly_ on any cells referenced in
 *   `state.writtenSinceRecalc`.
 *
 * ... and then propagating the recalculation to any cells whose formulas have
 * static dependencies on any cells that have changed their value, ultimately
 * reaching any cell C that depends _indirectly_ on any of the above, unless
 * _every_ dependency path from a recalculation root to C contains some cell
 * whose value did not change upon recalculation.
 *
 * See algorithm writeup in {@link Docs.RecalculationAlgorithm}
 *
 * PRECONDITION:
 *
 * Cells referenced in `state.writtenSinceRecalc` themselves should already
 * have their new values and be in state `upToDateState`. All cells in
 * the model should be in state `upToDateState` or lower.
 * @param [which=CHANGED_OR_VOLATILE] which set of cells to recalculate
 * @returns {Reference[]} references to any ranges which changed when
 *   reevaluating cells whose formulas changed since the last recalculation.
 */
export function recalculate (
  model: Model,
  recalcState: RecalcState,
  which: typeof CHANGED_OR_VOLATILE | typeof ALL_FORMULA_CELLS | typeof CHANGED_ONLY = CHANGED_OR_VOLATILE,
): Reference[] {
  model.profile?.milestone('start');
  const iterCalcSetting = model.iterativeCalculationSettings();

  const transientState = new RecalcTransientState(recalcState.upToDateState, iterCalcSetting);
  if (which === CHANGED_OR_VOLATILE || which === CHANGED_ONLY) {
    changedOrVolatilePrep(model, recalcState, transientState, which === CHANGED_ONLY);
  }
  else {
    invariant(which === ALL_FORMULA_CELLS);
    allFormulaCellsPrep(model, transientState);
  }
  model.profile?.milestone('prep');
  /** @type {LinkedList<Cell>} */
  const queue: LinkedList<Cell> = new LinkedList();

  transientState.recalculationRootCells.forEach(rootCell => {
    // A recalculation root can be already in state NEWLY_UPTODATE here, if
    // we detected a circular dependency in it while enqueueing a previous
    // recalculation root. So skip such roots.
    if (rootCell.state !== transientState.NEWLY_UPTODATE) {
      enqueueCellPrecededByDirtyDependencies(
        model,
        transientState,
        rootCell,
        transientState.rootPaths.get(rootCell) || null,
        queue,
      );
    }
  });

  model.profile?.milestone('enqueue');
  mainRecalcLoop(queue, model, transientState);
  model.profile?.milestone('mainRecalcLoop');
  doIterativeCalculation(model, transientState);
  model.profile?.milestone('iterative');

  model.profile?.summarize(transientState, model);
  recalcState.namesAwaitingRecalc = recalcState.namesAwaitingRecalc.filter(
    vid => !transientState.cellsEvaluated.has(vid.key),
  );
  recalcState.needsInitRecalc = [];
  recalcState.upToDateState = transientState.NEWLY_UPTODATE;
  updateCircularDependencyError(transientState, model);
  recalcState.lastRecalc = transientState;
  return transientState.changedCellsChangedRefs;
}

function ensureVertexIdIncluded (vertexIdsToPropagateFrom: VertexId[], cellVertexId: CellVertexId | NameVertexId) {
  if (
    !vertexIdsToPropagateFrom.some(
      vertexId =>
        vertexId.key === cellVertexId.key ||
        (vertexId instanceof RangeVertexId && cellVertexId instanceof CellVertexId && vertexId.contains(cellVertexId)),
    )
  ) {
    vertexIdsToPropagateFrom.push(cellVertexId);
  }
}

function cellToVertexIdKey (cell: Cell) {
  if (cell.sheetIndex == null) {
    return nameVertexIdKey(cell.workbookKey, cell.sheetIndex, cell.id);
  }
  else {
    const addr = a1ToRowColumnOrNull(cell.id);
    if (addr) {
      const [ row, col ] = addr;
      return cellVertexIdKey(cell.workbookKey, cell.sheetIndex, row, col);
    }
    else {
      return nameVertexIdKey(cell.workbookKey, cell.sheetIndex, cell.id);
    }
  }
}

function updateCircularDependencyError (ts: RecalcTransientState, model: Model) {
  if (ts.cycleCells.size) {
    model.addError(ModelError.fromCircularDependencyWith(ts.cycleCells));
  }
  const circularDependencyError = model.errors.find(err => err.type === 'circdep');
  if (circularDependencyError && circularDependencyError.vertexIds.size > ts.cycleCells.size) {
    // circularDependencyError has cells not found found to be part of a
    // dependency cycle this time around. Find any that we did update in this
    // recalculation, and remove them from circularDependencyError.
    for (const prevCycleCellVertexId of circularDependencyError.vertexIds) {
      const prevCycleCell = vertexIdToCell(model, prevCycleCellVertexId, true);
      if (prevCycleCell?.state === ts.NEWLY_UPTODATE && !ts.cycleCells.has(prevCycleCell)) {
        // Cell was updated in this recalculation and not found to be part of a
        // dependency cycle this time. Remove it from circularDependencyError.
        circularDependencyError.vertexIds.delete(prevCycleCellVertexId);
      }
    }
    if (circularDependencyError.vertexIds.size === 0) {
      model._removeError(circularDependencyError);
    }
  }
}
