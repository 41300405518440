/**
 * Trim any empty cells from the right and bottom edges of an array.
 *
 * Optionally removes rows and columns that have been hidden (that is, their heights have been set
 * to zero).
 */
export function removeHidden (matrix, model, keepHiddenRows = false, keepHiddenColumns = false) {
  const wb = model.getWorkbook(matrix.workbookName);
  let mx = matrix;
  if (!wb) {
    return mx;
  }
  const sheet = wb.getSheet(matrix.sheetName);
  if (!sheet) {
    return mx;
  }
  if (mx.length !== 0) {
    const rowOffset = matrix.top;
    const colOffset = matrix.left;
    const colEnd = matrix.right;

    if (!keepHiddenRows) {
      mx = removeHiddenRows(mx, rowOffset, sheet.row_heights);
    }
    if (!keepHiddenColumns) {
      mx = removeHiddenColumns(mx, colOffset, colEnd, sheet.columns ?? []);
    }
    mx.workbookName = matrix.workbookName;
    mx.sheetName = matrix.sheetName;
  }
  return mx;
}

/**
 * Remove any rows that have a row height of 0.
 *
 * @param {import('@grid-is/apiary').AreaCellArray} array
 * @param {number} rowOffset
 * @param {Object.<number, number>} rowHeights
 */
function removeHiddenRows (array, rowOffset, rowHeights) {
  return array.filter((_, i) => {
    const rowIndex = rowOffset + i + 1;
    const rowHeight = rowHeights[rowIndex] ?? 1;
    return rowHeight > 0;
  });
}

/**
 * Remove any columns that have a column width of 0.
 *
 * @param {import('@grid-is/apiary').AreaCellArray} array
 * @param {number} colOffset
 * @param {number} colEnd
 * @param {{ begin: number, end: number, width: number }[]} columns
 */
function removeHiddenColumns (array, colOffset, colEnd, columns) {
  const hiddenColumns = [];
  for (let i = colOffset + 1; i <= colEnd + 1; i++) {
    const columnInfo = columns.find(col => col.begin <= i && i <= col.end);
    if (columnInfo?.width === 0) {
      hiddenColumns.push(i - colOffset - 1);
    }
  }
  if (hiddenColumns.length !== 0) {
    return array.map(row => row.filter((_, cellIndex) => hiddenColumns.indexOf(cellIndex) === -1));
  }
  return array;
}
