import FormulaError from './excel/FormulaError';
import type { CellValue } from './excel/types';
import { isBoxed, unbox, type MaybeBoxed } from './excel/ValueBox';

export function isErr (d: any): d is MaybeBoxed<FormulaError> {
  return unbox(d) instanceof FormulaError;
}

export function isBool (d: any): d is MaybeBoxed<boolean> {
  return typeof unbox(d) === 'boolean';
}

export function isNum (d: any): d is MaybeBoxed<number> {
  return typeof unbox(d) === 'number';
}

export function isStr (d: any): d is MaybeBoxed<string> {
  return typeof unbox(d) === 'string';
}

export function isCellValue (d: any): d is MaybeBoxed<CellValue> {
  return d === null || isBoxed(d) || isStr(d) || isNum(d) || isBool(d) || isErr(d);
}
