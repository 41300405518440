import { b64encode } from '@grid-is/base64';
import { EvaluationContext } from '../EvaluationContext.js';
import FormulaError from '../FormulaError.js';
import type Reference from '../Reference';
import { ERROR_NA } from '../constants';
import { isRef } from './utils';

/**
 * Throw an error unconditionally. Used for testing error handling.
 */
export function GRID_ERROR (message?: string | Reference): never {
  const resolvedMessage = isRef(message) ? String(message.resolveSingle()) : message;
  throw new Error(resolvedMessage || 'Artificial error thrown by GRID.ERROR()');
}

export function GRID_ISPRINT (this: EvaluationContext): boolean {
  return !!this.env?.get('isPrint');
}

export function GRID_ISMOBILE (this: EvaluationContext): boolean {
  return !!this.env?.get('isMobile');
}

export function GRID_USERNAME (this: EvaluationContext): string | FormulaError {
  return String(this.env?.get('username') || '') || ERROR_NA;
}

export function GRID_SCENARIO (this: EvaluationContext): string {
  const state = this.writeState();
  if (Object.keys(state).length) {
    return b64encode(JSON.stringify(state), '-_');
  }
  return '';
}
