import { type ColumnInfo, offsFromCol } from '@grid-is/apiary';
import { chain } from '@grid-is/iterators';

type AxisItem = {
  index: number,
  size: number,
  start: number,
  end: number,
  fill?: boolean,
}

export type Sizes = Map<number, number> | Record<string | number, number> | ColumnInfo[];

function normalizeSizes (sizes: Sizes, defaultSize: number, scale: number, maxSize: number) {
  const items: AxisItem[] = [];
  if (sizes instanceof Map) {
    for (const [ index, width ] of sizes) {
      items[index] = {
        index,
        size: (width ?? defaultSize) * scale,
        start: 0,
        end: 0,
      };
    }
    for (let i = 0; i < items.length; i++) {
      if (!items[i]) {
        items[i] = {
          index: i,
          size: defaultSize * scale,
          start: 0,
          end: 0,
        };
      }
    }
  }
  // old style objects: { 1: 24, 13: 19 } or { A: 30, B: 20 }
  else if (!Array.isArray(sizes)) {
    const areColumnWidths = Object.keys(sizes).some(key => /[A-Za-z]+/.test(key));
    if (areColumnWidths) {
      // Convert the column letters (A, B) to numerical indices (1, 2)
      sizes = Object.fromEntries(Object.entries(sizes).map(([ key, value ]) => [ offsFromCol(key) + 1, value ]));
    }
    const maxIndex = Math.min(
      maxSize,
      chain(Object.keys(sizes)).map(Number)
        .max() ?? 0,
    );
    // FIXME: ensure maxIndex is finite
    for (let index = 0; index < maxIndex; index++) {
      items.push({
        index,
        size: (sizes[index + 1] ?? defaultSize) * scale,
        start: 0,
        end: 0,
      });
    }
  }
  // RLE arrays of sizes: [{begin:1,end:1,width:16},{begin:2,end:25,width:9}],
  else {
    let index = 1;
    for (const d of sizes) {
      if (d.begin < index) {
        // Overlapping column definitions; this has been observed to happen in
        // .xlsx exported from Numbers. Handle by "rewinding" the process, ergo
        // effectively ignoring the previous column definitions with the same
        // `begin` index (or a smaller one, though I bet that doesn't come up).
        items.splice(d.begin - 1);
        index = d.begin;
      }
      while (index < d.begin) {
        // fill with default until we have reached definition
        if (index > maxSize) {
          break;
        }
        items.push({
          index: (index++) - 1,
          size: defaultSize * scale,
          fill: true,
          start: 0,
          end: 0,
        });
      }
      while (index <= d.end) {
        // fill with definition until we have reached the end
        if (index > maxSize + 1) {
          break;
        }
        items.push({
          index: (index++) - 1,
          size: d.width * scale,
          start: 0,
          end: 0,
        });
      }
      if (index > maxSize) {
        break;
      }
    }
  }
  return items;
}

function updateOffsets (items: AxisItem[]) {
  return items.reduce((sum, item) => {
    item.start = sum;
    sum += item.size;
    item.end = sum;
    return sum;
  }, 0);
}

type Options = Readonly<{
  scale?: number,
  defaultSize?: number,
  maxSize: number,
}>

export class CellAxis {
  private scale: number;
  private defaultSize: number;
  items: AxisItem[];

  public static readonly columnOptions = {
    headerSize: 31 / 1.3,
    scale: 6.5 * 1.17,
    defaultSize: 12,
  };

  public static readonly rowOptions = {
    headerSize: 50 / 1.3,
    scale: 1.3,
    defaultSize: 21 / 1.3,
  };

  public static readonly structuredSheetColumnOptions = {
    headerSize: 40 / 1.3,
    scale: 6.5 * 1.17,
    defaultSize: 27,
  };

  public static readonly structuredSheetRowOptions = {
    scale: 1.3,
    defaultSize: 30 / 1.3,
  };

  constructor (sizes: Sizes, { scale = 1, defaultSize = 1, maxSize }: Options) {
    this.scale = scale;
    this.defaultSize = defaultSize;
    if (sizes) {
      this.items = normalizeSizes(sizes, defaultSize, scale, maxSize + 1);
    }
    else {
      this.items = [];
    }
    updateOffsets(this.items);
  }

  get totalSize () {
    const { length } = this.items;
    if (length) {
      return this.items[length - 1].end;
    }
    return 0;
  }

  toPx (index: number) {
    const length = this.items.length;
    if (index < 0) {
      // index out of lower bounds
      return {
        index: index,
        start: -1,
        size: 0,
        end: -1,
      };
    }
    else if (index < length) {
      return this.items[index];
    }
    // index out of upper bounds
    const { scale, defaultSize } = this;
    const offs = this.totalSize + ((index - length) * defaultSize * scale);
    return {
      index: index,
      size: defaultSize * scale,
      start: offs,
      end: offs + defaultSize * scale,
    };
  }

  // grid?
  toIndex (px: number) {
    if (px < 0) {
      // out of lower bounds
      return -1;
    }

    const totalSize = this.totalSize;
    if (px <= totalSize && totalSize > 0) {
      return this.items.findIndex(d => px >= d.start && px <= d.end);
    }

    // out of upper bounds
    const remainingWidth = px - totalSize;
    const indexOutOfBounds = Math.ceil(remainingWidth / (this.defaultSize * this.scale)) - 1;
    const r = Math.max(0, this.items.length + indexOutOfBounds);
    return r;
  }

  toMaxDigitWidth (px: number) {
    return px / this.scale;
  }
}
