/* eslint-disable no-console */
import { tokenize, tokenTypes } from '@borgar/fx';
import * as Sentry from '@sentry/nextjs';
import EventEmitter from 'component-emitter';
import debounce from 'lodash.debounce';

import { loadLazy as loadApiaryFunctions, Model, ModelError } from '@grid-is/apiary';
import { ParseFailureEvaluationError } from '@grid-is/apiary/lib/Workbook';
import { truncatedList } from '@grid-is/collections';
import { instrumentForTracing } from '@grid-is/tracking';
import { assert } from '@grid-is/validation';

import {
  create,
  discardDraft,
  getDocument,
  publishDraft,
  sources as setSourceIDs,
  update,
  updateDraft,
  updateTitle,
} from '@/api/document';
import { getWorkbook, updateNativeWorkbook } from '@/api/source';
import ChartTheme from '@/grid/ChartTheme';
import { parseStyles } from '@/grid/customStyles/parseStyles';
import { print as printModelState } from '@/utils/modelState';
import { upsertWorkbook } from '@/utils/processWorkbook';
import { SequentialWorker } from '@/utils/sequential';
import { extractTitle } from '@/utils/text';

import { isFormula } from '../options/utils/isFormula';
import { createDocument, fromSlate, parseJSON } from './fileformat';
import { InlineNode, MetaNode, TextNode, visit } from './Node';
import { applyNodeFilter } from './nodeFilter';
import { ThemeKeeper } from './ThemeKeeper';
import { validateAndFix } from './validateAndFix';
import { WbMonitor } from './WbMonitor';

/**
 * @typedef {InlineNode | TextNode | import("./Node").Element | MetaNode} DocNode
 */

/**
 * @param {DocNode | null} doc
 */
function getStats (doc) {
  const stats = {
    functions: {},
    expressions: 0,
  };
  if (!doc) {
    return stats;
  }
  visit(doc, node => {
    const opts = node.attr ? Object.values(node.attr) : [];
    if (opts.length) {
      opts.forEach(val => {
        if (String(val)[0] === '=') {
          stats.expressions++;
          tokenize(val).forEach(token => {
            if (token.type === tokenTypes.FUNCTION) {
              stats.functions[token.value] = (stats.functions[token.value] || 0) + 1;
            }
          });
        }
      });
    }
  });
  return stats;
}

/**
 * @typedef {NonNullable<import('@/api/document').Document['sources']>[number]} WorkbookSource
 */

/*
Events emitted by Doc:

  save:
    Emitted when the document has just been saved to server.
  slugchange:
    Emitted when the document's slug has been updated (normally because the title had changed post save).
  ready:
    (Triggered from the outside view) emitted once the document has been attached to a view.
  warning:
    Emitted if something notable came up that could be worth emitting to console or tracking
  linkwb:
    Emitted when attachSource is called. Usually this means a source workbook refererence is added/removed/replaced,
    to/from the document (but there may be no change). The event data captures adds and removals.
    Note: This does not apply to loading/removing any workbooks from the model.

  modelready:
    Emitted when model has loaded workbooks and has been initialized (and recalculated, if it contains any volatile cells).
  modelbegincalc:
    Emitted when a write has occurred in the model and recalculation is about to start.
  modelrecalc:
    Emitted every time a workbook in the model changes its state (via write, reset, or workbook update from the
    websocket). Not emitted when a recalculation (due to volatiles) occurs at model initialization time.
  modelreset:
    Emitted every time a workbook in the model resets its state (via model.reset()).

  wbattached:
    Emitted when a workbook has just been added to the model (note that this workbook could still be invalid).
  wbfail:
    Emitted if a requested source workbook fails to load from server.
  wbloaded:
    Emitted every time a workbook has been loaded.
  wbrenamed:
    Emitted when a workbook has been loaded and its name differs from that of the existing workbook with the same ID
    (or the workbook that is being replaced, if there is one).
  wbloading:
    Emitted every time a workbook starts to be loaded (requsted from server).
  wbupdateerror:
    Emitted when server sends workbook update (through socket) and the new workbook version is defective.
  wbupdate:
    Emitted when model changes underlying workbooks. (Note difference from linkwb.) Payload { id, isInitial, defect }

  interaction:
    Event may by emitted by rendered elements when an interaction with them occurs.
  nodataclick:
    Event may by emitted by "no data" error panel if user clicks an interaction element within it.

When initializeModel loads workbooks this is the expected sequence of events

- Success: wbloading > wbloaded > wbattached > wbupdate > modelready
- Failure: wbloading > wbfail
*/
// log events to console
const DEBUG = false;

const NATIVE_WORKBOOK_DELAY = 1500;

export class Doc extends EventEmitter {
  // XXX: each native workbook should have its own sequencer. Not an issue now because we have at most one.
  // XXX: Once scoped to a workbook, it should be safe to put a max-length of 1 or 2 on the queue.
  workbookWriteSequencer = new SequentialWorker();
  docWriteSequencer = new SequentialWorker();

  /**
   * @param {Partial<import('@/api/document').Document>} props
   */
  constructor (props = {}, auth_token = '') {
    super();
    /** @type {boolean} */
    this.allowWorkbookPruning = false; // XXX: gets assigned true from outside
    this.auth_token = auth_token;
    this._disableSave = false;
    // Declare and read properties from the API document
    /** @type {string} */
    this.author;
    /** @type {string[]} */
    this.allowed_domains;
    /** @type {Record<string, unknown>} */
    this.client_settings;
    /** @type {boolean} */
    this.commenting_enabled;
    /** @type {import('@/api/user').User} */
    this.creator;
    /** @type {string | null} */
    this.data_modified;
    /** @type {string} */
    this.description;
    /** @type {import('@/api/user').User | null} */
    this.edited_by;
    /** @type {number} */
    this.embed_views;
    /** @type {Partial<import('../../api/user').AccountFeatures>} */
    this.features;
    /** @type {string} */
    this.format;
    /** @type {boolean} */
    this.has_password;
    /** @type {string | null} */
    this.id;
    /** @type {boolean} */
    this.is_duplicatable;
    /** @type {boolean} */
    this.is_embeddable;
    /** @type {boolean} */
    this.is_welcome_document;
    /** @type {boolean} */
    this.isLiked;
    /** @type {string} */
    this._language;
    /** @type {number} */
    this.likes;
    /** @type {string} */
    this.publish_time;
    /** @type {string | null} */
    this.published_version_tag;
    /** @type {string} */
    this.slug;
    /** @type {WorkbookSource[]} */
    this.sources;
    /** @type {string} */
    this.thumbnail_url;
    /** @type {string} */
    this.title;
    /** @type {'normal' | 'tutorial'} */
    this.type;
    /** @type {string} */
    this.update_time;
    /** @type {number} */
    this.version;
    /** @type {import('@/api/document').Document['view_access']} */
    this.view_access;
    /** @type {boolean} */
    this.viewers_can_save_scenarios;
    /** @type {number} */
    this.views;
    this._copyProps(props);

    /** @type {Model} */
    this.model = instrumentForTracing(new Model());
    this.model.on('recalc', ev => {
      DEBUG && console.log('modelrecalc');
      this.design.update();
      this.emit('modelrecalc', ev);
    });
    this.model.on('beforerecalc', ev => {
      DEBUG && console.log('modelbeforerecalc');
      this.emit('modelbeforerecalc', ev);
    });

    /** @type {ThemeKeeper} */
    this.design = new ThemeKeeper();
    this.design.model = this.model;

    /** @type {string} */
    this.externalCss = '';
    /** @type {string} */
    this.customStyle = '';

    // debounce updating the workbook on the server if the user is rapidly editing it
    this.nativeWorkbookChange = debounce(this._nativeWorkbookChange, NATIVE_WORKBOOK_DELAY);
    this.model.on('native-update', this.nativeWorkbookChange);

    this.model.on('reset', ev => {
      DEBUG && console.log('modelreset');
      this.emit('modelreset', ev);
    });
    this.model.on('error', this.onModelError);
    // temporary hack: ensure that when model is replaced, the lastWrite counter updates
    this.model.lastWrite = -Math.random();

    // stores the model state for getStatefulUrl
    this.modelState = [];

    // parse body and assign as the DOM state
    const domBody = this.parseDocBody(props.body || '');
    assert(domBody, 'props.body must parse successfully');
    /** @type {import('./Node').Element} */
    this.dom;
    this.setDom(domBody);

    this.stats = getStats(domBody);
    // read draft state
    /**
     * @type {{
     *   description?: any,
     *   language?: any,
     *   body?: any,
     * }}
     */
    this._draft_state = this.parseDraftState(props.draft);
    // if user reverts, it will be back to this point in time
    this._published_state = this.getDocState();

    this._globalProps = {
      documentId: this.id,
      canonicalUrl: this.getCanonicalUrl(false),
      canEdit: this.access === 'edit',
      /** @param {string} featureName */
      canUse: featureName => !!this.features[featureName],
      /**
       * @param {string} event
       * @param {any[]} args
       */
      emit: (event, ...args) => this.emit(event, ...args),
      // track will get overwritten by the view renderer, this is here to keep editor compatible
      track: () => {},
      /** @param {any} error */
      onError: error => Sentry.captureException(error, { tags: this.sentryTags() }),
    };

    this.isDirty = false;
    /** @type {null | undefined | import('@/utils/modelState').ModelStateArray} */
    this.initialModelState = null;
    this.modelDirty = false;
    this.isEmbedded = false;

    // set up a workbook monitor (which needs to be turned on via Doc#useMonitoring)
    this.wbMonitor = new WbMonitor();
    // make sure that a websocket update is delayed so it does not interfere with the saving of a native workbook
    this.wbMonitor.on('update', this.onWorkbookUpdated);

    // Make a promise that can be awaited in order to defer work until after the model has been initalized
    this.initalized = new Promise(resolve => {
      this._resolveInitializedPromise = resolve;
    });
  }

  _nativeWorkbookChange = async e => {
    const saveChanges = async () => {
      assert(this.id, 'Document should have an ID when trying to save GRID sheet');
      let workbook = this.model.getWorkbook(e.workbookName);
      assert(workbook, 'workbook in native-update event should exist in the model');

      try {
        const storedWrites = [ ...this.model.writes() ];
        if (storedWrites.length > 0) {
          this.model.reset();
        }
        workbook.reset();
        const saveData = workbook.toCSF();
        if (storedWrites.length > 0) {
          this.model.writeMultiple(storedWrites);
        }
        const ret = await updateNativeWorkbook(this.id, workbook.id, saveData, workbook.version);
        // IMPORTANT: refetch the workbook from the model because it may have been replaced by an undo/redo operation while
        // the save was in progress
        workbook = this.model.getWorkbook(e.workbookName);
        if (workbook) {
          workbook.update_time = ret.modified;
          workbook.version = ret.version;
        }
      }
      catch (err) {
        if (err == null || typeof err !== 'object') {
          throw err;
        }
        const status = 'status' in err ? err.status : -1;
        if (status === 409) {
          if ('error_code' in err && err.error_code === 'document_version_mismatch') {
            this.emit('document-version-mismatch', err);
          }
          else {
            this.emit('lock-conflict');
          }
        }
        else if (status === 401) { // XXX: this is probably dead code by now. 401s always result in an AuthorizationRequiredError
          this.emit('unauthenticated-user');
        }
        else if (status === 404 || status === 403) {
          this.emit('native-save-fail');
        }
        else {
          throw err;
        }
      }
    };
    this.workbookWriteSequencer.enqueue(saveChanges);
  };

  /** @type {import('@/grid/Theme').default} */
  get theme () {
    return this.design.theme;
  }

  /** @type {import('@/grid/ChartTheme').default} */
  get chartTheme () {
    return this.design.chartTheme;
  }

  /** @param {string} source */
  updateCustomStyle (source) {
    if (!this.features.can_apply_custom_styles) {
      return;
    }
    const rules = parseStyles(source);
    const bodyStyles = rules.get('body');
    if (bodyStyles) {
      this.design.override = bodyStyles;
      this.design.update();
    }
  }

  sentryTags () {
    return { documentId: this.id, view_access: this.view_access };
  }

  /**
   * @param {Partial<import('@/api/document').Document>} props
   */
  _copyProps (props) {
    const now = new Date().toISOString();
    this.access = props.access ?? null;
    // author is deprecated
    this.author = ('author' in props ? String(props.author) : props.creator?.username) || '';
    this.allowed_domains = props.allowed_domains ?? [];
    this.client_settings = props.client_settings ?? {};
    this.commenting_enabled = props.commenting_enabled ?? false;
    this.creator = props.creator || { id: '', name: '', username: '' };
    this.data_modified = props.data_modified ?? null;
    this.description = props.description ?? '';
    this.edited_by = props.edited_by ?? null;
    this.embed_views = props.embed_views ?? 0;
    this.features = props.features ?? {};
    this.format = props.format ?? 'v2';
    this.has_password = props.has_password ?? false;
    this.id = props.id ?? null;
    this.is_duplicatable = props.is_duplicatable ?? false;
    this.is_embeddable = props.is_embeddable ?? false;
    this.is_welcome_document = props.is_welcome_document ?? false;
    this.isLiked = props.isLiked ?? false;
    this.language = props.language ?? 'en-US';
    this.likes = props.likes ?? 0;
    this.publish_time = props.publish_time ?? now;
    this.published_version_tag = props.published_version_tag ?? null;
    this.slug = props.slug ?? '';
    this.sources = props.sources ?? [];
    this.thumbnail_url = props.thumbnail_url ?? '';
    this.title = props.title ?? '';
    this.type = props.type ?? 'normal';
    this.update_time = props.update_time ?? now;
    this.version = props.version ?? 0;
    this.view_access = props.view_access ?? 'private';
    this.viewers_can_save_scenarios = props.viewers_can_save_scenarios ?? false;
    this.views = props.views ?? 0;
  }

  parseDraftState (draftRaw) {
    const draftState = {};
    if (draftRaw) {
      const draftProps = JSON.parse(draftRaw);
      if (draftProps.description) {
        draftState.description = draftProps.description;
      }
      if (draftProps.language) {
        draftState.language = draftProps.language;
      }
      if (draftProps.body) {
        draftState.body = this.parseDocBody(draftProps.body, 'draft');
        this.statsDraft = getStats(draftState.body);
      }
    }
    return draftState;
  }

  /**
   * @param {string} bodyRaw
   * @returns {import('./Node').Element}
   */
  parseDocBody (bodyRaw, bodyType = 'body') {
    /** @type {import('./Node').Element | null} */
    let dom = null;
    const body = typeof bodyRaw === 'string' ? bodyRaw.trim() : bodyRaw;
    if (body) {
      if (this.format === 'v2') {
        // @ts-expect-error - we presume this is a body element
        dom = parseJSON(body);
      }
      else {
        console.error('Invalid document format ' + this.format);
        dom = createDocument('Unsupported document format');
      }
    }
    if (dom) {
      const opts = {};
      validateAndFix(dom, opts);
      if (DEBUG && typeof console !== 'undefined') {
        console.info(bodyType, opts.stats);
      }
    }
    else {
      dom = createDocument();
    }
    return dom;
  }

  /** @type {string} */
  get language () {
    return this._language || 'en-US';
  }

  /** @type {string} */
  set language (value) {
    this._language = value;
    // trigger setting a new global props instance because language is also
    // exposed as locale through globalprops
    this.setGlobalProps({});
  }

  /** @type {string | undefined} */
  get showViewChrome () {
    return this.dom.attr?.showViewChrome;
  }

  set showViewChrome (value) {
    if (!this.dom.attr) {
      this.dom.attr = {};
    }
    this.dom.attr.showViewChrome = value;
  }

  getSettings () {
    return {
      language: this.language,
      description: this.description,
      classicCharts: this.design.chartThemeName === ChartTheme.gridChartsOld,
      showViewChrome: this.showViewChrome,
      backgroundColor: this.design.props.backgroundColor,
      accentColor: this.design.props.accentColor,
      bodyFont: this.design.props.bodyFont,
      chartFont: this.design.props.chartFont,
      titleFont: this.design.props.titleFont,
      externalCss: this.externalCss || '',
      customStyle: this.customStyle || '',
    };
  }

  /**
   * @param {string} name The setting name
   * @param {string} value A new setting value
   */
  applySetting (name, value) {
    if (name === 'description') {
      this.description = value;
    }
    else if (name === 'showViewChrome') {
      this.showViewChrome = value;
    }
    else if (name === 'classicCharts') {
      this.design.chartThemeName = (value === 'true')
        ? ChartTheme.gridChartsOld
        : ChartTheme.gridChartsNew;
    }
    else if (name === 'language') {
      this.language = value;
    }
    else if (
      name === 'accentColor' ||
      name === 'backgroundColor' ||
      name === 'titleFont' ||
      name === 'bodyFont' ||
      name === 'chartFont'
    ) {
      this.design.setProps({ [name]: value });
    }
    else if (name === 'externalCss') {
      this.externalCss = value;
    }
    else if (name === 'customStyle') {
      this.customStyle = value;
      this.updateCustomStyle(value);
    }
  }

  onModelError = async errorEvent => {
    const { workbookId, error, seenBefore } = errorEvent;
    if (error?.type === 'overpruning' && !seenBefore) {
      console.warn(`Reloading workbook with id ${workbookId} due to overpruning`);
      Sentry.captureEvent({
        message: error.message,
        tags: {
          ...this.sentryTags(),
          workbookId: workbookId,
          type: error.type,
        },
        level: 'warning',
      });
      this.allowWorkbookPruning = false;
      const currentState = this.model.writes();
      await this.loadSource(workbookId);
      // Recalculate _all_ workbooks (because the effective cross-workbook
      // dependencies may have changed as a result of loading this source).
      this.model.writeMultiple(currentState, { forceRecalc: true });
    }
    else if ([ 'unexpected', 'recalcfailed', 'evalfailed' ].includes(error.type) && !seenBefore) {
      /** @typedef {{meta: {}, errors: string, ast?: string, formula?: string}} ApiaryContext */
      /** @type {import('@sentry/types').CaptureContext & {contexts: {apiary: ApiaryContext}}} */
      const captureContext = {
        tags: {
          ...this.sentryTags(),
          workbookId: workbookId,
          type: error.type,
        },
        level: error.level >= ModelError.ERROR ? 'error' : 'warning',
        contexts: {
          apiary: {
            meta: this.model.meta,
            errors: this.model.errors
              .filter(err => err !== error)
              .map(err => `"${err.message}" in ${err.references.size} cells: ${truncatedList(err.references).join(', ')}`)
              .join('\n'),
          },
        },
      };
      if (isEvalFailedAboutUnparseableFormulaInGridSheet(error, this.model)) {
        // No-op, don't capture ignorable errors
      }
      else if (error.origException) {
        if ('ast' in error.origException) {
          captureContext.contexts.apiary.ast = JSON.stringify(error.origException.ast);
        }
        if ('formula' in error.origException) {
          captureContext.contexts.apiary.formula = error.origException.formula;
        }
        Sentry.captureException(error.origException, captureContext);
      }
      else {
        Sentry.captureEvent({ message: error.message, ...captureContext });
      }
    }
  };

  /**
   * @param {import("./Node").Element} dom
   */
  setDom (dom) {
    validateAndFix(dom);
    this.stats = getStats(dom);

    let css = '';
    let style = '';
    const ch = Array.from(dom.children);
    for (const n of ch) {
      if (n instanceof MetaNode) {
        if (dom.removeChild) {
          dom.removeChild(n);
          if (n.type === 'css') {
            css = n.value;
          }
          else if (n.type === 'style') {
            style = n.value;
          }
        }
      }
    }
    this.externalCss = css;
    this.customStyle = style;
    this.updateCustomStyle(this.customStyle);

    this.dom = dom;

    this.design.chartThemeName = this.dom.attr?.chartThemeName || ChartTheme.gridChartsOld;
    this.design.setProps(this.dom.attr?.design || {});

    this.dom.attr = this.dom.attr || {};
  }

  /**
   * @param {object} meta
   * @param {string} [meta.description]
   * @param {string} [meta.language]
   * @param {import("./Node").Element} [meta.body]
   * @param {string} [meta.chartThemeName]
   * @param {Record<string, string>} [meta.design]
   * @param {Record<string, any>} [meta.attr]
   * @param {string} [meta.title]
   */
  setDocState (meta) {
    // the `this.dom !== meta.body` is important, to not needlessly run setDom
    if (meta.body && this.dom !== meta.body) {
      this.setDom(meta.body);
    }
    if (meta.description) {
      this.description = meta.description;
    }
    if (meta.language) {
      this.language = meta.language;
    }
    if (meta.title) {
      this.title = meta.title;
    }
    if (meta.design) {
      this.design.setProps(meta.design);
    }
    if (meta.chartThemeName) {
      this.chartThemeName = meta.chartThemeName;
    }
  }

  getDocState () {
    return {
      body: this.dom,
      description: this.description,
      language: this.language,
      chartThemeName: this.chartThemeName,
      design: this.design.props,
      title: this.title,
    };
  }

  getSourceId () {
    if (this.sources && this.sources.length) {
      return this.sources[0].id;
    }
    return null;
  }

  get date () {
    return this.publish_time;
  }

  get hasDraft () {
    // we have a draft if the _draft_state exists and constains a key that is not null
    const { _draft_state } = this;
    return _draft_state && Object.keys(_draft_state).some(k => _draft_state[k] != null);
  }

  getGlobalProps () {
    // global props is passed to a context so we only hand out
    // a new object instance if it has changed
    this._globalProps.locale = this.language;
    return this._globalProps;
  }

  setGlobalProps (props) {
    this._globalProps = { ...this._globalProps, ...props };
  }

  toSlateDom () {
    return this.dom.toSlate().children;
  }

  toJSON () {
    const body = this.dom.toGrid();
    body.attr = body.attr || {};
    body.attr.design = this.design.saveData();
    body.attr.chartThemeName = this.design.chartThemeName;
    if (body.attr.showViewChrome === 'all') {
      delete body.attr.showViewChrome;  // 'all' is the default, so omit it
    }

    if (this.customStyle) {
      const styleNode = new MetaNode('style', this.customStyle);
      body.children.unshift(styleNode.toGrid());
    }
    if (this.externalCss) {
      const cssNode = new MetaNode('css', this.externalCss);
      body.children.unshift(cssNode.toGrid());
    }

    return JSON.stringify(body);
  }

  fromSlateDom (slateDoc) {
    const newDom = fromSlate(slateDoc, this.dom.attr, this.dom.id);
    const oldDom = this.dom;

    newDom.normalize();
    oldDom.normalize();

    const newJSON = JSON.stringify(newDom.toGrid());
    const oldJSON = JSON.stringify(oldDom.toGrid());
    if (newJSON !== oldJSON) {
      this.isDirty = true;
      this.dom = newDom;
    }

    return this.isDirty;
  }

  getUrlPrefix () {
    if (typeof location !== 'undefined') {
      return `${location.protocol}//${location.hostname}${location.port ? ':' + location.port : ''}`;
    }
    return '';
  }

  getStatefulUrl (relative = true, returnAlteredUrlOnly = false) {
    const modelState = this.modelState || [];
    let alteredUrl = '';
    if (modelState.length) {
      // when link is requested from the ui, this should be set as a query param
      const serialState = printModelState(modelState);
      alteredUrl = '?s=' + serialState;
    }
    if (returnAlteredUrlOnly) {
      return alteredUrl;
    }
    const canonicalUrl = this.getCanonicalUrl(relative) ?? '';
    return canonicalUrl + alteredUrl;
  }

  getCanonicalUrl (relative = true) {
    if (this.author && this.slug) {
      const prefix = relative ? '' : this.getUrlPrefix();
      return `${prefix}/@${this.author}/${this.slug}`;
    }
    return null;
  }

  getEmbedUrl (shareInOriginalState) {
    const embedUrl = `${this.getUrlPrefix()}/embed/${this.slug}`;
    return shareInOriginalState ? embedUrl : `${embedUrl}${this.getStatefulUrl(true, true)}`;
  }

  /**
   * Update the document's list of sources, and handle different errors that need to be addressed as a result of that,
   * i.e. lock conflict and authentication errors.
   */
  async handleSettingSources () {
    if (this.id) {
      const setSources = async () => {
        assert(this.id);
        try {
          const result = await setSourceIDs(this.id, this.version, this.sources.map(src => src.id));
          this.version = result.version;
        }
        catch (err) {
          if (err == null || typeof err !== 'object') {
            throw err;
          }
          const status = 'status' in err ? err.status : -1;
          if (status === 409) {
            if ('error_code' in err && err.error_code === 'document_version_mismatch') {
              this.emit('document-version-mismatch', err);
            }
            else {
              this.emit('lock-conflict');
            }
          }
          else if (status === 401) {
            this.emit('unauthenticated-user');
          }
          else {
            throw err;
          }
        }
      };
      await this.docWriteSequencer.enqueue(setSources);
    }
  }

  /**
   * Add given source ID to the document's list of sources, optionally replacing another.
   *
   * @param {WorkbookSource} source Add a data source (likely a workbook) to the document's list of sources, optionally replacing another.
   * @param {string | null} [replacingSourceId=null] existing source to replace with sourceId if present
   */
  async attachSource (source, replacingSourceId = null) {
    /**
     * @type {{
     *   removeId: string | null,
     *   addedId: string | null,
     * }}
     */
    const changes = {
      removeId: null,
      addedId: null,
    };

    const isPresent = this.sources.find(d => d.id === source.id);
    if (!isPresent) {
      // remove replace target source (if any)
      const indexToReplace = this.sources.findIndex(src => src.id === replacingSourceId);
      if (indexToReplace > -1) {
        changes.addedId = source.id;
        changes.removeId = replacingSourceId;
        this.sources[indexToReplace] = source;

        // tag outbound workbook so we know when it can be removed safely
        const wb = replacingSourceId ? this.model.getWorkbookById(replacingSourceId) : null;
        if (wb) {
          wb.replacedBy = source.id;
          wb.state().state = 'replaced'; // XXX: Touching internal WB state seems bad
        }
      }
      // add new source
      else {
        changes.addedId = source.id;
        this.sources.push(source);
      }

      // this document exists on the server, so let it know about the change
      // else the source will attached when doc is created (see save)
      await this.handleSettingSources();
    }
    else if (source.id !== replacingSourceId) {
      const prevAlsoPresent = this.sources.some(src => src.id === replacingSourceId);
      const moan = prevAlsoPresent
        ? 'both are already sources'
        : 'former is already a source and latter is not!';
      this.emit('warning', {
        message: `Adding source ${source.id} to replace different source ${replacingSourceId} but ${moan}`,
      });
    }

    DEBUG && console.log('linkwb');
    Object.freeze(changes);
    this.emit('linkwb', changes);
    // NOTE: loading the workbook is taken care of by this.useMonitoring()
    // if useMonitoring is not active, then a new workbook will not be loaded
    return changes;
  }

  /**
   * Remove given source ID from the document's list of sources.
   *
   * @param {string} sourceId the ID of the source to remove
   */
  async detachSource (sourceId) {
    const index = this.sources.findIndex(d => d.id === sourceId);
    if (index !== -1) {
      this.sources.splice(index, 1);
      this.model.removeWorkbook(sourceId);
      await this.handleSettingSources();
      this.emit('linkwb', Object.freeze({ removeId: sourceId, addedId: null }));
    }
  }

  /**
   * Perform any steps needed, such as loading the workbooks, to get the doc to a ready state.
   * @param {'edit' | 'view' | 'preview'} [mode='edit'] controls how much initialization work is performed. From slowest to fastest:
   *    - edit (default): Full initialization. All spreadsheet functions loaded.
   *    - view: Only load spreadsheet functions that are used in the document or workbooks.
   *    - preview: Load workbooks in a read-only mode.
   */
  async initializeModel (mode = 'edit') {
    const readOnly = mode === 'preview' && !this.initialModelState;
    const editMode = mode === 'edit';

    // Start loading all workbooks
    const workbookCsfPromises = this.sources.map(source => this._loadWorkbookCSF(source));

    // Await preconditions (such as loading a parser and loading lazy-loaded functions)
    await this._preconditionsToInitializingModel(editMode);

    let loadedSuccessfully = true;

    try {
      // Wait until workbooks have been loaded
      const workbookCsfList = await Promise.all(workbookCsfPromises);

      const nativeCsfList = workbookCsfList.filter(csf => csf.type === 'native');
      const nonNativeCsfList = workbookCsfList.filter(csf => csf.type !== 'native');

      // Add non-native workbooks first, then native.
      //
      // This is to work around the unconditional recalculation of previously
      // unresolvable references that become resolvable. Let's take an example
      // using workbooks A and B.
      //
      //    1. A contains a reference to B. A is added first.
      //
      //    2. The reference to B is unresolvable since B has not been added
      //       yet, so Apiary adds the reference to a set of unresolvable
      //       references.
      //
      //    3. B is added.
      //
      //    4. After adding B, Apiary goes through its unresolvable references
      //       and notices that A's reference to B has become resolvable. It
      //       marks the cell containing the reference as needing recalculation.
      //
      // So if a native workbook is added as the first workbook, ALL of its
      // outgoing references will be unresolvable. As the other workbooks are
      // added, the references become resolvable. This would cause ALL external
      // references in the native workbook to be recalculated.
      //
      // To avoid this, we add non-native workbooks first (they cannot contain
      // external references, so they don't have the same problem).
      for (const csf of [ ...nonNativeCsfList, ...nativeCsfList ]) {
        this._addWorkbookToModel(csf, readOnly);
        this.emit('wbupdate', {
          id: csf.id,
          isInitial: true,
          defect: null,
        });
      }

      // Ensure that the model's workbook order is the same as the source order
      this.model.orderWorkbooks(this.sources.map(d => d.id));

      this.model.setupInitRecalculation();

      // After adding workbooks, lazy imports may have been added. Await those.
      await this.model.lazyImportPromise.catch(err => Sentry.captureException(err));
    }
    catch (err) {
      loadedSuccessfully = false;
      this._onLoadWorkbookError(err);
      // If execution is stopped here, we would show a loader indefinitely.
      // Allowing execution to continue causes the document to finish loading.
      //
      // An error modal will be shown to the user, notifying him that loading a
      // workbook failed.
    }

    if (this.monitoring) {
      this.monitorWorkbooks();
    }

    let nativeWorkbooksChanged = new Set();
    if (!readOnly) {
      // Do initial recalculation in all workbooks before applying writes
      // (This may cause redundant work due to volatile functions, but it is the
      // safe way to go because the initial recalculation cleans up some state
      // that's unclear in CSF, and this may be necessary before applying writes.)
      nativeWorkbooksChanged = this.model.recalculate().nativeWorkbooksChanged;
    }

    if (this.initialModelState) {
      // this is the initial loading, so set a proposed initial state (if we have it)
      this.model.writeMultiple(this.initialModelState);
    }

    // if no workbook is present, emit ready right away
    DEBUG && console.log('modelready');
    this._resolveInitializedPromise(null);
    this.emit('modelready');

    const saveChangesFromInitRecalc = (
      // Do not save if we encountered errors when loading
      loadedSuccessfully &&
      // Do not save if there are writes
      !this.initialModelState &&
      // Only save in edit mode
      editMode
    );

    if (saveChangesFromInitRecalc) {
      for (const nativeWorkbook of nativeWorkbooksChanged) {
        this.model.emit('native-update', {
          workbookName: nativeWorkbook.name,
          workbookId: nativeWorkbook.keyInDepGraph,
          lastWrite: this.model.lastWrite,
          action: 'init-recalc',
        });
      }
    }

    // Returning this allows: doc = await Doc.fetch(...).initializeModel(...)
    return this;
  }

  /**
   * @param {boolean} editMode
   * @returns {Promise<void>}
   */
  async _preconditionsToInitializingModel (editMode) {
    await Promise.all([
      Model.preconditions, // Preconditions that the Model may have (loading a parser)
      this._loadApiaryFunctions(editMode),
    ]);
  }

  /**
   * @param {boolean} editMode
   * @returns {Promise<void[] | PromiseSettledResult<void>[]>}
   */
  _loadApiaryFunctions (editMode) {
    const apiaryFunctionsToLoad = editMode
      ? null // Null means load every function
      : Object.keys(Object.assign({}, this.stats?.functions, this.statsDraft?.functions));
    return loadApiaryFunctions(apiaryFunctionsToLoad);
  }

  async reloadAllSources () {
    await this.initalized;
    await Promise.all(this.sources.map(async wb => {
      await this.loadSource(wb.id);
    }));
    this.model.recalculate();
    this.emit('modelready');
  }

  /**
   * Load the workbook with the given ID, and any lazy-loaded spreadsheet
   * function modules it may require.
   *
   * After this is called, the caller should make sure the model recalculates.
   * This is left to the caller as it may need to involve other workbooks loaded
   * concurrently, and order them according to cross-workbook dependencies.
   */
  loadSource = async (workbookId, refreshSourcesAndPublishedVersionTag = false) => {
    assert(this.id);
    if (refreshSourcesAndPublishedVersionTag) {
      const latestDoc = await getDocument(this.id, this.auth_token);
      this.sources = latestDoc.sources || [];
      this.published_version_tag = latestDoc.published_version_tag;
    }

    const workbookInfo = this.sources.find(wb => wb.id === workbookId);
    if (!workbookInfo) {
      console.log('did not load source', workbookId, 'as it is not attached');
      // this can happen when replacing file upload with cloud drive workbook
      // XXX: log to sentry to know how often this happens?
      return;
    }

    try {
      const csf = await this._loadWorkbookCSF(workbookInfo);
      await Model.preconditions;
      const workbook = this._addWorkbookToModel(csf);

      // ensure model wb order is the same as client source order
      this.model.orderWorkbooks(this.sources.map(d => d.id));

      // await any lazy-loaded spreadsheet function module imports
      await workbook.lazyImportPromise;
    }
    catch (err) {
      this._onLoadWorkbookError(err, workbookId);
    }
  };

  /**
   * @param {WorkbookSource} source
   */
  async _loadWorkbookCSF (source) {
    const { id } = source;

    DEBUG && console.log('wbloading', { id });
    this.emit('wbloading', { id });

    assert(this.id);
    const workbookBody = await getWorkbook(this.id, id, this.auth_token, this.allowWorkbookPruning, this.published_version_tag, this.isEmbedded);
    // These properties of `WorkbookBody` _should_ be present with non-nullish
    // values per the type, but in reality the API sometimes sends an empty body
    // ... apparently when the workbook has a NULL body in the DB. So default
    // the properties here to ensure that the type is satisfied. See GRID-3980.
    // XXX remove this once we have ensure that the API never sends a body
    // without these properties.
    const EMPTY_WORKBOOK_BODY = { schema_version: '4.9', sheets: [], names: [], styles: [] };
    const csf = Object.assign(EMPTY_WORKBOOK_BODY, workbookBody, source);

    const eventProps = this._getWorkbookLoadedOrAttachedEventProps(csf);
    DEBUG && console.log('wbloaded', eventProps, csf.filename);
    this.emit('wbloaded', eventProps);

    return csf;
  }

  /**
   * @param {import('@grid-is/apiary/lib/csf').WorkbookBody & WorkbookSource} csf
   */
  _addWorkbookToModel (csf, readOnly = false) {
    // Check whether this workbook already exists under a different name.
    // This can happen if a cloud workbook was renamed.
    const existingWb = this.model.getWorkbookById(csf.id);
    const newWbName = csf.filename?.replace(/\.gsheet$/i, '');
    const newSingleSheetName = csf.sheets.length === 1 ? csf.sheets[0].name : null;
    const newSingleTableName = csf.tables?.length === 1 ? csf.tables[0].name : null;
    if (existingWb && newWbName && existingWb.name !== newWbName) {
      this._emitRenamedEvent(
        existingWb,
        newWbName,
        singleObjectRenaming(existingWb, newSingleSheetName, 'sheet'),
        singleObjectRenaming(existingWb, newSingleTableName, 'table'));
    }

    // invalid and valid workbooks are both represented in the model
    // note that this will automatically update the workbook if it already exists in the model
    const workbook = this.model.addWorkbook(csf, { recalcVolatiles: false, readOnly });

    const eventProps = this._getWorkbookLoadedOrAttachedEventProps(csf);
    DEBUG && console.log('wbattached', eventProps);
    this.emit('wbattached', eventProps);

    // remove any that are wb.replacedBy === workbookId
    this.model.getWorkbooks().forEach(d => {
      if (d.replacedBy === csf.id) {
        this.model.removeWorkbook(d.id);
        if (d.name !== newWbName) {
          this._emitRenamedEvent(
            d,
            newWbName,
            singleObjectRenaming(d, newSingleSheetName, 'sheet'),
            singleObjectRenaming(d, newSingleTableName, 'table'),
          );
        }
      }
    });

    // leave initial recalculation (for volatiles and defined names) to caller
    // as it may need to involve more workbooks and order them correctly.

    return workbook;
  }

  _onLoadWorkbookError (err, workbookId) {
    Sentry.captureException(err);
    this.emit('wbfail', {
      id: workbookId,
      defect: 'http_error',
      error: err,
    });
  }

  /**
   * @param {import('@grid-is/apiary/lib/csf').WorkbookCSF} csf
   */
  _getWorkbookLoadedOrAttachedEventProps (csf) {
    return Object.freeze({
      id: csf.id,
      state: csf.state,
      isStale: csf.state === 'invalid' && csf.sheets,
      defect: csf.defect,
    });
  }

  /**
   * @param {import('@grid-is/apiary').Workbook} workbook
   * @param {string} newName
   * @param {import('@/editor/EditorEventStream').ReferenceRenaming} [alsoSheet] single-sheet renaming for which
   *   incoming references should be updated
   * @param {import('@/editor/EditorEventStream').ReferenceRenaming} [alsoTable] single-table renaming for which
   *   incoming references should be updated without workbook prefix
   */
  _emitRenamedEvent (workbook, newName, alsoSheet, alsoTable) {
    /** @type {import('@/editor/EditorEventStream').Events['ON_DOCUMENT_WORKBOOK_RENAMED']} */
    const renameEventProps = { prevName: workbook.name, newName, alsoSheet, alsoTable };
    DEBUG && console.log('wbrenamed', renameEventProps);
    this.emit('wbrenamed', renameEventProps);
  }

  useMonitoring () {
    this.monitoring = true;
    this.on('linkwb', this.monitorWorkbooks);
  }

  suspendMonitoring () {
    this.wbMonitor.unwatchAll();
    this.off('linkwb', this.monitorWorkbooks);
    this.monitoring = false;
  }

  monitorWorkbooks = () => {
    assert(this.id);
    this.wbMonitor.watchList()
      .filter(wbBeingWatched => !this.sources.some(wb => wb.id === wbBeingWatched.workbookId))
      .forEach(wbInfo => this.wbMonitor.unwatch(wbInfo));
    for (const wb of this.sources) {
      // if not currently watching this workbook, start watching (.watch ignores re-watches)
      this.wbMonitor.watch({
        workbookId: wb.id,
        documentId: this.id,
        shouldPing: !!wb.cloud_connection?.should_ping,
      });
    }
  };

  // socket event callback
  /**
   * @param {{
   *   id: string,
   *   update_time?: string,
   *   state: any,
   *   defect: any,
   * }} _new
   */
  onWorkbookUpdated = async _new => {
    const workbook = this.model.getWorkbookById(_new.id);
    const _curr = workbook ? workbook.state() : { update_time: undefined, defect: undefined };
    const theirs = Date.parse(_new.update_time || '');
    const ours = Date.parse(_curr.update_time || '');

    if ((theirs > ours) || !_curr.update_time) {
      const msgState = _new.state;
      const defect = msgState === 'processing' || msgState === 'uploading' ? _curr.defect : _new.defect;
      this.model.setWorkbookState(_new.id, msgState, defect);
      // even if an workbook is invalid we may still need to load it
      // because of the "stale" workbook case
      const dontHaveDataYet = !workbook;
      if (msgState === 'ready' || (msgState === 'invalid' && dontHaveDataYet)) {
        await this.loadSource(_new.id, true);
        const wb = this.model.getWorkbookById(_new.id);
        if (wb != null) {
          this.model.recalculate();
        }
        DEBUG && console.log('wbupdate');
        this.emit('wbupdate', {
          id: _new.id,
          isInitial: false,
          defect,
        });
      }
      else if (_new.defect) {
        DEBUG && console.log('wbupdateerror');
        this.emit('wbupdateerror', _new);
      }
    }
  };

  isBlank () {
    return !(this.dom && this.dom.hasContent());
  }

  /**
   * Create a new document with an (optional) native workbook
   * @param {string | null} [source] Where the creation is initiated from
   */
  async create (source = null) {
    const docTitle = this.title || 'Untitled';
    const sourceId = (await upsertWorkbook({ type: 'native', name: 'Project sheet' }))?.workbook?.id;

    this.isDirty = false;
    const r = await create(
      this.toJSON(),
      sourceId,
      docTitle,
      this.type || 'normal',
      { properties: { initiated_from: source } },
    );
    this.version = r.version;
    this.id = r.id;
    this.slug = r.slug;
    this.emit('save', { created: true });
    this.emit('slugchange');

    return r;
  }

  async save (source = null) {
    if (this._disableSave) {
      return true;
    }

    if (!this.isDirty) {
      return true;
    }

    if (this._shouldSaveDraft()) {
      try {
        return await this._saveDraft();
      }
      catch (err) {
        if (err && typeof err === 'object' && 'status' in err && err.status === 401) {
          this.emit('unauthenticated-user');
        }
        else {
          throw err;
        }
      }
    }

    const docTitle = this.title || 'Untitled';

    // if user reverts, it will be back to this point in time
    this._published_state = this.getDocState();

    // document has an id: it has a server version
    if (this.id) {
      const doSave = async () => {
        assert(this.id);
        this.model.clearCachedFormulasExcept(this.collectFormulas());
        try {
          const r = await update(
            this.id,
            this.version,
            this.toJSON(),
            docTitle,
            this.description,
            this.language,
            this.client_settings,
            this.type,
          );
          // must be marked clean after update is done as it may throw
          this.isDirty = false;

          this.slug = r.slug;
          this.version = r.version;
          this.emit('save', { created: false });
          this.emit('slugchange');
        }
        catch (err) {
          if (err && typeof err === 'object' && 'status' in err && err.status === 401) {
            this.emit('unauthenticated-user');
          }
          else {
            throw err;
          }
        }
      };
      await this.docWriteSequencer.enqueue(doSave);
    }
    // else, this is a new document that needs creating
    else {
      const sourceId = this.sources[0] && this.sources[0].id;
      this.isDirty = false;
      const r = await create(
        this.toJSON(),
        sourceId,
        docTitle,
        this.type || 'normal',
        { properties: { initiated_from: source } },
      );
      this.version = r.version;
      this.id = r.id;
      this.slug = r.slug;
      this.emit('save', { created: true });
      this.emit('slugchange');
    }
  }

  _shouldSaveDraft () {
    if (this.hasDraft) {
      return true;
    }
    else if (this.view_access === 'public' && !this.has_password) {
      return true;
    }
    else if (this.is_embeddable) {
      return true;
    }
    return false;
  }

  // this is called only when we already have a document, which has been shared
  // all further edits will be stored in the drafts field
  async _saveDraft () {
    const doSave = async () => {
      if (!this.isDirty) {
        return true;
      }
      // make sure the draft_state.body instance is up to date with the current dom
      this._draft_state.body = this.dom;

      assert(this.id);
      const r = await updateDraft(
        this.id,
        this.version,
        this.toJSON(),
        this.description !== this._published_state.description ? this.description : null,
        this.language !== this._published_state.language ? this.language : undefined,
      );
      this.version = r.version;
      this.isDirty = false;
    };
    return this.docWriteSequencer.enqueue(doSave);
  }

  async updateTitle (title) {
    assert(this.id);
    const r = await updateTitle(this.id, title || 'Untitled');
    this.slug = r.slug;
    this.title = title;
    this.emit('slugchange');
  }

  getTitleSuggestion () {
    let paragraph = '';
    if (this.dom && this.dom.find) {
      // Look for a node whose children contain text.
      const isTextOrInlineNode = node => node instanceof TextNode || node instanceof InlineNode;
      const hasText = child => isTextOrInlineNode(child) && child.hasContent();
      const foundNode = this.dom.find(node => node.children?.some(hasText));
      if (foundNode) {
        // Join all the text in the node's children into a single string.
        // Why?
        // Because formatting may have caused the text to be split into multiple nodes.
        paragraph = foundNode.children.filter(child => isTextOrInlineNode(child) && child.value)
          .map(child => child.value)
          .join('');
      }
    }
    let result = extractTitle(paragraph);
    if (!result) {
      // No text in the document. Fall back to using the name of the first external workbook.
      const wbInfo = this.sources?.find(source => source.type !== 'native');
      if (wbInfo) {
        result = wbInfo.filename;
        const hasFileExtension = /\.\w{3,6}$/.test(result);
        if (hasFileExtension) {
          // Remove the file extension
          result = result.slice(0, result.lastIndexOf('.'));
        }
      }
    }
    return result;
  }

  async publishDraft () {
    const doPublish = async () => {
      assert(this.id);
      this._draft_state = {};

      // if user reverts, it will be back to this point in time
      this._published_state = this.getDocState();

      const r = await publishDraft(this.id, this.version);
      this.version = r.version;
    };
    await this.docWriteSequencer.enqueue(doPublish);
  }

  useDraft () {
    if (!this.hasDraft) {
      return;
    }
    this.setDocState(this._draft_state);
  }

  async discardDraft (removeFromServer = false) {
    this.setDocState(this._published_state);

    if (this.id && this.hasDraft && removeFromServer) {
      const doDiscard = async () => {
        assert(this.id);
        this._draft_state = {};
        const r = await discardDraft(this.id, this.version);
        this.version = r.version;
      };
      await this.docWriteSequencer.enqueue(doDiscard);
    }
  }

  filterNodes (nodeFilter, preserveLayout = false) {
    if (this.dom && nodeFilter && nodeFilter.length) {
      applyNodeFilter(this.dom, nodeFilter, preserveLayout);
      this._disableSave = true;
    }
  }

  // XXX: This is a pure function with no dependencies on the document. Move it out.
  sameSet (setA, setB) {
    for (const elem of setB) {
      if (!setA.has(elem)) {
        return false;
      }
    }
    for (const elem of setA) {
      if (!setB.has(elem)) {
        return false;
      }
    }
    return true;
  }

  /**
   * @param {string} idSlug
   * @param {string} [auth_token]
   * @param {string} [embedReferrer]
   */
  async refresh (idSlug, auth_token, embedReferrer) {
    const newDoc = await getDocument(idSlug, auth_token, embedReferrer != null, embedReferrer);
    const oldSourceIds = new Set();
    const newSourceIds = new Set();
    this.sources.forEach(source => oldSourceIds.add(source.id));
    if (newDoc.sources?.length) {
      newDoc.sources.forEach(source => newSourceIds.add(source.id));
    }
    this._copyProps(newDoc);
    // Parse body and assign as the DOM state
    const domBody = this.parseDocBody(newDoc.body);
    // XXX: if the body fails to parse, then we keep the old body, but still
    // update other properties. Is there a better way to go?
    if (domBody) {
      this.stats = getStats(domBody);
      this.setDom(domBody);
    }
    // Read draft state
    this._draft_state = this.parseDraftState(newDoc.draft);
    // If user reverts, it will be back to this point in time
    this._published_state = this.getDocState();
    // We've just nuked the thing - so it can't be dirty :)
    this.isDirty = false;
    // Check to see if sources list is still the same.
    // We have a separate monitor taking care up keeping sources
    // up to date, so this is mainly here for if a user is looking
    // at a doc that has just be published with new sources, or
    // collaborating with the editor who is adding/removing sources.
    if (!this.sameSet(oldSourceIds, newSourceIds)) {
      await this.reloadAllSources();
    }
    return this;
  }

  /**
   *
   * @param {(attr: Record<string, any>) => boolean} predicate
   */
  collectFormulas (predicate = () => true) {
    /** @type {string[]} */
    const formulas = [];
    const processAttr = (/** @type {{ [s: string]: any; } | ArrayLike<any>} */ attr) => {
      if (attr) {
        for (const val of Object.values(attr)) {
          if (isFormula(val) && predicate(attr)) {
            formulas.push(val);
          }
          else if (Array.isArray(val)) {
            val.forEach(processAttr);
          }
          else if (typeof val === 'object') {
            processAttr(val);
          }
        }
      }
    };
    visit(this.dom, (/** @type {DocNode} */ node) => processAttr(node.attr));
    return formulas;
  }
}

Doc.fetch = async function (idSlug, auth_token, embed, embedReferrer) {
  const docPayload = await getDocument(idSlug, auth_token, embed, embedReferrer);
  return new Doc(docPayload, auth_token);
};

/**
 * @param {import('@grid-is/apiary').Workbook} workbook
 * @param {string | null} newSingleObjectName
 * @param {'sheet' | 'table'} kind
 * @returns {import('@/editor/EditorEventStream').ReferenceRenaming | undefined}
 */
function singleObjectRenaming (workbook, newSingleObjectName, kind) {
  if (!newSingleObjectName) {
    return;
  }
  const prevObjects = kind === 'sheet' ? workbook.getSheets() : workbook.getTables();
  if (prevObjects.length !== 1) {
    return;
  }
  const prevSingleObjectName = prevObjects[0].name;
  const getMethod = kind === 'sheet' ? 'getSheet' : 'getTable';
  const model = workbook._model;
  const firstWorkbookWithThisName = model.getWorkbooks().find(wb => wb[getMethod](prevSingleObjectName) != null);
  return {
    from: prevSingleObjectName,
    to: newSingleObjectName,
    includeUnprefixed: firstWorkbookWithThisName === workbook,
  };
}

/**
 * @param {{
 *   type?: string,
 *   origException?: Error,
 *   vertexIds?: import('@grid-is/apiary/lib/VertexIdSet').default,
 * }} error
 * @param {Model} model
 */
function isEvalFailedAboutUnparseableFormulaInGridSheet (error, model) {
  if (
    error.type === 'evalfailed' &&
    error.origException instanceof ParseFailureEvaluationError &&
    error.vertexIds != null
  ) {
    return error.vertexIds.workbookKeys().every(key => model.getWorkbookByKey(key)?.type === 'native');
  }
  return false;
}
