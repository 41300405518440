// import MODE_GOOGLE from ./constants (instead of ../mode like other modules do), to dodge a dependency cycle
import { ERROR_NAME, ERROR_REF, ERROR_VALUE, MODE_GOOGLE, MAX_COL, MAX_ROW } from './constants';
import { parse as parseReference, colFromOffs } from './referenceParser';
import Range from './referenceParser/Range.js';
import { isMatrix, default as Matrix } from './Matrix';
import Cell from './Cell';
import FormulaError from './FormulaError';
import { isCellValue, isStr } from '../typeguards';
import { invariant } from '../validation';
import { box, type MaybeBoxed } from './ValueBox.js';
import { ResolveAreaOptions, type ReturnOptions } from '../ResolveAreaOptions';
import { Signal, type CallbackWithStop } from '../Signal';
import { needsQuoting, type ParsedReference } from './referenceParser/parser.js';
import { ERROR_CALC_LAMBDA_NOT_ALLOWED, isLambda } from './lambda';
import { isContextDependent } from './ast-common';
import type { EvaluationContext } from './EvaluationContext';
import type { ArrayValue, FormulaValue, IterationOptions, MaybeBoxedFormulaValue } from './types';
import type Workbook from '../Workbook';
import type { AreaArray, AreaBoxedValueArray, AreaCellArray, AreaValueArray, Sheet } from '..';
import { excelEqual } from '../utils';
import type { AreaArrayElement } from '../AreaArray';

// A1 reference syntax functions
const QUOT = "'";

function isErr (d: unknown): d is FormulaError {
  return d instanceof FormulaError;
}

export type NameReference = Reference & { name: string, size: undefined };
export type A1Reference = Reference & { range: Range, size: number };

/**
 * Generate a prefix according to the workbook name and sheet name of .
 * The prefix may be empty, but if it is not, it ends with '!' so that the reference range expression can be
 * concatenated directly onto it.
 * @param ref a reference to create a prefix for (or a plain JS object pretending to be one)
 * @param filePart false to ignore the workbook part of the reference
 * @return the prefix for the given reference
 */
export function prefix (
  ref:
    | Reference
    | (Partial<ParsedReference> & {
      sheetName?: string,
      workbookName?: string,
    }),
  filePart: boolean | undefined = true,
): string {
  let r = '';
  let quote = false;
  if (filePart) {
    if (ref.workbookName) {
      r += '[' + ref.workbookName + ']';
      quote ||= needsQuoting(ref.workbookName);
    }
  }
  if (ref.sheetName) {
    r += ref.sheetName || '';
    quote ||= needsQuoting(ref.sheetName);
  }
  // apply quoting if it contains any characters that call for it:
  if (quote) {
    r = QUOT + r.replace(/'/g, "''") + QUOT;
  }
  if (r) {
    r += '!';
  }
  return r;
}

type ReferenceArgs = {
  sheetName?: string | null,
  workbookName?: string | null,
  dynamic?: boolean,
  nonValue?: boolean,
  conditional?: boolean,
  ctx?: EvaluationContext,
};

type Inverted = 0 | 1 | 2 | 3;

/**
 * Reference to a single cell or range of cells.
 *
 * @param [ref] a cell address (A1, $A$1, Sheet1!A1, etc.) or range (Sheet1!A1:B2, etc.)
 *   or name (totalRevenue) or another Reference instance
 * @param [sheetName] name of the sheet where ref should be resolved.
 *   Overridden by an explicit sheet name in `ref` if present.
 * @param workbook name of the workbook where ref and sheetName should
 *   be resolved. Overridden by an explicit workbook name in `ref` if present.
 */
class Reference {
  /**
   * True if the referenced cell(s) are not certain to be required up-to-date
   * before the depending cell is evaluated. For instance, IF(A1, B1, C1) will
   * reference B1 and C1 conditionally (and may need _neither_ of them, if A1
   * resolves to an error value. Set to true for IF true/false branch references
   */
  readonly conditional: boolean;

  /**
   * True if this reference originates from a reference function like
   * `INDIRECT(something)` , not a static reference like Sheet1!A1:B2. Such a
   * reference is not represented in the dependency graph and thus is not
   * guaranteed by the recalculation algorithm to be up-to-date when the
   * referencing formula is evaluated. Thus all formula cells in or affecting
   * the referenced range must be checked up-to-date if/when the referenced
   * values turn out to be needed in evaluating the referencing formula.
   */
  readonly dynamic: boolean;

  /**
   * If true, this reference will not be used to read cell values. This is
   * for use by dependencies such as that of `FORMULATEXT(A1)`, which depends
   * on the formula of cell A1 but not on its value, so should not require A1
   * to be up-to-date before the depending cell is evaluated.
   */
  readonly nonValue: boolean;

  readonly workbookName: string;
  readonly sheetName: string;
  readonly range: Range | null;
  readonly name: string | null;
  readonly ctx: EvaluationContext | undefined;
  readonly inverted: Inverted;
  static parse = parseReference;
  static from = <Arg extends string | A1Reference | NameReference | Reference | Range>(
    cellRef: Arg,
    options?: ConstructorParameters<typeof Reference>[1],
    silent = true,
  ): Arg extends Range ? A1Reference : Arg extends Reference ? Arg : Reference | null => {
    try {
      return new Reference(cellRef, options) as Arg extends Range
        ? A1Reference
        : Arg extends Reference
          ? Arg
          : Reference | null;
    }
    catch (err) {
      if (!silent) {
        console.error(`Illegal reference ${cellRef} with ${options?.sheetName} ${options?.workbookName}`, err);
      }
    }
    // @ts-expect-error this is assured dynamically by the constructor
    return null;
  };

  /**
   * @param ref a cell address or name, or another `Reference` (copy) or `Range`.
   * @param [options]
   * @param [options.sheetName=''] sheet name to use if none is found in `ref`
   * @param [options.workbookName=''] workbook name to use if none is found in `ref`
   * @param [options.dynamic=false]
   * @param [options.nonValue=false]
   * @param [options.conditional=false]
   * @param [options.ctx]
   */
  constructor (
    ref: string | Reference | Range,
    { sheetName, workbookName, dynamic, nonValue, conditional, ctx }: ReferenceArgs = {},
  ) {
    this.conditional = conditional || false; //
    this.dynamic = dynamic || false;
    this.nonValue = nonValue || false;
    this.workbookName = workbookName || '';
    this.sheetName = sheetName || '';

    if (ref instanceof Range) {
      this.range = ref;
      this.name = null;
      this.workbookName = workbookName || '';
      this.sheetName = sheetName || '';
      this.ctx = ctx;
      this.inverted = ref.inverted;
    }
    else if (ref) {
      let param: ParsedReference | Reference | null = null;
      if (isRef(ref)) {
        this.conditional = conditional ?? ref.conditional;
        this.dynamic = dynamic ?? ref.dynamic;
        this.nonValue = nonValue ?? ref.nonValue;
        this.ctx = ctx ?? ref.ctx;
        param = ref;
      }
      else {
        invariant(isStr(ref));
        param = parseReference(ref);
        this.ctx = ctx;
      }
      if (param) {
        this.name = param.name;
        this.range = param.range;
        this.workbookName = param.workbookName || workbookName || '';
        this.sheetName = param.sheetName || sheetName || '';
        this.inverted = param.inverted;
      }
      else {
        throw new Error(`Not a valid reference: ${ref}`);
      }
    }
    else {
      throw new Error('No ref was passed');
    }
    invariant(
      !(this.isAddress && this.workbookName && !this.sheetName),
      'A1 reference should not have workbook name and no sheet name',
    );
  }

  /** Legacy alias for property `ctx` */
  get _ () {
    return this.ctx;
  }

  get width (): number {
    if (!this.range) {
      throw new Error('Cannot get width of a name reference');
    }
    return this.range.width;
  }

  get height (): number {
    if (!this.range) {
      throw new Error('Cannot get height of a name reference');
    }
    return this.range.height;
  }

  /**
   * Return a cloned instance with the range shifted and/or changed in size.
   *
   * Only call this for an address reference, it will throw on a name reference.
   */
  offset (rows: number, cols: number, height: number, width: number): A1Reference {
    if (!this.range) {
      throw new Error('Cannot offset a name reference');
    }
    if (!isFinite(rows)) {
      throw new Error('Cannot offset reference by rows ' + rows);
    }
    if (!isFinite(cols)) {
      throw new Error('Cannot offset reference by cols ' + cols);
    }
    if (!isFinite(width) || width <= 0) {
      throw new Error('Cannot resize reference to width ' + width);
    }
    if (!isFinite(height) || height <= 0) {
      throw new Error('Cannot resize reference to height ' + height);
    }
    const top = this.range.top + rows;
    const left = this.range.left + cols;
    if (top < 0 || left < 0) {
      throw new Error(`Offset resolves to negative coordinates ${top}, ${left}`);
    }
    return new Reference(
      new Range({
        top: top,
        left: left,
        bottom: Math.min(top + height - 1, MAX_ROW),
        right: Math.min(left + width - 1, MAX_COL),
        $top: this.range.$top,
        $left: this.range.$left,
        $bottom: this.range.$bottom,
        $right: this.range.$right,
      }),
      this,
    ) as A1Reference;
  }

  get size () {
    return this.range?.size;
  }

  is1D () {
    return this.width === 1 || this.height === 1;
  }

  /**
   * True if this is a reference to a cell (A1) or a cell range (A1:B2), false if this is a reference to a name.
   */
  get isAddress () {
    return this.range != null;
  }

  toString (abs: boolean = false): string {
    return prefix(this) + (this.name || this.range!.toString(abs));
  }

  /**
   * Iterate over all cells of this range, yielding a single-cell address reference for each cell.
   * The Reference instance yielded from this generator is reused, so the consumer must not hold on to it, only use it
   * during each yield.
   * @throws {Error} if this is not an address reference and cannot be resolved to one
   */
  * [Symbol.iterator] (): IterableIterator<A1Reference> {
    let resolved: MaybeBoxedFormulaValue;
    if (this.name) {
      invariant(this.ctx, 'Name reference must have a ctx to iterate over its cells');
      resolved = this.resolveToNonName(this.ctx);
    }
    else {
      resolved = this;
    }
    if (!isRef(resolved)) {
      throw new Error(`Cannot iterate this name reference, it resolves to ${typeof resolved}, not a range`);
    }
    const itm = new Reference(resolved);
    const { top, bottom, left, right } = itm;
    for (let c = left; c <= right; c++) {
      for (let r = top; r <= bottom; r++) {
        // @ts-expect-error
        itm.range = new Range({ top: r, left: c, bottom: r, right: c });
        yield itm as A1Reference;
      }
    }
  }

  /**
   * Return a cloned instance with range set to the top-left corner of this
   * reference's range.
   *
   * Only call this for an address reference, it will throw on a name reference.
   */
  collapse () {
    if (!this.range) {
      throw new Error('Cannot collapse a name reference');
    }
    return new Reference(
      new Range({
        top: this.range.top,
        left: this.range.left,
        bottom: this.range.top,
        right: this.range.left,
        $top: this.range.$top,
        $left: this.range.$left,
        $bottom: this.range.$bottom,
        $right: this.range.$right,
      }),
      this,
    ) as A1Reference;
  }

  /**
   * Return a new Reference that refers to row r (zero-based) of this range.
   * This must be an address reference (having a .range and not a .name).
   * Note that bounds checking is not performed; this can yield cells outside this range.
   *
   * @param [r=0] which row to get (0 for first row, 1 for second, etc.)
   * @returns a reference like this one but narrowed to that row. The returned instance
   *   is guaranteed to be new, even if it is identical to `this`.
   */
  collapseToRow (r: number = 0): A1Reference {
    if (!this.range) {
      throw new Error('Cannot collapseToRow a name reference');
    }
    const rowIndex = this.range.top + r;
    return new Reference(
      new Range({
        ...this.range,
        top: rowIndex,
        bottom: rowIndex,
      }),
      this,
    ) as A1Reference;
  }

  /**
   * Return a cloned instance that refers to column c (zero-based) of this range.
   * This must be an address reference (having a .range and not a .name).
   * Note that bounds checking is not performed; this can yield cells outside this range.
   *
   * @param [c=0] which column to get (0 for first, 1 for second, etc.)
   * @returns a reference like this one but narrowed to that column. The returned instance
   *   is guaranteed to be new, even if it is identical to `this`.
   */
  collapseToColumn (c: number = 0): A1Reference {
    if (!this.range) {
      throw new Error('Cannot collapseToColumn a name reference');
    }
    const colIndex = this.range.left + c;
    return new Reference(
      new Range({
        ...this.range,
        left: colIndex,
        right: colIndex,
      }),
      this,
    ) as A1Reference;
  }

  /**
   * Return a cloned instance that refers to the cell at row r, column c (zero-based) of this range.
   * This must be an address reference (having a .range and not a .name).
   * Note that bounds checking is not performed; this can yield a cell outside this range. The returned instance
   *   is guaranteed to be new, even if it is identical to `this`.
   *
   * @param [r=0] which row to get (0 for first, 1 for second, etc.)
   * @param [c=0] which column to get (0 for first, 1 for second, etc.)
   * @returns a reference like this one but narrowed to that cell.
   */
  collapseToCell (r: number = 0, c: number = 0): A1Reference {
    if (!this.range) {
      throw new Error('Cannot collapseToCell a name reference');
    }
    const rowIndex = this.range.top + r;
    const colIndex = this.range.left + c;
    return new Reference(
      new Range({
        ...this.range,
        top: rowIndex,
        bottom: rowIndex,
        left: colIndex,
        right: colIndex,
      }),
      this,
    ) as A1Reference;
  }

  /**
   * Return a reference to the zero-based nth cell in a left-to-right-then-top-down traversal of this range.
   * This must be an address reference (having a .range and not a .name).
   * Note that bounds checking is not performed; this can yield a cell outside this range.
   * The returned instance is guaranteed to be new, even if it is identical to `this`.
   * @param n zero-based index of the cell to return
   * @returns reference like this one except narrowed to the single cell specified.
   */
  collapseToNthCell (n: number = 0): A1Reference {
    const colIndex = n % this.width;
    const rowIndex = Math.floor(n / this.width);
    return this.collapseToCell(rowIndex, colIndex);
  }

  /**
   * Get fully-qualified cell ID. This is either a global name or a cell address prefixed with sheet name ('Sheet1!A1')
   * and (if present) a workbook pathname. The sheet name (or global name) is cased as in this reference (or in `cellID`)
   * which may be arbitrary. If you need consistent casing, use `getCanonicalCellId`.
   * @param cellID optional, cell ID to qualify. If null (the default), use the (top-left) cell ID of this reference.
   * @return the cell ID prefixed with sheet name and with workbook pathname if present
   */
  getRefId (cellID: string | null = null): string {
    if (cellID) {
      // parseReference returns immutable objects, hence the need to copy
      const ref = Object.assign({}, parseReference(cellID), {
        sheetName: this.sheetName,
        // hack for prefix (ref isn't really a Reference instance so it doesn't have the `isAddress` property});
        isAddress: true,
      });
      return prefix(ref) + (ref.range ? colFromOffs(ref.range.left) + (ref.range.top + 1) : ref.name);
    }
    return prefix(this) + this.getCellId();
  }

  /**
   * Get this reference's name if this is a name reference, else the unprefixed A1 address of the top-left corner cell.
   */
  getCellId (): string {
    return this.range ? colFromOffs(this.range.left) + (this.range.top + 1) : this.name!;
  }

  /**
   * Return a cloned instance with the given range.
   */
  withRange (range: Range | ConstructorParameters<typeof Range>[0]) {
    return new Reference(range instanceof Range ? range : new Range(range), this) as A1Reference;
  }

  /**
   * Return a cloned instance with the given evaluation context.
   * Used to be called `resolver` in many places.
   */
  withContext (ctx: EvaluationContext | undefined): Reference {
    return new Reference(this, { ctx });
  }

  resolveSingle (): ArrayValue {
    const c = this.resolveCell();
    if (c == null) {
      return c;
    }
    const result = c.v;
    if (isRef(result) || isMatrix(result)) {
      return result.resolveSingle();
    }
    return result;
  }

  resolveSingleBoxed (): MaybeBoxed<ArrayValue> {
    const c = this.resolveCell();
    if (c == null) {
      return c;
    }
    const result = c.v;
    if (isRef(result) || isMatrix(result)) {
      return result.resolveSingleBoxed();
    }
    if (c.z) {
      return box(result, { numberFormat: c.z });
    }
    return result;
  }

  /**
   * @param [requireSheet=true] set to false to return a null sheet rather than throw an error
   * @returns error if workbook fails to resolve, or if sheet fails to resolve and `requireSheet` is true
   * @throws {FormulaError} if this reference is missing a resolver.
   */
  resolveWorkbookAndSheet (
    resolver?: EvaluationContext | null,
    requireSheet: boolean = true,
  ):
    | {
      workbook: Workbook,
      sheet: Sheet | null,
    }
    | FormulaError {
    if (resolver == null) {
      resolver = this.ctx;
    }
    if (!resolver) {
      throw ERROR_VALUE.detailed('Internal error: Reference missing a resolver');
    }
    const sheetName = this.sheetName || resolver.sheetName;
    let workbook: Workbook | undefined;
    const sheet = resolver.resolveSheet(sheetName || '', this.workbookName);
    if (sheet == null) {
      workbook = resolver.resolveWorkbook(this.workbookName);
      if (requireSheet) {
        return ERROR_REF.detailed(
          this.workbookName && !workbook ? `Workbook not found: ${this.workbookName}` : `Sheet not found: ${sheetName}`,
        );
      }
    }
    else {
      workbook = resolver.getWorkbookByKey(sheet.workbookKey);
    }
    if (!workbook) {
      return ERROR_REF.detailed(this.workbookName ? `Workbook not found: ${this.workbookName}` : 'No workbooks loaded');
    }
    return { workbook, sheet };
  }

  /**
   * Resolve this reference to an AreaArray, or if that's not possible, throw (don't return) a `FormulaError` instance.
   * NOTE: the area array is populated only where the sheet is populated, so the array itself may have smaller
   * dimensions if this reference extends beyond sheet bounds, but will have bounds and default-value information to
   * to represent the rest.
   * @returns an area array, in which:
   *   * `sheetName` is exactly the `sheetName` of this reference (which may be null, and if not null, may differ in
   *     case from the actual name of the referenced sheet)
   *   * `workbookName` is exactly the name of the workbook to which the `workbookName` of this reference resolves
   *     (which is the default workbook of this reference's resolver, if this reference does not have a workbookName).
   *     Note that unlike the `sheetName` attribute, this `workbookName` attribute can differ in case from that of the
   *     reference.
   * @throws {FormulaError} if:
   *   * this is a name reference and the name is not found, or its formula evaluates to a string or number or error, or
   *     fails to evaluate due to a circular dependency in defined names referencing one another
   *   * this reference's workbook is not found in this reference's resolver (can also happen if this reference has no
   *     `workbookName`, if there are _no_ workbooks in this reference's resolver, though this is probably an edge case)
   */
  _resolveArea<O extends ReturnOptions> (options: ResolveAreaOptions<O>): AreaArray<AreaArrayElement<O>> {
    const resolver = this.ctx;
    if (!resolver) {
      throw ERROR_VALUE.detailed('Internal error: Reference missing a resolver');
    }
    if (this.name) {
      const resolved = this.resolveToNonName(resolver);
      if (isErr(resolved)) {
        throw resolved;
      }
      if (isLambda(resolved)) {
        throw ERROR_CALC_LAMBDA_NOT_ALLOWED;
      }
      if (!isRef(resolved)) {
        const mx = isMatrix(resolved) ? resolved : Matrix.of(resolved);
        return mx._resolveArea(options);
      }
      return resolved._resolveArea(options);
    }
    const resolved = this.resolveWorkbookAndSheet(resolver);
    if (isErr(resolved)) {
      throw resolved;
    }
    const { workbook, sheet } = resolved;
    invariant(sheet != null);
    const { area, ...attributes } = sheet.resolveArea(this.range!, options);

    return Object.assign(area, attributes, {
      sheetName: sheet.name,
      workbookName: workbook.name,
    });
  }

  /**
   * @throws {FormulaError} if:
   *   * this is a name reference and the name is not found, or its formula evaluates to a string or number or error, or
   *     fails to evaluate due to a circular dependency in defined names referencing one another
   *   * this reference's workbook is not found in this reference's resolver (can also happen if this reference has no
   *     `workbookName`, if there are _no_ workbooks in this reference's resolver, though this is probably an edge case)
   */
  resolveAreaCells (cropTo?: 'any-cell-information' | 'cells-with-non-blank-values'): AreaCellArray {
    return this._resolveArea(new ResolveAreaOptions({ returnCells: true, returnBoxed: true, cropTo })) as AreaCellArray;
  }

  /**
   * @throws {FormulaError} if:
   *   * this is a name reference and the name is not found, or its formula evaluates to a string or number or error, or
   *     fails to evaluate due to a circular dependency in defined names referencing one another
   *   * this reference's workbook is not found in this reference's resolver (can also happen if this reference has no
   *     `workbookName`, if there are _no_ workbooks in this reference's resolver, though this is probably an edge case)
   */
  resolveAreaValues () {
    return this._resolveArea(new ResolveAreaOptions({ returnCells: false, returnBoxed: false })) as AreaValueArray;
  }

  resolveAreaBoxed () {
    return this._resolveArea(new ResolveAreaOptions({ returnCells: false, returnBoxed: true })) as AreaBoxedValueArray;
  }

  /**
   * Produce a `Matrix` populated with the cell values from the range of this reference.
   */
  toMatrix (expandError: boolean = true): Matrix | FormulaError {
    try {
      const area = this.resolveAreaBoxed();
      const width = area.right - area.left + 1;
      const height = area.bottom - area.top + 1;
      const mx = new Matrix(width, height);
      mx.setData(area);
      this.ctx?.recordDependencyUse?.(this);
      return mx;
    }
    catch (err) {
      if (err instanceof FormulaError) {
        if (expandError && this.ctx?.mode !== MODE_GOOGLE && this.isAddress && this.size! > 1) {
          return new Matrix(this.width, this.height, err);
        }
        return err;
      }
      throw err;
    }
  }

  /**
   * Return the cell values of the given range, optionally skipping blank cells.
   * Default is to skip _unpopulated_ blanks, i.e. blank values in unpopulated
   * areas.
   * @param [opts={ leaveBoxed: false, skipBlanks: 'unpopulated' }]
   * @return the specified cell values, or error if this is
   *   a name reference that cannot be resolved, or whose formula resolves to an error or a non-range-reference value.
   */
  resolveRange<Boxed extends boolean = false> (
    opts?: IterationOptions<Boxed>,
  ): Array<Boxed extends false ? ArrayValue : MaybeBoxed<ArrayValue>> | FormulaError {
    type ElementType = Boxed extends false ? ArrayValue : MaybeBoxed<ArrayValue>;
    const resolver = this.ctx;
    if (!opts) {
      opts = { leaveBoxed: false as Boxed, skipBlanks: 'unpopulated' };
    }
    if (!resolver) {
      return ERROR_VALUE;
    }
    if (this.name) {
      const resolved = this.resolveToNonName(resolver);
      if (isErr(resolved)) {
        return resolved;
      }
      if (!isRef(resolved)) {
        return ERROR_NAME.detailed(
          `Cannot evaluate defined name "${this.name}" as a range, because it returns ${typeof resolved}`,
        );
      }
      return resolved.resolveRange(opts);
    }
    const matrix = this.toMatrix(false);
    if (isErr(matrix)) {
      return matrix;
    }
    const range: ElementType[] = [];
    for (const { value } of matrix.iterAll(opts)) {
      range.push(value as ElementType);
    }
    return range;
  }

  cropToSheet (sheet: Sheet) {
    if (!this.range) {
      throw new Error('Cannot cropToSheet a name reference');
    }
    const [ sheetWidth, sheetHeight ] = sheet.getSize();
    if (sheetHeight > this.bottom && sheetWidth > this.right) {
      return this;
    }
    const cropped = this.withRange({
      ...this.range,
      right: Math.max(Math.min(this.range.right, sheetWidth - 1), 0),
      bottom: Math.max(Math.min(this.range.bottom, sheetHeight - 1), 0),
    });
    return cropped;
  }

  /**
   * Return true if any of the referenced cells satisfy the given predicate, else false.
   *
   * NOTE THIS GOTCHA: if the reference fails to resolve, then this currently
   * returns a `FormulaError` ... which is truthy. So logic that uses the return
   * value in a boolean context may mistake a failure to resolve for the answer
   * that “yes, one of the referenced cells satisfies the given predicate” which
   * is _not_ what is meant by an error return value. This is likely to lead to
   * unintended behavior. So callers should explicitly compare the return value
   * with `true` if that is the meaning being looked for.
   *
   * FIXME: redesign this to avoid the above gotcha, e.g. by throwing the error
   * rather than returning it. (And update call sites to handle this change.)
   *
   * @param predicate a function returning true/false for a given cell
   * @return true if the predicate returns true for one of the referenced cells. Error if:
   *   * this is a name reference and the name is not found, or resolves to a string or number or error, or
   *     encounters a circular dependency via defined names referencing one another
   *   * this reference is workbook-qualified
   *   * this reference has no resolver
   */
  any (predicate: (arg0: Cell | null) => boolean): boolean | FormulaError {
    const resolver = this.ctx;
    if (!resolver) {
      return ERROR_VALUE.detailed('Internal error: Reference.any called on a reference with no resolver');
    }
    if (this.name) {
      const resolved = this.resolveToNonName(resolver);
      if (isErr(resolved)) {
        return resolved;
      }
      if (!isRef(resolved)) {
        return ERROR_NAME.detailed(
          `Cannot iterate area cells of defined name "${this.name}", because it returns ${typeof resolved}`,
        );
      }
      return resolved.any(predicate);
    }
    invariant(this.range);
    const resolved = this.resolveWorkbookAndSheet(resolver, this.isAddress);
    if (isErr(resolved)) {
      return resolved;
    }
    const { sheet } = resolved;
    if (sheet == null) {
      return ERROR_REF.detailed(`Sheet not found: ${this.sheetName || resolver.sheetName}`);
    }
    const [ cols, rows ] = sheet.getSize();
    const minR = Math.max(this.range.top, 0);
    const maxR = Math.min(this.range.bottom, rows);
    const minC = Math.max(this.range.left, 0);
    const maxC = Math.min(this.range.right, cols);
    for (let r = minR; r <= maxR; r++) {
      for (let c = minC; c <= maxC; c++) {
        const cell = sheet.getCellByCoords(r, c);
        if (predicate(cell)) {
          return true;
        }
      }
    }
    return false;
  }

  resolveCell (): Cell | null {
    // cannot cast an area to a cell
    if (this.range && this.range.size > 1) {
      return new Cell({ v: ERROR_VALUE });
    }
    // need to have a context
    const ctx = this.ctx;
    if (!ctx) {
      return new Cell({ v: ERROR_VALUE });
    }
    if (this.name) {
      const resolved = this.resolveToNonName(ctx);
      if (isRef(resolved) || isMatrix(resolved)) {
        return resolved.resolveCell();
      }
      const cell = new Cell({ v: null });
      cell.v = resolved;
      return cell;
    }

    const effectiveSheetName = this.sheetName || ctx.sheetName || '';
    // at least one workbook must be present
    const sheet = ctx.resolveSheet(effectiveSheetName, this.workbookName);
    if (sheet == null) {
      let msg;
      if (this.workbookName || !ctx.resolveWorkbook(this.workbookName)) {
        msg = `Workbook not found: ${this.workbookName}`;
      }
      else {
        const where = this.workbookName ? ` in workbook ${this.workbookName}` : '';
        msg = `Sheet not found: ${effectiveSheetName}${where}`;
      }
      return new Cell({ v: ERROR_REF.detailed(msg) });
    }
    const cell = sheet.getCellByCoords(this.top, this.left);
    this.ctx?.recordDependencyUse?.(this);
    return cell;
  }

  /**
   * Resolve a single cell from this reference. This differs from `resolveCell`
   * and `resolveAreaValues` and `resolveAreaCells` in that:
   * * if a defined-name formula evaluates to a non-reference, this returns the
   *   defined-name cell object itself (where `resolveCell` would return a blank
   *   cell object, and `resolveAreaValues` and `resolveAreaCells` would return
   *   an error, wrapped in a cell object in the latter case)
   * * if this (or the result of the last defined-name that does not evaluate to
   *   a name reference) is a range reference, this returns the top left cell of
   *   the referenced range, but `resolveCell` returns an artificial blank cell.
   *
   * The reference must have a context, else this will throw.
   */
  resolveCellOrDefinedName (): Cell | null {
    invariant(this.ctx);
    const { resolved, nameCell } = this._resolveToNonName(this.ctx);
    if (isRef(resolved)) {
      invariant(resolved.isAddress);
      return resolved.collapse().resolveCell();
    }
    invariant(nameCell);
    return nameCell;
  }

  /**
   * Resolve name reference if that's what this is, returning what its formula resolves to.
   * If this is already an address range reference, it is just returned.
   * If it is a name reference, the `rawOutput` result of that name's formula is returned. That may be:
   * * another reference (if the formula is e.g. `=Sheet1!A1`)
   * * a number, string, boolean or `FormulaError` (for e.g. `=A1+B2`, `=A1&B2`, `=A1=B2`, or `=IDONTEXIST()`)
   *
   * If the defined name has no last-computed value, or it is stale, or the
   * formula is context-dependent, then the formula must be evaluated in this
   * call. If this happens, the result is just returned but is _not_ assigned as
   * the last-computed value (`.v`) of the defined-name object. This is because
   * the change of .v here would not propagate to dependents (because it is not
   * occurring in the recalculation algorithm) — and then when recalculation
   * happens, it may evaluate this defined-name formula again and get the same
   * value, and thus consider it up-to-date and not propagate recalculation to
   * dependents ... so recalculation may incorrectly fail to update those
   * dependent cells.
   * @param [opts] the evaluation context, if not this.ctx
   * @returns resolved: the result of evaluating the named formula (`#NAME?` if
   *   the name is not found), and nameCell: the defined-name cell object to
   *   which the name was resolved
   */
  resolveName (opts?: EvaluationContext): { resolved: MaybeBoxedFormulaValue, nameCell?: Cell } {
    // TODO: merge opts with this._ and/or reduce opts to just take in contextSheetName or something
    if (this.isAddress) {
      return { resolved: this };
    }
    invariant(this.name);
    if (!opts) {
      opts = this.ctx;
    }
    invariant(opts, 'resolveName requires an evaluation context');
    const ctx = this.workbookName ? opts.resolveWorkbook(this.workbookName) || opts : opts;
    const nameCell = ctx.resolveName(this.name, this.sheetName);
    opts.recordDependencyUse?.(this);
    if (!nameCell) {
      // tolerate nulls from resolveName (simplifies tests)
      return { resolved: ERROR_NAME.detailed(`Name not found: ${this}`) };
    }
    if (isErr(nameCell)) {
      return { resolved: nameCell };
    }
    let currentValue = nameCell.v;
    if (isRef(currentValue)) {
      currentValue = maybeGiveExplicitPrefixToDefinedNameReferenceResult(currentValue, opts);
    }
    if (currentValue != null && !opts.isDirtyFormulaCell?.(nameCell) && !isContextDependent(nameCell._ast)) {
      // already evaluated and doesn't need context-dependent evaluation; just return it
      // (with number format workbook/sheetname made explicit (if ref) or numberformat applied (if cell value)
      const resolved = boxValueIfCellHasNumberFormat(currentValue, nameCell);
      return { resolved, nameCell };
    }
    // TODO: throw EvaluationOrderException if there's a value and it's _not_ up-to-date?
    if (nameCell._ast == null) {
      return {
        resolved: ERROR_NAME.detailed(
          `Cannot evaluate defined name "${this.name}" whose formula did not parse: ${nameCell.f}`,
        ),
        nameCell,
      };
    }
    invariant(opts.evaluateAST, 'Evaluation context for resolveName must have evaluateAST');
    // evaluate but guard against infinite recursion when name evaluates itself.
    let resolved;
    try {
      // @ts-expect-error
      if (this.__evaluating) {
        resolved = ERROR_NAME.detailed('Circular dependency in defined names');
      }
      else {
        // @ts-expect-error
        this.__evaluating = true;
        // note that nameCell may have been resolved cross-workbook, so it may
        // be in a different workbook and/or sheet! We must evaluate its formula
        // in that context.
        const workbook = opts.getWorkbookByKey(nameCell.workbookKey);
        const workbookName = workbook?.name || null;
        const sheetName = nameCell.sheetIndex == null ? null : workbook?.getSheetByIndex(nameCell.sheetIndex)?.name;
        resolved = opts.evaluateAST(nameCell._ast, {
          ...opts,
          rawOutput: true,
          workbookName,
          sheetName,
        });
      }
    }
    finally {
      // @ts-expect-error
      this.__evaluating = false;
    }
    // Just return the evaluation result, do not assign it to nameCell.v
    if (isRef(resolved)) {
      resolved = maybeGiveExplicitPrefixToDefinedNameReferenceResult(resolved, opts);
    }
    return { resolved, nameCell };
  }

  /**
   * Resolve this name reference (if that's what this is) and evaluate its formula, and if that yields a name reference,
   * recurse until something other than a name reference is obtained, or a loop is detected. If a loop is detected,
   * return `ERROR_NAME` with a circular dependency detail message. Else return the first non-name-reference result. If
   * this reference is already not a name reference, just return `this`.
   * @param [opts] the evaluation context, if not this.ctx
   * @returns this reference if it is not a name reference, else the first defined-name formula result
   *   that isn't a name reference, else `ERROR_NAME` if defined-name formula results form a loop of name references
   *   leading back to this reference. Guaranteed not to return a name reference.
   */
  resolveToNonName (opts?: EvaluationContext): MaybeBoxed<ArrayValue> | A1Reference | Matrix {
    return this._resolveToNonName(opts).resolved;
  }

  /**
   * Inner implementation of `resolveToNonName` that also returns the
   * defined-name Cell object from whose formula the result is obtained.
   * @param [opts] the evaluation context, if not this.ctx
   * @returns result of evaluating
   *   the formula of the last defined name that does not result in another name
   *   reference (else `this` if not a name reference, or `#NAME?` if any name
   *   is not found, or if following defined-name formula results leads into a
   *   loop of name references), and that last defined-name cell object if there
   *   is one. In the case of a reference loop there isn't one. The `resolved`
   *   may be a reference, but is guaranteed not to be a _name_ reference.
   */
  _resolveToNonName (opts?: EvaluationContext): {
    resolved: MaybeBoxed<ArrayValue> | A1Reference | Matrix,
    nameCell?: Cell,
  } {
    if (this.range != null) {
      return { resolved: this as A1Reference };
    }
    const alreadyResolvedNames: Set<string> = new Set();
    let resolved: MaybeBoxedFormulaValue = this;
    let nameCell: Cell | undefined;
    while (isRef(resolved) && typeof resolved.name === 'string' && !alreadyResolvedNames.has(resolved.name)) {
      alreadyResolvedNames.add(resolved.name);
      ({ resolved, nameCell } = resolved.resolveName(opts));
    }
    if (isRef(resolved) && resolved.name) {
      // while loop exited because alreadyResolvedNames.has(ref.name), so we found a circular dependency loop in names
      let namePath = Array.from(alreadyResolvedNames);
      namePath = namePath.slice(namePath.indexOf(resolved.name!));
      namePath.push(resolved.name!);
      return { resolved: ERROR_NAME.detailed('Circular dependency in defined names: ' + namePath.join(' -> ')) };
    }
    // @ts-expect-error (resolved is not a name reference; type checker does not infer this from the above )
    return { resolved, nameCell };
  }

  /**
   * Generate a prefix according to the workbook name and directory and the sheet name of this reference.
   * The prefix may be empty, but if it is not, it ends with '!' so that the reference range expression can be
   * concatenated directly onto it.
   *
   * @return the prefix for this reference
   */
  prefix (): string {
    return prefix(this);
  }

  /**
   * Return a cloned instance with the given changes made to prefix properties.
   * Any that are null or absent are not set (but you can pass '' to reset them).
   */
  withPrefix (p: { sheetName?: string, workbookName?: string }): Reference {
    const newParams: ConstructorParameters<typeof Reference>[1] = { ...this };
    if ('workbookName' in p) {
      newParams.workbookName = p.workbookName || '';
    }
    if ('sheetName' in p) {
      newParams.sheetName = p.sheetName || '';
    }
    const refArg = this.range ?? this.name;
    invariant(refArg);
    return new Reference(refArg, newParams);
  }

  contains (other: Reference): boolean {
    // first adjust for one possibly having a context workbook and the other not
    // (because that can confuse the check of same workbook and sheet)
    if (this.ctx && !other.ctx) {
      other = other.withContext(this.ctx);
    }
    else if (!this.ctx && other.ctx) {
      other = other.withPrefix({
        workbookName: other.workbookName || other.ctx.workbookName || '',
        sheetName: other.sheetName || other.ctx.sheetName || '',
      });
    }
    // is it the same workbook?
    if (
      !excelEqual(
        this.workbookName || this.ctx?.workbookName || '',
        other.workbookName || other.ctx?.workbookName || '',
      )
    ) {
      return false;
    }
    // is it the same sheet?
    if (!excelEqual(this.sheetName || this.ctx?.sheetName || '', other.sheetName || other.ctx?.sheetName || '')) {
      return false;
    }
    // are these named refs and if so do the names match?
    if (this.name && other.name && !excelEqual(this.name, other.name)) {
      return false;
    }
    // these must be ranges, so does the geometry match?
    return !!(this.range && other.range && this.range.contains(other.range!));
  }

  /**
   * Iterate over the formula cells pointed to by this reference. If `this` is
   * a name reference, `iterFormulaCells` returns the single defined name cell.
   * Precondition: Resolver (`this._`) must be set.
   */
  visitFormulaCells (callback: CallbackWithStop<Cell>) {
    const ctx = this.ctx;
    invariant(ctx != null);

    const wbName = this.workbookName || ctx.workbookName;
    const sheetName = this.sheetName || ctx.sheetName;

    if (this.name) {
      const wb = this.workbookName ? ctx.resolveWorkbook(this.workbookName) : null;
      if (this.workbookName && !wb) {
        // explicit workbook name that does not resolve; no cells to visit
        return;
      }
      // Visit that single defined-name cell, if it exists and has a formula.
      // One case where it does not have a formula is in a LET context, where a
      // name resolves to a fake cell carrying the LET-bound parameter value.
      const cell = (wb || ctx).resolveName(this.name, this.sheetName);
      if (cell instanceof Cell && cell.f) {
        callback(cell, new Signal());
      }
    }
    else {
      const sheet = ctx.resolveWorkbook(wbName)?.getSheet(sheetName);
      if (!sheet) {
        return;
      }
      if (this.range?.size === 1) {
        // single-cell reference, special-cased to skip R-tree
        const cell = sheet.getCellByCoords(this.range.top, this.range.left, false, true);
        if (cell) {
          callback(cell, new Signal());
        }
      }
      else {
        sheet.visitFormulaCellsIntersecting(this, callback);
      }
    }
  }

  isResolvable (): boolean {
    const resolver = this.ctx;
    if (!resolver) {
      return false;
    }
    if (this.name) {
      const resolved = this.resolveToNonName(resolver);
      if (isErr(resolved)) {
        return false;
      }
      if (isRef(resolved)) {
        return resolved.isResolvable();
      }
      return true;
    }
    const workbookAndSheet = this.resolveWorkbookAndSheet(resolver);
    if (isErr(workbookAndSheet)) {
      return false;
    }
    if (!workbookAndSheet.workbook || !workbookAndSheet.sheet) {
      return false;
    }
    return !!(workbookAndSheet.workbook && workbookAndSheet.sheet);
  }

  get top () {
    if (!this.range) {
      throw new Error('Cannot get top of a name reference');
    }
    return this.range.top;
  }

  get left () {
    if (!this.range) {
      throw new Error('Cannot get left of a name reference');
    }
    return this.range.left;
  }

  get bottom () {
    if (!this.range) {
      throw new Error('Cannot get bottom of a name reference');
    }
    return this.range.bottom;
  }

  get right () {
    if (!this.range) {
      throw new Error('Cannot get right of a name reference');
    }
    return this.range.right;
  }
}

/**
 * Return a reference like `ref` but with its effective workbook name made
 * explicit if different from that of `ctx`, and with its effective sheet name
 * made explicit if `ref` is an address reference with no explicit sheet name.
 */
function maybeGiveExplicitPrefixToDefinedNameReferenceResult (ref: Reference, ctx: EvaluationContext) {
  let explicitWorkbookName: string | undefined;
  if (
    !ref.workbookName &&
    ref.ctx?.workbookName &&
    ref.ctx.workbookName.toLowerCase() !== ctx.resolveWorkbook()?.name?.toLowerCase()
  ) {
    explicitWorkbookName = ref.ctx.workbookName;
  }
  let explicitSheetName: string | undefined;
  if (!ref.sheetName && ref.isAddress) {
    explicitSheetName = ref.ctx?.sheetName || ref.ctx?.resolveSheet()?.name;
  }
  if (explicitSheetName || explicitWorkbookName) {
    return ref.withPrefix({
      sheetName: explicitSheetName || ref.sheetName,
      workbookName: explicitWorkbookName,
    });
  }
  return ref;
}

function boxValueIfCellHasNumberFormat (formulaValue: FormulaValue, nameCell: Cell) {
  return isCellValue(formulaValue) && nameCell.z ? box(formulaValue, { numberFormat: nameCell.z }) : formulaValue;
}

export function isRef (d: any): d is Reference {
  return d instanceof Reference;
}

export function isA1Ref (d: any): d is A1Reference {
  return d instanceof Reference && d.range != null;
}

export function isNameRef (d: any): d is NameReference {
  return d instanceof Reference && d.name != null;
}

export function toRef (maybeRef: string | Reference | null | undefined): Reference | null {
  if (typeof maybeRef === 'string') {
    return Reference.from(maybeRef);
  }
  else if (maybeRef instanceof Reference) {
    return maybeRef;
  }
  return null;
}

/**
 * Check that the given reference is an A1 reference and return it as such.
 * @throws {Error} if the reference is not an A1 reference
 */
export function checkA1 (ref: Reference) {
  if (!ref.range) {
    throw new Error('Expected A1 reference');
  }
  return ref as A1Reference;
}

export default Reference;
