import { isDateFormat } from 'numfmt';

import { Cell, Range, Reference, type Sheet } from '@grid-is/apiary';
import { every, some } from '@grid-is/iterators';
import { assert } from '@grid-is/validation';

export function cellsAreComparable (a: Cell | null, b: Cell | null, ignoreNumberFormatting: boolean = false): boolean {
  return (
    a != null && b != null &&
    a.v != null && b.v != null &&
    typeof a.v === typeof b.v &&
    (ignoreNumberFormatting || a.z === b.z)
  );
}

type Side = 'left' | 'right' | 'top' | 'bottom';
export function trimBlanksFromRange (range: Range, sheet: Sheet, sides: Side[] = [ 'left', 'right', 'top', 'bottom' ]): Range {
  let { left, right, top, bottom } = range;
  const cropped = new Reference(range).cropToSheet(sheet).range;
  assert(cropped);
  // trim left
  if (sides.includes('left')) {
    while (left < cropped.right && allCellsBlank(cropped.collapseToColumn(left - range.left), sheet)) {
      left += 1;
    }
  }
  // trim right
  if (sides.includes('right')) {
    right = cropped.right;
    while (left < right && allCellsBlank(cropped.collapseToColumn(right - range.left), sheet)) {
      right -= 1;
    }
  }
  // trim top
  if (sides.includes('top')) {
    while (top < cropped.bottom && allCellsBlank(cropped.collapseToRow(top - range.top), sheet)) {
      top += 1;
    }
  }
  // trim bottom
  if (sides.includes('bottom')) {
    bottom = cropped.bottom;
    while (top < bottom && allCellsBlank(cropped.collapseToRow(bottom - range.top), sheet)) {
      bottom -= 1;
    }
  }
  return new Range({ left, right, top, bottom });
}

function allCellsBlank (range: Range, sheet): boolean {
  return every(getCellsInRange(range, sheet), isBlank);
}

export function* getCellsInRange (range: Range, sheet): Iterable<Cell> {
  // XXX: leverage a method that is efficient for whole row/column selections
  for (const { row, column } of range.iterCoordinates()) {
    yield sheet.getCellByCoords(row, column) || {};
  }
}

export function notBlank (cell: Cell | null): boolean {
  return !isBlank(cell);
}

export function isBlank (cell: Cell | null): boolean {
  return cell == null || cell.v == null || cell.v === '';
}

export function numberInRange (sheet: Sheet, range: Range): boolean {
  return some(getCellsInRange(range, sheet), containsNumber);
}

export function containsNumber (cell: Cell | null): cell is Cell & { v: number } {
  return cell != null && typeof cell.v === 'number' && !isDateFormat(cell.z || '');
}

export function containsDate (cell: Cell | null): cell is Cell & { v: number } {
  return cell != null && typeof cell.v === 'number' && isDateFormat(cell.z || '');
}

export function containsText (cell: Cell | null): cell is Cell & { v: string } {
  return cell != null && typeof cell.v === 'string' && cell.v.trim().length > 0;
}
