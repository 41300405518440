import { Range, type SlimRange, type SlimRangeOrCoords } from '@grid-is/apiary';
import { assert } from '@grid-is/validation';

// @ts-expect-error
import { selectionColor } from '@/WorkbookEditor/constants.module.scss';

// FIXME: use grid/utils/id?
const getId = () => (
  Array(10)
    .fill(5)
    .map(() => Math.floor(Math.random() * 36).toString(36))
    .join('')
);

export type Side = 'top' | 'left' | 'right' | 'bottom';

// XXX: this class is used to represent both selections (while navigating) and formula references (while editing a formula)
// We should consider splitting it up into separate classes depending on intended use
export class Selection {
  _ranges: Range[];
  anchor: Range;
  top: number;
  left: number;
  right: number;
  bottom: number;
  id: string;
  borderColor?: string;
  fillColor?: string;
  label?: string;
  focused?: boolean;
  locked?: boolean;
  structuredSections?: string[];

  constructor (range: Range | Selection, anchor: Range | null = null, borderColor: string | null = null, fillColor: string | null = null, id: string | null = null, label: string = '', focused: boolean = false) {
    assert(range instanceof Range || range instanceof Selection);

    this._ranges = [];
    this.top = range.top;
    this.left = range.left;
    this.right = range.right;
    this.bottom = range.bottom;
    this.structuredSections = undefined;

    /** @type string */
    this.id = id || 'sel-' + getId();
    this.borderColor = borderColor || selectionColor;
    this.fillColor = fillColor || selectionColor;
    this.label = label;
    this.focused = focused;
    this.locked = false;

    if (range instanceof Selection) {
      this.id = range.id;
      this.anchor = range.anchor;
      this.borderColor = range.borderColor;
      this.fillColor = range.fillColor;
      this.label = range.label;
      this.focused = range.focused;
      this.locked = range.locked;
      for (let rangeIndex = 0; rangeIndex < range.rangeCount; rangeIndex++) {
        this.addRange(range.getRangeAt(rangeIndex));
      }
    }
    else {
      this.anchor = new Range(anchor || range).collapse();
      if (range) {
        this.addRange(range);
      }
    }
    if (this.constructor === Selection) {
      // selections are mutable, but sealed
      Object.seal(this);
    }
  }

  get isCollapsed () {
    return this.rangeCount === 1 && this.getRangeAt(0).size === 1;
  }

  /**
   *
   *
   * @readonly
   * @memberof Selection
   * @returns {number} the number of ranges in the Selection
   */
  get rangeCount () {
    if (!Array.isArray(this._ranges)) {
      return 0;
    }
    return this._ranges.length;
  }

  get width () {
    return (this.right - this.left) + 1;
  }

  get height () {
    return (this.bottom - this.top) + 1;
  }

  get size () {
    return this.width * this.height;
  }

  get anchorOffset () {
    return {
      top: this.top - this.anchor.top,
      left: this.left - this.anchor.left,
      bottom: this.bottom - this.anchor.bottom,
      right: this.right - this.anchor.right,
    };
  }

  get isStructuredHeaders () {
    return this.structuredSections?.length === 1 && this.structuredSections[0] === 'headers';
  }

  setStructuredSections (sections: string[]) {
    this.structuredSections = sections;
  }

  /**
   * Update the position of an anchor within a selection.
   * e.g. tab & shift+tab on an active selection should update
   * the placement of the anchor within a selection
   *
   * @param {Range} range
   * @memberof Selection
   */
  setAnchor (range: Range) {
    if (!range.collapse().contains(range)) {
      // An anchor cell must only be of size 1 cell.
      throw new Error('Anchor range provided contains multiple cells');
    }
    else if (!this.containsCell(range)) {
      // the anchor cell provided must be contained in at least one range found in the selection.
      throw new Error('Anchor range provided does not exist in any ranges found in the selection');
    }
    else {
      this.anchor = range;
    }
  }

  addRange (range: Range) {
    this._ranges.push(new Range(range));
    updateSelectionCoords(this);
  }

  removeRange (range: Range) {
    if (this.rangeCount === 1) {
      throw new Error('Cannot remove range. An instance of Selection requires at least 1 range');
    }
    const idx = this._ranges.indexOf(range);
    if (idx > -1) {
      this._ranges.splice(idx, 1);
      updateSelectionCoords(this);
    }
  }

  getRangeAt (n: number): Range {
    if (n < 0 || n > this.rangeCount - 1) {
      throw new Error(n + ' is not a valid index.');
    }
    return this._ranges[n];
  }

  collapse () {
    this.collapseToStart();
  }

  collapseToStart () {
    const { top, left } = this;
    this._ranges = []; // clear ranges
    this.anchor = new Range({ top, left });
    this.addRange(this.anchor);
  }

  collapseToEnd () {
    const top = this.bottom;
    const left = this.right;
    this._ranges = []; // clear ranges
    this.anchor = new Range({ top, left });
    this.addRange(this.anchor);
  }

  containsCell (range: SlimRangeOrCoords) {
    // because the selection can be irregular we need to examine its ranges
    return this._ranges.some(d => d.contains(range));
  }

  moveBy ({ top = 0, left = 0 }) {
    const [ range ] = this._ranges;
    const sel = new Selection(
      range.moveBy(left, top),
      null,
      this.borderColor,
      this.fillColor,
      this.id,
      this.label,
      this.focused,
    );
    return sel;
  }

  extendSide (side: Side, destIndex: number) {
    const [ range ] = this._ranges;
    return new Selection(
      range.extendSide(side, destIndex),
      this.anchor,
      this.borderColor,
      this.fillColor,
      this.id,
      this.label,
      this.focused,
    );
  }

  extend ({ top, left, right = left, bottom = top }: SlimRange, fromAnchor = true): Selection {
    const [ oldRange ] = this._ranges;
    const origin = fromAnchor
      ? this.anchor
      : oldRange;

    const range = new Range({
      top: Math.min(origin.top, top),
      left: Math.min(origin.left, left),
      bottom: Math.max(origin.bottom, bottom),
      right: Math.max(origin.right, right),
      unbounded: oldRange?.unbounded,
    });
    return new Selection(
      range,
      this.anchor,
      this.borderColor,
      this.fillColor,
      this.id,
      this.label,
      this.focused,
    );
  }

  clone () {
    return new Selection(this);
  }

  toString () {
    // XXX: longterm it would be useful if the toString function would
    // store other properties like the anchor range. This would allow
    // us to restore the selection to its previous state.
    // Until then we can just stringify the first range as there is only
    // one range supported
    return String(this.getRangeAt(0));
  }
}

// ensure top/left/bottom/right props are up-to-date
function updateSelectionCoords (selection: Selection) {
  if (selection._ranges.length) {
    const xs: number[] = [];
    const ys: number[] = [];

    selection._ranges.forEach(d => {
      if (d instanceof Range) {
        xs.push(d.left, d.right);
        ys.push(d.top, d.bottom);
      }
      else {
        // throw error?
      }
    });
    selection.top = Math.min(...ys);
    selection.left = Math.min(...xs);
    selection.bottom = Math.max(...ys);
    selection.right = Math.max(...xs);
    // 1. enforce that the anchor is within the selection bounds
    // 2. reset to top, left corner if it's missing
    if (!selection.anchor || !selection.containsCell(selection.anchor)) {
      selection.anchor = selection.getRangeAt(0).collapse();
    }
  }
  else {
    selection.anchor = new Range({ left: 0, top: 0 });
    selection.top = 0;
    selection.left = 0;
    selection.bottom = 0;
    selection.right = 0;
  }
}
