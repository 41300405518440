import { isErr, toBool, isMatrix, isRef } from './utils';
import Matrix from '../Matrix';
import { isBool, isCellValue } from '../../typeguards';
import { invariant } from '../../validation';
import { unbox, type MaybeBoxed } from '../ValueBox.js';
import type FormulaError from '../FormulaError';
import type { CellValue, ArrayValue } from '../types';
import type Reference from '../Reference';

export type FilterContext = {
  ifEmpty: CellValue | Matrix,
  errorType: FormulaError,
  returnSingletonError: boolean,
  expandSingletonIncludes: boolean,
  errorsMeanFalse: boolean,
  refErrorOnNonResolvingArray: boolean,
  funcName: 'FILTER' | 'FILTER.GOOGLE',
};

/**
 * @returns Filtered array or value
 */
export function filter (
  filterContext: FilterContext,
  array: Reference | Matrix,
  ...includeArgs: (Reference | Matrix | FormulaError | undefined)[]
): CellValue | Matrix {
  try {
    if (filterContext.returnSingletonError && array.size === 1) {
      check1x1array(array);
    }
    const includeVectors = includeArgs.map(arg => {
      return !isRef(arg) && !isMatrix(arg) ? Matrix.of([ [ arg ?? null ] ]) : arg;
    });
    checkMxNarray(includeVectors, filterContext);
    const isHorizontal = determineWhetherHorizontal(array, includeVectors, filterContext);
    const includeMx = constructIncludeMatrix(array, includeVectors, isHorizontal, filterContext);
    earlyReturnIfAllTrue(array, includeMx, filterContext);
    return constructFilteredResult(array.toMatrix(), includeMx, isHorizontal, filterContext);
  }
  catch (err) {
    if (isCellValue(err) || isMatrix(err)) {
      return unbox(err);
    }
    invariant(err instanceof Error);
    throw err;
  }
}

/**
 * @param {Matrix | Reference} array
 */
function check1x1array (array: Matrix | Reference) {
  const arrayMx = array.toMatrix(false);
  if (isErr(arrayMx)) {
    throw arrayMx;
  }
  const singletonValue = arrayMx.get(0, 0);
  if (isErr(singletonValue)) {
    throw singletonValue;
  }
}

/**
 * @param {(Matrix | Reference)[]} includeVectors
 * @param {FilterContext} filterContext
 */
function checkMxNarray (includeVectors: (Matrix | Reference)[], filterContext: FilterContext) {
  const badIncludeArgIndex = includeVectors.findIndex(arg => arg.width !== 1 && arg.height !== 1);
  if (badIncludeArgIndex !== -1) {
    const badIncludeArg = includeVectors[badIncludeArgIndex];
    const index = includeVectors.length === 1 ? '' : ' ' + (badIncludeArgIndex + 1);
    const dimensions = `${badIncludeArg.height}x${badIncludeArg.width}`;
    throw filterContext.errorType.detailed(
      `${filterContext.funcName} include argument${index} is ${dimensions}, ` +
        'should be a single-row or single-column array',
    );
  }
}

/**
 * @param {Matrix | Reference} array
 * @param {(Matrix | Reference)[]} includeVectors
 * @param {FilterContext} filterContext
 * @returns {boolean}
 */
function determineWhetherHorizontal (
  array: Matrix | Reference,
  includeVectors: (Matrix | Reference)[],
  filterContext: FilterContext,
): boolean {
  const firstNonSingletonIncludeVector = includeVectors.find(v => v.width !== v.height);
  if (firstNonSingletonIncludeVector) {
    return firstNonSingletonIncludeVector.width >= firstNonSingletonIncludeVector.height;
  }
  else if (array.width === 1 || array.height === 1) {
    // only have single-valued include arguments, so go by orientation of array
    return array.width >= array.height;
  }
  else {
    // array is NxM (not a single row or column) and include arguments are
    // single-valued, so can't tell whether to filter horizontally or vertically
    // ... so return error.
    throw filterContext.errorType.detailed(`${filterContext.funcName} include argument does not match array length`);
  }
}

/**
 * @param {Matrix | Reference} array
 * @param {(Matrix | Reference)[]} includeVectors
 * @param {boolean} isHorizontal
 * @param {FilterContext} filterContext
 * @returns {Matrix}
 */
function constructIncludeMatrix (
  array: Matrix | Reference,
  includeVectors: (Matrix | Reference)[],
  isHorizontal: boolean,
  filterContext: FilterContext,
): Matrix {
  const [ firstIncludeVector, ...extraIncludeVectors ] = includeVectors;
  /** @type {[number, number]} */
  const includeDimensions: [number, number] = isHorizontal ? [ array.width, 1 ] : [ 1, array.height ];
  // Check all include vectors for dimensions incompatible with array.
  // If incompatible, expand singletons if appropriate, else return error.
  for (let i = 0; i < includeVectors.length; i++) {
    const include = includeVectors[i];
    const mismatch =
      (isHorizontal && include.width !== array.width) ||
      (!isHorizontal && include.height !== array.height) ||
      (include.size === 1 && array.size !== 1);
    if (!mismatch) {
      continue;
    }
    if (include.size !== 1 || !filterContext.expandSingletonIncludes) {
      const index = includeVectors.length === 1 ? '' : ' ' + (i + 1);
      throw filterContext.errorType.detailed(
        `${filterContext.funcName} include argument${index} does not match array length`,
      );
    }
    const includeMx = include.toMatrix(false);
    if (isErr(includeMx)) {
      includeVectors[i] = new Matrix(...includeDimensions, includeMx);
      continue;
    }
    const singletonValue = includeMx.get(0, 0);
    includeVectors[i] = new Matrix(array.width, array.height, singletonValue);
  }
  let includeMx = firstIncludeVector.toMatrix(false);
  if (isErr(includeMx)) {
    throw filterContext.errorsMeanFalse ? filterContext.ifEmpty : includeMx;
  }
  if (filterContext.errorsMeanFalse) {
    includeMx = includeMx.map((/** @returns {boolean | FormulaError} */ element): boolean | FormulaError => {
      const bool = toBool(unbox(element));
      return isErr(bool) ? false : bool;
    });
  }
  for (const extraIncludeArg of extraIncludeVectors) {
    // Must be the Google variant of FILTER, since we have extraIncludeVectors
    const extraIncludeMx = extraIncludeArg.toMatrix(false);
    if (isErr(extraIncludeMx)) {
      throw filterContext.ifEmpty;
    }
    includeMx = includeMx.map(
      /** @returns {boolean | FormulaError} */
      (element, { x, y }): boolean | FormulaError => {
        let extraIncludeCondition = toBool(extraIncludeMx.get(x, y));
        if (isErr(extraIncludeCondition)) {
          extraIncludeCondition = false;
        }
        invariant(isBool(element));
        return element && extraIncludeCondition;
      },
    );
  }
  return includeMx;
}

/**
 * @param {Matrix | Reference} array
 * @param {Matrix} includeMx
 * @param {FilterContext} filterContext
 */
function earlyReturnIfAllTrue (array: Matrix | Reference, includeMx: Matrix, filterContext: FilterContext) {
  const includeIsAllTrue = includeMx.every(element => {
    const bool = toBool(unbox(element));
    return !isErr(bool) && bool;
  });
  if (includeIsAllTrue) {
    // Quick path where no filtering is needed. This avoids copying the array.
    // We know includeLength === (isHorizontal ? array.width : array.height)),
    // else we would have returned an error above. EXCEPT: in Google variant,
    // if array is a reference that fails to resolve, we return a single #REF!
    if (filterContext.refErrorOnNonResolvingArray) {
      const arrayMx = array.toMatrix(false);
      if (isErr(arrayMx)) {
        throw arrayMx;
      }
    }
    throw array.toMatrix();
  }
}

/**
 * @param {Matrix | FormulaError} values
 * @param {Matrix} includeMx
 * @param {boolean} isHorizontal
 * @param {FilterContext} filterContext
 * @returns {CellValue | Matrix | FormulaError} Filtered array or value
 */
function constructFilteredResult (
  values: Matrix | FormulaError,
  includeMx: Matrix,
  isHorizontal: boolean,
  filterContext: FilterContext,
): CellValue | Matrix | FormulaError {
  if (isErr(values)) {
    // Happens despite toMatrix being told to expand errors --- in Google mode,
    // and also for 1x1 matrices in other modes (if there is a way to get those)
    return values;
  }

  /** @type {boolean | FormulaError} */
  let defaultInclude: boolean | FormulaError = false;
  if (includeMx.isPartiallyPopulated) {
    if (isHorizontal && includeMx._defaultColumn.length) {
      defaultInclude = toBool(unbox(includeMx._defaultColumn[0]));
    }
    else if (!isHorizontal && includeMx._defaultRow.length) {
      defaultInclude = toBool(unbox(includeMx._defaultRow[0]));
    }
    else {
      defaultInclude = toBool(unbox(includeMx._defaultValue));
    }
  }
  if (isErr(defaultInclude)) {
    return filterContext.errorsMeanFalse ? filterContext.ifEmpty : defaultInclude;
  }
  const includeIter = includeMx.iterPopulated();
  /** @returns {{doInclude: boolean | FormulaError, done: boolean}} */
  const includeNext = (): { doInclude: boolean | FormulaError, done: boolean } => {
    const next = includeIter.next();
    if (next.done) {
      return { doInclude: defaultInclude, done: true };
    }
    return { doInclude: toBool(next.value.value), done: false };
  };

  /** @type {(n: number) => MaybeBoxed<ArrayValue>[]} */
  const getColumnOrRow: (n: number) => MaybeBoxed<ArrayValue>[] = isHorizontal
    ? n => values.getColumnBoxed(n)
    : n => values.getRowBoxed(n);
  const newColumnsOrRows: MaybeBoxed<ArrayValue>[][] = [];
  const [ valuesLength, valuesPopulatedLength ] = isHorizontal
    ? [ values.width, values.populatedWidth ]
    : [ values.height, values.populatedHeight ];
  let n = 0;
  for (; n < valuesLength; n += 1) {
    const { doInclude, done: includedDone } = includeNext();
    if (isErr(doInclude)) {
      return filterContext.errorsMeanFalse ? filterContext.ifEmpty : doInclude;
    }
    if (includedDone && n >= valuesPopulatedLength) {
      break;
    }
    if (doInclude) {
      newColumnsOrRows.push(getColumnOrRow(n));
    }
  }
  if (newColumnsOrRows.length === 0) {
    return filterContext.ifEmpty;
  }
  const result = isHorizontal ? Matrix.ofTransposed(newColumnsOrRows) : Matrix.of(newColumnsOrRows);
  if (defaultInclude && n < valuesLength) {
    result._defaultValue = values._defaultValue;
    if (isHorizontal) {
      result.width = result.width + valuesLength - n;
    }
    else {
      result.height = result.height + valuesLength - n;
    }
  }
  return result;
}
