/**
 * A bitfield number describing an argument in a signature, composed of some
 * non-empty subset of the bits
 *   ERR, BLANK, MISSING, NUM, BOOL, STR, RANGE, ARR.
 */
export type SigType = number;

/**
 * A bitfield number containing flags for the given function.
 */
type FlagType = number;

/**
 * A bitfield which shows which modes this signature should apply to.
 */
export type ModeType = number;

/**
 * A bit in the `ModeType` bitfield.
 */
export type ModeBit = 2 | 4 | 8 | 16;

export type WorkbookMode = Exclude<ModeBit, 16>;

export const MODE_GRID_SHEET: WorkbookMode = 2;
export const MODE_EXCEL: WorkbookMode = 4;
export const MODE_GOOGLE: WorkbookMode = 8;
export const MODE_CANVAS: ModeBit = 16;
export const MODE_ALL: ModeType = MODE_GRID_SHEET | MODE_EXCEL | MODE_GOOGLE | MODE_CANVAS;

/**
 * A function signature. See https://github.com/GRID-is/Apiary/blob/master/doc/excel.md.
 *
 * The elements are as follows:
 *
 *   - mode: Describes where the function is available (Excel workbooks, Scratchpad, etc)
 *   - minArgs: Minimum number of arguments in Excel
 *   - maxArgs: Maximum number of arguments in Excel
 *   - googleMinArgs: Minimum number of arguments in Google Sheets
 *   - googleMaxArgs: Maximum number of arguments in Google Sheets
 *   - headTypes: The arguments that come before the variadic arguments
 *   - varTypes: Variadic arguments which come between the head and tail.
 *   - tailTypes: The arguments that come after the variadic arguments, if there are any.
 *   - returnType: The type of values this function can return.
 *   - flags: A bitfield containing flags for the function. Currently only one flag is defined,
 *     `FLAG_VALUEBOX`, which is true for functions which support boxed arguments (if false,
 *     arguments are unboxed before a call) not to unbox the function.
 *
 * The `-1` value for a minimum/maximum number of arguments indicates that the
 * value has not been specified. This could be because the function is e.g. only
 * available in Excel and not Google Sheets, or simply because the value hasn't
 * inserted into the signature. The "Special precedents" and "function flags"
 * observed in the Excel implementation are not accounted for here.
 */
export type FunctionSignature = [
  mode: ModeType,
  minArgs: number,
  maxArgs: number,
  googleMinArgs: number,
  googleMaxArgs: number,
  headTypes: SigType[],
  varTypes: SigType[],
  tailTypes: SigType[],
  returnType: SigType,
  flags: FlagType,
];

/**
 * A public function signature. A less terse form of FunctionSignature, suitable for external use.
 * Currently only a subset of the information in FunctionSignature is output because we don't yet
 * have a need for the full details.
 */
export type PublicFunctionSignature = {
  mode: ModeType,
};

/**
 * A collection of PublicFunctionSignature, stored as an object. The object's keys are function
 * names, the values are the array of signatures available for that function.
 */
export type PublicFunctionSignatures = Record<string, PublicFunctionSignature[]>;
