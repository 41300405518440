/* eslint-disable no-undef */

import { DefaultMap } from '@grid-is/collections';
import RTree from './algorithm/rtree';
import Reference from './excel/Reference';
import { a1ToRowColumn } from './excel/referenceParser/a1';
import Range from './excel/referenceParser/Range';
import { constructCellID } from './utils';
import { invariant } from './validation';
import { chain, range } from '@grid-is/iterators';
import { isNonLiteralNode } from './excel/ast';
import { type ASTRootNode } from './excel/ast-types';
import type { CellCSF } from './csf';
import type { CellValue } from './excel/types';

const coordsKey = (row: number, column: number) => `${row};${column}`;

export function initGsdv (cells: Record<string, CellCSF>, cellsWithGsdv: string[]) {
  const gsdvSet = new Set(cellsWithGsdv);
  const claimantValues = new Map<string, CellValue>();

  const isGsdvSpillCandidate = (row: number, col: number) => {
    const cellId = constructCellID(row, col);
    const cellCSF = cells[cellId];
    if (!cellCSF) {
      return false;
    }
    const { f, F, ft } = cellCSF;
    return f != null && F == null && (ft === 'a' || formulaMayContainGsdvFunction(f));
  };

  const isBlankOrMissing = (row: number, column: number) => {
    const cellId = constructCellID(row, column);
    const cellCSF = cells[cellId];
    return !cellCSF || (cellCSF.f == null && cellCSF.v == null);
  };

  const { claimants, successfulClaims, conflictingClaims } = findAndCategorizeGsdvClaims(
    cellsWithGsdv,
    isGsdvSpillCandidate,
    isBlankOrMissing,
  );
  for (const claimant of claimants) {
    claimantValues.set(claimant, cells[claimant].v ?? null);
  }
  const refsNeedingRecalc: Reference[] = [];
  for (const { claimant } of conflictingClaims) {
    refsNeedingRecalc.push(new Reference(claimant));
  }

  if (successfulClaims.length) {
    // Do not mutate original object
    cells = { ...cells };
  }
  for (const { range } of successfulClaims) {
    const F = String(range);
    for (const { row, column } of range.iterCoordinates()) {
      const cellId = constructCellID(row, column);
      // Do not mutate original object
      const newCellCSF: CellCSF = { ...cells[cellId], F, ft: 'a' };
      if (!('v' in newCellCSF)) {
        newCellCSF.v = null;
      }
      cells[cellId] = newCellCSF;
      gsdvSet.delete(cellId);
    }
  }

  return { cells, refsNeedingRecalc, gsdvSet, claimantValues };
}

export function findSuccessfulClaimsBeforeGsdvClear (
  gsdvCells: string[],
  getCellByCoords: (row: number, column: number) => Cell | null,
) {
  const unsupportedFormula = (ast: ASTRootNode) => {
    return ast == null || (isNonLiteralNode(ast) && ast.callsUnsupportedFunction);
  };

  const isGsdvSpillCandidate = (row: number, col: number) => {
    const cell = getCellByCoords(row, col);
    if (!cell) {
      return false;
    }
    const { _ast, f, F } = cell;

    /**
     * Filter out any claimants whose formula we cannot evaluate.
     *
     * This is done to avoid single-cell formula cells making claims
     * that they should not make.
     *
     * Given the formula cells A and B where:
     *
     *    A's formula is `=IMPORTRANGE(...)`,
     *    B's formula is `=SUM(...)`,
     *
     * and the following cell layout where □ represents a GSDV cell:
     *
     *      A □ □
     *    B □ □ □
     *      □ □ □
     *
     * Neither A or B was able to claim any GSDV cells because their claim
     * ranges overlap:
     *
     *    A's claim:             B's claim:
     *                A ■ ■                  A □ □
     *              B ■ ■ ■                B ■ ■ ■
     *                ■ ■ ■                  □ □ □
     *
     * We typically resolve this by recalculating potential GSDV spill anchors,
     * but in this case:
     *
     *    - A cannot be evaluated because its formula failed to parse, or parsed
     *      but calls an unsupported function.
     *
     *    - B was successfully calculated, but it claimed no GSDV cells that
     *      overlapped with A's claim.
     *
     * We can resolve this by finding all claimants that can't be evaluated and
     * checking again for overlapping claims. Since B is no longer a claimant,
     * A's claim no longer has overlapping claims and it successfully claims the
     * GSDV cells, despite not being able to be evaluated.
     *
     * See GSDV docs {@link Docs.GSDV}
     */
    return !!(f != null && F == null && unsupportedFormula(_ast) && formulaMayContainGsdvFunction(f));
  };

  const isBlankOrMissing = (row: number, column: number) => {
    const cell = getCellByCoords(row, column);
    return !cell || (cell.f == null && cell.v == null);
  };

  const { successfulClaims } = findAndCategorizeGsdvClaims(gsdvCells, isGsdvSpillCandidate, isBlankOrMissing);
  return successfulClaims;
}

/**
 * Spreadsheet functions that we have seem emit GSDV cells (i.e. omit
 * the `F` attribute).
 *
 * This list is likely incomplete, we will add to it as we encounter
 * more of these functions.
 */
const knownGsdvFunctions = [ 'FILTER', 'UNIQUE', 'QUERY', 'IMPORTRANGE', 'GOOGLEFINANCE', 'IMPORTDATA', 'ARRAYFORMULA' ];

/**
 * Some spreadsheet functions are known not to emit `F` attributes for their
 * spilled ranges. This function returns true if one of those functions
 * seems to be used.
 *
 * See GSDV docs {@link Docs.GSDV}
 */
function formulaMayContainGsdvFunction (formula: string) {
  formula = formula.toUpperCase();
  return knownGsdvFunctions.some(n => formula.includes(n));
}

/**
 * Discovers GSDV claimants, resolves their claimed ranges and categorizes
 * those claims as successful (no overlaps) or unsuccessful (has overlaps).
 *
 * See GSDV docs {@link Docs.GSDV}
 */
function findAndCategorizeGsdvClaims (
  gsdv: string[],
  isGsdvSpillCandidate: (row: number, col: number) => boolean,
  isBlankCoords: (row: number, column: number) => boolean,
) {
  const coords = gsdv.map(r => a1ToRowColumn(r));
  const keySet = new Set<string>();
  const gsdvMaxRowByCol = new DefaultMap<number, number>(() => -1);
  const gsdvMaxColByRow = new DefaultMap<number, number>(() => -1);
  for (const [ row, column ] of coords) {
    keySet.add(coordsKey(row, column));
    if (gsdvMaxRowByCol.get(column) < row) {
      gsdvMaxRowByCol.set(column, row);
    }
    if (gsdvMaxColByRow.get(row) < column) {
      gsdvMaxColByRow.set(row, column);
    }
  }
  // Change to record the max coordinate seen at _or beyond_ a given row/column
  makeCumulative(gsdvMaxRowByCol);
  makeCumulative(gsdvMaxColByRow);

  const isGsdvCoords = (row: number, column: number) => {
    return keySet.has(coordsKey(row, column));
  };

  const claimants = findNeighborsAboveAndLeftOf(coords, isGsdvSpillCandidate);
  const claims = [ ...claimants ].map(claimant => ({
    claimant,
    range: findGsdvClaim(claimant, isGsdvCoords, isBlankCoords, gsdvMaxRowByCol, gsdvMaxColByRow),
  }));

  const claimRTree = new RTree();
  claimRTree.load(claims.map(({ range }) => range.toBoundingBox()));

  const conflictingClaims: typeof claims = [];
  const successfulClaims: typeof claims = [];

  for (const claim of claims) {
    const { range } = claim;
    const hasConflictingClaims =
      claimRTree.search(range.toBoundingBox()).filter(node => range.left !== node.minX || range.top !== node.minY)
        .length > 0;
    (hasConflictingClaims ? conflictingClaims : successfulClaims).push(claim);
  }

  return { claimants, successfulClaims, conflictingClaims };
}

function makeCumulative (maxThingByThing: Map<number, number>) {
  const entriesByDecreasingKey = [ ...maxThingByThing.entries() ].sort((a, b) => b[0] - a[0]);
  let cumulativeMax = -1;
  for (const [ column, maxRow ] of entriesByDecreasingKey) {
    if (cumulativeMax > maxRow) {
      maxThingByThing.set(column, cumulativeMax);
    }
    else {
      cumulativeMax = maxRow;
    }
  }
}

/**
 * Find the "claim" range anchored at `claimant` where every coordinate in the
 * claim range is contained within `gsdv`, except the coordinate of the claimant
 * itself.
 *
 * See GSDV docs {@link Docs.GSDV}
 */
function findGsdvClaim (
  claimant: string,
  isGsdv: (row: number, column: number) => boolean,
  isBlank: (row: number, column: number) => boolean,
  gsdvMaxRowByCol: Map<number, number>,
  gsdvMaxColByRow: Map<number, number>,
): Range {
  const [ claimantY, claimantX ] = a1ToRowColumn(claimant);

  // Given this example where C represents the claimant and □ represents GSDV cells:
  //
  //    C □ □ □
  //    □ □ □
  //    □ □
  //    □
  //
  // There are four possible claims:
  //
  //    C □ □ □    C ■ □ □    C ■ ■ □    C ■ ■ ■
  //    ■ □ □      ■ ■ □      ■ ■ ■      □ □ □
  //    ■ □        ■ ■        □ □        □ □
  //    ■          □          □          □
  //
  // We could try to figure out which claim is "optimal" by looking for other
  // claimants and figuring out which claimants cancel out others, but that would
  // be very computationally expensive.
  //
  // Instead, traverse down and then right. This heuristic assumes that vertical
  // spills are more common than horizontal ones.
  //
  const maxYoffset = gsdvMaxRowByCol.get(claimantX)! - claimantY;
  const { firstColumnMaxYWithBlanks, firstColumnMaxY } = scanFirstColumn(
    maxYoffset,
    claimantY,
    claimantX,
    isGsdv,
    isBlank,
  );

  const maxXoffset = chain(range(firstColumnMaxYWithBlanks + 1))
    .map(i => gsdvMaxColByRow.get(claimantY + i)! - claimantX)
    .max()!;
  const { xOffset, yOffset } = scanRemainingColumns(
    maxXoffset,
    claimantY,
    claimantX,
    firstColumnMaxYWithBlanks,
    firstColumnMaxY,
    isGsdv,
    isBlank,
  );

  invariant(xOffset || yOffset, 'potential spill anchor must claim at least one cell');
  return new Range({
    left: claimantX,
    right: claimantX + xOffset,
    top: claimantY,
    bottom: claimantY + yOffset,
  });
}

function scanFirstColumn (
  maxYoffset: number,
  claimantY: number,
  claimantX: number,
  isGsdv: (row: number, column: number) => boolean,
  isBlank: (row: number, column: number) => boolean,
): { firstColumnMaxYWithBlanks: number, firstColumnMaxY: number } {
  let firstColumnMaxY = 0;
  let firstColumnMaxYWithBlanks = 0;
  while (firstColumnMaxYWithBlanks < maxYoffset) {
    const y = claimantY + firstColumnMaxYWithBlanks + 1;
    if (isGsdv(y, claimantX)) {
      firstColumnMaxYWithBlanks++;
      firstColumnMaxY = firstColumnMaxYWithBlanks;
    }
    else if (isBlank(y, claimantX)) {
      firstColumnMaxYWithBlanks++;
    }
    else {
      break;
    }
  }
  return { firstColumnMaxYWithBlanks, firstColumnMaxY };
}

function scanRemainingColumns (
  maxXoffset: number,
  claimantY: number,
  claimantX: number,
  maxGsdvOrBlankYinFirstColumn: number,
  maxGsdvYinFirstColumn: number,
  isGsdv: (row: number, column: number) => boolean,
  isBlank: (row: number, column: number) => boolean,
): { xOffset: number, yOffset: number } {
  let xOffset = 0;
  let yOffset = maxGsdvYinFirstColumn;
  let maxGsdvOrBlankX = 0;
  while (maxGsdvOrBlankX < maxXoffset) {
    const x = claimantX + maxGsdvOrBlankX + 1;
    let foundGsdv = false;
    for (let i = 0; i <= maxGsdvOrBlankYinFirstColumn; i++) {
      const y = claimantY + i;
      if (isGsdv(y, x)) {
        foundGsdv = true;
        if (y > yOffset && y <= maxGsdvOrBlankYinFirstColumn) {
          yOffset = y;
        }
      }
      else if (!isBlank(y, x)) {
        return { xOffset, yOffset };
      }
    }
    maxGsdvOrBlankX++;
    if (foundGsdv) {
      xOffset = maxGsdvOrBlankX;
    }
  }
  return { xOffset, yOffset };
}

/**
 * Given a list of root cell coordinate-pairs `[y,x]` (zero-based), return any
 * cell addresses which:
 *  - Were not contained in `coords`
 *  - Lie to the top or left of a cell address in `coords`
 * @param coords cell addresses in A1 format without prefix
 * @param include determine whether to include a given cell in the output
 * @returns cell addresses of neighbours in A1 format without prefix
 */
function findNeighborsAboveAndLeftOf (
  coords: [y: number, x: number][],
  include: (row: number, col: number) => boolean,
): Set<string> {
  const keySet = new Set(coords.map(([ row, column ]) => coordsKey(row, column)));
  const topLeftNeighbours = new Set<string>();
  for (const [ row, col ] of coords) {
    if (row > 0 && !keySet.has(coordsKey(row - 1, col))) {
      if (include(row - 1, col)) {
        const cellId = constructCellID(row - 1, col);
        topLeftNeighbours.add(cellId);
      }
    }
    if (col > 0 && !keySet.has(coordsKey(row, col - 1))) {
      if (include(row, col - 1)) {
        const cellId = constructCellID(row, col - 1);
        topLeftNeighbours.add(cellId);
      }
    }
  }
  return topLeftNeighbours;
}
