import Reference, { isRef } from '../Reference';
import { isMatrix } from '../Matrix';
import { isBool, isErr, isNum, isStr } from '../../typeguards';
import { ERROR_NUM, ERROR_VALUE, MODE_GOOGLE } from '../constants';
import FormulaError from '../FormulaError';
import { MaybeBoxed, isBoxed } from '../ValueBox.js';
import { FormulaArgument, FormulaValue } from '../types.js';
import { ModeBit } from './sigtypes.js';

const MAX_VALUE = Number.MAX_VALUE;
const MIN_NORMAL = 2 ** -1022;
const EPSILON = Number.EPSILON;

const buffer = new ArrayBuffer(8);
const view = new DataView(buffer);

/**
 * Read the unsigned exponent of a double precision IEEE754 number, shifted by
 * four bits to the left. So the result is in the form:
 *        0 | e e e e e e e e e e e |  0 0 0 0
 * derived from the first 16 bits of the big endian IEEE754 binary representation:
 *     Sign |       Exponent        |  Fraction bits
 *        s | e e e e e e e e e e e |  f f f f [...]
 * by applying AND with this bitmask:
 *   0b   0 | 1 1 1 1 1 1 1 1 1 1 1 |  0 0 0 0
 * = 0x     |     7       f       f |        0
 * and leaving this result bit-shifted for the sake of speed.
 */
function getShiftedBiasedExponent (num: number): number {
  view.setFloat64(0, num);
  // 0x7ff0 = 0b0111111111110000
  return view.getUint16(0) & 0x7ff0;
}

/**
 * Round a number to 15 significant digits
 */
export function round15 (num: number): number {
  if (num === Infinity || num === -Infinity) {
    return num;
  }
  // calling with no args => num = undefined => will emit 0
  if (!isFinite(num) && num != null) {
    return NaN;
  }
  if (num) {
    const d = Math.ceil(Math.log10(num < 0 ? -num : num));
    const magnitude = 10 ** (15 - Math.floor(d));
    if (!Number.isFinite(magnitude)) {
      // As `num` approaches 0, the value of `magnitude` increases. JavaScript
      // is able to represent 1e+308, but not 1e+309 or above. It represents
      // 1e+309 and above using Infinity, which causes `/ magnitude` to resolve
      // to NaN.
      //
      // `num` needs to be extremely close to zero (e.g. 1e-294) for this to
      // happen, so return 0.
      return 0;
    }
    const shifted = Math.round(num * magnitude);
    return shifted / magnitude;
  }
  return 0;
}

/**
 * Round a number to 15 significant digits. Handles ValueBox numbers without
 * unboxing them. Otherwise same as `round15`.
 */
export function round15Boxed (num: MaybeBoxed<number>): MaybeBoxed<number> {
  if (isBoxed(num)) {
    return num.map(round15);
  }
  return round15(num);
}

export function nearlyEqual (a: number | boolean, b: number | boolean): boolean {
  if (typeof a === 'boolean' || typeof b === 'boolean') {
    return a === b;
  }
  // shortcut on equal
  if (a === b) {
    return true;
  }
  const diff = Math.abs(a - b);
  // denormal/subnormal numbers
  if (a === 0 || b === 0 || diff < MIN_NORMAL) {
    return diff < EPSILON * MIN_NORMAL;
  }
  // normal numbers
  return diff / Math.min(Math.abs(a) + Math.abs(b), MAX_VALUE) < EPSILON;
}

/**
 * Decide heuristically whether the result `aPlusB` of adding or subtracting
 * some number `b` to/from the number `a`, should be replaced with zero. The
 * heuristic is whether `aPlusB` is smaller (closer to zero) than `a` by 50 or
 * more binary orders of magnitude (which is just over 15 decimal orders of
 * magnitude). This heuristic matches that of Excel and can be computed by a
 * simple binary operation on the floating-point internals of `aPlusB` and `a`
 * and an integer subtraction.
 */
export function shouldZeroCancel (aPlusB: number, a: number): boolean {
  // `0x320` is `(50 << 4)`
  return getShiftedBiasedExponent(a) - getShiftedBiasedExponent(aPlusB) >= 0x320;
}

/**
 * Return `aPlusB` unless it is extremely close to 0 in comparison with `a`,
 * per the `shouldZeroCancel` heuristic, in which case return 0.
 *
 * See https://docs.microsoft.com/en-us/office/troubleshoot/excel/floating-point-arithmetic-inaccurate-result
 *   > Should an addition or subtraction operation result in a value at or very
 *   > close to zero, Excel 97 and later will compensate for any error introduced
 *   > as a result of converting an operand to and from binary.
 */
export function zeroCancel (aPlusB: number, a: number): number {
  if (shouldZeroCancel(aPlusB, a)) {
    return 0;
  }
  else {
    return aPlusB;
  }
}

/**
 * Valid things:
 * [+-]?\d+(\.\d+)?
 * [+-]\dE[+-]?\d
 * These (from what I can test) happen to be the same things as pass through
 * regular multiplication operation.
 */
export function toNum (value: FormulaArgument, err = ERROR_VALUE): number | FormulaError {
  if (isRef(value) || isMatrix(value)) {
    value = value.resolveSingle();
  }
  if (isErr(value)) {
    return value;
  }
  if (value == null) {
    return 0;
  }
  if (isBool(value)) {
    return +value;
  }
  if (isStr(value)) {
    const num = value ? +value : ERROR_VALUE;
    if (num instanceof FormulaError) {
      return num;
    }
    return isFinite(num) ? num : parseDateTime(value);
  }
  if (isNum(value) && isFinite(value)) {
    return value;
  }
  return err;
}

export function add (a: FormulaArgument, b: FormulaArgument): number | FormulaError {
  a = toNum(a);
  b = toNum(b);
  if (a instanceof FormulaError) {
    return a;
  }
  if (b instanceof FormulaError) {
    return b;
  }
  return a + b;
}

export function serialTime (
  hour: FormulaValue,
  minute: FormulaValue,
  second: FormulaValue,
  ms: FormulaValue = 0,
): number | FormulaError {
  hour = toNum(hour);
  minute = toNum(minute);
  second = toNum(second);
  ms = toNum(ms);
  if (hour instanceof FormulaError) {
    return hour;
  }
  if (minute instanceof FormulaError) {
    return minute;
  }
  if (second instanceof FormulaError) {
    return second;
  }
  if (ms instanceof FormulaError) {
    return ms;
  }
  const ts = hour * 60 * 60 + minute * 60 + second + ms / 1000;
  return ts < 0 ? ERROR_NUM : ts / (60 * 60 * 24);
}

export function serialDate (
  year: FormulaValue,
  month: FormulaValue,
  day: FormulaValue,
  mode?: ModeBit,
): number | FormulaError {
  let y = toNum(year, ERROR_NUM);
  let m = toNum(month, ERROR_NUM);
  let d = toNum(day, ERROR_NUM);
  if (y instanceof Error) {
    return y;
  }
  if (m instanceof Error) {
    return m;
  }
  if (d instanceof Error) {
    return d;
  }
  y = Math.floor(y);
  m = Math.floor(m);
  d = Math.floor(d);

  // Excel seems to short this because [0, 0, 1000] does not work
  if ((y <= 0 || y === 1900) && m < 1) {
    return ERROR_NUM;
  }

  // If year is between 0 (zero) and 1899 (inclusive), Excel (not Google) adds that value to 1900 to calculate the year.
  if (y >= 0 && y <= 1899 && mode !== MODE_GOOGLE) {
    y += 1900;
  }

  // Must know the final year and month in order to perform the next step
  if (m > 12) {
    y += Math.floor(m / 12);
    m %= 12;
  }
  else if (m < 1) {
    y -= Math.ceil((12 + m) / 12);
    m = ((11 + m) % 12) + 1;
  }

  let epoch = -Infinity;
  if (y <= 1900 && m <= 2 && mode !== MODE_GOOGLE) {
    // This amazing quirk dates back to (at least) Lotus 123
    // See: https://www.joelonsoftware.com/2006/06/16/my-first-billg-review/
    // This emulates how Excel treats this, which is not the most predictable behavior.
    //  =DATE(1901, -11, 366) => 366 (1900-12-31)
    //  =DATE(1900, 12, -335) => 0 (1900-12-31)
    epoch = 25568;
  }
  else if (y < 10000) {
    epoch = 25569;
  }
  const r = Date.UTC(y, m - 1, d) / 864e5 + epoch;
  return r >= 0 && r <= 2958465 ? r : ERROR_NUM;
}

const months: Partial<Record<string, number>> = {
  jan: 1,
  janu: 1,
  janua: 1,
  januar: 1,
  january: 1,
  feb: 2,
  febr: 2,
  febru: 2,
  februa: 2,
  februar: 2,
  february: 2,
  mar: 3,
  marc: 3,
  march: 3,
  apr: 4,
  apri: 4,
  april: 4,
  may: 5,
  jun: 6,
  june: 6,
  jul: 7,
  july: 7,
  aug: 8,
  augu: 8,
  augus: 8,
  august: 8,
  sep: 9,
  sept: 9,
  septe: 9,
  septem: 9,
  septemb: 9,
  septembe: 9,
  september: 9,
  oct: 10,
  octo: 10,
  octob: 10,
  octobe: 10,
  october: 10,
  nov: 11,
  nove: 11,
  novem: 11,
  novemb: 11,
  novembe: 11,
  november: 11,
  dec: 12,
  dece: 12,
  decem: 12,
  decemb: 12,
  decembe: 12,
  december: 12,
};

export function isLeapYear (y: number) {
  return y === 1900 || (y % 4 === 0 && y % 100 !== 0) || y % 400 === 0;
}

export function validDate (y: number, m: number | string, d: number) {
  if (!isFinite(y) || !y) {
    return ERROR_VALUE;
  }
  if (!isFinite(d) || !d) {
    return ERROR_VALUE;
  }
  if (typeof m === 'string') {
    m = m in months ? months[m]! : parseInt(m, 10);
  }
  if (!isFinite(m) || !m) {
    return ERROR_VALUE;
  }
  if (y < 30) {
    // 0-29 => 20??
    y += 2000;
  }
  else if (y < 100) {
    // 30-99 => 19??
    y += 1900;
  }
  else if (y < 1900 || !isFinite(y)) {
    return ERROR_VALUE;
  }
  if (m > 12) {
    return ERROR_VALUE;
  }
  let maxDays = 31;
  if (m === 2) {
    maxDays = isLeapYear(y) ? 29 : 28;
  }
  else if (m === 4 || m === 6 || m === 9 || m === 11) {
    maxDays = 30;
  }
  if (d > maxDays) {
    return ERROR_VALUE;
  }

  return serialDate(y, m, d);
}

/**
 * Parse a single value (string or reference to a string) into a number representing an Excel datetime.
 * @param arg a single value to convert
 * @param preferDayFirst resolve ambiguous-order dates with the day-first order (month, day, year).
 *   Example: interpret 4/3/21 as March 4th. Default is false, i.e. month-first, i.e. April 3rd in this example.
 * @param lenientDayMonthOrder return date rather than error when the value is valid as month-first and not
 *   as day-first, but preferMonthFirst is false --- or vice versa. Default is false (i.e. be strict, return error
 *   even if value can be parsed in the non-preferred day/month order style).
 */
export function parseDateTime (
  arg: string | FormulaError | Reference,
  preferDayFirst?: boolean,
  lenientDayMonthOrder?: boolean,
): number | FormulaError {
  if (arg instanceof FormulaError) {
    return arg;
  }
  let value = isRef(arg) ? arg.resolveSingle() : arg;
  if (isStr(value)) {
    value = value.trim().toLowerCase();
    let m;
    // 2018-06-22
    if ((m = /^(\d{4})[-/](\d\d?)[-/](\d\d?)(\s[\d:.apm ]+)?$/.exec(value))) {
      return add(validDate(+m[1], m[2], +m[3]), parseTime(m[4]));
    }
    // 22[- /]JUNE, 22[- /]JUN
    if ((m = /^(\d+)[-/ ]?([a-z]{3,9})(\s(?=.*[apm:])[\d:.apm ]+)?$/.exec(value))) {
      return add(validDate(new Date().getUTCFullYear(), m[2], +m[1]), parseTime(m[3]));
    }
    if ((m = /^(\d+)[-/ ]?([a-z]{3,9})[-/ ]?(\d\d)(\s(?=.*[apm:])[\d:.apm ]+)?$/.exec(value))) {
      return add(validDate(+m[3], m[2], +m[1]), parseTime(m[4]));
    }
    // JUNE[- /]22, JUN[- /]22
    if ((m = /^([a-z]{3,9})[-/ ]?(\d+)(\s(?=.*[apm:])[\d:.apm ]+)?$/.exec(value))) {
      return add(validDate(new Date().getUTCFullYear(), m[1], +m[2]), parseTime(m[3]));
    }
    // 6[-/]2022 (Excel accepts this for any year from 1900 to 9999)
    if ((m = /^([1-9]|1[0-2])[-/]((?:19|[2-9]\d)\d\d)(\s(?=.*[apm:])[\d:.apm ]+)?$/.exec(value))) {
      return add(validDate(+m[2], m[1], 1), parseTime(m[3]));
    }
    // 6[-/]22
    if ((m = /^(\d+)[-/](\d+)(\s(?=.*[apm:])[\d:.apm ]+)?$/.exec(value))) {
      return add(validDate(new Date().getUTCFullYear(), m[1], +m[2]), parseTime(m[3]));
    }
    // if !preferDayFirst || lenientDayMonthOrder:
    // 1/2/3  [2 jan 2003]
    // June 22 June 2018
    // Jun 22 Jun 2018
    // June-22-2018
    // Jun-22-2018
    // Jun/22/2018
    // 06/22/2018
    // 06/22/18
    //
    // OR, if preferDayFirst || lenientDayMonthOrder:
    // 1/2/3  [1 feb 2003]
    // 22 June 2018
    // 22 Jun 2018
    // 22-June-2018
    // 22-Jun-2018
    // 22/Jun/2018
    // 22/06/2018
    // 22/06/18
    if ((m = /^(\d+|[a-z]+)[- /](\d+|[a-z]+)[- /](\d+)(\s[\d:.apm ]+)?$/.exec(value))) {
      if (isFinite(+m[1]) && +m[1] > 12 && isFinite(+m[2]) && +m[2] <= 12) {
        // only valid as DMY
        return !preferDayFirst && !lenientDayMonthOrder
          ? ERROR_VALUE
          : add(validDate(+m[3], m[2], +m[1]), parseTime(m[4]));
      }
      else if (isFinite(+m[2]) && +m[2] > 12 && isFinite(+m[1]) && +m[1] <= 12) {
        // only valid as MDY
        return preferDayFirst && !lenientDayMonthOrder
          ? ERROR_VALUE
          : add(validDate(+m[3], m[1], +m[2]), parseTime(m[4]));
      }
      // valid as either DMY or MDY (or valid as neither!), so interpret in the preferred way
      if (preferDayFirst) {
        return add(validDate(+m[3], m[2], +m[1]), parseTime(m[4]));
      }
      else {
        return add(validDate(+m[3], m[1], +m[2]), parseTime(m[4]));
      }
    }
    if (/^[\d:.apm ]+$/i.test(value)) {
      return parseTime(value);
    }
  }
  return ERROR_VALUE;
}

/**
 * Convert a serial-number date to year, month and day.
 * See https://www.codeproject.com/Articles/2750/Excel-Serial-Date-to-Day-Month-Year-and-Vice-Versa
 */
export function toYMD (ord: number, replicateExcelBugs = true): [year: number, month: number, day: number] {
  const intOrd = Math.floor(ord);
  if (replicateExcelBugs) {
    if (intOrd === 0) {
      return [ 1900, 1, 0 ];
    }
    if (intOrd === 60) {
      return [ 1900, 2, 29 ];
    }
    if (intOrd < 60) {
      return [ 1900, intOrd < 32 ? 1 : 2, ((intOrd - 1) % 31) + 1 ];
    }
  }
  else {
    if (intOrd === 0 || intOrd === 1) {
      return [ 1899, 12, 30 + intOrd ];
    }
    if (intOrd === 60) {
      return [ 1900, 2, 28 ];
    }
    if (intOrd < 60) {
      return [ 1900, intOrd < 33 ? 1 : 2, ((intOrd - 2) % 31) + 1 ];
    }
  }
  let l = intOrd + 68569 + 2415019;
  const n = Math.floor((4 * l) / 146097);
  l = l - Math.floor((146097 * n + 3) / 4);
  const i = Math.floor((4000 * (l + 1)) / 1461001);
  l = l - Math.floor((1461 * i) / 4) + 31;
  const j = Math.floor((80 * l) / 2447);
  const nDay = l - Math.floor((2447 * j) / 80);
  l = Math.floor(j / 11);
  const nMonth = j + 2 - 12 * l;
  const nYear = 100 * (n - 49) + i + l;
  return [ nYear, nMonth, nDay ];
}

export function parseTime (timestr: string): number | FormulaError {
  if (!timestr) {
    return 0;
  }
  if (isStr(timestr)) {
    const bits = [ '', '', '', '', '' ];
    let section = 0;
    let lastChar = '';
    let colons = 0;
    let lastWasDigit = false;
    let frac_section = -1;
    let PM: number | undefined;
    let haveLargeNumber = false;
    const tokens = timestr.toLowerCase().split(/(\d+|[:.]| +|[pa]m?|.)/i);
    for (let i = 0; i < tokens.length; i++) {
      const char = tokens[i].charAt(0);
      const isDigit = char >= '0' && char <= '9';
      if (!char || char === ' ') {
        // ignore spaces and empty strings
        if (char) {
          lastChar = char;
        }
        continue;
      }
      else if (isDigit) {
        if (lastWasDigit || colons > 2) {
          return ERROR_VALUE;
        }
        bits[section] = tokens[i];
        const len = bits[section].length;
        if ((section < 3 && len > 4) || (len > 2 && haveLargeNumber)) {
          return ERROR_VALUE;
        }
        // only one of the first three section may be over 2 digits long
        if (len > 2) {
          haveLargeNumber = true;
        }
      }
      else if (char === ':') {
        // must follow digit
        if (!lastWasDigit) {
          return ERROR_VALUE;
        }
        // move to next section
        if (section < 4) {
          // 5th item is "overflow"
          section++;
        }
        colons++;
      }
      else if (char === '.') {
        // must follow digit
        // must have seen a colon already
        // must be the only occurrance
        // allowed as both minute-fractions and second fractions :-(
        if (!lastWasDigit || !colons || section > 2) {
          return ERROR_VALUE;
        }
        frac_section = section;
        section = 3;
      }
      else if (char === 'a' || char === 'p') {
        // must follow [ digit, space ], and be the only occurance
        if (!lastWasDigit || lastChar !== ' ' || PM != null) {
          return ERROR_VALUE;
        }
        PM = char === 'p' ? 12 : 0;
      }
      else {
        return ERROR_VALUE;
      }
      lastWasDigit = isDigit;
      lastChar = char;
    }
    // must have either a colon or AM/PM
    if (!colons && PM == null) {
      return ERROR_VALUE;
    }
    // adapt to quirky behaviour in "1:1.1:1"
    if (frac_section === 1) {
      if (bits[4]) {
        bits[2] = bits[3];
      }
      else {
        bits[2] = bits[1];
        bits[1] = bits[0];
        bits[0] = '';
      }
    }
    // adjust hours for PM
    let h = +(bits[0] || 0);
    if (PM != null) {
      if (h > 12) {
        return ERROR_VALUE;
      }
      if (h < 12) {
        h += PM;
      }
    }
    const _ms = 1000 * +(bits[3] ? '0.' + bits[3].slice(0, 3) : 0);
    return serialTime(h, bits[1] || 0, bits[2] || 0, _ms);
  }
  return ERROR_VALUE;
}

/**
 * Convert a JavaScript `Date` instance to an Excel serial number. Permit null, returning null.
 */
export function dateToSerial (d: Date | null): number | null {
  if (d == null) {
    return null;
  }
  return d.valueOf() / 864e5 + 25569;
}
