import { MODE_GRID_SHEET, MODE_EXCEL, MODE_GOOGLE, MODE_CANVAS, MODE_ALL } from './excel/constants';
import type { ModeBit, ModeType } from './excel/functions/sigtypes';
import { individualBits, oxfordCommaJoin } from './utils';
import { invariant } from './validation';

export const ALL_MODES = [ MODE_EXCEL, MODE_GOOGLE, MODE_GRID_SHEET, MODE_CANVAS ] as const;
export const ALL_WORKBOOK_MODES = [ MODE_EXCEL, MODE_GOOGLE, MODE_GRID_SHEET ] as const;

/**
 * @param {ModeBit} mode
 */
function modeBitToString (mode: ModeBit) {
  switch (mode) {
    case MODE_GRID_SHEET:
      return 'Grid Sheets';
    case MODE_EXCEL:
      return 'Excel';
    case MODE_GOOGLE:
      return 'Google Sheets';
    case MODE_CANVAS:
      return 'Grid Canvas';
    default:
      invariant(false, 'unknown mode bit');
  }
}

/**
 * @param {ModeType} modeBitField
 * @returns {ModeBit[]}
 */
function individualModeBits (modeBitField: ModeType): ModeBit[] {
  // @ts-expect-error We know the resulting bits are in `ModeBit`
  return individualBits(modeBitField, MODE_ALL);
}

/**
 * Describes mode bit field in plain english. For example:
 *   (MODE_GRID_SHEET | MODE_EXCEL) -> "Grid Sheets and Excel"
 *   (MODE_GRID_SHEET | MODE_EXCEL | MODE_GOOGLE) -> "Grid Sheets, Excel, and Google Sheets"
 * @param {ModeType} modeBitField
 * @returns {string}
 */
export function describeModeBitfield (modeBitField: ModeType): string {
  invariant(!!modeBitField);
  const bits = individualModeBits(modeBitField);
  return oxfordCommaJoin(bits.map(modeBitToString));
}

export const COERCE_NONE = 0;
export const COERCE_CELLS = 1;
export const COERCE_SPILLS = 2;
export const COERCE_ALL = 3; // COERCE_CELLS | COERCE_SPILLS

export type CoercionMode = typeof COERCE_NONE | typeof COERCE_CELLS | typeof COERCE_SPILLS | typeof COERCE_ALL;

/** @type {Partial<Record<ModeBit, CoercionMode>>} */
const MODE_TO_COERCE_NULL_TO_ZERO: Partial<Record<ModeBit, CoercionMode>> = {
  [MODE_EXCEL]: COERCE_ALL,
  [MODE_GOOGLE]: COERCE_NONE,
};
const DEFAULT_NULL_TO_ZERO_COERCION = COERCE_NONE;

/**
 * @param {ModeBit} mode
 */
export function modeCoercesNullToZero (mode: ModeBit) {
  return MODE_TO_COERCE_NULL_TO_ZERO[mode] ?? DEFAULT_NULL_TO_ZERO_COERCION;
}

export { MODE_GRID_SHEET, MODE_EXCEL, MODE_GOOGLE, MODE_CANVAS, MODE_ALL };
