import type { ASTNode } from '../ast-types';

type FnParseFormula = (formula: string) => ASTNode;

/**
 * Formula parser function, if formula parser has finished (asynchronously) importing, else null.
 * This can be called from synchronous code which itself is guaranteed to be executed only after the formula parser
 * has finished importing. The caller is then responsible for enforcing that guarantee, and/or documenting that _its_
 * callers must enforce it, and/or guarding against this being `null` with appropriate error handling.
 */
export let parseFormula: FnParseFormula | null = null;

/**
 * Rewrite the given formula, adjusting references as appropriate for a given
 * move operation.
 * @throws {FormulaSyntaxError} if formula fails to parse
 * @type {ReplaceRefsOnMoveFn | null}
 */
export type ReplaceRefsOnMoveFn = (
  formula: string,
  moveFromRef: string,
  moveToRef: string,
  formulaSheet: string,
  moveFromSheet: string,
  moveToSheet: string,
  formulaWb: string,
  moveFromWb: string,
  moveToWb: string
) => string;

export let replaceRefsOnMove: ReplaceRefsOnMoveFn | null = null;

/**
 * Rewrite the given formula, replacing references to a given workbook with a
 * new workbook name.
 * @param formula formula to rewrite
 * @param fromWorkbookName existing workbook name to match (case-insensitive)
 * @param toWorkbookName new workbook name to replace `fromWorkbookName` with
 * @throws {FormulaSyntaxError} if formula fails to parse
 */
export type ReplaceWorkbookReferencesFn = (formula: string, fromWorkbookName: string, toWorkbookName: string) => string;

/**
 * @type {ReplaceWorkbookReferencesFn | null}
 */
export let replaceWorkbookReferences: ReplaceWorkbookReferencesFn | null = null;

/**
 * Rewrite the given formula, replacing references to a given sheet with a new
 * sheet name.
 * @param formula formula to rewrite
 * @param fromName existing sheet name to match (case-insensitive)
 * @param toName new sheet name to replace `fromName` with
 * @param workbookName workbook name (case-insensitive) in which to replace
 *   sheet references; reference prefixes with a different workbook name will be
 *   left unchanged
 * @param [includeNoWb=true] true to replace sheet name in reference prefixes
 *   that do not specify a workbook name
 * @throws {FormulaSyntaxError} if formula fails to parse
 */
export type ReplaceSheetReferencesFn = (
  formula: string,
  fromName: string,
  toName: string,
  workbookName: string,
  includeNoWb?: boolean
) => string;

/**
 * @type {ReplaceSheetReferencesFn | null}
 */
export let replaceSheetReferences: ReplaceSheetReferencesFn | null = null;

/**
 * Rewrite the given formula, replacing a given table name in structured
 * references with a new table name.
 * @param formula formula to rewrite
 * @param fromName existing table name to match (case-insensitive)
 * @param toName new table name to replace `fromName` with
 * @param workbookName workbook name (case-insensitive) in which to replace
 *   table references; reference prefixes with a different workbook name will be
 *   left unchanged
 * @param [includeNoWb=true] true to replace table name in structured references
 *   that do not specify a workbook name
 * @throws {FormulaSyntaxError} if formula fails to parse
 */
export type ReplaceTableReferencesFn = (
  formula: string,
  fromName: string,
  toName: string,
  workbookName: string,
  includeNoWb?: boolean
) => string;

/**
 * @type {ReplaceTableReferencesFn | null}
 */
export let replaceTableReferences: ReplaceTableReferencesFn | null = null;

/**
 * Promise which can be awaited in asynchronous code to ensure that the formula parser has finished importing,
 * and obtain the resolved `formulaParser/parser.js` module object.
 * @type {Promise<{
 *   parse: FnParseFormula,
 *   replaceRefsOnMove: ReplaceRefsOnMoveFn,
 *   replaceSheetReferences: ReplaceSheetReferencesFn,
 *   replaceWorkbookReferences: ReplaceWorkbookReferencesFn,
 * }>}
 */
export const ready: Promise<{
  parse: FnParseFormula,
  replaceRefsOnMove: ReplaceRefsOnMoveFn,
  replaceSheetReferences: ReplaceSheetReferencesFn,
  replaceWorkbookReferences: ReplaceWorkbookReferencesFn,
}> = import('./parser.js').then(parser => {
  parseFormula = parser.parse;
  replaceRefsOnMove = parser.replaceRefsOnMove;
  replaceSheetReferences = parser.replaceSheetReferences;
  replaceTableReferences = parser.replaceTableReferences;
  replaceWorkbookReferences = parser.replaceWorkbookReferences;
  return parser;
});

/**
 * Throw an Error if formula parser import has not been completed.
 * @throws {Error} if the `parseFormula` export is still null.
 */
export function assertParserLoaded () {
  if (parseFormula == null) {
    throw new Error('Precondition failed: formula parser must have finished importing before this is called');
  }
}
