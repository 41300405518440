export type ReturnOptions = Readonly<{ returnCells: boolean, returnBoxed: boolean, returnLambda: boolean }>;

export class ResolveAreaOptions<O extends ReturnOptions = ReturnOptions> {
  public readonly returnCells: O['returnCells'];
  public readonly returnBoxed: O['returnBoxed'];
  public readonly returnLambda: O['returnLambda'];
  public readonly cropTo: 'any-cell-information' | 'cells-with-non-blank-values';

  constructor (options: Partial<O> & { cropTo?: 'any-cell-information' | 'cells-with-non-blank-values' }) {
    this.returnCells = !!options.returnCells;
    this.returnBoxed = !!options.returnBoxed;
    this.returnLambda = !!options.returnLambda;
    this.cropTo = options.cropTo || 'any-cell-information';
  }
}
