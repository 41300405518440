import * as utm from './utm';

export function normalizeReferrer (url: string) {
  const loc = typeof window === 'undefined' ? { protocol: 'http', host: '' } : window.location;
  // URI matching from RFC-3986: https://tools.ietf.org/html/rfc3986#appendix-B
  const m = /^(([^:/?#]+):)?(\/\/([^/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?/.exec(url);
  return m ? `${m[2] || loc.protocol}://${m[4] || loc.host}` : url;
}

export function isLinkToGridDoc (urlString: string) {
  try {
    const { hostname, pathname } = new URL(urlString);
    if (
      hostname === 'localhost' || // local dev
      hostname === 'grid.is' || // legacy
      hostname === 'calculatorstudio.co' ||
      hostname === 'app.calculatorstudio.co' ||
      hostname === 'app-dev.calculatorstudio.co' || // used by cypress
      /grid-[a-z-]+?grid-is\.vercel\.app/.test(hostname) // branch deployments
    ) {
      return /^\/@[^/]+\/[^/]+$/.test(pathname);
    }
  }
  catch {
    // URL is invalid
  }
  return false;
}

export function isValidWebsiteUrl (url: string, allowUppercase: boolean = false) {
  if (allowUppercase) {
    return /^(?:https?:\/\/)?(?:[^.]+\.)?[A-z0-9]+\.[A-z.]{2,}(\/.*)?$/.test(url);
  }
  else {
    return /^(?:https?:\/\/)?(?:[^.]+\.)?[a-z0-9]+\.[a-z.]{2,}(\/.*)?$/.test(url);
  }
}

interface RedirectOpts {
  queryParams?: Record<string, string>,
}

const getRedirectUrl = (opts: RedirectOpts) => {
  const isBrowser = typeof window !== 'undefined';
  const loginRedirectURL = isBrowser ? window.location.pathname : '';
  const queryString = isBrowser && window.location.search ? window.location.search : '';
  const searchParams = new URLSearchParams(queryString);
  if (opts.queryParams) {
    Object.entries(opts.queryParams).forEach(([ key, value ]) => {
      searchParams.set(key, value);
    });
  }
  const modifiedQueryString = searchParams.toString();
  const url = joinUrl(loginRedirectURL, modifiedQueryString);
  return encodeURIComponent(url);
};

export const getLoginUrl = (redirectOpts: RedirectOpts = {}, ...utmParams) => {
  let url = `/user/login?next=${getRedirectUrl(redirectOpts)}`;
  if (utmParams) {
    url = utm.url(url, ...utmParams);
  }
  return url;
};

export const getSignupUrl = (redirectOpts: RedirectOpts = {}, ...utmParams) => {
  let url = `/get-grid/signup?next=${getRedirectUrl(redirectOpts)}`;
  if (utmParams) {
    url = utm.url(url, ...utmParams);
  }
  return url;
};

export const isValidDomain = (domain: string) => {
  // Allows:
  // https://www.domain.com
  // http://www.domain.com
  // www.domain.com
  // subdomain.domain.com
  // www.domain.com/something/another-thing
  // domain.com
  // DoMaIn.com
  return /^(?:https?:\/\/)?(?:[^.]+\.)?[a-z\d.-]+\.(?:[a-z]{2,}|\d{1,3})(?:\/.*)?$/i.test(domain);
};

export const isValidHttpUrl = (candidate?: string | URL) => {
  let url;

  try {
    url = new URL(candidate!);
  }
  catch {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export function isImageUrl (url: string): boolean {
  const imageExtensions = [ '.png', '.jpg', '.jpeg', '.svg', '.gif', '.webp' ];
  let result = false;
  if (isValidHttpUrl(url)) {
    const urlPath = new URL(url).pathname;
    const extension = urlPath.slice(urlPath.lastIndexOf('.'));
    result = imageExtensions.indexOf(extension.toLowerCase()) !== -1;
  }
  return result;
}

export function splitUrl (url: string): [string, string, string] {
  let path = '';
  let query = '';
  let hash = '';
  const queryIndex = url.indexOf('?');
  const hashIndex = url.indexOf('#', queryIndex + 1);
  if (queryIndex === -1 && hashIndex === -1) {
    path = url;
  }
  else if (queryIndex === -1) {
    path = url.slice(0, hashIndex);
    hash = url.slice(hashIndex + 1);
  }
  else if (hashIndex === -1) {
    path = url.slice(0, queryIndex);
    query = url.slice(queryIndex + 1);
  }
  else {
    path = url.slice(0, queryIndex);
    query = url.slice(queryIndex + 1, hashIndex);
    hash = url.slice(hashIndex + 1);
  }
  return [ path, query, hash ];
}

export function joinUrl (path: string, query: string, hash: string = ''): string {
  let url = path;
  if (query) {
    url += '?' + query;
  }
  if (hash) {
    url += '#' + hash;
  }
  return url;
}
