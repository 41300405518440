const defaultCellHeight = 16;

export class RowHeightGetter {
  /**
   * @param {import('@grid-is/apiary').WorkSheet} [sheet]
   */
  constructor (sheet) {
    this._sheet = sheet;
    this._defaultHeight = sheet?.defaults?.row_height ?? defaultCellHeight;
  }

  /**
   * @param {number} rowIndex
   * @returns {number}
   */
  get (rowIndex) {
    const adjustedIndex = rowIndex + 1;
    const height = this._sheet?.row_heights?.[adjustedIndex] ?? this._defaultHeight;
    return this._scale(height);
  }

  /**
   * @returns {number}
   */
  getDefault () {
    return this._scale(this._defaultHeight);
  }

  /**
   * @param {number} size
   * @returns {number}
   */
  _scale (size) {
    const scalingFactor = 1.3;
    if (size) {
      size *= scalingFactor;
    }
    return size;
  }
}
