import React from 'react';
import csx from 'classnames';
import PropTypes from 'prop-types';

import { token } from '@grid-is/browser-utils';
import { useMounted } from '@grid-is/custom-hooks';

import DesignGrid from './DesignGrid';
import { elementClasses } from './elementClasses';
import Error from './error';
import ErrorBoundary from './ErrorBoundary';
import gridHandlers from './index';
import Col from './layout/col';
import Row from './layout/row';
import Link from './text/link';
import Mention from './text/mention';
import Ruler from './text/ruler';
import TextBlock from './text/TextBlock';
import { defaultTheme } from './Theme';
import { ThemesProvider } from './ThemesContext';

import styles from './gridDocument.module.scss';

const hasOwnProperty = Object.prototype.hasOwnProperty;
const noOp = () => {};

function GridElm (props) {
  if (!props.isReady) {
    // TODO: Escaping rendering the GRID components during server side rendering.
    // so that search engine crawlers don't render buttons & other GRID elements
    // that reference the model
    return null;
  }
  let Obj = gridHandlers[props.type];
  if (!Obj) {
    Obj = Error;
    props = { ...props, message: `Unknown element: "${props.type}"` };
  }
  const id = props.uuid;
  return (
    <ErrorBoundary key={id + '-err'} emit={props.emit}>
      <Obj key={id + '-grid'} parentKey={id} {...props} />
    </ErrorBoundary>
  );
}

GridElm.propTypes = {
  isReady: PropTypes.bool,
  type: PropTypes.string,
  uuid: PropTypes.string,
  emit: PropTypes.func,
};

// these are all called with (props, slateDomNode)
const formatters = {

  // layout
  'row': p => <Row {...p} />,
  'col': p => <Col {...p} />,

  // grid
  'grid:block': p => <GridElm {...p} />,
  'grid:inline': p => <GridElm {...p} />,

  // text blocks
  'h1': p => <TextBlock {...p} />,
  'h2': p => <TextBlock {...p} />,
  'h3': p => <TextBlock {...p} />,
  'h4': p => <TextBlock {...p} />,
  'h5': p => <TextBlock {...p} />,
  'h6': p => <TextBlock {...p} />,
  'ol': p => <TextBlock {...p} />,
  'ul': p => <TextBlock {...p} />,
  'quote': p => <TextBlock {...p} />,
  'p': p => <TextBlock {...p} />,
  'code': p => <TextBlock {...p} />,
  'hr': p => <div id={p.id} key={p.key} className={elementClasses('hr')}><Ruler {...p} /></div>,

  // inline elements
  'link': p => <Link {...p} />,
  'mention': p => <Mention {...p} />,

  // default if no handler found
  'block': p => <span id={p.id} key={p.key}>{p.children}</span>,

  // editing components that shouldn't be there (but just in case...)
  'blank': () => null,
  'p:hidden': () => null,
  'inlinePlaceholder': () => null,

  // none of these should be rendered in view mode
  'slidebreak': () => null,
  'pagebreak': () => null,
  'chartAssistant': () => null,
};

const marks = [
  { id: 'bold', handler: p => <strong key={p.key}>{p.children}</strong> },
  { id: 'italic', handler: p => <em key={p.key}>{p.children}</em> },
  { id: 'code', handler: p => <code key={p.key}>{p.children}</code> },
];

function renderNode (node, globalProps, thumbnailMode, pageBreakAfter = false) {
  if (!node) {
    return [];
  }

  if (hasOwnProperty.call(node, 'text')) {
    let elm = node.text;
    marks.forEach(mark => {
      if (node[mark.id]) {
        elm = mark.handler({ key: node.id, children: elm });
      }
    });
    return elm;
  }

  let handler = formatters[node.type];
  if (!handler) {
    handler = formatters.block;
  }

  const inner = node.children
    ? node.children.map(n => renderNode(n, globalProps, thumbnailMode))
    : null;

  if (node.data && thumbnailMode) {
    node.data.thumbnailMode = true;
  }
  const nodeProps = Object.assign({
    key: node.id,
    id: node.id,
    element: node,
    children: inner,
    value: node.value,
    pageBreakAfter: pageBreakAfter,
  }, globalProps, node.data);

  nodeProps.track = (eventType, extraData = {}) => {
    nodeProps.emit('track', {
      isGRID: true,
      type: eventType,
      elementType: 'unknown',
      documentId: nodeProps.documentId,
      elementId: node.id,
      elementLabel: nodeProps.title || '',
      timestamp: Date.now(),
      submitData: {},
      ...extraData,
    });
  };

  return handler(nodeProps, node);
}

function findNode (id, nodes) {
  if (nodes) {
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      if (node.id === id) {
        return node;
      }
      else {
        const r = findNode(id, node.children);
        if (r) {
          return r;
        }
      }
    }
  }
}

function onClick (e, document, globalProps) {
  // find nearest element that has an ID
  let curr = e.target;
  while (curr && globalProps.emit) {
    if (curr.id) {
      // is element a document node?
      const node = findNode(curr.id, document);
      if (node) {
        globalProps.emit('interaction', {
          type: 'click',
          id: node.id,
          name: node.type,
          attr: { ...node.data },
          documentId: globalProps.documentId,
        });
        return;
      }
    }
    curr = curr.parentNode;
  }
}

/**
 * @param {object} props Document render options
 * @param {object[]} props.document
 * @param {import('@grid-is/apiary').Model} props.model
 * @param {object} [props.theme];
 * @param {import('./ChartTheme').default} [props.chartTheme]
 * @param {Record<string, any>} [props.globalProps]
 * @param {object} [props.renderedRef]
 * @param {import('react').ReactNode} [props.children]
 * @param {boolean} [props.useFullWidth]
 * @param {boolean} [props.thumbnailMode]
 * @param {boolean} [props.embed=false];
 */
export default function GridDocument (props) {
  const { theme, embed, model } = props;
  const isReady = useMounted();

  if (!props.document.length) {
    return null;
  }
  const globalProps = {
    emit: noOp,
    track: noOp,
    onError: noOp,
    ...props.globalProps,
    theme,
    model,
    isAuthenticated: !!token.get(),
    isReady,
    embed,
  };

  return (
    <div
      style={theme.styles}
      id="grid-doc-body"
      data-testid="grid-document"
      className={csx(styles.document, props.useFullWidth && styles.useFullWidth)}
      ref={props.renderedRef}
      onClick={e => onClick(e, props.document, globalProps)}
      >
      <ThemesProvider
        chartTheme={props.chartTheme}
        theme={theme}
        >
        {props.document.map((node, i) => {
          const pageBreakAfter = (props.document[i + 1]?.type === 'pagebreak');
          return renderNode(node, globalProps, props.thumbnailMode, pageBreakAfter);
        })}
        {props.children}
        <DesignGrid visible={false} />
      </ThemesProvider>
    </div>
  );
}

GridDocument.propTypes = {
  document: PropTypes.array.isRequired,
  thumbnailMode: PropTypes.bool,
  globalProps: PropTypes.object,
  theme: PropTypes.object,
  chartTheme: PropTypes.object,
  useFullWidth: PropTypes.bool,
  model: PropTypes.object.isRequired,
  renderedRef: PropTypes.object,
  embed: PropTypes.bool,
};

GridDocument.defaultProps = {
  theme: defaultTheme,
  embed: false,
};

GridElm.propTypes = {
  uuid: PropTypes.string,
  type: PropTypes.string,
  isReady: PropTypes.bool,
};
