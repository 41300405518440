import { Reference } from '@grid-is/apiary';
import { sessionStorage } from '@grid-is/browser-utils';
import { DefaultMap } from '@grid-is/collections';
import { assert } from '@grid-is/validation';

import { Selection } from '../utils/Selection';

export const SelectionMemoryKey = 'SelectionMemory';

export type WorkbookSelection = {
  lastSelectedSheet: string,
  selections: Map<string, Selection>,
}

type SelectionMemoryEntry = [string, WorkbookSelection];

export class SelectionMemory {
  workbookSelections: DefaultMap<string, WorkbookSelection>;

  constructor (entries: SelectionMemoryEntry[] = []) {
    this.workbookSelections = new DefaultMap(() => {
      return { lastSelectedSheet: '', selections: new Map() };
    }, entries);
  }

  setSelection (workbookId: string, sheetName: string, selection: Selection) {
    const workbook = this.workbookSelections.get(workbookId);
    workbook.lastSelectedSheet = sheetName;
    workbook.selections.set(sheetName, selection);
    storeSelectionMemory(this);
  }

  getSelection (workbookId: string, sheetName: string) {
    const workbook = this.workbookSelections.get(workbookId);
    return workbook.selections.get(sheetName);
  }

  getSheet (workbookId: string) {
    const workbook = this.workbookSelections.get(workbookId);
    return workbook.lastSelectedSheet;
  }

  toString () {
    return JSON.stringify(
      Array.from(this.workbookSelections.entries()).map(([ workbookId, workbook ]) => {
        return [
          workbookId,
          {
            lastSelectedSheet: workbook.lastSelectedSheet,
            selections: Array.from(workbook.selections.entries()).map(([ sheetName, selection ]) => [ sheetName, String(selection) ]),
          },
        ];
      }),
    );
  }
}

function deserialize (serializedEntries: string): SelectionMemoryEntry[] | undefined {
  const entries = JSON.parse(serializedEntries);
  if (Array.isArray(entries)) {
    return entries.map(([ workbookId, workbook ]: [string, { lastSelectedSheet: string, selections: string[]}]) => {
      return [
        workbookId,
        {
          lastSelectedSheet: workbook.lastSelectedSheet,
          selections: new Map(workbook.selections.map(([ sheetName, stringifiedSelection ]) => {
            const ref = new Reference(stringifiedSelection);
            assert(ref.range, 'selection should be an A1 reference');
            return [ sheetName, new Selection(ref.range) ];
          })),
        },
      ];
    });
  }
}

export function restoreSelectionMemory () {
  try {
    const entries = deserialize(sessionStorage.getItem(SelectionMemoryKey));
    return new SelectionMemory(entries);
  }
  catch {
    return new SelectionMemory();
  }
}

function storeSelectionMemory (SelectionMemory: SelectionMemory) {
  sessionStorage.setItem(SelectionMemoryKey, SelectionMemory.toString());
}

export function clearSelectionMemory () {
  sessionStorage.removeItem(SelectionMemoryKey);
}

