/* eslint-disable default-param-last */
import { Range, Reference } from '@grid-is/apiary';

/**
 * @param {object} props
 * @param {import('@grid-is/apiary').Model} props.model
 * @param {string | Reference | null} [props.reference]
 * @param {string | null} [props.sheetName]
 * @returns {import('@grid-is/apiary').A1Reference}
 */
export function resolveRenderContext (props) {
  const model = props.model;
  let workbookName = model.defaultWorkbookName;
  let sheetName = '';
  let top = 0;
  let bottom = 0;
  let left = 0;
  let right = 0;
  let setRange = false;

  // if we have a qualified reference, read props from that
  if (props.reference) {
    const ref = Reference.from(props.reference);
    if (ref) {
      workbookName = ref.workbookName;
      sheetName = ref.sheetName;
      if (ref.range) {
        top = ref.top;
        bottom = ref.bottom;
        left = ref.left;
        right = ref.right;
        setRange = true;
      }
    }
  }

  if (props.sheetName) {
    sheetName = props.sheetName;
  }

  // XXX: We might want to make an exception here for GRID sheets and allow
  //      open ended selections in them. That, however, means that we'll need to
  //      solve the problem of what happens if user selects 1:1 which this is
  //      allowing us to sidestep.
  const wb = model.getWorkbook(workbookName);
  if (wb) {
    const sheetSize = wb.getSheetSize(sheetName);
    if (!setRange) {
      [ right, bottom ] = wb.getSheetSize(sheetName);
    }
    else if ((sheetSize[0] * sheetSize[1]) === 0) {
      // empty [GRID] sheet
      right = 0;
      bottom = 0;
    }
    else {
      right = Math.min(right, sheetSize[0] - 1);
      bottom = Math.min(bottom, sheetSize[1] - 1);
    }
  }

  if (right < left) {
    left = right;
  }
  if (bottom < top) {
    top = bottom;
  }

  const range = new Range({ top, left, bottom, right });

  return /** @type {import('@grid-is/apiary').A1Reference} */ (
    new Reference(range, { sheetName, workbookName })
  );
}
