import Range, { type SlimRange } from './excel/referenceParser/Range.js';
import { invariant } from './validation';
import { CellVertexId, NameVertexId, RangeVertexId, vertexIdFromRange } from './DependencyGraph';
import Reference, { checkA1, type A1Reference } from './excel/Reference';
import { parseFormula, replaceRefsOnMove } from './excel//formulaParser';
import { referenceToVertexId } from './dep-graph-helpers';
import type Workbook from './Workbook';
import type Cell from './excel/Cell';

/**
 * @param relativeToFrom Range relative to the `from` argument
 * @returns Range relative to the `to` argument
 */
export function translateRelative (
  relativeToFrom: Range,
  from: { top: number, left: number },
  to: { top: number, left: number },
): SlimRange {
  const vdelta = relativeToFrom.top - from.top;
  const hdelta = relativeToFrom.left - from.left;
  const top = to.top + vdelta;
  const left = to.left + hdelta;
  return {
    top,
    left,
    bottom: top + relativeToFrom.height - 1,
    right: left + relativeToFrom.width - 1,
  };
}

/**
 * Assuming that the cells within `from` will be moved to `to`,
 * rewrite `.f` fields of formulas referencing cells in `from`.
 */
function rewriteFormulasAfterMove (wb: Workbook, from: Reference, to: Reference) {
  const alreadyRewritten = new Set<Cell>();
  rewriteReferencesPointingTo(wb, to, from, to, alreadyRewritten);
  rewriteReferencesPointingTo(wb, from, from, to, alreadyRewritten);
}

/**
 * Will format 1x1 ranges as e.g. `A1:A1`.
 */
function rangeToVerboseString (range: Range): string {
  if (range.size === 1) {
    const formatted = range.toString();
    return formatted + ':' + formatted;
  }
  else {
    return range.toString();
  }
}

/**
 * Precondition: formula parser has finished importing (`formulaParserReady` has resolved).
 * @param from reference containing a workbook and sheet prefix
 * @param to reference containing a workbook and sheet prefix
 * @returns the updated formula.
 */
export function rewriteFormulaAfterMove (
  contextWorkbookName: string,
  contextSheetName: string,
  formula: string,
  from: string | A1Reference,
  to: string | A1Reference,
): string {
  if (!replaceRefsOnMove) {
    throw new Error("'replaceRefsOnMove' is not ready");
  }
  from = checkA1(new Reference(from));
  to = checkA1(new Reference(to));

  if (!from.workbookName || !from.sheetName) {
    return formula;
  }
  if (!to.workbookName || !to.sheetName) {
    return formula;
  }

  return replaceRefsOnMove(
    formula,
    rangeToVerboseString(from.range),
    rangeToVerboseString(to.range),
    contextSheetName,
    from.sheetName,
    to.sheetName,
    contextWorkbookName,
    from.workbookName,
    to.workbookName,
  );
}

/**
 * Precondition: formula parser has finished importing (`formulaParserReady` has resolved).
 */
function rewriteReferencesPointingTo (
  wb: Workbook,
  pointingToRef: Reference,
  from: Reference,
  to: Reference,
  alreadyRewritten: Set<Cell>,
) {
  const fromRange = from.range;
  invariant(fromRange instanceof Range && from.sheetName != null && from.workbookName != null);
  const toRange = to.range;
  invariant(toRange instanceof Range && to.sheetName != null && to.workbookName != null);
  invariant(
    fromRange.width === toRange.width && fromRange.height === toRange.height,
    `ranges should be same size, ${fromRange} ~ ${toRange}`,
  );

  const pointingToSheetIdx = wb.getSheetIndex(pointingToRef.sheetName);
  invariant(pointingToSheetIdx != null);
  invariant(pointingToRef.range instanceof Range);
  const vertexId = vertexIdFromRange(wb.keyInDepGraph, pointingToSheetIdx, pointingToRef.range);
  wb._model._graph.visitIncomingEdges(vertexId, edge => {
    if (edge.from.id instanceof NameVertexId) {
      // References from defined-name formulas can be ignored here, because:
      // * they are _currently_ always from cached-formula defined names,
      //   because those are the only defined-name formulas in GRID Sheets,
      //   which are the only workbooks in which the user can move cells.
      // * cached formulas should not themselves be rewritten; the actual home
      //   of the formula to rewrite is in an element option in the document,
      //   and those are rewritten using `Model.rewriteFormulaAfterMove` when
      //   the workbook editor performs a move operation.
      //
      // XXX Revisit this if/when we support _actual_ defined names (that aren't
      // just cached formulas) in GRID Sheets.
      return;
    }
    const dependentSheet = wb.getSheetByIndex(edge.from.id.sheetIndex);
    invariant(dependentSheet != null && typeof dependentSheet.name === 'string');
    const dependentCell = dependentSheet.getCellByCoords(edge.from.id.rowIndex, edge.from.id.colIndex);
    invariant(dependentCell != null && dependentCell.f != null);

    if (alreadyRewritten.has(dependentCell)) {
      return;
    }

    const wbName = wb.name;
    invariant(wbName != null);
    // @ts-expect-error replaceRefsOnMove is ensured non-null by the documented precondition
    const newFormula = replaceRefsOnMove(
      dependentCell.f,
      rangeToVerboseString(fromRange),
      rangeToVerboseString(toRange),
      dependentSheet.name,
      from.sheetName,
      to.sheetName,
      // This function is for intra-workbook moves only. Waspiary supports moves
      // across workbooks though.
      wbName,
      wbName,
      wbName,
    );
    alreadyRewritten.add(dependentCell);
    if (newFormula === dependentCell.f) {
      return;
    }
    dependentCell.f = newFormula;
    // @ts-expect-error parseFormula is ensured non-null by the documented precondition
    dependentCell._ast = parseFormula(newFormula);
    dependentCell.v = null;

    const vertexId = referenceToVertexId(
      wb._model,
      new Reference(
        new Range({
          top: edge.from.id.rowIndex,
          left: edge.from.id.colIndex,
        }),
        { sheetName: dependentSheet.name, workbookName: wb.name },
      ),
    );
    invariant(vertexId instanceof CellVertexId);
    wb._model._recalcState.writtenSinceRecalc.push(vertexId);
  });
}

/**
 * If one of the references has size 1x1, but the other does not, then expand
 * the former to match the latter. Top-left corner is maintained.
 */
function coerce1x1RefToOtherSize (ref1: Reference, ref2: Reference): [Reference, Reference] {
  if (!(ref1.range instanceof Range && ref2.range instanceof Range)) {
    return [ ref1, ref2 ];
  }
  if (ref1.width === ref2.width && ref1.height === ref2.height) {
    return [ ref1, ref2 ];
  }
  if (ref1.size !== 1 && ref2.size !== 1) {
    throw new Error('Invalid reference sizes given');
  }
  if (ref1.size === 1) {
    const newRange = new Range({
      ...ref1.range,
      right: ref1.left + ref2.width - 1,
      bottom: ref1.top + ref2.height - 1,
    });
    return [ new Reference(newRange, { sheetName: ref1.sheetName, workbookName: ref1.workbookName }), ref2 ];
  }
  else {
    invariant(ref2.size === 1);
    const newRange = new Range({
      ...ref2.range,
      right: ref2.left + ref1.width - 1,
      bottom: ref2.top + ref1.height - 1,
    });
    return [ ref1, new Reference(newRange, { sheetName: ref2.sheetName, workbookName: ref2.workbookName }) ];
  }
}

/**
 * Precondition: formula parser has finished importing (`formulaParserReady` has resolved).
 * @param wb The workbook to act on.
 * @param from If range is 1x1, the width/height from `to` is used.
 * @param to If range is 1x1, the width/height from `from` is used.
 */
function moveCells (wb: Workbook, from: string | Reference, to: string | Reference) {
  let fromRef = new Reference(from, { ctx: wb });
  let toRef = new Reference(to, { ctx: wb });
  [ fromRef, toRef ] = coerce1x1RefToOtherSize(fromRef, toRef);

  rewriteFormulasAfterMove(wb, fromRef, toRef);

  const fromSheet = wb.getSheet(fromRef.sheetName);
  const toSheet = wb.getSheet(toRef.sheetName);
  const fromRange = fromRef.range;
  const toRange = toRef.range;
  invariant(fromSheet && toSheet && fromRange instanceof Range && toRange instanceof Range);
  let { changedRefsInFrom, changedRefsInTo } = fromSheet.moveCells(toSheet, fromRange, toRange);

  changedRefsInFrom = changedRefsInFrom.map(ref => ref.withPrefix({ workbookName: wb.name }));
  changedRefsInTo = changedRefsInTo.map(ref => ref.withPrefix({ workbookName: wb.name }));
  const changedRefs = [ ...changedRefsInFrom, ...changedRefsInTo ];
  // Formulas may need recalculation
  wb.updateDependencies();
  const model = wb._model;
  const changed = model._recalcState.changedSinceRecalc;
  for (const ref of changedRefs) {
    const vertexId = referenceToVertexId(model, ref);
    invariant(vertexId instanceof RangeVertexId || vertexId instanceof CellVertexId);
    changed.push(vertexId);
  }
  model.recalculate();
}

export default moveCells;
