import handlers from '.';
import { SpreadsheetFunction, type EvaluationContext } from '../EvaluationContext';
import FormulaError from '../FormulaError';
import type Reference from '../Reference';
import { ASTCallNode } from '../ast-types';
import { ERROR_NAME, ERROR_REF } from '../constants';
import { Lambda, isLambda } from '../lambda';
import { NEVER_SUPPORTED, funcSigs } from '../signatures';
import { LazyArgumentFunction, MaybeBoxedFormulaArgument } from '../types';
import { lazyArgumentFunctions } from './lazy-argument';
import { isErr, isRef, isMatrix } from './utils';
import { unbox } from '../ValueBox';

type AnySpreadsheetFunction = SpreadsheetFunction<MaybeBoxedFormulaArgument[]>;

export function resolveCallee (
  ast: ASTCallNode,
  ctx: EvaluationContext,
  nameExists: (name: string) => boolean,
  nameRefCallback?: (ref: Reference | 'volatile') => void,
):
  | { funcName: string, handler: AnySpreadsheetFunction, lazyArgHandler: null, lambda: null }
  | { funcName: string, handler: null, lazyArgHandler: LazyArgumentFunction, lambda: null }
  | { funcName: string | null, handler: null, lazyArgHandler: null, lambda: Lambda | null }
  | FormulaError {
  const [ funcName, handler, lazyArgHandler ] =
    typeof ast.call === 'string' ? normalizeAndLookup(ast.call) : [ null, null, null ];
  if (handler) {
    return { funcName, handler, lazyArgHandler: null, lambda: null };
  }
  else if (lazyArgHandler) {
    return { funcName, handler, lazyArgHandler, lambda: null };
  }
  const lambda = resolveLambda(funcName, ast.call, ctx, nameExists, nameRefCallback);
  if (isErr(lambda)) {
    return lambda;
  }
  if (lambda == null) {
    return { funcName, handler: null, lazyArgHandler: null, lambda: null };
  }
  if (!isLambda(lambda)) {
    return isRef(lambda)
      ? ERROR_REF.detailed('Cannot lambda-call a cell reference')
      : ERROR_NAME.detailed('Cannot lambda-call a non-lambda defined-name formula');
  }
  return { funcName, handler, lazyArgHandler, lambda };
}

// Accept a Lambda, or a 1x1 Matrix only containing a Lambda
function extractLambda (lambdaOrMatrix: MaybeBoxedFormulaArgument): Lambda | null {
  if (isLambda(lambdaOrMatrix)) {
    return unbox(lambdaOrMatrix);
  }
  if (isMatrix(lambdaOrMatrix)) {
    const single = lambdaOrMatrix.resolveSingle();
    if (isLambda(single)) {
      return single;
    }
  }
  return null;
}

export function resolveLambda (
  name: string | null,
  ast: ASTCallNode['call'],
  ctx: EvaluationContext,
  nameExists: (name: string) => boolean,
  nameRefCallback?: (ref: Reference | 'volatile') => void,
): Lambda | FormulaError | null {
  let lambdaOrRef: MaybeBoxedFormulaArgument;
  if (name) {
    lambdaOrRef = ctx.evaluateASTNode({ name });
  }
  else if (typeof ast === 'object' && 'bound' in ast) {
    lambdaOrRef = ast.bound;
  }
  else if (typeof ast === 'object' && 'lambda' in ast) {
    lambdaOrRef = Lambda.fromAST(ast);
  }
  else {
    lambdaOrRef = ctx.evaluateASTNode(ast);
  }
  if (isRef(lambdaOrRef)) {
    const ref = lambdaOrRef;
    const maybeLambda = extractLambda(ref.resolveToNonName());
    if (maybeLambda != null) {
      nameRefCallback?.(ref);
      lambdaOrRef = maybeLambda;
    }
    else if (ref.name) {
      if (nameExists(ref.name)) {
        return ERROR_REF.detailed(`Cannot lambda-call ${ref} which does not resolve to a lambda`);
      }
      const funcName = normalizeFunctionName(ref.name);
      const isKnownFunction = NEVER_SUPPORTED.includes(funcName) || funcName in funcSigs;
      if (!isKnownFunction) {
        // Possible lambda reference to a name defined later; record reference
        nameRefCallback?.(ref);
      }
      return null;
    }
  }
  const maybeLambda = extractLambda(lambdaOrRef);
  if (maybeLambda != null) {
    return maybeLambda;
  }
  return null;
}

function normalizeAndLookup (
  rawFunctionName: string,
): [string, AnySpreadsheetFunction, null] | [string, null, LazyArgumentFunction] | [string, null, null] {
  const normalizedName = normalizeFunctionName(rawFunctionName);
  const handler = handlers[normalizedName] || null;
  const lazyArgHandler = lazyArgumentFunctions[normalizedName] || null;
  return [ normalizedName, handler, lazyArgHandler ];
}

function normalizeFunctionName (rawFunctionName: string) {
  return rawFunctionName.toUpperCase().replace(/^(?:_XLFN\.|_XLWS\.|__XLUDF\.)+/, '');
}
