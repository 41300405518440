import { ERROR_VALUE } from '../constants';
import { toNum } from './utils';
import { isNum, isStr, isErr, isRef, isMatrix } from '../../utils';
import type { FormulaArgument } from '../types';
import type FormulaError from '../FormulaError';

/** Filters an argument list down to numbers or error but allows fewer things through
 * than the toNumList method used by aggregate functions.
 * Conforms to the behaviour of XNPV (in Excel).
 * @param args zero or more arguments
 * @param [errorType] error value to return if a non-number is encountered
 */
export function toNumListStrict (
  args: FormulaArgument[],
  errorType: FormulaError = ERROR_VALUE,
): number[] | FormulaError {
  const res: number[] = [];
  let r = 0;
  for (const arg of args) {
    const value = arg;
    if (isNum(value) && isFinite(value)) {
      res[r++] = value;
    }
    else if (isStr(value)) {
      const n = value ? toNum(value) : errorType;
      if (isErr(n) || !isFinite(n)) {
        return errorType;
      }
      res[r++] = n;
    }
    else if (isRef(value) || isMatrix(value)) {
      const sub = value.resolveRange({ skipBlanks: 'none' });
      if (isErr(sub)) {
        return sub;
      }
      for (const subValue of sub) {
        if (isNum(subValue) && isFinite(subValue)) {
          res[r++] = subValue;
        }
        else {
          return errorType;
        }
      }
    }
    else {
      return errorType;
    }
  }
  return res;
}
