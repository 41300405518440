import { ERROR_NA, ERROR_VALUE, MISSING } from '../constants';
import type { EvaluationContext } from '../EvaluationContext';
import type FormulaError from '../FormulaError';
import type Reference from '../Reference';
import type { CellValueAtom, FormulaArgument } from '../types';
import { eachToMatrix, areAllRangesSameSize, extendIfFuncRange, filterMatrixByCriteria } from './utils-funcif';
import { average, standardFilterMap, min, max, sum, count, type FnNumberFilterMap } from './utils-visit';
import { isErr } from './utils';

/**
 * AVERAGEIF(criteria_range, criterion, [average_range])
 * Returns the average of a range depending on criteria.
 */
export function AVERAGEIF (
  this: EvaluationContext,
  range: Reference,
  criteria: CellValueAtom | FormulaError,
  averageRange?: Reference | null,
) {
  if (averageRange == null) {
    averageRange = range;
  }
  else if (range.width !== averageRange.width || range.height !== averageRange.height) {
    averageRange = extendIfFuncRange(averageRange, range, this);
  }
  return AVERAGEIFS(averageRange, range, criteria);
}

/**
 * AVERAGEIFS(average_range, criteria_range1, criterion1, [criteria_range2, criterion2, ...])
 * Returns the average of a range depending on multiple criteria.
 */
export function AVERAGEIFS (averageRange: Reference, ...criteriaArgs: (Reference | CellValueAtom | FormulaError)[]) {
  if ((criteriaArgs.length & 1) !== 0) {
    // Check even number of criteria args.
    // FIXME: This should be removed when the number of arguments is validated using signature.
    return ERROR_NA;
  }
  return aggregateIfs(average, averageRange, criteriaArgs);
}

/**
 * COUNTIF(range, criterion)
 * Returns a conditional count across a range.
 */
export function COUNTIF (range: Reference, criteria: CellValueAtom | FormulaError) {
  return COUNTIFS(range, criteria);
}

/**
 * COUNTIFS(criteria_range1, criterion1, [criteria_range2, criterion2, ...])
 * Returns the count of a range depending on multiple criteria.
 */
export function COUNTIFS (...criteriaArgs: (Reference | CellValueAtom | FormulaError)[]) {
  if ((criteriaArgs.length & 1) !== 0) {
    // Check even number of criteria args.
    // FIXME: This should be removed when the number of arguments is validated using signature.
    return ERROR_NA;
  }

  // @ts-expect-error (enforced by signature)
  const criteria: (CellValueAtom | FormulaError)[] = criteriaArgs.filter((_, i) => i % 2 !== 0);

  // @ts-expect-error (enforced by signature)
  const criteriaRanges: Reference[] = criteriaArgs.filter((_, i) => i % 2 === 0);

  if (!areAllRangesSameSize([ ...criteriaRanges ])) {
    return ERROR_VALUE;
  }

  const criteriaMatrices = eachToMatrix(criteriaRanges);
  if (isErr(criteriaMatrices)) {
    return criteriaMatrices;
  }
  const filtered = filterMatrixByCriteria(criteriaMatrices[0], criteria, criteriaMatrices);
  return count(filtered, x => [ x !== MISSING, x ]);
}

/**
 * MAXIFS(range, criteria_range1, criterion1, [criteria_range2, criterion2], …)
 * Returns the maximum value in a filtered range of cells, filtered by a set of criteria applied to additional ranges.
 */
export function MAXIFS (range: Reference, ...criteriaArgs: (Reference | CellValueAtom | FormulaError)[]) {
  if ((criteriaArgs.length & 1) !== 0) {
    // Check even number of criteria args.
    // FIXME: This should be removed when the number of arguments is validated
    // using signature.
    return ERROR_NA;
  }
  return aggregateIfs(max, range, criteriaArgs);
}

/**
 * MINIFS(range, criteria_range1, criterion1, [criteria_range2, criterion2], …)
 * Returns the minimum value in a filtered range of cells, filtered by a set of
 * criteria applied to additional ranges.
 */
export function MINIFS (range: Reference, ...criteriaArgs: (Reference | CellValueAtom | FormulaError)[]) {
  if ((criteriaArgs.length & 1) !== 0) {
    // Check even number of criteria args.
    // FIXME: This should be removed when the number of arguments is validated using signature.
    return ERROR_NA;
  }
  return aggregateIfs(min, range, criteriaArgs);
}

/**
 * SUMIF(range, criteria, [sum_range])
 * Returns a conditional sum across a range.
 * @see https://support.office.com/en-us/article/sumif-function-169b8c99-c05c-4483-a712-1697a653039b
 */
export function SUMIF (
  this: EvaluationContext,
  range: Reference,
  criteria: CellValueAtom | FormulaError,
  sumRange: Reference | null,
) {
  if (sumRange == null) {
    sumRange = range;
  }
  else if (range.width !== sumRange.width || range.height !== sumRange.height) {
    sumRange = extendIfFuncRange(sumRange, range, this);
  }
  return SUMIFS(sumRange, range, criteria);
}

/**
 * SUMIFS(sum_range, criteria_range1, criterion1, [criteria_range2, criterion2, ...])
 * Returns the sum of a range depending on multiple criteria.
 */
export function SUMIFS (sumRange: Reference, ...criteriaArgs: (Reference | CellValueAtom | FormulaError)[]) {
  if ((criteriaArgs.length & 1) !== 0) {
    // Check even number of criteria args.
    // FIXME: This should be removed when the number of arguments is validated using signature.
    return ERROR_NA;
  }
  return aggregateIfs(sum, sumRange, criteriaArgs);
}

/**
 * @param criteriaArgs even-numbered array of
 *   criteria arguments, [ criteria_range1, criterion1, ... ] where the
 *   odd-numbered arguments are all of type `Reference` and the even-numbered
 *   arguments are all of type `CellValue`. This should be guaranteed by the
 *   caller and is not checked here.
 */
function aggregateIfs (
  aggregation: (args: FormulaArgument | FormulaArgument[], filterMap: FnNumberFilterMap) => number | FormulaError,
  targetRange: Reference,
  criteriaArgs: (Reference | CellValueAtom | FormulaError)[],
) {
  // @ts-expect-error (enforced by signature)
  const criteria: (CellValueAtom | FormulaError)[] = criteriaArgs.filter((_, i) => i % 2 !== 0);
  // @ts-expect-error (enforced by signature)
  const criteriaRanges: Reference[] = criteriaArgs.filter((_, i) => i % 2 === 0);

  if (!areAllRangesSameSize([ targetRange, ...criteriaRanges ])) {
    return ERROR_VALUE;
  }

  const target = targetRange.toMatrix(false);
  if (isErr(target)) {
    return target;
  }
  const criteriaMatrices = eachToMatrix(criteriaRanges);
  if (isErr(criteriaMatrices)) {
    return criteriaMatrices;
  }
  const filtered = filterMatrixByCriteria(target, criteria, criteriaMatrices);
  return aggregation(filtered, standardFilterMap);
}
