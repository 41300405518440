import { useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import csx from 'classnames';

import { Reference } from '@grid-is/apiary';
import { assert } from '@grid-is/validation';

import { EventStream } from '@/editor/EditorEventStream';
import { getElementOptionsForSelection } from '@/editor/elementOptionAid';
import { EditableElementTypes } from '@/editor/types/elements';
import { GridBlockElement } from '@/editor/types/elements/block';
import { TableElement, UploadOrInsertTabularDataToGridSheets } from '@/editor/utils/paste';
import gridHandlers, { GridBlockElementTypes } from '@/grid';
import { Doc } from '@/grid/Doc';
import { gridElementMap } from '@/grid/elements';
import ErrorBoundary from '@/grid/ErrorBoundary';
import { Modal } from '@/grid-ui/Modal';
import { useDeps } from '@/bootstrapping/dependencies';
import { useAuth } from '@/utils/auth';
import { CellsProxy } from '@/WorkbookEditor/Sheet/utils/CellsProxy';
import { Selection } from '@/WorkbookEditor/utils/Selection';

import { getAppropriateElementTypesForSelection } from '../../SpreadsheetPanel/insertFromSpreadsheet';

import styles from './PasteTabularDataToGridSheets.module.scss';

type PasteTabularDataToGridSheetsProps = {
  doc: Doc,
  globalProps?: Record<string, any>,
}

// In the first iteration, we only support these previews
const orderedSuggestions: GridBlockElementTypes[] = [
  'table',
  'column',
  'line',
  'pie',
  'bar',
  'scatter',
];

function isSuggestibleBlockData (data: unknown): data is GridBlockElement['data'] {
  return data != null && orderedSuggestions.includes((data as GridBlockElement['data']).type);
}

export const PasteTabularDataToGridSheets = ({ doc, globalProps = {} }: PasteTabularDataToGridSheetsProps) => {
  const [ suggestedElements, setSuggestedElements ] =
    useState<GridBlockElement[]>([]);
  const uploader = useRef(new UploadOrInsertTabularDataToGridSheets());
  const focusedPreview = useRef<HTMLDivElement>(null);
  const { user } = useAuth();
  const { userEvents } = useDeps();
  const isDocumentOwner = !!(user?.id && user?.id === doc.creator.id);
  useEffect(() => {
    async function pasteTabularData (tableElementsWithClipboardData: TableElement[]) {
      try {
        const tableElementsWithExpr = await uploader.current.insertTabularDataToGridSheets(doc, tableElementsWithClipboardData, isDocumentOwner, userEvents);
        if (tableElementsWithExpr) {
          tableElementsWithExpr.forEach(elementData => EventStream.emit(EventStream.UPDATE_ELEMENT_DATA, elementData));
          if (tableElementsWithExpr.length === 1) {
            // if there is only one table on the clipboard,
            // we see if we can suggest a different element to insert
            const wb = doc.model.getWorkbooks().find(({ type }) => type === 'native');
            if (wb) {
              const refAsString = tableElementsWithExpr[0]?.data?.expr?.slice(1);
              if (refAsString) {
                const ref = new Reference(refAsString);
                assert(ref.range, 'ref should be an A1 reference');
                const suggestedElementTypes = getAppropriateElementTypesForSelection(new Selection(ref.range), new CellsProxy(ref.sheetName, wb));
                const suggestedElements: GridBlockElement[] = [];
                orderedSuggestions.forEach(type => {
                  if (suggestedElementTypes.indexOf(type) === -1) {
                    return true;
                  }
                  const suggestionData = getElementOptionsForSelection({
                    type,
                    model: doc.model,
                    workbookName: wb.name,
                    sheetName: ref.sheetName,
                    range: ref.range,
                  }) as GridBlockElement['data'] | { type?: EditableElementTypes};
                  if (isSuggestibleBlockData(suggestionData)) {
                    suggestedElements.push({ type: 'grid:block', id: tableElementsWithExpr[0].id, data: suggestionData, children: [ { text: '' } ] });
                  }
                });
                setSuggestedElements(suggestedElements);
                focusedPreview.current?.focus();
              }
            }
          }
        }
      }
      catch (e) {
        Sentry.captureException(e);
      }
    }
    EventStream.on(EventStream.PASTE_TABULAR_DATA, pasteTabularData);
    return () => {
      EventStream.off(EventStream.PASTE_TABULAR_DATA, pasteTabularData);
    };
  }, [ doc, isDocumentOwner, userEvents ]);

  if (suggestedElements.length <= 1) {
    return null;
  }

  function onSelectSuggestion (el: GridBlockElement) {
    EventStream.emit(EventStream.UPDATE_ELEMENT_DATA, el);
    setSuggestedElements([]);
  }
  return (
    <Modal
      open
      header="Paste as..."
      size="large"
      onClose={() => setSuggestedElements([])}
      >
      <section className={styles.main}>
        {suggestedElements.map((el, i) => {
          const { type } = el.data;
          const elementTypeInfo = gridElementMap.get(type);
          const suggestedElementLabel = elementTypeInfo?.label || type;
          const Obj = gridHandlers[type];
          const showHorizontalOverlay = type === 'table' || type === 'bar';
          return (
            <div
              key={type}
              className={csx(styles.suggestion, showHorizontalOverlay && styles.horizontalOverlay, type === 'table' && styles.verticalOverlay)}
              role="button"
              tabIndex={0}
              ref={i === 0 ? focusedPreview : undefined}
              onClick={() => onSelectSuggestion(el)}
              onKeyDown={e => {
                if (e.key === 'Enter' || e.key === ' ') {
                  onSelectSuggestion(el);
                }
              }}
              style={doc.theme.styles}
              >
              <ErrorBoundary key={type + '-err'}>
                <div className={styles.chart}>
                  <Obj
                    key={type + '-grid'}
                    parentKey={el.id}
                    model={doc.model}
                    chartTheme={doc.chartTheme}
                    theme={doc.theme}
                    {...el.data}
                    {...globalProps}
                    // do not show the legend for the charts to save space. Only include it for tables
                    legend={type === 'table' ? el.data.legend : undefined}
                    />
                </div>
              </ErrorBoundary>
              <strong className={styles.title}>{suggestedElementLabel}</strong>
            </div>
          );
        })}

        <div className={styles.subText}>Not sure which to choose? You can always edit chart type later.</div>
      </section>
    </Modal>
  );
};
