import React from 'react';
import csx from 'classnames';
import PropTypes from 'prop-types';

import DataError from '../DataError';
import imageMetaCache from '../imageMetaCache';
import modelProp from '../modelProp';
import { elementVisibility, framing, imageShape, imageWidth, linkUrl, optBorderColor, sourceUrl, targetCell, value } from '../propsData';
import { ThemesContext } from '../ThemesContext';
import { parseHref } from '../utils/urlState';
import Wrapper from '../Wrapper';

import styles from './image.module.scss';

const elementOptions = {
  [sourceUrl.name]: sourceUrl,
  [elementVisibility.name]: elementVisibility,
  [imageWidth.name]: imageWidth,
  [framing.name]: framing,
  [imageShape.name]: imageShape,
  [optBorderColor.name]: optBorderColor,
  [linkUrl.name]: linkUrl,
  [targetCell.name]: targetCell,
  [value.name]: value,
};

export const sizes = {
  'xx-small': 20,
  'x-small': 64,
  'small': 99,
  'medium': 140,
  'large': 214,
  'x-large': 330,
  'full': Infinity,
};

export default class GridImage extends React.Component {
  static propTypes = {
    error: PropTypes.string,
    model: modelProp.isRequired,
    isEditor: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    element: PropTypes.object,
    track: PropTypes.func,
  };

  static options = elementOptions;
  static requiredOption = sourceUrl.name;
  static contextType = ThemesContext;

  constructor (props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromProps (props, prevState) {
    let stateError = prevState.error ?? null;
    let stateLoading = prevState.loading ?? true;

    const imgSrc = sourceUrl.read(props);
    if (imgSrc && imgSrc !== prevState.src) {
      stateError = null;
      stateLoading = true;
    }

    const state = {
      src: imgSrc,
      lastSrc: prevState.src,
      visible: elementVisibility.read(props),
      width: imageWidth.read(props),
      framing: framing.read(props),
      shape: imageShape.read(props),
      modelId: props.model?.lastWrite,
      borderColor: optBorderColor.read(props),
      href: linkUrl.read(props),
      error: stateError,
      loading: stateLoading,
    };
    for (const key of Object.keys(state)) {
      if (state[key] !== prevState[key]) {
        return state;
      }
    }
    return null;
  }

  onLoad = () => {
    const img = this.imageRef?.current;
    if (img?.naturalWidth) {
      imageMetaCache.setMeta(this.state.src, img);
    }
    this.setState({ loading: false, error: false });
  };

  onError = () => {
    this.setState({ error: true, loading: false });
  };

  onClick = () => {
    this.props.track('interact', { elementType: 'image' });
    targetCell.write(this.props, value.readCells(this.props));
  };

  imageRef = React.createRef();

  render () {
    const props = this.props;
    if (!props.isEditor && !elementVisibility.read(props)) {
      return null;
    }

    const { theme } = /** @type {React.ContextType<typeof ThemesContext>} */(this.context);
    const { src, href, borderColor, width, framing, shape, error, loading, lastSrc } = this.state;
    if (error) {
      return (
        <Wrapper {...props} className={styles.wd_full}>
          <DataError
            title="Failed to fetch"
            message="You may need to replace the file"
            {...props}
            />
        </Wrapper>
      );
    }
    if (!src) {
      return (
        <Wrapper {...props} className={styles.wd_full}>
          <DataError
            title="No source found"
            message="Please select an image URL"
            {...props}
            />
        </Wrapper>
      );
    }

    const imgStyle = {};
    const wrapperStyle = {};
    let dims = imageMetaCache.getMeta(src);
    if (loading && lastSrc) {
      // if we're loading and we have an earlier image
      // then we're switching in the process of switching
      // from one to the next and we want a stable size
      dims = imageMetaCache.getMeta(lastSrc);
    }
    const aspect = dims ? dims[1] / dims[0] : 1;
    const isPortrait = aspect >= 1;
    let isFullWidth = false;
    let isNativeWidth = false;

    let borderSpace = 0;
    if (borderColor) {
      borderSpace = 4;
      if (shape === 'circle' || shape === 'ellipse') {
        wrapperStyle.background = theme.resolveColor(borderColor);
      }
      else {
        imgStyle.border = '2px solid ' + theme.resolveColor(borderColor);
      }
    }

    if (!width) {
      // native mode:
      isNativeWidth = true;
      if (dims) {
        imgStyle.width = dims[0] - borderSpace;
        if (shape === 'circle') {
          // When clipped to a circle, reduce an oblong to a square so it uses the minimum space.
          const minDim = Math.min(...dims) - borderSpace;
          imgStyle.width = `${minDim}px`;
          imgStyle.height = imgStyle.width;
        }
      }
    }
    else {
      // scaled mode:
      const rawSize = sizes[width] ?? 20;
      const size = rawSize - borderSpace;
      if (isFinite(size)) {
        let w = 20;
        if (framing === 'cover') {
          w = isPortrait ? size : size / aspect;
        }
        else { // framing === 'contain'
          w = isPortrait ? size / aspect : size;
        }
        imgStyle.width = w;

        if (shape === 'circle') {
          imgStyle.height = w;
        }
      }
      else {
        isFullWidth = true;
      }
    }

    const haveTarget = targetCell.isFx(props);

    const cls = csx(
      styles.image,
      styles.inline,
      borderColor && styles.withBorder,
      isFullWidth && styles.fullWidth,
      isNativeWidth && styles.native,
      haveTarget && styles.clickable,
      shape === 'rrect' && styles.roundedRect,
      shape === 'circle' && styles.circle,
      shape === 'ellipse' && styles.ellipse,
    );

    const useLinkTag = href && !props.isEditor;
    const ImgWrap = useLinkTag && !haveTarget ? 'a' : 'span';
    const linkProps = useLinkTag ? parseHref(href, props.isAuthenticated).props : {};
    if (useLinkTag) {
      // Note, this is sent as if there is a link element around the image which you are clicking
      linkProps.onClick = () => props.track('interact', { elementType: 'link', targetUrl: href });
    }

    return (
      <Wrapper {...props} className={cls}>
        <ImgWrap className={styles.imgWrap} {...linkProps} style={wrapperStyle}>
          <img
            style={imgStyle}
            ref={this.imageRef}
            src={src}
            onClick={haveTarget ? this.onClick : undefined}
            onLoad={this.onLoad}
            onError={this.onError}
            />
          {(href && !useLinkTag) ? (
            <a className={styles.linkIndicator} {...linkProps}>
              <svg width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="foo" d="M6,5.4L11,5.4M9.1,2.95L11,2.95Q14,2.95 14,5.5T11,8.05L9.1,8.05M7.9,2.95L6,2.95Q3,2.95 3,5.5T6,8.05L7.9,8.05" />
                <use href="#foo" fill="none" stroke="white" strokeWidth="4.5" strokeOpacity="0.6" strokeLinecap="square" />
                <use href="#foo" fill="none" stroke="black" strokeWidth="1.4" />
              </svg>
            </a>
          ) : null}
        </ImgWrap>
      </Wrapper>
    );
  }
}
