import { colFromOffs } from '@grid-is/apiary';

const defaultCellWidth = 10;

export class ColumnWidthGetter {
  /**
   * @param {import('@grid-is/apiary').WorkSheet} [sheet]
   */
  constructor (sheet) {
    this._sheet = sheet;
    this._defaultWidth = sheet?.defaults?.col_width ?? defaultCellWidth;
  }

  /**
   * @param {number} columnIndex
   * @returns {number}
   */
  get (columnIndex) {
    if (!this._sheet) {
      return this.getDefault();
    }
    let width = 0;
    if (this._sheet.columns) {
      // the newer column list, introduced in CSF 4.0
      width = this._getFromColumnList(columnIndex) ?? this._defaultWidth;
    }
    else if (this._sheet.col_widths) {
      // the older col_widths dictionary
      width = this._getFromColWidthDict(columnIndex) ?? this._defaultWidth;
    }
    return this._scale(width);
  }

  /**
   * @returns {number}
   */
  getDefault () {
    return this._scale(this._defaultWidth);
  }

  /**
   * @param {number} columnIndex
   * @returns {number | undefined}
   */
  _getFromColumnList (columnIndex) {
    const adjustedIndex = columnIndex + 1;
    const columnInfo = this._sheet?.columns?.find(col => col.begin <= adjustedIndex && adjustedIndex <= col.end);
    return columnInfo?.width;
  }

  /**
   * @param {number} columnIndex
   * @returns {number | undefined}
   */
  _getFromColWidthDict (columnIndex) {
    const columnLetter = colFromOffs(columnIndex);
    return this._sheet?.col_widths?.[columnLetter];
  }

  /**
   * @param {number} size
   * @returns {number}
   */
  _scale (size) {
    const scalingFactor = 6.5 * 1.17;
    if (size) {
      size *= scalingFactor;
    }
    return size;
  }
}
