import { a1ToRowColumn, Cell, Range, Reference, Sheet } from '@grid-is/apiary';
import { chain } from '@grid-is/iterators';
import { assert } from '@grid-is/validation';

import { cellsAreComparable, containsText, getCellsInRange, isBlank, notBlank, trimBlanksFromRange } from './cells';

type Orientation = 'vertical' | 'horizontal';

export class Structure {
  public data: Range;
  public label?: Range;

  constructor (data: Range, label?: Range) {
    this.data = data;
    this.label = label;
  }

  toString (): string {
    let result = `${this.constructor.name}: ${new Reference(this.data)}`;
    if (this.label) {
      result += ` Label: ${new Reference(this.label)}`;
    }
    return result;
  }
}

export class SingleValue extends Structure {
  constructor (data: Range, label?: Range) {
    assert(data.size === 1);
    super(data, label);
  }
}

export class List extends Structure {
  constructor (data: Range, label?: Range) {
    assert(data.size > 1);
    super(data, label);
  }
}

export class Table extends Structure {
  public orientation: Orientation;
  public columnHeaders?: Range;
  public rowHeaders?: Range;

  constructor (data: Range, orientation: Orientation, label?: Range, columnHeaders?: Range, rowHeaders?: Range) {
    assert(data.size > 1);
    super(data, label);
    this.orientation = orientation;
    this.columnHeaders = columnHeaders;
    this.rowHeaders = rowHeaders;
  }

  toString (): string {
    let result = super.toString();
    result += ` Orientation: ${this.orientation}`;
    if (this.columnHeaders) {
      result += ` ColumnHeaders: ${new Reference(this.columnHeaders)}`;
    }
    if (this.rowHeaders) {
      result += ` RowHeaders: ${new Reference(this.rowHeaders)}`;
    }
    return result;
  }
}

export function inferStructure (sheet: Sheet, range: Range): SingleValue | List | Table {
  range = trimBlanksFromRange(range, sheet);

  // Single value
  if (range.size === 1) {
    let label: Range | undefined;
    const labelCell = scanForLabelCell(sheet, String(range));
    if (labelCell) {
      label = new Reference(labelCell.id).range!;
    }
    return new SingleValue(range, label);
  }

  // List (or a single value with a label)
  if (range.width === 1 || range.height === 1) {
    const topLeft = range.collapseToTopLeft();
    const firstCell = sheet.getCellByCoords(range.top, range.left);
    const rest = [ ...range.except(topLeft) ][0];
    const remainingCells = [ ...getCellsInRange(rest, sheet) ];

    // Check if first cell contains a label
    if (
      typeof firstCell?.v === 'string' && remainingCells.every(c => typeof c?.v !== 'string') ||
      firstCell?.s?.bold && remainingCells.every(c => !c?.s?.bold)
    ) {
      if (rest.size === 1) {
        return new SingleValue(rest, topLeft);
      }
      return new List(rest, topLeft);
    }

    // No label found
    return new List(range);
  }

  // Table
  let label: Range | undefined;
  let data = range;

  // Search for a label - a single value in either the first row or column
  const labelResult = extractLabel(range, sheet, 'row') || extractLabel(range, sheet, 'column');
  if (labelResult) {
    label = labelResult.label;
    data = labelResult.remainder;
    if (data.size === 1) {
      return new SingleValue(data, label);
    }
    if (data.width === 1 || data.height === 1) {
      return new List(data, label);
    }
  }

  // TODO:
  // Deal with blank rows/columns....somehow
  // Skip over hierarchical headers...and then relax the uniqueness criterion?

  let columnHeaders: Range | undefined;
  let rowHeaders: Range | undefined;
  /*
  Columns and row headers. There are four scenarios:
  1. Column and row headers
  2. Column headers only
  3. Row headers only
  4. No headers
  */
  if (headersInFirstRowAndColumn(sheet, data)) {
    rowHeaders = new Range(Object.assign({}, data.collapseToColumn(0), { top: data.top + 1 }));
    columnHeaders = new Range(Object.assign({}, data.collapseToRow(0), { left: data.left + 1 }));
    data = new Range(Object.assign({}, data, { left: data.left + 1, top: data.top + 1 }));
  }
  if (!columnHeaders && headersInRange(sheet, data, 'row')) {
    columnHeaders = data.collapseToRow(0);
    data = new Range(Object.assign({}, data, { top: data.top + 1 }));
  }
  if (!columnHeaders && !rowHeaders && headersInRange(sheet, data, 'column')) {
    rowHeaders = data.collapseToColumn(0);
    data = new Range(Object.assign({}, data, { left: data.left + 1 }));
  }

  let orientation: Orientation = 'vertical';
  if (columnHeaders && !rowHeaders) {
    orientation = 'vertical';
  }
  else if (rowHeaders && !columnHeaders) {
    orientation = 'horizontal';
  }
  else if (data.width > data.height) {
    // We could do something more clever hear, like check for uniformity along rows/columns in terms of data types, formatting, magnitude etc.
    orientation = 'horizontal';
  }

  return new Table(data, orientation, label, columnHeaders, rowHeaders);
}

function extractLabel (range: Range, sheet: Sheet, orientation: 'row' | 'column'): null | {label: Range, remainder: Range} {
  const firstRowOrColumn = orientation === 'row' ? range.collapseToRow() : range.collapseToColumn();
  const trimmed = trimBlanksFromRange(firstRowOrColumn, sheet);
  if (trimmed.size === 1) {
    const data = [ ...range.except(firstRowOrColumn) ][0];
    return {
      label: trimmed,
      remainder: trimBlanksFromRange(data, sheet),
    };
  }
  return null;
}

function headersInFirstRowAndColumn (sheet: Sheet, range: Range) {
  let foundHeaders = false;
  if (range.width >= 3 && range.height >= 3) {
    // The top-left corner cell must be empty because we're looking for something that looks like
    //    | H1 | H2
    // H1 | v1 | v2
    // H2 | v3 | v4
    const topLeftCell = sheet.getCellByCoords(range.top, range.left);
    if (isBlank(topLeftCell)) {
      const otherCellsAreNotBlank = (r: Range): boolean => chain(getCellsInRange(r, sheet))
        .skip(1)
        .every(notBlank);
      foundHeaders = otherCellsAreNotBlank(range.collapseToRow(0)) && otherCellsAreNotBlank(range.collapseToColumn(0));
    }
  }
  return foundHeaders;
}

function headersInRange (sheet: Sheet, range: Range, inspectBy: 'row' | 'column'): boolean {
  /*
     Header criteria
     - A header must be unique
     - The values that follow a header must all have the same data type and number format
     - One of:
        - At least one header must be different from the data that follows is, whether that be in data type or number formatting
        - All the headers are bold or all are italic and the next row/column is not
    */
  let foundHeaders = false;
  const byRow = inspectBy === 'row';

  // Pre-condition - there must be at least two data cells following a header cell
  if (byRow && range.height >= 3 || !byRow && range.width >= 3) {
    // First condition - headers must be unique
    const headerCells = [ ...getCellsInRange(byRow ? range.collapseToRow(0) : range.collapseToColumn(0), sheet) ];
    const uniques = new Set<string>();
    headerCells.filter(notBlank).forEach(c => uniques.add(c.v?.toString() || ''));
    if (headerCells.length === uniques.size) {
      // Second condition - data following a header must be uniform in data type and number format
      let dataIsUniform = true;
      // Third condition - At least one header differs from the data that follows in either data type or number format
      let anyHeaderDifferentFromData = false;
      for (let i = 0; dataIsUniform && i < headerCells.length; i++) {
        const collapsedRange = byRow ? range.collapseToColumn(i) : range.collapseToRow(i);
        const dataCells = [ ...getCellsInRange(collapsedRange, sheet) ].slice(1);
        dataIsUniform = chain(dataCells)
          .adjacentPairs()
          .every(([ a, b ]) => cellsAreComparable(a, b));
        if (!anyHeaderDifferentFromData) {
          anyHeaderDifferentFromData = !cellsAreComparable(headerCells[i], dataCells[0]);
        }
      }
      if (dataIsUniform && !anyHeaderDifferentFromData) {
        // Third condition (b) - headers are differently formatted (bold, underline) than next row/column
        const firstAllBold = headerCells.every(c => c.s?.bold);
        // underline is an optional string, one of: "single", "singleAccounting", "double", "doubleAccounting"
        // Check if all the cells in the row have the same explicit value
        const firstAllUnderlined = headerCells[0].s?.underline != null && headerCells.every(c => c.s?.underline === headerCells[0].s?.underline);
        if (firstAllBold || firstAllUnderlined) {
          const second = [ ...getCellsInRange(byRow ? range.collapseToRow(1) : range.collapseToColumn(1), sheet) ];
          const secondAllBold = second.every(c => c.s?.bold);
          const secondAllUnderlined = second[0].s?.underline != null && second.every(c => c.s?.underline === second[0].s?.underline);
          anyHeaderDifferentFromData = firstAllBold && !secondAllBold || firstAllUnderlined && !secondAllUnderlined;
        }
      }
      foundHeaders = dataIsUniform && anyHeaderDifferentFromData;
    }
  }
  return foundHeaders;
}

export function scanForLabelCell (sheet: Sheet, cellId: string): Cell | null {
  const [ row, col ] = a1ToRowColumn(cellId);
  const offsets = [ [ 0, -1 ], [ -1, 0 ],  [ 0, -2 ], [ -2, 0 ],  [ 0, -3 ], [ -3, 0 ] ];
  for (const [ rowOffset, colOffset ] of offsets) {
    const currentRow = row + rowOffset;
    const currentCol = col + colOffset;
    if (currentRow >= 0 && currentCol >= 0) {
      const cell = sheet.getCellByCoords(currentRow, currentCol);
      if (containsText(cell)) {
        return cell;
      }
      else if (cell?.M) {
        // The cell belongs to a range of merged cells.
        const mergedRef = new Reference(cell.M);
        // Check if the top-left cell of the merged range is in the same row or column as the cell we are trying to find the label for
        if (mergedRef.top === row || mergedRef.left === col) {
          // Yes, let's pick it if it contains text
          const topLeft = sheet.getCellByCoords(mergedRef.top, mergedRef.left);
          if (containsText(topLeft)) {
            return topLeft;
          }
        }
      }
    }
  }
  return null;
}
