import { CellVertexId, NameVertexId } from './DependencyGraph';
import { invariant, AssertionError } from './validation';
import Cell from './excel/Cell';
import { cellToVertexId } from './dep-graph-helpers';
import VertexIdSet from './VertexIdSet';
import Reference from './excel/Reference';
import type Workbook from './Workbook';
import type { ASTNode } from './excel/ast-types';

type ConvertibleToVertexIDs =
  | Cell
  | CellVertexId
  | NameVertexId
  | Iterable<Cell | CellVertexId | NameVertexId>
  | null
  | undefined;

/**
 * Converts various types to vertex IDs. Allows for a user friendly interface.
 */
function toVertexIdArray (value: ConvertibleToVertexIDs): (CellVertexId | NameVertexId)[] {
  if (!value) {
    return [];
  }
  if (!Array.isArray(value) && Symbol.iterator in value) {
    value = [ ...value ];
  }
  if (Array.isArray(value)) {
    return value.map(v => (v instanceof Cell ? cellToVertexId(v) : v));
  }
  if (value instanceof Cell) {
    return [ cellToVertexId(value) ];
  }
  invariant(value instanceof CellVertexId || value instanceof NameVertexId, 'wrong cell type given to toVertexIdArray');
  return [ value ];
}

const ERROR = 3;
const WARNING = 2;
const NOTICE = 1;
const NONE = 0;
const UNKNOWN = -1; // XXX does this actually happen? Can we make it not happen?

type Level = typeof ERROR | typeof WARNING | typeof NOTICE | typeof NONE;

class ModelError extends Error {
  static readonly ERROR = ERROR;
  static readonly WARNING = WARNING;
  static readonly NOTICE = NOTICE;
  static readonly NONE = NONE;
  static readonly UNKNOWN = UNKNOWN;

  workbook: Workbook | null = null;
  origException: (Error & { formula?: string, ast?: ASTNode }) | null = null;

  type: string;
  level: Level;
  vertexIds: VertexIdSet<CellVertexId | NameVertexId>;

  /**
   * @param [cells] indications of cell(s) or defined name(s) associated with the error
   * @param [type] error type identifier
   * @param [origException] original exception, included for troubleshooting errors whose reason is unknown
   */
  constructor (
    message: string,
    level: Level = WARNING,
    cells: ConvertibleToVertexIDs = null,
    type: string = 'unknown',
    origException: Error | null = null,
  ) {
    super(message);
    this.type = type;
    this.level = Math.min(3, Math.max(level, -1)) as Level;

    const vertexIds = toVertexIdArray(cells);
    this.vertexIds = new VertexIdSet(vertexIds);

    Object.defineProperty(this, 'origException', {
      enumerable: false,
      writable: false,
      value: origException,
    });
    Object.defineProperty(this, 'workbook', {
      enumerable: false,
      writable: true,
      value: null,
    });
  }

  /**
   * @returns set of cell IDs (e.g. `Sheet1!A1`), defined names, and column names for structured sheets
   */
  get references (): Set<string> {
    const set = new Set<string>();

    for (const vertex of this.vertexIds.values()) {
      if (vertex instanceof NameVertexId) {
        set.add(vertex.name);
      }
      else if (vertex instanceof CellVertexId) {
        let sheetName: string | undefined;
        let column;
        // XXX: This is really brittle. Whether a workbook is present depends on how/where the error is created.
        if (this.workbook) {
          // XXX: why store the workbook if we're just going to use it to get the model...and then get the workbook again?!?
          const model = this.workbook._model;
          const workbook = model.getWorkbookByKey(vertex.workbookKey);
          const sheet = typeof vertex.sheetIndex === 'number' ? workbook?.getSheetByIndex(vertex.sheetIndex) : null;
          sheetName = sheet?.name;
          column = sheet?.getColumns()[vertex.colIndex];
        }

        if (column) {
          set.add(column.name);
        }
        else {
          set.add(String(new Reference(vertex.toRange(), { sheetName })));
        }
      }
      else {
        throw new AssertionError('vertex ID should either be a cell or name vertex ID');
      }
    }

    return set;
  }

  /**
   * Create a circular dependency error involving the given cells.
   * @param vertexIds vertex IDs of cells (defined names or sheet cells) known to be involved in a dependency cycle
   */
  static fromCircularDependencyWith (vertexIds: ConvertibleToVertexIDs): ModelError {
    return new ModelError('Dependency Cycle Found', ModelError.NOTICE, vertexIds, 'circdep');
  }

  /**
   * Create an error representing something unsupported (a spreadsheet function or other functionality) in a formula
   * @param what the function name or a concise description of the functionality
   * @param neverSupport true if we intend never to support the thing, false if we think we maybe will
   * @param cell the sheet-prefixed cell address or defined name whose formula contains the unsupported thing
   * @param [isOrAre='is'] whichever verb form makes sense after `what`
   * @param [type=null] optional override of the `ModelError` type; the default type is `fn-unsup` if
   *   `neverSupport` is true, else `fn`
   */
  static fromUnsupported (
    what: string,
    neverSupport: boolean,
    cell: Cell | NameVertexId | CellVertexId,
    isOrAre: 'is' | 'are' = 'is',
    type: string | null = null,
  ) {
    if (type == null) {
      type = neverSupport ? 'fn-unsup' : 'fn';
    }
    return new ModelError(
      `${what} ${isOrAre} not ${neverSupport ? '' : 'yet '}supported`,
      neverSupport ? ModelError.NOTICE : ModelError.WARNING,
      cell,
      type,
    );
  }

  /**
   * Create an error about invalid cell values in workbook input in the given cells.
   * @param vertexIds vertex IDs of cells (defined names or sheet cells) in which invalid cell
   *   values were found and replaced with #VALUE!
   */
  static fromInvalidCellValueAt (vertexIds: ConvertibleToVertexIDs): ModelError {
    return new ModelError('Invalid cell value', ModelError.WARNING, vertexIds, 'invalid-cell-value');
  }

  valueOf () {
    return this.message;
  }

  toString () {
    return this.message;
  }

  /**
   * Produce a representation of this error for JSON serialization.
   * Adds the non-enumerable properties `message` and `origException` (the latter only if set),
   * and makes `cells` an array.
   * Despite the name, this does not return a JSON string, it returns an object to stringify instead of this one, see
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#tojson_behavior
   * @returns an object that will JSON.stringify more usefully than this instance.
   */
  toJSON () {
    const repr: {
      message: string,
      type: string,
      level: number,
      vertexIds: string[],
      origException?: {
        message: string,
        stack: string | undefined,
      },
    } = {
      message: this.message,
      type: this.type,
      level: this.level,
      vertexIds: Array.from(this.vertexIds.values()).map(v => v.key),
    };
    const origException = this.origException;
    if (origException) {
      repr.origException = {
        message: origException.message,
        stack: origException.stack,
      };
    }
    return repr;
  }
}

export default ModelError;
