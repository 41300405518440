import { ERROR_NUM, ERROR_DIV0, ERROR_VALUE, MISSING } from '../constants';
import { toNum, isErr, toNumList, isRef, isMatrix, fmtWithCellValues } from './utils';
import { toDateEngineering, toNumEngineering } from './analysis-toolpak';
import { round15, parseDateTime, toYMD, serialDate } from './utils-number';
import { toNumListStrict } from './utils-financial';
import { EPSILON, findRootBracket, findRootBrent } from './utils-math';
import { isBool, isStr } from '../../utils';
import type FormulaError from '../FormulaError.js';
import type Reference from '../Reference';
import type Matrix from '../Matrix';
import type { CellValue, CellValueAtom, FormulaArgument, NonMatrixFormulaArgument, ArrayValue } from '../types';
import { invariant } from '../../validation';
import { DAYS360, yearfrac } from './datetime';
import { collectNumbers, standardFilterMap } from './utils-visit';
import { isLambda } from '../lambda';

// ACCRINT(issue, first_payment, settlement, rate, redemption, frequency, basis)
// Calculates the accrued interest of a security that has periodic payments.
export function ACCRINT () {
  return null;
}

// ACCRINTM(issue, maturity, rate, [redemption], basis)
// Calculates the accrued interest of a security that pays interest at maturity.
export function ACCRINTM () {
  return null;
}

// AMORDEGRC(???)
// The depreciation of an asset in a single period (straight-line, implicit coefficient).
export function AMORDEGRC () {
  return null;
}

// AMORLINC(cost, purchase_date, first_period_end, salvage, period, rate, [basis])
// Returns the depreciation for an accounting period, or the prorated depreciation if the asset was purchased in the middle of a period.
export function AMORLINC () {
  return null;
}

function daysInMonth (year: number, monthZeroIndexed: number): number {
  const d = new Date(Date.UTC(year, monthZeroIndexed + 1, 0));
  return d.getUTCDate();
}

function setYear (date: Date, year: number) {
  const dom = date.getUTCDate();
  const domIsMax = dom === daysInMonth(date.getUTCFullYear(), date.getUTCMonth());
  date.setUTCFullYear(year);
  if (date.getUTCDate() < dom) {
    // JavaScript skipped ahead a month, so we step back by one.
    date.setUTCDate(0);
  }
  if (domIsMax) {
    const dim = daysInMonth(date.getUTCFullYear(), date.getUTCMonth());
    date.setUTCDate(dim);
  }
}

// Adds a given number of `months` to `date`, which is assumed to originate from
// some "original date", through a sequence of mutations. Which day of the month
// is chosen after the month addition depends on `origDay` (the day of month of
// the "original date") as well as `origDayMax` (whether the original day of
// month was the highest in its month).
function addMonths (date: Date, months: number, origDay: number, origDayMax: boolean) {
  const dom = date.getUTCDate();
  const domIsMax = dom === daysInMonth(date.getUTCFullYear(), date.getUTCMonth());
  date.setUTCMonth(date.getUTCMonth() + months);
  if (date.getUTCDate() < dom) {
    // JavaScript added too many months, and we step back by one. For example:
    /*
      > const d = new Date("2023-01-30T12:00Z")
      > d.setUTCMonth(1) // February
      > d
      2023-03-02T12:00:00.000Z
      > d.setUTCDate(0) // Fix it by stepping back 1 month
      > d
      2023-02-28T12:00:00.000Z
     */
    date.setUTCDate(0);
  }
  if (domIsMax) {
    const dim = daysInMonth(date.getUTCFullYear(), date.getUTCMonth());
    date.setUTCDate(origDayMax ? dim : Math.min(origDay, dim));
  }
}

function dateToExcel (jsDate: Date): number {
  if (jsDate.getUTCFullYear() < 1900) {
    return 0;
  }
  const excelDate = serialDate(jsDate.getUTCFullYear(), jsDate.getUTCMonth() + 1, jsDate.getUTCDate());
  invariant(!isErr(excelDate));
  return excelDate;
}

// Calculates both COUPPCD ("previous coupon date" before the settlement date),
// and COUPNCD ("next coupon date" after the settlement date). Returns JavaScript
// date objects.
function getPcdNcdJs (settlement: number, maturity: number, frequency: number): [Date, Date] {
  const [ mY, mM, mD ] = toYMD(maturity);
  const [ sY, sM, sD ] = toYMD(settlement);
  const resultDate = new Date(Date.UTC(mY, mM - 1, mD, 12));
  const settlementDate = new Date(Date.UTC(sY, sM - 1, sD, 12));
  const origDay = resultDate.getUTCDate();
  const origDayMax = origDay === daysInMonth(resultDate.getUTCFullYear(), resultDate.getUTCMonth());
  setYear(resultDate, sY);

  if (resultDate < settlementDate) {
    addMonths(resultDate, 12, origDay, origDayMax);
  }
  /* eslint-disable-next-line no-unmodified-loop-condition */
  while (resultDate > settlementDate) {
    addMonths(resultDate, -12 / frequency, origDay, origDayMax);
  }
  // Copy the current value of `resultDate`, which will serve as `pcd`. It will
  // be further mutated, turning it into `ncd`.
  const pcdJs = new Date(resultDate.valueOf());
  addMonths(resultDate, 12 / frequency, origDay, origDayMax);
  const ncdJs = resultDate;
  return [ pcdJs, ncdJs ];
}

// Same as `getPcdNcdJs`, but returns Excel dates.
function getPcdNcd (settlement: number, maturity: number, frequency: number): [number, number] {
  const [ pcdJs, ncdJs ] = getPcdNcdJs(settlement, maturity, frequency);
  const pcd = dateToExcel(pcdJs);
  const ncd = dateToExcel(ncdJs);
  return [ pcd, ncd ];
}

function validateBasis (basisArg: Reference | FormulaError | undefined | CellValueAtom) {
  let basis = basisArg === MISSING ? 0 : toNumEngineering(basisArg);
  if (isErr(basis)) {
    return basis;
  }
  basis = Math.floor(basis);
  if (basis < 0 || basis > 4) {
    return ERROR_NUM;
  }
  return basis;
}

function validateFrequency (frequencyArg: Reference | FormulaError | undefined | CellValueAtom) {
  let frequency = toNumEngineering(frequencyArg);
  if (isErr(frequency)) {
    return frequency;
  }
  frequency = Math.floor(frequency);
  if (![ 1, 2, 4 ].includes(frequency)) {
    return ERROR_NUM;
  }
  return frequency;
}

// Shared input validation for `COUP*` set of functions.
function coupValidation (
  settlementArg: Reference | FormulaError | undefined | CellValueAtom,
  maturityArg: Reference | FormulaError | undefined | CellValueAtom,
  frequencyArg: Reference | FormulaError | undefined | CellValueAtom,
  basisArg: Reference | FormulaError | undefined | CellValueAtom,
): FormulaError | [number, number, number, number] {
  const settlement = toDateEngineering(settlementArg);
  if (isErr(settlement)) {
    return settlement;
  }
  const maturity = toDateEngineering(maturityArg);
  if (isErr(maturity)) {
    return maturity;
  }
  const frequency = validateFrequency(frequencyArg);
  if (isErr(frequency)) {
    return frequency;
  }
  const basis = validateBasis(basisArg);
  if (isErr(basis)) {
    return basis;
  }
  if (settlement >= maturity) {
    return ERROR_NUM;
  }
  return [ settlement, maturity, frequency, basis ];
}

function coupdays (settlement: number, maturity: number, frequency: number, basis: number): number {
  if (basis === 0 || basis === 2 || basis === 4) {
    // Basis in [0, 2, 4]: US (NASD) 30/360, Actual/360, European 30/360
    return 360 / frequency;
  }
  else if (basis === 3) {
    // Actual/365
    return 365 / frequency;
  }
  else {
    // Basis === 1: Actual / Actual
    const [ pcdJs, ncdJs ] = getPcdNcdJs(settlement, maturity, frequency);
    // We want to handle PCD values before 1st January 1900, which is why we
    // work with JS objects here rather than Excel dates.
    return Math.round((+ncdJs - +pcdJs) / (24 * 60 * 60 * 1000));
  }
}

// COUPDAYS(settlement, maturity, frequency, basis)
// Calculates the number of days in the coupon, or interest payment, period
// that contains the specified settlement date.
export function COUPDAYS (
  settlementArg: Reference | FormulaError | undefined | CellValueAtom,
  maturityArg: Reference | FormulaError | undefined | CellValueAtom,
  frequencyArg: Reference | FormulaError | undefined | CellValueAtom,
  basisArg: Reference | FormulaError | undefined | CellValueAtom,
) {
  const values = coupValidation(settlementArg, maturityArg, frequencyArg, basisArg);
  if (isErr(values)) {
    return values;
  }
  const [ settlement, maturity, frequency, basis ] = values;
  return coupdays(settlement, maturity, frequency, basis);
}

// Calculate the number of days between two Excel dates, according to the given basis.
function coupDateDiff (fromDate: number, toDate: number, basis: number): number {
  if ((basis & 3) !== 0) {
    // Basis in [1, 2, 3]
    return toDate - fromDate;
  }
  // Basis in [0, 4]
  const result = DAYS360(fromDate, toDate, basis === 4);
  invariant(!isErr(result));
  return result;
}

function coupdayBsSnc (settlement: number, maturity: number, frequency: number, basis: number, isPrevious: boolean) {
  const [ pcd, ncd ] = getPcdNcd(settlement, maturity, frequency);
  const cd = isPrevious ? pcd : ncd;
  return Math.abs(coupDateDiff(cd, settlement, basis));
}

function coupdayBsSncValidating (
  settlementArg: Reference | FormulaError | undefined | CellValueAtom,
  maturityArg: Reference | FormulaError | undefined | CellValueAtom,
  frequencyArg: Reference | FormulaError | undefined | CellValueAtom,
  basisArg: Reference | FormulaError | undefined | CellValueAtom,
  isPrevious: boolean,
) {
  const values = coupValidation(settlementArg, maturityArg, frequencyArg, basisArg);
  if (isErr(values)) {
    return values;
  }
  const [ settlement, maturity, frequency, basis ] = values;
  return coupdayBsSnc(settlement, maturity, frequency, basis, isPrevious);
}

// COUPDAYBS(settlement, maturity, frequency, basis)
// Calculates the number of days from the first coupon, or interest payment, until settlement.
export function COUPDAYBS (
  settlementArg: Reference | FormulaError | undefined | CellValueAtom,
  maturityArg: Reference | FormulaError | undefined | CellValueAtom,
  frequencyArg: Reference | FormulaError | undefined | CellValueAtom,
  basisArg: Reference | FormulaError | undefined | CellValueAtom,
) {
  return coupdayBsSncValidating(settlementArg, maturityArg, frequencyArg, basisArg, true);
}

// COUPDAYSNC(settlement, maturity, frequency, basis)
// Calculates the number of days from the settlement date until the next coupon, or interest payment.
export function COUPDAYSNC (
  settlementArg: Reference | FormulaError | undefined | CellValueAtom,
  maturityArg: Reference | FormulaError | undefined | CellValueAtom,
  frequencyArg: Reference | FormulaError | undefined | CellValueAtom,
  basisArg: Reference | FormulaError | undefined | CellValueAtom,
) {
  return coupdayBsSncValidating(settlementArg, maturityArg, frequencyArg, basisArg, false);
}

// COUPNCD(settlement, maturity, frequency, basis)
// Calculates next coupon, or interest payment, date after the settlement date.
export function COUPNCD (
  settlementArg: Reference | FormulaError | undefined | CellValueAtom,
  maturityArg: Reference | FormulaError | undefined | CellValueAtom,
  frequencyArg: Reference | FormulaError | undefined | CellValueAtom,
  basisArg: Reference | FormulaError | undefined | CellValueAtom,
) {
  const values = coupValidation(settlementArg, maturityArg, frequencyArg, basisArg);
  if (isErr(values)) {
    return values;
  }
  // We throw away the basis because it is unused
  const [ settlement, maturity, frequency ] = values;
  return getPcdNcd(settlement, maturity, frequency)[1];
}

// COUPPCD(settlement, maturity, frequency, basis)
// Calculates last coupon, or interest payment, date before the settlement date.
export function COUPPCD (
  settlementArg: Reference | FormulaError | undefined | CellValueAtom,
  maturityArg: Reference | FormulaError | undefined | CellValueAtom,
  frequencyArg: Reference | FormulaError | undefined | CellValueAtom,
  basisArg: Reference | FormulaError | undefined | CellValueAtom,
) {
  const values = coupValidation(settlementArg, maturityArg, frequencyArg, basisArg);
  if (isErr(values)) {
    return values;
  }
  // We throw away the basis because it is unused
  const [ settlement, maturity, frequency ] = values;
  return getPcdNcd(settlement, maturity, frequency)[0];
}

function coupNum (settlement: number, maturity: number, frequency: number) {
  const pcd = getPcdNcd(settlement, maturity, frequency)[0];
  if (pcd === 0) {
    return ERROR_NUM;
  }
  const [ pY, pM ] = toYMD(pcd);
  const [ mY, mM ] = toYMD(maturity);
  const numMonths = (mY - pY) * 12 + mM - pM;
  return (numMonths * frequency) / 12;
}

// COUPNUM(settlement, maturity, frequency, basis)
// Calculates the number of coupons, or interest payments, between the settlement date and the maturity date of the investment.
export function COUPNUM (
  settlementArg: Reference | FormulaError | undefined | CellValueAtom,
  maturityArg: Reference | FormulaError | undefined | CellValueAtom,
  frequencyArg: Reference | FormulaError | undefined | CellValueAtom,
  basisArg: Reference | FormulaError | undefined | CellValueAtom,
) {
  const values = coupValidation(settlementArg, maturityArg, frequencyArg, basisArg);
  if (isErr(values)) {
    return values;
  }
  // We throw away the basis because it is unused
  const [ settlement, maturity, frequency ] = values;
  return coupNum(settlement, maturity, frequency);
}

/**
 * This is a more accurate version of IPMT which CUMIPMT needs to pass the test cases.
 */
function calcIpmt (
  rate: number,
  period: number,
  number_of_periods: number,
  present_value: number,
  future_value: number,
  end_or_beginning: number,
): number {
  if (rate === 0) {
    return 0;
  }
  const expansion = rate + 1;
  const expansion_left = expansion ** number_of_periods - expansion ** (period - 1);
  let result = (present_value + future_value) * expansion_left;
  result /= expansion ** number_of_periods - 1;
  result = -rate * (result - future_value);
  if (end_or_beginning === 0) {
    return result;
  }
  if (period === 1) {
    return 0;
  }
  else {
    return result / expansion;
  }
}

/**
 * CUMIPMT(rate, number_of_periods, present_value, start_period, end_period, end_or_beginning)
 * Calculates the cumulative interest over a range of payment periods for an investment based on constant-amount
 * periodic payments and a constant interest rate.
 */
export function CUMIPMT (
  rate: NonMatrixFormulaArgument,
  number_of_periods: NonMatrixFormulaArgument,
  present_value: NonMatrixFormulaArgument,
  start_period: NonMatrixFormulaArgument,
  end_period: NonMatrixFormulaArgument,
  end_or_beginning: NonMatrixFormulaArgument,
): number | FormulaError {
  return computeConstantAmountPayments(
    cumulativeInterest,
    rate,
    number_of_periods,
    present_value,
    start_period,
    end_period,
    end_or_beginning,
  );
}

/**
 * CUMPRINC(rate, number_of_periods, present_value, start_period, end_period, end_or_beginning)
 * Calculates the cumulative principal paid over a range of payment periods for an investment based on constant-amount
 * periodic payments and a constant interest rate.
 */
export function CUMPRINC (
  rate: NonMatrixFormulaArgument,
  number_of_periods: NonMatrixFormulaArgument,
  present_value: NonMatrixFormulaArgument,
  start_period: NonMatrixFormulaArgument,
  end_period: NonMatrixFormulaArgument,
  end_or_beginning: NonMatrixFormulaArgument,
): number | FormulaError {
  return computeConstantAmountPayments(
    cumulativePrincipal,
    rate,
    number_of_periods,
    present_value,
    start_period,
    end_period,
    end_or_beginning,
  );
}

function cumulativeInterest (
  rate: number,
  number_of_periods: number,
  present_value: number,
  start_period: number,
  end_period: number,
  end_or_beginning: number,
) {
  let interest = 0;
  for (let period = start_period; period <= end_period; period += 1) {
    const ipmt = calcIpmt(rate, period, number_of_periods, present_value, 0, end_or_beginning);
    interest += ipmt;
  }
  return interest;
}

function cumulativePrincipal (
  rate: number,
  number_of_periods: number,
  present_value: number,
  start_period: number,
  end_period: number,
  end_or_beginning: number,
) {
  const pmt = PMT(rate, number_of_periods, present_value, 0.0, end_or_beginning);
  if (isErr(pmt)) {
    return pmt;
  }
  let result = 0.0;

  if (start_period === 1) {
    if (!end_or_beginning) {
      result = pmt + present_value * rate;
    }
    else {
      result = pmt;
    }
    start_period += 1;
  }

  for (let period = start_period; period <= end_period; period += 1) {
    if (end_or_beginning) {
      result += pmt - rate * (FV(rate, period - 2, pmt, present_value, 1) - pmt);
    }
    else {
      result += pmt - rate * FV(rate, period - 1, pmt, present_value, 0);
    }
  }
  return result;
}

function computeConstantAmountPayments (
  fn: (
    rate: number,
    number_of_periods: number,
    present_value: number,
    start_period: number,
    end_period: number,
    end_or_beginning: number
  ) => number | FormulaError,
  rate: NonMatrixFormulaArgument,
  number_of_periods: NonMatrixFormulaArgument,
  present_value: NonMatrixFormulaArgument,
  start_period: NonMatrixFormulaArgument,
  end_period: NonMatrixFormulaArgument,
  end_or_beginning: NonMatrixFormulaArgument,
) {
  rate = toNum(rate);
  if (isErr(rate)) {
    return rate;
  }
  if (rate <= 0) {
    return ERROR_NUM;
  }

  number_of_periods = toNum(number_of_periods);
  if (isErr(number_of_periods)) {
    return number_of_periods;
  }
  if (number_of_periods < 1) {
    return ERROR_NUM;
  }

  present_value = toNum(present_value);
  if (isErr(present_value)) {
    return present_value;
  }

  start_period = toNum(start_period);
  if (isErr(start_period)) {
    return start_period;
  }
  if (start_period < 1) {
    return ERROR_NUM;
  }

  end_period = toNum(end_period);
  if (isErr(end_period)) {
    return end_period;
  }
  if (end_period < 1) {
    return ERROR_NUM;
  }

  end_or_beginning = toNum(end_or_beginning);
  if (isErr(end_or_beginning)) {
    return end_or_beginning;
  }
  if (end_or_beginning !== 0) {
    end_or_beginning = 1;
  }

  if (start_period > end_period) {
    return ERROR_NUM;
  }
  if (number_of_periods < end_period) {
    return ERROR_NUM;
  }

  return fn(rate, number_of_periods, present_value, start_period, end_period, end_or_beginning);
}

// DB(cost, salvage, life, period, [month])
// Calculates the depreciation of an asset for a specified period using the arithmetic declining balance method.
export function DB (cost: number, salvage: number, life: number, period: number, month?: number) {
  if (cost < 0 || salvage < 0 || life <= 0 || period <= 0) {
    return ERROR_NUM;
  }
  if (month == null) {
    month = 12;
  }
  else {
    month = Math.floor(month);
  }
  if (month < 1 || month > 13) {
    return ERROR_NUM;
  }
  if (Math.floor(period) > Math.floor(life) + 1 - Math.floor(month / 12)) {
    return ERROR_NUM;
  }
  if (cost === 0) {
    return 0;
  }

  let rate = 1 - (salvage / cost) ** (1 / life);
  rate = Math.round(rate * 1000) / 1000;

  // Rate is calculated before flooring
  life = Math.floor(life);
  period = Math.floor(period);

  const firstYear = (cost * rate * month) / 12;
  if (period < 2) {
    return firstYear;
  }

  let db = firstYear;
  let sumDep = firstYear;
  for (let i = 2; i <= Math.min(life, period); i++) {
    db = (cost - sumDep) * rate;
    sumDep += db;
  }
  if (period > life) {
    db = ((cost - sumDep) * rate * (12 - month)) / 12;
  }
  return db;
}

// DDB(cost, salvage, life, period, [factor])
// Calculates the depreciation of an asset for a specified period using the double-declining balance method.
export function DDB (cost: number, salvage: number, life: number, period: number, factor?: number) {
  // Used algorithm from OpenDocument standard:
  // https://docs.oasis-open.org/office/v1.2/os/OpenDocument-v1.2-os-part2.html#DDB
  if (factor == null) {
    factor = 2;
  }
  if (cost < 0 || salvage < 0 || life <= 0 || period <= 0 || life < period || factor <= 0) {
    return ERROR_NUM;
  }
  let rate = factor / life;
  let oldValue;
  if (rate >= 1) {
    rate = 1;
    if (period === 1) {
      oldValue = cost;
    }
    else {
      oldValue = 0;
    }
  }
  else {
    oldValue = cost * (1 - rate) ** (period - 1);
  }
  const newValue = cost * (1 - rate) ** period;
  return Math.max(oldValue - Math.max(newValue, salvage), 0);
}

function validateDisc (
  settlementArg: Reference | FormulaError | undefined | CellValueAtom,
  maturityArg: Reference | FormulaError | undefined | CellValueAtom,
  priceOrDiscountArg: Reference | FormulaError | undefined | CellValueAtom,
  redemptionArg: Reference | FormulaError | undefined | CellValueAtom,
  basisArg: Reference | FormulaError | undefined | CellValueAtom,
) {
  const settlement = toDateEngineering(settlementArg);
  if (isErr(settlement)) {
    return settlement;
  }
  const maturity = toDateEngineering(maturityArg);
  if (isErr(maturity)) {
    return maturity;
  }
  const prOrDisc = toNumEngineering(priceOrDiscountArg);
  if (isErr(prOrDisc)) {
    return prOrDisc;
  }
  const redemption = toNumEngineering(redemptionArg);
  if (isErr(redemption)) {
    return redemption;
  }
  const basis = validateBasis(basisArg);
  if (isErr(basis)) {
    return basis;
  }
  if (settlement >= maturity || prOrDisc <= 0 || redemption <= 0) {
    return ERROR_NUM;
  }
  return [ settlement, maturity, prOrDisc, redemption, basis ];
}

// DISC(settlement, maturity, price, redemption, basis)
// Calculates the discount rate of a security based on price.
export function DISC (
  settlementArg: Reference | FormulaError | undefined | CellValueAtom,
  maturityArg: Reference | FormulaError | undefined | CellValueAtom,
  priceArg: Reference | FormulaError | undefined | CellValueAtom,
  redemptionArg: Reference | FormulaError | undefined | CellValueAtom,
  basisArg: Reference | FormulaError | undefined | CellValueAtom,
) {
  const values = validateDisc(settlementArg, maturityArg, priceArg, redemptionArg, basisArg);
  if (isErr(values)) {
    return values;
  }
  const [ settlement, maturity, pr, redemption, basis ] = values;
  const yf = yearfrac(settlement, maturity, basis);
  return (1 - pr / redemption) / yf;
}

// Argument validation logic common between `DOLLARDE` and `DOLLARFR`
function validateDollarDeFr (
  dollarArg: Reference | FormulaError | undefined | CellValueAtom,
  fractionArg: Reference | FormulaError | undefined | CellValueAtom,
): [number, number] | FormulaError {
  const dollar = toNumEngineering(dollarArg) ?? 0;
  if (isErr(dollar)) {
    return dollar;
  }
  let fraction = toNumEngineering(fractionArg) ?? 0;
  if (isErr(fraction)) {
    return fraction;
  }
  if (fraction <= 0) {
    return fraction === 0 ? ERROR_DIV0 : ERROR_NUM;
  }
  fraction = Math.floor(fraction);
  return [ dollar, fraction ];
}

// DOLLARDE(fractional_dollar, fraction)
// Converts a price quotation given as a decimal fraction into a decimal value.
export function DOLLARDE (
  fractionalDollarArg: Reference | FormulaError | undefined | CellValueAtom,
  fractionArg: Reference | FormulaError | undefined | CellValueAtom,
): number | FormulaError {
  const validatedArgs = validateDollarDeFr(fractionalDollarArg, fractionArg);
  if (isErr(validatedArgs)) {
    return validatedArgs;
  }
  const [ fd, frac ] = validatedArgs;
  const dollars = fd > 0 ? Math.floor(fd) : -Math.floor(-fd);
  let cents = fd % 1;
  cents /= frac;
  cents *= 10 ** Math.ceil(Math.log10(frac));
  return dollars + cents;
}

// DOLLARFR(decimal_dollar, fraction)
// Converts a price quotation given as a decimal value into a decimal fraction.
export function DOLLARFR (
  decimalDollarArg: Reference | FormulaError | undefined | CellValueAtom,
  fractionArg: Reference | FormulaError | undefined | CellValueAtom,
): number | FormulaError {
  const validatedArgs = validateDollarDeFr(decimalDollarArg, fractionArg);
  if (isErr(validatedArgs)) {
    return validatedArgs;
  }
  const [ dd, frac ] = validatedArgs;
  const dollars = dd > 0 ? Math.floor(dd) : -Math.floor(-dd);
  let cents = dd % 1;
  cents *= frac;
  cents *= 10 ** -Math.ceil(Math.log10(frac));
  return dollars + cents;
}

function duration (
  settlement: number,
  maturity: number,
  rate: number,
  yieldValue: number,
  frequency: number,
  basis: number,
) {
  // https://github.com/apache/openoffice/blob/abddca1fc6554c2522bf498da9a49c75c6c0efbb/main/scaddins/source/analysis/analysishelper.cxx#L963-L993
  const yf = yearfrac(settlement, maturity, basis);
  const n = coupNum(settlement, maturity, frequency);
  if (isErr(n)) {
    return n;
  }

  const couponPayment = (rate * 100) / frequency;
  const discountFactor = yieldValue / frequency + 1;

  const diff = yf * frequency - n;

  let dur = 0;
  for (let t = 1; t < n; t += 1) {
    dur += ((t + diff) * couponPayment) / discountFactor ** (t + diff);
  }
  dur += ((n + diff) * (couponPayment + 100)) / discountFactor ** (n + diff);

  let p = 0;
  for (let t = 1; t < n; t += 1) {
    p += couponPayment / discountFactor ** (t + diff);
  }
  p += (couponPayment + 100) / discountFactor ** (n + diff);

  return dur / (p * frequency);
}

function validateDuration (
  settlementArg: Reference | FormulaError | undefined | CellValueAtom,
  maturityArg: Reference | FormulaError | undefined | CellValueAtom,
  rateArg: Reference | FormulaError | undefined | CellValueAtom,
  yieldValueArg: Reference | FormulaError | undefined | CellValueAtom,
  frequencyArg: Reference | FormulaError | undefined | CellValueAtom,
  basisArg: Reference | FormulaError | undefined | CellValueAtom,
): [number, number, number, number, number, number] | FormulaError {
  const settlement = toDateEngineering(settlementArg);
  if (isErr(settlement)) {
    return settlement;
  }
  const maturity = toDateEngineering(maturityArg);
  if (isErr(maturity)) {
    return maturity;
  }
  const rate = toNumEngineering(rateArg);
  if (isErr(rate)) {
    return rate;
  }
  const yieldValue = toNumEngineering(yieldValueArg);
  if (isErr(yieldValue)) {
    return yieldValue;
  }
  const frequency = validateFrequency(frequencyArg);
  if (isErr(frequency)) {
    return frequency;
  }
  const basis = validateBasis(basisArg);
  if (isErr(basis)) {
    return basis;
  }
  if (settlement >= maturity || yieldValue < 0 || rate < 0) {
    return ERROR_NUM;
  }
  return [ settlement, maturity, rate, yieldValue, frequency, basis ];
}

// DURATION(settlement, maturity, rate, yield, frequency, basis) .
// Calculates the number of compounding periods required for an investment of a specified present value appreciating at a given rate to reach a target value.
export function DURATION (
  settlementArg: Reference | FormulaError | undefined | CellValueAtom,
  maturityArg: Reference | FormulaError | undefined | CellValueAtom,
  rateArg: Reference | FormulaError | undefined | CellValueAtom,
  yieldValueArg: Reference | FormulaError | undefined | CellValueAtom,
  frequencyArg: Reference | FormulaError | undefined | CellValueAtom,
  basisArg: Reference | FormulaError | undefined | CellValueAtom,
) {
  const validatedArgs = validateDuration(settlementArg, maturityArg, rateArg, yieldValueArg, frequencyArg, basisArg);
  if (isErr(validatedArgs)) {
    return validatedArgs;
  }
  return duration(...validatedArgs);
}

// EFFECT(nominal_rate, periods_per_year)
// Calculates the annual effective interest rate given the nominal rate and number of compounding periods per year.
export function EFFECT (
  nominalRateArg: Reference | FormulaError | undefined | CellValueAtom,
  numPeriodsArg: Reference | FormulaError | undefined | CellValueAtom,
) {
  const nominalRate = toNumEngineering(nominalRateArg) ?? 0;
  if (isErr(nominalRate)) {
    return nominalRate;
  }
  let numPeriods = toNumEngineering(numPeriodsArg) ?? 0;
  if (isErr(numPeriods)) {
    return numPeriods;
  }
  numPeriods = Math.floor(numPeriods);
  if (nominalRate <= 0 || numPeriods < 1) {
    return ERROR_NUM;
  }
  return (1 + nominalRate / numPeriods) ** numPeriods - 1;
}

/**
 * FV(rate, number_of_periods, payment_amount, [present_value], [end_or_beginning])
 * Calculates the future value of an annuity investment based on constant-amount periodic payments and a constant
 * interest rate.
 */
export function FV (
  rate: number,
  number_of_periods: number,
  payment_amount: number,
  present_value: number = 0,
  end_or_beginning: number = 0,
): number {
  if (rate === 0) {
    return -(present_value + payment_amount * number_of_periods);
  }

  const term = (1 + rate) ** number_of_periods;
  if (end_or_beginning === 0) {
    return -(present_value * term + (payment_amount * (term - 1)) / rate);
  }
  return -(present_value * term + (payment_amount * (term - 1) * (1 + rate)) / rate);
}

// FVSCHEDULE(principal, rate_schedule)
// Calculates the future value of some principal based on a specified series of potentially varying interest rates.
export function FVSCHEDULE (
  principalArg: Reference | FormulaError | undefined | CellValueAtom,
  rateScheduleArg: Reference | Matrix | FormulaError | undefined | CellValueAtom,
) {
  let principal = toNumEngineering(principalArg);
  if (isErr(principal)) {
    return principal;
  }
  let rawRates: (ArrayValue | undefined)[];
  if (isMatrix(rateScheduleArg) || isRef(rateScheduleArg)) {
    const mx = rateScheduleArg.toMatrix(false);
    if (isErr(mx)) {
      return mx;
    }
    rawRates = mx.resolveRange();
  }
  else {
    rawRates = [ rateScheduleArg ];
  }
  for (const rawRate of rawRates) {
    const rate = toNumEngineering(rawRate) ?? 0;
    if (isErr(rate)) {
      return rate;
    }
    principal *= 1 + rate;
  }
  return principal;
}

// INTRATE(settlement, maturity, investment, redemption, basis)
// Calculates the effective interest rate generated when an investment is
// purchased at one price and sold at another with no interest or dividends
// generated by the investment itself.
export function INTRATE (
  settlementArg: Reference | FormulaError | undefined | CellValueAtom,
  maturityArg: Reference | FormulaError | undefined | CellValueAtom,
  investmentArg: Reference | FormulaError | undefined | CellValueAtom,
  redemptionArg: Reference | FormulaError | undefined | CellValueAtom,
  basisArg: Reference | FormulaError | undefined | CellValueAtom,
) {
  const settlement = toDateEngineering(settlementArg);
  if (isErr(settlement)) {
    return settlement;
  }
  const maturity = toDateEngineering(maturityArg);
  if (isErr(maturity)) {
    return maturity;
  }
  const investment = toNumEngineering(investmentArg);
  if (isErr(investment)) {
    return investment;
  }
  const redemption = toNumEngineering(redemptionArg);
  if (isErr(redemption)) {
    return redemption;
  }
  const basis = validateBasis(basisArg);
  if (isErr(basis)) {
    return basis;
  }
  if (settlement >= maturity) {
    return ERROR_NUM;
  }
  if (investment <= 0 || redemption <= 0) {
    return ERROR_NUM;
  }
  const yf = yearfrac(settlement, maturity, basis);
  return (redemption - investment) / (investment * yf);
}

/**
 * IPMT(rate, period, number_of_periods, present_value, [future_value], [end_or_beginning])
 * Calculates the payment on interest for an investment based on constant-amount periodic payments and a constant
 * interest rate.
 */
export function IPMT (
  rate: number,
  period: number,
  number_of_periods: number,
  present_value: number,
  future_value: number | undefined,
  end_or_beginning: number | undefined,
): number | FormulaError {
  if (rate <= -1) {
    return ERROR_NUM;
  }

  if (period < 1) {
    return ERROR_NUM;
  }

  if (number_of_periods < 1) {
    return ERROR_NUM;
  }
  if (number_of_periods < period) {
    return ERROR_NUM;
  }

  if (future_value == null) {
    future_value = 0;
  }

  if (end_or_beginning == null) {
    end_or_beginning = 0;
  }
  else if (end_or_beginning !== 0) {
    end_or_beginning = 1;
  }

  const payment = PMT(rate, number_of_periods, present_value, future_value, end_or_beginning);
  if (isErr(payment)) {
    return payment;
  }

  let interest: number | FormulaError;
  if (period === 1) {
    if (end_or_beginning === 1) {
      interest = 0;
    }
    else {
      interest = -present_value;
    }
  }
  else if (end_or_beginning === 1) {
    interest = FV(rate, period - 2, payment, present_value, 1) - payment;
  }
  else {
    interest = FV(rate, period - 1, payment, present_value, 0);
  }
  if (isErr(interest)) {
    return interest;
  }

  return interest * rate;
}

/**
 * IRR(cashflow_amounts, [rate_guess])
 * Calculates the internal rate of return on an investment based on a series of periodic cash flows.
 */
export function IRR (cashflow_amounts: Matrix | Reference, rate_guess: number | null): number | FormulaError {
  const guess = rate_guess ?? 0.1;
  if (guess <= -1) {
    // Excel borks out on this, so we do too (even if we don't really need to)
    return ERROR_VALUE;
  }
  const amounts = toNumList([ cashflow_amounts ]);
  if (isErr(amounts)) {
    return ERROR_VALUE;
  }
  let anyPos = false;
  let anyNeg = false;
  for (const amount of amounts) {
    anyPos = anyPos || amount > 0;
    anyNeg = anyNeg || amount < 0;
    if (anyPos && anyNeg) {
      break;
    }
  }
  if (!anyPos || !anyNeg) {
    // Must have at least one negative and at least one positive value, else IRR is not defined.
    return ERROR_NUM;
  }
  const TOLERANCE = 1e-13;
  const MAX_STEPS = 30; // Excel uses 20, but in a presumably different algorithm, so we don't follow that exactly
  // Very high initial guess still does not rule out "normal" IRRs so make lower bound no higher than -0.5
  const LOWER_BOUND_MAXIMUM = -0.5;
  // IRR < -99.99999% is unlikely, and NPV can get a bit wild near -100%, so only go nearer if initial guess is very low
  const LOWER_BOUND_MARGIN = 0.0000001;
  const min = Math.min((1 - LOWER_BOUND_MARGIN) * -1 + LOWER_BOUND_MARGIN * guess, LOWER_BOUND_MAXIMUM);
  // Never put upper bound any lower than 100% (and only do that if initialGuess is pretty close to -1)
  const UPPER_BOUND_MINIMUM = 1;
  // Put upper bound at 1000 times (guess - min) from min; for default initialGuess this is 1999.99 (199,999%)
  // Really unlikely to want any IRR result higher than that, and if you do, it's reasonable to require you to
  // put in an initialGuess higher than 0.1.
  const UPPER_BOUND_MULTIPLIER = 1000;
  const max = Math.max(min + (guess - min) * UPPER_BOUND_MULTIPLIER, UPPER_BOUND_MINIMUM);
  const npvOfCashflows = (newGuess: number) => NPV(newGuess, ...amounts);
  const bracket = irrInitialBracket(npvOfCashflows, min, max, guess, TOLERANCE);
  if (isErr(bracket)) {
    return bracket;
  }
  return findRootBrent(npvOfCashflows, bracket, TOLERANCE, MAX_STEPS, guess) ?? ERROR_NUM;
}

/**
 * ISPMT(rate, period, number_of_periods, present_value)
 * Calculates the interest paid (or received) for the specified period of a loan (or investment) with even principal
 * payments.
 */
export function ISPMT (
  rate: number,
  period: number,
  number_of_periods: number,
  present_value: number,
): number | FormulaError {
  if (number_of_periods === 0) {
    return ERROR_DIV0;
  }

  return present_value * rate * (period / number_of_periods - 1);
}

// MDURATION(settlement, maturity, rate, yield, frequency, basis)
// Calculates the modified Macaulay duration of a security paying periodic
// interest, such as a US Treasury Bond, based on expected yield.
export function MDURATION (
  settlementArg: Reference | FormulaError | undefined | CellValueAtom,
  maturityArg: Reference | FormulaError | undefined | CellValueAtom,
  rateArg: Reference | FormulaError | undefined | CellValueAtom,
  yieldValueArg: Reference | FormulaError | undefined | CellValueAtom,
  frequencyArg: Reference | FormulaError | undefined | CellValueAtom,
  basisArg: Reference | FormulaError | undefined | CellValueAtom,
) {
  const validatedArgs = validateDuration(settlementArg, maturityArg, rateArg, yieldValueArg, frequencyArg, basisArg);
  if (isErr(validatedArgs)) {
    return validatedArgs;
  }
  const [ settlement, maturity, rate, yieldValue, frequency, basis ] = validatedArgs;
  const dur = duration(settlement, maturity, rate, yieldValue, frequency, basis);
  if (isErr(dur)) {
    return dur;
  }
  return dur / (1 + yieldValue / frequency);
}

// MIRR(values, finance_rate, reinvest_rate);
// Calculates the modified internal rate of return on an investment based on
// a series of periodic cash flows and the difference between the interest
// rate paid on financing versus the return received on reinvested income.
export function MIRR (valuesArg: Matrix | Reference, financeRate: number, reinvestRate: number): number | FormulaError {
  const values = collectNumbers(valuesArg, standardFilterMap);
  if (isErr(values)) {
    return values;
  }
  const n = values.length;
  const rr = 1 + reinvestRate;
  const fr = 1 + financeRate;
  let posNpv = 0;
  let negNpv = 0;
  for (let i = 0; i < n; i += 1) {
    const v = values[i];
    if (v >= 0) {
      posNpv += v / rr ** i;
    }
    else {
      negNpv += v / fr ** i;
    }
  }
  if (negNpv !== 0 && posNpv === 0 && n !== 1) {
    return -1;
  }
  if (posNpv === 0 || negNpv === 0) {
    return ERROR_DIV0;
  }
  const result = ((-posNpv * rr ** (n - 1)) / negNpv) ** (1 / (n - 1)) - 1;
  return isFinite(result) ? result : ERROR_NUM;
}

// NOMINAL(effective_rate, periods_per_year)
// Calculates the annual nominal interest rate given the effective rate and number of compounding periods per year.
export function NOMINAL (
  effectiveRateArg: Reference | FormulaError | undefined | CellValueAtom,
  numPeriodsArg: Reference | FormulaError | undefined | CellValueAtom,
) {
  const effectiveRate = toNumEngineering(effectiveRateArg) ?? 0;
  if (isErr(effectiveRate)) {
    return effectiveRate;
  }
  let numPeriods = toNumEngineering(numPeriodsArg) ?? 0;
  if (isErr(numPeriods)) {
    return numPeriods;
  }
  numPeriods = Math.floor(numPeriods);
  if (effectiveRate <= 0 || numPeriods < 1) {
    return ERROR_NUM;
  }
  return ((effectiveRate + 1) ** (1 / numPeriods) - 1) * numPeriods;
}

/**
 * NPER(rate, payment_amount, present_value, [future_value], [end_or_beginning])
 * Calculates the number of payment periods for an investment based on constant-amount periodic payments and a constant
 * interest rate.
 */
export function NPER (
  rate: number,
  pmt: number,
  pv: number,
  fv: number | undefined,
  eob: number | undefined,
): number | FormulaError {
  if (fv == null) {
    fv = 0;
  }
  eob = !eob ? 0 : 1;

  let result;
  if (rate === 0) {
    if (pmt === 0) {
      return ERROR_DIV0;
    }
    result = -(pv + fv) / pmt;
  }
  else {
    const condRate = 1 + rate * eob;
    const num = pmt * condRate - fv * rate;
    const denum = pv * rate + pmt * condRate;
    result = Math.log(num / denum) / Math.log(1 + rate);
  }
  if (!isFinite(result)) {
    return ERROR_NUM;
  }
  return result;
}

/**
 * NPV(discount, cashflow1, [cashflow2, ...])
 * Calculates the net present value of an investment based on a series of periodic cash flows and a discount rate.
 */
export function NPV (discount: number, ...args: (number | Reference | Matrix)[]): number | FormulaError {
  const cashflows = toNumList(args);
  if (isErr(cashflows)) {
    return cashflows;
  }

  let npv = 0;
  for (let i = 0; i < cashflows.length; i++) {
    const periodicRate = round15((1 + discount) ** (i + 1));
    if (!periodicRate) {
      return ERROR_DIV0;
    }
    else {
      npv += cashflows[i] / periodicRate;
    }
  }
  return npv;
}

// ODDFPRICE(???)
// The price per $100 face value of a security with an odd first period.
// export function ODDFPRICE () {
//   return null;
// }

// ODDFYIELD(???)
// The yield of a security with an odd first period.
// export function ODDFYIELD () {
//   return null;
// }

// ODDLPRICE(???)
// The price per $100 face value of a security with an odd last period.
// export function ODDLPRICE () {
//   return null;
// }

// ODDLYIELD(???)
// The yield of a security with an odd last period.
// export function ODDLYIELD () {
//   return null;
// }

// PDURATION(rate, pv, fv)
// The number of periods required by an investment to reach a specified value.
export function PDURATION (rate: number, pv: number, fv: number) {
  if (rate <= 0 || pv <= 0 || fv <= 0) {
    return ERROR_NUM;
  }
  return (Math.log(fv) - Math.log(pv)) / Math.log(1 + rate);
}

/**
 * PMT(rate, number_of_periods, present_value, [future_value], [end_or_beginning])
 * Calculates the periodic payment for an annuity investment based on constant-amount periodic payments and a constant
 * interest rate.
 */
export function PMT (
  rate: number,
  number_of_periods: number,
  present_value: number,
  future_value: number = 0,
  end_or_beginning: number = 0,
): number | FormulaError {
  if (rate <= -1) {
    return ERROR_NUM;
  }

  if (number_of_periods === 0) {
    return ERROR_NUM;
  }

  if (end_or_beginning !== 0) {
    end_or_beginning = 1;
  }

  let result;
  if (rate === 0) {
    result = (present_value + future_value) / number_of_periods;
  }
  else {
    const term = round15((1 + rate) ** number_of_periods);
    if (!term) {
      return ERROR_NUM;
    }
    result = (future_value * rate) / (term - 1) + (present_value * rate) / (1 - 1 / term);
    if (end_or_beginning !== 0) {
      result /= 1 + rate;
    }
  }
  return -result;
}

/**
 * PPMT(rate, period, number_of_periods, present_value, [future_value], [end_or_beginning])
 * Calculates the payment on the principal of an investment based on constant-amount periodic payments and a constant
 * interest rate.
 */
export function PPMT (
  rate: number,
  period: number,
  number_of_periods: number,
  present_value: number,
  future_value: number | undefined,
  end_or_beginning: number | undefined,
): number | FormulaError {
  if (rate <= -1) {
    return ERROR_NUM;
  }

  if (period < 1) {
    return ERROR_NUM;
  }

  if (number_of_periods === 0) {
    return ERROR_NUM;
  }
  if (number_of_periods < period) {
    return ERROR_NUM;
  }

  if (future_value == null) {
    future_value = 0;
  }

  if (end_or_beginning == null) {
    end_or_beginning = 0;
  }
  else if (end_or_beginning !== 0) {
    end_or_beginning = 1;
  }

  const pmt = PMT(rate, number_of_periods, present_value, future_value, end_or_beginning);
  if (isErr(pmt)) {
    return pmt;
  }
  const ipmt = IPMT(rate, period, number_of_periods, present_value, future_value, end_or_beginning);
  if (isErr(ipmt)) {
    return ipmt;
  }

  return pmt - ipmt;
}

function price (
  settlement: number,
  maturity: number,
  rate: number,
  yieldValue: number,
  redemption: number,
  frequency: number,
  basis: number,
) {
  const daysInPeriod = coupdays(settlement, maturity, frequency, basis);
  const daysNextCoupon = coupdayBsSnc(settlement, maturity, frequency, basis, false) / daysInPeriod;
  const n = coupNum(settlement, maturity, frequency);
  if (isErr(n)) {
    return n;
  }
  const daysLastCoupon = coupdayBsSnc(settlement, maturity, frequency, basis, true);

  const couponPayment = (100.0 * rate) / frequency;
  const discountFactor = 1.0 + yieldValue / frequency;

  let result = redemption / discountFactor ** (n - 1.0 + daysNextCoupon);
  result -= (couponPayment * daysLastCoupon) / daysInPeriod;
  for (let i = 0; i < n; i++) {
    result += couponPayment / discountFactor ** (i + daysNextCoupon);
  }
  return result;
}

// PRICE(settlement, maturity, rate, yield, redemption, frequency, basis)
// Calculates the price of a security paying periodic interest, such as a US Treasury Bond, based on expected yield.
export function PRICE (
  settlementArg: Reference | FormulaError | undefined | CellValueAtom,
  maturityArg: Reference | FormulaError | undefined | CellValueAtom,
  rateArg: Reference | FormulaError | undefined | CellValueAtom,
  yieldValueArg: Reference | FormulaError | undefined | CellValueAtom,
  redemptionArg: Reference | FormulaError | undefined | CellValueAtom,
  frequencyArg: Reference | FormulaError | undefined | CellValueAtom,
  basisArg: Reference | FormulaError | undefined | CellValueAtom,
) {
  const settlement = toDateEngineering(settlementArg);
  if (isErr(settlement)) {
    return settlement;
  }
  const maturity = toDateEngineering(maturityArg);
  if (isErr(maturity)) {
    return maturity;
  }
  const rate = toNumEngineering(rateArg);
  if (isErr(rate)) {
    return rate;
  }
  const yieldValue = toNumEngineering(yieldValueArg);
  if (isErr(yieldValue)) {
    return yieldValue;
  }
  const redemption = toNumEngineering(redemptionArg);
  if (isErr(redemption)) {
    return redemption;
  }
  const frequency = validateFrequency(frequencyArg);
  if (isErr(frequency)) {
    return frequency;
  }
  const basis = validateBasis(basisArg);
  if (isErr(basis)) {
    return basis;
  }
  if (settlement >= maturity || yieldValue < 0 || rate < 0 || redemption <= 0) {
    return ERROR_NUM;
  }
  return price(settlement, maturity, rate, yieldValue, redemption, frequency, basis);
}

// PRICEDISC(settlement, maturity, discount, redemption, basis)
// Calculates the price of a discount (non-interest-bearing) security, based on expected yield.
export function PRICEDISC (
  settlementArg: Reference | FormulaError | undefined | CellValueAtom,
  maturityArg: Reference | FormulaError | undefined | CellValueAtom,
  discountArg: Reference | FormulaError | undefined | CellValueAtom,
  redemptionArg: Reference | FormulaError | undefined | CellValueAtom,
  basisArg: Reference | FormulaError | undefined | CellValueAtom,
) {
  const values = validateDisc(settlementArg, maturityArg, discountArg, redemptionArg, basisArg);
  if (isErr(values)) {
    return values;
  }
  const [ settlement, maturity, discount, redemption, basis ] = values;
  const yf = yearfrac(settlement, maturity, basis);
  return redemption * (1 - discount * yf);
}

// PRICEMAT(settlement, maturity, issue, rate, yield, basis)
// Calculates the price of a security paying interest at maturity, based on expected yield.
export function PRICEMAT (
  settlementArg: Reference | FormulaError | undefined | CellValueAtom,
  maturityArg: Reference | FormulaError | undefined | CellValueAtom,
  issueArg: Reference | FormulaError | undefined | CellValueAtom,
  rateArg: Reference | FormulaError | undefined | CellValueAtom,
  yieldValueArg: Reference | FormulaError | undefined | CellValueAtom,
  basisArg: Reference | FormulaError | undefined | CellValueAtom,
) {
  const settlement = toDateEngineering(settlementArg);
  if (isErr(settlement)) {
    return settlement;
  }
  const maturity = toDateEngineering(maturityArg);
  if (isErr(maturity)) {
    return maturity;
  }
  const issue = toDateEngineering(issueArg);
  if (isErr(issue)) {
    return issue;
  }
  const rate = toNumEngineering(rateArg);
  if (isErr(rate)) {
    return rate;
  }
  const yieldValue = toNumEngineering(yieldValueArg);
  if (isErr(yieldValue)) {
    return yieldValue;
  }
  const basis = validateBasis(basisArg);
  if (isErr(basis)) {
    return basis;
  }
  if (settlement >= maturity || issue >= settlement || yieldValue < 0 || rate < 0) {
    return ERROR_NUM;
  }
  const imYf = yearfrac(issue, maturity, basis);
  const isYf = yearfrac(issue, settlement, basis);
  const smYf = yearfrac(settlement, maturity, basis);
  return 100 * ((1 + imYf * rate) / (1 + smYf * yieldValue) - isYf * rate);
}

/**
 * PV(rate, number_of_periods, payment_amount, [future_value], [end_or_beginning])
 * Calculates the present value of an annuity investment based on constant-amount periodic payments and a constant interest rate.
 */
export function PV (
  rate: number,
  number_of_periods: number,
  payment_amount: number,
  future_value: number,
  end_or_beginning: number,
): number | FormulaError {
  if (future_value == null) {
    future_value = 0;
  }

  if (end_or_beginning == null) {
    end_or_beginning = 0;
  }

  if (rate === 0) {
    return -(payment_amount * number_of_periods) - future_value;
  }

  const term = round15((1 + rate) ** number_of_periods);
  if (!term) {
    return ERROR_DIV0;
  }

  let result = ((1 - term) / rate) * payment_amount;
  if (end_or_beginning === 0) {
    result -= future_value;
  }
  else {
    result = result * (1 + rate) - future_value;
  }
  return result / term;
}

/** Approximate interest rate using David Cantrell's heuristic, as described in https://brownmath.com/bsci/loan.htm
 */
function rateHeuristicGuess (np: number, pmt: number, pv: number, fv: number) {
  // One over the natural logarithm of two (1 / log(2)) and the natural
  // logarithm of two, (log(2)), respectively.
  const one_over_log_two = 1.4426950408889634;
  const log_two = 0.6931471805599453;
  const q_nom = Math.log(1 + 1 / np);
  const q = q_nom * one_over_log_two;
  const one_over_q = log_two / q_nom;
  const heuristic_inner = 1 - pmt / (fv + pv);
  const heuristic_guess = (heuristic_inner ** one_over_q - 1) ** q - 1;
  if (Math.abs(heuristic_guess) === 1) {
    return NaN;
  }
  return heuristic_guess;
}

/**
 * RATE(number_of_periods, payment_per_period, present_value, [future_value], [end_or_beginning], [rate_guess])
 * Calculates the interest rate of an annuity investment based on constant-amount periodic payments and the assumption
 * of a constant interest rate.
 */
export function RATE (
  np: number,
  pmt: number,
  pv: number,
  fv: number,
  eob: number,
  guess: number,
): number | FormulaError {
  const fvNum = fv ?? 0;
  const eobNum = eob ?? 0;
  if (guess == null) {
    guess = 0.1;
  }
  if (guess <= -1) {
    return ERROR_VALUE;
  }

  const TOLERANCE = 0.0000001;
  const MAX_ITERATIONS = 128;
  /**
   * We have the formula:
   *   f'(rate) = fv + pmt*(eob*rate + 1)*((rate + 1) ** np - 1)/rate + pv*(rate + 1) ** np
   *
   * We want to find as value of `rate` where f(rate) = 0. Remember that `pmt`
   * is negative. We do this using Newton's method. We have the formula:
   *   rate_{n+1} = rate_n + f(x)/f'(rate_n)
   *
   * where f'(rate) is the derivitive of `f'` w.r.t rate:
   *   f''(rate) = eob*pmt*((rate + 1)**np - 1)/rate
   *              + np*pmt*(rate + 1)**np*(eob*rate + 1)/(rate*(rate + 1))
   *              + np*pv*(rate + 1)**np/(rate + 1)
   *              - pmt*(eob*rate + 1)*((rate + 1)**np - 1)/rate**2
   *
   * we repeat this calculation iteratively until
   *   n > MAX_ITERATIONS or |rate_n - rate_{n-1}| < TOLERANCE
   *
   * The initial guess is rate_0.
   *
   * This following function calculates f(rate)/f'(rate)
   */
  function nextDelta (rate: number): number | FormulaError {
    // The original formula is taken from PhpSpreadsheet (MIT). Sympy was used
    // to find the derivative and simplify for more numerical accuracy.
    if (rate === 0) {
      return ERROR_NUM;
    }
    const tt1 = (rate + 1) ** np;
    const tt2 = (rate + 1) ** (np - 1);
    // "Conditional rate". This expression is `1` if `eob` is zero, otherwise
    // it is `1 + rate`.
    const cond_rate = eobNum * rate + 1;
    const num = rate * (pmt * cond_rate * (tt1 - 1) + rate * (fvNum + pv * tt1));
    const denum =
      np * pv * rate * rate * tt2 +
      pmt * rate * (eobNum * (tt1 - 1) + np * tt2 * cond_rate) -
      pmt * cond_rate * (tt1 - 1);
    if (denum === 0) {
      return ERROR_NUM;
    }
    return num / denum;
  }

  function forGuess (rate: number): number | FormulaError {
    let good_enough = false;
    let iterations = 0;
    while (!good_enough && iterations < MAX_ITERATIONS) {
      const delta = nextDelta(rate);
      if (isErr(delta)) {
        return delta;
      }
      const new_rate = rate - delta;
      if (!isFinite(new_rate) || new_rate <= TOLERANCE - 1) {
        return ERROR_NUM;
      }
      good_enough = Math.abs(new_rate - rate) < TOLERANCE;
      iterations += 1;
      rate = new_rate;
    }
    return good_enough ? rate : ERROR_NUM;
  }

  const guesses = new Set([ guess ]);
  const heuristic_guess = rateHeuristicGuess(np, pmt, pv, fvNum);
  if (isFinite(heuristic_guess)) {
    guesses.add(heuristic_guess);
  }

  for (const g of guesses) {
    const guessResult = forGuess(g);
    if (!isErr(guessResult)) {
      return guessResult;
    }
  }
  return ERROR_NUM;
}

// RECEIVED(settlement, maturity, investment, discount, basis)
// Calculates the amount received at maturity for an investment in fixed-income securities purchased on a given date.
export function RECEIVED (
  settlementArg: Reference | FormulaError | undefined | CellValueAtom,
  maturityArg: Reference | FormulaError | undefined | CellValueAtom,
  investmentArg: Reference | FormulaError | undefined | CellValueAtom,
  discountArg: Reference | FormulaError | undefined | CellValueAtom,
  basisArg: Reference | FormulaError | undefined | CellValueAtom,
) {
  const settlement = toDateEngineering(settlementArg);
  if (isErr(settlement)) {
    return settlement;
  }
  const maturity = toDateEngineering(maturityArg);
  if (isErr(maturity)) {
    return maturity;
  }
  const investment = toNumEngineering(investmentArg);
  if (isErr(investment)) {
    return investment;
  }
  const discount = toNumEngineering(discountArg);
  if (isErr(discount)) {
    return discount;
  }
  const basis = validateBasis(basisArg);
  if (isErr(basis)) {
    return basis;
  }
  if (settlement >= maturity) {
    return ERROR_NUM;
  }
  if (investment <= 0 || discount <= 0) {
    return ERROR_NUM;
  }
  const yf = yearfrac(settlement, maturity, basis);
  const result = investment / (1 - discount * yf);
  if (result < 0) {
    return ERROR_NUM;
  }
  return result;
}

// RRI(nper, pv, fv)
// The equivalent interest rate for the growth of an investment.
export function RRI (nper: number, pv: number, fv: number) {
  if (nper <= 0) {
    return ERROR_NUM;
  }
  if (fv === 0) {
    return pv === 0 ? 0 : -1;
  }
  if (pv === 0) {
    return ERROR_NUM;
  }
  const fvDivPv = fv / pv;
  if (fvDivPv < 0) {
    return ERROR_NUM;
  }
  return fvDivPv ** (1 / nper) - 1;
}

// SLN(cost, salvage, life)
// Calculates the depreciation of an asset for one period using the straight-line method.
export function SLN (cost: number, salvage: number, life: number) {
  if (life === 0) {
    return ERROR_DIV0;
  }
  return (cost - salvage) / life;
}

// SYD(cost, salvage, life, period)
// Calculates the depreciation of an asset for a specified period using the sum of years digits method.
export function SYD (cost: number, salvage: number, life: number, period: number) {
  if (life <= 0 || period > life || period <= 0 || salvage < 0) {
    return ERROR_NUM;
  }
  return ((cost - salvage) * (life - period + 1) * 2) / (life * (life + 1));
}

/**
 * @returns {[ dayDiff: number, discountOrPrice: number ] | FormulaError} where
 * dayDiff is an integer > 0.
 */
function validateTbill (
  settlementArg: Reference | FormulaError | undefined | CellValueAtom,
  maturityArg: Reference | FormulaError | undefined | CellValueAtom,
  discountOrPriceArg: Reference | FormulaError | undefined | CellValueAtom,
): [number, number] | FormulaError {
  const settlement = toDateEngineering(settlementArg);
  if (isErr(settlement)) {
    return settlement;
  }
  const maturity = toDateEngineering(maturityArg);
  if (isErr(maturity)) {
    return maturity;
  }
  const dp = toNumEngineering(discountOrPriceArg);
  if (isErr(dp)) {
    return dp;
  }
  if (settlement >= maturity || dp <= 0) {
    return ERROR_NUM;
  }
  const yf = yearfrac(settlement, maturity, 1);
  if (yf > 1) {
    return ERROR_NUM;
  }
  return [ maturity - settlement, dp ];
}

// TBILLEQ(settlement, maturity, discount)
// Calculates the equivalent annualized rate of return of a US Treasury Bill based on discount rate.
export function TBILLEQ (
  settlementArg: Reference | FormulaError | undefined | CellValueAtom,
  maturityArg: Reference | FormulaError | undefined | CellValueAtom,
  discountArg: Reference | FormulaError | undefined | CellValueAtom,
) {
  const values = validateTbill(settlementArg, maturityArg, discountArg);
  if (isErr(values)) {
    return values;
  }
  const [ dayDiff, discount ] = values;
  const price = 100 * (1 - (discount * dayDiff) / 360);
  if (price <= 0) {
    return ERROR_NUM;
  }
  if (dayDiff <= 182) {
    return ((100 - price) / price) * (365 / dayDiff);
  }
  else {
    // `dayDiff` is an integer > 182, thus `f` is guaranteed > 0.5
    const f = dayDiff / 365;
    return (Math.sqrt(f * f - (2 * f - 1) * (1 - 100 / price)) - f) / (f - 0.5);
  }
}

// TBILLPRICE(settlement, maturity, discount)
// Calculates the price of a US Treasury Bill based on discount rate.
export function TBILLPRICE (
  settlementArg: Reference | FormulaError | undefined | CellValueAtom,
  maturityArg: Reference | FormulaError | undefined | CellValueAtom,
  discountArg: Reference | FormulaError | undefined | CellValueAtom,
) {
  const values = validateTbill(settlementArg, maturityArg, discountArg);
  if (isErr(values)) {
    return values;
  }
  const [ dayDiff, discount ] = values;
  const result = 100 * (1 - (discount * dayDiff) / 360);
  if (result <= 0) {
    return ERROR_NUM;
  }
  return result;
}

// TBILLYIELD(settlement, maturity, price)
// Calculates the yield of a US Treasury Bill based on price.
export function TBILLYIELD (
  settlementArg: Reference | FormulaError | undefined | CellValueAtom,
  maturityArg: Reference | FormulaError | undefined | CellValueAtom,
  priceArg: Reference | FormulaError | undefined | CellValueAtom,
) {
  const values = validateTbill(settlementArg, maturityArg, priceArg);
  if (isErr(values)) {
    return values;
  }
  const [ dayDiff, price ] = values;
  return ((100 - price) / price) * (360 / dayDiff);
}

// VDB(cost, salvage, life, start_period, end_period, [factor], [no_switch])
// Returns the depreciation of an asset for a particular period (or partial period).
export function VDB () {
  return null;
}

/**
 * XIRR(cashflow_amounts, cashflow_dates, [rate_guess])
 * Calculates the internal rate of return of an investment based on a specified series of potentially irregularly spaced cash flows.
 */
export function XIRR (
  cashflow_amounts: FormulaArgument,
  cashflow_dates: FormulaArgument,
  rate_guess: NonMatrixFormulaArgument,
): number | FormulaError {
  if (isErr(cashflow_amounts)) {
    return cashflow_amounts;
  }
  if (isBool(cashflow_amounts) || isStr(cashflow_amounts)) {
    return ERROR_VALUE;
  }
  if (!isRef(cashflow_amounts) && !isMatrix(cashflow_amounts)) {
    return ERROR_NUM;
  }

  if (isErr(cashflow_dates)) {
    return cashflow_dates;
  }
  if (isBool(cashflow_dates) || isStr(cashflow_dates)) {
    return ERROR_VALUE;
  }
  if (!isRef(cashflow_dates) && !isMatrix(cashflow_dates)) {
    return ERROR_NUM;
  }

  const resolved = resolveAmountsAndDates(cashflow_amounts, cashflow_dates);
  if (isErr(resolved)) {
    return resolved;
  }
  const { amounts, dates } = resolved;

  const guess = rate_guess == null ? 0.1 : toNum(rate_guess);
  if (isErr(guess)) {
    return guess;
  }
  if (guess <= -1) {
    return ERROR_NUM;
  }

  let anyPos = false;
  let anyNeg = false;
  for (const amount of amounts) {
    anyPos = anyPos || amount > 0;
    anyNeg = anyNeg || amount < 0;
    if (anyPos && anyNeg) {
      break;
    }
  }
  if (!anyPos || !anyNeg) {
    // Must have at least one negative and at least one positive value, else XIRR is not defined.
    return ERROR_NUM;
  }
  const TOLERANCE = 1e-13;
  const MAX_STEPS = 30; // Excel uses 20, but in a presumably different algorithm, so we don't follow that exactly
  // Very high initial guess still does not rule out "normal" IRRs so make lower bound no higher than -0.5
  const LOWER_BOUND_MAXIMUM = -0.5;
  // IRR near -100% is unlikely, and that range is fraught with floating-point artifacts, so only go near if initial guess is very low
  const LOWER_BOUND_MINIMUM = -0.999;
  const LOWER_BOUND_MARGIN = 0.001;
  const min = Math.max(LOWER_BOUND_MINIMUM, Math.min(LOWER_BOUND_MAXIMUM, -1 + LOWER_BOUND_MARGIN * (guess - -1)));
  // Never put upper bound any lower than 100% (and only do that if initialGuess is pretty close to -1)
  const UPPER_BOUND_MINIMUM = 1;
  // Put upper bound at 1e8 times (guess - min) from min; for default initialGuess this is 1.1e8
  const UPPER_BOUND_MULTIPLIER = 1e8;
  const max = Math.max(min + (guess - min) * UPPER_BOUND_MULTIPLIER, UPPER_BOUND_MINIMUM);
  const xnpvOfCashflows = (new_guess: number) => XNPV(new_guess, amounts, dates);
  const bracket = irrInitialBracket(xnpvOfCashflows, min, max, guess, TOLERANCE);
  if (isErr(bracket)) {
    return bracket;
  }
  return findRootBrent(xnpvOfCashflows, bracket, TOLERANCE, MAX_STEPS, guess) ?? ERROR_NUM;
}

function irrInitialBracket (
  func: (arg: number) => number | FormulaError,
  lowerLimit: number,
  upperLimit: number,
  initialGuess: number,
  errorTol: number,
): [a: number, b: number] | FormulaError {
  let a = lowerLimit;
  let b = upperLimit;
  for (const guess of irrRootBracketGuesses(lowerLimit, upperLimit, initialGuess)) {
    const bracket = findRootBracket(func, guess, lowerLimit, upperLimit, errorTol);
    if (isErr(bracket)) {
      return bracket;
    }
    if (bracket != null) {
      [ a, b ] = bracket;
      break;
    }
  }
  return [ a, b ];
}

/**
 * In `IRR` and `XIRR`, if `initialGuess` is > this, then we first try to find a
 * root bracket around zero before trying to find a root bracket around
 * `initialGuess`. Defaults to -0.997204455007436, see comment in the function.
 */

const PREFER_ZERO_THRESHOLD: number = -0.997204455007436;

/**
 * Heuristic choice of initial guesses for Brent root finding in IRR and XIRR.
 * Almost regardless of initial guess, we still try first to find a root near
 * zero, because the Excel IRR implementation (initially the only use case for
 * this root finder, along with XIRR) seems to prefer roots near zero even when
 * initial guess is quite close to a different root ... e.g. for:
 *
 * ```
 * Q$1:R$5 = [
 *   [-80000, -49],
 *   [-70000, -48],
 *   [12000, -47],
 *   [15000, -46],
 *   [18000, -45],
 *   [21000, -44]
 * ]
 * ```
 *
 * the NPV function has two real roots, at -0.19734503129463887424552421794 and
 * at -0.997499997048582619001303920229 and Excel prefers the former root even
 * when the initial guess gets _extremely_ close to the latter root:
 *
 * ```
 * =IRR(Q$1:R$5, -0.99)              = -0.197345031294639
 * =IRR(Q$1:R$5, -0.997204455007435) = -0.197345031294654
 * =IRR(Q$1:R$5, -0.997204455007436) = -0.997499997048583
 * ```
 *
 * That flipping point seems arbitrary, probably stemming from a heuristic
 * internal threshold in Excel's implementation. With nothing else to go on, we
 * to find a bracket around zero before we try to find a bracket around the
 * initial guess ... unless initial guess is lower than this threshold, then we
 * try the initial guess first. This matches Excel for this one case and
 * probably does not diverge _much_ from it in the general case.
 * @param lowerLimit
 * @param upperLimit
 * @param initialValue - initial guess, should be no lower than lowerLimit and
 *   no higher than upperLimit
 */
function irrRootBracketGuesses (lowerLimit: number, upperLimit: number, initialValue: number) {
  if (initialValue < lowerLimit) {
    initialValue = lowerLimit;
  }
  // Caller ensures that this constraint is satisfied
  invariant(initialValue <= upperLimit && initialValue >= lowerLimit, 'initial guess is out of range');
  const guess2 = lowerLimit + 0.01 * Math.abs(lowerLimit) + EPSILON;
  const guess3 = lowerLimit + Math.min((upperLimit - lowerLimit) ** 0.5, (upperLimit - lowerLimit) * 0.8);
  const preferZero = initialValue > PREFER_ZERO_THRESHOLD;
  const guesses = [ initialValue, guess2, guess3 ];
  if (lowerLimit < 0) {
    guesses.splice(preferZero ? 0 : 1, 0, 0);
  }
  return guesses;
}

function resolveAmountsAndDates (
  cashflow_amounts: Reference | Matrix,
  cashflow_dates: Reference | Matrix,
): { amounts: number[], dates: number[] } | FormulaError {
  const amountCellValues = cashflow_amounts.resolveRange({ skipBlanks: 'none' });
  if (isErr(amountCellValues)) {
    return amountCellValues;
  }

  // need as number list, not date object list, because subtraction should yield number of days
  const dateCellValues = cashflow_dates.resolveRange({ skipBlanks: 'none' });
  if (isErr(dateCellValues)) {
    return dateCellValues;
  }

  if (amountCellValues.length !== dateCellValues.length) {
    return ERROR_NUM.detailed('Mismatched number of amounts and dates');
  }

  let firstDate: number | null = null;
  const amounts: number[] = Array(amountCellValues.length);
  const dates: number[] = Array(dateCellValues.length);
  for (let i = 0; i < amountCellValues.length; i++) {
    let amount = amountCellValues[i];
    if (amount !== 0 && dateCellValues[i] === 0) {
      return ERROR_NUM; // reject items with a nonzero cashflow but not a real date
    }
    if (typeof amount === 'string') {
      const num = amount || i === 0 ? +amount : ERROR_VALUE;
      if (isErr(num)) {
        return num;
      }
      if (isFinite(num)) {
        amount = num;
        amounts[i] = num;
      }
      else {
        return ERROR_VALUE.detailed(fmtWithCellValues`Cashflow amount is non-numeric text ${amountCellValues[i]}`);
      }
    }
    let date = dateCellValues[i];
    if (typeof date === 'string') {
      const num = date ? +date : ERROR_VALUE;
      if (isErr(num)) {
        return num;
      }
      if (isFinite(num)) {
        date = num;
        dates[i] = num;
      }
      else {
        date = parseDateTime(date);
        if (typeof date !== 'number') {
          return ERROR_VALUE.detailed(fmtWithCellValues`Cashflow date is non-date text ${dateCellValues[i]}`);
        }
        dates[i] = date;
      }
    }
    if (isErr(date)) {
      return date;
    }
    if (typeof date !== 'number') {
      return ERROR_VALUE.detailed(`Cashflow date is ${date == null ? 'blank' : 'non-numeric: ' + dateCellValues[i]}`);
    }
    if (typeof amount === 'number') {
      amounts[i] = amount;
    }
    else if (isErr(amount)) {
      return amount;
    }
    else if (amount == null) {
      amounts[i] = 0;
    }
    else {
      const amountDescription = isLambda(amount) ? 'a lambda' : String(amount).toUpperCase();
      return ERROR_VALUE.detailed('Cashflow amount is ' + amountDescription);
    }
    date = Math.floor(date);
    dates[i] = date;
    if (firstDate == null) {
      firstDate = date;
    }
    else if (date < firstDate && amount) {
      return ERROR_NUM.detailed('First date must be earlier than all other cashflow dates');
    }
  }

  if (dateCellValues.length === 0) {
    return ERROR_VALUE.detailed('No dates received');
  }

  return { amounts, dates };
}

/**
 * XNPV(discount, cashflow_amounts, cashflow_dates)
 * Calculates the net present value of an investment based on a specified series of potentially irregularly spaced cash
 * flows and a discount rate.
 * In addition to their signature types in normal spreadsheet function invocation, cashflow_amounts and cashflow_dates
 * can also be arrays of cell values, for internal usage in XIRR.
 * @param discount
 * @param cashflow_amounts (type is CellValue[] when this is called directly from XIRR)
 * @param cashflow_dates (type is CellValue[] when this is called directly from XIRR)
 */
export function XNPV (
  discount: NonMatrixFormulaArgument,
  cashflow_amounts: FormulaArgument | CellValue[],
  cashflow_dates: FormulaArgument | CellValue[],
): number | FormulaError {
  discount = toNum(discount);
  if (isErr(discount)) {
    return discount;
  }
  if (isErr(cashflow_amounts)) {
    return cashflow_amounts;
  }
  if (isErr(cashflow_dates)) {
    return cashflow_dates;
  }

  const cashflows = toNumListStrict(Array.isArray(cashflow_amounts) ? cashflow_amounts : [ cashflow_amounts ]);
  if (isErr(cashflows)) {
    return cashflows;
  }

  // need as number list, not date object list, because subtraction should yield number of days
  const dates = toNumListStrict(Array.isArray(cashflow_dates) ? cashflow_dates : [ cashflow_dates ]);
  if (isErr(dates)) {
    return dates;
  }

  if (cashflows.length !== dates.length) {
    return ERROR_NUM;
  }

  for (let i = 0; i < cashflows.length; i++) {
    if (cashflows[i] !== 0 && dates[i] === 0) {
      return ERROR_NUM; // reject items with a nonzero cashflow but not a real date
    }
  }

  const date0 = Math.floor(dates[0]);
  let xnpv = 0;
  for (let i = 0; i < cashflows.length; i++) {
    // NOTE: dividing by 365 regardless of leap years, because docs say so.
    const datei = dates[i];
    const denominator = round15((1 + discount) ** ((Math.floor(datei) - date0) / 365));
    if (!denominator) {
      return ERROR_DIV0;
    }
    else {
      xnpv += cashflows[i] / denominator;
    }
  }
  return xnpv;
}

function calcYield (
  settlement: number,
  maturity: number,
  rate: number,
  pr: number,
  redemption: number,
  frequency: number,
  basis: number,
) {
  let yLo = 0;
  let yHi = 8;
  // Exponential search to find upper bound
  for (;;) {
    const prHi = price(settlement, maturity, rate, yHi, redemption, frequency, basis);
    if (isErr(prHi)) {
      return prHi;
    }
    if (pr >= prHi) {
      break;
    }
    yHi *= 4;
  }
  // Binary search to find exact value
  let yPrev = -Infinity;
  let y;
  for (;;) {
    y = yLo + (yHi - yLo) / 2;
    const prI = price(settlement, maturity, rate, y, redemption, frequency, basis);
    if (isErr(prI)) {
      return prI;
    }
    if (pr < prI) {
      yLo = y;
    }
    else {
      yHi = y;
    }
    if (Math.abs(y - yPrev) < 5e-7) {
      break;
    }
    yPrev = y;
  }
  return y;
}

// YIELD(settlement, maturity, rate, price, redemption, frequency, basis)
// Calculates the annual yield of a security paying periodic interest, such as a US Treasury Bond, based on price.
export function YIELD (
  settlementArg: Reference | FormulaError | undefined | CellValueAtom,
  maturityArg: Reference | FormulaError | undefined | CellValueAtom,
  rateArg: Reference | FormulaError | undefined | CellValueAtom,
  priceArg: Reference | FormulaError | undefined | CellValueAtom,
  redemptionArg: Reference | FormulaError | undefined | CellValueAtom,
  frequencyArg: Reference | FormulaError | undefined | CellValueAtom,
  basisArg: Reference | FormulaError | undefined | CellValueAtom,
) {
  const settlement = toDateEngineering(settlementArg);
  if (isErr(settlement)) {
    return settlement;
  }
  const maturity = toDateEngineering(maturityArg);
  if (isErr(maturity)) {
    return maturity;
  }
  const rate = toNumEngineering(rateArg);
  if (isErr(rate)) {
    return rate;
  }
  const pr = toNumEngineering(priceArg);
  if (isErr(pr)) {
    return pr;
  }
  const redemption = toNumEngineering(redemptionArg);
  if (isErr(redemption)) {
    return redemption;
  }
  const frequency = validateFrequency(frequencyArg);
  if (isErr(frequency)) {
    return frequency;
  }
  const basis = validateBasis(basisArg);
  if (isErr(basis)) {
    return basis;
  }
  if (settlement >= maturity || pr <= 0 || rate < 0 || redemption <= 0) {
    return ERROR_NUM;
  }
  return calcYield(settlement, maturity, rate, pr, redemption, frequency, basis);
}

// YIELDDISC(settlement, maturity, price, redemption, basis)
// Calculates the annual yield of a discount (non-interest-bearing) security, based on price.
export function YIELDDISC (
  settlementArg: Reference | FormulaError | undefined | CellValueAtom,
  maturityArg: Reference | FormulaError | undefined | CellValueAtom,
  priceArg: Reference | FormulaError | undefined | CellValueAtom,
  redemptionArg: Reference | FormulaError | undefined | CellValueAtom,
  basisArg: Reference | FormulaError | undefined | CellValueAtom,
) {
  const values = validateDisc(settlementArg, maturityArg, priceArg, redemptionArg, basisArg);
  if (isErr(values)) {
    return values;
  }
  const [ settlement, maturity, pr, redemption, basis ] = values;
  const yf = yearfrac(settlement, maturity, basis);
  return (redemption / pr - 1) / yf;
}

// YIELDMAT(settlement, maturity, issue, rate, price, basis)
// Calculates the annual yield of a security paying interest at maturity, based on price.
export function YIELDMAT (
  settlementArg: Reference | FormulaError | undefined | CellValueAtom,
  maturityArg: Reference | FormulaError | undefined | CellValueAtom,
  issueArg: Reference | FormulaError | undefined | CellValueAtom,
  rateArg: Reference | FormulaError | undefined | CellValueAtom,
  priceArg: Reference | FormulaError | undefined | CellValueAtom,
  basisArg: Reference | FormulaError | undefined | CellValueAtom,
) {
  const settlement = toDateEngineering(settlementArg);
  if (isErr(settlement)) {
    return settlement;
  }
  const maturity = toDateEngineering(maturityArg);
  if (isErr(maturity)) {
    return maturity;
  }
  const issue = toDateEngineering(issueArg);
  if (isErr(issue)) {
    return issue;
  }
  const rate = toNumEngineering(rateArg);
  if (isErr(rate)) {
    return rate;
  }
  const pr = toNumEngineering(priceArg);
  if (isErr(pr)) {
    return pr;
  }
  const basis = validateBasis(basisArg);
  if (isErr(basis)) {
    return basis;
  }
  if (settlement >= maturity || issue >= settlement || pr <= 0 || rate < 0) {
    return ERROR_NUM;
  }
  const imYf = yearfrac(issue, maturity, basis);
  const isYf = yearfrac(issue, settlement, basis);
  const smYf = yearfrac(settlement, maturity, basis);
  return ((1 + imYf * rate) / (pr / 100 + isYf * rate) - 1) / smYf;
}
