import EventEmitter from 'component-emitter';
import { ERROR_NAME } from './excel/constants';
import FormulaError from './excel/FormulaError';
import ModelError from './ModelError';
import Reference, { isA1Ref, toRef, type A1Reference } from './excel/Reference';
import { parse as parseRef } from './excel/referenceParser';
import { assertParserLoaded, parseFormula, ready as formulaParserReady } from './excel/formulaParser';
import Cell, { BLANK_CELL } from './excel/Cell';
import {
  ALL_FORMULA_CELLS,
  CHANGED_ONLY,
  CHANGED_OR_VOLATILE,
  RecalcState,
  briefRefStr,
  recalculate,
} from './recalculate';
import {
  evaluateAST,
  evaluateASTNodeUnbound,
  evaluateStaticReferenceASTNodeUnbound,
  EvaluationError,
} from './excel/evaluate';
import { DEV_LOGGING } from './devutil.js';
import { flags } from './Flags';
import { isMatrix, isRef, isErr, excelEqual } from './utils';
import { unbox } from './excel/ValueBox.js';
import { COERCE_NONE, modeCoercesNullToZero, MODE_GRID_SHEET, CoercionMode } from './mode';
import { CellVertexId, DependencyGraph, dumpOutgoing, NameVertexId } from './DependencyGraph';
import { invariant } from './validation';
import { WorkbookNotFoundError } from './errors';
import updateGraphFor, { removeWorkbookFromDependencyGraph } from './updateGraph';
import { extractRepeatedExpressions } from './optimizeGraph';
import { lazyLoadModulePathsNotYetImported, supportedFunctionNames } from './excel/functions';
import { visitAllCallNodes, type FnEvaluateASTNode } from './excel/ast';
import { visitAllTopLevelReferenceNodes } from './excel/ast-common';
import { ASTNode } from './excel/ast-types';
import { baseRunOptions } from './excel/evaluate-common';
import { rewriteFormulaAfterMove } from './moveCells';
import Workbook, { IterativeCalculationOptions, normalizeFormula } from './Workbook';
import { vertexIdToReference } from './dep-graph-helpers';
import { ModeBit, WorkbookMode } from './excel/functions/sigtypes';
import { CellValue, FormulaValue, type MaybeBoxedFormulaArgument, type MaybeBoxedFormulaValue } from './excel/types';
import { AreaArray, type AreaCellArray } from './AreaArray';
import { EvaluationContext, type MetricsEvent } from './excel/EvaluationContext';
import { WorkbookCSF } from './csf';
import { ModelStateTree } from './types';
import Matrix from './excel/Matrix';
import type WorkSheet from './WorkSheet.js';
import type { Table } from './Table.js';
import { getModelEntities } from './modelEntities';
import { Profile } from './Profile';
import { findVertexIdsNeedingInitRecalc } from './initRecalc';
import { goalSeek } from './goalSeek';
import { ERROR_CALC_LAMBDA_NOT_ALLOWED, isLambda } from './excel/lambda';

// Formulas containing references to external workbooks in workbooks whose
// update date is before the cutoff date are not guaranteed to be up-to-date.
const ALWAYS_RECALCULATE_EXTERNAL_WB_REFERENCES_CUTOFF = new Date('2023-08-15').getTime();

const DEFAULT_ITER_CALC_SETTINGS: IterativeCalculationOptions = {
  iterate: false,
  maxIterations: 100,
  maxChange: 0.001,
};

export type ModelEventArgs = {
  'native-update': {
    workbookName: string | undefined,
    workbookId: string | undefined,
    lastWrite: number,
    action: string,
  },
  error: {
    type: string,
    workbookId: string | undefined,
    workbookName: string | undefined,
    error: ModelError,
    seenBefore: boolean,
  },
  attach: { workbookName: string },
  detach: { workbookName: string },
  addsheet: {
    workbookName: string,
    sheetName: string,
    index: number,
  },
  beforerecalc: Model,
  recalc: Model,
  metrics: MetricsEvent,
  reset: {},
};

type ModelEventType = keyof ModelEventArgs;

type ModelEventListener<T extends ModelEventType> = (event: ModelEventArgs[T]) => void;

interface AddWorkbookOptions {
  /**
   * Settings for iterative calculations (nullish to disable; that's the default)
   */
  iterativeCalculation?: IterativeCalculationOptions,
  /**
   * Whether to recalculate volatile cells on init. Assumed true if not specified.
   */
  recalcVolatiles?: boolean,
  /**
   * workbook mode, overriding the default mode based on the `type` CSF property.
   */
  mode?: WorkbookMode,
  /**
   * When set to true, then work that is required to support writes is skipped in order to load the workbook faster.
   * In particular, formulas will not be parsed and the workbook will not be added to the dependency graph.
   * Moreover, no initial recalculation occurs, which may lead to inaccuracies in workbooks that depend on it.
   */
  readOnly?: boolean,
}

interface WriteMultipleOptions {
  /**
   * Recalculate all workbooks even if no writes were performed
   * @default false
   */
  forceRecalc?: boolean,
  /**
   * Don't perform automatic recalculation (but respect forceRecalc)
   * @default false
   */
  skipRecalc?: boolean,
  /**
   * Reset any previous writes first (so _only_ these writes apply)
   * @default false
   */
  reset?: boolean,
}

interface EvaluateExpressionOptions {
  /**
   * Value to return if expression is null or empty or `=`, or if
   * `options.single` is true and the expression does not resolve to a single
   * cell/value.
   */
  fallBack: AreaArray<CellValue> | AreaArray<Cell> | CellValue | Cell,
  /**
   * True if a single cell is to be returned, and it should be a defined-name
   * cell if that defined name evaluates to a cell value or array (i.e. not a
   * reference). If this is true, then `values` is ignored and assumed false,
   * and `single` is ignored and assumed true.
   */
  definedName?: boolean,
  /**
   * True if bare values are to be returned, false for Cell objects.
   */
  values: boolean,
  /**
   * True if a single cell or value is to be returned; false for a 2-D array of cells or values.
   */
  single: boolean,
  /**
   * Extra context to pass to `Model.runFormula()`, which is then passed through to the formula runner.
   */
  extraContext?: Partial<EvaluationContext> | null,
  cropTo?: 'any-cell-information' | 'cells-with-non-blank-values',
}

export interface ModelMeta {
  sources: {
    id: string,
    name: string,
    update_time: string | undefined,
    cellCount: number,
    volatileCount: number,
  }[],
  cellCount: number,
  volatileCount: number,
  graphNodes: number,
  graphEdges: number,
}

type WhichCellsToRecalculate = typeof CHANGED_OR_VOLATILE | typeof ALL_FORMULA_CELLS | typeof CHANGED_ONLY;

const externalFormulaCache: Partial<Record<string, ASTNode>> = {};
let instanceCounter = 1;

function isIgnore (f: string | null) {
  return f == null || f === '' || f === '=';
}

function stableSort<T> (arr: T[], compare: (a: T, b: T) => number) {
  return arr
    .map((item, i) => [ item, i ] as const)
    .sort((a, b) => compare(a[0], b[0]) || a[1] - b[1])
    .map(d => d[0]);
}

/**
 * Wrap the given cell or cell value into a 1x1 area array
 */
function wrapInAreaArray<T extends CellValue | Cell> (
  cell: T,
  sheetName: string,
  workbookName: string,
): AreaArray<Cell | null> | AreaArray<CellValue> {
  const array = [ [ cell ] ] as (Cell | null)[][] | CellValue[][];
  return Object.assign(array, {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    dataRight: 0,
    dataBottom: 0,
    defaultColumn: [],
    defaultRow: [],
    defaultValue: null,
    sheetName,
    workbookName,
  });
}

type FormulaProblem =
  | { type: 'uses_unsupported_functions', unsupportedFunctions: string[] }
  | { type: 'uses_unsupported_syntax', unsupportedSyntax: string[] }
  | { type: 'has_unresolvable_references', unresolvableReferences: Reference[] };

type AnalyzeFormulaResult =
  | {
    status: 'ok',
    formula: string,
    result: CellValue | Matrix | Reference,
    references: Reference[],
    functions: string[],
  }
  | { status: 'has_problems', problems: FormulaProblem[] }
  | { status: 'unparsable_formula' };

/**
 * Model implementation. Holds a name-keyed map of Workbook instances and serves as an interface over them for
 * initialization and reads and writes and metadata.
 *
 * Note that some methods of this class have a precondition that the `Model.preconditions` promise has been resolved.
 * Any code that may execute sooner than that should `await` that promise first, before calling those methods.
 */
export class Model extends EventEmitter implements EvaluationContext {
  /**
   * Promise that should be awaited before calling certain methods of `Model`
   * (noted with a “Precondition” in the documentation of those methods).
   */
  static preconditions = formulaParserReady;

  coerceNullToZero: CoercionMode = COERCE_NONE;
  mode: ModeBit = MODE_GRID_SHEET;
  flags = flags;
  readonly workbookName = null; // To satisfy EvaluationContext interface
  subscribers = [];
  lastWrite = 0;
  instanceId: number;
  getWorkbook: typeof getWorkbook;
  resolveWorkbook: typeof getWorkbook;
  getWorkbookByKey: typeof getWorkbookByKey;
  resolveSheet: typeof getSheet;
  getGlobal: typeof getGlobal;
  resolveName: typeof resolveName;
  getTable: typeof getTable;
  resolveTable: typeof getTable;
  writeState: typeof writeState;
  getEntities: typeof getModelEntities;

  _workbooks: Workbook[] = [];
  _recalcState = new RecalcState();
  _lazyImports = new Map<string, Promise<void>>();
  _graph = new DependencyGraph<Cell>();
  _meta: null | ModelMeta = null; // memoized result of meta getter
  _errorsFromModel: ModelError[] = [];
  _expressionEvaluationErrorsByMsg: Partial<Record<string, ModelError>> = {};
  _unrecognizedWorkbookNames = new Set<string>();
  profile: Profile | null = null;

  /**
   * Information about outside environment relevant to the model and functions.
   * Writable from the outside, but functions get only read access to it.
   */
  env: Map<'username' | 'isMobile' | 'isPrint', MaybeBoxedFormulaArgument> = new Map();

  /**
   * Evaluate a formula in the form of an AST, in a given evaluation context.
   * This wraps the `evaluateAST` function as a `Model` method, just to enable
   * calling it via evaluation context to dodge circular imports.
   */
  evaluateAST: (ast: ASTNode, options: EvaluationContext) => MaybeBoxedFormulaValue;

  /**
   * Evaluate an AST node.
   * This wraps the `evaluateASTNodeUnbound` function as a `Model` method, just
   * to enable calling it via evaluation context to dodge circular imports.
   */
  evaluateASTNode: FnEvaluateASTNode;

  /**
   * Make a `Model` instance and populate it with a workbook from the given CSF and init options.
   * Precondition: the `Model.preconditions` promise has been resolved.
   *
   * @param csf a CSF object representing a workbook, as received
   *   from the `/document/:docId/workbook/:wbId/body` endpoint of the GRID API.
   * @throws {Error} if precondition is not satisfied (the formula parser has not
   * finished importing)
   */
  static fromData (csf: WorkbookCSF, options: AddWorkbookOptions = {}): Model {
    const model = new Model();
    if (typeof options.mode === 'number') {
      model.mode = options.mode;
    }
    model.addWorkbook(csf, options);
    return model;
  }

  addError (modelError: ModelError): ModelError {
    let error = this._errorsFromModel.find(err => err.message === modelError.message);
    let seenBefore = false;
    if (!error) {
      error = modelError;
      this._errorsFromModel.push(error);
    }
    else {
      seenBefore = true;
      for (const vertexId of modelError.vertexIds) {
        error.vertexIds.add(vertexId);
      }
    }
    let lastWorkbook: Workbook | null = null;
    for (const vertexId of modelError.vertexIds) {
      const workbook = this.getWorkbookByKey(vertexId.workbookKey);
      if (workbook) {
        lastWorkbook = workbook;
        error.workbook = workbook;
        workbook._cellsWithErrors.add(vertexId);
      }
    }
    this.emit('error', {
      workbookId: lastWorkbook?.id,
      workbookName: lastWorkbook?.name,
      error,
      seenBefore,
    });
    return modelError;
  }

  constructor () {
    super();
    this.instanceId = instanceCounter++;
    this.getWorkbook = getWorkbook.bind(this);
    this.resolveWorkbook = getWorkbook.bind(this);
    this.getWorkbookByKey = getWorkbookByKey.bind(this);
    this.resolveSheet = getSheet.bind(this);
    this.getGlobal = getGlobal.bind(this);
    this.resolveName = resolveName.bind(this);
    this.getTable = getTable.bind(this);
    this.resolveTable = getTable.bind(this);
    this.triggerMetrics = this.triggerMetrics.bind(this);
    this.triggerUpdate = this.triggerUpdate.bind(this);
    this.evaluateAST = function (ast: ASTNode, options: EvaluationContext): MaybeBoxedFormulaValue {
      return evaluateAST(ast, { ...this, ...options });
    };
    this.evaluateASTNode = function (ast) {
      return evaluateASTNodeUnbound.call(this, ast);
    };
    this.getEntities = getModelEntities.bind(this);
    this.writeState = writeState.bind(this);

    /** Make coerceNullToZero enumerable so that `{ ...resolver, ... }` keeps it */
    Object.defineProperty(this, 'coerceNullToZero', {
      enumerable: true,
      get: function () {
        return modeCoercesNullToZero(this.mode);
      },
    });
  }

  get defaultWorkbookName () {
    return this._workbooks[0]?.name || '';
  }

  get ready () {
    return this._workbooks.every(wb => wb.ready);
  }

  get defect () {
    return this._workbooks.find(wb => wb.state().defect)?.state()?.defect;
  }

  get volatiles () {
    return this._graph.volatiles as ReadonlyArray<CellVertexId | NameVertexId>;
  }

  setWorkbookState (workbookId: string, state: WorkbookCSF['state'], defect: string | null) {
    const workbook = this._workbooks.find(wb => wb.id === workbookId);
    if (workbook) {
      workbook.state(state, defect);
      this.triggerUpdate();
    }
  }

  getWorkbooks () {
    return [ ...this._workbooks ];
  }

  /**
   * Get the workbook with the given ID, or null if no such workbook is in the model.
   */
  getWorkbookById (id: string): Workbook | undefined {
    return this._workbooks.find(wb => wb.id === id);
  }

  /**
   * Add workbook data to this model.
   * Precondition: the `Model.preconditions` promise has been resolved.
   * @param csf the workbook data as a CSF structure
   * @param options keyword arguments to pass to `updateWorkbook` or `Workbook.fromData`.
   * @throws {Error} if precondition is not satisfied (the formula parser has not finished importing)
   */
  addWorkbook (csf: WorkbookCSF, options: AddWorkbookOptions = {}): Workbook {
    const workbook = new Workbook({ ...options, csf, model: this });
    this._attachWorkbook(workbook, options.recalcVolatiles ?? true, options.readOnly);
    return workbook;
  }

  /**
   * @param vertexIDs IDs of the specific vertices to update.
   */
  _updateDependenciesFor (workbook: Workbook, vertexIDs?: Array<CellVertexId | NameVertexId>) {
    const { errors } = updateGraphFor(this._graph, workbook, vertexIDs);
    for (const error of errors) {
      workbook.addError(error);
    }
  }

  /**
   * Attach the given workbook to this model.
   */
  _attachWorkbook (wb: Workbook, recalculate = true, readOnly = false, extractExpressions = true) {
    this._meta = null;

    wb.on('error', (ev: unknown) => this.emit('error', ev));
    if (this.hasListeners('metrics')) {
      wb.on('metrics', this.triggerMetrics);
    }

    const oldIndex = this._workbooks.findIndex(existing => existing.id === wb.id);
    if (oldIndex === -1) {
      const sameName = this._workbooks.find(existing => existing.name.toUpperCase() === wb.name.toUpperCase());
      if (sameName) {
        throw new Error('Model already contains workbook with same name "' + sameName.name + '", cannot add another');
      }
      this._workbooks.push(wb);
    }
    else {
      const oldWorkbook = this._workbooks[oldIndex];
      this._workbooks[oldIndex] = wb;
      wb.applyWritesFrom(oldWorkbook);
    }

    if (!readOnly) {
      // Add workbook to new model's dependency graph
      this._updateDependenciesFor(wb);
      if (extractExpressions) {
        extractRepeatedExpressions(this);
      }

      // Assign static-reference values to defined names, or if not static, put them up for the init recalculation.
      const opts: EvaluationContext = {
        ...wb,
        workbookName: wb.name,
        coerceNullToZero: COERCE_NONE,
        evaluateAST,
      };
      const evaluateStaticReferenceASTNode = evaluateStaticReferenceASTNodeUnbound.bind(opts);
      for (const definedNameCell of wb.allDefinedNames()) {
        const staticRef = evaluateStaticReferenceASTNode(definedNameCell._ast);
        if (staticRef) {
          definedNameCell.v = staticRef;
        }
        else {
          this._recalcState.namesAwaitingRecalc.push(
            new NameVertexId(wb.keyInDepGraph, definedNameCell.sheetIndex, definedNameCell.id),
          );
        }
      }

      if (recalculate) {
        // Recalculate immediately to update volatile cells (and defined names
        // marked above) -- if we can.
        this.recalculate();
      }
    }
    wb.ready = true;
    this.lastWrite++;
    this.emit('attach', { workbookName: wb.name });
  }

  /**
   * Remove a given workbook from this model.
   * @param id the ID of the workbook to remove
   * @returns true if the workbook was found and removed, false if it was not found
   */
  removeWorkbook (id: string): boolean {
    const index = this._workbooks.findIndex(wb => wb.id === id);
    if (index === -1) {
      return false;
    }

    this._meta = null;

    const [ removed ] = this._workbooks.splice(index, 1);
    const workbookKey = removed.keyInDepGraph;
    this._recalcState.removeWorkbook(workbookKey);
    removeWorkbookFromDependencyGraph(this._graph, removed);
    this.recalculate();
    this.lastWrite++;
    this.emit('detach', { workbookName: removed.name });
    return true;
  }

  /**
   * Set the order in which workbooks are treated as default.
   * @param idList list of IDs in the order to use
   */
  orderWorkbooks (idList: string[]) {
    this._workbooks = stableSort(this._workbooks, (wbA, wbB) => {
      const indexA = idList.findIndex(id => id === wbA.id);
      const indexB = idList.findIndex(id => id === wbB.id);
      if (indexA === -1 && indexB === -1) {
        return 0;
      }
      if (indexA === -1) {
        return 1;
      }
      if (indexB === -1) {
        return -1;
      }
      return indexA - indexB;
    });
  }

  getCell (cellId: string, sheetName?: string | null, workbookName?: string | null): Cell | null {
    const sheet = this.resolveSheet(sheetName, workbookName);
    if (sheet) {
      return sheet.getCellByID(cellId);
    }
    return null;
  }

  /**
   * @param workbookName name of a workbook to look in, if `cellRef` does not have a workbook prefix
   */
  isGlobal (cellRef: string, workbookName?: string): boolean {
    const d = parseRef(cellRef);
    if (!d) {
      return false;
    }
    const wb = this.getWorkbook(d?.workbookName || workbookName);
    if (wb && d.name) {
      return wb.isGlobal(d.name);
    }
    return false;
  }

  get hasData () {
    return this._workbooks.length > 0;
  }

  triggerUpdate () {
    if (this._workbooks.length) {
      this.lastWrite++;
      this.emit('recalc', this);
    }
  }

  triggerMetrics (ev: (...args: ModelEventArgs['metrics'][]) => void) {
    this.emit('metrics', ev);
  }

  on<T extends ModelEventType> (event: T, listener: ModelEventListener<T>) {
    // if metrics are being subscribed to, ensure workbooks are also listened to
    if (event === 'metrics') {
      this.getWorkbooks().forEach(wb => {
        // ensure a single listener is subscribed to the workbook
        wb.off('metrics', this.triggerMetrics);
        // add listener if needed
        wb.on('metrics', this.triggerMetrics);
      });
    }
    if (event === 'addsheet') {
      this.getWorkbooks().forEach(wb => {
        // ensure a single listener is subscribed to the workbook
        wb.off('addsheet', this.triggerUpdate);
        // add listener if needed
        wb.on('addsheet', this.triggerUpdate);
      });
    }
    return super.on(event, listener);
  }

  off<T extends ModelEventType> (event: T, listener: ModelEventListener<T>) {
    if (event === 'metrics') {
      this.getWorkbooks().forEach(wb => {
        wb.off('metrics', this.triggerMetrics);
      });
    }
    if (event === 'addsheet') {
      this.getWorkbooks().forEach(wb => {
        wb.off('addsheet', this.triggerUpdate);
      });
    }
    return super.off(event, listener);
  }

  /**
   * Perform each write in the given list of writes and recalculate once at the end
   * (if the list was non-empty, or if `forceRecalc` is true).
   * Equivalent to calling `write` with each of the cell-val pairs in `writes` and
   * `recalcNow=false`, and then `recalculate` and `triggerUpdate` at the end.
   *
   * @param writes array of two-element arrays `[cell, vall]` for individual writes
   * @param {WriteMultipleOptions} [opts={}] Options for writes
   * @param {boolean} [opts.forceRecalc=false] Recalculate all workbooks even if no writes were performed
   * @param {boolean} [opts.skipRecalc=false] Don't perform automatic recalculation (but respect forceRecalc)
   * @param {boolean} [opts.reset=false] Reset any previous writes first (so _only_ these writes apply)
   */
  writeMultiple (writes: Array<readonly [string, CellValue]>, opts: WriteMultipleOptions = {}) {
    const { forceRecalc, skipRecalc, reset } = opts;
    if (reset) {
      // call reset directly on workbooks, not this.reset(), because don't want events emitted and a recalc
      this.getWorkbooks().forEach(wb => wb.reset());
    }
    writes.forEach(pair => {
      this._write(pair[0], pair[1], false);
    });
    if (forceRecalc || (writes.length > 0 && !skipRecalc)) {
      this.recalculate();
    }
  }

  /**
   * Write the given value to the given cell (A1 address or global name)
   *
   * @param cellRef the address or global name to write to
   * @param value the value to write
   * @param [recalcNow=true] true (the default) to recalculate immediately before returning
   * @param [skipVolatiles=false] true to skip evaluation of volatile cells when recalculating
   */
  write (cellRef: string | Reference, value: CellValue, recalcNow = true, skipVolatiles = false) {
    this._write(cellRef, value, recalcNow, skipVolatiles);
  }

  /**
   * Internal implementation of write, protected from wrapping of write method in GRID-client.
   */
  _write (cellRef: string | Reference, value: CellValue, recalcNow: boolean, skipVolatiles = false) {
    if (typeof cellRef === 'string') {
      cellRef = cellRef.replace(/^=/, '');
    }
    const wb = this._getReferencedWorkbook(cellRef);
    if (wb) {
      const didWrite = wb.write(cellRef, value);
      if (recalcNow && didWrite) {
        this.recalculate(skipVolatiles ? CHANGED_ONLY : CHANGED_OR_VOLATILE);
      }
      return wb;
    }
    else {
      this._emitWorkbookNotFoundError(cellRef);
    }
  }

  // return a list of all writes to the model
  writes () {
    return this.getWorkbooks().flatMap(wb => {
      return wb.writes().map(([ k, v ]) => {
        // any key that made it into wb.writes() was made by String(ref), so will parse; new Reference will not throw
        // XXX it _will_ throw if k is a bare cell address (no sheet prefix), because then passing a workbookName and
        // no sheetName is forbidden. We don't seem to have cases of that currently, but this is probably a lurker.
        const ref = new Reference(k, { workbookName: wb.name });
        return [ String(ref), v ] as const;
      });
    });
  }

  /**
   * @param ref A reference to the cell, or range of cells, to clear. Defined names are not supported.
   */
  clearCells (ref: string | Reference) {
    if (!ref) {
      return;
    }
    const wb = this._getReferencedWorkbook(ref);
    if (wb) {
      const changed = wb.clearCells(ref);
      if (changed) {
        this.recalculate();
      }
      this.triggerUpdate();
    }
    else {
      this._emitWorkbookNotFoundError(ref);
    }
  }

  _getReferencedWorkbook (ref: string | Reference): Workbook | undefined {
    let workbookName: string | undefined;
    const r = toRef(ref);
    if (r && r.workbookName) {
      workbookName = r.workbookName;
    }
    return this.getWorkbook(workbookName);
  }

  _emitWorkbookNotFoundError (ref: string | Reference) {
    const workbookName = toRef(ref)?.workbookName || '';
    this.emit('error', {
      type: 'workbook-not-found',
      error: new WorkbookNotFoundError(isRef(ref) ? ref.workbookName : ref),
      workbookId: null,
      workbookName,
      seenBefore: this._unrecognizedWorkbookNames.has(workbookName),
    });
    this._unrecognizedWorkbookNames.add(workbookName);
  }

  recalculate (whichCells: WhichCellsToRecalculate = CHANGED_OR_VOLATILE) {
    this.emit('beforerecalc');
    this._checkLazyImportsComplete();
    let changedCellChangedRefs: Reference[] = [];
    if (Profile.enabled) {
      this.profile = new Profile(makeRecalculationName(this), this.defaultWorkbookName);
    }
    try {
      changedCellChangedRefs = recalculate(this, this._recalcState, whichCells);
    }
    catch (err: any) {
      if (err instanceof ModelError) {
        this.addError(err);
      }
      else {
        if (DEV_LOGGING) {
          console.error(err);
        }
        this.addError(
          new ModelError('Recalculation failed: ' + err.message, ModelError.ERROR, null, 'recalcfailed', err),
        );
      }
    }
    finally {
      this.profile?.done();
      this.profile = null;
    }
    const nativeWorkbooksChanged = new Set<Workbook>();
    for (const ref of changedCellChangedRefs) {
      const wb = this.getWorkbook(ref.workbookName);
      invariant(wb != null);
      wb.updateCellResetState(ref);
      if (wb.type === 'native') {
        nativeWorkbooksChanged.add(wb);
      }
    }
    for (const wb of this._workbooks) {
      for (const sheet of wb._sheets) {
        sheet._cells.clearGsdv();
      }
    }
    this._recalcState.writtenSinceRecalc = [];
    this._recalcState.changedSinceRecalc = [];
    this.triggerUpdate();

    return { nativeWorkbooksChanged };
  }

  iterativeCalculationSettings (): IterativeCalculationOptions {
    let ret: IterativeCalculationOptions | null = null;
    for (const wb of this._workbooks) {
      const wbIterCalcSetting = wb._iterativeCalculationSettings;
      if (wbIterCalcSetting == null) {
        continue;
      }
      if (ret == null) {
        ret = {
          iterate: wbIterCalcSetting.iterate,
          maxIterations: wbIterCalcSetting.maxIterations,
          maxChange: wbIterCalcSetting.maxChange,
        };
      }
      else {
        ret.iterate ||= wbIterCalcSetting.iterate;
        ret.maxIterations = Math.max(ret.maxIterations, wbIterCalcSetting.maxIterations);
        ret.maxChange = Math.min(ret.maxChange, wbIterCalcSetting.maxChange);
      }
    }
    return ret || DEFAULT_ITER_CALC_SETTINGS;
  }

  goalSeek (controlCell: string | Reference, targetCell: string | Reference, targetValue: number) {
    const controlRef = Reference.from(controlCell);
    invariant(isA1Ref(controlRef));
    const targetRef = Reference.from(targetCell);
    invariant(isA1Ref(targetRef));
    return goalSeek(this, controlRef.collapse(), targetRef.collapse(), targetValue);
  }

  /**
   * Reset all cell values to their initial state (as data was when read).
   * Only values are reset; other cell attributes (function, style) are not affected.
   * This also triggers recalculation, so volatile cells will update.
   */
  reset () {
    for (const wb of this.getWorkbooks()) {
      wb.reset();
      wb.clearCachedFormulasExcept([]);
    }
    this.recalculate();
    this.emit('reset', {});
  }

  /**
   * Determine the result of a spreadsheet formula on this model.
   * The result may be cached, and reused if references are unchanged.
   * Precondition: the `Model.preconditions` promise has been resolved.
   * @param formula the formula code to run
   * @param extraContext extra options or properties to pass through to the formula runner. These will be
                     available to each spreadsheet function as attributes on `this`
   * @return result of the formula
   * @throws {Error} if precondition is not satisfied (the formula parser has not finished importing)
   * @throws {EvaluationError} if AST is invalid or an unanticipated internal error occurs so evaluation can't complete.
   *   This error will have its `.ast` and `.formula` properties populated.
   * @throws {FormulaSyntaxError} if formula needs to be parsed and can't be
   */
  runFormula (formula: string, extraContext: Partial<EvaluationContext> | null = null): FormulaValue {
    formula = normalizeFormula(formula);
    if (!formula) {
      return null;
    }
    assertParserLoaded();
    if (!extraContext) {
      // cache as a defined-name object if not a name or small-range reference
      const reference = Reference.from(formula.startsWith('=') ? formula.slice(1) : formula);
      const MIN_RANGE_SIZE_TO_CACHE = 10;
      const isTrivial = reference && (!reference.isAddress || reference.size! < MIN_RANGE_SIZE_TO_CACHE);
      if (!isTrivial) {
        const cellObj = this.getNativeWorkbook()?.getCachedFormulaCell(formula);
        if (cellObj) {
          return cellObj.v;
        }
      }
    }
    let ast = externalFormulaCache[formula];
    if (!ast) {
      // parseFormula is guaranteed non-null by documented pre-condition and by assertParserLoaded)
      ast = parseFormula!(formula);
      externalFormulaCache[formula] = ast;
    }
    try {
      return unbox(
        evaluateAST(ast, {
          ...this,
          ...extraContext,
          rawOutput: true,
        }),
      );
    }
    catch (err) {
      if (err instanceof EvaluationError) {
        err.formula = formula;
      }
      throw err;
    }
  }

  private getNativeWorkbook () {
    return this.getWorkbooks().find(wb => wb.type === 'native');
  }

  clearCachedFormula (formula: string | null | undefined) {
    return this.getNativeWorkbook()?.clearCachedFormula(formula);
  }

  clearCachedFormulasExcept (formulas: string[]) {
    return this.getNativeWorkbook()?.clearCachedFormulasExcept(formulas);
  }

  /**
   * Evaluate expression and return the resulting value.
   *
   * See `evaluateExpression`.
   *
   * @param expression the expression to evaluate. Treated as a formula if it begins with `=` and isn't just that.
   * @param fallBack value to return if expr is empty or null or just `=`.
   * @returns the resulting `Cell` instance, or `fallBack`, or a `Cell` instance with a `FormulaError` as its value.
   */
  readValue (expression: string, fallBack: CellValue | null = null): CellValue {
    // @ts-expect-error
    return this.evaluateExpression(expression, { fallBack, values: true, single: true });
  }

  /**
   * Evaluate expression and return the resulting `Cell`.
   *
   * See `evaluateExpression`.
   *
   * @param expression the expression to evaluate. Treated as a formula if it begins with `=` and isn't just that.
   * @param fallBack value to return if expr is empty or null or just `=`, or failed to resolve to a cell.
   * @returns the resulting `Cell` instance, or `fallBack`, or a `Cell` instance with a `FormulaError` as its value.
   */
  readCell (expression: string, fallBack: Cell = BLANK_CELL): Cell {
    // @ts-expect-error
    return this.evaluateExpression(expression, { fallBack, values: false, single: true });
  }

  /**
   * Evaluate expression, resolving any name reference result and recursing to
   * that formula, until a result that _isn't_ a name reference is obtained.
   * Return a single `Cell` which is:
   * * if the result is a reference to a range of sheet cells, the top-left cell
   *   cell of that range
   * * else if the result is that of a defined-name formula (not the original
   *   `expression`), return that last defined-name `Cell` object
   * * else the result itself (or its top-left element if it is a `Matrix`),
   *   wrapped in a `Cell` with no `id`.
   *
   * @see evaluateExpression
   * @param expression the expression to evaluate. Treated as a formula if it begins with `=` and isn't just that.
   * @param fallBack value to return if expr is empty or null or just `=`, or failed to resolve to a cell.
   * @returns the resulting `Cell` instance, or `fallBack`, or a `Cell` instance with a `FormulaError` as its value.
   */
  readCellOrDefinedName (expression: string, fallBack: Cell = BLANK_CELL): Cell {
    return this.evaluateExpression(expression, {
      fallBack,
      values: false,
      single: true,
      definedName: true,
      cropTo: 'any-cell-information',
    }) as Cell;
  }

  /**
   * Evaluate expression and return the resulting 2-D array of cells.
   *
   * See `evaluateExpression`.
   *
   * @param expression the expression to evaluate. Treated as a formula if it begins with `=` and isn't just that.
   * @returns the resulting 2-D array of cells, or an error or fallback wrapped into the same structure.
   */
  readCells (
    expression: string,
    options: { cropTo?: 'any-cell-information' | 'cells-with-non-blank-values' } = {},
  ): AreaCellArray {
    const { cropTo = 'any-cell-information' } = options;
    // @ts-expect-error
    const area: AreaCellArray = this.evaluateExpression(expression, {
      fallBack: [ [] ] as any,
      values: false,
      single: false,
      cropTo,
    });
    return area;
  }

  /**
   * Evaluate expression and return the resulting value, or cell, or 2-D array of values or of cells.
   *
   * A 2-D array is the type returned by `Reference.resolveArea`: an Array of Arrays of values or Cell instances,
   * plus the attributes `top`, `left`, `bottom`, `right`, `sheetName`.
   *
   * `expr` may be a literal value or a formula, or nullish or empty-string or the string `'='`. In the
   * latter three cases, `fallBack` is returned.
   *
   * Also, if `values` is false and `single` is true, then `fallBack` is returned if a cell could not be resolved.
   * This is not done if `values` is true or `single` is true. (The intent is to iron out this inconsistency when we
   * get to removing the fallBack parameter altogether, in ENGINE-142.)
   *
   * @param expression what to evaluate. Treated as a formula if it begins with `=` and isn't just that.
   * @returns the evaluated value, directly, or wrapped in a Cell instance or area array.
   *   If `single` is false and `values` is false, this returns an `AreaCellArray`.
   *   If `single` is false and `values` is true, this returns an `AreaValueArray`.
   *   If `single` is true and `values` is false, this returns a `Cell`.
   *   If `single` is true and `values` is true, this returns a `CellValue`.
   */
  evaluateExpression (
    expression: string,
    { fallBack, definedName, values, single, extraContext = null, cropTo }: EvaluateExpressionOptions,
  ): AreaArray<CellValue> | AreaArray<Cell> | CellValue | Cell {
    // sugar for components, nothing in? -> default "fallback" value
    if (isIgnore(expression)) {
      return fallBack;
    }
    // if it doesn't start with a = it is not a formula
    let singleValue: CellValue | Cell;
    let sheetName = '';
    let workbookName = '';
    // if it doesn't start with a "=", it is not an expression
    if (expression[0] === '=') {
      try {
        let res = this.runFormula(expression, extraContext);
        if (isRef(res)) {
          sheetName = res.sheetName || res.ctx?.sheetName || '';
          workbookName = res.workbookName || res.ctx?.workbookName || '';
        }
        if (isRef(res) || isMatrix(res)) {
          if (isRef(res)) {
            res = res.withContext({ ...this, ...extraContext });
          }
          if (definedName && isRef(res)) {
            return res.resolveCellOrDefinedName();
          }
          if (!single) {
            // no fallBack as resolveArea(Values|Cells) always either returns an array or throws.
            const area = values ? res.resolveAreaValues() : res.resolveAreaCells(cropTo);
            if (area.length === 0) {
              area.push([]);
            }
            return area;
          }
          else {
            const cell = res.resolveCell();
            if (values) {
              let value = cell?.v ?? fallBack;
              if (isMatrix(value) || isRef(value)) {
                value = value.resolveSingle();
              }
              if (isLambda(value)) {
                return ERROR_CALC_LAMBDA_NOT_ALLOWED;
              }
              return value;
            }
            return cell ?? fallBack;
          }
        }
        else if (isLambda(res)) {
          return ERROR_CALC_LAMBDA_NOT_ALLOWED;
        }
        // nulls emitted by runtime from other than ref resolutions are normalized to 0
        singleValue = res == null ? 0 : res;
      }
      catch (err: any) {
        if (DEV_LOGGING) {
          console.error(err);
        }
        if (err instanceof FormulaError) {
          singleValue = err;
        }
        else if (err.toFormulaError) {
          singleValue = err.toFormulaError();
        }
        else {
          this._emitExpressionEvaluationError(err);
          singleValue = ERROR_NAME.detailed(`Unexpected error evaluating formula: ${expression}`);
        }
      }
    }
    else {
      singleValue = expression;
    }
    // wrap the value in a cell and/or area depending on parameters
    if (!values && !(singleValue instanceof Cell)) {
      singleValue = new Cell({ v: singleValue });
    }
    if (!single) {
      return wrapInAreaArray(singleValue, sheetName, workbookName);
    }
    return singleValue;
  }

  _emitExpressionEvaluationError (exception: Error) {
    let error = this._expressionEvaluationErrorsByMsg[exception.message];
    let seenBefore = true;
    if (!error) {
      seenBefore = false;
      error = new ModelError(exception.message, ModelError.WARNING, null, 'unexpected', exception);
      this._expressionEvaluationErrorsByMsg[error.message] = error;
      // XXX: include evaluateExpression errors in this.errors and thus in the spreadsheet button and errors dialog?
      // They're not _spreadsheet_ errors, they're errors in the _GRID document_.
      // (But either kind may really be because of a bug in Apiary and not in the spreadsheet _or_ the GRID document...
      // and we are not making a clear distinction between these cases as it is...)
    }
    this.emit('error', {
      workbookId: null,
      workbookName: null,
      error,
      seenBefore,
    });
  }

  _removeError (modelError: ModelError) {
    const errorIndex = this._errorsFromModel.findIndex(err => err === modelError);
    if (errorIndex !== -1) {
      this._errorsFromModel.splice(errorIndex, 1);
      for (const vertexId of modelError.vertexIds) {
        const workbook = this.getWorkbookByKey(vertexId.workbookKey);
        if (workbook) {
          workbook._cellsWithErrors.delete(vertexId);
        }
      }
    }
    else {
      for (const wb of this._workbooks) {
        // No side effects if the given error doesn't belong to `wb`
        wb._removeError(modelError);
      }
    }
  }

  /**
   * @todo remove function once client usage has been removed.
   * @deprecated
   */
  analyzeFormula (
    formula: string,
    options?: {
      sheetName?: string,
      workbookName?: string | null,
    },
  ): AnalyzeFormulaResult {
    return this.analyzeAndFixFormula(formula, options);
  }

  analyzeAndFixFormula (
    formula: string,
    options?: { sheetName?: string, workbookName?: string | null },
  ): AnalyzeFormulaResult {
    formula = formula.trim();

    const evaluationContext: EvaluationContext = {
      ...baseRunOptions,
      ...this,
      ...options,
      allowMatrices: true,
    };

    invariant(parseFormula, 'parseFormula should be loaded');

    let ast: ASTNode;
    try {
      ast = parseFormula(formula);
    }
    catch (e: any) {
      if (e?.message === 'Invalid name or unrecognized formula syntax') {
        return { status: 'unparsable_formula' };
      }
      throw e;
    }

    if (ast && typeof ast === 'object' && 'call' in ast && ast.call === 'SORT') {
      // A SORT function with three arguments can be either Excel's or
      // Google's version of the SORT function. They differ in their
      // third argument, specifically:
      //
      //  - Excel takes 1 or -1
      //  - Google takes 1 or 0
      //
      // So if we receive a SORT function with three args, where the
      // third arg is a boolean arg (1, 0, true, false, "true", "false")
      // we will try to convert it to the Excel version (using 1 or -1).
      //
      // If we receive four or more arguments, we know that the formula
      // is using Google's syntax. In that case, we'll simply replace
      // the SORT function with SORT.GOOGLE.
      const { args } = ast;

      // eslint-disable-next-line no-inner-declarations
      function isUncoercedBooleanArg (arg: ASTNode) {
        return typeof arg === 'boolean' || arg === 0 || (typeof arg === 'string' && /^(true|false)$/i.test(arg));
      }

      const thirdArgIsBoolean = args.length > 2 && isUncoercedBooleanArg(args[2]);
      const hasFourOrMoreArgs = args.length > 3;
      const isGoogleSort = thirdArgIsBoolean || hasFourOrMoreArgs;

      if (isGoogleSort) {
        if (!hasFourOrMoreArgs) {
          const formulaUpper = formula.toUpperCase();

          const cases: [string, string][] = [
            [ 'FALSE)', '-1)' ],
            [ '0)', '-1)' ],
            [ '"FALSE")', '-1)' ],
            [ 'TRUE)', '1)' ],
            [ '"TRUE")', '1)' ],
          ];

          for (const [ toReplace, replaceWith ] of cases) {
            if (formulaUpper.endsWith(toReplace)) {
              const newFormula = formula.slice(0, formula.length - toReplace.length) + replaceWith;
              return this.analyzeFormula(newFormula, options);
            }
          }
        }

        if (/^=SORT\b/i.test(formula)) {
          return this.analyzeFormula('=SORT.GOOGLE' + formula.slice(5), options);
        }
      }
    }

    const usedFunctions = new Set<string>();

    visitAllCallNodes(ast, node => {
      if (typeof node.call === 'string') {
        usedFunctions.add(node.call);
      }
    });

    const problems: FormulaProblem[] = [];
    const usedUnsupported = new Set<string>();

    for (const functionName of usedFunctions) {
      const nameUpper = functionName.toUpperCase();
      if (!supportedFunctionNames.has(nameUpper)) {
        usedUnsupported.add(nameUpper);
      }
    }

    if (usedUnsupported.size > 0) {
      problems.push({ type: 'uses_unsupported_functions', unsupportedFunctions: [ ...usedUnsupported ] });
    }

    const resultOrLambda = unbox(this.evaluateAST(ast, evaluationContext));
    const result = isLambda(resultOrLambda) ? ERROR_CALC_LAMBDA_NOT_ALLOWED : resultOrLambda;

    if (isErr(result)) {
      const match = /^(?<unsupportedFeatureName>.+) (is|are) not( yet)? supported$/.exec(result.detail || '');
      const unsupportedFeatureName = match?.groups?.unsupportedFeatureName;
      if (unsupportedFeatureName) {
        problems.push({ type: 'uses_unsupported_syntax', unsupportedSyntax: [ unsupportedFeatureName ] });
      }
    }

    const encounteredReferences = new Set<string>();
    const references: Reference[] = [];
    const unresolvableReferences: Reference[] = [];

    const evaluateStaticReferenceASTNode = evaluateStaticReferenceASTNodeUnbound.bind(evaluationContext);
    visitAllTopLevelReferenceNodes(ast, node => {
      const ref: Reference | null = evaluateStaticReferenceASTNode(node) ?? null;
      const refString = String(ref);
      if (ref && !encounteredReferences.has(refString)) {
        encounteredReferences.add(refString);
        if (!ref.isResolvable()) {
          unresolvableReferences.push(ref);
        }
        references.push(ref);
      }
    });

    if (unresolvableReferences.length > 0) {
      problems.push({ type: 'has_unresolvable_references', unresolvableReferences });
    }

    if (problems.length) {
      return { status: 'has_problems', problems };
    }

    return { status: 'ok', formula, result, references, functions: [ ...usedFunctions ] };
  }

  /**
   * Precondition: formula parser has finished importing (`formulaParserReady` has resolved).
   * @param formula formula to update.
   * @param from reference containing a workbook and sheet prefix
   * @param to reference containing a workbook and sheet prefix
   * @returns the updated formula.
   */
  rewriteFormulaAfterMove (formula: string, from: string | A1Reference, to: string | A1Reference): string {
    const workbook = this._workbooks[0];
    const sheet = workbook?._sheets[0];
    return rewriteFormulaAfterMove(workbook?.name || '', sheet?.name || '', formula, from, to);
  }

  setupInitRecalculation () {
    for (const vertexId of findVertexIdsNeedingInitRecalc(this, ALWAYS_RECALCULATE_EXTERNAL_WB_REFERENCES_CUTOFF)) {
      this._recalcState.changedSinceRecalc.push(vertexId);
    }
  }

  get errors (): ModelError[] {
    const wbs = this.getWorkbooks();
    const errorList = [ ...this._errorsFromModel ];
    for (let i = 0; i < wbs.length; i++) {
      errorList.push(...wbs[i]._errors);
    }
    return errorList;
  }

  get meta (): ModelMeta {
    if (this._meta) {
      return this._meta;
    }
    let cellCount = 0;
    let volatileCount = 0;
    let graphNodeCount = 0;
    let graphEdgeCount = 0;
    const sources = this.getWorkbooks().map(wb => {
      const nVolatiles = this.volatiles.length;
      const nCells = wb.getSheets().reduce((sum, sheet) => sum + sheet.cellCount, 0);
      graphNodeCount += this._graph.nodeCount();
      graphEdgeCount += this._graph.edgeCount();
      cellCount += nCells || 0;
      volatileCount += nVolatiles || 0;
      return {
        id: wb.id,
        name: wb.name,
        update_time: wb.update_time,
        cellCount: nCells,
        volatileCount: nVolatiles,
      };
    });
    this._meta = {
      sources: sources,
      cellCount: cellCount,
      volatileCount: volatileCount,
      graphNodes: graphNodeCount,
      graphEdges: graphEdgeCount,
    };
    return this._meta;
  }

  /**
   * Return a single promise that settles when all dynamic imports of
   * spreadsheet function modules issued by this workbook's initialization
   * have completed (or failed).
   *
   * Special case: in Safari 12.5, the promise will reject as soon as _one_ of
   * the module imports fails, and meanwhile the others may not have completed.
   * So when more than one module is imported, it is possible that some of them
   * have yet to make their functions available for formula evaluation after
   * this promise settles. That's because Safari 12.5 does not support the
   * `Promise.allSettled` function. If we're really unlucky, it may sometimes
   * lead to evaluation failures due to the resulting race between the function
   * being called and the function becoming available. This would be quite the
   * corner case, but it _can_ happen when all of the below are true:
   *
   * - The browser is Safari 12.5.
   * - The dynamic import of a lazy-loaded function module fails (e.g. because
   *   of a network problem during page init).
   * - Another lazy-loaded function module whose import was issued during the
   *   same workbook init has not yet imported.
   *
   * In such a case, the document is already in trouble as one of the
   * lazy-loaded function modules it requires failed to load, and this just
   * compounds the problem a tiny bit by adding the possibility that _another_
   * lazy-loaded function will appear to be unsupported when a formula calling
   * it is evaluated, because that's happening too soon.
   */
  get lazyImportPromise (): Promise<any> {
    if (this._lazyImports.size <= 1) {
      return this._lazyImports.values().next().value ?? Promise.resolve(false);
    }
    // Use `Promise.allSettled` if available, but Safari 12.5 does not support it, so fall back to `Promise.all`.
    const promises = this._lazyImports.values();
    return typeof Promise.allSettled !== 'undefined' ? Promise.allSettled(promises) : Promise.all(promises);
  }

  _checkLazyImportsComplete () {
    // Find any lazy-import modules for which import has been triggered and not
    // yet completed.
    const notYetLoadedModulePaths = Array.from(this._lazyImports.keys()).filter(modulePath =>
      lazyLoadModulePathsNotYetImported.has(modulePath),
    );
    invariant(
      notYetLoadedModulePaths.length === 0,
      `Lazy-loaded function modules ${notYetLoadedModulePaths.join(', ')} ` +
        'should finish importing before Workbook.recalculate',
    );
  }
}

function makeRecalculationName (model: Model) {
  const recalcState = model._recalcState;
  const refStrings = new Set(
    [ ...recalcState.writtenSinceRecalc, ...model._recalcState.changedSinceRecalc ].map(v => briefRefStr(model, v)),
  );
  return [ ...refStrings ].join(', ') || 'init';
}

/**
 * Make a `Model` instance and populate it with a workbook from the given CSF and init options.
 * Precondition: the `Model.preconditions` promise has been resolved.
 *
 * @param csf a CSF object representing a workbook, as received
 *   from the `/document/:docId/workbook/:wbId/body` endpoint of the GRID API.
 * @throws {Error} if precondition is not satisfied (the formula parser has not
 * finished importing)
 */
export function modelFromData (csf: WorkbookCSF, options: AddWorkbookOptions = {}) {
  return Model.fromData(csf, options);
}

export function dumpGraph (model: Model) {
  const multipleWorkbooks = model.getWorkbooks().length > 1;
  return dumpOutgoing(model._graph, vertexId => {
    let ref = vertexIdToReference(model, vertexId);
    if (ref == null) {
      return '[Workbook/sheet not found] ' + vertexId.key;
    }
    if (!multipleWorkbooks) {
      ref = ref.withPrefix({ workbookName: '' });
    }
    return String(ref);
  });
}

export default Model;

/**
 * Get the workbook with the given name (case-insensitive), or undefined if no such workbook is in the model.
 */
function getWorkbook (this: Model, name?: string | null): Workbook | undefined {
  const nameDefaulted = name || this.defaultWorkbookName;
  if (nameDefaulted) {
    return this._workbooks.find(wb => excelEqual(wb.name, nameDefaulted));
  }
  else {
    return this._workbooks[0];
  }
}

/**
 * Get workbook with the given `keyInDepGraph`
 */
function getWorkbookByKey (this: Model, key: number): Workbook | undefined {
  return this._workbooks.find(wb => wb.keyInDepGraph === key);
}

function getSheet (this: Model, sheetName?: string | null, workbookName?: string | null): WorkSheet | null {
  if (workbookName || !sheetName) {
    return this.getWorkbook(workbookName)?.getSheet(sheetName) || null;
  }
  for (const workbook of this.getWorkbooks()) {
    const sheet = workbook.getSheet(sheetName);
    if (sheet) {
      return sheet;
    }
  }
  return null;
}

/**
 * @returns the cell object for the given defined-name, or a #NAME? error if not found
 */
function getGlobal (this: Model, name: string, workbookName?: string | null): Cell | FormulaError {
  invariant(typeof name === 'string', 'name must be string');
  if (workbookName) {
    const wb = this.getWorkbook(workbookName);
    if (wb) {
      return wb.getGlobal(name);
    }
  }
  else {
    for (const wb of this.getWorkbooks()) {
      const definedName = wb.getGlobal(name);
      if (!isErr(definedName)) {
        return definedName;
      }
    }
  }
  return ERROR_NAME.detailed('Global name not found: ' + name);
}

function resolveName (this: Model, name: string, sheetName?: string | null): Cell | FormulaError {
  if (sheetName) {
    return (
      this.resolveSheet(sheetName)?.locallyScopedNames[name.toLowerCase()] ||
      ERROR_NAME.detailed('Name not found: ' + name + ' in sheet ' + sheetName)
    );
  }
  return this.getGlobal(name);
}

function getTable (this: Model, name: string, workbookName: string | null | undefined): Table | null {
  invariant(typeof name === 'string', 'name must be string');
  if (workbookName) {
    const wb = this.getWorkbook(workbookName);
    if (wb) {
      return wb.getTable(name);
    }
  }
  else {
    for (const wb of this.getWorkbooks()) {
      const table = wb.getTable(name);
      if (table) {
        return table;
      }
    }
  }
  return null;
}

/**
 * Returns an object tree of all current writes to the model. The outermost object's
 * keys will be workbook names, second level will be sheet names, and third level will
 * be cell IDs or names with the written values as values:
 *
 * ```json
 *  {
 *   "myworkbook": {
 *     "sheet1": {
 *       "A1": 1234
 *     }
 *   }
 * }
 * ```
 */
function writeState (this: Model): ModelStateTree {
  const state: ModelStateTree = {};
  this.getWorkbooks().forEach(wb => {
    wb.writes().forEach(([ k, v ]) => {
      const ref = new Reference(k, { workbookName: wb.name });
      state[ref.workbookName] = state[ref.workbookName] || {};
      state[ref.workbookName][ref.sheetName] = state[ref.workbookName][ref.sheetName] || {};
      const cellIdOrName = ref.name || String(ref.range);
      state[ref.workbookName][ref.sheetName][cellIdOrName] = v;
    });
  });
  return state;
}
