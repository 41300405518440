export {
  type ModeBit,
  type ModeType,
  MODE_ALL,
  MODE_GRID_SHEET,
  MODE_EXCEL,
  MODE_GOOGLE,
  MODE_CANVAS,
} from './functions/sigtypes';
import FormulaError from './FormulaError';

export const MAX_COL = 2 ** 14 - 1;
export const MAX_ROW = 2 ** 20 - 1;
// Excel turns strings larger than 32767 bytes into an error. Google Sheets
// has a lower limit of 32000. We choose a larger limit of 512 KiB, or
// 262144 UTF-16 characters. Even the cheapest $100 smartphones have 2 GiB
// memory these days, so there's no reason to enforce a 64KiB limit.
export const MAX_STRING_CHARS = 2 ** 19 - 1;

// Excel does not recognize text in a formula
export const ERROR_NAME = new FormulaError('#NAME?', 5);
// formula has the wrong type of argument
// for example when a formula returns a range into a cell `=(A1:B2)`
export const ERROR_VALUE = new FormulaError('#VALUE!', 3);
// formula refers to a cell that is not valid
export const ERROR_REF = new FormulaError('#REF!', 4);
// formula tries to divide a number by 0 or an empty cell
export const ERROR_DIV0 = new FormulaError('#DIV/0!', 2);
// incorrect range operator in a formula, or
// an intersection operator between range references that don't intersect
export const ERROR_NULL = new FormulaError('#NULL!', 1);
// formula or function contains numeric values that aren't valid
export const ERROR_NUM = new FormulaError('#NUM!', 6);
// LOOKUP, HLOOKUP, LOOKUP, or MATCH functions if a formula can't find a referenced value
export const ERROR_NA = new FormulaError('#N/A', 7);

export const ERROR_GETDATA = new FormulaError('#GETTING_DATA', 8);

export const ERROR_SPILL = new FormulaError('#SPILL!', 9);

export const ERROR_UNKNOWN = new FormulaError('#UNKNOWN!', 12);

export const ERROR_FIELD = new FormulaError('#FIELD!', 13);

export const ERROR_CALC = new FormulaError('#CALC!', 14);

// Custom error for illegal formula syntax.
// Excel handles this by hard stopping user on illegal syntax entry
export const ERROR_SYNTAX = new FormulaError('#SYNTAX?', -1);

export const errorTable: Readonly<Record<string, FormulaError>> = {
  '#NAME?': ERROR_NAME,
  '#VALUE!': ERROR_VALUE,
  '#REF!': ERROR_REF,
  '#DIV/0!': ERROR_DIV0,
  '#NULL!': ERROR_NULL,
  '#NUM!': ERROR_NUM,
  '#N/A': ERROR_NA,
  '#GETTING_DATA': ERROR_GETDATA,
  '#SPILL!': ERROR_SPILL,
  '#UNKNOWN!': ERROR_UNKNOWN,
  '#FIELD!': ERROR_FIELD,
  '#CALC!': ERROR_CALC,
  '#SYNTAX?': ERROR_SYNTAX,
};

export function errorForCode (code: number) {
  return Object.values(errorTable).find(e => e.code === code);
}

export const VOLATILES = new Set([
  'NOW',
  'TODAY',
  'RAND',
  'RANDARRAY',
  'RANDBETWEEN',
  'OFFSET',
  'INDIRECT',
  // See: CLIENT-552
  // 'SUMIF',
  // 'AVERAGEIF',
  // 'COUNTIF',
  // 'LOOKUP',
  'INFO',
  'CELL',
  'GRID.ISPRINT',
  'GRID.SCENARIO',
  'GRID.USERNAME',
]);

// MISSING_VALUE is the value used to represent a missing argument.
// eslint-disable-next-line no-undefined
export const MISSING = undefined;
export const BLANK = null;

export const IDENTITY = <T>(v: T): T => v;

export const CACHED_FORMULA_CELL_ID_PREFIX = '__gridcf__';
export const EXTRACTED_SUBEXPRESSION_CELL_ID_PREFIX = '__gridef__';
