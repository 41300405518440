import { MAX_COL, MAX_ROW } from '../constants';
import { colFromOffs, LOCK } from './a1.js';

export const UNBOUNDED_NONE = 0;
export const UNBOUNDED_TOP = 1;
export const UNBOUNDED_BOTTOM = 2;
export const UNBOUNDED_TOP_BOTTOM = UNBOUNDED_TOP + UNBOUNDED_BOTTOM;
export const UNBOUNDED_LEFT = 4;
export const UNBOUNDED_RIGHT = 8;
export const UNBOUNDED_LEFT_RIGHT = UNBOUNDED_LEFT + UNBOUNDED_RIGHT;

export const INVERTED_X = 2;
export const INVERTED_Y = 1;

/**
 * @param {number} value
 * @param {number} min
 * @param {number} max
 */
const capToRange = (value, min, max) => Math.min(max, Math.max(min, value));

/**
 * @typedef {{ top: number, left: number, bottom: number, right: number }} SlimRange
 * @typedef {{ top: number, left: number, bottom?: number, right?: number }} SlimRangeOrCoords
 */

/**
 * A Range specifies an area in cell coordinate space that is enclosed by the Range's upper-left point (top,left)
 * and it's lower-right point (bottom,right).
 */
class Range {
  /**
   * @param {{
   *   top: number, left: number, bottom?: number, right?: number,
   *   $top?: boolean, $left?: boolean, $bottom?: boolean, $right?: boolean,
   *   unbounded?: number,
   * }} src zero-based coordinates of the bounds of a range, and optionally the "lockedness" of any of those bounds
   */
  constructor ({ top, left, bottom, right, $top, $left, $bottom, $right, unbounded }) {
    // coords
    let inverted = 0;

    // allow omitting bottom and right so that single point ranges can be created
    // quickly by doing: new Range({ top: 10, left: 10 })
    if (bottom == null) {
      bottom = top;
      $bottom = $top;
    }
    if (right == null) {
      right = left;
      $right = $left;
    }

    // flip inversed references: C2:A1 -> A1:C2
    // =INDIRECT("A1:B2") is the same as =INDIRECT("B2:A1")
    if (bottom < top) {
      const _top = bottom;
      const _$top = $bottom;
      bottom = top;
      $bottom = $top;
      top = _top;
      $top = _$top;
      inverted |= INVERTED_Y;
    }
    if (right < left) {
      const _left = right;
      const _$left = $right;
      right = left;
      $right = $left;
      left = _left;
      $left = _$left;
      inverted |= INVERTED_X;
    }

    if (!isFinite(top) || !isFinite(left) || !isFinite(bottom) || !isFinite(right)) {
      throw new Error(`Invalid Range coordinates ${top}, ${left}, ${bottom}, ${right}`);
    }

    if (!Range.withinBounds(left, top) || !Range.withinBounds(right, bottom)) {
      throw new Error(`Range out of bounds ${top}, ${left}, ${bottom}, ${right}`);
    }

    this.top = top * 1;
    this.bottom = bottom * 1;
    this.left = left * 1;
    this.right = right * 1;

    // locked
    this.$top = !!$top;
    this.$bottom = !!$bottom;
    this.$left = !!$left;
    this.$right = !!$right;

    this.inverted = /** @type {0 | 1 | 2 | 3} */ (inverted);
    this.unbounded = unbounded ?? UNBOUNDED_NONE;

    // immutable (but extendable)
    if (this.constructor === Range) {
      Object.freeze(this);
    }
  }

  /**
   * @param {number} left
   * @param {number} [right] set to the value of `left` if not provided
   */
  static createColumnRange (left, right = left) {
    return new Range({ left, right, top: 0, bottom: MAX_ROW, unbounded: UNBOUNDED_TOP_BOTTOM });
  }

  /**
   * @param {number} top
   * @param {number} [bottom] set to the value of `top` if not provided
   */
  static createRowRange (top, bottom = top) {
    return new Range({ top, bottom, left: 0, right: MAX_COL, unbounded: UNBOUNDED_LEFT_RIGHT });
  }

  /**
   * @param {boolean} [abs=false]
   */
  toString (abs = false) {
    const { left, right, top, bottom, $left, $right, $top, $bottom, unbounded } = this;

    // A:A
    if (top === 0 && bottom === MAX_ROW) {
      return (abs || $left ? LOCK : '') + colFromOffs(left) + ':' + (abs || $right ? LOCK : '') + colFromOffs(right);
    }
    // 1:1
    if (left === 0 && right === MAX_COL) {
      return (abs || $top ? LOCK : '') + (1 + top) + ':' + (abs || $bottom ? LOCK : '') + (1 + bottom);
    }
    // A2:A
    if (unbounded === UNBOUNDED_BOTTOM && bottom === MAX_ROW) {
      return (
        (abs || $left ? LOCK : '') +
        colFromOffs(left) +
        (abs || $top ? LOCK : '') +
        (1 + top) +
        ':' +
        (abs || $right ? LOCK : '') +
        colFromOffs(right)
      );
    }
    // B1:1
    if (unbounded === UNBOUNDED_RIGHT && right === MAX_COL) {
      return (
        (abs || $left ? LOCK : '') +
        colFromOffs(left) +
        (abs || $top ? LOCK : '') +
        (1 + top) +
        ':' +
        (abs || $bottom ? LOCK : '') +
        (1 + bottom)
      );
    }
    // A1:A1
    if (right > left || bottom > top) {
      return (
        (abs || $left ? LOCK : '') +
        colFromOffs(left) +
        (abs || $top ? LOCK : '') +
        (1 + top) +
        ':' +
        (abs || $right ? LOCK : '') +
        colFromOffs(right) +
        (abs || $bottom ? LOCK : '') +
        (1 + bottom)
      );
    }
    // A1
    return (abs || $left ? LOCK : '') + colFromOffs(left) + (abs || $top ? LOCK : '') + (1 + top);
  }

  /**
   * Returns true if this is 1x1, a single-cell range.
   */
  get isCollapsed () {
    return this.size === 1;
  }

  /**
   * Returns a new Range collapsed to a single point at to one of the boundary points of this range.
   *
   * @param {boolean} [toTopLeft=true] `true` collapses the Range to its top/left corner, `false` to its bottom/right.
   */
  collapse (toTopLeft = true) {
    return toTopLeft ? this.collapseToTopLeft() : this.collapseToBottomRight();
  }

  /**
   * Returns a new Range collapsed to a single point at the top-left corner of this range.
   * Does not copy lock attributes ($top etc.).
   */
  collapseToTopLeft () {
    const { left, top } = this;
    return new Range({ left, top });
  }

  /**
   * Returns a new Range collapsed to a single point at the bottom-right corner of this range.
   * Does not copy lock attributes ($top etc.).
   */
  collapseToBottomRight () {
    const { bottom, right } = this;
    return new Range({ left: right, top: bottom });
  }

  /**
   * Returns a new Range collapsed to the given row of this range (default top row)
   * Does not copy lock attributes ($top etc.).
   * Does not check bounds, so result will be outside this range if r >= height.
   * @param {number} r zero-based index of the row to collapse to.
   */
  collapseToRow (r = 0) {
    const { top, left, right } = this;
    const rowIndex = top + r;
    return new Range({ top: rowIndex, bottom: rowIndex, left, right });
  }

  /**
   * Returns a new Range collapsed to the given column of this range (default leftmost column).
   * Does not copy lock attributes ($top etc.).
   * Does not check bounds, so result will be outside this range if c >= width.
   * @param {number} c zero-based index of the column to collapse to.
   */
  collapseToColumn (c = 0) {
    const { top, bottom, left } = this;
    const colIndex = left + c;
    return new Range({ top, bottom, left: colIndex, right: colIndex });
  }

  /**
   * Returns a new Range with the same shorter dimension as this one, but the longer dimension set to `newLength`.
   * (This will make the longer dimension the shorter one, if `newLength` < the shorter dimension. The intended
   * use case of this is to extend rather than contract, in which case the longer dimension remains longer, so this
   * will need changing if the opposite use case becomes relevant.)
   * Does not copy lock attributes ($top etc.).
   * @param {number} newLength the length of the longer dimension (width or height) of the new range
   * @return {Range} a range like this one but with its longer dimension changed to `newLength`.
   */
  extendToLength (newLength) {
    const oldLength = Math.max(this.width, this.height);
    if (newLength < oldLength) {
      throw new Error(`extendToLength called with length ${newLength} shorter than current length ${oldLength}`);
    }
    const { top, bottom, left, right } = this;
    const extendedRange = { top, bottom, left, right };
    if (this.width >= this.height) {
      extendedRange.right = left + newLength - 1;
    }
    else {
      extendedRange.bottom = top + newLength - 1;
    }
    return new Range(extendedRange);
  }

  get width () {
    return this.right - this.left + 1;
  }

  get height () {
    return this.bottom - this.top + 1;
  }

  get size () {
    return this.width * this.height;
  }

  /**
   * Compares this range to another range to test if their defined rectangles are equal.
   * This only tests the values of top, left, bottom, right; ignoring any locks or initial invertedess of the range.
   *
   * @param {Object|Range} range the reference range with which to compare.
   */
  equals (range) {
    return (
      range &&
      this.top === range.top &&
      this.left === range.left &&
      this.bottom === range.bottom &&
      this.right === range.right
    );
  }

  /**
   * Compares this range to another range to test if their defined rectangles are equal.
   * This tests both the rectangles as well as the status of locks on the dimensions,
     but not the initial invertedness.
   *
   * @param {Object|Range} range the reference range with which to compare.
   */
  strictEquals (range) {
    return (
      this.equals(range) &&
      this.$top === range.$top &&
      this.$left === range.$left &&
      this.$bottom === range.$bottom &&
      this.$right === range.$right
    );
  }

  /**
   * Checks whether the specified range is fully covered by this range.
   *
   * @param {SlimRangeOrCoords} rangeOrCoords the reference range with which to compare.
   */
  contains (rangeOrCoords) {
    if (!rangeOrCoords) {
      return false;
    }
    const { top, left } = rangeOrCoords;
    const bottom = rangeOrCoords.bottom ?? rangeOrCoords.top;
    const right = rangeOrCoords.right ?? rangeOrCoords.left;
    return this.top <= top && this.left <= left && this.bottom >= bottom && this.right >= right;
  }

  /**
   * Subtract the supplied range from the current one. Yields up to 4 ranges, depending on how the ranges overlap.
   * @param {Range} range
   * @returns {IterableIterator<Range>}
   */
  * except (range) {
    if (!this.intersects(range)) {
      yield this;
    }
    else {
      if (this.left < range.left) {
        yield new Range({ left: this.left, right: range.left - 1, top: this.top, bottom: this.bottom });
      }
      if (this.right > range.right) {
        yield new Range({ left: range.right + 1, right: this.right, top: this.top, bottom: this.bottom });
      }
      if (this.top < range.top) {
        yield new Range({
          left: Math.max(this.left, range.left),
          right: Math.min(this.right, range.right),
          top: this.top,
          bottom: range.top - 1,
        });
      }
      if (this.bottom > range.bottom) {
        yield new Range({
          left: Math.max(this.left, range.left),
          right: Math.min(this.right, range.right),
          top: range.bottom + 1,
          bottom: this.bottom,
        });
      }
    }
  }

  /**
   * @param {Range} range
   * @returns {boolean}
   */
  intersects (range) {
    return range.right >= this.left && range.left <= this.right && range.top <= this.bottom && range.bottom >= this.top;
  }

  /**
   * @param {SlimRange} other
   */
  getIntersection (other) {
    const top = Math.max(this.top, other.top);
    const left = Math.max(this.left, other.left);
    const bottom = Math.min(this.bottom, other.bottom);
    const right = Math.min(this.right, other.right);
    if (bottom >= top && right >= left) {
      return new Range({ top, left, bottom, right });
    }
    else {
      return null;
    }
  }

  /**
   * @returns {IterableIterator<{row: number, column: number}>}
   */
  * iterCoordinates () {
    for (let row = this.top; row <= this.bottom; row++) {
      for (let column = this.left; column <= this.right; column++) {
        yield { row, column };
      }
    }
  }

  /**
   * @param {number} deltaX
   * @param {number} deltaY
   * @returns {Range} a new range that has been shifted by X and Y.
   */
  moveBy (deltaX, deltaY) {
    let { left, right, top, bottom } = this;
    deltaX = capToRange(deltaX, -left, MAX_COL - right);
    deltaY = capToRange(deltaY, -top, MAX_ROW - bottom);

    if ((this.unbounded & UNBOUNDED_LEFT) === 0) {
      left += deltaX;
    }
    if ((this.unbounded & UNBOUNDED_RIGHT) === 0) {
      right += deltaX;
    }
    if ((this.unbounded & UNBOUNDED_TOP) === 0) {
      top += deltaY;
    }
    if ((this.unbounded & UNBOUNDED_BOTTOM) === 0) {
      bottom += deltaY;
    }

    const { $left, $right, $top, $bottom, unbounded } = this;
    return new Range({ top, left, bottom, right, $left, $right, $top, $bottom, unbounded });
  }

  /**
   * @param {'top' | 'left' | 'right' | 'bottom'} side
   * @param {number} position
   */
  extendSide (side, position) {
    if (
      (side === 'top' && (this.unbounded & UNBOUNDED_TOP) === 0) ||
      (side === 'bottom' && (this.unbounded & UNBOUNDED_BOTTOM) === 0)
    ) {
      position = capToRange(position, 0, MAX_ROW);
    }
    else if (
      (side === 'left' && (this.unbounded & UNBOUNDED_LEFT) === 0) ||
      (side === 'right' && (this.unbounded & UNBOUNDED_RIGHT) === 0)
    ) {
      position = capToRange(position, 0, MAX_COL);
    }
    else {
      return this;
    }

    /** @type {SlimRange} */
    const slimRange = {
      left: this.left,
      right: this.right,
      bottom: this.bottom,
      top: this.top,
    };
    slimRange[side] = position;
    const { $left, $right, $top, $bottom, unbounded } = this;
    return new Range({ ...slimRange, $left, $right, $top, $bottom, unbounded });
  }

  toBoundingBox () {
    const { top, left, bottom, right } = this;
    return { minY: top, minX: left, maxY: bottom, maxX: right };
  }
}

Range.MAX_ROW = MAX_ROW;
Range.MAX_COL = MAX_COL;

Range.withinBounds = function (col, row) {
  return row <= Range.MAX_ROW && col <= Range.MAX_COL && row >= 0 && col >= 0;
};

export default Range;
